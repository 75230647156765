import React, { useEffect, useState } from "react";
import styled from "styled-components";
import * as XLSX from "xlsx";
import { CODE_COUNTRY_ITEMS } from "../../../common/constants";
import {
  reqGetAllUserById,
  reqGetEveryUserServiceLog,
} from "../../../requests/user";
import { Pagination } from "react-bootstrap";
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  ChartOptions,
  Legend,
  LinearScale,
  Tooltip,
} from "chart.js";
import { Bar, Pie } from "react-chartjs-2";

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend
);

interface User {
  id: string;
  name: string;
  country: number;
  email: string;
  point: number;
  agreeMarketing: boolean;
  favorite: string;
  latestWarehouseId: string;
  membershipId: number;
  createdAt: string;
  inflow: number;
}

interface ChartData {
  labels: string[];
  datasets: {
    label: string;
    data: number[];
    backgroundColor: string[];
    borderColor: string[];
    borderWidth: number;
  }[];
}

interface ChartSData {
  labels: string[];
  datasets: {
    label: string;
    data: number[];
    backgroundColor: string;
    borderColor: string;
    borderWidth: number;
  }[];
}

const initialMembershipData: ChartData = {
  labels: [],
  datasets: [
    {
      label: "",
      data: [],
      backgroundColor: [],
      borderColor: [],
      borderWidth: 1,
    },
  ],
};

const INFLOW_SOURCES = {
  GOOGLE: 0,
  INSTAGRAM: 1,
  TIKTOK: 2,
  WATCHA: 3,
  KAKAOTALK: 4,
  SEARCH: 5,
  LINE: 6,
  OTHER: 7,
};

const FILTER_PERIODS = {
  ALL: "All",
  DAY: "Day",
  WEEK: "Week",
  MONTH: "Month",
  YEAR: "Year",
};

const Anewuser: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [shipLog, setShipLog] = useState<any>({});
  const [shopLog, setShopLog] = useState<any>({});
  const [filteredUsers, setFilteredUsers] = useState<User[]>([]);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [membershipData, setMembershipData] = useState<ChartData>(
    initialMembershipData
  );
  const [countryData, setCountryData] = useState<ChartSData>({
    labels: [],
    datasets: [
      {
        label: "",
        data: [],
        backgroundColor: "",
        borderColor: "",
        borderWidth: 1,
      },
    ],
  });
  const [signupRouteData, setSignupRouteData] = useState<ChartSData>({
    labels: [],
    datasets: [
      {
        label: "",
        data: [],
        backgroundColor: "",
        borderColor: "",
        borderWidth: 1,
      },
    ],
  });
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filterPeriod, setFilterPeriod] = useState<string>(FILTER_PERIODS.ALL);

  useEffect(() => {
    fetchAllUsers();
  }, []);

  useEffect(() => {
    updateStatistics(users);
  }, [itemsPerPage, currentPage, startDate, endDate, searchTerm, filterPeriod]);

  const fetchAllUsers = async () => {
    try {
      const response = await reqGetAllUserById();
      setUsers(response.data);

      const serviceLogs = await reqGetEveryUserServiceLog();
      setShipLog(serviceLogs.data.shipLog);
      setShopLog(serviceLogs.data.shopLog);

      updateStatistics(response.data);
    } catch (error) {
      console.error("전체 사용자 정보 조회 실패:", error);
    }
  };

  const updateStatistics = (users: User[]) => {
    const membershipCounts: { [key: string]: number } = {
      "1": 0,
      "2": 0,
      "3": 0,
    };
    const countryCounts: { [key: number]: number } = {};
    const signupRouteCounts: { [key: number]: number } = {
      [INFLOW_SOURCES.GOOGLE]: 0,
      [INFLOW_SOURCES.INSTAGRAM]: 0,
      [INFLOW_SOURCES.TIKTOK]: 0,
      [INFLOW_SOURCES.WATCHA]: 0,
      [INFLOW_SOURCES.KAKAOTALK]: 0,
      [INFLOW_SOURCES.SEARCH]: 0,
      [INFLOW_SOURCES.LINE]: 0,
      [INFLOW_SOURCES.OTHER]: 0,
    };

    const now = new Date();
    const startUTC = startDate ? new Date(startDate) : null;
    let endUTC = endDate ? new Date(endDate) : null;

    // 동일한 날짜일 경우 해당 날짜의 24시간 범위로 설정
    if (startDate === endDate && startUTC && endUTC) {
      endUTC.setHours(23, 59, 59, 999);
    }

    const filteredUsers = users.filter((user) => {
      const createdAtUTC = new Date(user.createdAt);

      let withinPeriod = true;
      if (filterPeriod !== FILTER_PERIODS.ALL) {
        switch (filterPeriod) {
          case FILTER_PERIODS.DAY:
            withinPeriod =
              now.getTime() - createdAtUTC.getTime() <= 24 * 60 * 60 * 1000;
            break;
          case FILTER_PERIODS.WEEK:
            withinPeriod =
              now.getTime() - createdAtUTC.getTime() <= 7 * 24 * 60 * 60 * 1000;
            break;
          case FILTER_PERIODS.MONTH:
            withinPeriod =
              now.getTime() - createdAtUTC.getTime() <=
              30 * 24 * 60 * 60 * 1000;
            break;
          case FILTER_PERIODS.YEAR:
            withinPeriod =
              now.getTime() - createdAtUTC.getTime() <=
              365 * 24 * 60 * 60 * 1000;
            break;
        }
      }

      return (
        (!startUTC || createdAtUTC >= startUTC) &&
        (!endUTC || createdAtUTC <= endUTC) &&
        user.id.toUpperCase().includes(searchTerm.toUpperCase()) &&
        withinPeriod
      );
    });

    filteredUsers.forEach((user: User) => {
      const membershipId = user.membershipId.toString();
      if (membershipCounts.hasOwnProperty(membershipId)) {
        membershipCounts[membershipId]++;
      }

      countryCounts[user.country] = (countryCounts[user.country] || 0) + 1;

      if (signupRouteCounts.hasOwnProperty(user.inflow)) {
        signupRouteCounts[user.inflow]++;
      }
    });

    setMembershipData(prepareChartData(membershipCounts));
    setCountryData(prepareCountryChartData(countryCounts));
    setSignupRouteData(prepareSignupRouteChartData(signupRouteCounts));
    setFilteredUsers(filteredUsers);
    if (startDate || endDate) {
      setTotalPages(1);
    } else {
      setTotalPages(Math.ceil(users.length / itemsPerPage));
      updateFilteredUsers(filteredUsers);
    }
  };

  const prepareChartData = (membershipCounts: { [key: string]: number }) => {
    return {
      labels: ["Red", "Blue", "Green"],
      datasets: [
        {
          label: "Membership Status",
          data: [
            membershipCounts["1"],
            membershipCounts["2"],
            membershipCounts["3"],
          ],
          backgroundColor: [
            "rgba(255, 99, 132, 0.2)", // Red
            "rgba(54, 162, 235, 0.2)", // Blue
            "rgba(75, 192, 192, 0.2)", // Green
          ],
          borderColor: [
            "rgba(255, 99, 132, 1)", // Red
            "rgba(54, 162, 235, 1)", // Blue
            "rgba(75, 192, 192, 1)", // Green
          ],
          borderWidth: 1,
        },
      ],
    };
  };

  const prepareCountryChartData = (countryCounts: {
    [key: number]: number;
  }): ChartSData => {
    const sortedCountryCounts = Object.entries(countryCounts)
      .sort(([, a], [, b]) => b - a)
      .slice(0, 10); // Get top 10 countries

    const labels = sortedCountryCounts.map((key) =>
      typeof CODE_COUNTRY_ITEMS[+key[0]] === "string"
        ? CODE_COUNTRY_ITEMS[+key[0]]
        : "Unknown"
    ) as string[];
    const data = sortedCountryCounts.map((key) => key[1]) as number[];

    return {
      labels,
      datasets: [
        {
          label: "Member Count by Country",
          data,
          backgroundColor: "rgba(54, 162, 235, 0.2)",
          borderColor: "rgba(54, 162, 235, 1)",
          borderWidth: 1,
        },
      ],
    };
  };

  const prepareSignupRouteChartData = (signupRouteCounts: {
    [key: number]: number;
  }): ChartSData => {
    const labels = Object.keys(INFLOW_SOURCES).filter(
      (key) =>
        typeof INFLOW_SOURCES[key as keyof typeof INFLOW_SOURCES] === "number"
    ) as string[];
    const data = Object.values(INFLOW_SOURCES)
      .filter((value) => typeof value === "number")
      .map((source) => signupRouteCounts[source] || 0) as number[];

    return {
      labels,
      datasets: [
        {
          label: "가입 경로",
          data,
          backgroundColor: "rgba(75, 192, 192, 0.2)",
          borderColor: "rgba(75, 192, 192, 1)",
          borderWidth: 1,
        },
      ],
    };
  };

  const handleItemsPerPageChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const newItemsPerPage = parseInt(e.target.value);
    setItemsPerPage(newItemsPerPage === -1 ? users.length : newItemsPerPage);
    setCurrentPage(1);
    updateFilteredUsers(users);
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    updateFilteredUsers(users);
  };

  const updateFilteredUsers = (users: User[]) => {
    const sortedUsers = [...users].sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    );
    const startIndex = (currentPage - 1) * itemsPerPage;
    const displayedUsers =
      itemsPerPage === users.length
        ? sortedUsers
        : sortedUsers.slice(startIndex, startIndex + itemsPerPage);
    setFilteredUsers(displayedUsers);
    setTotalPages(Math.ceil(users.length / itemsPerPage));
  };

  const handleDownload = () => {
    const wb = XLSX.utils.book_new();
    const wsData = filteredUsers.map((user) => ({
      "Space Code": user.id,
      Name: user.name,
      Country: CODE_COUNTRY_ITEMS[user.country] || "Unknown",
      Date: new Date(user.createdAt).toLocaleDateString(),
      Membership:
        user.membershipId === 1
          ? "Red"
          : user.membershipId === 2
          ? "Blue"
          : "Green",
    }));

    const ws = XLSX.utils.json_to_sheet(wsData);
    XLSX.utils.book_append_sheet(wb, ws, "Users");
    XLSX.writeFile(wb, "UserList.xlsx");
  };

  const options: ChartOptions<"bar"> = {
    indexAxis: "y",
  };

  return (
    <main id="main" className="main">
      <Header>
        <FilterContainer>
          <label>
            Start Date:
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </label>
          <label>
            End Date:
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </label>
          <Dropdown
            value={filterPeriod}
            onChange={(e) => setFilterPeriod(e.target.value)}
          >
            <option value={FILTER_PERIODS.ALL}>All</option>
            <option value={FILTER_PERIODS.DAY}>1 Day</option>
            <option value={FILTER_PERIODS.WEEK}>1 Week</option>
            <option value={FILTER_PERIODS.MONTH}>1 Month</option>
            <option value={FILTER_PERIODS.YEAR}>1 Year</option>
          </Dropdown>
          <button
            onClick={() => {
              updateStatistics(users);
              setItemsPerPage(users.length);
            }}
          >
            Filter
          </button>
        </FilterContainer>
        <DownloadButton onClick={handleDownload}>Download</DownloadButton>
      </Header>

      <Content>
        <TableContainer>
          <ItemsPerPageContainer>
            <label>Show</label>
            <Dropdown value={itemsPerPage} onChange={handleItemsPerPageChange}>
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
              <option value={-1}>All</option>
            </Dropdown>
            <label>entries</label>
            <SearchBar
              type="text"
              placeholder="Search by Space Code"
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value.replace(/\s/g, "").toUpperCase());
                setCurrentPage(1);
                updateFilteredUsers(users);
              }}
            />
          </ItemsPerPageContainer>

          <div
            style={{
              padding: "0.5rem 1rem",
              borderRadius: "0.4rem",
              backgroundColor: "#f8f9fa",
              textAlign: "center",
            }}
          ></div>

          <div className="container-fluid">
            <table className="table text-center">
              <thead>
                <Row
                  style={{
                    borderBottom: "1px solid lightgray",
                    fontWeight: "bold",
                  }}
                >
                  <div>Space Code</div>
                  <div>Name</div>
                  <div>Country</div>
                  <div>Date</div>
                  <div>Membership</div>
                </Row>
              </thead>

              <tbody>
                {filteredUsers.map((user, index) => (
                  <Row key={index}>
                    <div>{user.id}</div>
                    <div>{user.name}</div>
                    <div>{CODE_COUNTRY_ITEMS[user.country] || "Unknown"}</div>
                    <div>{new Date(user.createdAt).toLocaleString()}</div>
                    <div>
                      {user.membershipId === 1
                        ? "Red"
                        : user.membershipId === 2
                        ? "Blue"
                        : "Green"}
                    </div>
                  </Row>
                ))}
              </tbody>
            </table>
          </div>

          <CenteredPagination style={{ marginTop: "1rem" }}>
            <Pagination.First
              onClick={() => handlePageChange(1)}
              disabled={currentPage === 1}
            />
            <Pagination.Prev
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            />
            {currentPage > 3 && <Pagination.Ellipsis />}
            {[...Array(totalPages)]
              .map((_, index) => index + 1)
              .filter(
                (page) =>
                  page === 1 ||
                  page === totalPages ||
                  (page >= currentPage - 2 && page <= currentPage + 2)
              )
              .map((page) => (
                <Pagination.Item
                  key={page}
                  active={page === currentPage}
                  onClick={() => handlePageChange(page)}
                >
                  {page}
                </Pagination.Item>
              ))}
            {currentPage < totalPages - 2 && <Pagination.Ellipsis />}
            <Pagination.Next
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            />
            <Pagination.Last
              onClick={() => handlePageChange(totalPages)}
              disabled={currentPage === totalPages}
            />
          </CenteredPagination>
        </TableContainer>

        <StatisticsContainer>
          <div className="chart-container large-chart">
            <h3>가입 합계</h3>
            <Pie data={membershipData} id="pie-chart" />
          </div>
          <div className="chart-container large-chart">
            <h3>가입 경로</h3>
            <Bar data={signupRouteData} options={options} />
          </div>
          <div className="chart-container large-chart">
            <h3>가입자 국가 Top 10</h3>
            <Bar data={countryData} options={options} />
          </div>
        </StatisticsContainer>
      </Content>
    </main>
  );
};

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
`;

const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  label {
    font-weight: bold;
  }

  input {
    margin-left: 0.5rem;
  }

  button {
    padding: 0.5rem 1rem;
    border-radius: 0.4rem;
    background-color: var(--color-main-blue);
    color: var(--color-white);
    border: none;
    cursor: pointer;
    &:hover {
      background-color: var(--color-main-blue-hover);
    }
  }
`;

const SearchBar = styled.input`
  padding: 0.5rem 1rem;
  border-radius: 0.4rem;
  border: 1px solid lightgray;
  margin-left: auto; /* To position it to the right */
`;

const DownloadButton = styled.button`
  padding: 0.5rem 1rem;
  border-radius: 0.4rem;
  background-color: var(--color-main-blue);
  color: var(--color-white);
  border: none;
  cursor: pointer;
  &:hover {
    background-color: var(--color-main-blue-hover);
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const TableContainer = styled.div`
  padding: 1rem;
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const ItemsPerPageContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

  label {
    margin: 0 0.5rem;
  }
`;

const Dropdown = styled.select`
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;

  border-radius: 0.4rem;
  border: 1px solid lightgray;
  padding: 0.4rem;
`;

const CenteredPagination = styled(Pagination)`
  display: flex;
  justify-content: center;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  text-align: center;
  align-items: center;
  gap: 1rem;
  & > div {
    flex: 1 1 0;
    word-break: break-all;
    padding: 0.5rem;
    white-space: normal;
    overflow: hidden;
  }
`;

const StatisticsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 2rem;

  .chart-container {
    flex: 1;
    min-width: 300px;
    background-color: #fff;
    padding: 1rem;
    border-radius: 0.5rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
  }

  .large-chart {
    flex: 2;
  }
`;

export default Anewuser;
