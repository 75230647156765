import React, { useEffect, useState } from "react";
import styled from "styled-components";

const WorldTimeDisplay = ({ city, timeZone }: any) => {
  const [timeData, setTimeData] = useState<any>(null);

  useEffect(() => {
    const fetchTime = async () => {
      try {
        const response = await fetch(
          `https://worldtimeapi.org/api/timezone/${timeZone}`
        );
        const data = await response.json();
        const date = data.datetime.split("T")[0];
        const time = data.datetime.split("T")[1].split(".")[0];
        setTimeData({ datetime: data.datetime.split(".")[0], date, time });
      } catch (error) {
        console.error("Error fetching time data:", error);
      }
    };

    fetchTime();
  }, [timeZone]);

  useEffect(() => {
    if (timeData) {
      const intervalId = setInterval(() => {
        setTimeData((prevTimeData: any) => {
          const newDate = new Date(prevTimeData.datetime);
          newDate.setSeconds(newDate.getSeconds() + 1);
          return {
            ...prevTimeData,
            datetime: newDate.toISOString(),
            time: newDate.toTimeString().split(" ")[0],
            date: newDate.toLocaleDateString(),
          };
        });
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [timeData]);

  if (!timeData) {
    return <div>Loading...</div>;
  }

  return (
    <TimeDisplay className="f-col">
      <div className="city">{city}</div>
      <div className="time">{timeData.time}</div>
      <div className="date">{timeData.date}</div>
    </TimeDisplay>
  );
};

const TimeDisplay = styled.div`
  .city {
    font-size: 1.2rem;
  }
  .date {
  }
  .time {
    font-size: 1.8rem;
  }
`;

export default WorldTimeDisplay;
