import React from "react";
import styled from "styled-components";

export default function TitleCustomer(props: {
  title: string;
  color?: string;
  style?: React.CSSProperties;
}) {
  return (
    <H1 style={{ ...props.style, color: props.color ?? "inherit" }}>
      {props.title.split("\n").map((line, index) => (
        <span key={index}>
          {line}
          <br />
        </span>
      ))}
    </H1>
  );
}

const H1 = styled.h1`
  margin-bottom: 0px;
  font-size: x-large;
  font-family: "Fjalla One", "NanumSquare", sans-serif;
  white-space: pre-line;
`;
