import React, { Fragment, useEffect, useState } from "react";
import { Button, Form, InputGroup, Modal, Table } from "react-bootstrap";
import styled from "styled-components";
import {
  CODE_SHOPING_ORDER_STATUS,
  CODE_SHOPPING_ORDER_ITEMS,
  CODE_SHOPING_PRODUCT_DETAIL_STATUS,
} from "../../common/constants";
import { reqShop, reqShopDetail, reqShopUpdate } from "../../requests/shop";
import { reqUpdateUserPoint } from "../../requests/user";
import { formatDate } from "../../common/format";
import { reqShipFEE } from "../../requests/ship";
interface ShopOrderModalProps {
  show: boolean;
  onHide: () => void;
  orderId: string;
  refreshData: () => void;
  status?: number;
}

interface OrderDetail {
  order_id: string;
  category: number;
  productId: string;
  name: string;
  option1: string;
  option2: string;
  quantity: number;
  price: number;
  ShippingFee: number;
  status: number;
  url: string;
  fee: number;
  cancellationReason: string;
  isCanceled: boolean;
  user: {
    point: number;
    id: string;
  };
}

interface ShopData {
  failed: boolean;
  refund: boolean;
  option1: string;
  option2: string;
  url: string;
  paymentDeadline: string;
  fancallNumber: string;
  usePointKrw: number;
  usePointUse: number;
}

interface UpdatedOrderDetails {
  [key: string]: OrderDetail;
}

const CShopDetailModal = ({
  show,
  onHide,
  orderId,
  refreshData,
  status,
}: ShopOrderModalProps) => {
  const [orderDetails, setOrderDetails] = useState<OrderDetail[]>([]);
  const [serviceFee, setServiceFee] = useState("");
  const [pointsApplied, setPointsApplied] = useState(0);
  const [shopData, setShopData] = useState<ShopData | null>(null);
  const [paymentDeadline, setPaymentDeadline] = useState("");
  const [exchangeRate, setExchangeRate] = useState(0);
  const [depositAmount, setDepositAmount] = useState("");
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [showKRWPModal, setShowKRWPModal] = useState(false);
  const [originalPoints, setOriginalPoints] = useState(
    orderDetails[0]?.user.point || 0
  );
  const showPaymentButtons = status === CODE_SHOPING_ORDER_STATUS.WAIT_PAY;
  const excludeStatuses = [3, 4, 5, 9, 10];
  useEffect(() => {
    console.log("Order status in modal:", status);
  }, [status]);

  useEffect(() => {
    setOriginalPoints(orderDetails[0]?.user.point || 0);
  }, [orderDetails]);

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const response = await reqShop({ order_id: orderId });
      } catch (error) {
        console.error("Error fetching order details:", error);
      }
    };

    if (orderId) {
      fetchOrderDetails();
    }
  }, [orderId]);

  const handleKRWPayment = () => {
    setShowKRWPModal(true);
  };

  const closeKRWPModal = () => {
    setShowKRWPModal(false);
  };

  const convertToUSD = (amount: number) => {
    return (amount / fixedExchangeRate).toFixed(2);
  };

  useEffect(() => {
    // fetchExchangeRate();
    fetchFixedExchangeRate();
    if (orderId) {
      fetchOrderDetails(orderId);
    }
  }, [orderId]);

  const handleAgreementChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTermsAgreed(e.target.checked);
  };

  useEffect(() => {
    const fetchOrderData = async () => {
      try {
        const response = await reqShop({ order_id: orderId });
        const data = response.data;
        setPaymentDeadline(data.paymentDeadline);
      } catch (error) {
        console.error("Error fetching order data:", error);
      }
    };

    fetchOrderData();
  }, [orderId]);

  // const fetchExchangeRate = () => {
  //   fetch(
  //     "https://quotation-api-cdn.dunamu.com/v1/forex/recent?codes=FRX.KRWUSD"
  //   )
  //     .then((response) => response.json())
  //     .then((data) => {
  //       const rate = data[0].basePrice;
  //       setExchangeRate(rate);
  //     })
  //     .catch((error) => console.error("Error fetching exchange rate:", error));
  // };

  const fetchFixedExchangeRate = async () => {
    try {
      const response = await reqShipFEE();
      if (response && response.data && response.data[0]?.fixedExchangeRateShop) {
        setFixedExchangeRate(parseFloat(response.data[0].fixedExchangeRateShop));
      }
    } catch (error) {
      console.error("Error fetching fixed exchange rate:", error);
    }
  };
  const [fixedExchangeRate, setFixedExchangeRate] = useState(0);

  useEffect(() => {
    if (orderId) {
      fetchOrderDetails(orderId);
    }
  }, [orderId]);

  const fetchOrderDetails = async (order_id: string) => {
    try {
      const detailResponse = await reqShopDetail({ order_id });
      const detailsWithStatus: OrderDetail[] = detailResponse.data.map(
        (item: OrderDetail) => ({
          ...item,
          isCanceled: item.status === CODE_SHOPING_ORDER_STATUS.CANCELED,
        })
      );
      setOrderDetails(detailsWithStatus);

      const shopResponse = await reqShop({ order_id });
      const feeValue = shopResponse.data.fee?.toString() ?? "";
      setServiceFee(feeValue);
      setShopData(shopResponse.data);
      if (shopResponse.data.paymentDeadline) {
        const formattedDeadline = new Date(shopResponse.data.paymentDeadline)
          .toISOString()
          .slice(0, 16);
        setPaymentDeadline(formattedDeadline);
      }
    } catch (error) {
      console.error("Error fetching order details:", error);
    }
  };

  const handleCancelOrderClick = () => {
    onHide();
  };

  const calculateTotal = () => {
    const productTotal = orderDetails.reduce((total, item) => {
      if (!item.isCanceled && !excludeStatuses.includes(item.status)) {
        return total + item.quantity * item.price;
      }
      return total;
    }, 0);

    const domesticShippingFee = orderDetails.reduce((total, item) => {
      if (!item.isCanceled && !excludeStatuses.includes(item.status)) {
        return total + item.ShippingFee;
      }
      return total;
    }, 0);

    const parsedServiceFee = parseFloat(serviceFee);
    let serviceFeeValue;
    if (!isNaN(parsedServiceFee) && parsedServiceFee > 0) {
      serviceFeeValue = Math.round(productTotal * (parsedServiceFee / 100));
    } else {
      serviceFeeValue =
        productTotal > 50000 ? Math.round(productTotal * 0.1) : 5000;
    }

    const paypalFee =
      (productTotal + serviceFeeValue + domesticShippingFee) * 0.05;
    const usePointKrw = shopData ? shopData.usePointKrw : 0;
    const usePointUse = shopData ? shopData.usePointUse : 0;

    const totalKrw =
      productTotal + domesticShippingFee + serviceFeeValue - usePointKrw;
    const totalUsd =
      productTotal +
      domesticShippingFee +
      serviceFeeValue +
      paypalFee -
      usePointUse;

    return {
      productTotal,
      domesticShippingFee,
      serviceFeeValue,
      paypalFee,
      totalKrw,
      totalUsd,
    };
  };

  const {
    productTotal,
    domesticShippingFee,
    serviceFeeValue,
    paypalFee,
    totalKrw,
    totalUsd,
  } = calculateTotal();

  const getStatusText = (status: number): string => {
    switch (status) {
      case 2:
        return "POSSIBILITY";
      case 3:
        return "EXPIRATION";
      case 4:
        return "RESTRICTION";
      case 5:
        return "SOLD_OUT";
      default:
        return "Unknown Status";
    }
  };

  const handleDepositAmountChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDepositAmount(e.target.value);
  };

  const handlePaymentSubmit = async () => {
    const inputDepositAmount = parseFloat(depositAmount);
    const totalAmount = totalKrw;

    if (inputDepositAmount !== totalAmount) {
      alert(
        "The deposit amount you entered does not match the total amount of payment. Please enter the correct amount."
      );
      return;
    }

    if (pointsApplied > originalPoints) {
      alert("Error: You cannot apply more points than you have.");
      return;
    }

    try {
      const updateData = {
        order_id: orderId,
        status: CODE_SHOPING_ORDER_STATUS.PAID,
        paymentConfirmed: true,
        paymentAmountKRW: inputDepositAmount,
        usePointKrw: pointsApplied,
      };

      await reqShopUpdate(updateData);

      const remainingPoints = originalPoints - pointsApplied;

      if (remainingPoints < 0) {
        console.error("Error: Attempting to set negative points for a user.");
        alert("Error updating points. Please check the applied points.");
        return;
      } else if (remainingPoints === 0) {
        console.log("Notice: All points have been used.");
      }

      await reqUpdateUserPoint({
        userId: orderDetails[0]?.user.id,
        point: remainingPoints,
      });

      alert("Payment information updated successfully.");
      closeKRWPModal();
      onHide();
      refreshData();
    } catch (error) {
      console.error("Update failed:", error);
      alert("Failed to update payment information.");
    }
  };

  return (
    <Fragment>
      <StyledModal show={show} onHide={onHide} centered>
        <StyledModal.Header className="bg-dark text-white">
          <Modal.Title>{orderId}</Modal.Title>
          <button className="btn-close-custom" onClick={onHide}>
            &#10006;
          </button>
        </StyledModal.Header>

        <StyledModal.Body>
          <div className="container-fluid mb-4">
            <Table bordered className="text-center">
              <thead>
                <tr>
                  <th>NO</th>
                  <th>category</th>
                  <th>Product name</th>
                  <th>Option 1</th>
                  <th>Option 2</th>
                  <th>Quantity</th>
                  <th>Unit Price</th>
                  <th>Shipping Fee</th>
                  <th>status</th>
                </tr>
              </thead>
              <tbody>
                {orderDetails.map((detail, index) => (
                  <tr
                    key={index}
                    style={
                      excludeStatuses.includes(detail.status)
                        ? { textDecoration: "line-through" }
                        : undefined
                    }
                  >
                    <th
                      style={
                        detail.isCanceled
                          ? { textDecoration: "line-through" }
                          : undefined
                      }
                    >
                      {detail.productId}
                    </th>
                    <td>{CODE_SHOPPING_ORDER_ITEMS[detail.category]}</td>
                    <td
                      style={
                        detail.isCanceled
                          ? { textDecoration: "line-through" }
                          : undefined
                      }
                    >
                      {detail.name}
                    </td>
                    <td
                      style={
                        detail.isCanceled
                          ? { textDecoration: "line-through" }
                          : undefined
                      }
                    >
                      {detail.option1}
                    </td>
                    <td
                      style={
                        detail.isCanceled
                          ? { textDecoration: "line-through" }
                          : undefined
                      }
                    >
                      {detail.option2}
                    </td>
                    <td
                      style={
                        detail.isCanceled
                          ? { textDecoration: "line-through" }
                          : undefined
                      }
                    >
                      {detail.quantity}
                    </td>
                    <td
                      style={
                        detail.isCanceled
                          ? { textDecoration: "line-through" }
                          : undefined
                      }
                    >
                      {detail.price}
                    </td>
                    <td
                      style={
                        detail.isCanceled
                          ? { textDecoration: "line-through" }
                          : undefined
                      }
                    >
                      {detail.ShippingFee}
                    </td>
                    <td
                      style={
                        detail.isCanceled
                          ? { textDecoration: "line-through" }
                          : undefined
                      }
                    >
                      {getStatusText(detail.status)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          <div className="container-fluid mb-3">
            <Table bordered className="text-center table-dark table-hover">
              <thead>
                <tr>
                  <th>Title</th>
                  <th>KRW</th>
                  <th>USD</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>Product total</th>
                  <td>{productTotal}</td>
                  <td>${convertToUSD(productTotal)}</td>
                </tr>
                <tr>
                  <th>Domestic Shipping Cost</th>
                  <td>{domesticShippingFee}</td>
                  <td>${convertToUSD(domesticShippingFee)}</td>
                </tr>
                <tr>
                  <th>Service Cost</th>
                  <td>{serviceFeeValue}</td>
                  <td>${convertToUSD(serviceFeeValue)}</td>
                </tr>
                <tr>
                  <th>Paypal Cost</th>
                  <th>-</th>
                  <td>${convertToUSD(paypalFee)}</td>
                </tr>
                <tr>
                  <th>point</th>
                  <td>{shopData?.usePointKrw || 0}</td>
                  <td>{convertToUSD(shopData?.usePointUse || 0)}</td>
                </tr>
                <tr>
                  <th>total</th>
                  <td>{totalKrw}</td>
                  <td>{convertToUSD(totalUsd)}</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </StyledModal.Body>
        <div className="mb-4">
          <div className="card">
            <div className="card-header">관리자 메시지</div>
            <div className="card-body">
              <blockquote className="blockquote mb-0">
                <p>{shopData?.fancallNumber || "관리자 메시지가 없습니다."}</p>
              </blockquote>
            </div>
          </div>
        </div>
        <div className="payment-deadline-container mb-3">
          <InputGroup>
            <InputGroup.Text>결제 마감일</InputGroup.Text>
            <Form.Control
              type="text"
              value={formatDate(paymentDeadline)}
              readOnly
            />
          </InputGroup>
        </div>
        <StyledModal.Footer>
          <Button variant="secondary" onClick={handleCancelOrderClick}>
            Close
          </Button>
        </StyledModal.Footer>
      </StyledModal>
    </Fragment>
  );
};
export default CShopDetailModal;

const StyledModal = styled(Modal)`
  .modal-dialog {
    max-width: 68%;
    margin: 5;
    font-size: 0.9em; // 모달 전체 폰트 사이즈 조정

    .table th,
    .table td {
      padding: 0.5rem; /* 기본값보다 작은 값으로 설정하여 간격을 줄입니다. */
    }
  }
`;
