import { AxiosManager } from "../axiosManager";
import { CONST_COOKIE, removeCookie } from "../common/cookies";
import { store } from "../redux/store";
import { tokenClear } from "../redux/tokenSlice";
import { userClear } from "../redux/userSlice";

interface PasswordResetParams {
  email: string;
  code: string;
  newPassword: string;
}

export const reqVerifyToken = async () => {
  return await AxiosManager.get("auth/verify/token");
};

export async function reqRefreshAccessToken(refreshToken: any) {
  return await AxiosManager.post(`auth/refresh`, { refreshToken });
}

export const reqLogin = async (payload: any) => {
  return await AxiosManager.post("auth/login", payload);
};

export const reqLogout = async () => {
  try {
    removeCookie(CONST_COOKIE.REFRESH_TOKEN);
    store.dispatch(userClear());
    store.dispatch(tokenClear());
    const result = await AxiosManager.post("auth/logout");

    return result;
  } catch (err) {}
};

export const reqUserRegister = async (payload: any) => {
  return await AxiosManager.post("auth/register", payload);
};

export const reqResetPassword = async (payload: any) => {
  return await AxiosManager.post("auth/reset-password", payload);
};

export const reqPasswordFind = async (payload: any) => {
  return await AxiosManager.post("auth/password/find", payload);
};

export const reqPasswordReset = async ({
  email,
  code,
  newPassword,
}: PasswordResetParams) => {
  return await AxiosManager.post("auth/password/reset", {
    email,
    code,
    newPassword,
  });
};

export const reqRegisterSendCode = async (payload: any) => {
  return await AxiosManager.post("auth/register/sendcode", payload);
};

export const reqRegisterConfirmCode = async (payload: any) => {
  return await AxiosManager.post("auth/register/confirmcode", payload);
};
