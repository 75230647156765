import { useState } from "react";
import {
  Button,
  Dropdown,
  DropdownToggle,
  FormControl,
  Table,
} from "react-bootstrap";
import FormCheckInput from "react-bootstrap/esm/FormCheckInput";
import FormCheckLabel from "react-bootstrap/esm/FormCheckLabel";
import styled from "styled-components";
import { modalOpen, modalRefresh } from "../../redux/modalSlice";
import { MODAL_TYPE } from "./GlobalModal";
import { useDispatch } from "react-redux";
import { reqAdminRegister } from "../../requests/admin-auth";

export default function ModalAdminRegister() {
  const dispatch = useDispatch();
  const [form, setForm] = useState<{
    name: string;
    email: string;
    phone: string;
    password: string;
    passwordConfirm: string;
    role: number;
  }>({
    name: "",
    email: "",
    phone: "",
    password: "",
    passwordConfirm: "",
    role: 0,
  });

  return (
    <>
      <AdminRegister>
        <Table>
          <tbody>
            <tr>
              <th>이름 *</th>
              <td>
                <FormControl
                  placeholder="관리자 이름"
                  value={form.name}
                  onChange={(e) => {
                    setForm({ ...form, name: e.target.value });
                  }}
                />
              </td>
            </tr>
            <tr>
              <th>이메일 *</th>
              <td>
                <FormControl
                  placeholder="이메일"
                  value={form.email}
                  onChange={(e) => {
                    setForm({ ...form, email: e.target.value });
                  }}
                />
              </td>
            </tr>
            <tr>
              <th>휴대전화 번호</th>
              <td>
                <FormControl
                  placeholder="휴대전화 번호"
                  value={form.phone}
                  onChange={(e) => {
                    setForm({ ...form, phone: e.target.value });
                  }}
                />
              </td>
            </tr>
            <tr>
              <th>비밀번호 *</th>
              <td>
                <FormControl
                  placeholder="비밀번호"
                  value={form.password}
                  onChange={(e) => {
                    setForm({ ...form, password: e.target.value });
                  }}
                />
                <FormControl
                  placeholder="비밀번호 확인"
                  value={form.passwordConfirm}
                  onChange={(e) => {
                    setForm({ ...form, passwordConfirm: e.target.value });
                  }}
                />
              </td>
            </tr>
            <tr>
              <th>권한 *</th>
              <td>
                <div>
                  <FormCheckInput
                    type="radio"
                    name="role"
                    id="role-1"
                    style={{ marginRight: "10px" }}
                    checked={form.role === 0}
                    onChange={(e) => {
                      setForm({ ...form, role: 0 });
                    }}
                  />
                  <FormCheckLabel htmlFor="role-1">일반 관리자</FormCheckLabel>
                </div>
                <div>
                  <FormCheckInput
                    type="radio"
                    name="role"
                    id="role-3"
                    style={{ marginRight: "10px" }}
                    checked={form.role === 3}
                    onChange={(e) => {
                      setForm({ ...form, role: 3 });
                    }}
                  />
                  <FormCheckLabel htmlFor="role-3">중간 관리자</FormCheckLabel>
                </div>
                <div>
                  <FormCheckInput
                    type="radio"
                    name="role"
                    id="role-2"
                    style={{ marginRight: "10px" }}
                    checked={form.role === 1}
                    onChange={(e) => {
                      setForm({ ...form, role: 1 });
                    }}
                  />
                  <FormCheckLabel htmlFor="role-2">최고 관리자</FormCheckLabel>
                </div>
              </td>
            </tr>
          </tbody>
        </Table>
        <div className="d-flex justify-content-end gap-2 mt-4">
          <Button variant="outline-dark">취소</Button>
          <Button
            variant="dark"
            onClick={async (e) => {
              await reqAdminRegister(form);
              dispatch(
                modalOpen({
                  modalType: MODAL_TYPE.INFO,
                  title: "등록 완료",
                  payload: { text: "관리자 계정이 등록되었습니다." },
                })
              );
              dispatch(modalRefresh());
            }}
          >
            등록
          </Button>
        </div>
      </AdminRegister>
    </>
  );
}

const AdminRegister = styled.div`
  td {
    padding: 5px;
    input {
      margin-bottom: 10px;
    }
  }

  button {
    width: 160px;
  }
`;
