import { motion } from "framer-motion";
import { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { modalClose } from "../../redux/modalSlice";
import Icon from "../Icon";
import ExtrackModal from "./ExtrackModal";
import ImagePreviewModal from "./ImagePreviewModal";
import ModalAdminRegister from "./ModalAdminRegister";
import ModalAdminRegisterDetail from "./ModalAdminRegisterDetail";
import ModalCheck from "./ModalCheck";
import ModalDataSave from "./ModalDataSave";
import ModalInfo from "./ModalInfo";
import ModalShipModify from "./ModalShipModify";
import ModalShipOrderDetail from "./ModalShipOrderDetail";
import ModalShipPayPayment from "./ModalShipPayPayment";
import ModalShipRequest from "./ModalShipRequest";
import CONFIRM from "./CONFIRM";
import { MHeader } from "../../common/mobile-styled";
import LandingReviewModal from "./LandingReviewModal";
import LandingBlogModal from "./LandingBlogModal";
import ModalSplit from "./ModalSplit";
import ModalGetSplit from "./ModalGetSplit";

export const MODAL_TYPE = {
  INFO: 0,
  DATA_SAVE: 1,
  DATA_DELETE: 2,
  CONFIRM: 3,

  SHIP_MODIFY: 20,
  SHIP_REQUEST: 21,
  SHIP_ORDER_DATAIL: 22,
  SHIP_PAY_PAYMENT: 23,
  SHIP_MODAL: 24,
  SHIP_CHECK: 25,
  LADING_REVIEW: 26,
  LADING_BLOG: 27,
  SPLIT: 28,
  SPLIT_MODIFY: 29,
  IMAGE_PREVIEW: 100,

  ADMIN_REGISTER: 9991,
  ADMIN_REGISTER_DETAIL: 9992,
};

interface Modal {
  type: number;
  width: string;
  component: any;
}

const modals: Modal[] = [
  {
    type: MODAL_TYPE.INFO,
    width: isMobile ? "90%" : "600px",
    component: <ModalInfo />,
  },
  {
    type: MODAL_TYPE.CONFIRM,
    width: "600px",
    component: <CONFIRM />,
  },
  {
    type: MODAL_TYPE.DATA_SAVE,
    width: "400px",
    component: <ModalDataSave />,
  },
  // 20
  {
    type: MODAL_TYPE.SHIP_MODIFY,
    width: isMobile ? "90%" : "800px",
    component: <ModalShipModify />,
  },
  {
    type: MODAL_TYPE.SHIP_MODAL,
    width: isMobile ? "90%" : "1200px",
    component: <ExtrackModal />,
  },
  {
    type: MODAL_TYPE.SPLIT_MODIFY,
    width: isMobile ? "90%" : "800px",
    component: <ModalGetSplit />,
  },
  {
    type: MODAL_TYPE.SHIP_REQUEST,
    width: isMobile ? "90%" : "1200px",
    component: <ModalShipRequest />,
  },
  {
    type: MODAL_TYPE.SHIP_CHECK,
    width: isMobile ? "95vw" : "40vw",
    component: <ModalCheck />,
  },
  {
    type: MODAL_TYPE.LADING_REVIEW,
    width: isMobile ? "95vw" : "30vw",
    component: <LandingReviewModal />,
  },
  {
    type: MODAL_TYPE.LADING_BLOG,
    width: isMobile ? "95vw" : "30vw",
    component: <LandingBlogModal />,
  },
  {
    type: MODAL_TYPE.SPLIT,
    width: isMobile ? "95vw" : "600px",
    component: <ModalSplit />,
  },

  {
    type: MODAL_TYPE.IMAGE_PREVIEW,
    width: "1200px",
    component: <ImagePreviewModal />,
  },
  {
    type: MODAL_TYPE.SHIP_ORDER_DATAIL,
    width: isMobile ? "90%" : "80%",
    component: <ModalShipOrderDetail />,
  },
  {
    type: MODAL_TYPE.SHIP_PAY_PAYMENT,
    width: isMobile ? "90%" : "80%",
    component: <ModalShipPayPayment />,
  },

  // 999
  {
    type: MODAL_TYPE.ADMIN_REGISTER,
    width: "800px",
    component: <ModalAdminRegister />,
  },
  {
    type: MODAL_TYPE.ADMIN_REGISTER_DETAIL,
    width: "800px",
    component: <ModalAdminRegisterDetail />,
  },
];

export default function GlobalModal() {
  const dispatch = useDispatch();
  const modal = useSelector((state: any) => state.modal);

  const findModal = modals.find((v) => v.type === modal.modalType);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        dispatch(modalClose());
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [dispatch]);

  const handleModalOverlayClick = (e: any) => {
    e.stopPropagation();
  };

  return (
    <>
      {modal.isOpen && (
        <>
          {isMobile ? (
            <MobileModalOverlay
              onClick={handleModalOverlayClick}
              initial={{ opacity: 0 }}
              animate={{ opacity: modal.isOpen ? 1 : 0 }}
            >
              <motion.div
                id="modal-box"
                style={{
                  width: findModal?.width,
                }}
                onClick={(e) => e.stopPropagation()}
                initial={{ y: "50vh" }}
                animate={{
                  y: modal.isOpen ? 0 : "50vh",
                }}
              >
                <MHeader>
                  <div>{modal.title}</div>
                  <Icon
                    style={{ cursor: "pointer" }}
                    icon="x-lg"
                    onClick={() => dispatch(modalClose())}
                  />
                </MHeader>

                <div style={{ padding: "0 1rem" }}>{findModal?.component}</div>
              </motion.div>
            </MobileModalOverlay>
          ) : (
            <ModalOverlay
              onClick={handleModalOverlayClick}
              initial={{ opacity: 0 }}
              animate={{ opacity: modal.isOpen ? 1 : 0 }}
            >
              <motion.div
                id="modal-box"
                style={{
                  width: findModal?.width,
                  maxWidth: "90%",
                  maxHeight: "90%",
                  padding: "30px",
                }}
                onClick={(e) => e.stopPropagation()}
                initial={{ y: "50vh" }}
                animate={{
                  y: modal.isOpen ? 0 : "50vh",
                }}
              >
                <div className="modal-titlebox">
                  <div className="modal-title">{modal.title}</div>
                  <Icon
                    fontSize="24px"
                    style={{ cursor: "pointer" }}
                    icon="x-lg"
                    onClick={() => dispatch(modalClose())}
                  />
                </div>

                <div id="modal-inner">{findModal?.component}</div>
              </motion.div>
            </ModalOverlay>
          )}
        </>
      )}
    </>
  );
}

const MobileModalOverlay = styled(motion.div)`
  z-index: 9990;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  max-width: 100%;
  max-height: 100%;
  background-color: #00000012;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);

  #modal-box {
    max-width: 90%;
    max-height: 90%;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }

    background-color: white;
    border-radius: 20px;
    box-shadow: 0px 4px 10px #00000012;
    display: flex;
    flex-direction: column;
  }
`;

const ModalOverlay = styled(motion.div)`
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  max-width: 100%;
  max-height: 100%;
  background-color: #00000012;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);

  #modal-box {
    background-color: white;
    border-radius: 20px;
    box-shadow: 0px 4px 10px #00000012;
    cursor: default;
    display: flex;
    flex-direction: column;
  }

  .modal-titlebox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* margin-bottom: 30px; */
  }
  .modal-title {
    font-size: 24px;
    font-weight: bold;
  }
  #modal-inner {
    margin-top: 20px;
    width: 100%;
    overflow-y: auto;
    max-height: 70vh;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .modal-close {
  }
`;
