import React, { useEffect, useState } from 'react';
import ShopWiseModal from '../../../components/modal/ShopWiseModal';
import WiseFancallModel from '../../../components/modal/WiseFancallModel';
import WisePreorderModal from '../../../components/modal/WisePreorderModal';
import { reqAShop } from '../../../requests/shop';
import { CODE_SHOPING_PRODUCT_STATUS } from '../../../common/constants';
import { formatDate } from '../../../common/format';
type OrderData = {
  orderId: string;
  userId: string;
  createdAt: string;
  paymentDeadline: string;
  preorder: boolean;
  status: number;
  fancall: boolean;
  totalPriceUSD: number;
  PaymentMethod: number;
};

const AShopPaypal: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const [showPreorderModal, setShowPreorderModal] = useState(false);
  const [showFancallModal, setShowFancallModal] = useState(false);
  const [currentOrder, setCurrentOrder] = useState<OrderData | null>(null);
  const [orders, setOrders] = useState<OrderData[]>([]);

  const handleOrderClick = (order: OrderData) => {
    setCurrentOrder(order);

    if (order.fancall) {
        setShowFancallModal(true);
    } else if (order.preorder) {
        setShowPreorderModal(true);
    } else {
        setShowModal(true);
    }
};

const refreshData = () => {
  fetchOrders();
};

  useEffect(() => {
    fetchOrders();
  }, []);

  const fetchOrders = async () => {
    try {
      const response = await reqAShop();
      const wiseOrders = response.data.filter((order: OrderData) =>
        order.status === CODE_SHOPING_PRODUCT_STATUS.WAIT_PAY && order.PaymentMethod === 2);
      setOrders(wiseOrders);
    } catch (error) {
      console.error('Error fetching orders:', error);
    }
  };

  return (
    <main id="main" className="main">
      <section>
        <div className="pagetitle">
          <h2>구매대행 결제대기중(paypal)</h2>
        </div>
        <div className="container-fluid">
          <table className="table table-hover text-center">
            <thead>
              <tr>
                <th>주문번호</th>
                <th>주문날자</th>
                <th>결제마감일</th>
                <th>스페이스코드</th>
                <th>합계금액</th>
                <th>특이사항</th>
              </tr>
            </thead>
            <tbody>
              {orders.map(order => (
                <tr key={order.orderId} onClick={() => handleOrderClick(order)} style={{ cursor: 'pointer' }}>
                  <th>{order.orderId}</th>
                  <td>{formatDate(order.createdAt)}</td>
                  <td>{formatDate(order.paymentDeadline)}</td>
                  <td>{order.orderId}</td>
                  <td>{order.totalPriceUSD} USD</td>
                  <td className={order.preorder ? "bg-warning" : order.fancall ? "bg-info" : ""}>
                    {order.preorder ? "Preorder" : order.fancall ? "Fancall" : '-'}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        </section>
            {currentOrder &&
                <>
                    {currentOrder.fancall &&
                        <WiseFancallModel
                            show={showFancallModal}
                            onHide={() => setShowFancallModal(false)}
                            orderId={currentOrder.orderId}
                            refreshData={refreshData}
                        />
                    }
                    {currentOrder.preorder &&
                        <WisePreorderModal
                            show={showPreorderModal}
                            onHide={() => setShowPreorderModal(false)}
                            orderId={currentOrder.orderId}
                            refreshData={refreshData}
                        />
                    }
                    {!currentOrder.fancall && !currentOrder.preorder &&
                        <ShopWiseModal
                            show={showModal}
                            onHide={() => setShowModal(false)}
                            orderId={currentOrder.orderId}
                            refreshData={refreshData}
                        />
                    }
                </>
            }
        </main>
    );
}

export default AShopPaypal;
