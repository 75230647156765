import { AxiosManager } from "../axiosManager";

export function reqShip(payload: any) {
  return AxiosManager.get(`ship/${payload.id}`);
}

export function reqAllShip() {
  return AxiosManager.get("ship");
}

export function reqShiprevenue(startDate: any) {
  return AxiosManager.get(`ship/revenue/${startDate}`);
}

export function requserShip(userId: string) {
  return AxiosManager.get(`ship/user/${userId}`);
}

export function reqAShip() {
  return AxiosManager.get(`product`);
}

export function reqAShipstatus() {
  return AxiosManager.get(`product/status`);
}

export function reqproduct(tracking: string) {
  return AxiosManager.get(`product/${tracking}`);
}

export function reqProductOfUser(userId: string) {
  return AxiosManager.get(`product/user/${userId}`);
}

export function reqShipSendOrder(payload: any) {
  return AxiosManager.post("ship/sendorder", payload);
}

export function reqShipUpdateOrder(payload: any) {
  return AxiosManager.put(`ship/${payload.id}/status`, payload);
}

export function reqShipUpdateOrders(payload: any) {
  return AxiosManager.put(`ship/${payload.id}/cancel`, payload);
}

export function reqShipOrderUpdate(payload: any) {
  return AxiosManager.put(`ship/${payload.id}`, payload);
}

export function reqShipProductUpdate(payload: any) {
  return AxiosManager.post("product/detail", payload);
}

export function reqlost(payload: any) {
  return AxiosManager.post("lost-shipment-requests", payload);
}

export function reqlostGet() {
  return AxiosManager.get("lost-shipment-requests");
}

export function reqLostDetail(id: number) {
  return AxiosManager.get(`lost-shipment-requests/${id}`);
}

export function reqShipProductDetailUpdate(payload: any) {
  return AxiosManager.post(`ship/details`, payload);
}

export function reqUploadShipOrderImage(id: string, file: any) {
  const formData = new FormData();
  formData.append("file", file);

  return AxiosManager.post(`ship/upload-image/${id}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function reqShipProductDetail(payload: any) {
  return AxiosManager.put(`ship/${payload.id}/details`, payload);
}

export function reqShipProductBox(payload: any) {
  return AxiosManager.put(`product/${payload.tracking}`, payload);
}

export function reqShipProductDelete(payload: any) {
  return AxiosManager.delete(`product/${payload.tracking}`, payload);
}

export function reqShipDetail(payload: any) {
  return AxiosManager.get(`ship/${payload.order_id}/details`);
}

export function reqShipCompleteForCron(payload: any) {
  return AxiosManager.post(`product/complete/cron/${payload.id}`);
}

export function reqShipFEE(payload?: any) {
  return AxiosManager.get("shipping-configurations", { params: payload });
}

export function reqShipFEEUpdate(id: any, payload: any) {
  return AxiosManager.put(`shipping-configurations/${id}`, payload);
}

export function reqShipZone(data: any) {
  return AxiosManager.post("zones", data);
}

export function reqShipDelete() {
  return AxiosManager.delete("zones");
}

export function reqShipZoneGet() {
  return AxiosManager.get("zones");
}

export function reqfilterByUserId(userId: string) {
  return AxiosManager.post(`product/filter/userId`, { userId });
}

export function reqfilterByTracking(tracking: string) {
  return AxiosManager.post(`product/filter/tracking`, { tracking });
}

export function reqfilterByDateRange(payload: {
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
}) {
  return AxiosManager.post(`product/filter/date-range`, payload);
}

export function reqfilterByStatusAndUserId(userId: string, status: number[]) {
  return AxiosManager.post("product/filter/status-and-user", {
    userId,
    status,
  });
}

export function reqAShipS(page = 1, limit = 10, status?: string) {
  return AxiosManager.get(
    `product/page?page=${page}&limit=${limit}&status=${status}`
  );
}

export function reqShipZoneUpdate(id: any, data: any) {
  return AxiosManager.put(`zones/${id}`, data);
}

export function reqShipTrackingGet(payload: any) {
  return AxiosManager.get(`ship/tracking/orders/${payload.id}`);
}

export function reqShipToAlarmSend(payload: any) {
  return AxiosManager.post("ship/send/alarm", payload);
}

export function reqProductDetail(payload: any) {
  return AxiosManager.get(`ship/product-detail/${payload.id}`);
}

export function reqPutExpress(payload: any) {
  return AxiosManager.put(`ship/details/${payload.id}`, payload);
}
