import React from "react";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { isMobile } from "react-device-detect";

const LandingHeader = () => {
  const { pathname } = useLocation();
  const user = useSelector((state: any) => state.user);

  return (
    <HeaderWrapper>
      {isMobile ? (
        <MobileHeaderContent>
          <div id="h-logo">
            <a href="/">
              <img src="/landing/images/head_logo_2.png" alt="logo" />
            </a>
          </div>

          <a href="/service">
            {pathname === "/service" ? (
              <div style={{ color: "var(--color-main-blue)" }}>SERVICE</div>
            ) : (
              <div style={{ color: "var(--color-black)" }}>SERVICE</div>
            )}
          </a>

          <a href="/price">
            {pathname === "/price" ? (
              <div style={{ color: "var(--color-main-blue)" }}>PRICING</div>
            ) : (
              <div style={{ color: "var(--color-black)" }}>PRICING</div>
            )}
          </a>

          {!user.id && (
            <a href="/signup" style={{ color: "var(--color-black)" }}>
              JOIN
            </a>
          )}
          <a href="/login" style={{ color: "var(--color-black)" }}>
            LOGIN
          </a>
        </MobileHeaderContent>
      ) : (
        <HeaderContent>
          <div>
            <div id="h-logo">
              <a href="/">
                <img src="/landing/images/head_logo_2.png" alt="logo" />
              </a>
            </div>

            <div id="h-nav">
              <a href="/service">
                {pathname === "/service" ? (
                  <div style={{ color: "var(--color-main-blue)" }}>SERVICE</div>
                ) : (
                  <div style={{ color: "var(--color-black)" }}>SERVICE</div>
                )}
              </a>

              <a href="/price">
                {pathname === "/price" ? (
                  <div style={{ color: "var(--color-main-blue)" }}>PRICING</div>
                ) : (
                  <div style={{ color: "var(--color-black)" }}>PRICING</div>
                )}
              </a>
            </div>

            <div id="h-join">
              {!user.id && (
                <a href="/signup" style={{ color: "var(--color-black)" }}>
                  Join
                </a>
              )}
              <a href="/login" style={{ color: "var(--color-black)" }}>
                Login
              </a>
            </div>
          </div>
        </HeaderContent>
      )}
    </HeaderWrapper>
  );
};

const HeaderContent = styled.div`
  width: 100%;
  background-color: var(--color-white);
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  z-index: 99;
  box-shadow: 0 4px 10px #00000012;

  & > div {
    width: 70%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    color: var(--color-black);
    background-color: var(--color-white);
    padding: 20px 0;
  }

  #h-logo {
    & > a > img {
      width: 67px;
      height: 40px;
    }
  }

  #h-nav {
    display: flex;
    gap: 50px;

    & > a > div:hover {
      color: var(--color-main-blue) !important;
    }
  }

  #h-join {
    display: flex;
    gap: 30px;

    & > a:hover {
      color: var(--color-main-blue) !important;
    }
  }
`;

const MobileHeaderContent = styled.div`
  display: flex;
  flex-direction: row;
  width: 100vw;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px;
  font-size: 16px;
  font-weight: bold;
  color: var(--color-black);
  background-color: var(--color-white);
  position: fixed;
  top: 0;
  z-index: 99;
  box-shadow: 0 4px 10px #00000012;

  #h-logo {
    & > a > img {
      width: 67px;
      height: 40px;
    }
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  width: 100%;
  text-align: center;
  justify-content: center;
`;

export default LandingHeader;
