import React from 'react';
import { Modal, Button } from 'react-bootstrap';

interface ConfirmationModalProps {
    show: boolean;
    onHide: () => void;
    onConfirm: (orderId: string) => void;
    Id: string;
}

export default function ConfirmationModal({ show, onHide, onConfirm, Id }: ConfirmationModalProps) {
    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title>배송 상태 변경</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                주문 {Id}를 배송완료로 변경하시겠습니까?
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>취소</Button>
                <Button variant="primary" onClick={() => onConfirm(Id)}>확인</Button>
            </Modal.Footer>
        </Modal>
    );
}
