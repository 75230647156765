import { useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { MEMBERSHIP } from "../../../common/constants";
import { reqGetAllUserById } from "../../../requests/user";
import { reqGetMembership } from "../../../requests/warehouse";
import AdminHeadTitle from "../../../components/AdminHeadTitle";
import { getAllPayments } from "../../../requests/payment";

interface User {
  id: string;
  name: string;
  country: number;
  email: string;
  point: number;
  latestWarehouseId: string;
  membershipId: number;
}

interface MembershipDetail {
  id: number;
  name: string;
  signupFeeRed: number;
  signupFeeGreen: number;
  signupFeeBlue: number;
  repurchaseDiscountRed: number;
  repurchaseDiscountGreen: number;
  repurchaseDiscountBule: number;
  serviceFeeRed: number;
  serviceFeeGreen: number;
  serviceFeeBlue: number;
  contentCouponRed: number;
  contentCouponGreen: number;
  contentCouponBlue: number;
  freeStorageDaysRed: number;
  freeStorageDaysGreen: number;
  freeStorageDaysBlue: number;
  version: number;
  [key: string]: number | string;
}

interface Payment {
  pk: number;
  type: string;
  userId: string;
  createdAt: string;
  paymentAmount: string;
  refundAmount: string;
  paypalTransactionId: string | null;
  primaryInfo: string | null;
}

const MembershipManagement = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [membershipCounts, setMembershipCounts] = useState<{
    [key: number]: number;
  }>({});
  const [showPolicyModal, setShowPolicyModal] = useState(false);
  const [showUsersModal, setShowUsersModal] = useState(false);
  const [selectedMembershipUsers, setSelectedMembershipUsers] = useState<
    User[]
  >([]);
  const [membershipDetails, setMembershipDetails] = useState<
    MembershipDetail[]
  >([]);
  const [editDetails, setEditDetails] = useState<MembershipDetail[]>([]);
  const [payments, setPayments] = useState<Payment[]>([]);
  const [visiblePayments, setVisiblePayments] = useState<Payment[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const PAYMENTS_PER_PAGE = 15;

  useEffect(() => {
    fetchAllUsers();
    fetchMembershipDetails();
    fetchPayments();
  }, []);

  useEffect(() => {
    loadMorePayments();
  }, [payments, currentPage]);

  const fetchAllUsers = async () => {
    try {
      const response = await reqGetAllUserById();
      setUsers(response.data);
      calculateMembershipCounts(response.data);
    } catch (error) {
      console.error("전체 사용자 정보 조회 실패:", error);
    }
  };

  const fetchMembershipDetails = async () => {
    try {
      const response = await reqGetMembership();
      setMembershipDetails(response.data);
      setEditDetails(response.data);
    } catch (error) {
      console.error("멤버쉽 정보 조회 실패:", error);
    }
  };

  const fetchPayments = async () => {
    try {
      const response = await getAllPayments();
      const sortedPayments = response.data
        .filter((payment: Payment) => payment.type === "membership")
        .sort(
          (a: Payment, b: Payment) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
      setPayments(sortedPayments);
    } catch (error) {
      console.error("결제 정보 조회 실패:", error);
    }
  };

  const handleMembershipCountClick = (membershipId: number) => {
    const selectedUsers = users.filter(
      (user) => user.membershipId === membershipId
    );
    setSelectedMembershipUsers(selectedUsers);
    setShowUsersModal(true);
  };

  const calculateMembershipCounts = (users: User[]) => {
    const counts: { [key: number]: number } = {};
    users.forEach((user) => {
      counts[user.membershipId] = (counts[user.membershipId] || 0) + 1;
    });
    setMembershipCounts(counts);
  };

  const loadMorePayments = () => {
    const startIndex = (currentPage - 1) * PAYMENTS_PER_PAGE;
    const endIndex = startIndex + PAYMENTS_PER_PAGE;
    setVisiblePayments((prevPayments) => [
      ...prevPayments,
      ...payments.slice(startIndex, endIndex),
    ]);
  };

  return (
    <main id="main" className="main">
      <AdminHeadTitle
        subtitle1="고객 관리"
        subtitle2="전체 고객 리스트"
        title="전체 고객 리스트"
      />
      <div>
        <div className="pagetitle" style={{ marginBottom: "20px" }}>
          <Button variant="primary" onClick={() => setShowPolicyModal(true)}>
            등급별 조정 정책 보기
          </Button>
        </div>
        <div className="container-fluid">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>멤버쉽</th>
                <th>회원수</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(membershipCounts).map((membershipId, index) => (
                <tr key={index}>
                  <td>{MEMBERSHIP[parseInt(membershipId)]}</td>
                  <td
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      handleMembershipCountClick(parseInt(membershipId))
                    }
                  >
                    {membershipCounts[parseInt(membershipId)]}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          <Table striped bordered hover>
            <thead>
              <tr>
                <th>유저 ID</th>
                <th>결제일</th>
                <th>결제 금액</th>
              </tr>
            </thead>
            <tbody>
              {visiblePayments.map((payment, index) => (
                <tr key={index}>
                  <td>{payment.userId}</td>
                  <td>{new Date(payment.createdAt).toLocaleString()}</td>
                  <td>{payment.paymentAmount} USD</td>
                </tr>
              ))}
            </tbody>
          </Table>
          {visiblePayments.length < payments.length && (
            <div className="text-center">
              <Button onClick={() => setCurrentPage(currentPage + 1)}>
                Load More
              </Button>
            </div>
          )}
        </div>
        <Modal
          show={showPolicyModal}
          onHide={() => setShowPolicyModal(false)}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>등급별 조정 정책</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-dialog modal-xl">
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className="modal-title fs-5">할인율 설정하기</h1>
                </div>
                <div className="modal-body">
                  {membershipDetails.map((detail, index) => (
                    <div key={index}>
                      <table className="table text-center">
                        <tr>
                          <th>항목</th>
                          <th>Red</th>
                          <th>Blue</th>
                          <th>Green</th>
                        </tr>
                        <tbody>
                          <tr>
                            <th>멤버쉽 가입비용</th>
                            <td>${detail.signupFeeRed}</td>
                            <td>${detail.signupFeeBlue}</td>
                            <td>${detail.signupFeeGreen}</td>
                          </tr>
                          <tr>
                            <th>리팩피 할인율</th>
                            <td>{detail.repurchaseDiscountRed}%</td>
                            <td>{detail.repurchaseDiscountBule}%</td>
                            <td>{detail.repurchaseDiscountGreen}%</td>
                          </tr>
                          <tr>
                            <th>구매대행 서비스 비용</th>
                            <td>{detail.serviceFeeRed}%</td>
                            <td>{detail.serviceFeeBlue}%</td>
                            <td>{detail.serviceFeeGreen}%</td>
                          </tr>
                          <tr>
                            <th>체크컨텐츠 쿠폰지급</th>
                            <td>{detail.contentCouponRed}</td>
                            <td>{detail.contentCouponBlue}</td>
                            <td>{detail.contentCouponGreen}</td>
                          </tr>
                          <tr>
                            <th>창고 무료 이용기한</th>
                            <td>{detail.freeStorageDaysRed}일</td>
                            <td>{detail.freeStorageDaysGreen}일</td>
                            <td>패키지당 30일</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  ))}
                </div>
                <hr />
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => setShowPolicyModal(false)}
                  >
                    Close
                  </Button>
                </Modal.Footer>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={showUsersModal}
          onHide={() => setShowUsersModal(false)}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>멤버쉽 유저 정보</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>이름</th>
                  <th>이메일</th>
                  <th>스페이스 코드</th>
                </tr>
              </thead>
              <tbody>
                {selectedMembershipUsers.map((user, index) => (
                  <tr key={index}>
                    <td>{user.name}</td>
                    <td>{user.email}</td>
                    <td>{user.id}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowUsersModal(false)}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </main>
  );
};

export default MembershipManagement;
