import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  CODE_SHOPPING_ORDER_STATUS,
  CODE_SHIPING_STATUS,
  CODE_COUNTRY_ITEMS,
} from "../common/constants";
import Icon from "./Icon";
import styled from "styled-components";

const AdminHeadFilter = ({
  selectStatus,
  selectStatusOption,
  selectViewPage,
  enterSearch,
  selectSearchOption,
}: any) => {
  const { pathname } = useLocation();

  const [viewFilter, setViewFilter] = useState<string>("");

  const [searchOptionFilter, setSearchOptionFilter] = useState<string>("");
  const [searchWordFilter, setSearchWordFilter] = useState<string>("");

  const [countryStatus, setCountryStatus] = useState<string>("");
  const [marketingAgreeStatus, setMarketingAgreeStatus] = useState<string>("");
  const [membershipStatus, setMembershipStatus] = useState<string>("");
  const [statusOptionListObject, setStatusOptionListObject] = useState<any>([]);
  const [statusListObject, setStatusListObject] = useState<object>({});

  const [searchOptionList, setSearchOptionList] = useState<string[]>([]);
  const [viewFilterList, setViewFilterList] = useState<object[]>([
    { name: "20개씩 보기", value: "20" },
    { name: "50개씩 보기", value: "50" },
    { name: "100개씩 보기", value: "100" },
    { name: "전체 보기", value: "1000000" },
  ]);

  const orderNumberSearchPageList = [
    "/admin/shop/order",
    "/admin/shop/cancel",
    "/admin/shop/pay/wise",
    "/admin/shop/paid",
    "/admin/ship/order",
    "/admin/ship/boxing",
    "/admin/invoice",
    "/admin/ship/pay",
    "/admin/ship/paid",
  ];

  const 국가_상태_리스트 = CODE_COUNTRY_ITEMS;
  const 마케팅_동의_상태_리스트 = { 0: "비동의", 1: "동의" };
  const 멤버십_상태_리스트 = { 1: "레드", 2: "블루", 3: "그린" };

  useEffect(() => {
    if (orderNumberSearchPageList.includes(pathname)) {
      setSearchOptionList(["주문 번호"]);
      setSearchOptionFilter("주문 번호");
    }
    if (pathname === "/admin/shop/details") {
      setStatusListObject(CODE_SHOPPING_ORDER_STATUS);
      setSearchOptionList(["주문 번호", "스페이스 코드"]);
      setSearchOptionFilter("주문 번호");
    }
    if (pathname === "/admin/ship/expired") {
      setSearchOptionList(["주문 번호", "스페이스 코드"]);
      setSearchOptionFilter("주문 번호");
    }
    if (pathname === "/admin/ship/details") {
      setStatusListObject(CODE_SHIPING_STATUS);
      setSearchOptionList(["주문 번호", "스페이스 코드"]);
      setSearchOptionFilter("주문 번호");
    }
    if (pathname === "/admin/member/list") {
      setStatusOptionListObject([
        국가_상태_리스트,
        마케팅_동의_상태_리스트,
        멤버십_상태_리스트,
      ]);
      setStatusListObject(국가_상태_리스트);
      setSearchOptionList(["스페이스 코드"]);
      setSearchOptionFilter("스페이스 코드");
    }
  }, []);

  const handleKeyPress = (event: any) => {
    if (event.key === " ") {
      event.preventDefault();
    }
  };

  return (
    <Wrapper>
      <Col>
        {pathname === "/admin/member/list" ? (
          <>
            <Row>
              {/* 상태 필터 */}
              {selectStatus && (
                <>
                  <Box className="status">
                    <label>국가</label>
                    <select
                      name="status-country"
                      className="input-reset dropdown"
                      value={countryStatus}
                      onChange={(e: any) => {
                        selectStatus(e.target.value);
                        selectStatusOption("국가");
                        setCountryStatus(e.target.value);
                        setMarketingAgreeStatus("");
                        setMembershipStatus("");
                      }}
                    >
                      <option value="0">전체</option>
                      {Object.entries(statusListObject).map(([key, value]) => {
                        return (
                          <option key={key} value={key}>
                            {value}
                          </option>
                        );
                      })}
                    </select>
                    <Icon
                      icon="caret-down-fill"
                      style={{ position: "absolute", top: "20%", right: "4%" }}
                    />
                  </Box>

                  <Box className="status">
                    <label>마케팅 동의</label>
                    <select
                      name="status-marketing"
                      className="input-reset dropdown"
                      value={marketingAgreeStatus}
                      onChange={(e: any) => {
                        selectStatus(e.target.value);
                        selectStatusOption("마케팅 동의");
                        setCountryStatus("0");
                        setMarketingAgreeStatus(e.target.value);
                        setMembershipStatus("");
                      }}
                    >
                      <option value="">전체</option>
                      {Object.entries(마케팅_동의_상태_리스트).map(
                        ([key, value]) => {
                          return (
                            <option key={key} value={key}>
                              {value}
                            </option>
                          );
                        }
                      )}
                    </select>
                    <Icon
                      icon="caret-down-fill"
                      style={{ position: "absolute", top: "20%", right: "4%" }}
                    />
                  </Box>

                  <Box className="status">
                    <label>멤버십</label>
                    <select
                      name="status-membership"
                      className="input-reset dropdown"
                      value={membershipStatus}
                      onChange={(e: any) => {
                        selectStatus(e.target.value);
                        selectStatusOption("멤버십");
                        setCountryStatus("0");
                        setMarketingAgreeStatus("");
                        setMembershipStatus(e.target.value);
                      }}
                    >
                      <option value="">전체</option>
                      {Object.entries(멤버십_상태_리스트).map(
                        ([key, value]) => {
                          return (
                            <option key={key} value={key}>
                              {value}
                            </option>
                          );
                        }
                      )}
                    </select>
                    <Icon
                      icon="caret-down-fill"
                      style={{ position: "absolute", top: "20%", right: "4%" }}
                    />
                  </Box>
                </>
              )}
            </Row>

            <Row>
              {/* 검색 */}
              {enterSearch && (
                <Box className="search">
                  <div id="search-opt-dropdown">
                    <select
                      name="view"
                      className="input-reset"
                      value={searchOptionFilter}
                      onChange={(e: any) => {
                        setSearchOptionFilter(e.target.value);
                        selectSearchOption(e.target.value);
                      }}
                    >
                      {searchOptionList.map((option: any, index) => {
                        return (
                          <option key={index} value={option}>
                            {option}
                          </option>
                        );
                      })}
                    </select>
                    <Icon
                      icon="caret-down-fill"
                      style={{ position: "absolute", top: "20%", right: "6%" }}
                    />
                  </div>

                  <div id="search-input">
                    <input
                      type="text"
                      className="input-reset"
                      placeholder={searchOptionFilter}
                      value={searchWordFilter}
                      onKeyPress={handleKeyPress}
                      onChange={(e: any) => {
                        const word = e.target.value.toUpperCase();
                        setSearchWordFilter(word);
                        enterSearch(word);
                      }}
                    />
                    <SearchButton>
                      <Icon icon="search" color="var(--color-main-blue)" />
                    </SearchButton>
                  </div>
                </Box>
              )}
            </Row>
          </>
        ) : (
          <Row>
            {/* 상태 필터 */}
            {selectStatus && (
              <Box className="status">
                <select
                  name="status"
                  className="input-reset dropdown"
                  onChange={(e: any) => {
                    selectStatus(e.target.value);
                  }}
                >
                  <option value="">전체</option>
                  {Object.entries(statusListObject).map(([key, value]) => {
                    return (
                      <option key={key} value={key}>
                        {value}
                      </option>
                    );
                  })}
                </select>
                <Icon
                  icon="caret-down-fill"
                  style={{ position: "absolute", top: "20%", right: "4%" }}
                />
              </Box>
            )}

            {/* 검색 */}
            {enterSearch && (
              <Box className="search">
                <div id="search-opt-dropdown">
                  <select
                    name="view"
                    className="input-reset"
                    value={searchOptionFilter}
                    onChange={(e: any) => {
                      setSearchOptionFilter(e.target.value);
                      selectSearchOption(e.target.value);
                    }}
                  >
                    {searchOptionList.map((option: any) => {
                      return <option value={option}>{option}</option>;
                    })}
                  </select>
                  <Icon
                    icon="caret-down-fill"
                    style={{ position: "absolute", top: "20%", right: "6%" }}
                  />
                </div>

                <div id="search-input">
                  <input
                    type="text"
                    className="input-reset"
                    placeholder={searchOptionFilter}
                    value={searchWordFilter}
                    onKeyPress={handleKeyPress}
                    onChange={(e: any) => {
                      const word = e.target.value.toUpperCase();
                      setSearchWordFilter(word);
                      enterSearch(word);
                    }}
                  />
                  <SearchButton>
                    <Icon icon="search" color="var(--color-main-blue)" />
                  </SearchButton>
                </div>
              </Box>
            )}
          </Row>
        )}

        <Row style={{ justifyContent: "right" }}>
          {/* 보기 필터 */}
          {selectViewPage && (
            <Box className="view">
              <select
                name="view"
                className="input-reset dropdown"
                value={viewFilter}
                onChange={(e: any) => {
                  setViewFilter(e.target.value);
                  selectViewPage(Number(e.target.value));
                }}
              >
                {viewFilterList.map((status: any, index) => {
                  return (
                    <option key={index} value={status.value}>
                      {status.name}
                    </option>
                  );
                })}
              </select>
              <Icon
                icon="caret-down-fill"
                style={{ position: "absolute", top: "20%", right: "6%" }}
              />
            </Box>
          )}
        </Row>
      </Col>
    </Wrapper>
  );
};

const SearchButton = styled.div`
  border: 1px solid var(--color-main-blue);
  padding: 0.4rem 1rem;
  align-items: center;
  border-top-right-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem;
  cursor: pointer;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;

  gap: 3rem;
  .status {
    flex: 1 1 0;
  }
  .search {
    flex: 3 1 0;
  }
  .view {
    width: 15vw;
  }
`;
const Col = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
const Box = styled.div`
  display: flex;
  border: 1px solid lightgray;
  border-radius: 0.4rem;
  position: relative;

  label {
    font-weight: bold;
    align-items: center;
    display: flex;
    width: 30%;
    padding: 0 1rem;
    border-right: 1px solid lightgray;
  }
  .input-reset {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0.4rem;
    width: 100%;
    border: none;
    padding: 0.4rem 0.8rem;
  }
  #search-opt-dropdown {
    position: relative;
    flex: 1 1 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 1px solid lightgray;
  }
  #search-opt-input {
    flex: 4 1 0;
    margin-left: 1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  #search-input {
    position: relative;
    display: flex;
    flex-direction: row;
    flex: 4 1 0;
    margin-left: 1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    & > input:focus {
      border-radius: 0;
    }
  }
`;
const Wrapper = styled.div`
  border-bottom: 1px solid lightgray;
  padding: 0 0 2rem 0;
  margin: 0 0 2rem 0;
`;

export default AdminHeadFilter;
