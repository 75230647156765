/* eslint-disable react-hooks/rules-of-hooks */
import Cookies from "js-cookie";
import { useEffect, useRef, useState } from "react";
import {
  Collapse,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
} from "react-bootstrap";
import { isMobile } from "react-device-detect";
import ReactMarkdown from "react-markdown";
import { useSelector } from "react-redux";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ROUTE_URL } from "../../common/constants";
import { formatDate } from "../../common/format";
import { MButton } from "../../common/mobile-styled";
import {
  reqDeleteAlarm,
  reqDeleteAllAlarm,
  reqEveryAlarm,
  reqReadSign,
} from "../../requests/alarm";
import { reqLogout, reqVerifyToken } from "../../requests/auth";
import { reqGetNowPopupList } from "../../requests/popup";
import { reqGetUserById } from "../../requests/user";
import CShopSendorder from "../../views/Customer/Shop/CShopSendorder";
import Icon from "../Icon";
import ProfileImg from "../ProfileImg";
import PopupModal from "../modal/PopupModal";
import "./CustomerLayout.css";
export default function CustomerLayout() {
  const nav = useNavigate();
  const location = useLocation();
  const user = useSelector((state: any) => state.user);
  const [userInfo, setUserInfo] = useState<any>();
  const [curUrl, setCurUrl] = useState<any>(location);

  const [sidebarOpen, setSidebarOpen] = useState<boolean>(
    isMobile ? false : true
  );
  const [menuOpen, setMenuOpen] = useState<{
    shop: boolean;
    ship: boolean;
    warehouse: boolean;
    blog: boolean;
    guide: boolean;
    Recomandation: boolean;
  }>({
    shop: false,
    ship: false,
    warehouse: false,
    blog: false,
    guide: false,
    Recomandation: false,
  });

  const verifyToken = async () => {
    try {
      const res = await reqVerifyToken();
      const { result, accessToken } = res.data;
      console.log(result, accessToken);
      if (!result && accessToken === null) {
        nav(ROUTE_URL.LOGIN);
        return;
      }
    } catch (error) {
      nav(ROUTE_URL.LOGIN);
      return;
    }
  };

  useEffect(() => {
    setCurUrl(location.pathname);
    fetchEveryAlarm();
    verifyToken();

    const pageElement = document.getElementById("cl-content-inner");
    if (pageElement) {
      pageElement.scrollTop = 0;
    }
  }, [location.pathname]);

  // alarm
  const [min, setMin] = useState<number>(0);
  const time = useRef(0);
  const timerId = useRef<any>(null);
  const [message, setMessage] = useState<any[]>([]);
  const [newMessage, setNewMessage] = useState<boolean>(false);
  const [alarmData, setAlarmData] = useState<any>([]);
  const [unreadAlarmData, setUnreadAlarmData] = useState<any>([]);

  const [showAlarmModal, setShowAlarmModal] = useState<boolean>(false);
  const [expandedAlarmId, setExpandedAlarmId] = useState(null);

  const sidebarRef = useRef<HTMLDivElement>(null);
  const closeSidebar = () => {
    if (isMobile) {
      setSidebarOpen(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target as Node)
      ) {
        closeSidebar();
      }
    };

    if (sidebarOpen && isMobile) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [sidebarOpen, isMobile]);

  useEffect(() => {
    timerId.current = setInterval(() => {
      time.current -= 1;
      if (time.current % 60 === 0) {
        setMin(time.current / 60);
      }
    }, 1000);
    //
    return () => clearInterval(timerId.current);
  }, []);

  const handleShowAlarmModal = () => {
    setShowAlarmModal(true);
  };

  const handleCloseAlarmModal = async () => {
    try {
      await fetchEveryAlarm();
    } catch (error) {
      console.error("Failed to fetch Alarm records:", error);
    }

    setShowAlarmModal(false);
  };

  useEffect(() => {
    if (time.current <= 0) {
      time.current = 300;
    }
  }, [min]);

  // useEffect(() => {
  // const refreshToken = getCookie(CONST_COOKIE.REFRESH_TOKEN);
  // if (!refreshToken) {
  //   nav(ROUTE_URL.LOGIN);
  // }
  // }, []);

  const fetchEveryAlarm = async () => {
    try {
      if (user.id !== "") {
        const response = await reqEveryAlarm({ userId: user.id });
        setAlarmData(response.data);
        const unreadAlarmList = response.data.filter(
          (alarm: any) => alarm.read === 0
        );
        setUnreadAlarmData(unreadAlarmList);
      }
    } catch (error) {
      console.error("Failed to fetch Alarm records:", error);
    }
  };

  useEffect(() => {
    fetchEveryAlarm();
    fetchUserInfo();
  }, [user]);

  const fetchUserInfo = async () => {
    const res = await reqGetUserById({ id: user.id });
    setUserInfo(res.data);
  };

  const toggleAlarm = (id: any) => {
    setExpandedAlarmId((prevId) => (prevId === id ? null : id));
  };

  const [showPopup, setShowPopup] = useState<any>(false);
  const [popupData, setPopupData] = useState<any>();

  useEffect(() => {
    const popupCookie = Cookies.get("hidePopup");
    if (!popupCookie) {
      fetchNowPopupList();
      setShowPopup(true);
    }
  }, []);

  const fetchNowPopupList = async () => {
    const res = await reqGetNowPopupList();
    setPopupData(res.data);
  };

  const handleAlarmListClick = async ({ target }: any) => {
    const deleteIconContainer = target.closest("[data-delete-icon]");

    if (deleteIconContainer) {
      const alarmId = deleteIconContainer
        .getAttribute("data-delete-icon")
        .replace("alarm-", "");
      await reqDeleteAlarm({ id: alarmId });
      const newAlarmData = alarmData.filter(
        (alarm: any) => alarm.id != alarmId
      );
      setAlarmData(newAlarmData);
    } else {
      const alarmTarget = target.closest('[id^="alarm-"]');
      if (alarmTarget) {
        const alarmId = alarmTarget.id.replace("alarm-", "");
        toggleAlarm(alarmId);

        const clickedAlarmIndex = alarmData.findIndex(
          (alarm: any) => alarm.id == alarmId
        );
        if (clickedAlarmIndex !== -1) {
          await reqReadSign({ id: alarmId });
          const newAlarmData = [...alarmData];
          newAlarmData[clickedAlarmIndex] = {
            ...newAlarmData[clickedAlarmIndex],
            read: true,
          };
          setAlarmData(newAlarmData);
        }
      }
    }
  };

  const handleAlarmDeleteClick = async () => {
    await reqDeleteAllAlarm({ userId: user.id });
    await fetchEveryAlarm();
    return;
  };

  const toggleMenu = (menuKey: keyof typeof menuOpen) => {
    setMenuOpen((prevMenuOpen) => ({
      shop: false,
      ship: false,
      warehouse: false,
      blog: false,
      guide: false,
      Recomandation: false,
      [menuKey]: !prevMenuOpen[menuKey],
    }));
  };

  const [createOrderModal, setCreateOrderModal] = useState<boolean>(false);

  const openCreateOrderModal = () => {
    setCreateOrderModal(false);
    setTimeout(() => setCreateOrderModal(true), 0);
  };

  return (
    <>
      {createOrderModal && (
        <CShopSendorder
          closeCreateOrderModal={() => setCreateOrderModal(false)}
        />
      )}

      <div
        className={[
          "cl-content",
          sidebarOpen ? "cl-content-narrow" : "cl-content-wide",
        ].join(" ")}
      >
        <div id="cl-content-inner">
          <Outlet />
        </div>
      </div>

      {/* Navigation*/}
      <div
        className={[
          "cl-sidebar",
          isMobile
            ? sidebarOpen
              ? "cl-sidebar-mobile"
              : "cl-sidebar-narrow"
            : sidebarOpen
            ? "cl-sidebar-wide"
            : "cl-sidebar-narrow",
        ].join(" ")}
        ref={sidebarRef}
      >
        <div className="cl-sidebar-inner">
          <ul className="cl-sidebar-ul">
            <li>
              <div
                className="cl-sidebar-menu"
                onClick={() => toggleMenu("shop")}
                style={{ color: menuOpen.shop ? "white" : "" }}
              >
                My Custom Orders
                {menuOpen.shop ? (
                  <Icon icon="caret-up" />
                ) : (
                  <Icon icon="caret-down" />
                )}
              </div>
              <Collapse in={menuOpen.shop}>
                <ul className="cl-sidebar-submenu">
                  <li
                    className={
                      curUrl === "/customer/shop/sendorder" ? "active" : ""
                    }
                    onClick={openCreateOrderModal}
                  >
                    Create Order Request
                  </li>
                  <li
                    className={
                      curUrl === "/customer/shop/order" ? "active" : ""
                    }
                    onClick={(e) => {
                      nav("/customer/shop/order");
                    }}
                  >
                    My Orders
                  </li>
                  <li
                    className={curUrl === "/customer/shop/pay" ? "active" : ""}
                    onClick={(e) => {
                      nav("/customer/shop/pay");
                    }}
                  >
                    Pending Payments
                  </li>
                  {/* <li
                    className={curUrl === "/customer/shop/paid" ? "active" : ""}
                    onClick={(e) => {
                      nav("/customer/shop/paid");
                    }}
                  >
                    Processing orders
                  </li>
                  <li
                    className={
                      curUrl === "/customer/shop/details" ? "active" : ""
                    }
                    onClick={(e) => {
                      nav("/customer/shop/details");
                    }}
                  >
                    Compleled orders
                  </li> */}
                </ul>
              </Collapse>
            </li>

            <li>
              <div
                className="cl-sidebar-menu"
                onClick={() => toggleMenu("ship")}
                style={{ color: menuOpen.ship ? "white" : "" }}
              >
                My Shipments
                {menuOpen.ship ? (
                  <Icon icon="caret-up" />
                ) : (
                  <Icon icon="caret-down" />
                )}
              </div>
              <Collapse in={menuOpen.ship}>
                <ul className="cl-sidebar-submenu">
                  <li
                    className={
                      curUrl === "/customer/ship/sendorder" ? "active" : ""
                    }
                    onClick={(e) => {
                      nav("/customer/ship/sendorder");
                    }}
                  >
                    Create Shipping Request
                  </li>
                  {/* <li
                    className={
                      curUrl === "/customer/ship/order" ? "active" : ""
                    }
                    onClick={(e) => {
                      nav("/customer/ship/order");
                    }}
                  >
                    My shipments
                  </li> */}
                  <li
                    className={
                      curUrl === "/customer/ship/details" ? "active" : ""
                    }
                    onClick={(e) => {
                      nav("/customer/ship/details");
                    }}
                  >
                    My Shipments
                  </li>
                  <li
                    className={curUrl === "/customer/ship/pay" ? "active" : ""}
                    onClick={(e) => {
                      nav("/customer/ship/pay");
                    }}
                  >
                    Pending Payments
                  </li>
                </ul>
              </Collapse>
            </li>

            <li>
              <div
                className="cl-sidebar-menu"
                onClick={() => toggleMenu("warehouse")}
                style={{ color: menuOpen.warehouse ? "white" : "" }}
              >
                My Storage
                {menuOpen.warehouse ? (
                  <Icon icon="caret-up" />
                ) : (
                  <Icon icon="caret-down" />
                )}
              </div>
              <Collapse in={menuOpen.warehouse}>
                <ul className="cl-sidebar-submenu">
                  <li
                    className={curUrl === "/customer/warehouse" ? "active" : ""}
                    onClick={(e) => {
                      nav("/customer/warehouse");
                    }}
                  >
                    All My Packages
                  </li>
                  <li
                    className={
                      curUrl === "/customer/warehouse/modify" ? "active" : ""
                    }
                    onClick={(e) => {
                      nav("/customer/warehouse/modify");
                    }}
                  >
                    My Package's Information
                  </li>
                  <li
                    className={
                      curUrl === "/customer/warehouse/checkcontents"
                        ? "active"
                        : ""
                    }
                    onClick={(e) => {
                      nav("/customer/warehouse/checkcontents");
                    }}
                  >
                    My Checked Items
                  </li>
                  <li
                    className={
                      curUrl === "/customer/warehouse/lost" ? "active" : ""
                    }
                    onClick={(e) => {
                      nav("/customer/warehouse/lost");
                    }}
                  >
                    Find My Tracking Number
                  </li>
                  {/* <li
                    onClick={(e) => {
                    className={
                      curUrl === "/customer/warehouse/split" ? "active" : ""
                    }
                      nav("/customer/warehouse/split");
                    }}
                  >
                    split
                  </li> */}
                </ul>
              </Collapse>
            </li>

            <li>
              <div
                className="cl-sidebar-menu"
                onClick={() => toggleMenu("blog")}
                style={{ color: menuOpen.blog ? "white" : "" }}
              >
                Review & Notice
                {menuOpen.blog ? (
                  <Icon icon="caret-up" />
                ) : (
                  <Icon icon="caret-down" />
                )}
              </div>
              <Collapse in={menuOpen.blog}>
                <ul className="cl-sidebar-submenu">
                  {/* <li
                    onClick={(e) => {
                    className={
                      curUrl === "/customer/service/blog" ? "active" : ""
                    }
                      nav("/customer/service/blog");
                    }}
                  >
                    Blog
                  </li> */}
                  <li
                    className={
                      curUrl === "/customer/review/every" ? "active" : ""
                    }
                    onClick={(e) => {
                      nav("/customer/review/every");
                    }}
                  >
                    <div>Review</div>
                  </li>
                  <li
                    className={
                      curUrl === "/customer/service/notice" ? "active" : ""
                    }
                    onClick={(e) => {
                      nav("/customer/service/notice");
                    }}
                  >
                    Notice
                  </li>
                </ul>
              </Collapse>
            </li>

            {/* <li
             className={
                      curUrl === "/customer/review/every" ? "active" : ""
                    }
              onClick={(e) => {
                nav("/customer/review/every");
              }}
            >
              <div className="cl-sidebar-menu">Review</div>
            </li> */}

            <li
              className={curUrl === "/customer/calculator" ? "active" : ""}
              onClick={(e) => {
                nav("/customer/calculator");
              }}
            >
              <div className="cl-sidebar-menu">Shipping Calculator</div>
            </li>

            <li
              className={curUrl === "/customer/support" ? "active" : ""}
              onClick={(e) => {
                nav("/customer/support");
              }}
            >
              <div className="cl-sidebar-menu">Q&A</div>
            </li>

            <li
              className={curUrl === "/customer/membership" ? "active" : ""}
              onClick={(e) => {
                nav("/customer/membership");
              }}
            >
              <div className="cl-sidebar-menu">Membership</div>
            </li>

            <li>
              <div
                className="cl-sidebar-menu"
                onClick={() => toggleMenu("guide")}
                style={{ color: menuOpen.guide ? "white" : "" }}
              >
                How To Use
                {menuOpen.guide ? (
                  <Icon icon="caret-up" />
                ) : (
                  <Icon icon="caret-down" />
                )}
              </div>
              <Collapse in={menuOpen.guide}>
                <ul className="cl-sidebar-submenu">
                  <li
                    className={
                      curUrl === "/customer/guide/shop" ? "active" : ""
                    }
                    onClick={(e) => {
                      nav("/customer/guide/shop");
                    }}
                  >
                    My Custom Orders
                  </li>
                  <li
                    className={
                      curUrl === "/customer/guide/storage" ? "active" : ""
                    }
                    onClick={(e) => {
                      nav("/customer/guide/storage");
                    }}
                  >
                    My shipping
                  </li>
                  <li
                    className={
                      curUrl === "/customer/guide/ship" ? "active" : ""
                    }
                    onClick={(e) => {
                      nav("/customer/guide/ship");
                    }}
                  >
                    My storage
                  </li>

                  {/* <li
                    className={curUrl === "/customer/guide/fee" ? "active" : ""}
                    onClick={(e) => {
                      nav("/customer/guide/fee");
                    }}
                  >
                    Pricing
                  </li> */}
                </ul>
              </Collapse>
            </li>
            {/* <li>
              <div
                className="cl-sidebar-menu"
                onClick={() => toggleMenu("Recomandation")}
                style={{ color: menuOpen.Recomandation ? "white" : "" }}
              >
                Recommendation Shop
                {menuOpen.Recomandation ? (
                  <Icon icon="caret-up" />
                ) : (
                  <Icon icon="caret-down" />
                )}
              </div>
              <Collapse in={menuOpen.Recomandation}>
                <ul className="cl-sidebar-submenu">
                  <li
                    onClick={(e) => {
                      nav("/customer/Recomandation/K-pop");
                    }}
                  >
                    K-pop
                  </li>
                  <li
                    onClick={(e) => {
                      nav("/customer/Recomandation/Stationary");
                    }}
                  >
                    Stationary
                  </li>
                  <li
                    onClick={(e) => {
                      nav("/customer/Recomandation/Food");
                    }}
                  >
                    Food
                  </li>
                  <li
                    onClick={(e) => {
                      nav("/customer/Recomandation/Beauty");
                    }}
                  >
                    Beauty
                  </li>
                  <li
                    onClick={(e) => {
                      nav("/customer/Recomandation/Fashion");
                    }}
                  >
                    Fashion
                  </li>
                  <li
                    onClick={(e) => {
                      nav("/customer/Recomandation/Comics");
                    }}
                  >
                    Comics
                  </li>
                </ul>
              </Collapse>
            </li> */}
          </ul>
        </div>
      </div>

      {/* 알림 모달 */}
      <Modal
        show={showAlarmModal}
        onHide={() => handleCloseAlarmModal()}
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          maxHeight: "90vh",
          overflowY: "hidden",
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Alarm</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ overflowY: "auto", maxHeight: "80vh" }}>
          <div>
            {alarmData.length > 0 ? (
              <>
                <div className="f-spb line f-center" style={{ width: "100%" }}>
                  <b>Alarm History</b>
                  <MButton
                    style={{
                      backgroundColor: "var(--color-main-red)",
                      color: "var(--color-white)",
                      cursor: "pointer",
                    }}
                    onClick={handleAlarmDeleteClick}
                  >
                    Delete All
                  </MButton>
                </div>

                <AlarmList onClick={handleAlarmListClick}>
                  {alarmData &&
                    alarmData.map((alarm: any) => (
                      <AlarmElement id={`alarm-${alarm.id}`} key={alarm.id}>
                        <AlarmTime>
                          {alarm.read ? (
                            <span data-delete-icon={`alarm-${alarm.id}`}>
                              <Icon icon="x-lg" color="grey" fontSize="12px" />
                            </span>
                          ) : (
                            <Icon
                              icon="circle-fill"
                              color="var(--color-main-red)"
                              fontSize="12px"
                            ></Icon>
                          )}
                          <span>{formatDate(alarm.createdAt)}</span>
                        </AlarmTime>
                        <AlarmContent expanded={expandedAlarmId == alarm.id}>
                          <b>Admin:</b>{" "}
                          <ReactMarkdown>{alarm.content}</ReactMarkdown>
                        </AlarmContent>
                      </AlarmElement>
                    ))}
                </AlarmList>
              </>
            ) : (
              "There's no unread alarm."
            )}
          </div>
        </Modal.Body>
      </Modal>

      {/*헤더부분*/}
      <div className="cl-header">
        <div
          style={{
            maxWidth: "60%",
            overflow: "hidden",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Icon
            icon="list"
            fontSize="2.6rem"
            style={{
              marginRight: "10px",
              marginLeft: "10px",
              cursor: "pointer",
              color: "var(--color-black)",
            }}
            onClick={(e) => {
              setSidebarOpen(!sidebarOpen);
            }}
          />
          <Link to="/customer" className="cl-header-ci">
            My Dashboard
          </Link>
        </div>

        <div className="cl-header-customer">
          {isMobile ? (
            <Dropdown className="cl-header-alarm">
              <DropdownToggle
                style={{
                  backgroundColor: "black",
                  border: "0px",
                  color: "white",
                  height: "50px",
                  display: "flex",
                  alignItems: "center",
                  position: "fixed",
                  top: "10.5%",
                  right: "5.5%",
                  borderRadius: "50%",
                }}
                variant=""
                onClick={() => {
                  handleShowAlarmModal();
                }}
              >
                <Icon
                  icon="bell-fill"
                  fontSize="26px"
                  style={{ paddingTop: "4px" }}
                />
                <div
                  style={{
                    position: "absolute",
                    top: "3px",
                    right: "0px",
                    backgroundColor: "var(--color-main-red)",
                    color: "white",
                    fontSize: "11px",
                    padding: "3px 7px 3px 7px",
                    borderRadius: "40px",
                    border: "1px solid white",
                    boxShadow:
                      "0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)",
                  }}
                >
                  {unreadAlarmData.length}
                </div>
              </DropdownToggle>
            </Dropdown>
          ) : (
            <Dropdown className="cl-header-alarm">
              <DropdownToggle
                style={{
                  backgroundColor: "transparent",
                  border: "0px",
                  color: "black",
                  height: "50px",
                  display: "flex",
                  alignItems: "center",
                }}
                variant=""
                onClick={() => {
                  handleShowAlarmModal();
                }}
              >
                <Icon
                  icon="bell-fill"
                  fontSize="26px"
                  style={{ paddingTop: "4px" }}
                />
                <div
                  style={{
                    position: "absolute",
                    top: "3px",
                    right: "0px",
                    backgroundColor: "var(--color-main-red)",
                    color: "white",
                    fontSize: "11px",
                    padding: "3px 7px 3px 7px",
                    borderRadius: "40px",
                    border: "1px solid white",
                  }}
                >
                  {unreadAlarmData.length}
                </div>
              </DropdownToggle>
            </Dropdown>
          )}

          <Dropdown>
            <DropdownToggle
              style={{
                backgroundColor: "transparent",
                border: "0px",
                color: "black",
                display: "flex",
                alignItems: "center",
                height: "50px",
              }}
            >
              <ProfileImg
                src={`https://kr.object.ncloudstorage.com/ontact-bucket/${userInfo?.imageUrl}`}
                width="33px"
              />
              <span style={{ marginLeft: "10px" }}>{user.name}</span>
            </DropdownToggle>
            <DropdownMenu align={"end"} className="cl-header-dropdown-div">
              <DropdownItem
                onClick={(e) => {
                  nav("/");
                }}
              >
                To Main
              </DropdownItem>
              <DropdownItem
                onClick={(e) => {
                  nav("/customer/settings");
                }}
              >
                Settings
              </DropdownItem>
              <DropdownItem
                onClick={async (e) => {
                  await reqLogout();
                  nav("/");
                }}
              >
                Logout
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>

      {/* 팝업 */}
      {popupData &&
        showPopup &&
        popupData.map((data: any) => (
          <PopupModal
            isAdmin={false}
            onHide={() => setShowPopup(false)}
            popupData={data}
          />
        ))}
    </>
  );
}

const AlarmList = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
`;

const AlarmElement = styled.li`
  margin-bottom: 4px;
  padding: 8px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
  /* display: flex;
  flex-direction: column; */
  &:last-child {
    border-bottom: none;
  }
`;

const AlarmTime = styled.div`
  font-size: 0.8rem;
  color: #666;
  display: flex;
  align-items: center;
  & > i {
    margin: 0 10px 0 0;
  }
  & > span > i {
    margin: 0 10px 0 0;
  }
`;

interface AlarmContentProps {
  expanded: boolean;
}

const AlarmContent = styled.div<AlarmContentProps>`
  margin-top: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${({ expanded }) =>
    expanded &&
    `
    white-space: pre-wrap;
    overflow: visible;
    text-overflow: clip;
  `}
`;

const Footer = styled.div`
  border-top: 1px solid #eee;
  display: flex;
  gap: 10px;
  padding: 10px;

  .footer-item {
    h5 {
      font-weight: bold;
    }

    ul {
      padding: 0;
      padding-left: 5px;
    }

    width: 100%;

    @media (max-width: 1279px) {
    }

    @media (max-width: 767px) {
    }

    @media (max-width: 575px) {
    }
  }

  .title {
  }

  @media (max-width: 1279px) {
  }

  @media (max-width: 767px) {
    flex-wrap: wrap;
  }

  @media (max-width: 575px) {
    flex-wrap: wrap;
  }
`;
