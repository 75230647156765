import { AxiosManager } from "../axiosManager";

// ProductSplit 생성
export function reqAddProductSplit(payload: any) {
  return AxiosManager.post("split", payload);
}

// 모든 ProductSplit 가져오기
export function reqAllProductSplit(payload: any) {
  return AxiosManager.get("split", {
    params: payload,
  });
}

// 단일 ProductSplit 가져오기
export function reqReadProductSplit(id: string) {
  return AxiosManager.get(`split/${id}`);
}

// 단일 유저 ProductSplit 가져오기
export function reqProductSplit(tracking: any) {
  return AxiosManager.get(`split/tracking/${tracking}`);
}

// ProductSplit 업데이트
export function reqUpdateProductSplit(id: string, payload: any) {
  return AxiosManager.put(`split/${id}`, payload);
}

// ProductSplit 모두 업데이트
export function reqUpdateProductSplitByTracking(
  tracking: string,
  payload: any
) {
  return AxiosManager.put(`split/tracking/${tracking}`, payload);
}

// ProductSplit 삭제
export function reqRemoveProductSplit(id: string) {
  return AxiosManager.delete(`split/${id}`);
}

export function reqUpdateProductSplitByTrack(tracking: string, payload: any) {
  return AxiosManager.put(`product/delete/${tracking}`, payload);
}

// ProductSplit 이미지 업로드
export function reqUploadProductSplitImage(id: string, file: File) {
  const formData = new FormData();
  formData.append("file", file);

  // FormData 내용 확인
  formData.forEach((value, key) => {
    console.log(`${key}: ${value}`);
  });

  return AxiosManager.post(`split/${id}/upload`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
