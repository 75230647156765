import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  reqCouponList,
  reqCreateCoupon,
  reqDeleteCoupon,
  reqGetCouponLog,
  reqModifyCoupon,
} from "../../../requests/coupon";
import {
  AUTO_RECEIVE_CONDITION,
  AUTO_RECEIVE_CONDITION_KR,
  COUPON_DUE_TYPE,
  DISCOUNT_TYPE,
  MEMBERSHIP,
  USE_CONDITION,
  USE_CONDITION_KR,
} from "../../../common/constants";
import Icon from "../../../components/Icon";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import AdminHeadTitle from "../../../components/AdminHeadTitle";
import { formatDate } from "../../../common/format";
interface Coupon {
  id?: number;
  name: string;
  isAutoReceive: boolean;
  autoReceiveCondition: string;
  discountType: string;
  percentDiscountPrice?: number;
  krwDiscountPrice?: number;
  usdDiscountPrice?: number;
  krwLimitOfUsePrice: number;
  usdLimitOfUsePrice: number;
  useCondition: string;
  validityType: string;
  validityDate: number;
  validityDurationStart?: Date;
  validityDurationEnd?: Date;
  target?: string;
  hashCode?: string;
}

const Toast = Swal.mixin({
  toast: true,
  position: "center",
  showConfirmButton: false,
  timer: 2000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

const initCouponData = {
  name: "",
  isAutoReceive: false,
  autoReceiveCondition: AUTO_RECEIVE_CONDITION["REGISTER"],
  discountType: DISCOUNT_TYPE["PERCENT"],
  percentDiscountPrice: 0,
  krwDiscountPrice: 0,
  usdDiscountPrice: 0,
  useCondition: USE_CONDITION["RATING_FEE"],
  krwLimitOfUsePrice: 0,
  usdLimitOfUsePrice: 0,
  validityType: COUPON_DUE_TYPE["FIXED"],
  validityDate: 0,
  validityDurationStart: new Date(),
  validityDurationEnd: new Date(),
  target: MEMBERSHIP[3],
};

export default function Acoupon() {
  const [createModal, setCreateModal] = useState<boolean>(false);
  const [modifyModal, setModifyModal] = useState<boolean>(false);
  const [logModal, setLogModal] = useState<boolean>(false);

  const [linkOption, setLinkOption] = useState<boolean>(false);
  const [conditionDropdown, setConditionDropdown] = useState<boolean>(false);

  //! fetch
  const [couponList, setCouponList] = useState<Coupon[]>();

  const fetchCouponList = async () => {
    try {
      const res = await reqCouponList();
      setCouponList(res.data);
    } catch (error) {}
  };

  useEffect(() => {
    fetchCouponList();
  }, []);

  // 특정 링크 클릭형 쿠폰 - 발급 대상 드롭다운
  useEffect(() => {
    setConditionDropdown(linkOption);
  }, [linkOption]);

  //! convert
  const convertToGood = (
    discountType: string,
    percentDiscountPrice?: number,
    krwDiscountPrice?: number,
    usdDiscountPrice?: number
  ) => {
    if (discountType === DISCOUNT_TYPE["PERCENT"]) {
      return `${percentDiscountPrice}% Discount`;
    }
    if (discountType === DISCOUNT_TYPE["FLAT"]) {
      return `${krwDiscountPrice?.toLocaleString("ko-KR")}₩ Discount`;
    }
  };

  const convertToDue = (
    validityType: string,
    validityDate?: number,
    validityDurationStart?: Date,
    validityDurationEnd?: Date
  ) => {
    if (validityType === COUPON_DUE_TYPE["FIXED"]) {
      return `After issuance ${validityDate}Work`;
    }
    if (validityType === COUPON_DUE_TYPE["DUE"]) {
      return `${
        validityDurationEnd
          ?.toLocaleString("ko-KR", {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour12: false,
          })
          .split(" ")[0]
      } 까지`;
    }
  };

  const convertToDate = (y: string, m: string, d: string) => {
    return `${y}-${m.padStart(2, "0")}-${d.padStart(2, "0")}`;
  };

  const convertToAutoRecevieCondition = (condition: string): string => {
    if (condition === AUTO_RECEIVE_CONDITION["REGISTER"]) {
      return AUTO_RECEIVE_CONDITION_KR["REGISTER"];
    }
    if (condition === AUTO_RECEIVE_CONDITION["ADD_RECOMMAND"]) {
      return AUTO_RECEIVE_CONDITION_KR["ADD_RECOMMAND"];
    }
    if (condition === AUTO_RECEIVE_CONDITION["RECEIVE_RECOMMAND"]) {
      return AUTO_RECEIVE_CONDITION_KR["RECEIVE_RECOMMAND"];
    }
    if (condition === AUTO_RECEIVE_CONDITION["LINK"]) {
      return AUTO_RECEIVE_CONDITION_KR["LINK"];
    }
    return "-";
  };

  const convertToUseCondition = (condition: string): string => {
    if (condition === USE_CONDITION["RATING_FEE"]) {
      return USE_CONDITION_KR["RATING_FEE"];
    }
    if (condition === USE_CONDITION["CHECK_CONTENTS"]) {
      return USE_CONDITION_KR["CHECK_CONTENTS"];
    }
    if (condition === USE_CONDITION["SHOP_SERVICE_FEE"]) {
      return USE_CONDITION_KR["SHOP_SERVICE_FEE"];
    }
    if (condition === USE_CONDITION["MEMBERSHIP"]) {
      return USE_CONDITION_KR["MEMBERSHIP"];
    }
    return "-";
  };

  //! 값 검증
  const isValidCoupon = () => {
    if (
      couponData.name === "" ||
      (couponData.validityType === COUPON_DUE_TYPE["DUE"] &&
        couponData.validityDurationEnd === null)
    ) {
      return false;
    }
    return true;
  };

  //! Coupon Data
  const [couponData, setCouponData] = useState<Coupon>(initCouponData);

  //! Event
  const handleModifyModal = (index: number, couponId: number | undefined) => {
    setModifyModal(true);
    if (couponList) {
      setCouponData(couponList[index]);
    }
  };

  const handleLogModal = async (
    index: number,
    couponId: number | undefined
  ) => {
    setLogModal(true);
    if (couponList) {
      setCouponData(couponList[index]);
      const res = await reqGetCouponLog({ couponId: couponList[index].id });
      setCouponLogs(res.data);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setCouponData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setCouponData((prevData) => {
      let newValue: any = value;

      if (name === "isAutoReceive") {
        newValue = value === "true";
      } else {
        newValue = value;
      }
      return {
        ...prevData,
        [name]: newValue,
      };
    });
  };

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setCouponData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // 특정 링크 클릭형 쿠폰
    if (name === "autoReceiveCondition") {
      setLinkOption(value === AUTO_RECEIVE_CONDITION["LINK"]);
    }
  };

  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");
  const [day, setDay] = useState("");

  const handleYearChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setYear(e.target.value);
  };

  const handleMonthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMonth(e.target.value);
  };

  const handleDayChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDay(e.target.value);
  };

  const handleSubmit = async () => {
    console.log(couponData);
    if (!isValidCoupon()) {
      Toast.fire({
        icon: "warning",
        title: "유효하지 않은 쿠폰입니다!",
      });
      return;
    }
    Swal.fire({
      title: "쿠폰을 생성하시겠습니까?",
      text: "",
      icon: "question",

      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "승인",
      cancelButtonText: "취소",

      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const readyCouponData = {
          ...couponData,
          validityDurationEnd: new Date(convertToDate(year, month, day)),
        };

        await reqCreateCoupon(readyCouponData);

        Swal.fire("쿠폰을 생성했습니다.", "", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    });
  };

  const handleModify = async () => {
    console.log(couponData);
    if (!isValidCoupon()) {
      Toast.fire({
        icon: "warning",
        title: "유효하지 않은 쿠폰입니다!",
      });
      return;
    }
    Swal.fire({
      title: "쿠폰을 수정하시겠습니까?",
      text: "",
      icon: "question",

      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "승인",
      cancelButtonText: "취소",

      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const readyCouponData = {
          ...couponData,
          validityDurationEnd: new Date(convertToDate(year, month, day)),
        };

        await reqModifyCoupon(readyCouponData);

        Swal.fire("쿠폰을 수정했습니다.", "", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    });
  };

  const handleDelete = async () => {
    Swal.fire({
      title: "쿠폰을 삭제하시겠습니까?",
      text: "되돌릴 수 없는 작업입니다.",
      icon: "warning",

      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "승인",
      cancelButtonText: "취소",

      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await reqDeleteCoupon({ couponId: couponData.id });

        Swal.fire("쿠폰을 삭제했습니다.", "", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    });
  };

  //! 내역 보기
  const [couponLogs, setCouponLogs] = useState<any>();

  return (
    <Wrapper>
      {/* 모달 */}
      {createModal && (
        <ModalBackgound>
          <ModalWrapper>
            <ModalHeader>
              <div>쿠폰 추가하기</div>
              <Icon
                icon="x-lg"
                fontSize="2rem"
                onClick={() => setCreateModal(false)}
              />
            </ModalHeader>

            <ModalBody>
              {/* 이름 */}
              <label>이름</label>
              <ModalInput
                name="name"
                value={couponData.name}
                onChange={handleInputChange}
              ></ModalInput>

              {/* 자동 지급 */}
              <label>자동 지급</label>
              <Box>
                <RadioRow>
                  <div>
                    <label>사용</label>
                    <input
                      type="radio"
                      name="isAutoReceive"
                      checked={couponData.isAutoReceive === true}
                      value="true"
                      onChange={handleRadioChange}
                    />
                  </div>
                  <div>
                    <label>미사용</label>
                    <input
                      type="radio"
                      name="isAutoReceive"
                      checked={couponData.isAutoReceive === false}
                      value="false"
                      onChange={handleRadioChange}
                    />
                  </div>
                </RadioRow>
              </Box>

              {/* (지급 조건) */}
              {couponData.isAutoReceive && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    position: "relative",
                  }}
                >
                  <label>지급 조건</label>
                  <Dropdown
                    name="autoReceiveCondition"
                    value={couponData.autoReceiveCondition}
                    onChange={handleSelectChange}
                  >
                    <option value={AUTO_RECEIVE_CONDITION["REGISTER"]}>
                      {AUTO_RECEIVE_CONDITION_KR["REGISTER"]}
                    </option>
                    <option value={AUTO_RECEIVE_CONDITION["ADD_RECOMMAND"]}>
                      {AUTO_RECEIVE_CONDITION_KR["ADD_RECOMMAND"]}
                    </option>
                    <option value={AUTO_RECEIVE_CONDITION["RECEIVE_RECOMMAND"]}>
                      {AUTO_RECEIVE_CONDITION_KR["RECEIVE_RECOMMAND"]}
                    </option>
                    <option value={AUTO_RECEIVE_CONDITION["LINK"]}>
                      {AUTO_RECEIVE_CONDITION_KR["LINK"]}
                    </option>
                    <option value={AUTO_RECEIVE_CONDITION["MEMBERSHIP"]}>
                      {AUTO_RECEIVE_CONDITION_KR["MEMBERSHIP"]}
                    </option>
                  </Dropdown>
                  <Icon
                    icon="caret-down-fill"
                    style={{ position: "absolute", top: "66%", right: "6%" }}
                  />
                </div>
              )}

              {/* 특정 링크 클릭형 쿠폰일 경우 - 대상 조건 설정 */}
              {conditionDropdown && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    position: "relative",
                  }}
                >
                  <label>발급 대상</label>
                  <Dropdown
                    name="target"
                    value={couponData.target}
                    onChange={handleSelectChange}
                  >
                    <option value={MEMBERSHIP[3]}>{MEMBERSHIP[3]}</option>
                    <option value={MEMBERSHIP[2]}>{MEMBERSHIP[2]}</option>
                    <option value={MEMBERSHIP[1]}>{MEMBERSHIP[1]}</option>
                    <option value={MEMBERSHIP[0]}>전체</option>
                  </Dropdown>
                  <Icon
                    icon="caret-down-fill"
                    style={{ position: "absolute", top: "66%", right: "6%" }}
                  />
                </div>
              )}

              {/* 혜택 */}
              <label>혜택</label>
              <Box>
                <RadioRow>
                  <div>
                    <label>정률 할인</label>
                    <input
                      type="radio"
                      name="discountType"
                      checked={
                        couponData.discountType === DISCOUNT_TYPE["PERCENT"]
                      }
                      value={DISCOUNT_TYPE["PERCENT"]}
                      onChange={handleRadioChange}
                    />
                  </div>
                  <div>
                    <label>정액 할인</label>
                    <input
                      type="radio"
                      name="discountType"
                      checked={
                        couponData.discountType === DISCOUNT_TYPE["FLAT"]
                      }
                      value={DISCOUNT_TYPE["FLAT"]}
                      onChange={handleRadioChange}
                    />
                  </div>
                </RadioRow>
              </Box>

              {/* (금액) */}
              <label>금액</label>
              {couponData.discountType === DISCOUNT_TYPE["PERCENT"] ? (
                <Row
                  style={{
                    alignItems: "center",
                  }}
                >
                  <ModalInput
                    type="text"
                    style={{ flexGrow: "9" }}
                    name="percentDiscountPrice"
                    value={couponData.percentDiscountPrice}
                    onChange={handleInputChange}
                    onFocus={(e) => {
                      if (e.target.value === "0") e.target.value = "";
                    }}
                  />
                  <P
                    style={{
                      flexGrow: "1",
                      fontWeight: "bold",
                      textAlign: "right",
                    }}
                  >
                    %
                  </P>
                </Row>
              ) : (
                <Row
                  style={{
                    alignItems: "center",
                  }}
                >
                  <ModalInput
                    type="text"
                    style={{ width: "45%" }}
                    name="krwDiscountPrice"
                    value={couponData.krwDiscountPrice}
                    onChange={handleInputChange}
                    onFocus={(e) => {
                      if (e.target.value === "0") e.target.value = "";
                    }}
                  />
                  <P
                    style={{
                      fontWeight: "bold",
                      textAlign: "right",
                      marginLeft: ".4rem",
                      marginRight: "1rem",
                    }}
                  >
                    ₩
                  </P>

                  <ModalInput
                    type="text"
                    style={{ width: "45%" }}
                    name="usdDiscountPrice"
                    value={couponData.usdDiscountPrice}
                    onChange={handleInputChange}
                    onFocus={(e) => {
                      if (e.target.value === "0") e.target.value = "";
                    }}
                  />
                  <P
                    style={{
                      fontWeight: "bold",
                      textAlign: "right",
                      marginLeft: ".4rem",
                    }}
                  >
                    $
                  </P>
                </Row>
              )}

              {/* 사용 금액 제한 */}
              <label>사용 금액 제한 (~ 이상시 사용 가능)</label>
              <Row
                style={{
                  alignItems: "center",
                }}
              >
                <ModalInput
                  type="text"
                  style={{ width: "45%" }}
                  name="krwLimitOfUsePrice"
                  value={couponData.krwLimitOfUsePrice}
                  onChange={handleInputChange}
                  onFocus={(e) => {
                    if (e.target.value === "0") e.target.value = "";
                  }}
                />
                <P
                  style={{
                    fontWeight: "bold",
                    textAlign: "right",
                    marginLeft: ".4rem",
                    marginRight: "1rem",
                  }}
                >
                  ₩
                </P>

                <ModalInput
                  type="text"
                  style={{ width: "45%" }}
                  name="usdLimitOfUsePrice"
                  value={couponData.usdLimitOfUsePrice}
                  onChange={handleInputChange}
                  onFocus={(e) => {
                    if (e.target.value === "0") e.target.value = "";
                  }}
                />
                <P
                  style={{
                    fontWeight: "bold",
                    textAlign: "right",
                    marginLeft: ".4rem",
                  }}
                >
                  $
                </P>
              </Row>

              {/* 사용 조건 */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                }}
              >
                <label>사용 조건</label>
                <Dropdown
                  name="useCondition"
                  value={couponData.useCondition}
                  onChange={handleSelectChange}
                >
                  <option value={USE_CONDITION["RATING_FEE"]}>
                    {USE_CONDITION_KR["RATING_FEE"]}
                  </option>
                  <option value={USE_CONDITION["CHECK_CONTENTS"]}>
                    {USE_CONDITION_KR["CHECK_CONTENTS"]}
                  </option>
                  <option value={USE_CONDITION["SHOP_SERVICE_FEE"]}>
                    {USE_CONDITION_KR["SHOP_SERVICE_FEE"]}
                  </option>
                  <option value={USE_CONDITION["MEMBERSHIP"]}>
                    {USE_CONDITION_KR["MEMBERSHIP"]}
                  </option>
                </Dropdown>
                <Icon
                  icon="caret-down-fill"
                  style={{ position: "absolute", top: "66%", right: "6%" }}
                />
              </div>

              {/* 유효 기간 */}
              <label>유효 기간</label>
              <Box>
                <RadioRow>
                  <div>
                    <label>N일 후</label>
                    <input
                      type="radio"
                      name="validityType"
                      checked={
                        couponData.validityType === COUPON_DUE_TYPE["FIXED"]
                      }
                      value={COUPON_DUE_TYPE["FIXED"]}
                      onChange={handleRadioChange}
                    />
                  </div>
                  <div>
                    <label>정해진 기간</label>
                    <input
                      type="radio"
                      name="validityType"
                      checked={
                        couponData.validityType === COUPON_DUE_TYPE["DUE"]
                      }
                      value={COUPON_DUE_TYPE["DUE"]}
                      onChange={handleRadioChange}
                    />
                  </div>
                </RadioRow>
              </Box>

              {/* (기간 설정) */}
              {couponData.validityType === COUPON_DUE_TYPE["FIXED"] ? (
                <Row
                  style={{
                    alignItems: "center",
                    marginTop: "1.8rem",
                  }}
                >
                  <ModalInput
                    type="text"
                    style={{ flexGrow: "9" }}
                    name="validityDate"
                    value={couponData.validityDate}
                    onChange={handleInputChange}
                    onFocus={(e) => {
                      if (e.target.value === "0") e.target.value = "";
                    }}
                  />
                  <P
                    style={{
                      flexGrow: "1",
                      fontWeight: "bold",
                      textAlign: "right",
                    }}
                  >
                    일 후
                  </P>
                </Row>
              ) : (
                <DateDueRow>
                  <input
                    type="text"
                    placeholder="YYYY"
                    maxLength={4}
                    value={year}
                    onChange={handleYearChange}
                  />
                  <P>-</P>
                  <input
                    type="text"
                    placeholder="MM"
                    maxLength={2}
                    value={month}
                    onChange={handleMonthChange}
                  />
                  <P>-</P>
                  <input
                    type="text"
                    placeholder="DD"
                    maxLength={2}
                    value={day}
                    onChange={handleDayChange}
                  />
                  <P> 까지</P>
                </DateDueRow>
              )}
            </ModalBody>

            <ModalFooter>
              <Button
                style={{ marginTop: "2rem", width: "100%" }}
                onClick={() => handleSubmit()}
              >
                추가하기
              </Button>
            </ModalFooter>
          </ModalWrapper>
        </ModalBackgound>
      )}
      {modifyModal && (
        <ModalBackgound>
          <ModalWrapper>
            <ModalHeader>
              <div>쿠폰 수정하기</div>
              <Icon
                icon="x-lg"
                fontSize="2rem"
                onClick={() => setModifyModal(false)}
              />
            </ModalHeader>

            <ModalBody>
              {/* 이름 */}
              <label>이름</label>
              <ModalInput
                name="name"
                value={couponData.name}
                onChange={handleInputChange}
              ></ModalInput>

              {/* 링크 */}
              <label style={{ color: "var(--color-main-blue)" }}>링크</label>
              <ModalInput
                name="link"
                value={`/event-coupon/${couponData.hashCode}`}
              ></ModalInput>

              {/* 자동 지급 */}
              <label>자동 지급</label>
              <Box>
                <RadioRow>
                  <div>
                    <label>사용</label>
                    <input
                      type="radio"
                      name="isAutoReceive"
                      checked={couponData.isAutoReceive === true}
                      value="true"
                      onChange={handleRadioChange}
                    />
                  </div>
                  <div>
                    <label>미사용</label>
                    <input
                      type="radio"
                      name="isAutoReceive"
                      checked={couponData.isAutoReceive === false}
                      value="false"
                      onChange={handleRadioChange}
                    />
                  </div>
                </RadioRow>
              </Box>

              {/* (지급 조건) */}
              {couponData.isAutoReceive && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    position: "relative",
                  }}
                >
                  <label>지급 조건</label>
                  <Dropdown
                    name="autoReceiveCondition"
                    value={couponData.autoReceiveCondition}
                    onChange={handleSelectChange}
                  >
                    <option value={AUTO_RECEIVE_CONDITION["REGISTER"]}>
                      {AUTO_RECEIVE_CONDITION_KR["REGISTER"]}
                    </option>
                    <option value={AUTO_RECEIVE_CONDITION["ADD_RECOMMAND"]}>
                      {AUTO_RECEIVE_CONDITION_KR["ADD_RECOMMAND"]}
                    </option>
                    <option value={AUTO_RECEIVE_CONDITION["RECEIVE_RECOMMAND"]}>
                      {AUTO_RECEIVE_CONDITION_KR["RECEIVE_RECOMMAND"]}
                    </option>
                    <option value={AUTO_RECEIVE_CONDITION["LINK"]}>
                      {AUTO_RECEIVE_CONDITION_KR["LINK"]}
                    </option>
                  </Dropdown>
                  <Icon
                    icon="caret-down-fill"
                    style={{ position: "absolute", top: "66%", right: "6%" }}
                  />
                </div>
              )}

              {/* 특정 링크 클릭형 쿠폰일 경우 - 대상 조건 설정 */}
              {(conditionDropdown ||
                couponData.autoReceiveCondition ===
                  AUTO_RECEIVE_CONDITION["LINK"]) && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    position: "relative",
                  }}
                >
                  <label>발급 대상</label>
                  <Dropdown
                    name="target"
                    value={couponData.target}
                    onChange={handleSelectChange}
                  >
                    <option value={MEMBERSHIP[3]}>{MEMBERSHIP[3]}</option>
                    <option value={MEMBERSHIP[2]}>{MEMBERSHIP[2]}</option>
                    <option value={MEMBERSHIP[1]}>{MEMBERSHIP[1]}</option>
                    <option value={MEMBERSHIP[0]}>전체</option>
                  </Dropdown>
                  <Icon
                    icon="caret-down-fill"
                    style={{ position: "absolute", top: "66%", right: "6%" }}
                  />
                </div>
              )}

              {/* 혜택 */}
              <label>혜택</label>
              <Box>
                <RadioRow>
                  <div>
                    <label>정률 할인</label>
                    <input
                      type="radio"
                      name="discountType"
                      checked={
                        couponData.discountType === DISCOUNT_TYPE["PERCENT"]
                      }
                      value={DISCOUNT_TYPE["PERCENT"]}
                      onChange={handleRadioChange}
                    />
                  </div>
                  <div>
                    <label>정액 할인</label>
                    <input
                      type="radio"
                      name="discountType"
                      checked={
                        couponData.discountType === DISCOUNT_TYPE["FLAT"]
                      }
                      value={DISCOUNT_TYPE["FLAT"]}
                      onChange={handleRadioChange}
                    />
                  </div>
                </RadioRow>
              </Box>

              {/* (금액) */}
              <label>금액</label>
              {couponData.discountType === DISCOUNT_TYPE["PERCENT"] ? (
                <Row
                  style={{
                    alignItems: "center",
                  }}
                >
                  <ModalInput
                    type="text"
                    style={{ flexGrow: "9" }}
                    name="percentDiscountPrice"
                    value={couponData.percentDiscountPrice}
                    onChange={handleInputChange}
                    onFocus={(e) => {
                      if (e.target.value === "0") e.target.value = "";
                    }}
                  />
                  <P
                    style={{
                      flexGrow: "1",
                      fontWeight: "bold",
                      textAlign: "right",
                    }}
                  >
                    %
                  </P>
                </Row>
              ) : (
                <Row
                  style={{
                    alignItems: "center",
                  }}
                >
                  <ModalInput
                    type="text"
                    style={{ width: "45%" }}
                    name="krwDiscountPrice"
                    value={couponData.krwDiscountPrice}
                    onChange={handleInputChange}
                    onFocus={(e) => {
                      if (e.target.value === "0") e.target.value = "";
                    }}
                  />
                  <P
                    style={{
                      fontWeight: "bold",
                      textAlign: "right",
                      marginLeft: ".4rem",
                      marginRight: "1rem",
                    }}
                  >
                    ₩
                  </P>

                  <ModalInput
                    type="text"
                    style={{ width: "45%" }}
                    name="usdDiscountPrice"
                    value={couponData.usdDiscountPrice}
                    onChange={handleInputChange}
                    onFocus={(e) => {
                      if (e.target.value === "0") e.target.value = "";
                    }}
                  />
                  <P
                    style={{
                      fontWeight: "bold",
                      textAlign: "right",
                      marginLeft: ".4rem",
                    }}
                  >
                    $
                  </P>
                </Row>
              )}

              {/* 사용 금액 제한 */}
              <label>사용 금액 제한 (~ 이상시 사용 가능)</label>
              <Row
                style={{
                  alignItems: "center",
                }}
              >
                <ModalInput
                  type="text"
                  style={{ width: "45%" }}
                  name="krwLimitOfUsePrice"
                  value={couponData.krwLimitOfUsePrice}
                  onChange={handleInputChange}
                  onFocus={(e) => {
                    if (e.target.value === "0") e.target.value = "";
                  }}
                />
                <P
                  style={{
                    fontWeight: "bold",
                    textAlign: "right",
                    marginLeft: ".4rem",
                    marginRight: "1rem",
                  }}
                >
                  ₩
                </P>

                <ModalInput
                  type="text"
                  style={{ width: "45%" }}
                  name="usdLimitOfUsePrice"
                  value={couponData.usdLimitOfUsePrice}
                  onChange={handleInputChange}
                  onFocus={(e) => {
                    if (e.target.value === "0") e.target.value = "";
                  }}
                />
                <P
                  style={{
                    fontWeight: "bold",
                    textAlign: "right",
                    marginLeft: ".4rem",
                  }}
                >
                  $
                </P>
              </Row>

              {/* 사용 조건 */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                }}
              >
                <label>사용 조건</label>
                <Dropdown
                  name="useCondition"
                  value={couponData.useCondition}
                  onChange={handleSelectChange}
                >
                  <option value={USE_CONDITION["RATING_FEE"]}>
                    {USE_CONDITION_KR["RATING_FEE"]}
                  </option>
                  <option value={USE_CONDITION["CHECK_CONTENTS"]}>
                    {USE_CONDITION_KR["CHECK_CONTENTS"]}
                  </option>
                  <option value={USE_CONDITION["SHOP_SERVICE_FEE"]}>
                    {USE_CONDITION_KR["SHOP_SERVICE_FEE"]}
                  </option>
                  <option value={USE_CONDITION["MEMBERSHIP"]}>
                    {USE_CONDITION_KR["MEMBERSHIP"]}
                  </option>
                </Dropdown>
                <Icon
                  icon="caret-down-fill"
                  style={{ position: "absolute", top: "66%", right: "6%" }}
                />
              </div>

              {/* 유효 기간 */}
              <label>유효 기간</label>
              <Box>
                <RadioRow>
                  <div>
                    <label>N일 후</label>
                    <input
                      type="radio"
                      name="validityType"
                      checked={
                        couponData.validityType === COUPON_DUE_TYPE["FIXED"]
                      }
                      value={COUPON_DUE_TYPE["FIXED"]}
                      onChange={handleRadioChange}
                    />
                  </div>
                  <div>
                    <label>정해진 기간</label>
                    <input
                      type="radio"
                      name="validityType"
                      checked={
                        couponData.validityType === COUPON_DUE_TYPE["DUE"]
                      }
                      value={COUPON_DUE_TYPE["DUE"]}
                      onChange={handleRadioChange}
                    />
                  </div>
                </RadioRow>
              </Box>

              {/* (기간 설정) */}
              {couponData.validityType === COUPON_DUE_TYPE["FIXED"] ? (
                <Row
                  style={{
                    alignItems: "center",
                    marginTop: "1.8rem",
                  }}
                >
                  <ModalInput
                    type="text"
                    style={{ flexGrow: "9" }}
                    name="validityDate"
                    value={couponData.validityDate}
                    onChange={handleInputChange}
                    onFocus={(e) => {
                      if (e.target.value === "0") e.target.value = "";
                    }}
                  />
                  <P
                    style={{
                      flexGrow: "1",
                      fontWeight: "bold",
                      textAlign: "right",
                    }}
                  >
                    일 후
                  </P>
                </Row>
              ) : (
                <DateDueRow>
                  <input
                    type="text"
                    placeholder="YYYY"
                    maxLength={4}
                    value={year}
                    onChange={handleYearChange}
                  />
                  <P>-</P>
                  <input
                    type="text"
                    placeholder="MM"
                    maxLength={2}
                    value={month}
                    onChange={handleMonthChange}
                  />
                  <P>-</P>
                  <input
                    type="text"
                    placeholder="DD"
                    maxLength={2}
                    value={day}
                    onChange={handleDayChange}
                  />
                  <P> 까지</P>
                </DateDueRow>
              )}
            </ModalBody>

            <ModifyModalFooter>
              <Button
                style={{
                  marginTop: "2rem",
                  width: "100%",
                  backgroundColor: "var(--color-main-red)",
                }}
                onClick={() => handleDelete()}
              >
                삭제하기
              </Button>
              <Button
                style={{ marginTop: "2rem", width: "100%" }}
                onClick={() => handleModify()}
              >
                수정하기
              </Button>
            </ModifyModalFooter>
          </ModalWrapper>
        </ModalBackgound>
      )}
      {logModal && (
        <ModalBackgound>
          <ModalWrapper style={{ width: "70vw" }}>
            <ModalHeader>
              <div>쿠폰 사용 내역</div>
              <Icon
                icon="x-lg"
                fontSize="2rem"
                onClick={() => setLogModal(false)}
              />
            </ModalHeader>

            <ModalBody>
              <label>[{couponData.name}] 쿠폰 사용 내역</label>

              <Table>
                <thead>
                  <tr>
                    <th>스페이스 코드</th>
                    <th>이름</th>
                    <th>지급일</th>
                    <th>사용 상태</th>
                    <th>사용일</th>
                    <th>KRW 할인 금액</th>
                    <th>USD 할인 금액</th>
                  </tr>
                </thead>
                <tbody>
                  {couponLogs &&
                    couponLogs.map((coupon: any, index: number) => (
                      <tr key={index}>
                        <td>{coupon.user?.id || "-"}</td>
                        <td>
                          {coupon.user?.firstName + coupon.user?.lastName ||
                            "-"}
                        </td>
                        <td>
                          {coupon.createdAt
                            ? formatDate(coupon.createdAt)
                            : "-"}
                        </td>
                        <td>{coupon.used ? "사용" : "미사용"}</td>
                        <td>
                          {coupon.usedAt ? formatDate(coupon.usedAt) : "-"}
                        </td>
                        <td>{coupon.krwDiscounted.toLocaleString("ko-KR")}₩</td>
                        <td>{coupon.usdDiscounted.toLocaleString("ko-KR")}$</td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </ModalBody>

            <ModalFooter>
              <Button
                onClick={() => setLogModal(false)}
                style={{ width: "100%" }}
              >
                닫기
              </Button>
            </ModalFooter>
          </ModalWrapper>
        </ModalBackgound>
      )}

      {/* 헤더 */}
      <AdminHeadTitle
        subtitle1="고객 관리"
        subtitle2="쿠폰 관리"
        title="쿠폰 관리"
      />

      {/* 바디 */}
      <Body>
        {/* 개별 지급 */}
        <SingleSection>
          <LabelSection>
            <Label>개별 지급</Label>
          </LabelSection>
          <ButtonSection>
            <Link to="/admin/member/list">
              <Button>개별 지급하러 가기</Button>
            </Link>
          </ButtonSection>
        </SingleSection>

        {/* 리스트 */}
        <ListSection>
          <Row>
            <LabelSection>
              <Label>목록</Label>
            </LabelSection>

            <Col style={{ width: "90%" }}>
              <HeadListRow>
                <div className="check">
                  <input type="checkbox" />
                </div>
                <div className="name">이름</div>
                <div className="auto-receive">자동 지급</div>
                <div className="auto-receive-condition">자동 지급 조건</div>
                <div className="good">혜택</div>
                <div className="limit">사용 금액 제한</div>
                <div className="use-condition">사용 조건</div>
                <div className="due">유효 기간</div>
                <div className="setting">설정 </div>
                <div className="log">내역 </div>
              </HeadListRow>

              <>
                {couponList &&
                  couponList?.map((coupon: Coupon, index: number) => {
                    return (
                      <ListRow>
                        <div className="check">
                          <input type="checkbox" />
                        </div>

                        {/* 이름 */}
                        <div className="name">{coupon.name}</div>

                        {/* 자동 지급 */}
                        <div className="auto-receive">
                          {coupon.isAutoReceive ? "사용" : "미사용"}
                        </div>

                        {/* 자동 지급 조건 */}
                        <div className="auto-receive-condition">
                          {coupon.isAutoReceive
                            ? convertToAutoRecevieCondition(
                                coupon.autoReceiveCondition
                              )
                            : "-"}
                        </div>

                        {/* 혜택 */}
                        <div className="good">
                          {convertToGood(
                            coupon.discountType,
                            coupon.percentDiscountPrice,
                            coupon.krwDiscountPrice,
                            coupon.usdDiscountPrice
                          )}
                        </div>

                        {/* 사용 금액 제한 */}
                        <div className="limit">{`${coupon.krwLimitOfUsePrice.toLocaleString(
                          "ko-KR"
                        )}₩ 이상`}</div>

                        {/* 사용 조건 */}
                        <div className="use-condition">
                          {convertToUseCondition(coupon.useCondition)}
                        </div>

                        {/* 유효 기간 */}
                        <div className="due">
                          {convertToDue(
                            coupon.validityType,
                            coupon.validityDate,
                            coupon.validityDurationStart,
                            coupon.validityDurationEnd
                          )}
                        </div>

                        {/* 설정 */}
                        <div
                          className="setting"
                          onClick={() => handleModifyModal(index, coupon.id)}
                          style={{
                            color: "var(--color-main-blue)",
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                        >
                          설정하기
                        </div>

                        {/* 내역 */}
                        <div
                          className="log"
                          onClick={() => handleLogModal(index, coupon.id)}
                          style={{
                            color: "var(--color-main-blue)",
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                        >
                          내역보기
                        </div>
                      </ListRow>
                    );
                  })}
              </>

              <ButtonSection style={{ marginTop: "1.8rem" }}>
                <Button onClick={() => setCreateModal(true)}>
                  쿠폰 추가하기
                </Button>
              </ButtonSection>
            </Col>
          </Row>
        </ListSection>
      </Body>
    </Wrapper>
  );
}

//! Modal

const RadioRow = styled.div`
  display: flex;
  flex-direction: row;

  & div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex: 1 1 0;
    text-align: center;
    align-items: center;
    & label {
      font-weight: bold;
      margin: 0 1.6rem 0 0;
    }
  }
  & input {
    vertical-align: middle;
    appearance: none;
    border: max(2px, 0.1em) solid gray;
    border-radius: 50%;
    width: 1.25em;
    height: 1.25em;
  }
  & input:checked {
    border: 0.4em solid var(--color-main-blue);
  }
`;
const Dropdown = styled.select`
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;

  border-radius: 0.4rem;
  border: 1px solid lightgray;
  padding: 0.8rem;
`;

const Box = styled.div`
  border-radius: 0.4rem;
  border: 1px solid lightgray;
  padding: 0.8rem;
`;
const DateDueRow = styled.div`
  align-items: center;
  margin-top: 1.8rem;
  display: flex;
  flex-direction: row;
  border-radius: 0.4rem;
  border: 1px solid lightgray;
  gap: 0.2rem;
  padding: 0.4rem;

  & > input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    border: none;
    border-radius: 0.4rem;
    text-align: center;
    padding: 0.4rem;
    flex: none;
    width: 26%;
  }
  & > p {
  }
`;

const ModalFooter = styled.div``;
const ModifyModalFooter = styled(ModalFooter)`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  & > div {
    flex-grow: 1 1 0;
  }
`;
const ModalInput = styled.input`
  border: 1px solid lightgray;
  border-radius: 0.4rem;
  padding: 0.8rem;
`;
const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  & label {
    font-weight: bold;
    margin: 1rem 0;
  }
`;
const ModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid lightgray;

  font-size: 1.6rem;
  font-weight: bold;
`;
const ModalWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
  border-radius: 1rem;
  width: 26vw;
  overflow-y: scroll;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
  background-color: #fff;

  padding: 1.8rem;
  height: 80vh;
`;
const ModalBackgound = styled.div`
  position: fixed;
  z-index: 98;
  top: 0%;
  left: 0%;
  background-color: #00000078;
  width: 100vw;
  height: 100vh;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;

  & th,
  & td {
    border: 1px solid lightgray;
    padding: 0.8rem;
    text-align: center;
  }

  & th {
    background-color: var(--color-main-blue);
    color: white;
    font-weight: bold;
  }
`;

//! Main

const Label = styled.label`
  font-weight: bold;
  font-size: 1.4rem;
`;
const LabelSection = styled.div`
  width: 14%;
`;
const P = styled.p`
  margin-bottom: 0;
`;
const Button = styled.div`
  background-color: var(--color-main-blue);
  color: var(--color-white);
  padding: 0.6rem;
  border-radius: 0.6rem;
  text-align: center;
  width: 10rem;

  &:hover {
    background-color: var(--color-main-blue-hover);
  }
`;
const ButtonSection = styled.div`
  text-align: center;
`;
const ListRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 1rem;
  & .check {
    flex: 0.2 1 0;
  }
  & .name {
    flex: 1 1 0;
  }
  & .auto-receive {
    text-align: center;
    flex: 1 1 0;
  }
  & .auto-receive-condition {
    text-align: center;
    flex: 1 1 0;
  }
  & .use-condition {
    text-align: center;
    flex: 1 1 0;
  }
  & .good {
    text-align: center;
    flex: 1 1 0;
  }
  & .limit {
    text-align: center;
    flex: 1 1 0;
  }
  & .due {
    text-align: center;
    flex: 1 1 0;
  }
  & .setting {
    text-align: center;
    flex: 0.5 1 0;
  }
  & .log {
    text-align: center;
    flex: 0.5 1 0;
  }
`;
const HeadListRow = styled(ListRow)`
  font-weight: bold;
  margin-bottom: 1rem;
`;
const LogHeadListRow = styled(HeadListRow)`
  border-bottom: 1px solid lightgray;
  padding-bottom: 1rem;
  & > div {
    flex: 1 1 0;
    text-align: center;
  }
`;
const LogListRow = styled(ListRow)`
  & > div {
    flex: 1 1 0;
    text-align: center;
  }
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
`;
const ListSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1.8rem;
`;
const SingleSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 3rem;
  border-bottom: 1px solid lightgray;
  width: 100%;
`;
const Body = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1.8rem;
`;
const Title = styled.label`
  font-weight: bold;
  font-size: 1.8rem;
  padding-bottom: 1.8rem;
  border-bottom: 1px solid lightgray;
  width: 100%;
`;
const Header = styled.div`
  display: flex;
  flex-direction: row;
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
