import { Fragment, useEffect, useState } from "react";
import {
  Button,
  Card,
  Collapse,
  Dropdown,
  FormControl,
  Modal,
} from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { useSelector, useDispatch } from "react-redux";
import Select, { SingleValue } from "react-select";
import Swal from "sweetalert2";
import styled from "styled-components";
import {
  CODE_COUNTRY_ITEMS,
  ADMIN_BADGE,
  CODE_SHOPING_ORDER_STATUS,
  CODE_SHOPPING_ORDER_ITEMS,
} from "../../common/constants";
import { RootUserState } from "../../redux/store";
import { MODAL_TYPE } from "./GlobalModal";
import {
  reqShop,
  reqShopDetail,
  reqShopDetailpPost,
  reqShopPost,
  reqShopPosts,
  reqShopUpdate,
} from "../../requests/shop";
import { useNavigate } from "react-router-dom";
import { reqAddAdminBadge } from "../../requests/badge";
import { modalOpen } from "../../redux/modalSlice";
import { formatDate } from "../../common/format";
import Icon from "../Icon";
import {
  MBody,
  MButton,
  MFooter,
  MRow,
  MTwoButtonRow,
} from "../../common/mobile-styled";
interface ShopOrderModalProps {
  show: boolean;
  onHide: () => void;
  orderId: string;
  refreshData: () => void;
}
interface OrderDetail {
  order_id: string;
  productId: string;
  category: number;
  status: number;
  name: string;
  option1: string;
  option2: string;
  quantity: number;
  price: number;
  url: string;
  preorder: boolean;
  preorderStartAt: string;
  preorderEndAt: string;
  fancall: boolean;
  fancallName: string;
  fancallBirthday: string;
  fancallNumber: string;
  fancallEmail: string;
  fancallCountry: number;
  fancallKakao: string;
  fancallSnsLine: string;
  isCanceled: boolean;
}

interface ProductDetail {
  name: string;
  category: number;
  option1: string;
  option2: string;
  quantity: number;
  price: number;
  url: string;
  preorder: boolean;
  preorderStartAt?: string;
  preorderEndAt?: string;
  fancall: boolean;
  fancallName?: string;
  fancallBirthday?: string;
  fancallNumber?: string;
  fancallEmail?: string;
  fancallCountry?: number;
  fancallKakao?: string;
  fancallSnsLine?: string;
}

interface AdditionalInfo {
  failed: boolean;
}

const CShopOrderReapplyModal = ({
  show,
  onHide,
  orderId,
  refreshData,
}: ShopOrderModalProps) => {
  const [orderDetails, setOrderDetails] = useState<OrderDetail[]>([]);
  const [selectedDetail, setSelectedDetail] = useState<OrderDetail | null>(
    null
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [serviceFee, setServiceFee] = useState("");
  const [isEditMode, setIsEditMode] = useState(false);
  const [productDetails, setProductDetails] = useState<ProductDetail[]>([]);
  const [fancallNumber, setFancallNumber] = useState("");
  const [step, setStep] = useState(1);
  const handleModifyClick = () => {
    setIsEditMode(true);
  };

  useEffect(() => {
    const fetchFancallNumber = async () => {
      try {
        const response = await reqShop({ order_id: orderId });
        setFancallNumber(response.data.fancallNumber);
      } catch (error) {
        console.error("Error fetching fancallNumber:", error);
      }
    };

    if (orderId) {
      fetchFancallNumber();
    }
  }, [orderId]);

  useEffect(() => {
    if (orderId) {
      fetchOrderDetails(orderId);
    }
  }, [orderId]);

  const fetchOrderDetails = async (order_id: string) => {
    try {
      const detailResponse = await reqShopDetail({ order_id });
      const detailsWithStatus = detailResponse.data.map(
        (item: OrderDetail) => ({
          ...item,
          isCanceled: item.status === 10,
        })
      );
      setOrderDetails(detailsWithStatus);

      const shopResponse = await reqShop({ order_id });
      const fee = shopResponse.data.fee
        ? shopResponse.data.fee.toString()
        : "0";
      setServiceFee(fee);
    } catch (error) {
      console.error("Error fetching order details:", error);
    }
  };

  const handleReapplyOrderClick = async (detail: any) => {
    setSelectedDetail(detail);
    setStep(2);
    onHide();
    refreshData();
  };

  useEffect(() => {
    if (orderDetails.length > 0) {
      setProducts(
        orderDetails.map((detail) => ({
          id: detail.order_id,
          name: detail.name,
          category: detail.category,
          option1: detail.option1,
          option2: detail.option2,
          quantity: detail.quantity,
          price: detail.price,
          url: detail.url,
          preorder: detail.preorder,
          preorderStartAt: detail.preorderStartAt || "",
          preorderEndAt: detail.preorderEndAt || "",
          fancall: detail.fancall,
          fancallName: detail.fancallName || "",
          fancallBirthday: detail.fancallBirthday || "",
          fancallNumber: detail.fancallNumber || "",
          fancallEmail: detail.fancallEmail || "",
          fancallCountry: detail.fancallCountry,
          fancallKakao: detail.fancallKakao || "",
          fancallSnsLine: detail.fancallSnsLine || "",
        }))
      );
    }
  }, [orderDetails, isEditMode, show]);

  const handleReapplyOrder = async (orderId: string) => {
    try {
      // await reqShopUpdate({
      //   order_id: orderId,
      //   status: CODE_SHOPING_ORDER_STATUS.NEW,
      // });

      setStep(2);
      onHide();
      refreshData();
    } catch (error) {
      console.error("Reapply order failed:", error);
      alert("Failed to reapply the order.");
    }
  };

  const handlecancelOrderClick = async () => {
    await handlecancelOrder(orderId);
  };

  const handlecancelOrder = async (orderId: string) => {
    try {
      await reqShopUpdate({
        order_id: orderId,
        status: CODE_SHOPING_ORDER_STATUS.CANCELED,
      });
      onHide();
      refreshData();
    } catch (error) {
      console.error("Reapply order failed:", error);
      alert("Failed to reapply the order.");
    }
  };

  const [additionalInfo, setAdditionalInfo] = useState<AdditionalInfo>({
    failed: false,
  });
  const user = useSelector((state: RootUserState) => state.user);
  const [loading, setLoading] = useState(false);

  const submitAllInfo = async () => {
    if (loading) return;

    setLoading(true);
    if (user.Check) {
      await Swal.fire({
        title: "Action Blocked",
        text: "Please wait until the space code changes.",
        icon: "error",
        confirmButtonText: "OK",
      });
      setLoading(false);
      return;
    }

    const payload = {
      additionalInfo: {
        ...additionalInfo,
        userId: user.id,
        preorder: products.some((product) => product.preorder),
        fancall: products.some((product) => product.fancall),
      },
      productDetails: products.map((product) => ({
        name: product.name,
        category: product.category,
        option1: product.option1,
        option2: product.option2,
        quantity: product.quantity,
        price: product.price,
        url: product.url,
        preorder: product.preorder,
        fancall: product.fancall,
        preorderStartAt: product.preorderStartAt,
        preorderEndAt: product.preorderEndAt,
        fancallName: product.fancallName,
        fancallBirthday: product.fancallBirthday,
        fancallNumber: product.fancallNumber,
        fancallEmail: product.fancallEmail,
        fancallCountry: product.fancallCountry,
        fancallKakao: product.fancallKakao,
        fancallSnsLine: product.fancallSnsLine,
        userId: user.id,
      })),
    };

    try {
      const response = await reqShopPosts(payload);
      console.log("정보 제출됨", response);

      const badgePayload = {
        badgeName: ADMIN_BADGE.SHOP_REQUEST_FORM,
      };
      await reqAddAdminBadge(badgePayload);
      onHide();
      dispatch(
        modalOpen({
          modalType: MODAL_TYPE.INFO,
          title: "Submission Success",
          payload: {
            text: "Your order has been successfully submitted. Purchase request details can be checked on All my orders, and ONTACT Korea will send you an estimate after checking it.",
          },
        })
      );
      // navigate("/customer/shop/order");
      window.location.href = "/customer/shop/order";
    } catch (error) {
      console.error("정보 제출 중 오류 발생", error);
    } finally {
      setLoading(false);
    }
  };

  const categoryOptions = Object.entries(CODE_SHOPPING_ORDER_ITEMS).map(
    ([key, value]) => ({
      value: Number(key),
      label: value,
    })
  );

  const handleCategoryChange = (
    selectedOption: SingleValue<{ value: number; label: string }>,
    index: number
  ) => {
    const newProducts = [...products];
    newProducts[index].category = selectedOption ? selectedOption.value : 0;
    setProducts(newProducts);
  };

  const handleInputChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value, type, checked } = event.target;
    let newValue: any = type === "checkbox" ? checked : value;

    if (name === "price") {
      newValue = newValue.replace(/[^0-9]/g, "");
    }

    if (name === "quantity") {
      newValue = newValue.replace(/[^0-9]/g, "");
    }

    const updatedProducts = products.map((product, i) => {
      if (i === index) {
        if (name === "preorder" && newValue) {
          return { ...product, [name]: newValue, fancall: false };
        } else if (name === "fancall" && newValue) {
          return { ...product, [name]: newValue, preorder: false };
        } else {
          return { ...product, [name]: newValue };
        }
      }
      return product;
    });

    setProducts(updatedProducts);
  };

  const toggleSection = (index: number, section: "preorder" | "fancall") => {
    const newProducts = [...products];
    newProducts[index] = {
      ...newProducts[index],
      [section]: !newProducts[index][section],
    };
    setProducts(newProducts);
  };

  const handleReapplyAllOrders = async () => {
    Swal.fire({
      title: "Are you sure to Reapply?",
      icon: "question",

      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",

      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          // 예를 들어 모든 주문에 대한 처리 로직
          for (const detail of orderDetails) {
            // await reqShopUpdate({
            //   order_id: detail.order_id,
            //   status: CODE_SHOPING_ORDER_STATUS.NEW,
            // });
          }
          setStep(2);
          refreshData();
          onHide();
        } catch (error) {
          console.error("Failed to reapply all orders:", error);
          alert("Failed to reapply all orders.");
        }
      }
    });
  };

  const handleAdditionalInfoChange = (event: any) => {
    const { name, checked } = event.target;

    if (name === "totalCancellation") {
      setAdditionalInfo((prevInfo) => ({
        ...prevInfo,
        failed: checked,
      }));
    } else if (name === "partialCancellation") {
      setAdditionalInfo((prevInfo) => ({
        ...prevInfo,
        failed: !checked,
      }));
    } else {
      const { value, type } = event.target;
      let finalValue = value;

      if (type === "checkbox") {
        finalValue = checked;
      } else if (type === "radio") {
        finalValue = parseInt(value, 10);
      }

      setAdditionalInfo((prevInfo) => ({
        ...prevInfo,
        [name]: finalValue,
      }));
    }
  };

  const handleFancallCountrySelect = (countryId: number, index: number) => {
    const newProducts = [...products];
    newProducts[index].fancallCountry = countryId;
    setProducts(newProducts);
  };

  const [countrySearchTerm, setCountrySearchTerm] = useState("");

  const filteredCountries = Object.entries(CODE_COUNTRY_ITEMS).filter(
    ([id, name]) => {
      if (Array.isArray(name)) {
        return name.some((n) =>
          n.toLowerCase().includes(countrySearchTerm.toLowerCase())
        );
      } else {
        return name.toLowerCase().includes(countrySearchTerm.toLowerCase());
      }
    }
  );

  const handleNextStep = (nextStep: number) => {
    if (nextStep === 3) {
      const isAnyFieldMissing = products.some((product) => {
        return (
          product.name === "" ||
          product.category === 0 ||
          product.quantity === 0 ||
          product.price === 0 ||
          product.url === ""
        );
      });

      if (isAnyFieldMissing) {
        Swal.fire(
          "Error!",
          "Please fill in all required fields for every product (Product name, Category, Quantity, Price and Site URL).",
          "error"
        );
        return;
      }
    }

    setStep(nextStep);
  };
  const deleteProduct = (index: number) => {
    if (products.length === 1) {
      Swal.fire("Error!", "At least one product must exist.", "error");
      return;
    }
    setProducts((currentProducts) =>
      currentProducts.filter((_, i) => i !== index)
    );
  };

  const toggleFancallItem = (index: number) => {
    setOpenItems((prev) => ({
      ...prev,
      [`fancall-${index}`]: !prev[`fancall-${index}`],
    }));
  };

  const [products, setProducts] = useState([
    {
      id: Date.now().toString(),
      name: "",
      category: 0,
      option1: "",
      option2: "",
      quantity: 0,
      price: 0,
      url: "",
      preorder: false,
      fancall: false,
      preorderStartAt: "",
      preorderEndAt: "",
      fancallName: "",
      fancallBirthday: "",
      fancallNumber: "",
      fancallEmail: "",
      fancallCountry: 0,
      fancallKakao: "",
      fancallSnsLine: "",
    },
  ]);

  const addProduct = () => {
    setProducts([
      ...products,
      {
        id: `${Date.now()}-${Math.random().toString(36).substr(2, 9)}`,
        name: "",
        category: 0,
        option1: "",
        option2: "",
        quantity: 0,
        price: 0,
        url: "",
        preorder: false,
        fancall: false,
        preorderStartAt: "",
        preorderEndAt: "",
        fancallName: "",
        fancallBirthday: "",
        fancallNumber: "",
        fancallEmail: "",
        fancallCountry: 0,
        fancallKakao: "",
        fancallSnsLine: "",
      },
    ]);
  };

  // const [openItems, setOpenItems] = useState<{ [key: number]: boolean }>({});

  // const toggleItem = (index: number) => {
  //   setOpenItems((prev) => ({
  //     ...prev,
  //     [index]: !prev[index],
  //   }));
  // };

  const [openItems, setOpenItems] = useState<{
    [key: number | string]: boolean;
  }>({});

  useEffect(() => {
    const initialOpenItems: { [key: number | string]: boolean } = {};
    products.forEach((product, index) => {
      initialOpenItems[index] = true;
      initialOpenItems[`fancall-${index}`] = false;
    });
    setOpenItems(initialOpenItems);
  }, [products]);

  const toggleItem = (index: number) => {
    setOpenItems((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const MobileView = () => {
    return (
      <MobileWrapper>
        <div style={{ marginBottom: "1rem" }}>
          <Card>
            <Card.Body
              style={{ backgroundColor: "var(--color-main-light-red)" }}
            >
              <Card.Title>Reasons for cancellation</Card.Title>
              <Card.Text>{fancallNumber || "Not Available"}</Card.Text>
            </Card.Body>
          </Card>
        </div>

        <MBody style={{ border: "none" }}>
          {orderDetails.map((detail, index) => (
            <div
              className="accordion-item"
              key={index}
              style={{
                borderBottom: "1px solid lightgray",
                paddingBottom: ".5rem",
                marginBottom: ".5rem",
              }}
            >
              <div
                className="accordion-header"
                id={`heading${index}`}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    if (detail.isCanceled) {
                      handleReapplyOrder(detail.order_id);
                    } else {
                      toggleItem(index);
                    }
                  }}
                  style={{
                    padding: ".5rem .5rem .5rem 0",
                  }}
                >
                  <Icon
                    icon={openItems[index] ? "caret-up" : "caret-down-fill"}
                  ></Icon>
                </div>
                <div style={{ fontWeight: "bold" }}>
                  No. {index + 1} {detail.isCanceled ? "(Canceled)" : ""}
                </div>
              </div>

              <Collapse in={openItems[index] ?? true}>
                <div
                  id={`collapse${index}`}
                  className="accordion-collapse collapse"
                >
                  <div
                    className="accordion-body"
                    style={{ padding: "0 1.5rem" }}
                  >
                    {isMobile ? (
                      <div>
                        <MRow>
                          <div className="label">Category</div>
                          <div className="data">
                            {CODE_SHOPPING_ORDER_ITEMS[detail.category]}
                          </div>
                        </MRow>

                        <MRow>
                          <div className="label">Product Name</div>
                          <div className="data">{detail.name}</div>
                        </MRow>

                        <MRow>
                          <div className="label">Option 1</div>
                          <div className="data">{detail.option1 || "-"}</div>
                        </MRow>

                        <MRow>
                          <div className="label">Option 2</div>
                          <div className="data">{detail.option2 || "-"}</div>
                        </MRow>

                        <MRow>
                          <div className="label">Quantity</div>
                          <div className="data">{detail.quantity}</div>
                        </MRow>

                        <MRow>
                          <div className="label">Unit Price</div>
                          <div className="data price">{detail.price}</div>
                        </MRow>

                        <MRow>
                          <div className="label">Link</div>
                          <div className="data">
                            <a
                              href={`http${
                                detail.url.includes("://") ? "" : "s://"
                              }${detail.url}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="d-inline-block text-truncate"
                              style={{ maxWidth: "700px" }}
                            >
                              {detail.url}
                            </a>
                          </div>
                        </MRow>
                        {detail.preorder && (
                          <MRow>
                            <div className="label">Pre order</div>
                            <div className="data">
                              {formatDate(detail.preorderStartAt)} ~{" "}
                              {formatDate(detail.preorderEndAt)}
                            </div>
                          </MRow>
                        )}
                        {detail.fancall && (
                          <>
                            <div
                              onClick={(e) => {
                                e.stopPropagation();
                                toggleFancallItem(index);
                              }}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                padding: ".5rem .5rem .5rem 0",
                                cursor: "pointer",
                                gap: ".5rem",
                              }}
                            >
                              <Icon
                                icon={
                                  openItems[`fancall-${index}`]
                                    ? "caret-up"
                                    : "caret-down-fill"
                                }
                              ></Icon>
                              <b>Fan Call</b>
                            </div>
                            <Collapse
                              in={openItems[`fancall-${index}`] ?? false}
                            >
                              <div>
                                <MRow>
                                  <div className="label">Fancall Name</div>
                                  <div className="data">
                                    {detail.fancallName}
                                  </div>
                                </MRow>
                                <MRow>
                                  <div className="label">Fancall Birthday</div>
                                  <div className="data">
                                    {formatDate(
                                      detail.fancallBirthday as string
                                    )}
                                  </div>
                                </MRow>
                                <MRow>
                                  <div className="label">Fancall Number</div>
                                  <div className="data">
                                    {detail.fancallNumber}
                                  </div>
                                </MRow>
                                <MRow>
                                  <div className="label">Fancall Email</div>
                                  <div className="data">
                                    {detail.fancallEmail}
                                  </div>
                                </MRow>
                                <MRow>
                                  <div className="label">Fancall Country</div>
                                  <div className="data">
                                    {CODE_COUNTRY_ITEMS[detail.fancallCountry]}
                                  </div>
                                </MRow>
                                <MRow>
                                  <div className="label">Fancall Kakao</div>
                                  <div className="data">
                                    {detail.fancallKakao}
                                  </div>
                                </MRow>
                                <MRow>
                                  <div className="label">Fancall Sns Line</div>
                                  <div className="data">
                                    {detail.fancallSnsLine}
                                  </div>
                                </MRow>
                              </div>
                            </Collapse>
                          </>
                        )}
                      </div>
                    ) : (
                      <div>
                        <tbody>
                          <Label>Category</Label>
                          <P>{CODE_SHOPPING_ORDER_ITEMS[detail.category]}</P>
                          <Label>Product Name</Label>
                          <P>{detail.name}</P>
                          <Label>Option 1</Label>
                          <P>{detail.option1 || "-"}</P>
                          <Label>Option 2</Label>
                          <P>{detail.option2 || "-"}</P>
                          <Label>Quantity</Label>
                          <P>{detail.quantity}</P>
                          <Label>Unit Price(KRW)</Label>
                          <P>{detail.price}</P>
                          <Label>Link</Label>
                          <P>
                            <a
                              href={`http${
                                detail.url.includes("://") ? "" : "s://"
                              }${detail.url}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="d-inline-block text-truncate"
                              style={{ maxWidth: "700px" }}
                            >
                              {detail.url}
                            </a>
                          </P>
                          <Label>- Pre order</Label>
                          <P>
                            {detail.preorder
                              ? `${formatDate(
                                  detail.preorderStartAt
                                )} ~ ${formatDate(detail.preorderEndAt)}`
                              : "No"}
                          </P>
                          {detail.fancall && (
                            <>
                              <Label>- Fan call</Label>
                              <p></p>
                              <Label>FancallName</Label>
                              <P>{detail.fancallName}</P>
                              <Label>FancallBirthday</Label>
                              <P>{formatDate(detail.fancallBirthday)}</P>
                              <Label>FancallNumber</Label>
                              <P>{detail.fancallNumber}</P>
                              <Label>FancallEmail</Label>
                              <P>{detail.fancallEmail}</P>
                              <Label>FancallCountry</Label>
                              <P>{CODE_COUNTRY_ITEMS[detail.fancallCountry]}</P>
                              <Label>FancallKakao</Label>
                              <P>{detail.fancallKakao}</P>
                              <Label>FancallSnsLine</Label>
                              <P>{detail.fancallSnsLine}</P>
                            </>
                          )}
                        </tbody>
                      </div>
                    )}
                  </div>
                </div>
              </Collapse>
            </div>
          ))}
        </MBody>
      </MobileWrapper>
    );
  };
  return (
    <Fragment>
      <Modal show={show && !isEditMode} onHide={onHide} size="xl" centered>
        <Modal.Header
          closeButton
          style={{ backgroundColor: "var(--color-lightgray)" }}
        >
          <Modal.Title>{orderId}</Modal.Title>
        </Modal.Header>

        {isMobile ? (
          <MobileView />
        ) : (
          <Modal.Body>
            <div className="mb-4">
              <Card>
                <Card.Body style={{ backgroundColor: "#ffcccc" }}>
                  <Card.Title>Reasons for cancellation</Card.Title>
                  <Card.Text>{fancallNumber || "Not Available"}</Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div className="accordion" id="accordionPreview">
              {orderDetails.map((detail, index) => (
                <div className="accordion-item" key={index}>
                  <div
                    className="accordion-header"
                    id={`heading${index}`}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleItem(index);
                      }}
                      style={{
                        padding: ".5rem",
                      }}
                    >
                      <Icon
                        icon={openItems[index] ? "caret-up" : "caret-down-fill"}
                      ></Icon>
                    </div>
                    <div style={{ fontWeight: "bold" }}>No. {index + 1}</div>
                  </div>

                  <Collapse in={openItems[index] ?? true}>
                    <div
                      id={`collapse${index}`}
                      className="accordion-collapse collapse"
                    >
                      <div className="accordion-body">
                        <table className="table">
                          <tbody>
                            <tr>
                              <th>Category</th>
                              <td>
                                {CODE_SHOPPING_ORDER_ITEMS[detail.category]}
                              </td>
                            </tr>
                            <tr>
                              <th>Product Name</th>
                              <td>{detail.name}</td>
                            </tr>
                            <tr>
                              <th>Option 1</th>
                              <td>{detail.option1 || "-"}</td>
                            </tr>
                            <tr>
                              <th>Option 2</th>
                              <td>{detail.option2 || "-"}</td>
                            </tr>
                            <tr>
                              <th>Quantity</th>
                              <td>{detail.quantity}</td>
                            </tr>
                            <tr>
                              <th>Unit Price(KRW)</th>
                              <td>{detail.price}</td>
                            </tr>
                            <tr>
                              <th>Link</th>
                              <td>
                                <a
                                  href={`http${
                                    detail.url.includes("://") ? "" : "s://"
                                  }${detail.url}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="d-inline-block text-truncate"
                                  style={{ maxWidth: "700px" }}
                                >
                                  {detail.url}
                                </a>
                              </td>
                            </tr>
                            <tr>
                              <th>Pre order</th>
                              <td>
                                {detail.preorder
                                  ? `${formatDate(
                                      detail.preorderStartAt
                                    )} ~ ${formatDate(detail.preorderEndAt)}`
                                  : "No"}
                              </td>
                            </tr>
                            {detail.fancall && (
                              <>
                                <tr>
                                  <th>Fancall Name</th>
                                  <td>{detail.fancallName}</td>
                                </tr>
                                <tr>
                                  <th>Fancall Birthday</th>
                                  <td>
                                    {formatDate(
                                      detail.fancallBirthday as string
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <th>Fancall Number</th>
                                  <td>{detail.fancallNumber}</td>
                                </tr>
                                <tr>
                                  <th>Fancall Email</th>
                                  <td>{detail.fancallEmail}</td>
                                </tr>
                                <tr>
                                  <th>Fancall Country</th>
                                  <td>
                                    {CODE_COUNTRY_ITEMS[detail.fancallCountry]}
                                  </td>
                                </tr>
                                <tr>
                                  <th>Fancall Kakao</th>
                                  <td>{detail.fancallKakao}</td>
                                </tr>
                                <tr>
                                  <th>Fancall Sns Line</th>
                                  <td>{detail.fancallSnsLine}</td>
                                </tr>
                              </>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </Collapse>
                </div>
              ))}
            </div>
          </Modal.Body>
        )}

        {isMobile ? (
          <MFooter style={{ padding: "1rem" }}>
            <MTwoButtonRow>
              <MButton
                onClick={handlecancelOrderClick}
                style={{
                  backgroundColor: "var(--color-gray)",
                  color: "var(--color-white)",
                }}
              >
                Close
              </MButton>
              <MButton
                onClick={handleReapplyAllOrders}
                style={{
                  backgroundColor: "var(--color-main-blue)",
                  color: "var(--color-white)",
                }}
              >
                Reapply All
              </MButton>
            </MTwoButtonRow>
          </MFooter>
        ) : (
          <Modal.Footer>
            <Button variant="primary" onClick={handleReapplyAllOrders}>
              Reapply All
            </Button>
            <Button variant="secondary" onClick={handlecancelOrderClick}>
              Close
            </Button>
          </Modal.Footer>
        )}
      </Modal>
      <Modal show={step === 2} onHide={() => setStep(0)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Step 2: Enter product information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {products.map((product, index) => (
            <form className="row" key={product.id}>
              <div className="col-12 mb-1">
                <label className="form-label">category *</label>
                <Select
                  value={categoryOptions.find(
                    (option) => option.value === product.category
                  )}
                  onChange={(selectedOption) =>
                    handleCategoryChange(
                      selectedOption as SingleValue<{
                        value: number;
                        label: string;
                      }>,
                      index
                    )
                  }
                  options={categoryOptions}
                  placeholder="Select Category"
                />
              </div>
              <div className="col-12 mb-1">
                <label className="form-label">Product name *</label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  value={product.name}
                  onChange={(e) => handleInputChange(index, e)}
                />
              </div>
              <div className="col-6">
                <label className="form-label">Option1</label>
                <input
                  type="text"
                  className="form-control"
                  name="option1"
                  value={product.option1}
                  onChange={(e) => handleInputChange(index, e)}
                />
              </div>
              <div className="col-6">
                <label className="form-label">Option2</label>
                <input
                  type="text"
                  className="form-control"
                  name="option2"
                  value={product.option2}
                  onChange={(e) => handleInputChange(index, e)}
                />
              </div>
              <div className="col-6">
                <label className="form-label">Quantity *</label>
                <input
                  className="form-control"
                  name="quantity"
                  value={product.quantity}
                  onChange={(e) => handleInputChange(index, e)}
                  onFocus={(e) => (e.target.value = "")}
                />
              </div>
              <div className="col-6">
                <label className="form-label">Unit Price(KRW) *</label>
                <input
                  className="form-control"
                  name="price"
                  value={product.price}
                  onChange={(e) => handleInputChange(index, e)}
                  onFocus={(e) => {
                    if (e.target.value === "0") e.target.value = "";
                  }}
                />
              </div>
              <div className="col-12">
                <label className="form-label">Site url *</label>
                <input
                  type="text"
                  className="form-control"
                  name="url"
                  value={product.url}
                  onChange={(e) => handleInputChange(index, e)}
                />
              </div>
              <div className="col-12" style={{ marginTop: "0.5rem" }}>
                <div
                  className="accordion accordion-flush"
                  id="accordionFlushExample"
                >
                  <div
                    className="accordion-item"
                    style={{ padding: "0.5rem 0" }}
                  >
                    <h4 className="accordion-header">
                      <div
                        className="form-check"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "1rem",
                          alignItems: "center",
                          fontSize: "1.1rem",
                        }}
                      >
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id={`preorder-${product.id}`}
                          name="preorder"
                          checked={product.preorder}
                          onChange={(e) => handleInputChange(index, e)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`preorder-${product.id}`}
                          style={{ fontWeight: "normal", width: "100%" }}
                        >
                          Pre Order
                        </label>
                        <Step2Dropdown>
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            onClick={() => toggleSection(index, "preorder")}
                            aria-expanded={product.preorder}
                            aria-controls={`flush-collapseOne-${index}`}
                            style={{ padding: "0" }}
                          ></button>
                        </Step2Dropdown>
                      </div>
                    </h4>
                    <Collapse in={product.preorder}>
                      <div
                        id={`flush-collapseOne-${index}`}
                        className="accordion-body"
                        style={{ padding: "0" }}
                      >
                        <div
                          className="accordion-body"
                          style={{ padding: "0", margin: "1rem 2rem" }}
                        >
                          <div style={{ marginBottom: "0.5rem" }}>
                            <h5>Start date ~ End date</h5>
                          </div>
                          <div className="input-group mb-3">
                            <input
                              type="date"
                              className="form-control"
                              name="preorderStartAt"
                              value={formatDate(product.preorderStartAt) || ""}
                              onChange={(e) => handleInputChange(index, e)}
                              placeholder="Start date"
                              aria-label="Start date"
                            />
                            <span className="input-group-text">~</span>
                            <input
                              type="date"
                              className="form-control"
                              name="preorderEndAt"
                              value={formatDate(product.preorderEndAt) || ""}
                              onChange={(e) => handleInputChange(index, e)}
                              placeholder="End date"
                              aria-label="End date"
                            />
                          </div>
                        </div>
                      </div>
                    </Collapse>
                  </div>
                  <div
                    className="accordion-item"
                    style={{ padding: "0.5rem 0" }}
                  >
                    <h4 className="accordion-header">
                      <div
                        className="form-check"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "1rem",
                          alignItems: "center",
                          fontSize: "1.1rem",
                        }}
                      >
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id={`fancall-${product.id}`}
                          name="fancall"
                          checked={product.fancall}
                          onChange={(e) => handleInputChange(index, e)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`fancall-${product.id}`}
                          style={{ fontWeight: "normal", width: "100%" }}
                        >
                          Fan Call
                        </label>
                        <Step2Dropdown>
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            onClick={() => toggleSection(index, "fancall")}
                            aria-expanded={product.fancall}
                            aria-controls={`flush-collapseTwo-${index}`}
                            style={{ padding: "0" }}
                          ></button>
                        </Step2Dropdown>
                      </div>
                    </h4>
                    <Collapse in={product.fancall}>
                      <div
                        id={`flush-collapseTwo-${index}`}
                        className="accordion-body"
                      >
                        <div className="accordion-body">
                          {/* Name Input */}
                          <div className="input-group mb-3">
                            <span className="input-group-text">Name</span>
                            <input
                              type="text"
                              aria-label="First name"
                              className="form-control"
                              name="fancallName"
                              value={product.fancallName || ""}
                              onChange={(e) => handleInputChange(index, e)}
                            />
                          </div>
                          {/* Birthday Input */}
                          <div className="input-group mb-3">
                            <span className="input-group-text">Birthday</span>
                            <input
                              type="date"
                              className="form-control"
                              name="fancallBirthday"
                              value={formatDate(product.fancallBirthday) || ""}
                              onChange={(e) => handleInputChange(index, e)}
                            />
                          </div>

                          {/* Phone Number Input */}
                          <div className="input-group mb-3">
                            <span className="input-group-text">Phone</span>
                            <input
                              type="text"
                              className="form-control"
                              name="fancallNumber"
                              value={product.fancallNumber || ""}
                              onChange={(e) => handleInputChange(index, e)}
                              placeholder="Phone Number"
                              aria-label="Phone Number"
                            />
                          </div>

                          {/* Email Input */}
                          <div className="input-group mb-3">
                            <span className="input-group-text">E-mail</span>
                            <input
                              type="email"
                              className="form-control"
                              name="fancallEmail"
                              value={product.fancallEmail || ""}
                              onChange={(e) => handleInputChange(index, e)}
                              placeholder="E-mail"
                              aria-label="E-mail"
                            />
                          </div>

                          {/* Country Input */}
                          <div className="input-group mb-3">
                            <label
                              className="input-group-text"
                              htmlFor="dropdown-basic-fancallCountry"
                            >
                              Country
                            </label>
                            <Dropdown>
                              <Dropdown.Toggle
                                variant="success"
                                id="dropdown-basic-fancallCountry"
                              >
                                {products[index].fancallCountry
                                  ? CODE_COUNTRY_ITEMS[
                                      products[index].fancallCountry
                                    ]
                                  : "Select Country"}
                              </Dropdown.Toggle>

                              <Dropdown.Menu
                                style={{
                                  maxHeight: "300px",
                                  overflowY: "auto",
                                }}
                              >
                                <FormControl
                                  autoFocus
                                  className="mx-2 my-1 w-auto"
                                  placeholder="Search country..."
                                  onChange={(e) =>
                                    setCountrySearchTerm(e.target.value)
                                  }
                                  value={countrySearchTerm}
                                />
                                {filteredCountries.map(([id, name]) => (
                                  <Dropdown.Item
                                    key={id}
                                    onClick={() =>
                                      handleFancallCountrySelect(
                                        Number(id),
                                        index
                                      )
                                    }
                                  >
                                    {name}
                                  </Dropdown.Item>
                                ))}
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                          {/* KakaoTalk ID Input */}
                          <div className="input-group mb-3">
                            <span className="input-group-text">
                              KakaoTalk ID
                            </span>
                            <input
                              type="text"
                              className="form-control"
                              name="fancallKakao"
                              value={product.fancallKakao || ""}
                              onChange={(e) => handleInputChange(index, e)}
                              placeholder="Kakao"
                              aria-label="Kakao"
                            />
                          </div>

                          {/* Line ID Input */}
                          <div className="input-group mb-3">
                            <span className="input-group-text">Line ID</span>
                            <input
                              type="text"
                              className="form-control"
                              name="fancallSnsLine"
                              value={product.fancallSnsLine || ""}
                              onChange={(e) => handleInputChange(index, e)}
                              placeholder="SnsLine"
                              aria-label="SnsLine"
                            />
                          </div>
                        </div>
                      </div>
                    </Collapse>
                  </div>
                </div>
                <div className="col-12" style={{ marginTop: "1rem" }}>
                  <button
                    type="button"
                    style={{ width: "100%", marginBottom: "1rem" }}
                    className="btn btn-danger"
                    onClick={() => deleteProduct(index)}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </form>
          ))}
          <Button
            style={{ width: "100%", marginBottom: "1rem" }}
            onClick={addProduct}
          >
            Add Product
          </Button>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={() => handleNextStep(1)}>
            BACK
          </Button>
          <Button variant="outline-success" onClick={() => handleNextStep(3)}>
            NEXT
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={step === 3} onHide={() => setStep(0)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Step 3: Preview Input Information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="accordion" id="accordionPreview">
            {products.map((product, index) => (
              <div className="accordion-item" key={product.id}>
                <div
                  className="accordion-header"
                  id={`heading${index}`}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleItem(index);
                    }}
                    style={{
                      padding: ".5rem",
                    }}
                  >
                    <Icon
                      icon={openItems[index] ? "caret-up" : "caret-down-fill"}
                    ></Icon>
                  </div>
                  <div style={{ fontWeight: "bold" }}>No. {index + 1}</div>
                </div>

                <Collapse in={openItems[index] ?? true}>
                  <div
                    id={`collapse${index}`}
                    className="accordion-collapse collapse"
                  >
                    <div className="accordion-body">
                      <table className="table">
                        <tbody>
                          <tr>
                            <th>Category</th>
                            <td>
                              {CODE_SHOPPING_ORDER_ITEMS[product.category]}
                            </td>
                          </tr>
                          <tr>
                            <th>Product name</th>
                            <td>{product.name}</td>
                          </tr>
                          <tr>
                            <th>Option 1</th>
                            <td>{product.option1 || "-"}</td>
                          </tr>
                          <tr>
                            <th>Option 2</th>
                            <td>{product.option2 || "-"}</td>
                          </tr>
                          <tr>
                            <th>Quantity</th>
                            <td>{product.quantity}</td>
                          </tr>
                          <tr>
                            <th>Unit price</th>
                            <td>{product.price}</td>
                          </tr>
                          <tr>
                            <th>Hyperlink</th>
                            <td
                              style={{
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                              }}
                            >
                              <a
                                href={product.url}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {product.url}
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <th>Pre Order</th>
                            <td>
                              {product.preorder
                                ? `${formatDate(
                                    product.preorderStartAt
                                  )} ~ ${formatDate(product.preorderEndAt)}`
                                : "No"}
                            </td>
                          </tr>
                          {product.fancall && (
                            // <tr>
                            //   <th>Fan Call</th>
                            //   <td>
                            //     <ul style={{ paddingLeft: 0 }}>
                            //       <li>Name: {product.fancallName}</li>
                            //       <li>
                            //         Birth: {formatDate(product.fancallBirthday)}
                            //       </li>
                            //       <li>Contact: {product.fancallNumber}</li>
                            //       <li>Email: {product.fancallEmail}</li>
                            //       <li>
                            //         Country:{" "}
                            //         {CODE_COUNTRY_ITEMS[product.fancallCountry]}
                            //       </li>
                            //       <li>Kakao: {product.fancallKakao}</li>
                            //       <li>Line: {product.fancallSnsLine}</li>
                            //     </ul>
                            //   </td>
                            // </tr>
                            <>
                              <tr>
                                <th>Fancall Name</th>
                                <td>{product.fancallName}</td>
                              </tr>
                              <tr>
                                <th>Fancall Birthday</th>
                                <td>{formatDate(product.fancallBirthday)}</td>
                              </tr>
                              <tr>
                                <th>Fancall Number</th>
                                <td>{product.fancallNumber}</td>
                              </tr>
                              <tr>
                                <th>Fancall Email</th>
                                <td
                                  style={{
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  {product.fancallEmail}
                                </td>
                              </tr>
                              <tr>
                                <th>Fancall Country</th>
                                <td>
                                  {CODE_COUNTRY_ITEMS[product.fancallCountry]}
                                </td>
                              </tr>
                              <tr>
                                <th>Fancall Kakao</th>
                                <td>{product.fancallKakao}</td>
                              </tr>
                              <tr>
                                <th>Fancall Sns Line</th>
                                <td>{product.fancallSnsLine}</td>
                              </tr>
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </Collapse>
              </div>
            ))}
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                name="totalCancellation"
                onChange={handleAdditionalInfoChange}
                checked={products.length === 1 || additionalInfo.failed}
              />{" "}
              Total cancellation
            </div>
            {products.length > 1 && (
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  name="partialCancellation"
                  onChange={handleAdditionalInfoChange}
                  checked={!additionalInfo.failed}
                />{" "}
                Partial cancellation (purchase available items)
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={() => setStep(2)}>
            Back
          </Button>
          <Button
            variant="outline-success"
            onClick={submitAllInfo}
            disabled={loading}
          >
            Submit
          </Button>
          {/* <Button variant="outline-success" onClick={() => {
                            setAdditionalInfo(prev => ({ ...prev, PaymentMethod: 1 }));
                            setStep(4);
                        }}>KRW 결제</Button>
                        <Button variant="outline-success" disabled onClick={() => {
                            setAdditionalInfo(prev => ({ ...prev, PaymentMethod: 2 }));
                            setStep(5);
                        }}>USD 결제</Button> */}
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};
export default CShopOrderReapplyModal;

const Title = styled.h3`
  border-bottom: 1px solid lightgray;
  padding-bottom: 1rem;
  margin-top: 1rem;
`;
const FeeBoxRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  & > div {
    flex: 1 0 0;
    text-align: center;
    align-items: center;
    border: 1px solid lightgray;
    padding: 0.4rem 0;
  }
  margin: 0.6rem 0;
`;
const TotalFeeBoxRow = styled(FeeBoxRow)`
  background-color: #474747;
  color: white;
`;

const MobileWrapper = styled.div`
  margin: 0;
  padding: 1rem 1rem 0 1rem;
`;

const MBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.6rem 0;
  margin: 0.4rem 0;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;
const Col = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  font-weight: bold;
  margin: 0.4rem 0 0.4rem 0;
`;
const P = styled.p`
  margin: 0 0 0.4rem 0;
`;

const Step2Dropdown = styled.div`
  & > button:focus {
    box-shadow: none !important;
  }
`;
