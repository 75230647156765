import { createSlice } from "@reduxjs/toolkit";

interface iAdmin {
  id: number;
  name: string;
  role: number;
  email: string;
  accessToken: string;
}

const initialState: iAdmin = {
  id: 0,
  name: "",
  email: "",
  role: 0,
  accessToken: "",
};

const adminSlice = createSlice({
  initialState,
  name: "admin",
  reducers: {
    adminSave: (state, action) => {
      const { id, name, role, email, accessToken } = action.payload;
      state.id = id;
      state.name = name;
      state.email = email;
      state.role = role;
      state.accessToken = accessToken;
      return state;
    },
    adminClear: (state) => {
      state.id = 0;
      state.name = "";
      state.email = "";
      state.role = 0;
      state.accessToken = "";
      return state;
    },
  },
});

export const { adminSave, adminClear } = adminSlice.actions;
export default adminSlice.reducer;
