import { Form, Modal } from "react-bootstrap";
import styled from "styled-components";

export const MWrapper = styled.div`
  padding: 0;
  margin: 0;

  .image {
    width: 100%;
  }
`;

export const MHeader = styled(Modal.Header)`
  font-size: 1.3rem;
  padding: 1rem;
  border-bottom: 2px solid lightgray;
`;

export const MBody = styled.div`
  font-size: 1rem;
  padding: 0;
  border-bottom: 2px solid lightgray;

  .sub-header {
    font-size: 1.1rem;
    font-weight: bold;
    padding: 1rem 0 0.8rem 0;
  }
  .step-header {
    font-size: 1.1rem;
    font-weight: bold;
    padding: 0.8rem 0 0.8rem 0;
    color: var(--color-main-blue);
  }

  .label {
  }

  .price {
    font-weight: bold;
    text-decoration: underline;
  }

  .line {
    padding-bottom: 0.6rem;
    border-bottom: 1px solid lightgray;
  }

  .bold {
    font-weight: bold;
    padding: 0.5rem 0 0.5rem 0;
  }

  .dropdown-box {
    padding: 0.4rem 0 0.4rem 0;
    border-bottom: 1px solid lightgray;
  }
  .dropdown-bar {
    display: flex;
    flex-direction: row;
    margin-bottom: 0.4rem;
  }
  .dropdown-icon {
    margin-right: 0.4rem;
  }
  .dropdown-data {
    padding-left: 1.4rem;
    margin-top: 0.4rem;
  }

  .f-row {
    display: flex;
    flex-direction: row;
  }
`;

export const MFooter = styled.div`
  font-size: 1rem;
  padding: 1rem 0;

  .pay-dead {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .date {
      font-weight: bold;
      text-decoration: underline;
    }
  }
`;

export const MTwoButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  & > div {
    width: 49%;
  }
`;

export const MButton = styled.div`
  padding: 0.4rem 0.6rem;
  font-size: 1.1rem;
  border-radius: 0.6rem;
  text-align: center;
  cursor: pointer;
`;

export const MDeleteButton = styled(MButton)`
  &:hover {
    background-color: var(--color-main-red-hover);
  }
  cursor: pointer;
`;

//! Tab Start

export const MTabSection = styled.div`
  /* padding: 0 1rem; */
`;

export const MTabHeader = styled.div`
  display: flex;
  font-size: 1.2rem;
  background-color: var(--color-lightgray);
  padding: 0.8rem 0;
  margin: 0 1rem;
  border-bottom: 1px solid lightgray;

  .dropdown {
    margin-right: 0.4rem;
  }
`;

export const MTabBody = styled.div`
  padding: 0 1rem 1rem 1rem;
  background-color: white;

  .line {
    padding-bottom: 0.4rem;
    border-bottom: 1px solid lightgray;
  }
`;

export const MTableBody = styled(MTabBody)``;

//! Tab End

export const MItem = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid lightgray;
  padding: 0.5rem 0;

  .input-label {
    margin: 0.5rem 0;
  }
  label {
    margin: 0.5rem 0;
  }
`;

export const MPageTitle = styled.div`
  font-weight: bold;
  font-size: 1.2rem;
  margin: 1rem 0;
`;

export const MRow = styled.div`
  display: flex;
  margin-bottom: 0.4rem;
  /* justify-content: left; */
  justify-content: space-between;

  .label {
    /* flex: 5 0 0; */
  }
  .data {
    /* flex: 5 0 0; */
    text-align: right;
  }
  .check-box {
    height: 1rem;
    /* flex: 5 0 0; */
  }

  .apply-coupon {
    color: var(--color-main-blue);
  }
`;

//! Custom UI

export const MBOptionRow = styled(Form.Check)`
  padding-bottom: 0.4rem;
`;

export const MCard = styled.div`
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.4);
  border-radius: 0.4rem;
  padding: 1rem;
`;

export const MSmallButton = styled.div`
  padding: 0.2rem 0.4rem;
  border-radius: 0.6rem;
  text-align: center;
  cursor: pointer;
`;

export const MobilePaymentSection = styled.div`
  & > div {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    border: 2px solid #ccc;
    border-radius: 8px;
    margin: 1rem 0;
    cursor: pointer;
    flex: 1 1 0;
    /* width: 100vw; */
    height: 7vh;
    & > img {
      width: 90%;
      height: 70%;
      object-fit: scale-down;
    }
  }
  .mobile-payment-card:hover,
  .selected {
  }
  .paypal,
  .visa {
    flex: 1 0 50%;
  }

  .amex {
    flex: 1 0 100%;
  }
`;
