import React, { useState } from "react";
import styled from "styled-components";
import {
  COUPON_DUE_TYPE,
  DISCOUNT_TYPE,
  USE_CONDITION,
  USE_CONDITION_KR,
} from "../common/constants";

//! Converter
export const convertToGood = (
  discountType: string,
  percentDiscountPrice?: number,
  krwDiscountPrice?: number,
  usdDiscountPrice?: number
) => {
  if (discountType === DISCOUNT_TYPE["PERCENT"]) {
    return `${percentDiscountPrice}% Discount`;
  }
  if (discountType === DISCOUNT_TYPE["FLAT"]) {
    if (krwDiscountPrice !== 0) {
      return `${krwDiscountPrice?.toLocaleString(
        "ko-KR"
      )}₩ or ${usdDiscountPrice?.toFixed(2)}$ Discount`;
    } else {
      return `${usdDiscountPrice?.toFixed(2)}$ Discount`;
    }
  }
};

export const convertToDate = (date: Date): string => {
  return date
    .toLocaleString("ko-KR", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour12: false,
    })
    .split(" ")[0];
};

export const convertToDueString = (
  dueType: string,
  startDate: Date,
  endDate: Date,
  day: number
) => {
  const startDue = convertToDate(startDate);
  if (dueType === COUPON_DUE_TYPE["FIXED"]) {
    const endDue = new Date();
    endDue.setDate(new Date(startDate).getDate() + day);
    return `${startDue} ~ ${endDue.toISOString().split("T")[0]}`;
  } else {
    const endDue = convertToDate(endDate);
    return `${startDue} ~ ${endDue}`;
  }
};

export const convertToUseCondition = (condition: string): string => {
  if (condition === USE_CONDITION["RATING_FEE"]) {
    return USE_CONDITION_KR["RATING_FEE"];
  }
  if (condition === USE_CONDITION["CHECK_CONTENTS"]) {
    return USE_CONDITION_KR["CHECK_CONTENTS"];
  }
  if (condition === USE_CONDITION["SHOP_SERVICE_FEE"]) {
    return USE_CONDITION_KR["SHOP_SERVICE_FEE"];
  }
  if (condition === USE_CONDITION["REPACK_FEE"]) {
    return USE_CONDITION_KR["REPACK_FEE"];
  }
  if (condition === USE_CONDITION["MEMBERSHIP"]) {
    return USE_CONDITION_KR["MEMBERSHIP"];
  }
  return "-";
};

const CouponList = ({
  krwInput,
  usdInput,
  couponList,
  setKrwDiscount,
  setUsdDiscount,
  couponApplied,
  setSelectedCouponId,
}: any) => {
  const [viewKrwDiscount, setViewKrwDiscount] = useState<number>(0);
  const [viewUsdDiscount, setViewUsdDiscount] = useState<number>(0);
  const [thisCouponId, setThisCouponId] = useState<any>();

  //! 쿠폰 로직
  const applyCoupon = (coupon: any) => {
    // console.log(coupon);
    if (coupon === null) {
      setViewKrwDiscount(0);
      setViewUsdDiscount(0);
      return;
    }
    if (coupon.coupon.discountType === DISCOUNT_TYPE["PERCENT"]) {
      const krwDiscount = Math.round(
        (krwInput * coupon.coupon.percentDiscountPrice) / 100
      );
      const usdDiscount = (usdInput * coupon.coupon.percentDiscountPrice) / 100;
      setViewKrwDiscount(krwDiscount);
      setViewUsdDiscount(usdDiscount);
      console.log(krwDiscount, usdDiscount);
    }
    if (coupon.coupon.discountType === DISCOUNT_TYPE["FLAT"]) {
      setViewKrwDiscount(coupon.coupon.krwDiscountPrice);
      setViewUsdDiscount(coupon.coupon.usdDiscountPrice);
    }
    console.log(krwInput, usdInput);
  };

  const handleApplyCouponButton = () => {
    couponApplied(true);
    setKrwDiscount(viewKrwDiscount);
    setUsdDiscount(viewUsdDiscount);
    setSelectedCouponId(thisCouponId);
  };

  const handleCouponClick = (coupon: any) => {
    if (thisCouponId === coupon.id) {
      setThisCouponId(null); // 현재 선택된 쿠폰을 다시 클릭하면 초기화
      applyCoupon(null);
    } else {
      setThisCouponId(coupon.id); // 다른 쿠폰을 클릭하면 새로 선택
      applyCoupon(coupon);
    }
  };

  return (
    <Wrapper>
      {couponList &&
        couponList.length > 0 &&
        couponList?.map((coupon: any) => {
          const isAvailableLimit =
            krwInput >= coupon.coupon.krwLimitOfUsePrice &&
            (usdInput < 0
              ? true
              : usdInput >= coupon.coupon.usdLimitOfUsePrice);
          const isSelected = thisCouponId === coupon.id;
          return (
            <CouponCard
              onClick={
                isAvailableLimit ? () => handleCouponClick(coupon) : undefined
              }
              className={`${!isAvailableLimit ? "un-available" : ""} ${
                isSelected ? "selected" : ""
              }`}
            >
              <CouponInfo>
                <div className="good">
                  {convertToUseCondition(coupon.coupon.useCondition)}{" "}
                  {convertToGood(
                    coupon.coupon.discountType,
                    coupon.coupon.percentDiscountPrice,
                    coupon.coupon.krwDiscountPrice,
                    coupon.coupon.usdDiscountPrice
                  )}
                </div>
                <div className="name">{coupon.coupon.name}</div>
                <div className="limit">
                  <div>
                    {`More than ${coupon.coupon.krwLimitOfUsePrice?.toLocaleString(
                      "ko-KR"
                    )} ₩ available`}
                  </div>
                  <div>
                    {`More than ${coupon.coupon.usdLimitOfUsePrice?.toLocaleString(
                      "ko-KR"
                    )} $ available`}
                  </div>
                </div>
                <div className="due">{`${convertToDueString(
                  coupon.coupon.validityType,
                  coupon.coupon.validityDurationStart,
                  coupon.coupon.validityDurationEnd,
                  coupon.coupon.validityDate
                )}`}</div>
              </CouponInfo>

              <CouponLogo className="logo">ontact</CouponLogo>
            </CouponCard>
          );
        })}

      <ApplyButton onClick={handleApplyCouponButton}>Apply Coupon</ApplyButton>
    </Wrapper>
  );
};

const ApplyButton = styled.div`
  display: flex;
  justify-content: center;
  background-color: var(--color-main-blue);
  color: var(--color-white);
  padding: 1rem;
  border-radius: 0.4rem;
  font-weight: bold;

  &:hover {
    background-color: var(--color-main-blue-hover);
  }
`;

const CouponInfo = styled.div`
  flex: 7 1 0;
  padding: 1.4rem;
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  .good {
    font-weight: bold;
    font-size: 1.6rem;
    color: var(--color-main-blue);
  }
  .name {
    font-weight: bold;
    font-size: 1.2rem;
  }
  .due {
    color: var(--color-main-blue);
  }
`;
const CouponLogo = styled.div`
  background-color: var(--color-main-blue);
  color: var(--color-white);
  flex: 1 1 0;
  font-size: 1.8rem;
  font-weight: bold;
  writing-mode: vertical-rl;
  align-items: center;
  display: flex;
  justify-content: center;
`;
const CouponCard = styled.div`
  display: flex;
  border: 2px solid var(--color-main-blue);
  border-radius: 0.4rem;

  &:hover,
  &:active {
    background-color: var(--color-lightgray);
  }

  /* 사용 불가 쿠폰 (제한 금액) */
  &.un-available {
    border: 2px solid lightgray;

    &:hover,
    &:active {
      background-color: white;
    }

    /* 자식 요소 스타일 변경 */
    * {
      color: lightgray !important;
    }

    .logo {
      background-color: lightgray;
      color: var(--color-white) !important;
    }

    .good,
    .due,
    .name {
      color: lightgray;
    }

    .limit div {
      color: var(--color-main-blue) !important;
    }
  }

  &.selected {
    background-color: var(--color-lightgray);
  }
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  gap: 1rem;
`;

export default CouponList;
