import React, { useEffect, useState } from "react";
import {
  CODE_SHIPPING_PAYMENT_STATUS,
  CODE_SHOPPING_ORDER_STATUS,
  ADMIN_BADGE,
} from "../../../common/constants";
import ShopDetailsModal from "../../../components/modal/ShopDetailsModal";
import { reqAShop } from "../../../requests/shop";
import AdminHeadTitle from "../../../components/AdminHeadTitle";
import styled from "styled-components";
import { reqReadAdminBadge } from "../../../requests/badge";
import { Pagination } from "react-bootstrap";
import AdminHeadFilter from "../../../components/AdminHeadFilter";
import ShopCancelModal from "../../../components/modal/ShopCancelModal";
import { formatDate } from "../../../common/format";
type OrderData = {
  orderId: string;
  userId: string;
  createdAt: string;
  PaymentMethod: number;
  updatedAt: string;
  preorder: boolean;
  fancall: boolean;
  totalPriceUSD: number;
  totalPriceKRW: number;
  status: number;
};

const AShopCancel: React.FC = () => {
  const [searchCategory, setSearchCategory] = useState("orderId");
  const [allData, setAllData] = useState<OrderData[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [currentOrder, setCurrentOrder] = useState<string | null>(null);
  const [orders, setOrders] = useState<OrderData[]>([]);
  const ITEMS_PER_PAGE = 10;

  const handleOrderClick = (orderId: string) => {
    setCurrentOrder(orderId);
    setShowModal(true);
  };

  const handleSelectClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  const displayPaymentAmount = (order: OrderData) => {
    switch (order.PaymentMethod) {
      case 1:
        return `${order.totalPriceKRW} KRW`;
      case 2:
        return `${order.totalPriceUSD} USD`;
      default:
        return "-";
    }
  };

  useEffect(() => {
    fetchOrders();

    const badgePayload = {
      badgeName: ADMIN_BADGE.SHOP_REQUEST_CANCEL,
    };
    reqReadAdminBadge(badgePayload);
  }, []);

  const fetchOrders = async () => {
    try {
      const response = await reqAShop();
      const filteredOrders = response.data.filter(
        (order: OrderData) => order.status === 10
      );
      setOrders(filteredOrders);
      setAllData(filteredOrders);
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };

  const getPaymentMethod = (methodCode: number) => {
    return CODE_SHIPPING_PAYMENT_STATUS[methodCode] || "알 수 없음";
  };

  const getstatusMethod = (methodCode: number) => {
    return CODE_SHOPPING_ORDER_STATUS[methodCode] || "알 수 없음";
  };

  // 띄어쓰기
  // const handleKeyPress = (event: any) => {
  //     if (event.key === " ") {
  //         event.preventDefault();
  //     }
  // };

  //! 필터 시작
  const [filteredData, setFilteredData] = useState<any[]>([]);

  const [searchWord, setSearchWord] = useState<string>("");
  const [searchOption, setSearchOption] = useState<string>("");

  const [selectedStatus, setSelectedStatus] = useState("");

  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    const thisFilteredData = orders
      .filter((order: any) =>
        order.orderId.toUpperCase().includes(searchWord.toUpperCase())
      )
      .sort(
        (a: any, b: any) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      );
    // const thisFilteredData = orders.filter(
    //   (order) =>
    //     order.orderId.toLowerCase().includes(searchWord.toLowerCase()) &&
    //     (selectedStatus ? order.status.toString() === selectedStatus : true)
    // );
    setFilteredData(thisFilteredData);
    setCurrentPage(1);
    setTotalPages(Math.ceil(thisFilteredData.length / itemsPerPage));
  }, [orders, searchWord, itemsPerPage, selectedStatus]);

  const handleItemsPerPageChange = (newItemsPerPage: number) => {
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1);
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const displayedOrders = filteredData.slice(
    startIndex,
    startIndex + itemsPerPage
  );
  //! 필터 끝

  return (
    <main id="main" className="main">
      <AdminHeadTitle
        subtitle1="구매대행"
        subtitle2="구매대행 취소내역"
        title="구매대행 취소내역"
      />

      {/* 옵션 필터 */}
      <AdminHeadFilter
        selectViewPage={(value: number) => handleItemsPerPageChange(value)}
        enterSearch={(value: string) => setSearchWord(value)}
        selectSearchOption={(value: string) => setSearchOption(value)}
      />

      <div className="container-fluid">
        <table className="table table-hover text-center">
          <thead>
            <tr>
              <th>주문번호</th>
              <th>스페이스코드</th>
              <th>주문날짜</th>
              <th>결제날짜</th>
              <th>결제수단</th>
              <th>결제금액</th>
              <th>주문상태</th>
            </tr>
          </thead>
          <tbody>
            {displayedOrders.map((order: any) => (
              <tr
                key={order.orderId}
                onClick={() => handleOrderClick(order.orderId)}
                style={{ cursor: "pointer" }}
              >
                <th>{order.orderId}</th>
                <th>{order.userId}</th>
                <td>{formatDate(order.createdAt)}</td>
                <td>{formatDate(order.updatedAt)}</td>
                <td>{getPaymentMethod(order.PaymentMethod)}</td>
                <td>{displayPaymentAmount(order)}</td>
                <th>{getstatusMethod(order.status)}</th>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* 페이지 바 */}
      <CenteredPagination style={{ marginTop: "1rem" }}>
        {[...Array(totalPages)].map((_, index) => (
          <Pagination.Item
            key={index}
            active={index + 1 === currentPage}
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </Pagination.Item>
        ))}
      </CenteredPagination>

      {currentOrder && (
        <ShopCancelModal
          show={showModal}
          onHide={() => setShowModal(false)}
          orderId={currentOrder}
        />
      )}
    </main>
  );
};

const CenteredPagination = styled(Pagination)`
  display: flex;
  justify-content: center;
`;

export default AShopCancel;
