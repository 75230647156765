import { useEffect, useState } from "react";
import { reqShipFEE, reqShipFEEUpdate } from "../../../requests/ship";
import styled from "styled-components";
import AdminHeadTitle from "../../../components/AdminHeadTitle";

type CarrierType = "fedex" | "ups" | "dhl" | "ems" | "kpacket" | "fixed" | "fix";

export default function Ashipmentfee() {
  const [fuelSurcharge, setFuelSurcharge] = useState<{
    fedex: number;
    ups: number;
    dhl: number;
  }>({ fedex: 35, ups: 35, dhl: 35 });
  const [exchangeRate, setExchangeRate] = useState({ google: "", naver: "" });
  const [serviceFees, setServiceFees] = useState<Record<CarrierType, number>>({
    fedex: 0,
    ups: 0,
    dhl: 0,
    ems: 0,
    kpacket: 0,
    fixed: 0,
    fix: 0,
  });
  const [editMode, setEditMode] = useState<boolean>(false);

  useEffect(() => {
    reqShipFEE()
      .then((response) => {
        const data = response.data[0];
        setServiceFees({
          fedex: parseFloat(data.fedexServiceFee),
          ups: parseFloat(data.upsServiceFee),
          dhl: parseFloat(data.dhlServiceFee),
          ems: parseFloat(data.emsServiceFee),
          kpacket: parseFloat(data.kpacketServiceFee),
          fixed: parseFloat(data.fixedExchangeRate),
          fix: parseFloat(data.fixedExchangeRateShop),
        });
      })
      .catch((error) => {
        console.error("배송 구성 가져오기 오류:", error);
      });
  }, []);

  const handleServiceFeeChange = (carrier: any, value: any) => {
    const stringValue = value.toString();
    setServiceFees((prev) => ({ ...prev, [carrier]: stringValue }));
  };

  const updateFeeData = async () => {
    await reqShipFEEUpdate(1, {
      fedexServiceFee: serviceFees.fedex,
      upsServiceFee: serviceFees.ups,
      dhlServiceFee: serviceFees.dhl,
      emsServiceFee: serviceFees.ems,
      kpacketServiceFee: serviceFees.kpacket,
      fixedExchangeRate: serviceFees.fixed,
      fixedExchangeRateShop: serviceFees.fix,
    });
  };

  const handleExchangeRateChange = (name: any, value: any) => {
    setExchangeRate((prev) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    // fetchExchangeRate();
  }, []);

  // const fetchExchangeRate = () => {
  //   fetch(
  //     "https://quotation-api-cdn.dunamu.com/v1/forex/recent?codes=FRX.KRWUSD"
  //   )
  //     .then((response) => response.json())
  //     .then((data) => {
  //       const rate = data[0].basePrice;
  //       setExchangeRate((prev) => ({ ...prev, naver: rate }));
  //     })
  //     .catch((error) => console.error("Error fetching exchange rate:", error));
  // };

  return (
    <main id="main" className="main">
      <AdminHeadTitle
        subtitle1="배송 운임 관리"
        subtitle2="배송 운임 설정"
        title="배송 운임 설정"
      />

      <div className="f-col">
        <div className="f-row">
          {/* 환율 설정*/}
          <div className="f-col">
            <div>
              {/* <div className="input-group mb-3">
                <span className="input-group-text" id="basic-addon1">
                  Google
                </span>
                <input
                  type="text"
                  className="form-control"
                  value={exchangeRate.google}
                  placeholder="비용발생"
                  aria-label="구글환율"
                  aria-describedby="basic-addon1"
                />
              </div> */}
              <div className="input-group mb-3">
                <span className="input-group-text" id="basic-addon1">
                  Naver
                </span>
                <input
                  type="text"
                  className="form-control"
                  value={exchangeRate.naver}
                  onChange={(e) =>
                    handleExchangeRateChange("naver", e.target.value)
                  }
                  disabled={!editMode}
                  placeholder="Naver 환율"
                  aria-label="Naver 환율"
                  aria-describedby="basic-addon1"
                />
              </div>
              <div className="input-group mb-3">
                <span className="input-group-text bg-warning" id="basic-addon1">
                  배송대행 고정환율
                </span>
                <input
                  type="text"
                  className="form-control text-end"
                  value={serviceFees.fixed}
                  onChange={(e) =>
                    handleServiceFeeChange("fixed", e.target.value)
                  }
                  disabled={!editMode}
                  placeholder="35%"
                  aria-label="35%"
                  aria-describedby="basic-addon1"
                />
              </div>
              <div className="input-group mb-3">
                <span className="input-group-text bg-warning" id="basic-addon1">
                  구매대행 고정환율
                </span>
                <input
                  type="text"
                  className="form-control text-end"
                  value={serviceFees.fix}
                  onChange={(e) =>
                    handleServiceFeeChange("fix", e.target.value)
                  }
                  disabled={!editMode}
                  placeholder="35%"
                  aria-label="35%"
                  aria-describedby="basic-addon1"
                />
              </div>
            </div>
          </div>

          {/* 서비스비용 설정*/}
          <div className="f-col">
            <div className="container">
              <div className="input-group mb-3">
                <span className="input-group-text bg-warning" id="basic-addon1">
                  FEDEX
                </span>
                <input
                  type="text"
                  className="form-control text-end"
                  value={serviceFees.fedex}
                  onChange={(e) =>
                    handleServiceFeeChange("fedex", e.target.value)
                  }
                  disabled={!editMode}
                  placeholder="35%"
                  aria-label="35%"
                  aria-describedby="basic-addon1"
                />
                <Percent>%</Percent>
              </div>
              <div className="input-group mb-3">
                <span className="input-group-text bg-warning" id="basic-addon1">
                  UPS
                </span>
                <input
                  type="text"
                  className="form-control text-end"
                  value={serviceFees.ups}
                  onChange={(e) =>
                    handleServiceFeeChange("ups", e.target.value)
                  }
                  disabled={!editMode}
                  placeholder="35%"
                  aria-label="35%"
                  aria-describedby="basic-addon1"
                />
                <Percent>%</Percent>
              </div>
              <div className="input-group mb-3">
                <span className="input-group-text bg-warning" id="basic-addon1">
                  DHL
                </span>
                <input
                  type="text"
                  className="form-control text-end"
                  value={serviceFees.dhl}
                  onChange={(e) =>
                    handleServiceFeeChange("dhl", e.target.value)
                  }
                  disabled={!editMode}
                  placeholder="35%"
                  aria-label="35%"
                  aria-describedby="basic-addon1"
                />
                <Percent>%</Percent>
              </div>
              <div className="input-group mb-3">
                <span className="input-group-text bg-warning" id="basic-addon1">
                  EMS
                </span>
                <input
                  type="text"
                  className="form-control text-end"
                  value={serviceFees.ems}
                  onChange={(e) =>
                    handleServiceFeeChange("ems", e.target.value)
                  }
                  disabled={!editMode}
                  placeholder="35%"
                  aria-label="35%"
                  aria-describedby="basic-addon1"
                />
                <Percent>%</Percent>
              </div>
              <div className="input-group mb-3">
                <span className="input-group-text bg-warning" id="basic-addon1">
                  K-PACKET
                </span>
                <input
                  type="text"
                  className="form-control text-end"
                  value={serviceFees.kpacket}
                  onChange={(e) =>
                    handleServiceFeeChange("kpacket", e.target.value)
                  }
                  disabled={!editMode}
                  placeholder="35%"
                  aria-label="35%"
                  aria-describedby="basic-addon1"
                />
                <Percent>%</Percent>
              </div>
            </div>
          </div>
        </div>

        <div>
          {editMode ? (
            <button
              className="btn btn-success"
              type="button"
              onClick={() => {
                updateFeeData();
                setEditMode(false);
              }}
              style={{ width: "15vw" }}
            >
              저장
            </button>
          ) : (
            <button
              className="btn btn-success"
              type="button"
              onClick={() => {
                setEditMode(true);
              }}
              style={{
                width: "15vw",
                border: "2px solid var(--color-main-blue)",
                backgroundColor: "transparent",
                color: "var(--color-main-blue)",
              }}
            >
              수정
            </button>
          )}
        </div>
      </div>
    </main>
  );
}

const Percent = styled.div`
  display: flex;
  align-items: center;
  padding: 0 0.4rem;
  border-top: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
`;
