export default function MTitle(props: { title: string }) {
  return (
    <>
      <div
        style={{
          marginBottom: "50px",
          marginTop: "5px",
          color: "var(--color-main-blue)",
          borderRadius: "30px",
          fontWeight: "bold",
          fontSize: "24px",
        }}
      >
        {props.title}
      </div>
    </>
  );
}
