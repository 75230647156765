import React, { Fragment, useEffect, useState } from "react";
import { Button, Form, InputGroup, Modal, Table } from "react-bootstrap";
import {
  CODE_SHIPPING_PRODUCT_STATUS,
  CODE_SHOPING_ORDER_STATUS,
} from "../../common/constants";
import { formatDate } from "../../common/format";
import { reqShipFEE } from "../../requests/ship";
import {
  reqShop,
  reqShopDetail,
  reqShopDetailUpdate,
  reqShopUpdate,
} from "../../requests/shop";
interface ShopOrderModalProps {
  show: boolean;
  onHide: () => void;
  orderId: string;
  refreshData: () => void;
}
interface OrderDetail {
  order_id: string;
  status: number;
  name: string;
  option1: string;
  option2: string;
  quantity: number;
  price: number;
  ShippingFee: number;
  url: string;
  fee: number;
  paymentDeadline: string;
  cancellationReason: string;
  preorderStartAt: string;
  preorderEndAt: string;
  isCanceled: boolean;
}

interface ShopData {
  account: string;
  failed: boolean;
  refund: boolean;
}
interface UpdatedOrderDetails {
  [key: string]: OrderDetail;
}

const WisePreorderModal = ({
  show,
  onHide,
  orderId,
  refreshData,
}: ShopOrderModalProps) => {
  const [orderDetails, setOrderDetails] = useState<OrderDetail[]>([]);
  const [serviceFee, setServiceFee] = useState("");
  const [cancellationReason, setCancellationReason] = useState("");
  const [paymentDeadline, setPaymentDeadline] = useState("");
  const [exchangeRate, setExchangeRate] = useState(0);
  const [UtotalUSD, setUtotalUSD] = useState(0);
  const [shopData, setShopData] = useState<ShopData | null>(null);
  const [confirmSendModalShow, setConfirmSendModalShow] = useState(false);
  const [confirmCancelModalShow, setConfirmCancelModalShow] = useState(false);
  const [updatedOrderDetails, setUpdatedOrderDetails] =
    useState<UpdatedOrderDetails>({});

  const handleCancelItem = async (index: number) => {
    const updatedDetails = {
      ...orderDetails[index],
      isCanceled: true,
      status: CODE_SHIPPING_PRODUCT_STATUS.CANCELED,
      order_id: orderId,
    };

    setOrderDetails((prevDetails) =>
      prevDetails.map((detail, idx) =>
        idx === index ? updatedDetails : detail
      )
    );

    try {
      await reqShopDetailUpdate([updatedDetails]);
      alert("상품이 취소되었습니다.");
    } catch (error) {
      console.error("상품 취소 실패:", error);
      alert("상품 취소에 실패했습니다.");
    }
  };

  const convertToUSD = (amount: number) => {
    return (amount / fixedExchangeRate).toFixed(2);
  };

  const [fixedExchangeRate, setFixedExchangeRate] = useState(0);

  const fetchFixedExchangeRate = async () => {
    try {
      const response = await reqShipFEE();
      if (response && response.data && response.data[0]?.fixedExchangeRateShop) {
        setFixedExchangeRate(parseFloat(response.data[0].fixedExchangeRateShop));
      }
    } catch (error) {
      console.error("Error fetching fixed exchange rate:", error);
    }
  };

  useEffect(() => {
    // fetchExchangeRate();
    fetchFixedExchangeRate();
    if (orderId) {
      fetchOrderDetails(orderId);
    }
  }, [orderId]);

  // const fetchExchangeRate = () => {
  //   fetch(
  //     "https://quotation-api-cdn.dunamu.com/v1/forex/recent?codes=FRX.KRWUSD"
  //   )
  //     .then((response) => response.json())
  //     .then((data) => {
  //       const rate = data[0].basePrice;
  //       setExchangeRate(rate);
  //     })
  //     .catch((error) => console.error("Error fetching exchange rate:", error));
  // };

  useEffect(() => {
    if (orderId) {
      fetchOrderDetails(orderId);
    }
  }, [orderId]);

  const fetchOrderDetails = async (order_id: string) => {
    try {
      const detailResponse = await reqShopDetail({ order_id });
      const detailsWithStatus: OrderDetail[] = detailResponse.data.map(
        (item: OrderDetail) => ({
          ...item,
          isCanceled: item.status === 10,
        })
      );
      setOrderDetails(detailsWithStatus);

      const shopResponse = await reqShop({ order_id });
      const feeValue = shopResponse.data.fee?.toString() ?? "";
      setServiceFee(feeValue);
      setShopData(shopResponse.data);
    } catch (error) {
      console.error("Error fetching order details:", error);
    }
  };

  const calculateTotal = () => {
    const productTotal = orderDetails.reduce((total, item) => {
      if (!item.isCanceled) {
        return total + item.quantity * item.price;
      }
      return total;
    }, 0);

    const domesticShippingFee = orderDetails.reduce((total, item) => {
      if (!item.isCanceled) {
        return total + item.ShippingFee;
      }
      return total;
    }, 0);

    const parsedServiceFee = parseFloat(serviceFee);
    let serviceFeeValue;
    if (!isNaN(parsedServiceFee) && parsedServiceFee > 0) {
      serviceFeeValue = productTotal * (parsedServiceFee / 100);
    } else {
      serviceFeeValue = productTotal > 50000 ? productTotal * 0.1 : 5000;
    }

    const paypalFee =
      (productTotal + serviceFeeValue + domesticShippingFee) * 0.05;
    const UtotalUSDValue = convertToUSD(
      productTotal + domesticShippingFee + serviceFeeValue + paypalFee
    );

    return {
      productTotal,
      domesticShippingFee,
      serviceFeeValue,
      paypalFee,
      total: productTotal + domesticShippingFee + serviceFeeValue,
      Utotal: productTotal + domesticShippingFee + serviceFeeValue + paypalFee,
      UtotalUSD: parseFloat(UtotalUSDValue),
    };
  };

  const {
    productTotal,
    domesticShippingFee,
    serviceFeeValue,
    paypalFee,
    total,
    Utotal,
  } = calculateTotal();

  const updateServiceFee = async () => {
    try {
      const updateData = {
        order_id: orderId,
        fee: parseInt(serviceFee),
      };

      const response = await reqShopUpdate(updateData);
      console.log("Service fee updated:", response);
      alert("서비스 비용이 업데이트되었습니다.");
    } catch (error) {
      console.error("Error updating service fee:", error);
      alert("서비스 비용 업데이트에 실패했습니다.");
    }
  };

  const handleOrderDetailChange = (
    index: any,
    field: keyof OrderDetail,
    value: any
  ) => {
    setOrderDetails((prevDetails) =>
      prevDetails.map((detail, idx) =>
        idx === index ? { ...detail, [field]: value } : detail
      )
    );

    setUpdatedOrderDetails((prevDetails) => {
      const updatedDetails = { ...prevDetails };
      updatedDetails[index] = {
        ...(updatedDetails[index] || orderDetails[index]),
        [field]: value,
      };
      return updatedDetails;
    });
  };

  const handleSendEstimateClick = () => {
    setConfirmSendModalShow(true);
  };

  const handleConfirmSendEstimate = async () => {
    try {
      setConfirmSendModalShow(false);
      await handleSendEstimate();
    } catch (error) {
      console.error("Error during confirmation:", error);
    }
  };

  const handleSendEstimate = async () => {
    try {
      const updatedDetailsArray = Object.values(updatedOrderDetails);

      await reqShopDetailUpdate(updatedDetailsArray);

      const totals = calculateTotal();
      setUtotalUSD(totals.UtotalUSD);
      const updateData = {
        order_id: orderId,
        cancellationReason: cancellationReason,
        paymentDeadline: paymentDeadline,
        totalPriceKRW: totals.total,
        totalPriceUSD: totals.UtotalUSD,
      };

      await reqShopUpdate(updateData);
      onHide();
      refreshData();
    } catch (error) {
      console.error("견적서 업데이트 실패:", error);
      alert("마감 일자를 지정해주세요.");
    }
  };

  const handleCancelOrderClick = () => {
    setConfirmCancelModalShow(true);
  };

  const handleConfirmCancelOrder = async () => {
    try {
      setConfirmCancelModalShow(false);
      await handleCancelOrder();
    } catch (error) {
      console.error("Cancellation failed:", error);
    }
  };

  const handleCancelOrder = async () => {
    try {
      const updateData = {
        order_id: orderId,
        status: CODE_SHOPING_ORDER_STATUS.CANCELED,
      };

      await reqShopUpdate(updateData);
      alert("주문이 취소되었습니다.");
      onHide();
      refreshData();
    } catch (error) {
      console.error("주문 취소 실패:", error);
      alert("주문 취소에 실패했습니다.");
    }
  };

  return (
    <Fragment>
      <Modal show={show} onHide={onHide} fullscreen centered>
        <Modal.Header className="bg-dark text-white">
          <Modal.Title>{orderId}</Modal.Title>
          <button className="btn-close-custom" onClick={onHide}>
            &#10006;
          </button>
        </Modal.Header>

        <Modal.Body>
          <div className="container-fluid mb-4">
            <Table bordered className="text-center">
              <thead>
                <tr>
                  <th>NO</th>
                  <th>상품명</th>
                  <th>옵션 1</th>
                  <th>옵션 2</th>
                  <th>수량</th>
                  <th>단가</th>
                  <th>국내배송비</th>
                  <th>Link</th>
                  <th>취소</th>
                </tr>
              </thead>
              <tbody>
                {orderDetails.map((detail, index) => (
                  <React.Fragment key={index}>
                    <tr>
                      <th
                        style={
                          detail.isCanceled
                            ? { textDecoration: "line-through" }
                            : undefined
                        }
                      >
                        {index + 1}
                      </th>
                      <td
                        style={
                          detail.isCanceled
                            ? { textDecoration: "line-through" }
                            : undefined
                        }
                      >
                        {detail.name}
                      </td>
                      <td
                        style={
                          detail.isCanceled
                            ? { textDecoration: "line-through" }
                            : undefined
                        }
                      >
                        {detail.option1}
                      </td>
                      <td
                        style={
                          detail.isCanceled
                            ? { textDecoration: "line-through" }
                            : undefined
                        }
                      >
                        {detail.option2}
                      </td>
                      <td
                        style={
                          detail.isCanceled
                            ? { textDecoration: "line-through" }
                            : undefined
                        }
                      >
                        <Form.Control
                          type="number"
                          value={detail.quantity}
                          onChange={(e) =>
                            handleOrderDetailChange(
                              index,
                              "quantity",
                              parseInt(e.target.value)
                            )
                          }
                        />
                      </td>
                      <td
                        style={
                          detail.isCanceled
                            ? { textDecoration: "line-through" }
                            : undefined
                        }
                      >
                        <Form.Control
                          type="number"
                          value={detail.price}
                          onChange={(e) =>
                            handleOrderDetailChange(
                              index,
                              "price",
                              parseFloat(e.target.value)
                            )
                          }
                        />
                      </td>
                      <td
                        style={
                          detail.isCanceled
                            ? { textDecoration: "line-through" }
                            : undefined
                        }
                      >
                        <Form.Control
                          type="number"
                          value={detail.ShippingFee}
                          onChange={(e) =>
                            handleOrderDetailChange(
                              index,
                              "ShippingFee",
                              parseFloat(e.target.value)
                            )
                          }
                        />
                      </td>
                      <td
                        style={
                          detail.isCanceled
                            ? { textDecoration: "line-through" }
                            : undefined
                        }
                      >
                        <a href={detail.url}>링크</a>
                      </td>
                      <td>
                        {detail.isCanceled ? (
                          <Button variant="secondary" size="sm" disabled>
                            <i className="bi bi-x"></i>
                          </Button>
                        ) : (
                          <Button
                            variant="secondary"
                            size="sm"
                            onClick={() => handleCancelItem(index)}
                          >
                            <i className="bi bi-x"></i>
                          </Button>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={9}>
                        <table className="table table-sm text-center">
                          <tbody>
                            <tr>
                              <th>시작일</th>
                              <td>{formatDate(detail.preorderStartAt)}</td>
                            </tr>
                            <tr>
                              <th>마감일</th>
                              <td>{formatDate(detail.preorderEndAt)}</td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            </Table>
          </div>
          <div className="container-fluid mb-3">
            <Table bordered className="text-center table-dark table-hover">
              <thead>
                <tr>
                  <th>Title</th>
                  <th>KRW</th>
                  <th>USD</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>Product total</th>
                  <td>{productTotal}</td>
                  <td>{convertToUSD(productTotal)}</td>
                </tr>
                <tr>
                  <th>Domestic Shipping Cost</th>
                  <td>{domesticShippingFee}</td>
                  <td>{convertToUSD(domesticShippingFee)}</td>
                </tr>
                <tr>
                  <th>
                    <InputGroup>
                      <InputGroup.Text className="bg-dark text-white fw-bold">
                        Service Cost
                      </InputGroup.Text>
                      <Form.Control
                        type="number"
                        placeholder="10"
                        value={serviceFee}
                        onChange={(e) => setServiceFee(e.target.value)}
                      />
                      <InputGroup.Text>%</InputGroup.Text>
                      <Button variant="primary" onClick={updateServiceFee}>
                        Update
                      </Button>
                    </InputGroup>
                  </th>
                  <td>{serviceFeeValue}</td>
                  <td>{convertToUSD(serviceFeeValue)}</td>
                </tr>
                <tr>
                  <th>Paypal Cost(5%)</th>
                  <th>-</th>
                  <td>{convertToUSD(paypalFee)}</td>
                </tr>
                <tr>
                  <th>Total</th>
                  <td>{total}</td>
                  <td>{convertToUSD(Utotal)}</td>
                </tr>
                <tr>
                  <th>
                    <InputGroup>
                      <InputGroup.Text>결제마감일</InputGroup.Text>
                      <Form.Control
                        type="datetime-local"
                        className="form-control"
                        value={paymentDeadline}
                        onChange={(e) => setPaymentDeadline(e.target.value)}
                      />
                    </InputGroup>
                  </th>
                  <td>
                    <InputGroup>
                      <InputGroup.Text id="inputGroup-sizing-default">
                        취소사유
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        aria-label="Sizing example input"
                        aria-describedby="inputGroup-sizing-default"
                        value={cancellationReason}
                        onChange={(e) => setCancellationReason(e.target.value)}
                      />
                    </InputGroup>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
          <div className="container-fluid">
            <Table bordered className="text-center table-dark table-hover">
              <div className="container-fluid">
                <Table bordered className="text-center table-dark table-hover">
                  <tbody>
                    <tr>
                      <th>환불 계좌</th>
                      <td>{shopData?.account}</td>
                    </tr>
                    <tr>
                      <th>주문 취소 유형</th>
                      <td>
                        {shopData?.failed
                          ? "Total cancellation"
                          : "Partial cancellation (purchase available items)"}
                      </td>
                    </tr>
                    <tr>
                      <th>환불 유형</th>
                      <td>
                        {shopData?.refund
                          ? "Regular refund"
                          : "Refund with points(+5% extra point)"}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancelOrderClick}>
            전체취소
          </Button>
          <Button variant="success" onClick={handleSendEstimateClick}>
            수정
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={confirmSendModalShow}
        onHide={() => setConfirmSendModalShow(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>견적서 수정 확인</Modal.Title>
        </Modal.Header>
        <Modal.Body>견적서를 수정하시겠습니까?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setConfirmSendModalShow(false)}
          >
            취소
          </Button>
          <Button variant="primary" onClick={handleConfirmSendEstimate}>
            수정
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={confirmCancelModalShow}
        onHide={() => setConfirmCancelModalShow(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>주문 취소 확인</Modal.Title>
        </Modal.Header>
        <Modal.Body>정말로 주문을 취소하시겠습니까?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setConfirmCancelModalShow(false)}
          >
            아니요
          </Button>
          <Button variant="danger" onClick={handleConfirmCancelOrder}>
            네, 취소할게요
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};
export default WisePreorderModal;
