import { useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { MEMBERSHIP } from "../../../common/constants";
import { reqGetAllUserById } from "../../../requests/user";
import {
  reqGetMembership,
  reqPutMembership,
} from "../../../requests/warehouse";
import AdminHeadTitle from "../../../components/AdminHeadTitle";

interface User {
  id: string;
  name: string;
  country: number;
  email: string;
  point: number;
  latestWarehouseId: string;
  membershipId: number;
}

interface MembershipDetail {
  id: number;
  name: string;
  signupFeeRed: number;
  signupFeeGreen: number;
  signupFeeBlue: number;
  repurchaseDiscountRed: number;
  repurchaseDiscountGreen: number;
  repurchaseDiscountBule: number;
  serviceFeeRed: number;
  serviceFeeGreen: number;
  serviceFeeBlue: number;
  contentCouponRed: number;
  contentCouponGreen: number;
  contentCouponBlue: number;
  freeStorageDaysRed: number;
  freeStorageDaysGreen: number;
  freeStorageDaysBlue: number;
  version: number;
  [key: string]: number | string;
}

const Amemberships = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [membershipCounts, setMembershipCounts] = useState<{
    [key: number]: number;
  }>({});
  const [showModal, setShowModal] = useState(false);
  const toggleModal = () => setShowModal(!showModal);
  const [showPolicyModal, setShowPolicyModal] = useState(false);
  const [showUsersModal, setShowUsersModal] = useState(false);
  const [selectedMembershipUsers, setSelectedMembershipUsers] = useState<
    User[]
  >([]);
  const [membershipDetails, setMembershipDetails] = useState<
    MembershipDetail[]
  >([]);
  const [editDetails, setEditDetails] = useState<MembershipDetail[]>([]);

  useEffect(() => {
    fetchAllUsers();
    fetchMembershipDetails();
  }, []);

  const fetchAllUsers = async () => {
    try {
      const response = await reqGetAllUserById();
      setUsers(response.data);
      calculateMembershipCounts(response.data);
    } catch (error) {
      console.error("전체 사용자 정보 조회 실패:", error);
    }
  };

  const fetchMembershipDetails = async () => {
    try {
      const response = await reqGetMembership();
      setMembershipDetails(response.data);
      setEditDetails(response.data);
    } catch (error) {
      console.error("멤버쉽 정보 조회 실패:", error);
    }
  };

  const handleInputChange = (
    index: number,
    field: keyof MembershipDetail,
    value: string
  ) => {
    const newValue = !isNaN(Number(value)) ? parseFloat(value) : 0;

    setEditDetails((prevDetails) =>
      prevDetails.map((detail, idx) =>
        idx === index ? { ...detail, [field]: newValue } : detail
      )
    );
  };

  const saveMembershipChanges = async (index: number) => {
    const detail = editDetails[index];
    if (!detail) return;

    const originalDetail = membershipDetails.find((d) => d.id === detail.id);
    if (!originalDetail) return;

    const payload = { id: detail.id, version: detail.version };

    Object.keys(detail).forEach((key) => {
      const detailKey = key as keyof MembershipDetail;
      if (detail[detailKey] !== originalDetail[detailKey]) {
        (payload as any)[detailKey] = detail[detailKey];
      }
    });

    try {
      await reqPutMembership(payload);
      alert("Changes saved successfully");
      fetchMembershipDetails();
    } catch (error) {
      console.error("Failed to save membership changes:", error);
      alert("Failed to save changes");
    }
  };

  const calculateMembershipCounts = (users: User[]) => {
    const counts: { [key: number]: number } = {};
    users.forEach((user) => {
      counts[user.membershipId] = (counts[user.membershipId] || 0) + 1;
    });
    setMembershipCounts(counts);
  };

  const handleMembershipCountClick = (membershipId: number) => {
    const selectedUsers = users.filter(
      (user) => user.membershipId === membershipId
    );
    setSelectedMembershipUsers(selectedUsers);
    setShowUsersModal(true);
  };

  return (
    <main id="main" className="main">
      <div>
        <div className="pagetitle" style={{ marginBottom: "20px" }}>
          <AdminHeadTitle
            subtitle1="최고 관리자"
            subtitle2="멤버십 관리"
            title="멤버십 관리"
          />
          <Button variant="primary" onClick={toggleModal}>
            등급별 조정 정책 보기
          </Button>
        </div>
        <div className="container-fluid" style={{ marginBottom: "20px" }}>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>멤버쉅</th>
                <th>회원수</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(membershipCounts).map((membershipId, index) => (
                <tr key={index}>
                  <td>{MEMBERSHIP[parseInt(membershipId)]}</td>
                  <td
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      handleMembershipCountClick(parseInt(membershipId))
                    }
                  >
                    {membershipCounts[parseInt(membershipId)]}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        <Modal show={showModal} onHide={toggleModal} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>등급별 조정 정책</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-dialog modal-xl">
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className="modal-title fs-5">할인율 설정하기</h1>
                </div>
                <div className="modal-body">
                  {membershipDetails.map((detail, index) => (
                    <div key={index}>
                      <table className="table text-center">
                        <tr>
                          <th>항목</th>
                          <th>Red</th>
                          <th>Blue</th>
                          <th>Green</th>
                        </tr>
                        <tbody>
                          <tr>
                            <th>멤버쉽 가입비용</th>
                            <td>${detail.signupFeeRed}</td>
                            <td>${detail.signupFeeBlue}</td>
                            <td>${detail.signupFeeGreen}</td>
                          </tr>
                          <tr>
                            <th>리팩피 할인율</th>
                            <td>{detail.repurchaseDiscountRed}%</td>
                            <td>{detail.repurchaseDiscountBule}%</td>
                            <td>{detail.repurchaseDiscountGreen}%</td>
                          </tr>
                          <tr>
                            <th>구매대행 서비스 비용</th>
                            <td>{detail.serviceFeeRed}%</td>
                            <td>{detail.serviceFeeBlue}%</td>
                            <td>{detail.serviceFeeGreen}%</td>
                          </tr>
                          <tr>
                            <th>체크컨텐츠 쿠폰지급</th>
                            <td>{detail.contentCouponRed}</td>
                            <td>{detail.contentCouponBlue}</td>
                            <td>{detail.contentCouponGreen}</td>
                          </tr>
                          <tr>
                            <th>창고 무료 이용기한</th>
                            <td>{detail.freeStorageDaysRed}일</td>
                            <td>{detail.freeStorageDaysGreen}일</td>
                            <td>패키지당 30일</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  ))}
                </div>
                <hr />
                <div className="container">
                  <h3>할인율 변경</h3>
                  {editDetails.map((detail, index) => (
                    <div key={index} className="mb-3">
                      <Table
                        striped
                        bordered
                        hover
                        size="sm"
                        className="text-center membership-edit-table"
                      >
                        <thead className="table-dark">
                          <tr>
                            <th>항목</th>
                            <th>RED</th>
                            <th>BLUE</th>
                            <th>GREEN</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>멤버쉅 가입 비용</th>
                            <td>
                              <input
                                type="text"
                                value={detail.signupFeeRed}
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "signupFeeRed",
                                    e.target.value
                                  )
                                }
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                value={detail.signupFeeBlue}
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "signupFeeBlue",
                                    e.target.value
                                  )
                                }
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                value={detail.signupFeeGreen}
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "signupFeeGreen",
                                    e.target.value
                                  )
                                }
                              />
                            </td>
                          </tr>
                          <tr>
                            <th>리팩피 할인율</th>
                            <td>
                              <input
                                type="number"
                                value={detail.repurchaseDiscountRed}
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "repurchaseDiscountRed",
                                    e.target.value
                                  )
                                }
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                value={detail.repurchaseDiscountBule}
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "repurchaseDiscountBule",
                                    e.target.value
                                  )
                                }
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                value={detail.repurchaseDiscountGreen}
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "repurchaseDiscountGreen",
                                    e.target.value
                                  )
                                }
                              />
                            </td>
                          </tr>
                          <tr>
                            <th>구매대행 서비스</th>
                            <td>
                              <input
                                type="text"
                                value={detail.serviceFeeRed}
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "serviceFeeRed",
                                    e.target.value
                                  )
                                }
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                value={detail.serviceFeeBlue}
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "serviceFeeBlue",
                                    e.target.value
                                  )
                                }
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                value={detail.serviceFeeGreen}
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "serviceFeeGreen",
                                    e.target.value
                                  )
                                }
                              />
                            </td>
                          </tr>
                          <tr>
                            <th>체크컨텐츠 쿠폰지급</th>
                            <td>
                              <input
                                type="number"
                                value={detail.contentCouponRed}
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "contentCouponRed",
                                    e.target.value
                                  )
                                }
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                value={detail.contentCouponBlue}
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "contentCouponBlue",
                                    e.target.value
                                  )
                                }
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                value={detail.contentCouponGreen}
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "contentCouponGreen",
                                    e.target.value
                                  )
                                }
                              />
                            </td>
                          </tr>
                          <tr>
                            <th>창고 무료 이용기한</th>
                            <td>
                              <input
                                type="number"
                                value={detail.freeStorageDaysRed}
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "freeStorageDaysRed",
                                    e.target.value
                                  )
                                }
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                value={detail.freeStorageDaysGreen}
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "freeStorageDaysGreen",
                                    e.target.value
                                  )
                                }
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                value={detail.freeStorageDaysBlue}
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "freeStorageDaysblue",
                                    e.target.value
                                  )
                                }
                              />
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                      <Button
                        variant="primary"
                        onClick={() => saveMembershipChanges(index)}
                      >
                        Save Changes
                      </Button>
                    </div>
                  ))}
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                    onClick={toggleModal}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={showUsersModal}
          onHide={() => setShowUsersModal(false)}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>멤버쉽 유저 정보</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>이름</th>
                  <th>이메일</th>
                  <th>스페이스 코드</th>
                </tr>
              </thead>
              <tbody>
                {selectedMembershipUsers.map((user, index) => (
                  <tr key={index}>
                    <td>{user.name}</td>
                    <td>{user.email}</td>
                    <td>{user.id}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowUsersModal(false)}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </main>
  );
};

export default Amemberships;
