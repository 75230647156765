// Pagination 컴포넌트
import React from "react";
import ReactPaginate from "react-paginate";
import styled from "styled-components";

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (selectedItem: { selected: number }) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  totalPages,
  onPageChange,
}) => {
  return (
    <PaginationContainer>
      <ReactPaginate
        previousLabel={"이전"}
        nextLabel={"다음"}
        breakLabel={"..."}
        pageCount={totalPages}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={onPageChange}
        containerClassName={"pagination"}
        activeClassName={"active"}
        forcePage={currentPage}
      />
    </PaginationContainer>
  );
};

// styled-components 정의
const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  ul {
    list-style: none;
    display: flex;
    gap: 5px;
    margin: 0;
    padding: 0;
    li {
      cursor: pointer;
      padding: 5px 10px;
      border: 1px solid var(--color-dark);
      border-radius: 5px;
      transition-duration: 0.3s;
      &:hover {
        background-color: var(--color-lightgray);
      }
      &.active {
        background-color: var(--color-lightgray);
      }
    }
  }
`;

export default Pagination;
