/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ReviewModal from "./modal/ReviewModal";
import Icon from "./Icon";
import { CODE_COUNTRY_ITEMS, CODE_COUNTRY } from "../common/constants";
import { isMobile } from "react-device-detect";
import { formatDate } from "../common/format";

// Utility function to mask last name
const maskLastName = (lastName: any) => {
  if (!lastName || lastName.length < 2) return lastName;
  return "*".repeat(lastName.length - 1) + lastName.charAt(lastName.length - 1);
};

const getCountryInfo = (countryCode: any) => {
  const countryName = CODE_COUNTRY_ITEMS[countryCode];
  const name = Array.isArray(countryName) ? countryName[0] : countryName;
  const country = CODE_COUNTRY.find((item) => item.name === name);
  return {
    name: name,
    flag: country ? `https://flagsapi.com/${country.code}/flat/64.png` : "",
  };
};

const ReviewCard = ({ usage, review }: any) => {
  const [reviewModal, setReviewModal] = useState<boolean>(false);
  const [isExpanded, setIsExpanded] = useState(false);

  const countryInfo = getCountryInfo(review?.user?.country);

  const MobileView = () => {
    return (
      <MReviewCard>
        <div className="image full-w" style={{ marginBottom: ".4rem" }}>
          {review.photoUrls ? (
            <M_CardImage
              src={`https://kr.object.ncloudstorage.com/ontact-bucket/${review.photoUrls}`}
              alt="Review Image"
            />
          ) : (
            <NoCardImage>
              <b>No Image</b>
            </NoCardImage>
          )}
        </div>

        <div className="f-col full-u">
          <div className="name">{`${review?.user?.firstName} ${maskLastName(
            review?.user?.lastName
          )}`}</div>
          <div className="country">
            <FlagIcon src={countryInfo.flag} alt="Country Flag" />
            {countryInfo.name}
          </div>

          <div className="f-row f-sp f-center" style={{ margin: ".4rem 0" }}>
            <div> {formatDate(review.createdAt)} </div>
            <div>
              {[1, 2, 3, 4, 5].map((_, index) => {
                return index < Number(review.rating) ? (
                  <Icon icon="star-fill" color="#f2c72c" key={index} />
                ) : (
                  <Icon icon="star" key={index} />
                );
              })}
            </div>
          </div>

          <div>{review.content}</div>
        </div>
      </MReviewCard>
    );
  };

  const MobileLandingView = () => {
    return (
      <MReviewCard>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <CardImageSection>
            {review.photoUrls ? (
              <M_CardImage
                src={`https://kr.object.ncloudstorage.com/ontact-bucket/${review.photoUrls}`}
                alt="Review Image"
              />
            ) : (
              <NoCardImage></NoCardImage>
            )}
          </CardImageSection>
          <CardInfo>
            <ReviewerName>{`${review?.user?.firstName} ${maskLastName(
              review?.user?.lastName
            )}`}</ReviewerName>
            <CountryInfo>
              <FlagIcon src={countryInfo.flag} alt="Country Flag" />
              {countryInfo.name}
            </CountryInfo>
            <ReviewDate>{formatDate(review.createdAt)}</ReviewDate>
            <div>
              {[1, 2, 3, 4, 5].map((_, index) => {
                return index < Number(review.rating) ? (
                  <Icon icon="star-fill" color="#f2c72c" key={index} />
                ) : (
                  <Icon icon="star" key={index} />
                );
              })}
            </div>
          </CardInfo>
        </div>

        <ReviewText>{review.content}</ReviewText>
      </MReviewCard>
    );
  };

  return (
    <>
      {usage === "DASHBOARD" ? (
        <>
          <CardWrapper onClick={() => setReviewModal(true)}>
            {isMobile ? (
              <MobileView />
            ) : (
              <>
                <CardImageSection style={{ width: "auto" }}>
                  {review.photoUrls ? (
                    <CardImage
                      src={`https://kr.object.ncloudstorage.com/ontact-bucket/${review.photoUrls}`}
                      alt="Review Image"
                    />
                  ) : (
                    <NoCardImage style={{ width: "10rem" }}>
                      No Image
                    </NoCardImage>
                  )}
                </CardImageSection>

                <CardInfo>
                  <ReviewerName>{`${review?.user?.firstName} ${maskLastName(
                    review?.user?.lastName
                  )}`}</ReviewerName>
                  <CountryInfo>
                    <FlagIcon src={countryInfo.flag} alt="Country Flag" />
                    {countryInfo.name}
                  </CountryInfo>
                  <ReviewDate>{formatDate(review.createdAt)}</ReviewDate>
                  <div>
                    {[1, 2, 3, 4, 5].map((_, index) => {
                      return index < Number(review.rating) ? (
                        <Icon icon="star-fill" color="#f2c72c" key={index} />
                      ) : (
                        <Icon icon="star" key={index} />
                      );
                    })}
                  </div>
                  <ReviewText>{review.content}</ReviewText>
                </CardInfo>
              </>
            )}
          </CardWrapper>
          {reviewModal && (
            <ReviewModal
              reviewData={{ ...review, photoUrl: review.photoUrls }}
              setViewReviewModal={(flag: boolean) => setReviewModal(flag)}
              isOwner={null}
            />
          )}
        </>
      ) : (
        <>
          {isMobile ? (
            <MLCardWrapper>
              <MobileLandingView />
            </MLCardWrapper>
          ) : (
            <LCardWrapper>
              <>
                <ProfileSection>
                  {review?.user?.imageUrl ? (
                    <ProfileImage
                      src={`https://kr.object.ncloudstorage.com/ontact-bucket/${review?.user?.imageUrl}`}
                      alt="Review Image"
                    />
                  ) : (
                    <NoneProfileImage></NoneProfileImage>
                  )}

                  <ProfileContentSection>
                    <ProfileName>{`${review?.user?.firstName} ${maskLastName(
                      review?.user?.lastName
                    )}`}</ProfileName>
                    <CountryInfo>
                      <FlagIcon src={countryInfo.flag} alt="Country Flag" />
                      {countryInfo.name}
                    </CountryInfo>
                    <div>
                      {[1, 2, 3, 4, 5].map((_, index) => {
                        return index < Number(review.rating) ? (
                          <Icon icon="star-fill" color="#f2c72c" key={index} />
                        ) : (
                          <Icon icon="star" key={index} />
                        );
                      })}
                    </div>
                  </ProfileContentSection>
                </ProfileSection>
                <LCardImageSection>
                  {review.photoUrls ? (
                    <LCardImage
                      src={`https://kr.object.ncloudstorage.com/ontact-bucket/${review.photoUrls}`}
                      alt="Review Image"
                    />
                  ) : (
                    <></>
                  )}
                </LCardImageSection>
                <LCardInfo>
                  {isExpanded ? (
                    <LReviewText>{review.content}</LReviewText>
                  ) : (
                    <LMoreReviewText> {review.content}</LMoreReviewText>
                  )}
                  {review.content.length > 65 && (
                    <MoreButton onClick={() => setIsExpanded(!isExpanded)}>
                      {isExpanded ? "Back" : "More"}
                    </MoreButton>
                  )}
                </LCardInfo>
              </>
            </LCardWrapper>
          )}
        </>
      )}
    </>
  );
};

const MReviewCard = styled.div`
  .image {
  }

  .name {
    font-weight: bold;
  }

  .country {
    display: flex;
    align-items: center;
    margin-top: 0.2rem;
  }

  .f-row {
    display: flex;
    flex-direction: row;
  }

  .f-col {
    display: flex;
    flex-direction: column;
  }

  .f-sp {
    display: flex;
    justify-content: space-between;
  }

  .f-center {
    display: flex;
    align-items: center;
  }
`;

const LReviewText = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  flex-shrink: 1;
  display: -webkit-box;
  -webkit-line-clamp: none;
  -webkit-box-orient: vertical;
  height: auto;
`;
const LMoreReviewText = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  flex-shrink: 1;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  height: 6em;
`;
const MoreButton = styled.div`
  margin-top: 1rem;
  color: rgb(0, 106, 93);
`;

const ProfileName = styled.div`
  font-weight: bold;
`;
const CountryInfo = styled.div`
  display: flex;
  align-items: center;
  color: gray;
  margin-top: 0.2rem;
`;
const FlagIcon = styled.img`
  width: 1.5rem;
  height: 1rem;
  margin-right: 0.5rem;
`;
const ProfileSection = styled.div`
  display: flex;
  flex-direction: row;
`;
const ProfileContentSection = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
  justify-content: space-between;
`;

const ProfileImage = styled.img`
  border-radius: 50%;
  object-fit: cover;
  width: 5rem;
  height: 5rem;
`;
const NoneProfileImage = styled.div`
  width: 5rem;
  height: 5rem;
`;
const CardWrapper = styled.div`
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: 1rem;
`;
const LCardWrapper = styled(CardWrapper)`
  flex-direction: column;
  flex: 1 0 0;
  padding: 1.4rem;
`;
const MLCardWrapper = styled.div`
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  padding: 1rem;
  flex-direction: column;
  flex: 1 0 0;
  padding: 1.4rem;
`;
const CardImage = styled.img`
  width: 10rem;
  height: 10rem;
  object-fit: cover;
`;
const M_CardImage = styled(CardImage)`
  object-fit: full;
  width: 80vw;
`;
const CardImageSection = styled.div`
  width: 100%;
`;
const LCardImageSection = styled.div`
  margin-top: 1.6rem;
  margin-bottom: 1rem;
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 100%;
  overflow: hidden;
`;
const LCardImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
const NoCardImage = styled.div`
  width: 80vw;
  height: 10rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background: lightgray;
`;
const LNoCardImage = styled(NoCardImage)``;
const CardInfo = styled.div`
  padding: 0.2rem 1rem 0 1.4rem;
  display: flex;
  flex-direction: column;
`;
const LCardInfo = styled.div`
  padding: 0.2rem;
  margin-bottom: 1.4rem;
`;
const ReviewerName = styled.h2`
  margin: 0;
  font-size: 1.2rem;
`;
const ReviewDate = styled.p`
  color: #666;
  margin: 0.4rem 0 0.4rem 0;
`;
const ReviewText = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  flex-shrink: 1;
  height: 3em;
  margin-top: 1rem;
`;

export default ReviewCard;
