import axios from "axios";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { ACODE_SHOPING_ORDER_STATUS, MEMBERSHIP } from "../../../common/constants";
import Pagination from "../../../components/Pagination";
import Title from "../../../components/Title";
import { getColor } from "../../../components/getColor";
import ConfirmationModal from "../../../components/modal/ConfirmationModal";
import { formatDate } from "../../../common/format";
const ITEMS_PER_PAGE = 10;

export default function AShipShipment() {
  const [search, setSearch] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState<string | null>(null);
  const [data, setData] = useState<OrderData[]>([]);
  const [currentPage, setCurrentPage] = useState(0);

  type OrderData = {
    id: string;
    userId: string;
    adrReceiver: string;
    createdAt: string;
    status: number;
    membershipGrade: string;
    user: {
      name: string;
      membershipId: number;
    };
    detail: {
      id: string;
      express: number;
    };
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}ship`
        );
        const filteredData = response.data.filter(
          (item: OrderData) =>
            item.status === ACODE_SHOPING_ORDER_STATUS.PRODUCT_WAREHOUSING
        );
        const sortedData = filteredData.sort(
          (a: OrderData, b: OrderData) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
        setData(sortedData);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };
    fetchData();
  }, []);

  const updateOrderStatus = (orderId: string) => {
    setData((prevData) =>
      prevData.map((order) =>
        order.id === orderId ? { ...order, status: 1 } : order
      )
    );
    setShowModal(false);
  };

  const [orders, setOrders] = useState(data);

  const handleStatusClick = (orderNumber: string) => {
    setSelectedOrder(orderNumber);
    setShowModal(true);
  };

  const handlePageChange = (selectedItem: { selected: number }) => {
    setCurrentPage(selectedItem.selected);
  };

  const filteredData = search
    ? data.filter((item) =>
      item.id.toLowerCase().includes(search.toLowerCase())
    )
    : data;

  const startIndex = currentPage * ITEMS_PER_PAGE;
  const displayedData = filteredData.slice(
    startIndex,
    startIndex + ITEMS_PER_PAGE
  );

  const tableRows = displayedData.map((item, index) => (
    <tr key={index}>
      <td>{index + 1 + startIndex}</td>
      <td>{item.id}</td>
      <td>{formatDate(item.createdAt)}</td>
      <td>{item.userId}</td>
      <td>{item.adrReceiver}</td>
      <td style={{ color: getColor(item.user.membershipId) }}>{MEMBERSHIP[item.user.membershipId]}</td>
      <td>{item.detail.express}</td>
      <td>
        <button
          className="btn btn-primary btn-sm"
          onClick={() => handleStatusClick(item.id)}
        >
          {item.status === 1 ? "완료" : "배송중"}
        </button>
      </td>
    </tr>
  ));

  return (
    <div>
      <Title title="배송대행 서비스 > 특송사 배송 상태 관리" />
      <Toolbar>
        <div className="right">
          <input
            type="text"
            placeholder="주문번호로 검색"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <button className="btn-main btn-dark">검색</button>
        </div>
      </Toolbar>

      <TableContainer>
        <table>
          <thead>
            <tr>
              <th>No</th>
              <th>주문번호</th>
              <th>주문날자</th>
              <th>스페이스코드</th>
              <th>성명</th>
              <th>멤버쉽 등급</th>
              <th>특송사 송장번호</th>
              <th>상태</th>
            </tr>
          </thead>
          <tbody>{tableRows}</tbody>
        </table>
      </TableContainer>
      <Pagination
        currentPage={currentPage}
        totalPages={Math.ceil(filteredData.length / ITEMS_PER_PAGE)}
        onPageChange={handlePageChange}
      />
      {showModal && (
        <ConfirmationModal
          show={showModal}
          onHide={() => setShowModal(false)}
          onConfirm={updateOrderStatus}
          Id={selectedOrder ?? ""}
        />
      )}
    </div>
  );
}

const TableContainer = styled.div`
  width: 100%;
  margin-top: 40px;
  margin-bottom: 40px;

  table {
    width: 100%;
    border-collapse: collapse;

    th,
    td {
      padding: 8px;
      text-align: center;
      border: 1px solid #ddd;
    }

    th {
      background-color: #f2f2f2;
    }
  }
`;

const Toolbar = styled.div`
  .right {
    display: flex;
    gap: 10px;
    width: 300px;
    float: right;

    button {
      width: 100px;
    }
  }

  .left {
    display: flex;
    gap: 10px;

    > div {
      width: 150px;

      button {
        border-radius: 20px;
      }
    }
  }
`;
