import { Fragment, useEffect, useState } from "react";
import { Button, Form, InputGroup, Modal, Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import styled from "styled-components";
import {
  CODE_BY_STATUS,
  CODE_SHOPING_ORDER_STATUS,
  CODE_SHOPING_PRODUCT_DETAIL_STATUS,
  CODE_SHOPPING_ORDER_ITEMS,
} from "../../common/constants";
import { RootState } from "../../redux/store";
import { reqSendAlarm } from "../../requests/alarm";
import { reqSingleCouponLog } from "../../requests/coupon";
import { reqShipFEE } from "../../requests/ship";
import {
  reqShop,
  reqShopDetail,
  reqShopDetailUpdatePuts,
  reqShopUpdate
} from "../../requests/shop";
interface ShopOrderModalProps {
  show: boolean;
  onHide: () => void;
  orderId: string;
  refreshData: () => void;
}
interface OrderDetail {
  order_id: string;
  productId: string;
  status: number;
  category: number;
  name: string;
  option1: string;
  option2: string;
  quantity: number;
  price: number;
  ShippingFee: number;
  url: string;
  fee: number;
  paymentDeadline: string;
  cancellationReason: string;
  preorderStartAt: string;
  preorderEndAt: string;
  fancallName: string;
  fancallBirthday: string;
  fancallNumber: string;
  fancallEmail: string;
  fancallCountry: number;
  fancallKakao: string;
  fancallSnsLine: string;
  isCanceled: boolean;
  user: {
    point: number;
    id: string;
  };
}
interface ShopData {
  account: string;
  failed: boolean;
  refund: boolean;
  price: number;
  status: number;
  usePointKrw: number;
  paymentConfirmed: boolean;
  usePointUse: number;
  snKrwShippingFee: number;
  snKrwFee: number;
  snKrwTotalFee: number;
  snKrwRealFee: number;
  snKrwPointApplied: number;
  snKrwCouponApplied: number;
  snUsdFee: number;
  snUsdTotalFee: number;
  snUsdRealFee: number;
  snUsdPointApplied: number;
  snUsdCouponApplied: number;
  snUsdPaypalSurcharge: number;
  snUsdShippingFee: number;
  userId: string;
  user: {
    name: string;
    membershipId: number;
  };
}

type OrderData = {
  orderId: string;
  userId: string;
  createdAt: string;
  PaymentMethod: number;
  preorder: boolean;
  fancall: boolean;
  price: number;
  status: number;
  name: string;
  failed: boolean;
  user: {
    name: string;
    membershipId: number;
  };
};

const WiseFancallModel = ({
  show,
  onHide,
  orderId,
  refreshData,
}: ShopOrderModalProps) => {
  const [orderDetails, setOrderDetails] = useState<OrderDetail[]>([]);
  const [serviceFee, setServiceFee] = useState("");
  const [pointsApplied, setPointsApplied] = useState(0);
  const admin = useSelector((state: RootState) => state.admin);
  const [originalPoints, setOriginalPoints] = useState(
    orderDetails[0]?.user.point || 0
  );
  const [orders, setOrders] = useState<any>([]);
  const [couponLog, setCouponLog] = useState<any>(null);
  const [cancellationReason, setCancellationReason] = useState("");
  const [customerMessage, setCustomerMessage] = useState("");
  const [paymentDeadline, setPaymentDeadline] = useState("");
  const [exchangeRate, setExchangeRate] = useState(0);
  const [fixedExchangeRate, setFixedExchangeRate] = useState(0);
  const [UtotalUSD, setUtotalUSD] = useState(0);
  const [shopData, setShopData] = useState<ShopData | null>(null);
  const [confirmCancelModalShow, setConfirmCancelModalShow] = useState(false);
  const [selectedStatuses, setSelectedStatuses] = useState<{
    [key: string]: number;
  }>({});
  const [showEstimateConfirmationModal, setShowEstimateConfirmationModal] =
    useState(false);
  const excludeStatuses = [3, 4, 5, 9, 10];

  const convertToUSD = (amount: number) => {
    return (amount / fixedExchangeRate).toFixed(2);
  };

  // const fetchExchangeRate = () => {
  //   fetch(
  //     "https://quotation-api-cdn.dunamu.com/v1/forex/recent?codes=FRX.KRWUSD"
  //   )
  //     .then((response) => response.json())
  //     .then((data) => {
  //       const rate = data[0].basePrice;
  //       setExchangeRate(rate);
  //     })
  //     .catch((error) => console.error("Error fetching exchange rate:", error));
  // };

  const fetchFixedExchangeRate = async () => {
    try {
      const response = await reqShipFEE();
      if (response && response.data && response.data[0]?.fixedExchangeRateShop) {
        setFixedExchangeRate(parseFloat(response.data[0].fixedExchangeRateShop));
      }
    } catch (error) {
      console.error("Error fetching fixed exchange rate:", error);
    }
  };

  useEffect(() => {
    // fetchExchangeRate();
    fetchFixedExchangeRate();
    if (orderId) {
      fetchOrderDetails(orderId);
      fetchShopOrder();
    }
  }, [orderId]);

  const fetchOrderDetails = async (order_id: string) => {
    try {
      const detailResponse = await reqShopDetail({ order_id });
      const detailsWithStatus: OrderDetail[] = detailResponse.data.map(
        (item: OrderDetail) => ({
          ...item,
          isCanceled: item.status === 10,
        })
      );
      setOrderDetails(detailsWithStatus);

      const shopResponse = await reqShop({ order_id });
      const feeValue = shopResponse.data.fee?.toString() ?? "";
      setServiceFee(feeValue);
      setShopData(shopResponse.data);
      if (shopResponse.data.paymentDeadline) {
        const formattedDeadline = new Date(shopResponse.data.paymentDeadline)
          .toISOString()
          .slice(0, 16);
        setPaymentDeadline(formattedDeadline);
      }
    } catch (error) {
      console.error("Error fetching order details:", error);
    }
  };

  const fetchShopOrder = async () => {
    const shopRes = await reqShop({ order_id: orderId });
    setOrders(shopRes.data);
    // console.log(shopRes.data);

    const { couponLogId } = shopRes.data;
    if (couponLogId) {
      const couponLogRes = await reqSingleCouponLog({
        couponLogId: shopRes.data.couponLogId,
      });
      setCouponLog(couponLogRes.data);
      // console.log(couponLogRes.data);
    } else {
      setCouponLog(null);
    }
  };

  const calculateTotal = () => {
    if (orderDetails.length === 0) {
      return {
        productTotal: 0,
        domesticShippingFee: 0,
        serviceFeeValue: 0,
        paypalFee: 0,
        total: 0,
        Utotal: 0,
        UtotalUSD: 0,
      };
    }

    const statusesToCheck = [
      CODE_SHOPING_PRODUCT_DETAIL_STATUS.EXPIRATION,
      CODE_SHOPING_PRODUCT_DETAIL_STATUS.RESTRICTION,
      CODE_SHOPING_PRODUCT_DETAIL_STATUS.SOLD_OUT,
      CODE_SHOPING_PRODUCT_DETAIL_STATUS.CANCELED,
      CODE_SHOPING_PRODUCT_DETAIL_STATUS.POINT,
    ];

    const allInSpecifiedStatuses = orderDetails.every((detail) =>
      statusesToCheck.includes(detail.status)
    );

    const productTotal = orderDetails.reduce((total, item) => {
      if (!excludeStatuses.includes(item.status)) {
        return total + item.quantity * item.price;
      }
      return total;
    }, 0);

    const domesticShippingFee = orderDetails.reduce((total, item) => {
      if (!excludeStatuses.includes(item.status)) {
        return total + item.ShippingFee;
      }
      return total;
    }, 0);

    let serviceFeeValue = 0;
    if (!allInSpecifiedStatuses) {
      const parsedServiceFee = parseFloat(serviceFee);
      serviceFeeValue = Math.round(
        productTotal * (isNaN(parsedServiceFee) ? 0 : parsedServiceFee / 100)
      );
      serviceFeeValue = Math.max(serviceFeeValue, 5000);
    }

    const paypalFee =
      (productTotal + serviceFeeValue + domesticShippingFee) * 0.05;
    const UtotalUSDValue = convertToUSD(
      productTotal + domesticShippingFee + serviceFeeValue + paypalFee
    );

    return {
      productTotal,
      domesticShippingFee,
      serviceFeeValue,
      paypalFee,
      total:
        productTotal +
        domesticShippingFee +
        serviceFeeValue -
        (couponLog?.krwDiscounted || 0),
      Utotal:
        productTotal +
        domesticShippingFee +
        serviceFeeValue +
        paypalFee -
        (couponLog?.krwDiscounted || 0),
      UtotalUSD: parseFloat(UtotalUSDValue),
    };
  };

  const {
    productTotal,
    domesticShippingFee,
    serviceFeeValue,
    paypalFee,
    total,
    Utotal,
  } = calculateTotal();

  const handleSendEstimateClick = async () => {
    try {
      const updatePromises = orderDetails.map((detail) => {
        if (selectedStatuses[detail.productId]) {
          const payload = {
            ...detail,
            status: selectedStatuses[detail.productId],
            ShippingFee: detail.ShippingFee,
            paymentDeadline: paymentDeadline,
          };
          return reqShopDetailUpdatePuts(detail.productId, payload);
        }
        return Promise.resolve();
      });

      await Promise.all(updatePromises);

      const totals = calculateTotal();
      setUtotalUSD(totals.Utotal);
      const updateData = {
        order_id: orderId,
        paymentDeadline: paymentDeadline,
      };
      await reqShopUpdate(updateData);

      alert("견적서가 성공적으로 업데이트되었습니다.");
      onHide();
      setShowEstimateConfirmationModal(false);
      refreshData();
    } catch (error) {
      console.error("Error during sending estimate:", error);
      alert("견적서 전송에 실패했습니다.");
    }
  };

  const handlePaymentConfirmed = async () => {
    if (!shopData || shopData.paymentConfirmed !== true) {
      alert("입금이 확인되지 않았습니다. 입금 확인 후 다시 시도해주세요.");
      return;
    }
    try {
      await reqShopUpdate({
        order_id: orderId,
        paymentConfirmed: true,
        status: CODE_SHOPING_ORDER_STATUS.PRODUCT_PART,
      });
      alert("입금이 확인되었습니다.");
      onHide();
      refreshData();
    } catch (error) {
      console.error("입금 확인 실패:", error);
      alert("입금 확인에 실패했습니다.");
    }
  };

  const handleCancelOrderClick = () => {
    setConfirmCancelModalShow(true);
  };

  const handleConfirmCancelOrder = async () => {
    try {
      setConfirmCancelModalShow(false);
      await handleCancelOrder();

      const alarmPayload = {
        userId: orders[0].userId,
        read: 0,
        content: `Your custom order request has been cancelled.`,
        sender: admin.name,
      };
      await reqSendAlarm(alarmPayload);
    } catch (error) {
      console.error("Cancellation failed:", error);
    }
  };

  const handleCancelOrder = async () => {
    try {
      const updateData = {
        order_id: orderId,
        fancallNumber: customerMessage,
        status: CODE_SHOPING_ORDER_STATUS.CANCELED,
        productId: admin.name,
      };

      await reqShopUpdate(updateData);
      alert("주문이 취소되었습니다.");
      onHide();
      refreshData();
    } catch (error) {
      console.error("주문 취소 실패:", error);
      alert("주문 취소에 실패했습니다.");
    }
  };


  return (
    <Fragment>
      <StyledModal show={show} onHide={onHide} centered>
        <StyledModal.Body>
          <div className="container-fluid mb-3">
            <ResponsiveTable>
              <thead id="header-thead">
                <tr>
                  <th scope="col">NO</th>
                  <th scope="col">품목</th>
                  <th scope="col">상품명</th>
                  <th scope="col">옵션1</th>
                  <th scope="col">옵션2</th>
                  <th scope="col">수량</th>
                  <th scope="col">단가</th>
                  <th scope="col">국내 배송비</th>
                  <th scope="col">상태</th>
                </tr>
              </thead>
              <tbody id="header-tbody">
                {orderDetails.map((detail, index) => (
                  <tr
                    key={index}
                    style={
                      excludeStatuses.includes(detail.status)
                        ? { textDecoration: "line-through" }
                        : undefined
                    }
                  >
                    <td>{detail.productId}</td>
                    <td>
                      {CODE_SHOPPING_ORDER_ITEMS[detail.category] ||
                        "Unknown Category"}
                    </td>
                    <td>{detail.name}</td>
                    <td>{detail.option1}</td>
                    <td>{detail.option2}</td>
                    <td>{detail.quantity}</td>
                    <td>{detail.price}</td>
                    <td>{detail.ShippingFee}</td>
                    <td>{CODE_BY_STATUS[detail.status]}</td>
                  </tr>
                ))}
              </tbody>
            </ResponsiveTable>
            <Table bordered className="text-center table-dark table-hover">
              <thead>
                <tr>
                  <th></th>
                  <th>KRW</th>
                  <th>USD</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>상품 합계</th>
                  <td>{productTotal}</td>
                  <td>{convertToUSD(productTotal)}</td>
                </tr>
                <tr>
                  <th>배송 비용</th>
                  <td>{domesticShippingFee}</td>
                  <td>{convertToUSD(domesticShippingFee)}</td>
                </tr>
                <tr>
                  <th>서비스 비용</th>
                  <td>{shopData?.price || 0}</td>
                  <td>{convertToUSD(shopData?.price ?? 0)}</td>
                </tr>
                <tr>
                  <th>환전 수수료(5%)</th>
                  <th>-</th>
                  <td>{convertToUSD(paypalFee)}</td>
                </tr>
                <tr>
                  <th>사용 포인트</th>
                  <td>{shopData?.snKrwPointApplied || 0}</td>
                  <td>{convertToUSD(shopData?.snUsdPointApplied || 0)}</td>
                </tr>
                <tr>
                  <th>사용 쿠폰 금액</th>
                  <td>{shopData?.snKrwCouponApplied || 0}</td>
                  <td>{shopData?.snUsdCouponApplied || 0}</td>
                </tr>
                <tr>
                  <th>합계</th>
                  <td>{shopData?.snKrwRealFee || 0}</td>
                  <td>{shopData?.snUsdRealFee || 0}</td>
                </tr>
                <tr>
                  <th colSpan={3}>
                    <InputGroup>
                      <InputGroup.Text>결제마감일</InputGroup.Text>
                      <Form.Control
                        type="datetime-local"
                        className="form-control"
                        value={paymentDeadline}
                        onChange={(e) => setPaymentDeadline(e.target.value)}
                      />
                    </InputGroup>
                  </th>
                </tr>
              </tbody>
            </Table>
          </div>
        </StyledModal.Body>
        <StyledModal.Footer style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ fontWeight: 'bold', fontSize: '1.2em', marginRight: 'auto' }}>
            <p>고객 이름: {shopData?.user.name}, 스페이스 코드: {shopData?.userId}</p>
          </div>
          <Button variant="secondary" onClick={handleCancelOrderClick}>
            전체취소
          </Button>
          <Button variant="success" onClick={handleSendEstimateClick}>
            견적서 수정
          </Button>
          <Button variant="primary" onClick={handlePaymentConfirmed}>
            입금완료
          </Button>
        </StyledModal.Footer>
      </StyledModal>
      <Modal
        show={confirmCancelModalShow}
        onHide={() => setConfirmCancelModalShow(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>주문 취소 확인</Modal.Title>
        </Modal.Header>
        <Modal.Body>정말로 주문을 취소하시겠습니까?</Modal.Body>
        <Modal.Body>사용자에게 알림을 전송합니다.</Modal.Body>
        <Form.Group className="mb-3" controlId="formCustomerMessage">
          <Form.Label>고객 메세지</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="고객에세 보내는 메세지"
            value={customerMessage}
            onChange={(e) => setCustomerMessage(e.target.value)}
          />
        </Form.Group>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setConfirmCancelModalShow(false)}
          >
            아니요
          </Button>
          <Button variant="danger" onClick={handleConfirmCancelOrder}>
            네, 취소할게요
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};
export default WiseFancallModel;

const StyledModal = styled(Modal)`
  .modal-dialog {
    max-width: 70%;
    margin: 5;
    font-size: 0.9em;

    .table th,
    .table td {
      padding: 0.5rem;
    }
  }

  display: flex;
  flex-direction: column;
  max-height: 80vh;

  .body {
    overflow-y: auto;
  }

  #header-tbl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table {
    white-space: nowrap;
    margin-bottom: 0;
  }
`;

const ResponsiveTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  table-layout: auto;

  th,
  td {
    padding: 8px;
    text-align: center;
    border: 1px solid #ddd;
  }

  @media (max-width: 768px) {
    font-size: 0.8em;
    th,
    td {
      padding: 6px;
    }
  }

  @media (max-width: 480px) {
    font-size: 0.7em;
    th,
    td {
      padding: 4px;
    }
  }
`;
