import { Button, Modal } from 'react-bootstrap';

interface ConfirmationModalProps {
    show: boolean;
    onHide: () => void;
    onConfirm: () => void;
}

export default function ExpiredModal({ show, onHide, onConfirm }: ConfirmationModalProps) {
    const handleConfirmClick = () => {
        onConfirm();
        onHide();
        window.location.reload();
    };

    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title>폐기처리</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                폐기처리 하시겠습니까?
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>취소</Button>
                <Button variant="primary" onClick={handleConfirmClick}>확인</Button>
            </Modal.Footer>
        </Modal>
    );
}
