import { Button, Modal } from "react-bootstrap";
import styled from "styled-components";
import { FormatTime } from "../../common/format";
import { useRef, useState } from "react";
import Swal from "sweetalert2";
import { reqSendBroadcastAlarm } from "../../requests/alarm";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

export default function PopupModifyModal(props: {
  show?: any;
  onHide?: any;
  onSubmit?: any;
  popupData?: any;
  uploadImage?: any;
  isCreateMode?: boolean;
}) {
  const [fileName, setFileName] = useState("선택된 이미지 없음");
  const [input, setInput] = useState<any>(
    props.isCreateMode
      ? {
          title: "",
          link: "",
          startDue: new Date(),
          endDue: new Date(),
          pcPos: "0",
          pcLeftOffset: "0",
          pcTopOffset: "0",
          mobilePos: "0",
          mobileLeftOffset: "0",
          mobileTopOffset: "0",
          onedayBan: "0",
        }
      : {
          id: props.popupData.id,
          title: props.popupData.title,
          link: props.popupData.link,
          startDue: props.popupData.startDue,
          endDue: props.popupData.endDue,
          pcPos: props.popupData.pcPos,
          pcLeftOffset: props.popupData.pcLeftOffset,
          pcTopOffset: props.popupData.pcTopOffset,
          mobilePos: props.popupData.mobilePos,
          mobileLeftOffset: props.popupData.mobileLeftOffset,
          mobileTopOffset: props.popupData.mobileTopOffset,
          onedayBan: props.popupData.onedayBan,
        }
  );

  const handleFileChange = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      setFileName(file.name);
    } else {
      setFileName("선택된 이미지 없음");
    }
    props.uploadImage(e);
  };

  const admin = useSelector((state: RootState) => state.admin);

  const handleConfirmClick = () => {
    // props.isCreateMode
    Swal.fire({
      title: `팝업을 ${props.isCreateMode ? "생성" : "추가"} 하시겠습니까?`,
      text: props.isCreateMode ? "모든 사용자에게 알림을 전송합니다." : "",
      icon: "question",

      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "승인",
      cancelButtonText: "취소",

      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        props.onSubmit(input);
        props.onHide();

        if (props.isCreateMode) {
          const alarmPayload = {
            userId: "-",
            read: 0,
            content: `Ontact Korea has uploaded a new blog post !`,
            sender: admin.name,
          };
          await reqSendBroadcastAlarm(alarmPayload);
        }
      }
    });
  };

  const onChangeInput = (e: any) => {
    const { name, value } = e.target;
    setInput({
      ...input,
      [name]: value,
    });
  };

  return (
    <Modal show={props.show} onHide={props.onHide} centered scrollable>
      <Modal.Header closeButton>
        <Modal.Title>
          {props.isCreateMode ? "팝업 생성하기" : "팝업 수정하기"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {input && (
          <ModalSection>
            <div>
              <label>제목</label>
              <input
                name="title"
                type="input"
                value={input.title}
                onChange={onChangeInput}
              ></input>
            </div>
            <div>
              <label>이미지</label>
              <div id="file-section">
                <label id="file-label" htmlFor="file">
                  <div className="btn-upload">업로드</div>
                </label>
                <p id="file-namespace">{fileName}</p>
                <input
                  id="file"
                  type="file"
                  name="file"
                  onChange={handleFileChange}
                  placeholder="이미지 업로드"
                  accept="image/*"
                  style={{ display: "none" }}
                />
              </div>
            </div>
            <div>
              <label>링크</label>
              <input
                name="link"
                type="input"
                value={input.link}
                onChange={onChangeInput}
              ></input>
            </div>
            <div>
              <label>시작일</label>
              <input
                name="startDue"
                type="datetime-local"
                className="form-control"
                placeholder="팝업 게시 시작일 선택"
                value={
                  FormatTime(new Date(input?.startDue)) ||
                  new Date("undefined").toISOString()
                }
                onChange={(e) => {
                  const selectDate: string = FormatTime(
                    new Date(e.target.value)
                  );
                  setInput({
                    ...input,
                    startDue: selectDate,
                  });
                }}
              />
            </div>
            <div>
              <label>종료일</label>
              <input
                name="endDue"
                type="datetime-local"
                className="form-control"
                placeholder="팝업 게시 종료일 선택"
                value={
                  FormatTime(new Date(input?.endDue)) ||
                  new Date("undefined").toISOString()
                }
                onChange={(e) => {
                  const selectDate: string = FormatTime(
                    new Date(e.target.value)
                  );
                  setInput({
                    ...input,
                    endDue: selectDate,
                  });
                }}
              />
            </div>
            <div className="pos-section">
              <label>PC 위치</label>
              <div className="pos-cell">
                <div className="pos-radio">
                  <label>왼쪽</label>
                  <input
                    name="pcPos"
                    type="radio"
                    value="0"
                    checked={input.pcPos == "0"}
                    onChange={onChangeInput}
                  />
                  <label>가운데</label>
                  <input
                    name="pcPos"
                    type="radio"
                    value="1"
                    checked={input.pcPos == "1"}
                    onChange={onChangeInput}
                  />
                  <label>오른쪽</label>
                  <input
                    name="pcPos"
                    type="radio"
                    value="2"
                    checked={input.pcPos == "2"}
                    onChange={onChangeInput}
                  />
                </div>
                <div className="pos-input">
                  <label>왼쪽 여백</label>
                  <label>위쪽 여백</label>
                </div>
                <div className="pos-input">
                  <div>
                    <input
                      name="pcLeftOffset"
                      type="input"
                      placeholder="왼쪽 여백"
                      value={input.pcLeftOffset}
                      onChange={onChangeInput}
                    />
                    <span>px</span>
                  </div>
                  <div>
                    <input
                      name="pcTopOffset"
                      type="input"
                      placeholder="위쪽 여백"
                      value={input.pcTopOffset}
                      onChange={onChangeInput}
                    />
                    <span>px</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="pos-section">
              <label>모바일 위치</label>
              <div className="pos-cell">
                <div className="pos-radio">
                  <label>왼쪽</label>
                  <input
                    name="mobilePos"
                    type="radio"
                    value="0"
                    checked={input.mobilePos == "0"}
                    onChange={onChangeInput}
                  />
                  <label>가운데</label>
                  <input
                    name="mobilePos"
                    type="radio"
                    value="1"
                    checked={input.mobilePos == "1"}
                    onChange={onChangeInput}
                  />
                  <label>오른쪽</label>
                  <input
                    name="mobilePos"
                    type="radio"
                    value="2"
                    checked={input.mobilePos == "2"}
                    onChange={onChangeInput}
                  />
                </div>
                <div className="pos-input">
                  <label>왼쪽 여백</label>
                  <label>위쪽 여백</label>
                </div>
                <div className="pos-input">
                  <div>
                    <input
                      name="mobileLeftOffset"
                      type="input"
                      placeholder="왼쪽 여백"
                      value={input.mobileLeftOffset}
                      onChange={onChangeInput}
                    />
                    <span>px</span>
                  </div>
                  <div>
                    <input
                      name="mobileTopOffset"
                      type="input"
                      placeholder="위쪽 여백"
                      value={input.mobileTopOffset}
                      onChange={onChangeInput}
                    />
                    <span>px</span>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <label>오늘 하루 안 보기</label>
              <div id="one-day-section">
                <div>
                  <label>사용</label>
                  <input
                    type="radio"
                    name="onedayBan"
                    value="0"
                    checked={input.onedayBan == "0"}
                    onChange={onChangeInput}
                  />
                </div>
                <div>
                  <label>사용 안 함</label>
                  <input
                    type="radio"
                    name="onedayBan"
                    value="1"
                    onChange={onChangeInput}
                    checked={input.onedayBan == "1"}
                  />
                </div>
              </div>
            </div>
          </ModalSection>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleConfirmClick}>
          {props.isCreateMode ? "생성" : "저장"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

const ModalSection = styled.div`
  display: flex;
  flex-direction: column;
  /* width: 90%; */
  align-items: center;
  text-align: left;
  padding: 10px 0;

  & > div {
    width: 90%;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    #file-section {
      line-height: 50px;
      border-radius: 10px;
      border: 1px solid grey;
      padding-left: 14px;
      background-color: lightgrey;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      #file-label {
        font-weight: bold;
        flex: 1 1 0;
      }
      #file-namespace {
        margin: 0 0 0 16px;
        border-left: 1px solid grey;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        padding: 0 16px 0 16px;
        text-align: left;
        background-color: white;
        align-items: center;
        flex: 6 1 0;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    & > label {
      font-weight: bold;
      margin-bottom: 16px;
    }
    & > input {
      line-height: 50px;
      border-radius: 10px;
      border: 1px solid grey;
      padding-left: 14px;
    }
  }

  .pos-section {
    .pos-cell {
      .pos-radio {
        & > input {
          margin: 0 8px;
        }
      }
      .pos-input {
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        label {
          width: 60%;
          font-weight: lighter;
          color: grey;
        }
        & > div {
          & > input {
            width: 60%;
            border-radius: 6px;
            border: 1px solid grey;
            line-height: 40px;
            padding-left: 10px;
          }
          & > span {
            margin: 0 6px;
          }
        }
      }
    }
  }

  #one-day-section {
    display: flex;
    flex-direction: row;
    & > div {
      & > input {
        margin: 0 8px;
      }
    }
  }
`;
