import React from "react";
import { RECOMMANDATION_SHOP_LIST_K_POP } from "../../common/constants";
import styled, { keyframes } from "styled-components";

const ShopKpop = () => {
  return (
    <>
      {Object.entries(RECOMMANDATION_SHOP_LIST_K_POP).flatMap(
        ([key, value]) => (
          <li>
            <a href={key} target="_blank" rel="noopener noreferrer">
              {value.includes(".") ? (
                <img src={`/landing/shop-logo/${value}`} alt={value} />
              ) : (
                <AltText>{value}</AltText>
              )}
            </a>
          </li>
        )
      )}
    </>
  );
};

export const AltText = styled.div`
  font-weight: bold;
  font-size: 1rem;
  text-align: center;
  color: var(--color-black);
  padding: 0;
  margin: 0;
`;

export default ShopKpop;
