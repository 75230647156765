import React from "react";
import Icon from "./Icon";
import styled from "styled-components";

const CustomerPageTitle = ({ subtitle1, subtitle2, title }: any) => {
  return (
    <div>
      <Title>{title}</Title>
    </div>
  );
};

const Title = styled.p`
  margin: 0 0 1.5rem 0;

  color: var(--color-main-blue);
  border-radius: 3rem;
  font-weight: bold;
  font-size: 1.8rem;
`;

export default CustomerPageTitle;
