import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import styled from "styled-components";
import Swal from "sweetalert2";
import { CODE_SHIPPING_PAYMENT_STATUS } from '../../common/constants';
import { reqShop, reqShopDetail } from '../../requests/shop';

interface ShopDetailsModalProps {
    show: boolean;
    onHide: () => void;
    orderId: string;
}

interface OrderDetail {
    orderId: string;
    name: string;
    option1: string;
    option2: string;
    quantity: number;
    price: number;
    ShippingFee: number;
    url: string;
    fee: number;
    paymentDeadline: string;
    cancellationReason: string;
    status: number;
    productId: string;
    PackageId: string;
}

interface ShopData {
    account: string;
    failed: boolean;
    refund: boolean;
    option1: string;
    option2: string;
    url: string;
    fancallCountry: number;
    PaymentMethod: number;
    paymentAmountKRW: number;
    paymentAmountUSD: number;
    paymentConfirmed: boolean;
    usePointKrw: number;
    usePointUse: number;
    snKrwShippingFee: number;
    snKrwFee: number;
    snKrwTotalFee: number;
    snKrwRealFee: number;
    snKrwPointApplied: number;
    snKrwCouponApplied: number;
    snUsdFee: number;
    snUsdTotalFee: number;
    snUsdRealFee: number;
    snUsdPointApplied: number;
    snUsdCouponApplied: number;
    snUsdPaypalSurcharge: number;
    snUsdShippingFee: number;
}

const ShopCancelModal = ({ show, onHide, orderId }: ShopDetailsModalProps) => {
    const [shopData, setShopData] = useState<Partial<ShopData>>({});
    const [orderDetails, setOrderDetails] = useState<OrderDetail[]>([]);
    const [fancallNumber, setFancallNumber] = useState('');

    const getstatusMethod = (status: number) => {
        switch (status) {
            case 1:
                return '요청서';
            case 2:
                return '구매가능';
            case 3:
                return '구매기한 만료';
            case 4:
                return '구매수량 제한';
            case 5:
                return '품절';
            case 6:
                return '주문 완료';
            case 7:
                return '입고 등록 완료';
            case 9:
                return '주문 중 품절';
            case 10:
                return '풀절';
            default:
                return '알 수 없는 상태';
        }
    };

    const getPaymentMethod = (methodCode: number) => {
        return CODE_SHIPPING_PAYMENT_STATUS[methodCode] || "알 수 없음";
    };

    useEffect(() => {
        if (orderId) {
            fetchOrderDetails(orderId);
        }
    }, [orderId]);

    useEffect(() => {
        const fetchFancallNumber = async () => {
            try {
                const response = await reqShop({ order_id: orderId });
                setShopData(response.data)
                setFancallNumber(response.data.fancallNumber);
            } catch (error) {
                console.error('Error fetching fancallNumber:', error);
            }
        };

        if (orderId) {
            fetchFancallNumber();
        }
    }, [orderId]);

    const fetchOrderDetails = async (order_id: string) => {
        try {
            const response = await reqShopDetail({ order_id });
            setOrderDetails(response.data);
        } catch (error) {
            console.error('Error fetching order details:', error);
        }
    };

    const copyToClipboardAndNavigate = (url: any) => {
        navigator.clipboard.writeText(url)
            .then(() => {
                Swal.fire({
                    icon: 'success',
                    title: 'Copied!',
                    text: 'URL has been copied to your clipboard and opening in a new tab.',
                    timer: 1500
                });
                window.open(url, '_blank');
            })
            .catch(err => {
                console.error('Failed to copy: ', err);
                Swal.fire({
                    icon: 'error',
                    title: 'Failed!',
                    text: 'Failed to copy URL.',
                });
            });
    };

    return (
        <Modal show={show} onHide={onHide} fullscreen centered>
            <Modal.Header className="bg-dark text-white">
                <Modal.Title>{orderId}</Modal.Title>
                <button className="btn-close-custom" onClick={onHide}>
                    &#10006;
                </button>
            </Modal.Header>

            <Modal.Body>
                <div className="container-fluid mb-3">
                    <ResponsiveTable className="text-center">
                        <thead>
                            <tr>
                                <th>NO</th>
                                <th>상품명</th>
                                <th>옵션 1</th>
                                <th>옵션 2</th>
                                <th>수량</th>
                                <th>단가</th>
                                <th>Link</th>
                                <th>주문번호</th>
                                <th>배송상태</th>
                            </tr>
                        </thead>
                        <tbody>
                            {orderDetails.map((detail, index) => (
                                <tr key={index}>
                                    <td>{detail.productId}</td>
                                    <td>{detail.name}</td>
                                    <td>{detail.option1}</td>
                                    <td>{detail.option2}</td>
                                    <td>{detail.quantity}</td>
                                    <td>{detail.price}</td>
                                    <td className="text-center">
                                        <a
                                            href={detail.url.startsWith('http') ? detail.url : `https://${detail.url}`}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                const fullUrl = detail.url.includes("://") ? detail.url : `https://${detail.url}`;
                                                copyToClipboardAndNavigate(fullUrl);
                                            }}
                                            target="_blank"
                                            className="d-inline-block text-truncate"
                                            style={{ maxWidth: "200px" }}
                                            title={detail.url}
                                        >
                                            {detail.url}
                                        </a>
                                    </td>
                                    <td>{detail.PackageId || '데이터 없음'}</td>
                                    <th>{getstatusMethod(detail.status)}</th>
                                </tr>
                            ))}
                        </tbody>
                    </ResponsiveTable>
                </div>
                <div style={{ backgroundColor: '#ffcccc', padding: '10px', borderRadius: '5px', margin: '10px 0' }}>
                    <h1>취소 사유: {fancallNumber || '데이터 없음'}</h1>
                </div>
                <div className="container-fluid mb-3">
                    <ResponsiveTable className="text-center">
                        <thead>
                            <tr>
                                <th>항목</th>
                                <th>값</th>
                            </tr>
                        </thead>
                        <tbody>
                            {shopData.PaymentMethod === 1 && (
                                <>
                                    <tr>
                                        <td>사용 포인트 (KRW)</td>
                                        <td>{shopData.snKrwPointApplied}</td>
                                    </tr>
                                    <tr>
                                        <td>쿠폰 적용 금액 (KRW)</td>
                                        <td>{shopData.snKrwCouponApplied}</td>
                                    </tr>
                                    <tr>
                                        <td>총 금액 (KRW)</td>
                                        <td>{shopData.snKrwTotalFee}</td>
                                    </tr>
                                    <tr>
                                        <td>실제 결제 금액 (KRW)</td>
                                        <td>{shopData.snKrwRealFee}</td>
                                    </tr>
                                </>
                            )}
                            {(shopData.PaymentMethod === 2 || shopData.PaymentMethod === 3) && (
                                <>
                                    <tr>
                                        <td>사용 포인트 (USD)</td>
                                        <td>{shopData.snUsdPointApplied}</td>
                                    </tr>
                                    <tr>
                                        <td>쿠폰 적용 금액 (USD)</td>
                                        <td>{shopData.snUsdCouponApplied}</td>
                                    </tr>
                                    <tr>
                                        <td>총 금액 (USD)</td>
                                        <td>{shopData.snUsdTotalFee}</td>
                                    </tr>
                                    <tr>
                                        <td>실제 결재 금액 (USD)</td>
                                        <td>{shopData.snUsdRealFee}</td>
                                    </tr>
                                </>
                            )}
                        </tbody>
                    </ResponsiveTable>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ShopCancelModal;

const ResponsiveTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  table-layout: auto;

  th, td {
    padding: 8px;
    text-align: center;
    border: 1px solid #ddd;
  }

  @media (max-width: 768px) {
    font-size: 0.8em;
    th, td {
      padding: 6px;
    }
  }

  @media (max-width: 480px) {
    font-size: 0.7em;
    th, td {
      padding: 4px;
    }
  }
`;
