import { AxiosManager } from "../axiosManager";

// 사용자 - 리뷰 생성
export async function reqCreateReview(payload: any) {
  return await AxiosManager.post("review/create", payload);
}

// 사용자 - 리뷰 수정
export async function reqModifyReview(payload: any) {
  return await AxiosManager.put("review/modify", payload);
}

// 사용자 - 리뷰 삭제
export async function reqDeleteReview(payload: any) {
  return await AxiosManager.delete(`review/delete/${payload}`);
}

// 사용자 - 자신의 리뷰 조회
export async function reqAllReviewByUser(payload: {
  userId: number;
  page: number;
}) {
  return await AxiosManager.get(
    `review/get-my/${payload.userId}/${payload.page}`
  );
}
// 전체 조회
export async function reqALlReview(payload: any) {
  return await AxiosManager.get("review/get-all", payload);
}

// 사용자 - 리뷰 최신순
export async function reqAllReviewByRecentToUser(payload: { page: number }) {
  return await AxiosManager.get(`review/get-recent/user/${payload.page}`);
}

// 관리자 - 리뷰 최신순
export async function reqAllReviewByRecentToAdmin(payload: { page: number }) {
  return await AxiosManager.get(`review/get-recent/admin/${payload.page}`);
}

// 사용자 - 리뷰 평점순
export async function reqAllReviewByRatingToUser(payload: { page: number }) {
  return await AxiosManager.get(`review/get-rating/user/${payload.page}`);
}

// 관리자 - 리뷰 평점순
export async function reqAllReviewByRatingToAdmin(payload: { page: number }) {
  return await AxiosManager.get(`review/get-rating/admin/${payload.page}`);
}

// 관리자 - 리뷰 평점순
export async function reqReviewForLandingPage(payload: { page: number }) {
  return await AxiosManager.get(`review/get-rating/landing/${payload.page}`);
}

// 관리자 - 응답 생성
export async function reqAddComment(payload: any) {
  return await AxiosManager.post("review/comment/create", payload);
}

// 사용자 - 리뷰 조회수 증가
export async function reqPlusViewCount(payload: any) {
  return await AxiosManager.post(`review/plus-view-count`, payload);
}

// 관리자 - 리뷰 상태 수정
export async function reqSetReviewStatus(payload: any) {
  return await AxiosManager.put("review/set-status", payload);
}
