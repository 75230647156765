import { AxiosManager } from "../axiosManager";

export async function reqCreatePopup(payload: any) {
  return await AxiosManager.post("popup/create", payload);
}

// 게시하기 - 내리기
export async function reqSetPopupStatus(payload: any) {
  return await AxiosManager.post("popup/status", payload);
}

export async function reqModifyPopup(payload: any) {
  return await AxiosManager.post("popup/modify", payload);
}

// 사용자 대상
export async function reqGetNowPopupList() {
  return await AxiosManager.get("popup/get-now");
}

// 관리자 대상
export async function reqGetPopupList() {
  return await AxiosManager.get("popup/list");
}

export async function reqDeletePopup(payload: any) {
  return await AxiosManager.delete("popup/delete", {
    params: payload,
  });
}
