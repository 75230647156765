import { format, parseISO } from "date-fns";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import styled from "styled-components";
import * as XLSX from "xlsx";
import AWarehouseModel from "../../../components/modal/AWarehouseModel";
import {
  reqAShipS,
  reqfilterByUserId,
  reqfilterByTracking,
} from "../../../requests/ship";
import AdminHeadTitle from "../../../components/AdminHeadTitle";
import Icon from "../../../components/Icon";

interface ShipRecord {
  id: string;
  tracking: string;
  userId: string;
  warehouseId: string;
  createdAt: string;
  updatedAt: string;
  warehouse: string;
  Storage: boolean;
  freeAt: string;
  status: number;
  orderId: string;
  user: {
    name: string;
    membershipId: number;
  };
}

const statusOptions: { [key: number]: string } = {
  1: "상품 미입력 상태",
  2: "배송대행 가능 상태",
  3: "출고",
  4: "체크 컨텐츠",
  5: "스플릿",
  6: "배송 완료",
  10: "폐기중",
  11: "만료",
};

export default function AWAll() {
  const [shipRecords, setShipRecords] = useState<ShipRecord[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedTrackingNumber, setSelectedTrackingNumber] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRecords, setSelectedRecords] = useState<string[]>([]);
  const [searchUserId, setSearchUserId] = useState("");
  const [searchTracking, setSearchTracking] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);

  const fetchShipRecords = async () => {
    try {
      const response = await reqAShipS(page, limit, selectedStatus);
      const { data, total } = response.data;
      setShipRecords(data);
      setTotalRecords(total);
    } catch (error) {
      console.error("입고기록 조회 실패:", error);
    }
  };

  useEffect(() => {
    fetchShipRecords();
  }, [page, limit, selectedStatus]);

  useEffect(() => {
    const fetchFilteredRecords = async () => {
      try {
        if (searchUserId) {
          const response = await reqfilterByUserId(searchUserId);
          const { data } = response.data;
          setShipRecords(applyStatusFilter(data));
        } else if (searchTracking) {
          const response = await reqfilterByTracking(searchTracking);
          const { data } = response.data;
          setShipRecords(applyStatusFilter(data));
        } else {
          fetchShipRecords();
        }
      } catch (error) {
        console.error("입고기록 조회 실패:", error);
      }
    };

    fetchFilteredRecords();
  }, [searchUserId, searchTracking, selectedStatus]);

  const handleSearch = () => {
    if (searchUserId) {
      reqfilterByUserId(searchUserId).then((response) => {
        const { data } = response.data;
        setShipRecords(applyStatusFilter(data));
      }).catch((error) => {
        console.error("입고기록 조회 실패:", error);
      });
    } else if (searchTracking) {
      reqfilterByTracking(searchTracking).then((response) => {
        const { data } = response.data;
        setShipRecords(applyStatusFilter(data));
      }).catch((error) => {
        console.error("입고기록 조회 실패:", error);
      });
    } else {
      fetchShipRecords();
    }
  };

  const handleLimitChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setLimit(Number(event.target.value));
    setPage(1);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const applyStatusFilter = (data: ShipRecord[]) => {
    return selectedStatus
      ? data.filter((record) => record.status.toString() === selectedStatus)
      : data;
  };

  const filteredRecords = shipRecords.filter(
    (record) =>
      record.userId.toLowerCase().includes(searchTerm.toLowerCase()) &&
      (selectedStatus ? record.status.toString() === selectedStatus : true)
  );

  const renderPaginationControls = () => {
    const totalPages = Math.ceil(totalRecords / limit);
    return (
      <PaginationControlsContainer>
        <button onClick={() => handlePageChange(page - 1)} disabled={page <= 1}>
          이전
        </button>
        <span>
          페이지 {page} / {totalPages}
        </span>
        <button
          onClick={() => handlePageChange(page + 1)}
          disabled={page >= totalPages}
        >
          다음
        </button>
      </PaginationControlsContainer>
    );
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleRowClick = (trackingNumber: string) => {
    setSelectedTrackingNumber(trackingNumber);
    setShowModal(true);
  };

  const handleExportToExcel = () => {
    const selectedData = shipRecords.filter((record) =>
      selectedRecords.includes(record.id)
    );
    const ws = XLSX.utils.json_to_sheet(
      selectedData.map(
        ({ tracking, userId, createdAt, orderId, status }, index) => ({
          "#": index + 1,
          송장번호: tracking,
          "입고 코드": userId,
          "입고 날짜": format(parseISO(createdAt), "yyyy-MM-dd HH:mm:ss"),
          "주문 번호": orderId,
          상태: getStatusText(status),
        })
      )
    );
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "SelectedShipRecords");
    XLSX.writeFile(wb, "SelectedShipRecords.xlsx");
  };

  const getStatusText = (status: keyof typeof statusOptions) => {
    return statusOptions[status] || "알 수 없음";
  };

  const handleSelectAllChange = (isChecked: boolean) => {
    setSelectAll(isChecked);
    setSelectedRecords(isChecked ? shipRecords.map((record) => record.id) : []);
  };

  const toggleRecordSelection = (id: string, isChecked: boolean) => {
    setSelectedRecords((prev) =>
      isChecked ? [...prev, id] : prev.filter((recordId) => recordId !== id)
    );
  };

  // 띄어쓰기
  const handleKeyPress = (event: any) => {
    if (event.key === " ") {
      event.preventDefault();
    }
  };

  return (
    <>
      <main id="main" className="main">
        <AdminHeadTitle
          subtitle1="창고 관리"
          subtitle2="전체 상품 관리"
          title="전체 상품 관리"
        />

        <main>
          <FilterContainer>
            <Box className="status">
              <select
                name="status"
                className="input-reset dropdown"
                onChange={(e: any) => setSelectedStatus(e.target.value)}
              >
                <option value="">Filter By Status</option>
                {Object.entries(statusOptions).map(([key, value]) => (
                  <option key={key} value={key}>
                    {value}
                  </option>
                ))}
              </select>
              <Icon
                icon="caret-down-fill"
                style={{ position: "absolute", top: "20%", right: "4%" }}
              />
            </Box>
            <Box className="search">
              <div id="search-input">
                <Input
                  type="text"
                  className="input-reset"
                  placeholder="User ID"
                  value={searchUserId}
                  onChange={(e: any) =>
                    setSearchUserId(e.target.value.toUpperCase())
                  }
                  onKeyPress={handleKeyPress}
                />
                <SearchButton>
                  <Icon icon="search" color="var(--color-main-blue)" />
                </SearchButton>
              </div>
            </Box>
          </FilterContainer>
          <FilterContainer>
            <Box className="view">
              <select
                name="view"
                className="input-reset dropdown"
                value={limit.toString()}
                onChange={handleLimitChange}
              >
                <option value="10">See 10</option>
                <option value="50">See 50</option>
                <option value="100">See 100</option>
                <option value="999999">Full View</option>
              </select>
              <Icon
                icon="caret-down-fill"
                style={{ position: "absolute", top: "20%", right: "6%" }}
              />
            </Box>
            <Box className="search">
              <div id="search-input">
                <Input
                  type="text"
                  className="input-reset"
                  placeholder="Tracking Number"
                  value={searchTracking}
                  onChange={(e: any) =>
                    setSearchTracking(e.target.value.toUpperCase())
                  }
                  onKeyPress={handleKeyPress}
                />
                <SearchButton>
                  <Icon icon="search" color="var(--color-main-blue)" />
                </SearchButton>
              </div>
            </Box>
          </FilterContainer>
          <RightAlignedContainer>
            <button
              onClick={handleExportToExcel}
              className="btn btn-primary"
              style={{ marginLeft: "10px" }}
            >
              Export to Excel
            </button>
          </RightAlignedContainer>
          <table className="table text-center">
            <thead>
              <tr>
                <th scope="col">
                  <input
                    type="checkbox"
                    checked={selectAll}
                    onChange={(e) => handleSelectAllChange(e.target.checked)}
                  />
                </th>
                <th scope="col">#</th>
                <th scope="col">송장번호</th>
                <th scope="col">입고코드</th>
                <th scope="col">입고날자</th>
                <th scope="col">주문번호</th>
                <th scope="col">상태</th>
              </tr>
            </thead>
            <tbody>
              {filteredRecords.map((record, index) => (
                <tr key={record.id}>
                  <th scope="row">
                    <input
                      type="checkbox"
                      onChange={(e) =>
                        toggleRecordSelection(record.id, e.target.checked)
                      }
                      checked={selectedRecords.includes(record.id)}
                    />
                  </th>
                  <td
                    onClick={() => handleRowClick(record.tracking)}
                    style={{ cursor: "pointer" }}
                  >
                    {index + 1}
                  </td>
                  <td
                    onClick={() => handleRowClick(record.tracking)}
                    style={{ cursor: "pointer" }}
                  >
                    {record.tracking}
                  </td>
                  <td
                    onClick={() => handleRowClick(record.tracking)}
                    style={{ cursor: "pointer" }}
                  >
                    {record.userId}
                  </td>
                  <td
                    onClick={() => handleRowClick(record.tracking)}
                    style={{ cursor: "pointer" }}
                  >
                    {format(parseISO(record.createdAt), "yyyy-MM-dd HH:mm:ss")}
                  </td>
                  <td
                    onClick={() => handleRowClick(record.tracking)}
                    style={{ cursor: "pointer" }}
                  >
                    {record.orderId || "출고 전"}
                  </td>
                  <td
                    onClick={() => handleRowClick(record.tracking)}
                    style={{ cursor: "pointer" }}
                  >
                    {getStatusText(record.status)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <PaginationControlsContainer>
            {renderPaginationControls()}
          </PaginationControlsContainer>
        </main>
      </main>
      <Modal size="lg" show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Product Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AWarehouseModel
            trackingNumber={selectedTrackingNumber}
            onClose={() => setShowModal(false)}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

const PaginationControlsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const RightAlignedContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 20px;
`;

const FilterContainer = styled.div`
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  display: flex;
  flex-wrap: wrap;
`;

const Box = styled.div`
  display: flex;
  border: 1px solid lightgray;
  border-radius: 0.4rem;
  position: relative;
  width: 100%;
  max-width: 300px;
  margin: 5px;
  .input-reset {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0.4rem;
    width: 100%;
    border: none;
    padding: 0.4rem 0.8rem;
  }
  #search-opt-dropdown {
    position: relative;
    flex: 1 1 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 1px solid lightgray;
  }
  #search-opt-input {
    flex: 1 1 0;
    margin-left: 1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  #search-input {
    position: relative;
    display: flex;
    align-items: center;
    flex: 1.4;
    margin-left: 1px;
    border-radius: 4px;
  }
`;
const Input = styled.input`
  flex-grow: 2;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;
const SearchButton = styled.div`
  border: 1px solid var(--color-main-blue);
  padding: 0.4rem 1rem;
  align-items: center;
  border-top-right-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem;
  cursor: pointer;
`;

