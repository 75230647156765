import React from "react";
import { isMobile } from "react-device-detect";
import CustomerPageTitle from "../../components/CustomerPageTitle";
import styled from "styled-components";
import { Tab, Tabs } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";

const CShopGuide = () => {
  const qaList = [
    {
      question: `What payment methods do you accept ?`,
      answer: `For custom orders we accept 2 kinds of currencies : 
Korean Won (₩) and US Dollars ($).
For Korean Won (₩) you can pay with Wise.
For US Dollars ($) you can pay through our website with PayPal and/or credit card (Visa, Master, Amex)`,
    },
    {
      question: `How much is the custom order service ?`,
      answer: `Below 50 000₩ purchase the fee will be $5 or 5 000₩. Between 50 000₩ we charge 10%, above 100 000₩ we charge 8%, above 200 000₩ we charge 7%, above 300 000₩ we charge 6%, above 400 000₩ we charge 5%. For more info please check out the pricing page.`,
    },
    {
      question: `What is the Third-party Payment fee ?`,
      answer: `All payments in USD have an applicable Third-party payment provider fee of 4%.`,
    },
    {
      question: `Do I need to pay storage fee for custom orders ?`,
      answer: `For the Green Membership users (Basic user), all custom order get 30 days free storage period when they get added to your space. After the 30 days free storage you will get charged $1 / per day and / per package. For Red and Blue Membership users, all custom orderd will benefit the free storage according to the membership period (3 months or 1 year).`,
    },
    {
      question: `Do you send picture of the order when it arrives ?`,
      answer: `Once your order arrives at the warehouse it will appear on your dashboard in the 'My Storage' section. You can click on it you will be able to see the item picture.`,
    },
    {
      question: `Can you purchase from non-Korean websites ?`,
      answer: `We only accept custom order request in Korean Won (₩) no other currencies. We do not accept custom order requests for overseas website (Outside of Korea). However we do accept overseas packages ordered by yourself.`,
    },
    {
      question: `Why was my order canceled ?`,
      answer: `One of the biggest reason an order getting canceled is that the item is sold out. 
If one or several item gets soldout during invoice calculation, staff will apply the cancellation option you choose when requestin 'Total cancellation' or ' Partial cancellation'.
If one or several items gets soldout after you processed with payment staff will apply the cancellation option you choose when requestin 'Total cancellation' or ' Partial cancellation'. Ontact Korea staff will then apply the refund method you choose by points or direct refund. For more details on refund methods check out our terms and conditions.`,
    },
    {
      question: `Can I cancel my order ?`,
      answer: `You can cancel an order request anytime before payment. Once the payment is made and the items are ordered, no cancellation can be made.`,
    },
    {
      question: `Can I get a refund ?`,
      answer: `You can get a refund in case of sold out items. You can choose which refund method you would like when making the payment. For more details on refund methods check out our terms and conditions.`,
    },
    {
      question: `Can you help me apply for fancall events ?`,
      answer: `We can help you apply for fancall events however you need to fill out the fancall section form. This information is strictly used for fancall application and nothing else.`,
    },
    {
      question: `Can you buy cosmetics and snacks ?`,
      answer: `You can purchase cosmetics and snacks through our custom order service. However, customers should be sure that items comply with shipping carriers shipping rules and their destination country customs rules and regulations. For more information please check our terms and conditions.`,
    },
    {
      question: `I have a membership how hoes the benefit apply ?`,
      answer: `Red and Blue membership users hold special custom order benefits. To learn more about those benefits please visit the membership page on your dashboard.`,
    },
    {
      question: `Are wholesale orders possible ?`,
      answer: `For wholesale orders please contact us at ontactkorea@gmail.com with your detailed request.`,
    },
  ];

  return (
    <Wrapper style={{ padding: `${isMobile && "1.4rem"}` }}>
      <CustomerPageTitle title="Guide: Tutorial video custom order" />

      <GuideWrapper>
        <div className="f-col">
          <div
            className={`${isMobile ? "f-col line" : "f-row line"}`}
            style={{ paddingBottom: "2rem" }}
          >
            <div className={`${isMobile ? "line" : ""}`}>
              <Tabs
                defaultActiveKey="step1"
                id="uncontrolled-tab-example"
                className="mb-3"
              >
                {/* Step 1 */}
                <Tab eventKey="step1" title="Step 1">
                  <img
                    className={`${isMobile ? "mobile-guide-img" : "guide-img"}`}
                    src="/resources/img/guide-1/1.png"
                    alt="1"
                  />
                </Tab>

                {/* Step 2 */}
                <Tab eventKey="step2" title="Step 2">
                  <Carousel slide={false}>
                    <Carousel.Item>
                      <img
                        className={`${
                          isMobile ? "mobile-guide-img" : "guide-img"
                        }`}
                        src="/resources/img/guide-1/2.png"
                        alt="2-1"
                      />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className={`${
                          isMobile ? "mobile-guide-img" : "guide-img"
                        }`}
                        src="/resources/img/guide-1/3.png"
                        alt="2-2"
                      />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className={`${
                          isMobile ? "mobile-guide-img" : "guide-img"
                        }`}
                        src="/resources/img/guide-1/4.png"
                        alt="2-3"
                      />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className={`${
                          isMobile ? "mobile-guide-img" : "guide-img"
                        }`}
                        src="/resources/img/guide-1/5.png"
                        alt="2-4"
                      />
                    </Carousel.Item>
                  </Carousel>
                </Tab>

                {/* Step 3 */}
                <Tab eventKey="step3" title="Step 3">
                  <img
                    className={`${isMobile ? "mobile-guide-img" : "guide-img"}`}
                    src="/resources/img/guide-1/6.png"
                    alt="3"
                  />
                </Tab>

                {/* Step 4 */}
                <Tab eventKey="step4" title="Step 4">
                  <Carousel slide={false}>
                    <Carousel.Item>
                      <img
                        className={`${
                          isMobile ? "mobile-guide-img" : "guide-img"
                        }`}
                        src="/resources/img/guide-1/7.png"
                        alt="4-1"
                      />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className={`${
                          isMobile ? "mobile-guide-img" : "guide-img"
                        }`}
                        src="/resources/img/guide-1/8.png"
                        alt="4-2"
                      />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className={`${
                          isMobile ? "mobile-guide-img" : "guide-img"
                        }`}
                        src="/resources/img/guide-1/9.png"
                        alt="4-3"
                      />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className={`${
                          isMobile ? "mobile-guide-img" : "guide-img"
                        }`}
                        src="/resources/img/guide-1/10.png"
                        alt="4-4"
                      />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className={`${
                          isMobile ? "mobile-guide-img" : "guide-img"
                        }`}
                        src="/resources/img/guide-1/11.png"
                        alt="4-5"
                      />
                    </Carousel.Item>
                  </Carousel>
                </Tab>

                {/* Step 5 */}
                <Tab eventKey="step5" title="Step 5">
                  <Carousel slide={false}>
                    <Carousel.Item>
                      <img
                        className={`${
                          isMobile ? "mobile-guide-img" : "guide-img"
                        }`}
                        src="/resources/img/guide-1/12.png"
                        alt="5-1"
                      />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className={`${
                          isMobile ? "mobile-guide-img" : "guide-img"
                        }`}
                        src="/resources/img/guide-1/13.png"
                        alt="5-2"
                      />
                    </Carousel.Item>
                  </Carousel>
                </Tab>

                {/* Step 6 */}
                <Tab eventKey="step6" title="Step 6">
                  <img
                    className={`${isMobile ? "mobile-guide-img" : "guide-img"}`}
                    src="/resources/img/guide-1/14.png"
                    alt="6"
                  />
                </Tab>
              </Tabs>
            </div>

            {isMobile ? (
              <div>
                <div
                  style={{
                    fontSize: "1.4rem",
                    fontWeight: "bold",
                    margin: "1.6rem 0 1.4rem 0",
                    color: "var(--color-main-blue)",
                  }}
                >
                  Custom order status workflow
                </div>
                <img
                  src="/resources/img/guide-step1.png"
                  alt="ex"
                  style={{ marginLeft: ".6rem", width: "100%" }}
                />
              </div>
            ) : (
              <div style={{ marginLeft: "4rem" }}>
                <Tabs
                  defaultActiveKey="step1"
                  id="uncontrolled-tab-example"
                  className="mb-3"
                >
                  <Tab eventKey="step1" title="Custom order status workflow">
                    <img
                      src="/resources/img/guide-step1.png"
                      alt="ex"
                      style={{ marginLeft: ".6rem" }}
                    />
                  </Tab>
                </Tabs>
              </div>
            )}
          </div>

          <FaqWrapper>
            <div className="qa-title">Custom order service FAQ</div>

            {qaList.map((qa: any) => {
              return (
                <div className="qa-section">
                  <div className="question">{qa.question}</div>
                  <div className="answer">{qa.answer}</div>
                </div>
              );
            })}
          </FaqWrapper>
        </div>
      </GuideWrapper>
    </Wrapper>
  );
};

const FaqWrapper = styled.div`
  .qa-title {
    color: var(--color-main-blue);
    font-weight: bold;
    font-size: 1.4rem;
    margin: 1.4rem 0 1rem 0;
  }
  .qa-section {
    margin-bottom: 1rem;
  }
  .question {
    font-weight: bold;
    font-size: 1.2rem;
  }
  .answer {
    margin: 0.4rem 0 0 0.6rem;
  }
`;

const GuideWrapper = styled.div`
  .guide-img {
    height: 721px;
  }
  .mobile-guide-img {
    width: 100%;
  }
`;

const Wrapper = styled.div`
  padding: 2rem;
  margin-bottom: 4rem;
`;

export default CShopGuide;
