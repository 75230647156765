import { AxiosManager } from "../axiosManager";

export const reqCodeAll = async () => {
  const result = await AxiosManager.get("code/all");

  return result;
};

export function reqUploadQNAImage(id: string, file: File) {
  const formData = new FormData();
  formData.append("file", file);

  return AxiosManager.post(`question-and-response/${id}/upload`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
