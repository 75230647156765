import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Pagination from "react-bootstrap/Pagination";
import styled from "styled-components";
import { CODE_COUNTRY_ITEMS } from "../../../common/constants";
import AdminHeadTitle from "../../../components/AdminHeadTitle";
import { reqGetAllUserById, reqGetLogsByUserId } from "../../../requests/user";

interface User {
  id: string;
  name: string;
  country: number;
  email: string;
  point: number;
  pointUsd: number;
  latestWarehouseId: string;
}

interface PointLog {
  createdAt: string;
  used: boolean;
  grant: boolean;
  krwUsedPoint: number;
  usdUsedPoint: number;
  krwGrantPoint: number;
  usdGrantPoint: number;
  status: number;
  manager: string;
  reason: string;
}

const Apoint: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [pointHistory, setPointHistory] = useState<PointLog[]>([]);
  const [showPointLogModal, setShowPointLogModal] = useState(false);

  useEffect(() => {
    fetchAllUsers();
  }, []);

  const fetchAllUsers = async () => {
    try {
      const response = await reqGetAllUserById();
      const sortedUsers = response.data.sort((a: User, b: User) => {
        if (b.pointUsd === a.pointUsd) {
          return b.point - a.point;
        }
        return b.pointUsd - a.pointUsd;
      });
      setUsers(sortedUsers);
      setTotalPages(Math.ceil(sortedUsers.length / itemsPerPage));
    } catch (error) {
      console.error("전체 사용자 정보 조회 실패:", error);
    }
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const uppercaseValue = e.target.value.toUpperCase();
    setSearchTerm(uppercaseValue);
    setCurrentPage(1); // 검색 시 첫 페이지로 이동
  };

  const filteredUsers = users.filter((user) =>
    user.id.toUpperCase().includes(searchTerm.toUpperCase())
  );

  const startIndex = (currentPage - 1) * itemsPerPage;
  const displayedUsers = filteredUsers.slice(
    startIndex,
    itemsPerPage === -1 ? filteredUsers.length : startIndex + itemsPerPage
  );

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleItemsPerPageChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const value = parseInt(e.target.value);
    setItemsPerPage(value === -1 ? users.length : value);
    setCurrentPage(1);
    setTotalPages(
      Math.ceil(filteredUsers.length / (value === -1 ? users.length : value))
    );
  };

  const handleRowClick = async (user: User) => {
    try {
      const response = await reqGetLogsByUserId(user.id);
      setPointHistory(response.data);
      setSelectedUser(user);
      setShowPointLogModal(true);
    } catch (error) {
      console.error("Failed to fetch point history:", error);
    }
  };

  const formatDate = (date: string) => {
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };
    return new Date(date).toLocaleDateString(undefined, options);
  };

  const getSourceText = (status: number) => {
    switch (status) {
      case 1:
        return "구매대행";
      case 2:
        return "배송대행";
      case 3:
        return "리뷰";
      case 4:
        return "매니저";
      case 5:
        return "환불";
      default:
        return "Unknown";
    }
  };

  return (
    <main id="main" className="main">
      <div>
        <AdminHeadTitle
          subtitle1="고객 관리"
          subtitle2="포인트 관리"
          title="포인트 관리"
        />

        <div className="container-fluid mb-4">
          <div className="row">
            <div className="col-md-4">
              <label htmlFor="itemsPerPage" className="form-label">
                페이지 당 항목 수
              </label>
              <select
                id="itemsPerPage"
                className="form-select"
                value={itemsPerPage === users.length ? -1 : itemsPerPage}
                onChange={handleItemsPerPageChange}
              >
                <option value={10}>10개씩 보기</option>
                <option value={20}>20개씩 보기</option>
                <option value={50}>50개씩 보기</option>
                <option value={-1}>전체 보기</option>
              </select>
            </div>
            <div className="col-md-4">
              <label htmlFor="searchTerm" className="form-label">
                스페이스코드 검색
              </label>
              <input
                type="text"
                id="searchTerm"
                className="form-control"
                value={searchTerm}
                onChange={handleSearchChange}
                style={{ textTransform: "uppercase" }}
              />
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <table className="table text-center">
            <thead>
              <tr>
                <th>이름</th>
                <th>스페이스코드</th>
                <th>국가</th>
                <th>이메일</th>
                <th>KRP</th>
                <th>USP</th>
              </tr>
            </thead>
            <tbody>
              {displayedUsers.map((user, index) => (
                <tr
                  key={index}
                  onClick={() => handleRowClick(user)}
                  style={{ cursor: "pointer" }}
                >
                  <td>{user.name}</td>
                  <td>{user.id}</td>
                  <td>{CODE_COUNTRY_ITEMS[user.country] || "Unknown"}</td>
                  <td>{user.email}</td>
                  <td>{user.point}P</td>
                  <td>{user.pointUsd}P</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <CenteredPagination>
          <Pagination.First
            onClick={() => handlePageChange(1)}
            disabled={currentPage === 1}
          />
          <Pagination.Prev
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          />
          {[...Array(totalPages > 10 ? 10 : totalPages)].map((_, index) => (
            <Pagination.Item
              key={index}
              active={index + 1 === currentPage}
              onClick={() => handlePageChange(index + 1)}
            >
              {index + 1}
            </Pagination.Item>
          ))}
          {totalPages > 10 && (
            <>
              <Pagination.Ellipsis disabled />
              <Pagination.Item onClick={() => handlePageChange(totalPages)}>
                {totalPages}
              </Pagination.Item>
            </>
          )}
          <Pagination.Next
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          />
          <Pagination.Last
            onClick={() => handlePageChange(totalPages)}
            disabled={currentPage === totalPages}
          />
        </CenteredPagination>
      </div>

      <Modal
        show={showPointLogModal}
        onHide={() => setShowPointLogModal(false)}
        size="xl"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{selectedUser?.name}'s Point History</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedUser && (
            <Table bordered>
              <tbody>
                <tr>
                  <td>SPACE CODE</td>
                  <td>{selectedUser.id}</td>
                  <td>보유 포인트</td>
                </tr>
                <tr>
                  <td>NAME</td>
                  <td>{selectedUser.name}</td>
                  <td>{selectedUser.point} KRP</td>
                </tr>
                <tr>
                  <td>COUNTRY</td>
                  <td>{CODE_COUNTRY_ITEMS[selectedUser.country]}</td>
                  <td>{selectedUser.pointUsd} USP</td>
                </tr>
              </tbody>
            </Table>
          )}
          <div className="table-responsive">
            <Table>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Type</th>
                  <th>Points</th>
                  <th>Sources</th>
                  <th>지급</th>
                  <th>reason</th>
                </tr>
              </thead>
              <tbody>
                {pointHistory.map((log, index) => (
                  <tr key={index}>
                    <td>{formatDate(log.createdAt)}</td>
                    <td>
                      {log.used ? "Used" : log.grant ? "Granted" : "Unknown"}
                    </td>
                    <td>
                      {log.used ? (
                        <>
                          {log.krwUsedPoint !== 0 &&
                            `KRW: -${log.krwUsedPoint}`}
                          {log.krwUsedPoint !== 0 && log.usdUsedPoint !== 0 && (
                            <br />
                          )}
                          {log.usdUsedPoint !== 0 &&
                            parseFloat(log.usdUsedPoint.toString()).toFixed(
                              2
                            ) !== "0.00" &&
                            `USD: -${parseFloat(
                              log.usdUsedPoint.toString()
                            ).toFixed(2)}`}
                        </>
                      ) : log.grant ? (
                        <>
                          {log.krwGrantPoint !== 0 &&
                            `KRW: +${log.krwGrantPoint}`}
                          {log.krwGrantPoint !== 0 &&
                            log.usdGrantPoint !== 0 && <br />}
                          {log.usdGrantPoint !== 0 &&
                            parseFloat(log.usdGrantPoint.toString()).toFixed(
                              2
                            ) !== "0.00" &&
                            `USD: +${parseFloat(
                              log.usdGrantPoint.toString()
                            ).toFixed(2)}`}
                        </>
                      ) : (
                        "0"
                      )}
                    </td>
                    <td>{getSourceText(log.status)}</td>
                    <td>{log.manager || "없음"}</td>
                    <td>{log.reason || "없음"}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Modal.Body>
      </Modal>
    </main>
  );
};

const CenteredPagination = styled(Pagination)`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
`;

export default Apoint;
