import { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import { reqShipFEE } from "../../../requests/ship";

function ACalculator() {
  const [actualWeight, setActualWeight] = useState<number>(0);
  const [length, setLength] = useState<number>(0);
  const [width, setWidth] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);
  const [selectedCountry, setSelectedCountry] = useState<string>("");
  const [rates, setRates] = useState<Record<string, Record<string, number>>>(
    {}
  );
  const [result, setResult] = useState<{
    baseRate?: number;
    specialHandlingFee?: number;
    fuelSurcharge?: number;
    remoteAreaSurcharge?: number;
    serviceFee?: number;
    vat?: number;
    totalCostKRW?: number;
    totalCostUSD?: number;
  }>({});
  const [shippingData, setShippingData] = useState({
    fedexFuelSurcharge: 5,
    fedexServiceFee: 5,
    fixedExchangeRate: 5,
  });
  const roundUpToHalf = (num: number) => {
    return Math.ceil(num * 2) / 2;
  };

  useEffect(() => {
    const fetchShippingData = async () => {
      try {
        const response = await reqShipFEE();
        console.log("API 응답:", response.data);
        if (response.data.length > 0) {
          const data = response.data[0];
          setShippingData({
            fedexFuelSurcharge: parseFloat(data.fedexFuelSurcharge),
            fixedExchangeRate: parseFloat(data.fixedExchangeRate),
            fedexServiceFee: parseFloat(data.fedexServiceFee),
          });
        }
      } catch (error) {
        console.error("API 요청 오류:", error);
      }
    };

    fetchShippingData();
  }, []);

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (file) {
      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        const data = e.target?.result;
        if (data) {
          const buffer = new Uint8Array(data as ArrayBuffer);
          const workbook = XLSX.read(buffer, { type: "array" });
          const firstSheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[firstSheetName];
          const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
          const mappedRates = convertToJson(jsonData);
          setRates(mappedRates);
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };

  type RateMapping = {
    [countryCode: string]: {
      [weight: number]: number;
    };
  };

  const convertToJson = (data: any[]): RateMapping => {
    const newRates: RateMapping = {};
    const countryCodes = data[0].slice(1);
    data.slice(1).forEach((row) => {
      const weight = row[0] as number;
      countryCodes.forEach((code: any, index: any) => {
        if (!newRates[code]) newRates[code] = {};
        newRates[code][weight] = row[index + 1] as number;
      });
    });
    return newRates;
  };

  const calculateVolumetricWeight = (l: number, w: number, h: number): number =>
    (l * w * h) / 5000;

  const determineApplicableWeight = (
    actualWeight: number,
    volumetricWeight: number
  ): number => Math.max(actualWeight, volumetricWeight);

  const defaultRate = 0; // 1KG당 기본 요금

  const calculateShippingCost = (weight: number) => {
    const roundedWeight = roundUpToHalf(weight);
    // 선택된 국가의 요금을 찾습니다. 'selectedCountry' 상태는 국가 코드를 저장해야 합니다.
    const countryRates = rates[selectedCountry];
    const countryRate =
      countryRates && countryRates[roundedWeight] ? countryRates[roundedWeight] : defaultRate;

    const baseRate = countryRate;

    // 특별 운송 수수료 계산
    const specialHandlingFee = weight * 1700;

    // 유류 할증료 계산
    const fuelSurcharge =
      (baseRate + specialHandlingFee) * (shippingData.fedexFuelSurcharge / 100);

    // 외곽지역 수수료 - 이 부분은 조건에 따라 결정됩니다.
    const remoteAreaSurcharge = 0;

    // 실제 청구 비용
    const actualCost =
      baseRate + specialHandlingFee + fuelSurcharge + remoteAreaSurcharge;

    // 서비스 비용 계산
    const serviceFee = actualCost * (shippingData.fedexServiceFee / 100);

    // 부가세(10 %) 계산
    const vat = (actualCost + serviceFee) * 0.1;

    // 총 청구금액 (KRW) 계산
    const totalCostKRW = actualCost + serviceFee + vat;

    // 총 청구금액 (USD) 계산
    const exchangeRate = shippingData.fixedExchangeRate;
    const exchangeFee = 0.05;
    const totalCostUSD =
      ((actualCost + serviceFee) / exchangeRate) * (1 + exchangeFee);

    return {
      baseRate,
      specialHandlingFee,
      fuelSurcharge,
      remoteAreaSurcharge,
      serviceFee,
      vat,
      totalCostKRW,
      totalCostUSD,
    };
  };

  const handleCountryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedCountry(event.target.value);
  };

  const handleInputChange = (
    value: string,
    setValue: React.Dispatch<React.SetStateAction<number>>
  ) => {
    setValue(value ? Number(value) : 0);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const volumetricWeight = calculateVolumetricWeight(length, width, height);
    const applicableWeight = determineApplicableWeight(
      actualWeight,
      volumetricWeight
    );
    const shippingCost = calculateShippingCost(applicableWeight);
    setResult(shippingCost);
  };

  const displayResult = (value?: number) => {
    return value !== undefined ? value.toFixed(2) : "";
  };

  return (
    <div>
      <input type="file" onChange={handleFileUpload} />
      <form onSubmit={handleSubmit}>
        <label htmlFor="countrySelect">국가 선택: </label>
        <select
          id="countrySelect"
          value={selectedCountry}
          onChange={handleCountryChange}
        >
          {Object.keys(rates).map((countryCode) => (
            <option key={countryCode} value={countryCode}>
              {countryCode}
            </option>
          ))}
        </select>
        <label htmlFor="actualWeight">실제 중량 (kg): </label>
        <input
          type="number"
          id="actualWeight"
          value={actualWeight}
          onChange={(e) => handleInputChange(e.target.value, setActualWeight)}
          placeholder="실제 중량 (kg)"
        />

        <label htmlFor="length">가로 (cm): </label>
        <input
          type="number"
          id="length"
          value={length}
          onChange={(e) => handleInputChange(e.target.value, setLength)}
          placeholder="긴 면 (cm)"
        />

        <label htmlFor="width">세로 (cm): </label>
        <input
          type="number"
          id="width"
          value={width}
          onChange={(e) => handleInputChange(e.target.value, setWidth)}
          placeholder="짧은 면 (cm)"
        />

        <label htmlFor="height">높이 (cm): </label>
        <input
          type="number"
          id="height"
          value={height}
          onChange={(e) => handleInputChange(e.target.value, setHeight)}
          placeholder="높이 (cm)"
        />

        <button type="submit">운임료 계산</button>
      </form>
      {result.baseRate !== undefined && (
        <div>
          <p>기본운임: ₩{result.baseRate.toFixed(2)}</p>
          <p>특별운송 수수료: ₩{result.specialHandlingFee?.toFixed(2)}</p>
          <p>유류할증료: ₩{result.fuelSurcharge?.toFixed(2)}</p>
          <p>서비스 비용: ₩{result.serviceFee?.toFixed(2)}</p>
          <p>부가세: ₩{result.vat?.toFixed(2)}</p>
          <p>총 청구금액 (KRW): ₩{result.totalCostKRW?.toFixed(2)}</p>
          <p>총 청구금액 (USD): ${result.totalCostUSD?.toFixed(2)}</p>
        </div>
      )}
    </div>
  );
}

export default ACalculator;
