import { useEffect, useState } from 'react';
import Swal from "sweetalert2";
import {
    CODE_BY_STATUS,
    CODE_SHOPING_ORDER_STATUS,
    CODE_SHOPPING_ORDER_ITEMS,
    CODE_SHOPING_PRODUCT_DETAIL_STATUS,
} from "../../common/constants";
import styled from "styled-components";
import { Button, Form, InputGroup, Modal, Table } from "react-bootstrap";
import { reqShipFEE } from "../../requests/ship";
import { reqShop, reqShopDetail } from '../../requests/shop';
interface ShopDetailsModalProps {
    show: boolean;
    onHide: () => void;
    orderId: string;
}

interface OrderDetail {
    orderId: string;
    name: string;
    option1: string;
    option2: string;
    category: number;
    quantity: number;
    price: number;
    ShippingFee: number;
    url: string;
    fee: number;
    paymentDeadline: string;
    cancellationReason: string;
    status: number;
    productId: string;
    PackageId: string;
}

interface ShopData {
    account: string;
    failed: boolean;
    refund: boolean;
    price: number;
    usePointKrw: number;
    paymentConfirmed: boolean;
    usePointUse: number;
    snKrwShippingFee: number;
    snKrwFee: number;
    snKrwTotalFee: number;
    snKrwRealFee: number;
    snKrwPointApplied: number;
    snKrwCouponApplied: number;
    snUsdFee: number;
    snUsdTotalFee: number;
    snUsdRealFee: number;
    snUsdPointApplied: number;
    snUsdCouponApplied: number;
    snUsdPaypalSurcharge: number;
    snUsdShippingFee: number;
}
const ShopDetailsModal = ({ show, onHide, orderId }: ShopDetailsModalProps) => {
    const [serviceFee, setServiceFee] = useState('');
    const [cancellationReason, setCancellationReason] = useState('');
    const [paymentDeadline, setPaymentDeadline] = useState('');
    const [orderDetails, setOrderDetails] = useState<OrderDetail[]>([]);
    const [shopData, setShopData] = useState<ShopData | null>(null);
    const [fancallNumber, setFancallNumber] = useState('');
    const excludeStatuses = [3, 4, 5, 9, 10];
    const getstatusMethod = (status: number) => {
        switch (status) {
            case 1:
                return '요청서';
            case 2:
                return '구매가능';
            case 3:
                return '구매기한 만료';
            case 4:
                return '구매수량 제한';
            case 5:
                return '품절';
            case 6:
                return '주문 완료';
            case 7:
                return '입고 등록 완료';
            case 9:
                return '주문 중 품절';
            case 10:
                return '풀절';
            default:
                return '알 수 없는 상태';
        }
    }

    useEffect(() => {
        if (orderId) {
            fetchOrderDetails(orderId);
            fetchFixedExchangeRate();
        }
    }, [orderId]);

    useEffect(() => {
        const fetchFancallNumber = async () => {
            try {
                const response = await reqShop({ order_id: orderId });
                const feeValue = response.data.fee?.toString() ?? "";
                setServiceFee(feeValue);
                setFancallNumber(response.data.fancallNumber);
                setShopData(response.data);
            } catch (error) {
                console.error('Error fetching fancallNumber:', error);
            }
        };

        if (orderId) {
            fetchFancallNumber();
        }
    }, [orderId]);

    const fetchOrderDetails = async (order_id: string) => {
        try {
            const response = await reqShopDetail({ order_id });
            setOrderDetails(response.data);
            setServiceFee(response.data.fee.toString());
            setPaymentDeadline(response.data.paymentDeadline);
            setCancellationReason(response.data.cancellationReason);
        } catch (error) {
            console.error('Error fetching order details:', error);
        }
    };

    const copyToClipboardAndNavigate = (url: any) => {
        navigator.clipboard.writeText(url)
            .then(() => {
                Swal.fire({
                    icon: 'success',
                    title: 'Copied!',
                    text: 'URL has been copied to your clipboard and opening in a new tab.',
                    timer: 1500
                });
                window.open(url, '_blank');
            })
            .catch(err => {
                console.error('Failed to copy: ', err);
                Swal.fire({
                    icon: 'error',
                    title: 'Failed!',
                    text: 'Failed to copy URL.',
                });
            });
    };
    const [fixedExchangeRate, setFixedExchangeRate] = useState(0);
    const [couponLog, setCouponLog] = useState<any>(null);
    const convertToUSD = (amount: number) => {
        return (amount / fixedExchangeRate).toFixed(2);
    };

    const fetchFixedExchangeRate = async () => {
        try {
            const response = await reqShipFEE();
            if (response && response.data && response.data[0]?.fixedExchangeRateShop) {
                setFixedExchangeRate(parseFloat(response.data[0].fixedExchangeRateShop));
            }
        } catch (error) {
            console.error("Error fetching fixed exchange rate:", error);
        }
    };

    const calculateTotal = () => {
        if (orderDetails.length === 0) {
            return {
                productTotal: 0,
                domesticShippingFee: 0,
                serviceFeeValue: 0,
                paypalFee: 0,
                total: 0,
                Utotal: 0,
                UtotalUSD: 0,
            };
        }

        const statusesToCheck = [
            CODE_SHOPING_PRODUCT_DETAIL_STATUS.EXPIRATION,
            CODE_SHOPING_PRODUCT_DETAIL_STATUS.RESTRICTION,
            CODE_SHOPING_PRODUCT_DETAIL_STATUS.SOLD_OUT,
            CODE_SHOPING_PRODUCT_DETAIL_STATUS.CANCELED,
            CODE_SHOPING_PRODUCT_DETAIL_STATUS.POINT,
        ];

        const allInSpecifiedStatuses = orderDetails.every((detail) =>
            statusesToCheck.includes(detail.status)
        );

        const productTotal = orderDetails.reduce((total, item) => {
            if (!excludeStatuses.includes(item.status)) {
                return total + item.quantity * item.price;
            }
            return total;
        }, 0);

        const domesticShippingFee = orderDetails.reduce((total, item) => {
            if (!excludeStatuses.includes(item.status)) {
                return total + item.ShippingFee;
            }
            return total;
        }, 0);

        let serviceFeeValue = 0;
        if (!allInSpecifiedStatuses) {
            const parsedServiceFee = parseFloat(serviceFee);
            serviceFeeValue = Math.round(
                productTotal * (isNaN(parsedServiceFee) ? 0 : parsedServiceFee / 100)
            );
            serviceFeeValue = Math.max(serviceFeeValue, 5000);
        }

        const paypalFee =
            (productTotal + serviceFeeValue + domesticShippingFee) * 0.05;
        const UtotalUSDValue = convertToUSD(
            productTotal + domesticShippingFee + serviceFeeValue + paypalFee
        );

        return {
            productTotal,
            domesticShippingFee,
            serviceFeeValue,
            paypalFee,
            total:
                productTotal +
                domesticShippingFee +
                serviceFeeValue -
                (couponLog?.krwDiscounted || 0),
            Utotal:
                productTotal +
                domesticShippingFee +
                serviceFeeValue +
                paypalFee -
                (couponLog?.krwDiscounted || 0),
            UtotalUSD: parseFloat(UtotalUSDValue),
        };
    };

    const {
        productTotal,
        domesticShippingFee,
        serviceFeeValue,
        paypalFee,
        total,
        Utotal,
    } = calculateTotal();

    const totals = (productTotal || 0) + (domesticShippingFee || 0) + (shopData?.price || 0);
    const Utotals = (productTotal || 0) + (domesticShippingFee || 0) + (shopData?.price || 0) + (paypalFee || 0);
    return (
        <Modal show={show} onHide={onHide} fullscreen centered>
            <Modal.Header className="bg-dark text-white">
                <Modal.Title>{orderId}</Modal.Title>
                <button className="btn-close-custom" onClick={onHide}>
                    &#10006;
                </button>
            </Modal.Header>

            <Modal.Body>
                <div className="container-fluid mb-3">
                    <ResponsiveTable className="text-center">
                        <thead>
                            <tr>
                                <th>NO</th>
                                <th>상품명</th>
                                <th>옵션 1</th>
                                <th>옵션 2</th>
                                <th>수량</th>
                                <th>단가</th>
                                <th>Link</th>
                                <th>주문번호</th>
                                <th>배송상태</th>
                            </tr>
                        </thead>
                        <tbody>
                            {orderDetails.map((detail, index) => (
                                <tr key={index}>
                                    <td>{detail.productId}</td>
                                    <td>{detail.name}</td>
                                    <td>{detail.option1}</td>
                                    <td>{detail.option2}</td>
                                    <td>{detail.quantity}</td>
                                    <td>{detail.price}</td>
                                    <td className="text-center">
                                        <a
                                            href={detail.url.startsWith('http') ? detail.url : `https://${detail.url}`}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                const fullUrl = detail.url.includes("://") ? detail.url : `https://${detail.url}`;
                                                copyToClipboardAndNavigate(fullUrl);
                                            }}
                                            target="_blank"
                                            className="d-inline-block text-truncate"
                                            style={{ maxWidth: "200px" }}
                                            title={detail.url}
                                        >
                                            {detail.url}
                                        </a>
                                    </td>
                                    <td>{detail.PackageId || '데이터 없음'}</td>
                                    <th>{getstatusMethod(detail.status)}</th>
                                </tr>
                            ))}
                        </tbody>
                    </ResponsiveTable>
                </div>
                {fancallNumber && (
                    <div style={{ backgroundColor: '#ffcccc', padding: '10px', borderRadius: '5px', margin: '10px 0' }}>
                        <h1>취소 사유: {fancallNumber}</h1>
                    </div>
                )}
                <div className="container-fluid mb-3">
                    {/* <ResponsiveTable>
                        <thead id="header-thead">
                            <tr>
                                <th scope="col">NO</th>
                                <th scope="col">품목</th>
                                <th scope="col">상품명</th>
                                <th scope="col">옵션1</th>
                                <th scope="col">옵션2</th>
                                <th scope="col">수량</th>
                                <th scope="col">단가</th>
                                <th scope="col">국내 배송비</th>
                                <th scope="col">상태</th>
                            </tr>
                        </thead>
                        <tbody id="header-tbody">
                            {orderDetails.map((detail, index) => (
                                <tr
                                    key={index}
                                    style={
                                        excludeStatuses.includes(detail.status)
                                            ? { textDecoration: "line-through" }
                                            : undefined
                                    }
                                >
                                    <td>{detail.productId}</td>
                                    <td>
                                        {CODE_SHOPPING_ORDER_ITEMS[detail.category] ||
                                            "Unknown Category"}
                                    </td>
                                    <td>{detail.name}</td>
                                    <td>{detail.option1}</td>
                                    <td>{detail.option2}</td>
                                    <td>{detail.quantity}</td>
                                    <td>{detail.price}</td>
                                    <td>{detail.ShippingFee}</td>
                                    <td>{CODE_BY_STATUS[detail.status]}</td>
                                </tr>
                            ))}
                        </tbody>
                    </ResponsiveTable> */}
                    <ResponsiveTable>
                        <thead>
                            <tr>
                                <th></th>
                                <th>KRW</th>
                                <th>USD</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>상품 합계</th>
                                <td>{productTotal}</td>
                                <td>{convertToUSD(productTotal)}</td>
                            </tr>
                            <tr>
                                <th>배송 비용</th>
                                <td>{domesticShippingFee}</td>
                                <td>{convertToUSD(domesticShippingFee)}</td>
                            </tr>
                            <tr>
                                <th>서비스 비용</th>
                                <td>{shopData?.price || 0}</td>
                                <td>{convertToUSD(shopData?.price ?? 0)}</td>
                            </tr>
                            <tr>
                                <th>환전 수수료(5%)</th>
                                <th>-</th>
                                <td>{convertToUSD(paypalFee)}</td>
                            </tr>
                            <tr>
                                <th>사용 포인트</th>
                                <td>{shopData?.snKrwPointApplied || 0}</td>
                                <td>{convertToUSD(shopData?.snUsdPointApplied || 0)}</td>
                            </tr>
                            <tr>
                                <th>사용 쿠폰 금액</th>
                                <td>{shopData?.snKrwCouponApplied || 0}</td>
                                <td>{shopData?.snUsdCouponApplied || 0}</td>
                            </tr>
                            <tr>
                                <th>합계</th>
                                <td>{totals || 0}</td>
                                <td>{convertToUSD(Utotals) || 0}</td>
                            </tr>
                        </tbody>
                    </ResponsiveTable>
                </div>
            </Modal.Body>
        </Modal>
    );
};
export default ShopDetailsModal;

const ResponsiveTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  table-layout: auto;

  th,
  td {
    padding: 8px;
    text-align: center;
    border: 1px solid #ddd;
  }

  // Using media queries to adjust font size and padding for smaller screens
  @media (max-width: 768px) {
    font-size: 0.8em;
    th,
    td {
      padding: 6px;
    }
  }

  @media (max-width: 480px) {
    font-size: 0.7em;
    th,
    td {
      padding: 4px;
    }
  }
`;