import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { codeSave } from "../../redux/codeSlice";
import { useDispatch } from "react-redux";
import { reqCodeAll } from "../../requests/code";
import { useSelector } from "react-redux";
import { CONST_COOKIE, getCookie } from "../../common/cookies";
import { reqRefreshAccessToken } from "../../requests/auth";
import { userClear, userSave } from "../../redux/userSlice";
import { tokenSave } from "../../redux/tokenSlice";
import { adminClear, adminSave } from "../../redux/adminSlice";
import { reqAdminRefreshAccessToken } from "../../requests/admin-auth";

export default function BlankLayout() {
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state.token);
  const admin = useSelector((state: any) => state.admin);
  const code = useSelector((state: any) => state.code);

  useEffect(() => {
    const code = async () => {
      const result = await reqCodeAll();
      dispatch(codeSave(result.data));
    };
    code();
  }, [code.refresh]);

  useEffect(() => {
    const srf = async () => {
      if (!user.accessToken) {
        const refreshToken = getCookie(CONST_COOKIE.REFRESH_TOKEN);
        if (!refreshToken || refreshToken === "") {
          dispatch(userClear());
        } else {
          const result = await reqRefreshAccessToken({ refreshToken });
          dispatch(tokenSave(result.data));
          dispatch(userSave(result.data));
        }
      }

      if (!admin.accessToken) {
        const adminRefreshToken = getCookie(CONST_COOKIE.ADMIN_REFRESH);
        if (!adminRefreshToken || adminRefreshToken === "") {
          dispatch(adminClear());
        } else {
          const result = await reqAdminRefreshAccessToken({
            refreshToken: adminRefreshToken,
          });
          dispatch(adminSave(result.data));
        }
      }
    };
    srf();
  }, [user.accessToken, admin.accessToken]);

  return (
    <>
      <Outlet />
    </>
  );
}
