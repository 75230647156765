import { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import { ADMIN_BADGE, CODE_COUNTRY_ITEMS } from "../../../common/constants";
import { reqReadAdminBadge } from "../../../requests/badge";
import {
  reqGetAllUserById,
  reqGetEveryUserServiceLog,
  reqUpdateUserId,
} from "../../../requests/user";
import AdminHeadTitle from "../../../components/AdminHeadTitle";

interface User {
  id: string;
  name: string;
  country: number;
  oldid: string;
  saveid: string;
  email: string;
  point: number;
  latestWarehouseId: string;
  membershipId: number;
  Check: boolean;
  international: number;
  phone: number;
}

export default function ACustomrSpace() {
  const [users, setUsers] = useState<User[]>([]);
  const [shipLog, setShipLog] = useState<any>({});
  const [shopLog, setShopLog] = useState<any>({});
  const [showModal, setShowModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [newUserId, setNewUserId] = useState("");

  useEffect(() => {
    fetchAllUsers();
    const badgePayload = {
      badgeName: ADMIN_BADGE.SPACE,
    };
    reqReadAdminBadge(badgePayload);
  }, []);

  const fetchAllUsers = async () => {
    try {
      const response = await reqGetAllUserById();
      setUsers(response.data);

      const serviceLogs = await reqGetEveryUserServiceLog();
      setShipLog(serviceLogs.data.shipLog);
      setShopLog(serviceLogs.data.shopLog);
    } catch (error) {
      console.error("전체 사용자 정보 조회 실패:", error);
    }
  };

  const handleRowClick = (user: User) => {
    setSelectedUser(user);
    setNewUserId(user.id);
    setShowModal(true);
  };

  const handleUpdateUserId = async () => {
    if (!selectedUser) return;

    Swal.fire({
      title: "스페이스 코드를 변경하시겠습니까?",
      text: "사용자 ID를 변경합니다.",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "승인",
      cancelButtonText: "취소",
      reverseButtons: true,
    }).then(async (result: any) => {
      if (result.isConfirmed) {
        try {
          const payload = {
            oldId: selectedUser.id,
            newId: newUserId,
          };
          await reqUpdateUserId(payload);

          setUsers(
            users.map((user) => {
              if (user.id === selectedUser.id) {
                return {
                  ...user,
                  id: newUserId,
                };
              }
              return user;
            })
          );

          Swal.fire("ID가 성공적으로 변경되었습니다.", "", "success");
          window.location.reload();
        } catch (error) {
          console.error("Failed to update user ID:", error);
          Swal.fire("ID 변경 실패", "오류가 발생했습니다.", "error");
        }
      }
    });
  };

  const filteredUsers = searchKeyword.trim()
    ? users.filter((user) => user.id === searchKeyword && user.Check)
    : users.filter((user) => user.Check);

  // 띄어쓰기
  const handleKeyPress = (event: any) => {
    if (event.key === " ") {
      event.preventDefault();
    }
  };

  return (
    <main id="main" className="main">
      <div>
        <div className="pagetitle">
          <AdminHeadTitle
            subtitle1="최고 관리자"
            subtitle2="스페이스 코드 변경"
            title="스페이스 코드 변경"
          />

          <div className="filter-bar">
            <Form>
              <Form.Group controlId="searchKeyword">
                <Form.Control
                  type="text"
                  placeholder="스페이스 코드로 검색"
                  onKeyPress={handleKeyPress}
                  value={searchKeyword.toUpperCase()}
                  onChange={(e) => setSearchKeyword(e.target.value)}
                />
              </Form.Group>
            </Form>
          </div>
        </div>
        <div className="container-fluid">
          <table className="table text-center">
            <thead>
              <tr>
                <th>이름</th>
                <th>스페이스코드</th>
                <th>요청 스페이스코드</th>
                <th>스페이스코드(JK)</th>
                <th>국가</th>
                <th>이메일</th>
                <th>국제코드</th>
                <th>폰번호</th>
              </tr>
            </thead>
            <tbody>
              {shipLog &&
                shopLog &&
                filteredUsers.map((user, index) => (
                  <tr key={index} onClick={() => handleRowClick(user)}>
                    <td>{user.name}</td>
                    <td>{user.id}</td>
                    <td>{user.oldid || "미요청"}</td>
                    <td>{user.saveid || "없음"}</td>
                    <td>{CODE_COUNTRY_ITEMS[user.country] || "Unknown"}</td>
                    <td>{user.email}</td>
                    <td>{user.international}</td>
                    <td>{user.phone}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>멤버십 변경 및 포인트 부여</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="requestedUserId">
              <Form.Label>요청 스페이스 코드</Form.Label>
              <Form.Control
                type="text"
                value={selectedUser ? selectedUser.oldid : ""}
                readOnly
              />
            </Form.Group>
            <Form.Group controlId="newUserId">
              <Form.Label>변경할 스페이스 코드</Form.Label>
              <Form.Control
                type="text"
                onKeyPress={handleKeyPress}
                value={newUserId.toUpperCase()}
                onChange={(e) => setNewUserId(e.target.value.trim())}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            닫기
          </Button>
          <Button variant="info" onClick={() => handleUpdateUserId()}>
            스페이스코드 변경
          </Button>
        </Modal.Footer>
      </Modal>
    </main>
  );
}
