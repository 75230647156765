import styled from "styled-components";
import YouTube from "react-youtube";
import { isMobile } from "react-device-detect";

const Service = () => {
  return (
    <LandingWrapper>
      <div id="landing-page">
        <div className="service_part_1 pad_t_150 pad_b_150">
          <div className="container_wrap po_rel">
            <div className="video_part after po_rel mar_b_100">
              <div
                className={`${
                  isMobile ? "mobile_text_box" : "text_box float_l"
                }`}
              >
                <img src="/landing/images/service_11.png" alt="" />
                <h2>WAREHOUSE SERVICE</h2>
                <p>
                  Our warehouse service offers a free Korean address and space
                  code, providing you with the utmost convenience and peace of
                  mind on your shopping journey. Experience the freedom to
                  purchase Korean products that perfectly match your heart's
                  desire, opening up a world of exciting possibilities.
                </p>
              </div>
              <div className="video_wrap">
                <div className="video">
                  <YouTube
                    className="youtube-video"
                    videoId="6hTT2gkc9rA" //동영상 주소
                    opts={{
                      width: "100%",
                      playerVars: {
                        autoplay: 0, //자동 재생 여부
                        modestbranding: 1, //컨트롤 바에 유튜브 로고 표시 여부
                        loop: 0, //반복 재생
                      },
                    }}
                    onReady={(e) => {
                      e.target.mute(); //소리 끔
                    }}
                  />
                </div>
              </div>
            </div>
            <ul>
              <li className="after" data-aos="fade-up">
                <img
                  className="float_l"
                  src="/landing/images/service_3.png"
                  alt=""
                />
                <div className="text_box float_l">
                  <h4>Use your space code to shop right away</h4>
                  <p>
                    Create an account on our website.
                    <br />
                    Direct access to your free Korean address and space code
                    through your dashboard. Complete a purchase on any Korean
                    site.
                  </p>
                </div>
              </li>
              <li className="after" data-aos="fade-up">
                <img
                  className="float_l"
                  src="/landing/images/service_4.png"
                  alt=""
                />
                <div className="text_box float_l">
                  <h4>Manage your package arrival</h4>
                  <p>
                    Get notified when a package reaches your space.
                    <br />
                    Get access to your package picture directly on your page.
                    <br />
                    Request content checking with one click.
                  </p>
                </div>
              </li>
              <li className="after" data-aos="fade-up">
                <img
                  className="float_l"
                  src="/landing/images/service_5.png"
                  alt=""
                />
                <div className="text_box float_l">
                  <h4>Enter item description data easily</h4>
                  <p>
                    Enter the package content data directly on your dashboard.
                    <br />
                    Use the designed category dropdown menu.
                  </p>
                </div>
              </li>
              <li className="after" data-aos="fade-up">
                <img
                  className="float_l"
                  src="/landing/images/service_6.png"
                  alt=""
                />
                <div className="text_box float_l">
                  <h4>30 days of free storage for every new package</h4>
                  <p>
                    Get 30 days of free storage per package.
                    <br />
                    After 30 days you get charged $1 per day and per package.
                    <br />3 months or 1 year membership option for more frequent
                    buyers.
                  </p>
                </div>
              </li>
            </ul>
            <a href="" className="link_btn">
              Learn more
            </a>
          </div>
        </div>

        <div className="service_part_2 pad_t_150 pad_b_150">
          <div className="container_wrap po_rel">
            <div className="video_part after po_rel mar_b_100">
              <div
                className={`${
                  isMobile ? "mobile_text_box" : "text_box float_r"
                }`}
              >
                <img src="/landing/images/service_1.png" alt="" />
                <h2>CUSTOM ORDER SERVICE</h2>
                <p>
                  Our custom order service is made to overcome any difficulty
                  you can meet while shopping.
                  <br />
                  With our designed purchase form, you can quickly request
                  purchase assistance from our team of experts and manage your
                  orders.
                </p>
              </div>
              <div className="video_wrap">
                <div className="video">
                  <YouTube
                    className="youtube-video"
                    videoId="kE6kFMsdoHA" //동영상 주소
                    opts={{
                      width: "100%",
                      playerVars: {
                        autoplay: 0, //자동 재생 여부
                        modestbranding: 1, //컨트롤 바에 유튜브 로고 표시 여부
                        loop: 0, //반복 재생
                      },
                    }}
                    onReady={(e) => {
                      e.target.mute(); //소리 끔
                    }}
                  />
                </div>
              </div>
            </div>
            <ul className="after">
              <li data-aos="fade-up">
                <h3>Intuitive purchase form</h3>
                <span></span>
                <p>· Create an account on our website</p>
                <p>
                  · Create a custom order request in one click from your
                  dashboard
                </p>
                <p>· Easily cancel and rewrite ordersOrder payment check out</p>
              </li>
              <li data-aos="fade-up" data-aos-delay="100">
                <h3>Order payment check out</h3>
                <span></span>
                <p>· Make payment for your request fast</p>
                <p>
                  · Access to different kind of payment method both in KRW and
                  USD
                </p>
                <p>
                  · In case of soldout item get refunded with point usuable in
                  your next payment
                </p>
              </li>
              <li data-aos="fade-up" data-aos-delay="200">
                <h3>Manage product arrival</h3>
                <span></span>
                <p>· Get notified when order is ready</p>
                <p>· Access to content picture at arrival</p>
                <p>
                  · Request shipping for each items seperately or all together
                </p>
              </li>
              <li data-aos="fade-up" data-aos-delay="300">
                <h3>Bulk orders Discount</h3>
                <span></span>
                <p>
                  · Comission range from 10% to 5% according to total price of
                  your request
                </p>
                <p>· Get 30 days free storage for each of your order</p>
              </li>
            </ul>
            <a href="" className="link_btn">
              Learn more
            </a>
          </div>
        </div>

        <div className="service_part_3 pad_t_150 pad_b_150">
          <div className="container_wrap po_rel">
            <div className="video_part after po_rel mar_b_100">
              <div
                className={`${
                  isMobile ? "mobile_text_box" : "text_box float_l"
                }`}
              >
                <img src="/landing/images/service_12.png" alt="" />
                <h2>Shipping service</h2>
                <p>
                  Our expert shipping service is designed to consolidate
                  multiple packages into one large box, ensuring cost-efficiency
                  for your orders. Each item is meticulously packed using bubble
                  wrap, and our boxes are securely consolidated with tape to
                  prevent any damage during transit. We strive to optimize your
                  shipping experience and deliver your items safely to your
                  doorstep, giving you the confidence and security you deserve.
                </p>
              </div>
              <div className="video_wrap">
                <div className="video">
                  <YouTube
                    className="youtube-video"
                    videoId="ToP38QustZk" //동영상 주소
                    opts={{
                      width: "100%",
                      playerVars: {
                        autoplay: 0, //자동 재생 여부
                        modestbranding: 1, //컨트롤 바에 유튜브 로고 표시 여부
                        loop: 0, //반복 재생
                      },
                    }}
                    onReady={(e) => {
                      e.target.mute(); //소리 끔
                    }}
                  />
                </div>
              </div>
            </div>

            <ul style={isMobile ? { paddingLeft: "0" } : {}}>
              <li className="after" data-aos="fade-up">
                <img
                  className="float_l img"
                  src="/landing/images/img_7.png"
                  alt=""
                />
                <div
                  className={
                    isMobile ? "mobile_text_box float_l" : "text_box float_l"
                  }
                >
                  <span>Point 1</span>
                  <h4>One-click shipping request</h4>
                  <p>
                    Request all your space to be packed in one-click.
                    <br />
                    Save several address in your profile to make shipping
                    request faster.
                  </p>
                </div>
              </li>
              <li className="after" data-aos="fade-up">
                <img
                  className="float_l img"
                  src="/landing/images/img_8.png"
                  alt=""
                />
                <div
                  className={
                    isMobile ? "mobile_text_box float_l" : "text_box float_l"
                  }
                >
                  <span>Point 2</span>
                  <h4>Tailored shipping invoice</h4>
                  <p>
                    Pay for shipping price and repack according to final box
                    kilogram.
                  </p>
                  <a href="" className="more">
                    Learn more about shipping prices
                  </a>
                </div>
              </li>
              <li className="after" data-aos="fade-up">
                <img
                  className="float_l img"
                  src="/landing/images/img_9.png"
                  alt=""
                />
                <div
                  className={
                    isMobile ? "mobile_text_box float_l" : "text_box float_l"
                  }
                >
                  <span>Point 3</span>
                  <h4>Easy checkout</h4>
                  <p>
                    Multiple payment options for your convenience in USD and
                    KRW.
                  </p>
                </div>
              </li>
              <li className="after" data-aos="fade-up">
                <img
                  className="float_l img"
                  src="/landing/images/img_10.png"
                  alt=""
                />
                <div
                  className={
                    isMobile ? "mobile_text_box float_l" : "text_box float_l"
                  }
                >
                  <span>Point 4</span>
                  <h4>Free package arrival picture</h4>
                  <p>
                    Experience peace of mind with our free package arrival
                    picture feature.
                    <br />
                    Receive a photo for every package so you can see its
                    condition without worry.
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div className="join_part pad_t_150 pad_b_150">
          <div className="container_wrap">
            <div className="content">
              <h3>Join today get $7 coupon!</h3>
              <p>Join the Ontact Korea family today and save $7 on shipping</p>
              <a href="" className="join_btn" data-aos="fade-up">
                Sign up now
              </a>
            </div>
          </div>
        </div>

        {/* Footer */}
        <footer>
          <div className="container_wrap after">
            <div className="part">
              <h3>Ontact Korea</h3>
              <p>
                CEO : SON JU KYUNG &nbsp;|&nbsp; Business Number : 420-71-00335
                <br />
                1F, 36, Jwasuyeong-ro 210beon-gil, Suyeong-gu, Busan, Republic
                of Korea, 48213
              </p>
            </div>
            <div className="part">
              <h3>Contact Us</h3>
              <p>
                Tel : 051-751-9020
                <br />
                Email : ontactkorea@gmail.com
                <br />
                CS : +82 10-2716-9020
                <br />
                Mon-Fri : 10AM ~ 18PM (excluded weekend, holiday)
              </p>
            </div>
          </div>
        </footer>

        {/* Scroller */}
        <a
          href="#"
          className="scroll-top d-flex align-items-center justify-content-center"
        >
          <i className="bi bi-arrow-up-short" />
        </a>
      </div>
    </LandingWrapper>
  );
};

const LandingWrapper = styled.div`
  margin-top: 80px;
  #landing-page > div {
    display: flex;
    justify-content: center;
  }
`;

export default Service;
