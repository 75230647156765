import { format } from "date-fns";
import {
  CODE_SHIPPING_ORDERS_STATUS,
  CODE_SHIPPING_PRODUCT_STATUS,
  CODE_SHOPS_STATUS,
  DATA_TYPE,
  DATE_FORMAT,
} from "../common/constants";

export default function FormatData(dataType: number, data: any) {
  let color = "white";
  let text = "";
  switch (dataType) {
    case DATA_TYPE.DATETIME:
      return <>{format(new Date(data), DATE_FORMAT.DEFAULT)}</>;
    case DATA_TYPE.DATE:
      return <>{format(new Date(data), DATE_FORMAT.ONLY_DATE)}</>;
    case DATA_TYPE.CODE_SHIPPING_PRODUCT_STATUS: {
      if (data === CODE_SHIPPING_PRODUCT_STATUS.ENTER_DATA) {
        color = "var(--color-main-blue)";
        text = "Enter data";
      } else if (data === CODE_SHIPPING_PRODUCT_STATUS.SHIPPING_AVAILABLE) {
        color = "var(--color-main-blue)";
        text = "Ready to ship";
      } else if (data === CODE_SHIPPING_PRODUCT_STATUS.ORDER_SENT) {
        color = "var(--bs-success)";
        text = "Packing";
      } else if (data === CODE_SHIPPING_PRODUCT_STATUS.CHECK_CONTENT) {
        color = "var(--bs-gray)";
        text = "Checked items";
      } else if (data === CODE_SHIPPING_PRODUCT_STATUS.SPLIT_ITEMS) {
        color = "var(--bs-gray)";
        text = "Split items";
      } else if (data === CODE_SHIPPING_PRODUCT_STATUS.SHIPPED) {
        color = "var(--bs-success)";
        text = "Shipped";
        // } else if (data === CODE_SHIPPING_PRODUCT_STATUS.CANCELED) {
        //   color = "var(--bs-gray)";
        //   text = "Canceled";
        // } else if (data === CODE_SHIPPING_PRODUCT_STATUS.EXPIRED) {
        //   color = "var(--color-main-red)";
        //   text = "Expired";
      } else if (data === CODE_SHIPPING_PRODUCT_STATUS.DISCARDED) {
        color = "var(--color-main-red)";
        text = "Discarded";
      } else {
        return <span>undefined</span>;
      }

      return (
        <span>
          <div
            style={{
              display: "inline-block",
              width: "10px",
              height: "10px",
              borderRadius: "10px",
              backgroundColor: color,
              marginRight: "5px",
            }}
          ></div>
          {text}
        </span>
      );
    }
    case DATA_TYPE.CODE_SHIPPING_ORDERS_STATUS: {
      if (data === CODE_SHIPPING_ORDERS_STATUS.REQUEST_SENT) {
        color = "var(--bs-primary)";
        text = "Request sent";
      } else if (data === CODE_SHIPPING_ORDERS_STATUS.PACKING) {
        color = "var(--bs-warning)";
        text = "Packing";
      } else if (data === CODE_SHIPPING_ORDERS_STATUS.FEE_CALCULATION) {
        color = "var(--bs-warning)";
        text = "Packing";
      } else if (data === CODE_SHIPPING_ORDERS_STATUS.PENDING_PAYMENT) {
        color = "var(--bs-danger)";
        text = "Pending payment";
      } else if (data === CODE_SHIPPING_ORDERS_STATUS.CONFIRMING_DEPOSIT) {
        color = "var(--bs-success)";
        text = "Confirming deposit";
      } else if (data === CODE_SHIPPING_ORDERS_STATUS.PREPARING_FOR_SHIPMENT) {
        color = "var(--bs-primary)";
        text = "Preparing for shipment";
      } else if (data === CODE_SHIPPING_ORDERS_STATUS.SHIPPED) {
        color = "var(--bs-primary)";
        text = "Shipped";
      } else if (data === CODE_SHIPPING_ORDERS_STATUS.SHIPPEDE) {
        color = "var(--bs-primary)";
        text = "Shipped";
      } else if (data === CODE_SHIPPING_ORDERS_STATUS.DELIVERY_COMPLETED) {
        color = "var(--bs-success)";
        text = "Delivery completed";
        // } else if (data === CODE_SHIPPING_ORDERS_STATUS.REVIEW_COMPLETED) {
        //   color = "var(--bs-success)";
        //   text = "Review completed";
      } else if (data === CODE_SHIPPING_ORDERS_STATUS.DISCARDED) {
        color = "var(--bs-danger)";
        text = "Discarded";
      } else {
        return <span>undefined</span>;
      }

      return (
        <span>
          <div
            style={{
              display: "inline-block",
              width: "10px",
              height: "10px",
              borderRadius: "10px",
              backgroundColor: color,
              marginRight: "5px",
            }}
          ></div>
          {text}
        </span>
      );
    }
    case DATA_TYPE.CODE_SHOPS_STATUS: {
      if (data === CODE_SHOPS_STATUS.REQUEST_SENT) {
        color = "var(--bs-primary)";
        text = "Request sent";
      } else if (data === CODE_SHOPS_STATUS.PENDING_PAYMENT) {
        color = "var(--bs-warning)";
        text = "Pending payment";
      } else if (data === CODE_SHOPS_STATUS.CONFIRMING_DEPOSIT) {
        color = "var(--bs-warning)";
        text = "Confirming deposit";
      } else if (data === CODE_SHOPS_STATUS.PENDING_COMPLETED) {
        color = "var(--bs-success)";
        text = "Payment completed";
      } else if (data === CODE_SHOPS_STATUS.ORDER_COMPLETED) {
        color = "var(--bs-success)";
        text = "Order completed";
      } else if (data === CODE_SHOPS_STATUS.INCONMINT_COMPLETED) {
        color = "var(--bs-success)";
        text = "Order completed";
      } else if (data === CODE_SHOPS_STATUS.CANCELED) {
        color = "var(--bs-danger)";
        text = "Cancelled";
      } else if (data === CODE_SHOPS_STATUS.PROCESSING) {
        color = "var(--bs-danger)";
        text = "Processing";
      } else {
        return <span>undefined</span>;
      }

      return (
        <span>
          <div
            style={{
              display: "inline-block",
              width: "10px",
              height: "10px",
              borderRadius: "10px",
              backgroundColor: color,
              marginRight: "5px",
            }}
          ></div>
          {text}
        </span>
      );
    }
    case DATA_TYPE.AUTH_ROLE: {
      if (data === 0) {
        return <>일반 관리자</>;
      } else if (data === 1) {
        return <>최고 관리자</>;
      } else if (data === 2) {
        return <>비활성화</>;
      } else if (data === 3) {
        return <>중간 관리자</>;
      }
    }
  }
}
