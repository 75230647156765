import { configureStore } from "@reduxjs/toolkit";
import adminReducer from "./adminSlice";
import codeReducer from "./codeSlice";
import loadingReducer from "./loadingSlice";
import modalReducer from "./modalSlice";
import popupImageReducer from "./popupImageReducer";
import tableReducer from "./tableSlice";
import tokenSlice from "./tokenSlice";
import userReducer from "./userSlice";

const persistedState = loadFromLocalStorage();

export const store = configureStore({
  reducer: {
    admin: adminReducer,
    code: codeReducer,
    loading: loadingReducer,
    modal: modalReducer,
    table: tableReducer,
    token: tokenSlice,
    user: userReducer,
    popupImage: popupImageReducer,
  },
  preloadedState: persistedState,
});

store.subscribe(() => {
  saveToLocalStorage(store.getState());
});
interface PopupImageState {
  imageUrl: string | null;
}
export interface RootState {
  admin: {
    id: number;
    name: string;
    role: number;
    accessToken: string;
  };
  popupImage: PopupImageState;
}

export interface RootUserState {
  user: {
    id: string;
    name: string;
    membershipId: number;
    email: string;
    Check: boolean;
  };
}

function loadFromLocalStorage() {
  try {
    const serializedState = localStorage.getItem("state");
    if (serializedState === null) return undefined;
    return JSON.parse(serializedState);
  } catch (e) {
    console.warn("로컬 스토리지에서 상태를 로드할 수 없습니다.", e);
    return undefined;
  }
}

function saveToLocalStorage(state: RootState) {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("state", serializedState);
  } catch (e) {
    console.warn("로컬 스토리지에 상태를 저장할 수 없습니다.", e);
  }
}

export type RootStates = ReturnType<typeof store.getState>;
