import React, { useEffect } from "react";
import styled from "styled-components";
import { reqPlusViewCount } from "../requests/user";
import { isMobile } from "react-device-detect";
import { MButton } from "../common/mobile-styled";

const Notice = ({ notice, tmpImage, setViewNotice }: any) => {
  useEffect(() => {
    async function plusViewCount() {
      const isAdmin = notice.view == undefined;
      if (isAdmin) {
        return;
      }
      await reqPlusViewCount({ id: notice.id });
    }
    plusViewCount();
  });

  const MobileView = () => {
    return (
      <>
        <MobileWrapper>
          <Col>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Label>Title:</Label>
              <P>{notice.title}</P>
            </div>

            <div style={{ display: "flex", flexDirection: "row" }}>
              <Label>Subtitle:</Label>
              <P>{notice.subtitle}</P>
            </div>

            <div style={{ display: "flex", flexDirection: "row" }}>
              <Label>Category:</Label>
              <P>{notice.category}</P>
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Label>Updated At:</Label>
              <P>
                {new Date(notice.updatedAt).toLocaleDateString("ko-KR", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                  hour: "2-digit",
                  minute: "2-digit",
                  hourCycle: "h23",
                })}
              </P>
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Label>View:</Label>
              <P>{notice.view + 1 || "0"}</P>
            </div>
          </Col>
          <NoticeTitle>
            {notice.title}
            <div style={{ marginTop: "1rem" }}>{notice.subTitle}</div>
          </NoticeTitle>
          <div style={{ marginTop: "1.4rem" }}>
            {tmpImage ? (
              <img src={tmpImage} alt="notice" />
            ) : (
              <>
                {notice.imageUrl && (
                  <img
                    src={`https://kr.object.ncloudstorage.com/ontact-bucket/${notice.imageUrl}`}
                    alt="notice"
                    style={{
                      width: "-webkit-fill-available",
                      marginTop: "0.6rem",
                      marginBottom: "1.6rem",
                    }}
                  />
                )}
              </>
            )}
          </div>
          <div>
            <div dangerouslySetInnerHTML={{ __html: notice.content }}></div>
          </div>
        </MobileWrapper>
      </>
    );
  };
  return (
    <>
      {isMobile ? (
        <MobileView />
      ) : (
        <NoticeWrapper>
          <NoticeHeader>
            <div>
              <label>Category:</label>
              <p>{notice.category}</p>
            </div>
            <div>
              <label>Updated At:</label>
              <p>
                {new Date(notice.updatedAt).toLocaleDateString("ko-KR", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                  hour: "2-digit",
                  minute: "2-digit",
                  hourCycle: "h23",
                })}
              </p>
            </div>
            <div>
              <label>View:</label>
              <p>{notice.view + 1 || "0"}</p>
            </div>
          </NoticeHeader>
          <NoticeTitle>
            {notice.title}
            <div style={{ marginTop: "1rem" }}>{notice.subTitle}</div>
          </NoticeTitle>
          <NoticeContents>
            <div>
              {tmpImage ? (
                <img src={tmpImage} alt="notice" />
              ) : (
                <>
                  {notice.imageUrl && (
                    <img
                      src={`https://kr.object.ncloudstorage.com/ontact-bucket/${notice.imageUrl}`}
                      alt="notice"
                    />
                  )}
                </>
              )}
            </div>
            <div>
              <div dangerouslySetInnerHTML={{ __html: notice.content }}></div>
            </div>
          </NoticeContents>
        </NoticeWrapper>
      )}
    </>
  );
};

const MobileWrapper = styled.div`
  margin: 0;
`;

const MBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
  align-items: center;
`;
const Col = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  font-weight: bold;
  margin: 0.4rem 0.6rem 0.4rem 0;
`;
const P = styled.p`
  margin: 0.4rem 0 0.4rem 0;
`;

const NoticeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2rem 0rem;
  padding: 0 2rem 0 0;
  width: 100%;
`;
const NoticeHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  border-bottom: 2px solid #dee2e6;
  & > div {
    display: flex;
    flex-direction: row;
    label {
      font-weight: bold;
      margin: 0 0.5rem 0 1rem;
    }
  }
`;
const NoticeTitle = styled.div`
  text-align: center;
  padding: 2rem 0;
  font-size: 1.3rem;
  background-color: #f8f9fa;
`;
const NoticeContents = styled.div`
  padding: 3rem 1.5rem;
  & > div > img {
    max-width: 30rem;
    max-height: 30rem;
    object-fit: scale-down;
    margin-bottom: 2rem;
  }
`;
const NoticeListBackButtonRow = styled.div`
  align-items: end;
  width: 100%;
  & > div {
    text-align: end;
    button {
      background-color: gray;
      color: white;
    }
  }
`;

export default Notice;
