import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Form, FormControl, Table } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import {
  CODE_COUNTRY_ITEMS,
  CODE_SHIPPING_ORDER_STATUS,
} from "../../common/constants";
import { modalClose, modalOpen } from "../../redux/modalSlice";
import {
  reqShipFEE,
  reqShipProductBox,
  reqShipUpdateOrders,
} from "../../requests/ship";
import { MODAL_TYPE } from "./GlobalModal";

interface ProductDetail {
  id: number;
  productId: number;
  category: number;
  name: string;
  quantity: number;
  price: number;
  trackingNumber: string;
}
interface Product {
  tracking: string;
  name: string;
  warehouse: string;
  price: number;
  Extra: number;
  overstoreFee: number;
  isUrgent: boolean;
  bag: boolean;
  details: ProductDetail[];
}

interface OrderDetails {
  optionLetter: boolean;
  optionPoster: boolean;
  optionAircap: boolean;
  optionSticker: boolean;
  optionBag: boolean;
  id: string;
  createdAt: string;
  estimatedAt?: string;
  expiredAt?: string;
  paidAt?: string;
  products: Product[];
  adrReceiver: string;
  adrEmail: string;
  adrPhone: string;
  adrCountry: string;
  adrPCCC?: string;
  adrCity: string;
  adrState: string;
  adrZip: string;
  adrOption1: string;
  adrOption2?: string;
  adrOption3?: string;
}

export default function ModalShipOrderDetail() {
  const dispatch = useDispatch();
  const modal = useSelector((state: any) => state.modal);
  const [exchangeRate, setExchangeRate] = useState<number>(0);
  const data = modal.payload.data;
  const orderDetails = modal.payload.data;
  const [selectedProducts, setSelectedProducts] = useState<number[]>([]);

  const toggleProductSelection = (index: number) => {
    setSelectedProducts((prevSelected) => {
      const currentIndex = prevSelected.indexOf(index);
      if (currentIndex > -1) {
        return prevSelected.filter((item) => item !== index);
      } else {
        console.log(data.products);
        return [...prevSelected, index];
      }
    });
  };

  // const fetchExchangeRate = () => {
  //   fetch(
  //     "https://quotation-api-cdn.dunamu.com/v1/forex/recent?codes=FRX.KRWUSD"
  //   )
  //     .then((response) => response.json())
  //     .then((data) => {
  //       const rate = data[0].basePrice;
  //       setExchangeRate(rate);
  //     })
  //     .catch((error) => console.error("Error fetching exchange rate:", error));
  // };

  const [fixedExchangeRate, setFixedExchangeRate] = useState(0);
  const fetchFixedExchangeRate = async () => {
    try {
      const response = await reqShipFEE();
      if (
        response &&
        response.data &&
        response.data[0]?.fixedExchangeRateShop
      ) {
        setFixedExchangeRate(
          parseFloat(response.data[0].fixedExchangeRateShop)
        );
      }
    } catch (error) {
      console.error("Error fetching fixed exchange rate:", error);
    }
  };

  useEffect(() => {
    // fetchExchangeRate();
    fetchFixedExchangeRate();
  }, []);

  const convertToKRW = (usd: number) => {
    return Math.floor(usd * 1000).toLocaleString();
  };

  const convertToUSD = (krw: number) => {
    return (krw / fixedExchangeRate).toFixed(2);
  };

  const handleCancelOrder = async () => {
    try {
      // Check if the order status is 1 before proceeding
      if (orderDetails.status !== 1) {
        dispatch(
          modalOpen({
            modalType: MODAL_TYPE.INFO,
            title: "Cancellation Error",
            payload: {
              text: "Order cannot be canceled because it is not in the correct status.",
            },
          })
        );
        return;
      }

      // Proceed with cancellation if status is 1
      await reqShipUpdateOrders({
        id: orderDetails.id,
        status: CODE_SHIPPING_ORDER_STATUS.CANCELED,
      });

      // Only proceed with product updates if the cancellation is successful
      const updatePromises = data.products.map((product: any) =>
        reqShipProductBox({
          tracking: product.tracking,
          status: CODE_SHIPPING_ORDER_STATUS.WAIT_PAY,
          bag: false,
          isUrgent: false,
        })
      );
      await Promise.all(updatePromises);

      dispatch(modalClose());
      dispatch(
        modalOpen({
          modalType: MODAL_TYPE.INFO,
          title: "Success",
          payload: {
            text: "Order has been canceled and all products updated successfully.",
          },
        })
      );
      window.location.reload();
    } catch (error) {
      console.error("Error updating order status:", error);

      // Type guard for AxiosError or similar
      if (axios.isAxiosError(error)) {
        const { response } = error;
        if (response) {
          const { status, data } = response;
          if (status === 500) {
            dispatch(
              modalOpen({
                modalType: MODAL_TYPE.INFO,
                title: "Cancellation Error",
                payload: {
                  text: "This box is already being packed. Please refresh.",
                },
              })
            );
          } else {
            dispatch(
              modalOpen({
                modalType: MODAL_TYPE.INFO,
                title: "Cancellation Error",
                payload: {
                  text:
                    data.message ||
                    "An error occurred while canceling the order. Please try again later.",
                },
              })
            );
          }
        } else {
          // Handle cases where response is not available
          dispatch(
            modalOpen({
              modalType: MODAL_TYPE.INFO,
              title: "Cancellation Error",
              payload: {
                text: "An unknown error occurred. Please try again later.",
              },
            })
          );
        }
      } else {
        // Handle errors that are not AxiosErrors
        dispatch(
          modalOpen({
            modalType: MODAL_TYPE.INFO,
            title: "Cancellation Error",
            payload: {
              text: "An unknown error occurred. Please try again later.",
            },
          })
        );
      }
    }
  };

  return (
    <>
      {isMobile ? (
        <MobileWrapper>
          <div>
            <Title>Included Products</Title>
            {data.products
              .slice()
              .sort((a: any, b: any) => {
                const aValue = a.warehouse === "free" ? 0 : Number(a.warehouse);
                const bValue = b.warehouse === "free" ? 0 : Number(b.warehouse);
                return aValue < bValue ? 1 : -1;
              })
              .map((product: any, index: number) =>
                product.detail && product.detail.length > 0 ? (
                  product.detail.map((detail: any, dIndex: number) => (
                    <div
                      key={`product-${index}-detail-${dIndex}`}
                      style={{
                        borderBottom: "1px solid lightgray",
                        paddingBottom: "1rem",
                        marginBottom: "1rem",
                      }}
                    >
                      {dIndex === 0 && (
                        <>
                          <Label>Tracking No.</Label>
                          <P>{product.tracking}</P>
                        </>
                      )}

                      <Label>Name</Label>
                      <P>{detail.name}</P>

                      <Label>Price</Label>
                      <P>
                        ${detail.price} / ₩
                        {Math.round(detail.price * fixedExchangeRate)}
                      </P>
                      {dIndex === 0 && (
                        <>
                          <Label>Warehouse</Label>
                          <P>
                            ${product.overstoreFee} / ₩
                            {convertToKRW(product.overstoreFee)}
                          </P>

                          <Label>Extra Charge</Label>
                          <P>
                            ${convertToUSD(product.Extra)} / ₩{product.Extra}
                          </P>

                          <Label>Is Urgent</Label>
                          <P>{product.isUrgent ? "Yes" : "No"}</P>

                          <Label>Bag</Label>
                          <P>{product.bag ? "Yes" : "No"}</P>
                        </>
                      )}
                    </div>
                  ))
                ) : (
                  <tr key={`product-${index}`}>
                    <td>{product.tracking}</td>
                    <td>{product.name || "N/A"}</td>
                    <td>
                      ₩{product.price} / $
                      {(product.price / fixedExchangeRate).toFixed(2)}
                    </td>
                    <td>
                      ${product.overstoreFee} / ₩
                      {convertToKRW(product.overstoreFee)}
                    </td>
                    <td>
                      ${convertToUSD(product.Extra)} / ₩{product.Extra}
                    </td>
                    <td>{product.isUrgent ? "Yes" : "No"}</td>
                    <td>{product.bag ? "Yes" : "No"}</td>
                  </tr>
                )
              )}
          </div>

          <div>
            <Title>Shopping Options</Title>
            <div>
              {/* 옵션 섹션 */}
              <Label style={{ marginTop: "1rem", marginBottom: "1rem" }}>
                - Options
              </Label>
              <div>
                <Form.Check
                  label="편지봉투 박스에 모아서 포장"
                  checked={data.optionLetter}
                  disabled
                />
                <Form.Check
                  label="포스터 튜브 합쳐서 포장"
                  checked={data.optionPoster}
                  disabled
                />
                <Form.Check
                  label="에어캡 추가"
                  checked={data.optionAircap}
                  disabled
                />
                <Form.Check
                  label="깨지기쉬움 스티커 4면 부착"
                  checked={data.optionSticker}
                  disabled
                />
                <Form.Check
                  label="보험가입"
                  checked={data.optionBag}
                  disabled
                />
              </div>
              {/* 배송 옵션 섹션 */}
              <Label style={{ marginTop: "1rem", marginBottom: "1rem" }}>
                - Options
              </Label>
              <div>
                <div>
                  <div>
                    <Label>Receiver</Label>
                    <FormControl disabled value={data.adrReceiver} />
                  </div>
                  <div>
                    <Label>Email</Label>
                    <FormControl disabled value={data.adrEmail} />
                  </div>
                  <div>
                    <Label>Phone Number</Label>
                    <FormControl disabled value={data.adrPhone} />
                  </div>
                  <div>
                    <Label>Country</Label>
                    <FormControl
                      disabled
                      value={
                        CODE_COUNTRY_ITEMS[Number(data.adrCountry)] ||
                        "Country not selected"
                      }
                    />
                  </div>
                  <div>
                    <Label>Personal Clearance Code</Label>
                    <FormControl disabled value={data.adrPCCC} />
                  </div>
                  <div>
                    <Label>City</Label>
                    <FormControl disabled value={data.adrCity} />
                  </div>
                  <div>
                    <Label>State</Label>
                    <FormControl disabled value={data.adrState} />
                  </div>
                  <div>
                    <Label>Zip Code</Label>
                    <FormControl disabled value={data.adrZip} />
                  </div>
                  <div>
                    <Label>Address 1</Label>
                    <FormControl disabled value={data.adrOption1} />
                  </div>
                  <div>
                    <Label>Address 2</Label>
                    <FormControl
                      disabled
                      value={data.adrOption2 ? data.adrOption2 : ""}
                    />
                  </div>
                  <div>
                    <Label>Address 3</Label>
                    <FormControl
                      disabled
                      value={data.adrOption3 ? data.adrOption3 : ""}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            {orderDetails.status ===
              CODE_SHIPPING_ORDER_STATUS.ORDER_CREATED && (
              <div className="d-flex justify-content-end gap-2 mt-3">
                {/* <Button
                  variant="success"
                  style={{ width: "200px" }}
                  onClick={async () => {
                    try {
                      await reqShipUpdateOrder({ id: orderDetails.id, status: CODE_SHIPPING_ORDER_STATUS.CANCELED });
                      await reqShipProductBox({ tracking: data.product.tracking, status: CODE_SHIPPING_ORDER_STATUS.WAIT_PAY });
                      dispatch(modalClose());
                      dispatch(modalOpen({
                        modalType: MODAL_TYPE.INFO,
                        title: "Success",
                        payload: { text: "Order has been canceled successfully." }
                      }));
                    } catch (error) {
                      console.error("Error updating order status:", error);
                    }
                  }}
                >
                  CANCELED
                </Button> */}
                <Button
                  variant="success"
                  style={{ width: "100%" }}
                  onClick={handleCancelOrder}
                >
                  CANCEL
                </Button>
              </div>
            )}
          </div>
        </MobileWrapper>
      ) : (
        <>
          <div
            style={{
              marginTop: "20px",
              display: "flex",
              flexDirection: "column",
              gap: "20px",
            }}
          >
            <Inner>
              <h5>Included Products</h5>
              <Table striped hover>
                <thead>
                  <tr>
                    <th>Tracking No.</th>
                    <th>Name</th>
                    <th>Price</th>
                    <th>Warehouse</th>
                    <th>Extra Charge</th>
                    <th>Is Urgent</th>
                    <th>Bag</th>
                  </tr>
                </thead>
                <tbody>
                  {data.products
                    .slice()
                    .sort((a: any, b: any) => {
                      const aValue =
                        a.warehouse === "free" ? 0 : Number(a.warehouse);
                      const bValue =
                        b.warehouse === "free" ? 0 : Number(b.warehouse);
                      return aValue < bValue ? 1 : -1;
                    })
                    .map((product: any, index: number) =>
                      product.detail && product.detail.length > 0 ? (
                        product.detail.map((detail: any, dIndex: number) => (
                          <tr key={`product-${index}-detail-${dIndex}`}>
                            {dIndex === 0 && (
                              <td rowSpan={product.detail.length}>
                                {product.tracking}
                              </td>
                            )}
                            <td>{detail.name}</td>
                            <td>
                              ${detail.price} / ₩
                              {Math.round(detail.price * fixedExchangeRate)}
                            </td>
                            {dIndex === 0 && (
                              <>
                                <td rowSpan={product.detail.length}>
                                  ${product.overstoreFee} / ₩
                                  {convertToKRW(product.overstoreFee)}
                                </td>
                                <td rowSpan={product.detail.length}>
                                  ${convertToUSD(product.Extra)} / ₩
                                  {product.Extra}
                                </td>
                                <td rowSpan={product.detail.length}>
                                  {product.isUrgent ? "Yes" : "No"}
                                </td>
                                <td rowSpan={product.detail.length}>
                                  {product.bag ? "Yes" : "No"}
                                </td>
                              </>
                            )}
                          </tr>
                        ))
                      ) : (
                        <tr key={`product-${index}`}>
                          <td>{product.tracking}</td>
                          <td>{product.name || "N/A"}</td>
                          <td>
                            ₩{product.price} / $
                            {(product.price / fixedExchangeRate).toFixed(2)}
                          </td>
                          <td>
                            ${product.overstoreFee} / ₩
                            {convertToKRW(product.overstoreFee)}
                          </td>
                          <td>
                            ${convertToUSD(product.Extra)} / ₩{product.Extra}
                          </td>
                          <td>{product.isUrgent ? "Yes" : "No"}</td>
                          <td>{product.bag ? "Yes" : "No"}</td>
                        </tr>
                      )
                    )}
                </tbody>
              </Table>
            </Inner>
            {/* 옵션 & 배송 옵션 */}
            <div style={{ display: "flex", gap: "20px" }}>
              {/* 옵션 섹션 */}
              <div style={{ flex: 1 }}>
                <h5 className="mb-3" style={{ fontWeight: "bold" }}>
                  Box Options
                </h5>
                <Form.Check
                  label="Collect all posters as minimum"
                  checked={data.optionLetter}
                  disabled
                />
                <Form.Check
                  label="Charge options"
                  checked={data.optionPoster}
                  disabled
                />
                <Form.Check
                  label="Add aircap"
                  checked={data.optionAircap}
                  disabled
                />
                <Form.Check
                  label="Attach fragile sticker on 4 side"
                  checked={data.optionSticker}
                  disabled
                />
                <Form.Check
                  label="package insurance"
                  checked={data.optionBag}
                  disabled
                />
              </div>
              {/* 배송 옵션 섹션 */}
              <Inner style={{ flex: 1 }}>
                <h5>Shipping Options</h5>
                <div
                  className="detail-container"
                  style={{ display: "flex", flexWrap: "wrap", gap: "20px" }}
                >
                  <div style={{ minWidth: "30%" }}>
                    <div className="font-large-order-detail">Receiver</div>
                    <FormControl disabled value={data.adrReceiver} />
                  </div>
                  <div style={{ minWidth: "30%" }}>
                    <div className="font-large-order-detail">Email</div>
                    <FormControl disabled value={data.adrEmail} />
                  </div>
                  <div style={{ minWidth: "30%" }}>
                    <div className="font-large-order-detail">Phone Number</div>
                    <FormControl disabled value={data.adrPhone} />
                  </div>
                  <div style={{ minWidth: "30%" }}>
                    <div className="font-large-order-detail">Country</div>
                    <FormControl
                      disabled
                      value={
                        CODE_COUNTRY_ITEMS[Number(data.adrCountry)] ||
                        "Country not selected"
                      }
                    />
                  </div>
                  <div style={{ minWidth: "30%" }}>
                    <div className="font-large-order-detail">
                      Personal Clearance Code
                    </div>
                    <FormControl disabled value={data.adrPCCC} />
                  </div>
                  <div style={{ minWidth: "30%" }}>
                    <div className="font-large-order-detail">City</div>
                    <FormControl disabled value={data.adrCity} />
                  </div>
                  <div style={{ minWidth: "30%" }}>
                    <div className="font-large-order-detail">State</div>
                    <FormControl disabled value={data.adrState} />
                  </div>
                  <div style={{ minWidth: "30%" }}>
                    <div className="font-large-order-detail">Zip Code</div>
                    <FormControl disabled value={data.adrZip} />
                  </div>
                  <div style={{ minWidth: "100%" }}>
                    <div className="font-large-order-detail">Address 1</div>
                    <FormControl
                      disabled
                      value={data.adrOption1}
                      style={{ width: "100%" }}
                    />
                  </div>
                  <div style={{ minWidth: "100%" }}>
                    <div className="font-large-order-detail">Address 2</div>
                    <FormControl
                      disabled
                      value={data.adrOption2 ? data.adrOption2 : ""}
                      style={{ width: "100%" }}
                    />
                  </div>
                  <div style={{ minWidth: "100%" }}>
                    <div className="font-large-order-detail">Address 3</div>
                    <FormControl
                      disabled
                      value={data.adrOption3 ? data.adrOption3 : ""}
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
              </Inner>
            </div>
          </div>
          {orderDetails.status === CODE_SHIPPING_ORDER_STATUS.ORDER_CREATED && (
            <div className="d-flex justify-content-end gap-2 mt-3">
              {/* <Button
            variant="success"
            style={{ width: "200px" }}
            onClick={async () => {
              try {
                await reqShipUpdateOrder({ id: orderDetails.id, status: CODE_SHIPPING_ORDER_STATUS.CANCELED });
                await reqShipProductBox({ tracking: data.product.tracking, status: CODE_SHIPPING_ORDER_STATUS.WAIT_PAY });
                dispatch(modalClose());
                dispatch(modalOpen({
                  modalType: MODAL_TYPE.INFO,
                  title: "Success",
                  payload: { text: "Order has been canceled successfully." }
                }));
              } catch (error) {
                console.error("Error updating order status:", error);
              }
            }}
          >
            CANCELED
          </Button> */}
              <Button
                variant="success"
                style={{ width: "200px" }}
                onClick={handleCancelOrder}
              >
                CANCEL
              </Button>
            </div>
          )}
        </>
      )}
    </>
  );
}

const Title = styled.h3`
  border-bottom: 1px solid lightgray;
  padding-bottom: 1rem;
  margin-top: 1rem;
`;
const FeeBoxRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  & > div {
    flex: 1 0 0;
    text-align: center;
    align-items: center;
    border: 1px solid lightgray;
    padding: 0.4rem 0;
  }
  margin: 0.6rem 0;
`;
const TotalFeeBoxRow = styled(FeeBoxRow)`
  background-color: #474747;
  color: white;
`;

const MobileWrapper = styled.div`
  margin: 0;
`;

const MBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.6rem 0;
  margin: 0.4rem 0;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;
const Col = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  font-weight: bold;
  margin: 0.4rem 0 0.4rem 0;
`;
const P = styled.p`
  margin: 0 0 0.4rem 0;
`;

const Inner = styled.div`
  overflow-y: auto;
  max-height: 60vh;

  .detail-container {
    overflow-y: auto;
    max-height: 60vh;
  }

  h5 {
    margin-bottom: 20px;
  }

  p,
  span {
    margin-bottom: 15px;
    display: block;
  }
`;
