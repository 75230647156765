import styled from "styled-components";
import Icon from "./Icon";
import { useEffect, useRef } from "react";

export default function ProfileImg(props: {
  src: string;
  width?: string;
  editable?: boolean;
  uploadImage?: any;
}) {
  useEffect(() => {
    console.log(props.src);
  }, []);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleEditClick = () => {
    fileInputRef.current?.click();
  };

  return (
    <>
      <Profile style={{ width: props.width, height: props.width }}>
        <div className="wraper">
          <img src={props.src} alt="profile" />
        </div>

        {props.editable && (
          <>
            <input
              type="file"
              ref={fileInputRef}
              onChange={props.uploadImage}
              style={{ display: "none" }}
              accept="image/*,image/heif,image/heic"
            />
            <div className="profile-edit" onClick={handleEditClick}>
              <Icon icon="pen" fontSize="20px" color="white" />
            </div>
          </>
        )}
      </Profile>
    </>
  );
}

const Profile = styled.div`
  position: relative;
  width: 200px;
  height: 200px;

  .wraper {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    overflow: hidden;
  }

  img {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .profile-edit {
    position: absolute;
    background-color: var(--color-main-blue);
    border-radius: 100%;
    width: 45px;
    height: 45px;
    right: 0px;
    bottom: 0px;
    text-align: center;
    line-height: 48px;
    transition-duration: 0.3s;
    cursor: pointer;

    &:hover {
      box-shadow: 0px 4px 10px #00000022;
    }
  }
`;
