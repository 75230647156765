import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { formatDate } from "../../common/format";
import { Blog } from "../../interface/Blog";

const LandingBlogModal = () => {
  const dispatch = useDispatch();
  const modal = useSelector((state: any) => state.modal);
  const blog: Blog = modal.payload.data;

  return (
    <BlogModalWrapper>
      <div className="blog-img">
        <img
          src={`https://kr.object.ncloudstorage.com/ontact-bucket/${blog.imageUrl}`}
          alt="blog-post"
        />
      </div>

      <div className="blog-title">{blog.title}</div>

      <div className="blog-writer">
        <div>{formatDate(blog.updatedAt)}</div>
        <div className="like float_r">
          <img src="/landing/images/icon_like.png" alt="" />
          <span>{blog.like}</span>
        </div>
      </div>

      <div className="blog-text">
        <p dangerouslySetInnerHTML={{ __html: blog.content }}></p>
      </div>
    </BlogModalWrapper>
  );
};

const BlogModalWrapper = styled.div`
  padding-top: 1rem;

  .blog-img img {
    width: 100%;
  }

  .blog-title {
    font-size: 20px;
    margin: 1rem 0;
    font-weight: bold;
    color: var(--color-main-blue);
  }

  .blog-writer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .blog-writer .like {
    display: flex;
    align-items: center;
  }
  .blog-writer .like img {
    width: 14px;
    margin-right: 10px;
  }
  .blog-writer.like span {
    font-size: 14px;
    line-height: 14px;
    color: #4b84da;
    font-weight: 600;
  }

  .blog-text {
    margin-top: 1rem;
  }
`;

export default LandingBlogModal;
