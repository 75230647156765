import React, { useEffect, useState } from "react";
import {
  ADMIN_BADGE,
  CODE_SHIPPING_PAYMENT_STATUS,
  CODE_SHOPING_ORDER_STATUS,
} from "../../../common/constants";
import { reqAShop } from "../../../requests/shop";
import WiseFancallModel from "../../../components/modal/WiseFancallModel";
import { reqReadAdminBadge } from "../../../requests/badge";
import AdminHeadTitle from "../../../components/AdminHeadTitle";
import { Pagination } from "react-bootstrap";
import styled from "styled-components";
import AdminHeadFilter from "../../../components/AdminHeadFilter";
import { formatDate } from '../../../common/format';
import { Table } from "react-bootstrap";
type OrderData = {
  orderId: string;
  userId: string;
  createdAt: string;
  PaymentMethod: number;
  preorder: boolean;
  fancall: boolean;
  status: number;
  url: string;
  paymentDeadline: string;
  totalPriceKRW: number;
  totalPriceUSD: number;
  paymentConfirmed: boolean;
  paymentAmountKRW: number;
  paymentAmountUSD: number;
  usePointKrw: number;
  usePointUse: number;
  productId: string;
};

const AShopOrder: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const [currentOrder, setCurrentOrder] = useState<OrderData | null>(null);
  const [orders, setOrders] = useState<OrderData[]>([]);

  const handleOrderClick = (order: OrderData) => {
    setCurrentOrder(order);
    setShowModal(true);
  };

  const refreshData = () => {
    fetchOrders();
  };

  useEffect(() => {
    fetchOrders();

    const badgePayload = {
      badgeName: ADMIN_BADGE.SHOP_PAYMENT_WAITING,
    };
    reqReadAdminBadge(badgePayload);
  }, []);

  const fetchOrders = async () => {
    try {
      const response = await reqAShop();
      const filteredOrders = response.data.filter(
        (order: OrderData) =>
          order.status === CODE_SHOPING_ORDER_STATUS.WAIT_PAY ||
          order.status === CODE_SHOPING_ORDER_STATUS.PAID
      );
      setOrders(filteredOrders);
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };

  const getPaymentMethod = (methodCode: number) => {
    return CODE_SHIPPING_PAYMENT_STATUS[methodCode] || "알 수 없음";
  };

  //! 필터 시작
  const [filteredData, setFilteredData] = useState<any[]>([]);

  const [searchWord, setSearchWord] = useState<string>("");
  const [searchOption, setSearchOption] = useState<string>("");

  const [selectedStatus, setSelectedStatus] = useState("");

  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    const thisFilteredData = orders
      .filter((order: any) =>
        order.orderId.toUpperCase().includes(searchWord.toUpperCase())
      )
      .sort((a: any, b: any) => {
        if (a.paymentConfirmed === b.paymentConfirmed) {
          return (
            new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
          );
        }
        return a.paymentConfirmed ? -1 : 1;
      });
    // const thisFilteredData = orders.filter(
    //   (order) =>
    //     order.orderId.toLowerCase().includes(searchWord.toLowerCase()) &&
    //     (selectedStatus ? order.status.toString() === selectedStatus : true)
    // );
    setFilteredData(thisFilteredData);
    setCurrentPage(1);
    setTotalPages(Math.ceil(thisFilteredData.length / itemsPerPage));
  }, [orders, searchWord, itemsPerPage, selectedStatus]);

  const handleItemsPerPageChange = (newItemsPerPage: number) => {
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1);
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const displayedOrders = filteredData.slice(
    startIndex,
    startIndex + itemsPerPage
  );
  //! 필터 끝

  return (
    <main id="main" className="main">
      <AdminHeadTitle
        subtitle1="구매대행"
        subtitle2="구매대행 결제 대기중"
        title="구매대행 결제 대기중"
      />

      {/* 옵션 필터 */}
      <AdminHeadFilter
        selectViewPage={(value: number) => handleItemsPerPageChange(value)}
        enterSearch={(value: string) => setSearchWord(value)}
        selectSearchOption={(value: string) => setSearchOption(value)}
      />

      <div className="table-responsive">
        <Table className="table-custom text-center">
          <thead className="table-light">
            <tr>
              <th>주문번호</th>
              <th>주문날자</th>
              <th>결제마감일</th>
              <th>담당자</th>
              <th>스페이스코드</th>
              <th>KRW</th>
              <th>사용포인트(KRW)</th>
              <th>USD</th>
              <th>사용포인트(USD)</th>
              <th>입금확인요청</th>
              <th>입금자명</th>
              <th>특이사항</th>
            </tr>
          </thead>
          <tbody>
            {displayedOrders.map((order) => (
              <tr
                key={order.orderId}
                onClick={() => handleOrderClick(order)}
                style={{ cursor: "pointer" }}
              >
                <th>{order.orderId}</th>
                <td>{formatDate(order.createdAt)}</td>
                <td>{formatDate(order.paymentDeadline)}</td>
                <td>{order.productId}</td>
                <td>{order.userId}</td>
                <td>₩ {order.paymentAmountKRW || 0}</td>
                <td>{order.usePointKrw || 0}</td>
                <td>$ {order.paymentAmountUSD || 0}</td>
                <td>{order.usePointUse || 0}</td>
                <td>{order.paymentConfirmed ? "입금 확인 필요" : "-"}</td>
                <td>{order.url}</td>
                <td>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={order.preorder}
                      readOnly
                    />
                    <label className="form-check-label">Preorder</label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={order.fancall}
                      readOnly
                    />
                    <label className="form-check-label">Fancall</label>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      {/* 페이지 바 */}
      <CenteredPagination style={{ marginTop: "1rem" }}>
        {[...Array(totalPages)].map((_, index) => (
          <Pagination.Item
            key={index}
            active={index + 1 === currentPage}
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </Pagination.Item>
        ))}
      </CenteredPagination>

      {currentOrder && (
        <WiseFancallModel
          show={showModal}
          onHide={() => setShowModal(false)}
          orderId={currentOrder.orderId}
          refreshData={refreshData}
        />
      )}
    </main>
  );
};

const CenteredPagination = styled(Pagination)`
  display: flex;
  justify-content: center;
`;

export default AShopOrder;
