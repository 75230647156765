import Icon from "../Icon";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import {
  reqAddComment,
  reqDeleteReview,
  reqModifyReview,
  reqPlusViewCount,
  reqSetReviewStatus,
} from "../../requests/review";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { isMobile } from "react-device-detect";
import { formatDate } from "../../common/format";
interface RatingStarsProps {
  rating: number;
  setRating: (rating: number) => void;
}

// 모달
const ReviewModal = ({
  reviewData,
  setViewReviewModal,
  isOwner,
  status,
}: any) => {
  const [reviewFormData, setReviewFormData] = useState<any>({
    reviewId: reviewData.reviewId,
    rating: reviewData.rating,
    content: reviewData.content,
    createdAt: reviewData.createdAt,
    photoUrl: reviewData.photoUrl,
    response: reviewData.response || "",
  });
  const [selectedImage, setSelectedImage] = useState<any>();
  const [previewImage, setPreviewImage] = useState<any>();
  const [modifyMode, setModifyMode] = useState<boolean>(false);
  const admin = useSelector((state: any) => state.admin);

  const maskLastName = (lastName: any) => {
    if (!lastName || lastName.length < 2) return lastName;
    return (
      "*".repeat(lastName.length - 1) + lastName.charAt(lastName.length - 1)
    );
  };

  const RatingStars = ({ rating }: RatingStarsProps) => {
    return (
      <div>
        {[...Array(5)].map((_, index) => {
          return (
            <button
              type="button"
              key={index}
              className={index < rating ? "text-warning" : "text-muted"}
              onClick={() =>
                setReviewFormData({ ...reviewFormData, rating: index + 1 })
              }
            >
              <span className="bi bi-star-fill"></span>
            </button>
          );
        })}
      </div>
    );
  };

  useEffect(() => {
    if (isOwner === null) plusViewCount();
  }, []);

  const plusViewCount = async () => {
    await reqPlusViewCount({ id: reviewData.id });
  };
  const [isLoading, setIsLoading] = useState(false);
  const submitModifyReview = async () => {
    try {
      Swal.fire({
        title: "Do you want to modify the review?",
        icon: "question",

        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",

        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          const formData = new FormData();
          formData.append("id", reviewFormData.reviewId);
          formData.append("rating", String(reviewFormData.rating));
          formData.append("content", reviewFormData.content);
          setIsLoading(true);
          if (selectedImage) {
            formData.append("file", selectedImage);
          }
          await reqModifyReview(formData);
          setIsLoading(false);
          setModifyMode(false);
          setViewReviewModal(false);
          Swal.fire("Your review has been modified.", "", "success").then(
            () => {
              window.location.reload();
            }
          );
        }
      });
    } catch (error) {}
  };

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (reader.readyState === 2) {
          setPreviewImage(reader.result);
        }
      };
      reader.readAsDataURL(file);
      setSelectedImage(file);
    }
  };

  const handleReviewDelete = async () => {
    try {
      Swal.fire({
        title: "Do you want to delete the review?",
        icon: "warning",

        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",

        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          await reqDeleteReview(Number(reviewFormData.reviewId));
          setViewReviewModal(false);
          Swal.fire("Your review has been deleted.", "", "success").then(() => {
            window.location.reload();
          });
        }
      });
    } catch (error) {}
  };

  const getStatusLabel = (status: string) => {
    switch (status) {
      case "PRI":
        return "비공개";
      case "PUB":
        return "공개";
      case "BEST":
        return "우수(공개)";
      default:
        return "Unknown";
    }
  };

  const handleReviewStatus = async (newStatus: string) => {
    try {
      Swal.fire({
        title: `리뷰를 ${getStatusLabel(newStatus)} 상태로 전환하시겠습니까?`,
        icon: "question",

        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "승인",
        cancelButtonText: "취소",

        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          await reqSetReviewStatus({
            reviewId: reviewFormData.reviewId,
            status: newStatus,
          });
          setViewReviewModal(false);
          Swal.fire("상태 변경이 완료되었습니다.", "", "success");
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      });
    } catch (error) {}
  };

  const handleCommentSubmit = async () => {
    try {
      Swal.fire({
        title: `해당 리뷰에 답글을 등록하시겠습니까?`,
        icon: "question",

        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "승인",
        cancelButtonText: "취소",

        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          await reqAddComment({
            reviewId: reviewFormData.reviewId,
            response: reviewFormData.response,
            sender: admin.name,
            manager: admin.name,
          });
          setViewReviewModal(false);
          Swal.fire("답글 등록이 완료되었습니다.", "", "success");
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      });
    } catch (error) {}
  };

  return (
    <>
      <Modal show={true} onHide={() => setViewReviewModal(false)}>
        {isLoading ? (
          <LoadingSection>
            <img src="/resources/img/loading.gif" alt="로딩중" />
          </LoadingSection>
        ) : null}
        <Modal.Header>
          <Icon
            icon="x-lg"
            fontSize="2rem"
            onClick={() => setViewReviewModal(false)}
          ></Icon>
        </Modal.Header>
        <Modal.Body>
          <ImageSection>
            {previewImage ? (
              <>{previewImage && <img src={previewImage} alt="preview" />}</>
            ) : (
              <>
                {reviewFormData.photoUrl ? (
                  <>
                    {isMobile ? (
                      <MImage
                        src={`https://kr.object.ncloudstorage.com/ontact-bucket/${reviewFormData.photoUrl}`}
                        alt="review"
                      />
                    ) : (
                      <img
                        id="image"
                        src={`https://kr.object.ncloudstorage.com/ontact-bucket/${reviewFormData.photoUrl}`}
                        alt="review"
                      />
                    )}
                  </>
                ) : null}
              </>
            )}
            <div>
              {modifyMode && (
                <>
                  <label htmlFor="image">Upload Image</label>
                  <input
                    type="file"
                    className="form-control-file"
                    id="image"
                    name="image"
                    accept="image/*"
                    onChange={handleFileChange}
                  />
                </>
              )}
            </div>
          </ImageSection>
          <ContentSection>
            {modifyMode ? (
              <RatingStars
                rating={reviewFormData.rating}
                setRating={function (rating: number): void {
                  throw new Error("Function not implemented.");
                }}
              />
            ) : (
              <StarRow>
                {[1, 2, 3, 4, 5].map((_, index) => {
                  return index < Number(reviewFormData.rating) ? (
                    <Icon icon="star-fill" color="#f2c72c" key={index} />
                  ) : (
                    <Icon icon="star" key={index} />
                  );
                })}
              </StarRow>
            )}
            <>
              <AuthorRow>{`${reviewData?.user?.firstName} ${maskLastName(
                reviewData?.user?.lastName
              )}`}</AuthorRow>
              <DateRow>{formatDate(reviewFormData.createdAt)}</DateRow>
            </>
            <TextRow>
              {modifyMode ? (
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={reviewFormData.content}
                  onChange={(e) =>
                    setReviewFormData({
                      ...reviewFormData,
                      content: e.target.value,
                    })
                  }
                />
              ) : (
                <>{reviewFormData.content}</>
              )}
            </TextRow>
          </ContentSection>
          {!isOwner ? (
            <>
              {isOwner !== null ? (
                <>
                  <CommnetArea>
                    <p>답글 남기기</p>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      value={reviewFormData.response || ""}
                      onChange={(e) =>
                        setReviewFormData({
                          ...reviewFormData,
                          response: e.target.value,
                        })
                      }
                    />
                  </CommnetArea>
                  <CommentBtnRow>
                    <ToCommentSaveButton onClick={handleCommentSubmit}>
                      등록
                    </ToCommentSaveButton>
                  </CommentBtnRow>
                </>
              ) : (
                <CommentAreaOfUser>
                  <label>Comment of Manager</label>
                  <p>{reviewFormData?.response || ""}</p>
                </CommentAreaOfUser>
              )}
            </>
          ) : (
            <>
              {reviewFormData.response && (
                <CommentAreaOfUser>
                  <label>Comment of Manager</label>
                  <p>{reviewFormData?.response || ""}</p>
                </CommentAreaOfUser>
              )}
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          {isOwner ? (
            <>
              {modifyMode ? (
                <ModifyButton onClick={submitModifyReview}>Save</ModifyButton>
              ) : (
                <ModifyButton onClick={() => setModifyMode(true)}>
                  Modify
                </ModifyButton>
              )}
              <DeleteButton onClick={handleReviewDelete}>Delete</DeleteButton>
            </>
          ) : (
            <>
              {status === "PRI" && (
                <>
                  <ToPublicButton onClick={() => handleReviewStatus("PUB")}>
                    공개로 전환
                  </ToPublicButton>
                  <ToBestButton onClick={() => handleReviewStatus("BEST")}>
                    우수 리뷰 선정
                  </ToBestButton>
                </>
              )}
              {status === "PUB" && (
                <>
                  <ToPrivateButton onClick={() => handleReviewStatus("PRI")}>
                    비공개로 전환
                  </ToPrivateButton>
                  <ToBestButton onClick={() => handleReviewStatus("BEST")}>
                    우수 리뷰 선정
                  </ToBestButton>
                </>
              )}
              {status === "BEST" && (
                <>
                  <ToBestOffButton onClick={() => handleReviewStatus("PUB")}>
                    우수 리뷰 해제
                  </ToBestOffButton>
                </>
              )}
            </>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};
const LoadingSection = styled.div`
  position: fixed;
  z-index: 99000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  & > img {
    width: 100%;
  }
`;
const MImage = styled.img`
  margin-bottom: 1rem;
  width: -webkit-fill-available;
`;

const CommentAreaOfUser = styled.div`
  border-top: 1px solid lightgray;
  margin-top: 2.6rem;
  padding-top: 2rem;
  & > label {
    font-weight: bold;
  }
  & > p {
    border: 1px solid lightgray;
    border-radius: 0.4rem;
    margin-top: 1rem;
    padding: 1rem;
  }
`;

const CommnetArea = styled.div`
  margin-top: 3rem;
  padding-top: 2rem;
  width: 100%;
  border-top: 1px solid lightgray;
`;
const CommentBtnRow = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;

const DefaultButton = styled.div`
  color: white;
  border-radius: 0.4rem;
  padding: 0.6rem;
`;

const ToPublicButton = styled(DefaultButton)`
  background-color: var(--color-main-blue);
  &:hover {
    background-color: var(--color-main-blue-hover);
  }
`;
const ToCommentSaveButton = styled(DefaultButton)`
  width: 14%;
  margin-top: 1rem;
  background-color: var(--color-main-blue);
  text-align: center;
  &:hover {
    background-color: var(--color-main-blue-hover);
  }
`;
const ToPrivateButton = styled(DefaultButton)`
  background-color: var(--color-gray);
  &:hover {
    background-color: var(--color-gray-hover);
  }
`;
const ToBestButton = styled(DefaultButton)`
  background-color: var(--color-yellow);
  &:hover {
    background-color: var(--color-yellow);
  }
`;
const ToBestOffButton = styled(DefaultButton)`
  background-color: var(--color-gray);
  &:hover {
    background-color: var(--color-gray-hover);
  }
`;
const ModifyButton = styled(DefaultButton)`
  background-color: var(--color-main-blue);
  &:hover {
    background-color: var(--color-main-blue-hover);
  }
`;
const DeleteButton = styled(DefaultButton)`
  background-color: var(--color-main-red);
  &:hover {
    background-color: var(--color-main-red-hover);
  }
`;

const ImageSection = styled.div`
  text-align: center;
  #image {
    max-width: 25rem;
    max-height: 25rem;
    object-fit: scale-down;
    margin-bottom: 2rem;
  }
`;
const ContentSection = styled.div`
  margin-top: 2rem;
`;
const StarRow = styled.div`
  margin-top: 1rem;
`;
const AuthorRow = styled.span`
  /* margin-top: 1rem; */
`;
const DateRow = styled.span`
  /* margin-top: 1rem; */
  margin-left: 2rem;
`;
const TextRow = styled.div`
  margin-top: 1rem;
`;

export default ReviewModal;
