import { format } from "date-fns";
import { Button, FormControl, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import {
  CODE_SHIPPING_ORDER_STATUS,
  DATE_FORMAT,
} from "../../common/constants";
import { modalClose, modalOpen, modalRefresh } from "../../redux/modalSlice";
import { reqShipUpdateOrder } from "../../requests/ship";
import { MODAL_TYPE } from "./GlobalModal";

export default function ModalShipPayPayment() {
  const dispatch = useDispatch();
  const modal = useSelector((state: any) => state.modal);

  const data = modal.payload.data;

  return (
    <>
      <div
        style={{
          height: "70vh",
          marginTop: "20px",
          paddingRight: "15px",
          paddingLeft: "5px",
          display: "flex",
          gap: "20px",
        }}
      >
        <Inner style={{ width: "30%" }}>
          <h5>Order Info</h5>
          <div className="detail-container">
            <div>
              Order No.
              <FormControl disabled value={data.id} />
            </div>
            <div>
              Order Date
              <FormControl
                disabled
                value={format(new Date(data.createdAt), DATE_FORMAT.ONLY_DATE)}
              />
            </div>
            <div>
              Estimate Date
              <FormControl
                disabled
                value={
                  data.estimatedAt
                    ? format(new Date(data.estimatedAt), DATE_FORMAT.ONLY_DATE)
                    : "-"
                }
              />
            </div>
            <div>
              Payment Expire Date
              <FormControl
                disabled
                value={
                  data.expiredAt
                    ? format(new Date(data.expiredAt), DATE_FORMAT.ONLY_DATE)
                    : "-"
                }
              />
            </div>
            <div>
              Payment Date
              <FormControl
                disabled
                value={
                  data.paidAt
                    ? format(new Date(data.paidAt), DATE_FORMAT.ONLY_DATE)
                    : "-"
                }
              />
            </div>
          </div>
        </Inner>
        <Inner style={{ width: "30%" }}>
          <h5>Shipping Options</h5>
          <div className="detail-container">
            <div>
              Receiver
              <FormControl disabled value={data.adrReceiver} />
            </div>
            <div>
              Email
              <FormControl disabled value={data.adrEmail} />
            </div>
            <div>
              Phone Number
              <FormControl disabled value={data.adrPhone} />
            </div>
            <div>
              Country
              <FormControl disabled value={data.adrCountry} />
            </div>
            <div>
              Personal Clearance Code
              <FormControl disabled value={data.adrPCCC} />
            </div>
            <div>
              City
              <FormControl disabled value={data.adrCity} />
            </div>
            <div>
              State
              <FormControl disabled value={data.adrState} />
            </div>
            <div>
              Zip Code
              <FormControl disabled value={data.adrZip} />
            </div>
            <div>
              Address
              <FormControl disabled value={data.adrOption1} />
            </div>
            <div>
              Apartment, Sutie, etc.
              <FormControl disabled value={data.adrOption2} />
            </div>
          </div>
        </Inner>
        <Inner style={{ width: "100%" }}>
          <h5>Included Products</h5>
          <div className="detail-container">
            <Table style={{ position: "relative" }}>
              <thead>
                <tr>
                  {/* <th style={{ top: 0, position: "sticky", width: "70px" }}>
                <FormCheck
                  checked={totalCheck}
                  onChange={(e) => {
                    setTotalCheck(e.target.checked);
                    setChecked(
                      Array<boolean>(totalCount).fill(e.target.checked)
                    );
                  }}
                />
              </th> */}
                  <th style={{ top: 0, position: "sticky" }}>Tracking No.</th>
                  <th style={{ top: 0, position: "sticky" }}>Category</th>
                  <th style={{ top: 0, position: "sticky" }}>Name</th>
                  <th style={{ top: 0, position: "sticky" }}>Quantity</th>
                  <th style={{ top: 0, position: "sticky" }}>Price</th>
                </tr>
              </thead>
              <tbody>
                {data.products?.map((v: any, index: number) => {
                  return (
                    <>
                      <tr
                        onClick={(e) => {
                          // const temp = [...checked];
                          // temp[index] = !temp[index];
                          // setChecked(temp);
                        }}
                      >
                        {/* <td>
                      <FormCheck checked={checked[index]} />
                    </td> */}
                        <td>{v.tracking}</td>
                        <td>{v.category}</td>
                        <td>{v.name}</td>
                        <td>{v.quantity}</td>
                        <td>{v.price}</td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </Inner>
      </div>

      <div className="d-flex justify-content-end gap-2 mt-3">
        <Button
          variant="success"
          style={{ width: "200px" }}
          onClick={async (e) => {
            await reqShipUpdateOrder({
              id: data.id,
              status: CODE_SHIPPING_ORDER_STATUS.PRODUCT_READY,
            });
            dispatch(modalClose());
            dispatch(modalRefresh());
            setTimeout(() => {
              dispatch(
                modalOpen({
                  modalType: MODAL_TYPE.INFO,
                  title: "Payment Done",
                  payload: { text: "Paid successfully" },
                })
              );
            }, 10);
          }}
        >
          PAYMENT
        </Button>
      </div>
    </>
  );
}

const Inner = styled.div`
  .detail-container {
    height: 90%;
    padding-right: 15px;
    overflow-y: scroll;
  }

  h5 {
    margin-bottom: 20px;
  }
  div {
    width: 100%;
    margin-bottom: 15px;
  }
`;
