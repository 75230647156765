import React from "react";
import { RECOMMANDATION_SHOP_LIST_FASHION } from "../../common/constants";
import { AltText } from "./ShopKpop";

const ShopFashion = () => {
  return (
    <>
      {Object.entries(RECOMMANDATION_SHOP_LIST_FASHION).flatMap(
        ([key, value]) => (
          <li>
            <a href={key} target="_blank" rel="noopener noreferrer">
              {value.includes(".") ? (
                <img src={`/landing/shop-logo/${value}`} alt={value} />
              ) : (
                <AltText>{value}</AltText>
              )}
            </a>
          </li>
        )
      )}
    </>
  );
};

export default ShopFashion;
