import { useState } from "react";
import { Button, FormCheck, FormControl, FormLabel } from "react-bootstrap";
import { CONST_COOKIE, getCookie, setCookie } from "../../common/cookies";
import { reqAdminLogin } from "../../requests/admin-auth";
import { useDispatch } from "react-redux";
import { adminSave } from "../../redux/adminSlice";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

export default function ALogin() {
  const nav = useNavigate();
  const dispatch = useDispatch();
  const [form, setForm] = useState<{ email: string; password: string }>({
    email: getCookie(CONST_COOKIE.ADMIN_LOGIN_SAVED_ID),
    password: "",
  });
  const [saveEmail, setSaveEmail] = useState<boolean>(
    getCookie(CONST_COOKIE.ADMIN_LOGIN_SAVE_ID_OPTION)
  );

  const handleLoginClick = async () => {
    try {
      const result = await reqAdminLogin(form);
      if (![0, 1, 3].includes(result.data.role)) {
        await Swal.fire({
          icon: "error",
          title: "로그인 실패",
          text: "권한이 없습니다.",
          confirmButtonText: "확인",
        });
        return;
      }
      if (saveEmail) {
        setCookie(CONST_COOKIE.ADMIN_LOGIN_SAVED_ID, form.email);
      }
      dispatch(adminSave(result.data));
      setCookie(CONST_COOKIE.ADMIN_REFRESH, result.data.refreshToken);
      nav("/admin");
    } catch (error) {
      console.error("Login request failed:", error);
      await Swal.fire({
        icon: "error",
        title: "로그인 실패",
        text: "로그인 권한을 확인해주세요.",
        confirmButtonText: "확인",
      });
    }
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          height: "70%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ width: "500px" }}>
          <h3>관리자 로그인</h3>
          <FormControl
            style={{ marginBottom: "10px", display: "block", width: "100%" }}
            placeholder="email or id"
            value={form.email}
            onChange={(e) => setForm({ ...form, email: e.target.value })}
          />
          <FormControl
            type="password"
            style={{ display: "block", width: "100%" }}
            placeholder="password"
            value={form.password}
            onChange={(e) => setForm({ ...form, password: e.target.value })}
          />
          <div className="d-flex gap-2 mt-3 align-items-center">
            <FormCheck
              id="save-email"
              checked={saveEmail}
              onChange={(e) => {
                setSaveEmail(e.target.checked);
                setCookie(
                  CONST_COOKIE.ADMIN_LOGIN_SAVE_ID_OPTION,
                  String(e.target.checked)
                );
              }}
            />
            <FormLabel htmlFor="save-email">아이디 저장</FormLabel>
          </div>
          <Button
            variant="dark"
            className="mt-4"
            style={{ width: "100%" }}
            onClick={handleLoginClick}
          >
            로그인
          </Button>
        </div>
      </div>
    </>
  );
}
