import { AxiosError } from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import { reqPasswordFind, reqPasswordReset } from '../../requests/auth';

export default function PasswordResetPage() {
    const [email, setEmail] = useState('');
    const [code, setCode] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [stage, setStage] = useState(1);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const navigate = useNavigate();

    const handleSendCode = async () => {
        setIsButtonDisabled(true);
        try {
            const response = await reqPasswordFind({ email });
            if (response.data) {
                Swal.fire('Success', 'Verification code sent to your email.', 'success');
                setStage(2);
            } else {
                throw new Error('Unexpected API response');
            }
        } catch (error) {
            const axiosError = error as AxiosError;
            if (axiosError.response && axiosError.response.status === 404) {
                Swal.fire('Error', 'Email not found.', 'error');
            } else {
                Swal.fire('Error', 'Failed to send verification code.', 'error');
            }
        } finally {
            setIsButtonDisabled(false);
        }
    };

    const handleResetPassword = async () => {
        if (!password || password.trim() === '') {
            Swal.fire('Error', 'New password must not be empty.', 'error');
            return;
        }

        if (password !== confirmPassword) {
            Swal.fire('Error', 'Passwords do not match.', 'error');
            return;
        }

        try {
            const response = await reqPasswordReset({ email, code, newPassword: password });
            if (response.data && response.data.success) {
                Swal.fire('Success', 'Your password has been reset successfully!', 'success');
                navigate('/login');
            } else {
                console.error('Server response:', response.data);
                throw new Error(response.data.message || 'Password reset failed');
            }
        } catch (error) {
            const axiosError = error as AxiosError;
            Swal.fire('Error', axiosError.message || 'Failed to reset password.', 'error');
        }
    };

    return (
        <Container>
            <Card>
                <h2>Reset Your Password</h2>
                {stage === 1 ? (
                    <>
                        <Input type="email" placeholder="Enter your email" value={email} onChange={e => setEmail(e.target.value)} />
                        <Button onClick={handleSendCode} disabled={isButtonDisabled}>Send Verification Code</Button>
                    </>
                ) : (
                    <>
                        <Input type="text" placeholder="Verification Code" value={code} onChange={e => setCode(e.target.value)} />
                        <Input type="password" placeholder="New Password" value={password} onChange={e => setPassword(e.target.value)} />
                        <Input type="password" placeholder="Confirm New Password" value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} />
                        <Button onClick={handleResetPassword}>Reset Password</Button>
                    </>
                )}
            </Card>
        </Container>
    );
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #f4f4f4;
`;

const Card = styled.div`
  padding: 20px;
  background: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: 300px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Input = styled.input`
  padding: 10px;
  border: 2px solid #ccc;
  border-radius: 4px;
  width: 100%;
`;

const Button = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #0056b3;
  }
  &:disabled {
    background-color: #007bff;
    cursor: not-allowed;
  }
`;
