import { createSlice } from "@reduxjs/toolkit";

interface iModal {
  isOpen: boolean;
  modalType: number;
  prevModalType: number | null;
  prevData: any;
  title: string;
  payload: any;
  refresh: boolean;
}

const initialState: iModal = {
  isOpen: false,
  modalType: 0,
  prevModalType: null,
  prevData: {},
  title: "",
  payload: {},
  refresh: false,
};

const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    modalOpen: (state, action) => {
      const { modalType, title, payload } = action.payload;
      if (state.isOpen) {
        state.prevModalType = state.modalType;
        state.prevData = state.payload;
      }
      state.isOpen = true;
      state.modalType = modalType;
      state.title = title;
      state.payload = payload;
    },
    modalClose: (state) => {
      if (state.prevModalType !== null) {
        state.modalType = state.prevModalType;
        state.payload = state.prevData;
        state.prevModalType = null;
      } else {
        state.isOpen = false;
      }
    },
    modalRefresh: (state) => {
      state.refresh = !state.refresh;
    },
  },
});

export const { modalOpen, modalClose, modalRefresh } = modalSlice.actions;
export default modalSlice.reducer;
