import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  reqAllReviewByRatingToUser,
  reqAllReviewByRecentToUser,
} from "../../requests/review";
import Icon from "../../components/Icon";
import ReviewCard from "../../components/ReviewCard";
import { isMobile } from "react-device-detect";
import CustomerPageTitle from "../../components/CustomerPageTitle";
import { MButton, MTwoButtonRow } from "../../common/mobile-styled";
import { Dropdown, Pagination } from "react-bootstrap";

const CReview = () => {
  const [reviewList, setReviewList] = useState<any>([
    {
      reviewId: 0,
      rating: 0,
      content: "",
      createdAt: null,
      photoUrl: "",
      response: "",
      user: null,
    },
  ]);
  const [currPage, setCurrPage] = useState<number>(1);
  const [orderMode, setOrderMode] = useState<string>("RECENT");
  const [pageMetaData, setPageMetaData] = useState<any>({
    reviewCount: 0,
    pageCount: 0,
  });

  useEffect(() => {
    fetchReviewList();
  }, [orderMode]);

  const fetchReviewList = async () => {
    try {
      let response;
      if (orderMode === "RECENT") {
        response = await reqAllReviewByRecentToUser({ page: currPage });
      } else {
        response = await reqAllReviewByRatingToUser({ page: currPage });
      }
      console.log(response.data);
      setReviewList(response.data?.reviewList);

      const reviewCount = Number(response.data?.reviewCount);
      let pageCount = Math.floor(reviewCount / 8);
      if (reviewCount % 8 !== 0) {
        pageCount = pageCount + 1;
      }
      setPageMetaData({
        reviewCount,
        pageCount,
      });
    } catch (error) {
      console.error("Failed to fetch reviews:", error);
    }
  };

  const handlePageChange = async (page: number) => {
    if (page === 0) {
      return;
    }
    let response;
    if (orderMode === "RECENT") {
      response = await reqAllReviewByRecentToUser({ page });
    } else {
      response = await reqAllReviewByRatingToUser({ page });
    }
    const reviewCount = Number(response.data?.reviewCount);
    let pageCount = Math.floor(reviewCount / 8);
    if (reviewCount % 8 !== 0) {
      pageCount = pageCount + 1;
    }
    if (page > pageCount) {
      return;
    }
    setReviewList(response.data?.reviewList);
    setCurrPage(page);
    setPageMetaData({
      reviewCount,
      pageCount,
    });
  };

  //! Dropdown
  const [dropdownText, setDropdownText] = useState<string>("Order By Recent");

  const MobileView = () => {
    return (
      <MobileWrapper>
        <div>
          <Dropdown>
            <Dropdown.Toggle
              variant="outline-dark"
              id="dropdown-basic"
              style={{ width: "100%" }}
            >
              {dropdownText}
            </Dropdown.Toggle>

            <Dropdown.Menu style={{ width: "100%", textAlign: "center" }}>
              <Dropdown.Item
                onClick={() => {
                  setOrderMode("RECENT");
                  setDropdownText("Order By Recent");
                }}
              >
                Order By Recent
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  setOrderMode("RATING");
                  setDropdownText("Order By Rating");
                }}
              >
                Order By Rating
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>

        <div style={{ marginTop: "2rem" }}>
          {reviewList &&
            reviewList.map((review: any, index: number) => (
              <div
                key={review.reviewId || index}
                style={{ marginTop: "1.4rem" }}
              >
                <ReviewCard usage="DASHBOARD" review={review} isMobile={true} />
              </div>
            ))}
        </div>
      </MobileWrapper>
    );
  };

  return (
    <Wrapper style={{ padding: `${isMobile && "1.4rem"}` }}>
      {isMobile ? (
        <CustomerPageTitle subtitle1="Event" title="Review" />
      ) : (
        <CustomerPageTitle title="Review" />
      )}

      <Section>
        {isMobile ? (
          <MobileView />
        ) : (
          <>
            <OrderModeSection>
              <MButton
                onClick={() => setOrderMode("RECENT")}
                style={{
                  backgroundColor: "var(--color-main-blue)",
                  color: "var(--color-white)",
                }}
              >
                Order By Recent
              </MButton>
              <MButton
                onClick={() => setOrderMode("RATING")}
                style={{
                  backgroundColor: "var(--color-main-blue)",
                  color: "var(--color-white)",
                }}
              >
                Order By Rating
              </MButton>
            </OrderModeSection>

            <ReviewBody>
              {reviewList &&
                reviewList.map((review: any, index: number) => (
                  <div
                    key={review.reviewId || index}
                    style={{
                      marginTop: "1.4rem",
                      width: "auto",
                      cursor: "pointer",
                    }}
                  >
                    <ReviewCard
                      usage="DASHBOARD"
                      review={review}
                      isMobile={true}
                    />
                  </div>
                ))}
            </ReviewBody>
          </>
        )}

        <ReviewFooter>
          {Array.from({ length: pageMetaData.pageCount }, (_, index) => (
            <PageNumber
              key={index + 1}
              $isSelected={index + 1 === currPage}
              onClick={() => handlePageChange(index + 1)}
            >
              {index + 1}
            </PageNumber>
          ))}
        </ReviewFooter>
      </Section>
    </Wrapper>
  );
};

const Title = styled.h3`
  border-bottom: 1px solid lightgray;
  padding-bottom: 1rem;
  margin-top: 1rem;
`;
const FeeBoxRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  & > div {
    flex: 1 0 0;
    text-align: center;
    align-items: center;
    border: 1px solid lightgray;
    padding: 0.4rem 0;
  }
  margin: 0.6rem 0;
`;
const TotalFeeBoxRow = styled(FeeBoxRow)`
  background-color: #474747;
  color: white;
`;

const MobileWrapper = styled.div`
  margin: 0;
`;

const MBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
  align-items: center;
`;
const Col = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  font-weight: bold;
  margin: 0.4rem 0 0.4rem 0;
`;
const P = styled.p`
  margin: 0.4rem 0 0.4rem 0;
`;

const ReviewBody = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(4, 1fr);
  gap: 1rem;
  margin: 0 auto;
  margin-top: 2rem;
`;
const ReviewFooter = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
`;
const PageNumber = styled.span<{ $isSelected: boolean }>`
  font-size: 1rem;
  cursor: pointer;
  margin: 0 0.2rem;
  padding: 6px 12px;
  border-radius: 0.375rem;
  background-color: ${(props) =>
    props.$isSelected ? "var(--color-main-blue)" : "transparent"};

  color: ${(props) => props.$isSelected && "var(--color-white)"};
`;
const RecentOrderButton = styled.div`
  border-radius: 0.4rem;
  padding: 0.6rem;
  margin: 2rem 1rem 0 0;
  &:hover {
    background-color: lightgray;
  }
`;
const M_RecentOrderButton = styled(RecentOrderButton)`
  border: 1px solid #272727;
  margin: 1rem 1rem 0 0;
`;
const OrderModeSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 25%;
  & > div {
    width: 49%;
  }
`;
const Section = styled.div``;
const Wrapper = styled.div`
  padding: 2rem;
  margin-bottom: 4rem;
`;

export default CReview;
