import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import styled from "styled-components";
import { ADMIN_BADGE } from "../../../common/constants";
import { formatDate } from "../../../common/format";
import AdminHeadTitle from "../../../components/AdminHeadTitle";
import Icon from "../../../components/Icon";
import ReviewModal from "../../../components/modal/ReviewModal";
import { reqReadAdminBadge } from "../../../requests/badge";
import {
  reqAllReviewByRatingToAdmin,
  reqAllReviewByRecentToAdmin,
} from "../../../requests/review";
const AReview: React.FC = () => {
  const [reviews, setReviews] = useState<any>([]);
  const [selectedReview, setSelectedReview] = useState<any>(null);
  const [reviewModal, setReviewModal] = useState<boolean>(false);
  const [pageMetaData, setPageMetaData] = useState<any>({});
  const [currPage, setCurrPage] = useState<number>(1);
  const [orderMode, setOrderMode] = useState<string>("RECENT");

  useEffect(() => {
    fetchReviews();

    const badgePayload = {
      badgeName: ADMIN_BADGE.REVIEW,
    };
    reqReadAdminBadge(badgePayload);
  }, []);

  useEffect(() => {
    fetchReviews();
  }, [orderMode]);

  const fetchReviews = async () => {
    try {
      let response;
      if (orderMode === "RECENT") {
        response = await reqAllReviewByRecentToAdmin({ page: currPage });
      } else {
        response = await reqAllReviewByRatingToAdmin({ page: currPage });
      }
      setReviews(response.data?.reviewList);

      const reviewCount = Number(response.data?.reviewCount);
      let pageCount = Math.floor(reviewCount / 7);
      if (reviewCount % 7 !== 0) {
        pageCount = pageCount + 1;
      }
      setPageMetaData({
        reviewCount,
        pageCount,
      });
    } catch (error) {
      console.error("Failed to fetch reviews:", error);
    }
  };

  const getStatusLabel = (status: string) => {
    switch (status) {
      case "PRI":
        return "비공개";
      case "PUB":
        return "공개";
      case "BEST":
        return "우수(공개)";
      default:
        return "Unknown";
    }
  };

  const handleReviewModal = ({ target }: any) => {
    const selectedReviewId = Number(
      target.closest(".review-row").id.split("-")[1]
    );
    const targetReview = reviews.find(
      (review: any) => review.id === selectedReviewId
    );
    setReviewModal(true);
    setSelectedReview({
      reviewId: targetReview.id,
      rating: targetReview.rating,
      content: targetReview.content,
      createdAt: targetReview.createdAt,
      photoUrl: targetReview.photoUrls,
      status: targetReview.status,
      response: targetReview.response,
    });
  };

  const handlePageChange = async (page: number) => {
    if (page === 0) {
      return;
    }
    let response;
    if (orderMode === "RECENT") {
      response = await reqAllReviewByRecentToAdmin({ page });
    } else {
      response = await reqAllReviewByRatingToAdmin({ page });
    }
    const reviewCount = Number(response.data?.reviewCount);
    let pageCount = Math.floor(reviewCount / 7);
    if (reviewCount % 7 !== 0) {
      pageCount = pageCount + 1;
    }
    if (page > pageCount) {
      return;
    }
    setReviews(response.data?.reviewList);
    setCurrPage(page);
    setPageMetaData({
      reviewCount,
      pageCount,
    });
  };

  return (
    <main id="main" className="main">
      <AdminHeadTitle
        subtitle1="서비스 관리"
        subtitle2="리뷰 관리"
        title="리뷰 관리"
      />

      <OrderModeSection>
        <RecentOrderButton onClick={() => setOrderMode("RECENT")}>
          최신순 정렬
        </RecentOrderButton>
        <RecentOrderButton onClick={() => setOrderMode("RATING")}>
          별점순 정렬
        </RecentOrderButton>
      </OrderModeSection>
      <ReviewSection>
        <RSHeader>
          <div>스페이스 코드</div>
          <div>주문 번호</div>
          <div className="h-date">
            등록일{" "}
            {orderMode === "RECENT" && (
              <Icon icon="caret-up-fill" fontSize="1rem" />
            )}
          </div>
          <div>
            별점 {orderMode === "RATING" && <Icon icon="caret-up-fill" />}
          </div>
          <div className="h-content">내용</div>
          <div>조회수</div>
          <div>상태</div>
          <div>담당자</div>
          <div>보기</div>
        </RSHeader>
        <RSBody>
          {reviews &&
            reviews.map((review: any) => (
              <RSRow
                id={`review-${review.id}`}
                className="review-row"
                key={review.id}
                onClick={(e) => handleReviewModal(e)}
              >
                <div>{review.userId}</div>
                <div>{review.orderId}</div>
                <div className="c-date">{formatDate(review.createdAt)}</div>
                <div>
                  {[1, 2, 3, 4, 5].map((_, index) => {
                    return index < Number(review.rating) ? (
                      <Icon icon="star-fill" color="#f2c72c" key={index} />
                    ) : (
                      <Icon icon="star" key={index} />
                    );
                  })}
                </div>
                <div className="c-content">{review.content}</div>
                <div>{review.view}</div>
                <div>{getStatusLabel(review.status)}</div>
                <div>{review.manager}</div>
                <div>
                  <Button
                    variant="primary"
                    onClick={() => setReviewModal(true)}
                  >
                    보기
                  </Button>
                </div>
              </RSRow>
            ))}
        </RSBody>
        <ReviewFooter>
          <Icon
            icon="arrow-left-short"
            fontSize="2.4rem"
            onClick={() => handlePageChange(currPage - 1)}
          />
          {Array.from({ length: pageMetaData.pageCount }, (_, index) => (
            <PageNumber
              key={index + 1}
              isSelected={index + 1 === currPage}
              onClick={() => handlePageChange(index + 1)}
            >
              {index + 1}
            </PageNumber>
          ))}
          <Icon
            icon="arrow-right-short"
            fontSize="2.4rem"
            onClick={() => handlePageChange(currPage + 1)}
          />
        </ReviewFooter>
      </ReviewSection>

      {reviewModal && (
        <ReviewModal
          reviewData={selectedReview}
          setViewReviewModal={(flag: boolean) => setReviewModal(flag)}
          isOwner={false}
          status={selectedReview.status}
        />
      )}
    </main>
  );
};

const RecentOrderButton = styled.div`
  border-radius: 0.4rem;
  padding: 0.6rem;
  border: 3px solid #272727;
  margin: 2rem 1rem 0 0;
  &:hover {
    background-color: lightgray;
  }
`;

const OrderModeSection = styled.div`
  display: flex;
  flex-direction: row;
`;

const ReviewFooter = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
`;
const PageNumber = styled.span<{ isSelected: boolean }>`
  font-size: 1.4rem;
  cursor: pointer;
  margin: 0 1rem;
  color: ${(props) => (props.isSelected ? "black" : "lightgray")};
`;

const ReviewSection = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2rem 0;
`;
const RSHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  border-bottom: 1px solid lightgray;
  padding: 1.2rem 0 1.2rem 0;
  & > div {
    flex: 1 1 0;
    font-weight: bold;
  }
  .h-date {
    flex: 2 1 0;
  }
  .h-content {
    flex: 3 1 0;
  }
`;
const RSBody = styled.div`
  display: flex;
  flex-direction: column;
`;
const RSRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  margin: 1rem 0;
  & > div {
    flex: 1 1 0;
  }
  .c-date {
    flex: 2 1 0;
  }
  .c-content {
    flex: 3 1 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export default AReview;
