import { format } from "date-fns";
import { useEffect, useState } from "react";
import { Button, Col, FormControl, Row } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import Swal from "sweetalert2";
import styled from "styled-components";
import { reqShipFEE } from "../../requests/ship";
import { CODE_SHOPPING_ORDER_ITEMS, DATE_FORMAT } from "../../common/constants";
import { modalClose, modalOpen } from "../../redux/modalSlice";
import { reqShipProductUpdates } from "../../requests/warehouse";
import { reqShipProductBox } from "../../requests/ship";
import { MODAL_TYPE } from "./GlobalModal";
import {
  MWrapper,
  MBody,
  MTabSection,
  MTabHeader,
  MFooter,
  MHeader,
  MTabBody,
  MBOptionRow,
  MRow,
  MButton,
  MTableBody,
} from "../../common/mobile-styled";

interface IDetail {
  id?: number;
  category: number;
  name: string;
  quantity: number;
  price: number;
  isEditing?: boolean;
  trackingNumber: string;
}

export default function ExtrackModal() {
  const dispatch = useDispatch();
  const [exchangeRate, setExchangeRate] = useState<number>(0);
  const modal = useSelector((state: any) => state.modal);
  const data = modal.payload.data;
  const [status, setStatus] = useState<number>(data.status || 0);
  const [isEditing, setIsEditing] = useState(false);
  const [editedData, setEditedData] = useState({});
  const [detail, setDetails] = useState<IDetail[]>(
    modal.payload.data.detail || []
  );
  // const fetchExchangeRate = () => {
  //   fetch(
  //     "https://quotation-api-cdn.dunamu.com/v1/forex/recent?codes=FRX.KRWUSD"
  //   )
  //     .then((response) => response.json())
  //     .then((data) => {
  //       const rate = data[0].basePrice;
  //       setExchangeRate(rate);
  //     })
  //     .catch((error) => console.error("Error fetching exchange rate:", error));
  // };

  const fetchFixedExchangeRate = async () => {
    try {
      const response = await reqShipFEE();
      if (response && response.data && response.data[0]?.fixedExchangeRateShop) {
        setFixedExchangeRate(parseFloat(response.data[0].fixedExchangeRateShop));
      }
    } catch (error) {
      console.error("Error fetching fixed exchange rate:", error);
    }
  };
  const [fixedExchangeRate, setFixedExchangeRate] = useState(0);

  useEffect(() => {
    // fetchExchangeRate();
    fetchFixedExchangeRate();
    setEditedData({ ...data });
    setStatus(data.status || 0);
  }, [data]);

  const handleDetailChange = (
    index: number,
    field: keyof IDetail,
    value: any
  ) => {
    const newDetails = [...detail];
    const updatedDetail = { ...newDetails[index], [field]: value };
    newDetails[index] = updatedDetail;
    setDetails(newDetails);
  };

  const toggleDetailEdit = (index: number) => {
    setDetails((currentDetails) =>
      currentDetails.map((detail, idx) =>
        idx === index ? { ...detail, isEditing: !detail.isEditing } : detail
      )
    );
  };

  const handleSubmit = () => {
    const payload = {
      details: detail.map((detail) => ({
        id: detail.id,
        category: detail.category,
        name: detail.name,
        quantity: Number(detail.quantity),
        price: Number(detail.price),
        trackingNumber: detail.trackingNumber,
      })),
    };

    reqShipProductUpdates(payload)
      .then(() => {
        setIsEditing(false);
        dispatch(modalClose());
        window.location.reload();
      })
      .catch((error) => {
        console.error("Error updating data:", error);
      });
  };

  const categoryOptions = Object.entries(CODE_SHOPPING_ORDER_ITEMS).map(
    ([key, value]) => ({
      value: parseInt(key),
      label: value,
    })
  );

  const findCategoryOption = (category: any) =>
    categoryOptions.find((option) => option.value === category) || null;

  const [isZoomed, setIsZoomed] = useState(false);

  const handleImageClick = () => {
    dispatch(
      modalOpen({
        modalType: MODAL_TYPE.IMAGE_PREVIEW,
        title: "Image Preview",
        payload: {
          data: {
            imageUrl: data.imageUrl,
          },
        },
      })
    );
  };

  const handleStatusUpdate = () => {
    dispatch(
      modalOpen({
        modalType: MODAL_TYPE.CONFIRM,
        title: "Confirm Update",
        payload: {
          text: "Do you want to update the status?",
          onConfirm: () => {
            updateStatus();
          },
        },
      })
    );
  };

  const updateStatus = () => {
    const payload = {
      tracking: data.tracking,
      status: 1,
    };

    reqShipProductBox(payload)
      .then((response) => {
        console.log("Status updated successfully:", response);
        dispatch(modalClose());
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        console.error("Failed to update status:", error);
        dispatch(modalClose());
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      });
  };

  return (
    <>
      {isMobile ? (
        <MWrapper>
          <MBody>
            <div className="line">
              <div className="sub-header">- Box Image</div>
              {data.imageUrl ? (
                <img
                  src={data.imageUrl}
                  alt="Attached File"
                  style={{
                    maxWidth: "-webkit-fill-available",
                  }}
                  onClick={handleImageClick}
                />
              ) : (
                "No image available"
              )}
            </div>

            <div className="line">
              <div className="sub-header">- Order Info</div>
              <div>
                <MRow>
                  <div className="label">Order No</div>
                  <div className="data">{data.tracking}</div>
                </MRow>

                <MRow>
                  <div className="label">Warehousing date</div>
                  <div className="data">
                    {format(new Date(data.createdAt), DATE_FORMAT.ONLY_DATE)}
                  </div>
                </MRow>

                <MRow>
                  <div className="label">Extra Charge Details</div>
                  <div className="data">
                    {data.Addstatus === 0
                      ? "None."
                      : data.Addstatus === 1
                        ? "Import Tax"
                        : data.Addstatus === 2
                          ? "Return Fee"
                          : data.Addstatus === 3
                            ? "Pay on delivery"
                            : data.Addstatus === 4
                              ? "Split"
                              : "Not Specified"}
                  </div>
                </MRow>

                <MRow>
                  <div className="label">Extra Charge</div>
                  <div className="data price">{`$${(
                    data.Extra / fixedExchangeRate
                  ).toFixed(2)} / ￦${data.Extra}`}</div>
                </MRow>
              </div>
            </div>

            <div>
              <div className="sub-header">- Editable Info</div>
              {detail.map((detail, index) => (
                <div key={index}>
                  <MRow>
                    <div className="label">Category</div>
                    <div className="data">
                      {detail.isEditing ? (
                        <Select
                          options={categoryOptions}
                          value={findCategoryOption(detail.category)}
                          onChange={(selectedOption) =>
                            handleDetailChange(
                              index,
                              "category",
                              selectedOption ? selectedOption.value : ""
                            )
                          }
                        />
                      ) : (
                        <>{findCategoryOption(detail.category)?.label}</>
                      )}
                    </div>
                  </MRow>

                  <MRow>
                    <div className="label">Name</div>
                    <div className="data">
                      {detail.isEditing ? (
                        <FormControl
                          value={detail.name}
                          onChange={(e) =>
                            handleDetailChange(index, "name", e.target.value)
                          }
                        />
                      ) : (
                        <>
                          {detail.name.length > 20
                            ? `${detail.name.substring(0, 10)}...`
                            : detail.name}
                        </>
                      )}
                    </div>
                  </MRow>

                  <MRow>
                    <div className="label">Quantity</div>
                    <div className="data">
                      {detail.isEditing ? (
                        <FormControl
                          type="number"
                          value={detail.quantity}
                          onChange={(e) =>
                            handleDetailChange(
                              index,
                              "quantity",
                              parseInt(e.target.value)
                            )
                          }
                        />
                      ) : (
                        <>{detail.quantity}</>
                      )}
                    </div>
                  </MRow>

                  <MRow>
                    <div className="label">Unit Price</div>
                    <div className="data price">
                      {detail.isEditing ? (
                        <FormControl
                          type="number"
                          value={detail.price}
                          onChange={(e) =>
                            handleDetailChange(
                              index,
                              "price",
                              parseFloat(e.target.value)
                            )
                          }
                        />
                      ) : (
                        <>
                          {`$${detail.price} / ￦${Math.round(
                            detail.price * fixedExchangeRate
                          ).toLocaleString("ko-KR")}`}
                        </>
                      )}
                    </div>
                  </MRow>

                  <MRow>
                    {status !== 3 && (
                      <Col xs={12} md={3}>
                        {/* <Button
                              onClick={() => toggleDetailEdit(index)}
                              size="sm"
                            >
                              {detail.isEditing ? "Save" : "Edit"}
                            </Button> */}
                      </Col>
                    )}
                  </MRow>
                </div>
              ))}
            </div>
          </MBody>

          <MFooter>
            {status !== 3 && (
              <MButton
                onClick={handleStatusUpdate}
                style={{
                  backgroundColor: "var(--color-main-blue)",
                  color: "var(--color-white)",
                }}
              >
                Enter data
                {/* <Button onClick={handleSubmit} variant="primary">
                      Submit All Changes
                    </Button> */}
              </MButton>
            )}
          </MFooter>
        </MWrapper>
      ) : (
        <div style={{ display: "flex", marginTop: "20px", gap: "20px" }}>
          <div style={{ flex: 1 }}>
            {data.imageUrl ? (
              <img
                src={data.imageUrl}
                alt="Attached File"
                style={{
                  maxWidth: isZoomed ? "100%" : "200px",
                  cursor: "pointer",
                }}
                onClick={handleImageClick}
              />
            ) : (
              "No image available"
            )}
          </div>
          <div style={{ flex: 3, display: "flex", flexDirection: "column" }}>
            <Inner>
              <h5>Order Info</h5>
              <div className="detail-container">
                <Row>
                  <Col>
                    <div>
                      Order No.
                      <FormControl disabled value={data.tracking} />
                    </div>
                  </Col>
                  <Col>
                    <div>
                      Warehousing date
                      <FormControl
                        disabled
                        value={format(
                          new Date(data.createdAt),
                          DATE_FORMAT.ONLY_DATE
                        )}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div>
                      Extra Charge Details
                      <FormControl
                        disabled
                        value={
                          data.Addstatus === 0
                            ? "None."
                            : data.Addstatus === 1
                              ? "Import Tax"
                              : data.Addstatus === 2
                                ? "Return Fee"
                                : data.Addstatus === 3
                                  ? "Pay on delivery"
                                  : data.Addstatus === 4
                                    ? "Split"
                                    : "Not Specified"
                        }
                      />
                    </div>
                  </Col>
                  <Col>
                    <div>
                      Extra charge
                      <FormControl
                        disabled
                        value={`${data.Extra} KRW / ${(
                          data.Extra / fixedExchangeRate
                        ).toFixed(2)} USD`}
                      />
                    </div>
                  </Col>
                </Row>
                {detail.map((detail, index) => (
                  <Row key={index} className="mb-3">
                    <Col xs={13} md={3}>
                      <strong>Category:</strong>
                      {detail.isEditing ? (
                        <Select
                          options={categoryOptions}
                          value={findCategoryOption(detail.category)}
                          onChange={(selectedOption) =>
                            handleDetailChange(
                              index,
                              "category",
                              selectedOption ? selectedOption.value : ""
                            )
                          }
                        />
                      ) : (
                        <span>
                          {findCategoryOption(detail.category)?.label}
                        </span>
                      )}
                    </Col>
                    <Col xs={12} md={3}>
                      <strong>Name:</strong>
                      {detail.isEditing ? (
                        <FormControl
                          value={detail.name}
                          onChange={(e) =>
                            handleDetailChange(index, "name", e.target.value)
                          }
                        />
                      ) : (
                        <span>
                          {detail.name.length > 10
                            ? `${detail.name.substring(0, 10)}...`
                            : detail.name}
                        </span>
                      )}
                    </Col>
                    <Col xs={12} md={3}>
                      <strong>Quantity:</strong>
                      {detail.isEditing ? (
                        <FormControl
                          type="number"
                          value={detail.quantity}
                          onChange={(e) =>
                            handleDetailChange(
                              index,
                              "quantity",
                              parseInt(e.target.value)
                            )
                          }
                        />
                      ) : (
                        <span>{detail.quantity}</span>
                      )}
                    </Col>
                    <Col xs={12} md={3}>
                      <strong>Unit Price:</strong>
                      {detail.isEditing ? (
                        <FormControl
                          type="number"
                          value={detail.price}
                          onChange={(e) =>
                            handleDetailChange(
                              index,
                              "price",
                              parseFloat(e.target.value)
                            )
                          }
                        />
                      ) : (
                        <span>
                          {`${detail.price} USD / ${Math.round(
                            detail.price * fixedExchangeRate
                          )} KRW`}
                        </span>
                      )}
                    </Col>
                    {status !== 3 && (
                      <Col xs={12} md={3}>
                        {/* <Button
                          onClick={() => toggleDetailEdit(index)}
                          size="sm"
                        >
                          {detail.isEditing ? "Save" : "Edit"}
                        </Button> */}
                      </Col>
                    )}
                  </Row>
                ))}
                {status !== 3 && (
                  <div className="text-end mt-3">
                    <Button
                      onClick={handleStatusUpdate}
                      variant="warning"
                      style={{ marginRight: "10px" }}
                    >
                      Enter data
                    </Button>
                    {/* <Button onClick={handleSubmit} variant="primary">
                      Submit All Changes
                    </Button> */}
                  </div>
                )}
              </div>
            </Inner>
          </div>
        </div>
      )}
    </>
  );
}

const Inner = styled.div`
  .detail-container {
    padding: 15px; /* 상하좌우 패딩을 모두 15px로 설정 */
  }

  h5 {
    margin-bottom: 2px; /* 제목과 내용 간의 간격 */
  }

  .row {
    margin-bottom: 5px; /* Row 간의 간격 조정 */
  }

  .row > .col {
    padding: 3px; /* Col 내부 패딩 조정 */
  }

  div {
    margin-bottom: 3px; /* div 요소들 간의 간격 */
  }
`;
