import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { RootUserState } from "../../redux/store";
import { useSelector } from "react-redux";
import {
  reqCouponByCode,
  reqCouponDuplicateCheck,
  reqSendCoupon,
} from "../../requests/coupon";
import Swal from "sweetalert2";
import { reqGetUserById } from "../../requests/user";
import { MEMBERSHIP } from "../../common/constants";
import styled from "styled-components";
import {
  convertToDueString,
  convertToGood,
  convertToUseCondition,
} from "../../components/CouponList";

const EventCoupon = () => {
  const { hashCode } = useParams();
  const user = useSelector((state: RootUserState) => state.user);
  const nav = useNavigate();

  const [coupon, setCoupon] = useState<any>();
  const [userData, setUserDate] = useState<any>();
  const [validCheck, setValidCheck] = useState<boolean>(false);

  useEffect(() => {
    validChecker();
  }, []);

  //! Event & Checker
  const validChecker = async () => {
    //fetch
    const couponRes = await reqCouponByCode({ code: hashCode });
    setCoupon(couponRes.data);

    const userRes = await reqGetUserById({ id: user.id });
    setUserDate(userRes.data);

    // check
    const isLogin = loginChecker();
    const isValidCondition = validConditionChecker(
      userRes.data,
      couponRes.data
    );
    const isCouponDuplicate = await duplicateCouponChecker(couponRes.data);

    setValidCheck(isLogin && isValidCondition && isCouponDuplicate);
  };

  const loginChecker = () => {
    return user.id !== "";
  };

  const validConditionChecker = (userData: any, coupon: any) => {
    if (coupon?.target === MEMBERSHIP[0]) {
      return true;
    }
    if (coupon?.target !== MEMBERSHIP[userData?.membershipId]) {
      return false;
    }
    return true;
  };

  const duplicateCouponChecker = async (coupon: any) => {
    const res = await reqCouponDuplicateCheck({
      userId: user?.id,
      couponId: coupon?.id,
    });
    return !res.data;
  };

  const couponDownloader = () => {
    Swal.fire({
      title: "Do you want to get this coupon?",
      icon: "question",

      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      cancelButtonText: "No",

      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        // 쿠폰 지급
        await reqSendCoupon({ userIdList: [user.id], couponId: coupon.id });

        Swal.fire("Successful!", "", "success");
        nav("/customer");
      }
    });
  };

  //! Component
  const InvalidAlertPage = () => {
    return (
      <AlertTextBox>
        <PP className="title">Invalid Access</PP>
        <PP>Are you logged in?</PP>
        <PP>Or, please check if the coupon payment is appropriate.</PP>
        <PP>
          If you have already received this coupon, you cannot reissue it.
        </PP>

        <LoginRouteBtn onClick={() => nav("/login")}>Login</LoginRouteBtn>
      </AlertTextBox>
    );
  };

  const ValidCouponPage = () => {
    return (
      <AlertTextBox style={{ width: "80vw" }}>
        <PP className="title">Congratulations!</PP>
        <PP>You can get a coupon 😄</PP>
        <PP>Just click and get it!</PP>
        <div onClick={couponDownloader}>
          <Coupon />
        </div>
      </AlertTextBox>
    );
  };

  const Coupon = () => {
    return (
      <CouponCard>
        <CouponInfo>
          <div className="good">
            {convertToUseCondition(coupon.useCondition)}
            {convertToGood(
              coupon.discountType,
              coupon.percentDiscountPrice,
              coupon.krwDiscountPrice,
              coupon.usdDiscountPrice
            )}
          </div>
          <div className="name">{coupon.name}</div>
          <div className="limit">
            <div>
              {`More than ${coupon.krwLimitOfUsePrice?.toLocaleString(
                "ko-KR"
              )} ₩ available`}
            </div>
            <div>
              {`More than ${coupon.usdLimitOfUsePrice?.toLocaleString(
                "ko-KR"
              )} $ available`}
            </div>
          </div>
          <div className="due">{`${convertToDueString(
            coupon.validityType,
            coupon.validityDurationStart,
            coupon.validityDurationEnd,
            coupon.validityDate
          )}`}</div>
        </CouponInfo>

        <CouponLogo className="logo">ontact</CouponLogo>
      </CouponCard>
    );
  };

  return (
    <Wrapper>
      {validCheck ? (
        <>{coupon && <ValidCouponPage />}</>
      ) : (
        <>
          <InvalidAlertPage />
        </>
      )}
    </Wrapper>
  );
};

const Btn = styled.div`
  border-radius: 1rem;
  background-color: var(--color-main-blue);
  color: var(--color-white);
  font-weight: bold;
  text-align: center;
  padding: 0.4rem;

  &:hover {
    background-color: var(--color-main-blue-hover);
  }
`;

const LoginRouteBtn = styled(Btn)``;

const PP = styled.p`
  margin-bottom: 0;
`;
const AlertTextBox = styled.div`
  border: 1px solid lightgray;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem;
  font-size: 1.4rem;

  .title {
    font-weight: bold;
    text-align: center;
    font-size: 1.8rem;
  }
`;
const Wrapper = styled.div`
  display: flex;
  padding: 4rem;
  width: 100vw;
  height: 100vh;

  align-items: center;
  justify-content: center;
`;

//! Coupon

const CouponInfo = styled.div`
  flex: 7 1 0;
  padding: 1.4rem;
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  .good {
    font-weight: bold;
    font-size: 1.6rem;
    color: var(--color-main-blue);
  }
  .name {
    font-weight: bold;
    font-size: 1.2rem;
  }
  .due {
    color: var(--color-main-blue);
  }
`;
const CouponLogo = styled.div`
  background-color: var(--color-main-blue);
  color: var(--color-white);
  flex: 1 1 0;
  font-size: 1.8rem;
  font-weight: bold;
  writing-mode: vertical-rl;
  align-items: center;
  display: flex;
  justify-content: center;
`;
const CouponCard = styled.div`
  display: flex;
  border: 2px solid var(--color-main-blue);
  border-radius: 0.4rem;
  cursor: pointer;

  &:hover,
  &:active {
    background-color: #ffecdf;
  }

  /* 사용 불가 쿠폰 (제한 금액) */
  &.un-available {
    border: 2px solid lightgray;

    &:hover,
    &:active {
      background-color: white;
    }

    /* 자식 요소 스타일 변경 */
    * {
      color: lightgray !important;
    }

    .logo {
      background-color: lightgray;
      color: var(--color-white) !important;
    }

    .good,
    .due,
    .name {
      color: lightgray;
    }

    .limit div {
      color: var(--color-main-blue) !important;
    }
  }

  &.selected {
    background-color: #ffecdf;
  }
`;

export default EventCoupon;
