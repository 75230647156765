import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { reqGetUserById, reqUpdateUserMembership } from "../../requests/user";
import { useSelector } from "react-redux";
import { MEMBERSHIP, USE_CONDITION } from "../../common/constants";
import Icon from "../../components/Icon";
import { isMobile } from "react-device-detect";
import Swal from "sweetalert2";
import { reqMyHaveCoupon, reqUseCoupon } from "../../requests/coupon";
import { RootUserState } from "../../redux/store";
import {
  convertToDueString,
  convertToGood,
  convertToUseCondition,
} from "../../components/CouponList";
import { useNavigate } from "react-router-dom";
import CustomerPageTitle from "../../components/CustomerPageTitle";
import { reqPaypalPayment } from "../../requests/paypal";
import { User, initialUser } from "../../interface/User";
import { MembershipInfo } from "../../interface/MembershipInfo";
import CMembershipPayModal from "../../components/modal/CMembershipPayModal";

const Toast = Swal.mixin({
  toast: true,
  position: "center",
  showConfirmButton: false,
  timer: 2000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

const membershipInfo: MembershipInfo[] = [
  {
    id: 3,
    title: "Green",
    color: "var(--color-green)",
    subtitle: "Basic Membership",
    text: ["No storage cost for 30 days", "10% Custom order cost"],
    price: 0,
  },
  {
    id: 2,
    title: "Blue",
    color: "var(--color-main-blue)",
    subtitle: "3 Month Membership",
    text: [
      "No storage cost for 90 days",
      "3 free check content coupon",
      "25% Repack cost discount",
      "7% Custom order cost",
    ],
    price: 90,
  },
  {
    id: 1,
    title: "Red",
    color: "var(--color-main-red)",
    subtitle: "1 Year Membership",
    text: [
      "No storage cost for 365 days",
      "12 free check content coupon",
      "No Repack cost",
      "5% Custom order cost",
    ],
    price: 360,
  },
];

const CMembership = () => {
  const nav = useNavigate();

  //! user
  const user = useSelector((state: RootUserState) => state.user);
  const [userData, setUserData] = useState<User>(initialUser);
  const [payModal, setPayModal] = useState<boolean>(false);
  const [selectedMembershipInfo, setSelectedMembershipInfo] =
    useState<MembershipInfo>();

  const fetchUserData = async () => {
    const res = await reqGetUserById({ id: user.id });
    console.log(res.data);
    setUserData(res.data);
  };

  useEffect(() => {
    fetchUserData();
    내가_가진_멤버십_쿠폰_가져오기();
  }, []);

  //! Coupon Start
  const [availableCouponList, setAvailableCouponList] = useState<any>();

  const 내가_가진_멤버십_쿠폰_가져오기 = async () => {
    const res = await reqMyHaveCoupon({ userId: user.id });
    const myCouponDataList = res.data;
    const thisAvailableCouponList = myCouponDataList.filter(
      (couponData: any) =>
        couponData.coupon.useCondition === USE_CONDITION["MEMBERSHIP"]
    );

    setAvailableCouponList(thisAvailableCouponList);
    if (thisAvailableCouponList.length > 0) {
      Toast.fire({
        icon: "success",
        title: "There are coupons you can use!",
      });
    }
  };

  //! Component
  const Coupon = (coupon_: any) => {
    const coupon = coupon_.coupon;
    return (
      <CouponCard>
        <CouponInfo>
          <div className="good">
            {convertToUseCondition(coupon.useCondition)}
            {convertToGood(
              coupon.discountType,
              coupon.percentDiscountPrice,
              coupon.krwDiscountPrice,
              coupon.usdDiscountPrice
            )}
          </div>
          <div className="name">{coupon.name}</div>
          <div className="limit">
            <div>
              {`More than ${coupon.krwLimitOfUsePrice?.toLocaleString(
                "ko-KR"
              )} ₩ available`}
            </div>
            <div>
              {`More than ${coupon.usdLimitOfUsePrice?.toLocaleString(
                "ko-KR"
              )} $ available`}
            </div>
          </div>
          <div className="due">{`${convertToDueString(
            coupon.validityType,
            coupon.validityDurationStart,
            coupon.validityDurationEnd,
            coupon.validityDate
          )}`}</div>
        </CouponInfo>

        <CouponLogo className="logo">ontact</CouponLogo>
      </CouponCard>
    );
  };

  //! Event
  const handleUseCoupon = async (couponId: number) => {
    Swal.fire({
      title: "Do you want to use this coupon?",
      text: "",
      icon: "question",

      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      cancelButtonText: "no",

      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const nextMembership = getUpperMembership(userData.membershipId);
          const membershipPayload = {
            id: user.id,
            membershipId: nextMembership,
          };

          await reqUpdateUserMembership(membershipPayload);

          await reqUseCoupon({
            couponLogId: couponId,
            krwDiscounted: 0,
            usdDiscounted: 0,
          });

          Swal.fire(
            "Congratulations on your membership promotion!",
            "",
            "success"
          );
          nav("/customer");
        } catch (error) {}
      }
    });
  };

  const handleClickMembershipCard = async (membership: MembershipInfo) => {
    if (membership.id === 3) return;
    setSelectedMembershipInfo(membership);
    setPayModal(true);
  };

  //! Utils
  const getUpperMembership = (membershipId: number) => {
    const nextMembership = membershipId === 1 ? 1 : membershipId - 1;
    return nextMembership;
  };

  return (
    <>
      {/* 결제 모달 */}
      {payModal && (
        <CMembershipPayModal
          userData={userData}
          selectedMembership={selectedMembershipInfo as MembershipInfo}
          setPayModal={(flag: boolean) => setPayModal(flag)}
        />
      )}

      <MembershipContainer>
        {isMobile ? (
          <CustomerPageTitle title="Membership" />
        ) : (
          <CustomerPageTitle
            title="Membership"
          />
        )}

        <MembershipHeader>
          <P className="title">My Membership</P>
          <div className="icon-row">
            <IconWrapper color={`${MEMBERSHIP[userData.membershipId]}`}>
              <Icon icon="award" color="#fbf7f0" />
            </IconWrapper>
            {MEMBERSHIP[userData.membershipId]} Membership
          </div>
          <P className="date">
            {userData.membershipEndAt === null
              ? ""
              : `Until ${new Date(
                  userData.membershipEndAt as Date
                ).toLocaleString("ko-KR", {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                  hour12: false,
                })}`}
          </P>
        </MembershipHeader>

        <MembershipBody $isMobile={isMobile}>
          {membershipInfo.map((membership: MembershipInfo, index: number) => {
            return (
              <MembershipCard key={index} color={membership.color}>
                <div className="head">
                  <P className="title">{membership.title}</P>
                  <P className="subtitle">{membership.subtitle}</P>
                </div>

                <div className="body">
                  <div className="text-box">
                    <P>
                      <Icon icon="check-lg" fontSize="1.4rem" />
                      {membership.text[0]}
                    </P>

                    <P>
                      <Icon icon="check-lg" fontSize="1.4rem" />
                      {membership.text[1]}
                    </P>
                    {membership.text[2] ? (
                      <P>
                        <Icon icon="check-lg" fontSize="1.4rem" />
                        {membership.text[2]}
                      </P>
                    ) : (
                      <div>&nbsp;</div>
                    )}
                    {membership.text[3] ? (
                      <P>
                        <Icon icon="check-lg" fontSize="1.4rem" />
                        {membership.text[3]}
                      </P>
                    ) : (
                      <div>&nbsp;</div>
                    )}
                  </div>
                  <P className="price">{membership.price}$</P>
                </div>

                <div
                  className="membership-footer"
                  onClick={() => handleClickMembershipCard(membership)}
                >
                  Join Membership
                </div>
              </MembershipCard>
            );
          })}
        </MembershipBody>

        <div style={{ marginTop: "2rem" }}>
          {availableCouponList &&
            availableCouponList?.length > 0 &&
            availableCouponList.map((coupon: any, index: number) => {
              return (
                <div key={index} onClick={() => handleUseCoupon(coupon.id)}>
                  <Coupon coupon={coupon.coupon} />
                </div>
              );
            })}
        </div>
      </MembershipContainer>
    </>
  );
};

const P = styled.p`
  margin-bottom: 0;
`;
const MembershipContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.6rem;
`;
const MembershipHeader = styled.div`
  border: 1px solid lightgray;
  border-radius: 1rem;
  padding: 1.4rem;

  .title {
    font-size: 2rem;
    font-weight: bold;
  }
  .icon-row {
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    margin: 1rem 0;
  }
  .date {
    font-size: 1.2rem;
  }
`;

interface MobileViewer {
  isMobile: boolean;
}

const MembershipBody = styled.div<{ $isMobile: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.$isMobile ? "column" : "row")};
  gap: 1rem;
`;

const MembershipCard = styled.div<IconProps>`
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  padding: 1.2rem;
  border-radius: 1rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  background-color: #ffffff;
  margin: 1rem 0.2rem 0 0.2rem;

  &:hover {
    box-shadow: 0 10px 24px rgba(0, 0, 0, 0.2), 0 5px 5px rgba(0, 0, 0, 0.2);
  }

  .head {
    border-bottom: 1px solid lightgray;
    padding-bottom: 1rem;
    color: ${(props) => props.color};
    .title {
      font-weight: bold;
      font-size: 1.6rem;
    }
    .subtitle {
      font-size: 1.2rem;
    }
  }

  .body {
    border-bottom: 1px solid lightgray;
    padding: 1rem 0;
    font-size: 1.2rem;

    .text-box {
      i {
        margin-right: 0.4rem;
      }
    }
    .price {
      font-weight: bold;
      font-size: 1.6rem;
      padding: 1rem 0;
    }
  }

  .membership-footer {
    padding: 1rem 0 0 0;
    cursor: pointer;
    color: ${(props) => props.color};
  }
`;

interface IconProps {
  color: string;
}

const IconWrapper = styled.div<IconProps>`
  background-color: ${(props) => {
    switch (props.color) {
      case "Red":
        return "var(--color-main-red)";
      case "Green":
        return "var(--color-green)";
      case "Blue":
        return "var(--color-main-blue)";
      default:
        return "#ffffff";
    }
  }};
  padding: 0.6rem;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0;
  vertical-align: middle;
  margin-right: 1.4rem;
`;

//! Coupon

const CouponInfo = styled.div`
  flex: 7 1 0;
  padding: 1.4rem;
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  .good {
    font-weight: bold;
    font-size: 1.6rem;
    color: var(--color-main-blue);
  }
  .name {
    font-weight: bold;
    font-size: 1.2rem;
  }
  .due {
    color: var(--color-main-blue);
  }
`;
const CouponLogo = styled.div`
  background-color: var(--color-main-blue);
  color: var(--color-white);
  flex: 1 1 0;
  font-size: 1.8rem;
  font-weight: bold;
  writing-mode: vertical-rl;
  align-items: center;
  display: flex;
  justify-content: center;
`;
const CouponCard = styled.div`
  display: flex;
  border: 2px solid var(--color-main-blue);
  border-radius: 0.4rem;
  cursor: pointer;

  &:hover,
  &:active {
    background-color: #ffecdf;
  }

  /* 사용 불가 쿠폰 (제한 금액) */
  &.un-available {
    border: 2px solid lightgray;

    &:hover,
    &:active {
      background-color: white;
    }

    /* 자식 요소 스타일 변경 */
    * {
      color: lightgray !important;
    }

    .logo {
      background-color: lightgray;
      color: var(--color-white) !important;
    }

    .good,
    .due,
    .name {
      color: lightgray;
    }

    .limit div {
      color: var(--color-main-blue) !important;
    }
  }

  &.selected {
    background-color: #ffecdf;
  }
`;

export default CMembership;
