import axios, { AxiosInstance } from "axios";
import { store } from "./redux/store";
import { CONST_COOKIE, getCookie } from "./common/cookies";
import { ROUTE_URL } from "./common/constants";
import { modalClose, modalOpen } from "./redux/modalSlice";
import { MODAL_TYPE } from "./components/modal/GlobalModal";
import { loadingClose } from "./redux/loadingSlice";
import { tokenSave } from "./redux/tokenSlice";
import { reqRefreshAccessToken } from "./requests/auth";
import { reqAdminRefreshAccessToken } from "./requests/admin-auth";
import { adminSave } from "./redux/adminSlice";
// import { reqRefreshAccessToken } from "./requests/auth";
// import { tokenReducer, tokenSlice } from "./reducers/slices/token.slice";
// import { CODE_COOKIE, ROUT_URL } from "./common/contants";
// import { loadingSlice } from "./reducers/slices/loading.slice";
// import { modalClose, modalOpen } from "./reducers/slices/modal.slice";
// import { MODAL_TYPE } from "./components/modals/GlobalModal";

export const AxiosManager: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 100000,
  headers: {},
});

// req 이전 모든 리퀘스트에 토큰 포함하여 전송
AxiosManager.interceptors.request.use(
  (config) => {
    const accessToken = store.getState().token.accessToken;
    const refreshToken = getCookie(CONST_COOKIE.REFRESH_TOKEN);
    const adminAccess = store.getState().admin.accessToken;
    const adminRefresh = getCookie(CONST_COOKIE.ADMIN_REFRESH);

    config.headers["Access-Token"] = accessToken;
    config.headers["Refresh-Token"] = refreshToken;

    config.headers["Admin-Access-Token"] = adminAccess;
    config.headers["Admin-Refresh-Token"] = adminRefresh;

    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

// res 이후 모든 리스폰스의 응답에 따른 일괄 처리
AxiosManager.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    if (error.response && error.response.status) {
      const originalConfig = error.config;
      store.dispatch(loadingClose());

      switch (error.response.data.code) {
        // status code가 401인 경우 `logout`을 커밋하고 `/login` 페이지로 리다이렉트

        case 40101: // 액세스 토큰 만료
          // refresh token으로 access token 갱신 to BE 후 API 재요청
          const refreshToken = getCookie(CONST_COOKIE.REFRESH_TOKEN);
          const result = await reqRefreshAccessToken(refreshToken);
          store.dispatch(tokenSave(result.data));
          return AxiosManager.request(originalConfig);
        case 40102: // 리프레시 토큰 만료
          return new Promise(() => {});

        case 40103: // 어드민 액세스 토큰 만료
          const adminRefreshToken = getCookie(CONST_COOKIE.ADMIN_REFRESH);
          const resultAdmin = await reqAdminRefreshAccessToken(
            adminRefreshToken
          );
          store.dispatch(adminSave(resultAdmin.data));
          return AxiosManager.request(originalConfig);
        case 40104: // 어드민 리프레시 토큰 만료
          return new Promise(() => {});

        case 40331: // 이용중지 유저
          store.dispatch(
            modalOpen({
              modalType: MODAL_TYPE.INFO,
              titel: "Alert",
              payload: { text: "Blocked Account" },
            })
          );
          window.location.href = ROUTE_URL.LOGIN;
          return new Promise(() => {});
        case 40401:
          // store.dispatch(modalClose());
          // setTimeout(() => {
          //   store.dispatch(
          //     modalOpen({
          //       title: "삭제된 데이터",
          //       modalType: MODAL_TYPE.INFO_NOTFOUND,
          //     })
          //   );
          // }, 10);
          // window.history.back();

          // alert("내부 서버 에러\n잠시후 다시 시도해 주십시오");
          // store.commit("auth/logout");
          //router.push("/login").catch(() => {});
          // 이행되지 않는 Promise를 반환하여 Promise Chaining 끊어주기
          //window.location.href = "/auth/login";
          return new Promise(() => {});
        case 50099:
          store.dispatch(modalClose());
          setTimeout(() => {
            store.dispatch(
              modalOpen({
                title: "Error",
                modalType: MODAL_TYPE.INFO,
                payload: {
                  text: "Oops, something went wrong.<br/>Please try again later.",
                },
              })
            );
          }, 10);

          // alert("내부 서버 에러\n잠시후 다시 시도해 주십시오");
          // store.commit("auth/logout");
          //router.push("/login").catch(() => {});
          // 이행되지 않는 Promise를 반환하여 Promise Chaining 끊어주기
          //window.location.href = "/auth/login";
          return new Promise(() => {});
        default:
          return Promise.reject(error);
      }
    }
    if (error.code === "ERR_NETWORK") {
      //네트워크 에러
      store.dispatch(modalClose());
      setTimeout(() => {
        store.dispatch(
          modalOpen({
            title: "Unable to connect",
            modalType: MODAL_TYPE.INFO,
            payload: {
              text: "Poor network connection detected.<br/>Please check your connectivity.",
            },
          })
        );
      }, 10);
      return new Promise(() => {});
    }
    return Promise.reject(error);
  }
);
