export interface User {
  id: string;
  recommandedPerson?: string | null;
  oldid?: string | null;
  saveid?: string | null;
  email: string;
  password: string;
  passwordError: number;
  pccc?: string | null;
  firstName: string;
  lastName: string;
  name: string;
  phone?: string | null;
  international?: string | null;
  membershipId: number;
  membership?: Membership;
  membershipEndAt?: Date | null;
  point: number;
  pointUsd: number;
  country: number;
  latestWarehouseId?: string | null;
  imageUrl?: string | null;
  address?: UserAddress[];
  refreshToken?: string | null;
  agreeMarketing: boolean;
  Check: boolean;
  favorite?: string | null;
  inflow: number;
  status: number;
  createdAt: Date;
  updatedAt: Date;
  deletedAt?: Date | null;
}

interface Membership {
  id: number;
}

interface UserAddress {
  userId: string;
}

export const initialUser: User = {
  id: "",
  recommandedPerson: null,
  oldid: null,
  saveid: "",
  email: "",
  password: "",
  passwordError: 0,
  pccc: null,
  firstName: "",
  lastName: "",
  name: "",
  phone: null,
  international: null,
  membershipId: 0,
  membershipEndAt: null,
  point: 0,
  pointUsd: 0,
  country: 0,
  latestWarehouseId: null,
  imageUrl: null,
  refreshToken: null,
  agreeMarketing: false,
  Check: false,
  favorite: null,
  inflow: 0,
  status: 0,
  createdAt: new Date(),
  updatedAt: new Date(),
  deletedAt: null,
};
