import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { reqAShop } from "../../../requests/shop";
import CShopOrderPreorderModal from "../../../components/modal/CShopOrderPreorderModal";
import {
  CODE_SHOPING_ORDER_STATUS,
  CODE_SHOPING_PAYMENT_STATUS,
} from "../../../common/constants";
import { RootUserState } from "../../../redux/store";
import styled from "styled-components";
import { isMobile } from "react-device-detect";
import { useLocation } from "react-router-dom";
import { formatDate } from '../../../common/format';
type OrderData = {
  orderId: string;
  userId: string;
  createdAt: string;
  paymentDeadline: string;
  preorder: boolean;
  totalPriceKRW: number;
  totalPriceUSD: number;
  PaymentMethod: number;
  status: number;
};

const CShopPaid: React.FC = () => {
  const [showPreorderModal, setShowPreorderModal] = useState(false);
  const [currentOrder, setCurrentOrder] = useState<OrderData | null>(null);
  const [orders, setOrders] = useState<OrderData[]>([]);
  const user = useSelector((state: RootUserState) => state.user);

  useEffect(() => {
    fetchOrders();
  }, [user?.id]);

  const fetchOrders = async () => {
    try {
      const response = await reqAShop();
      const filteredOrders = response.data.filter(
        (order: OrderData) =>
          order.userId === user.id &&
          [
            CODE_SHOPING_ORDER_STATUS.PRODUCT_PART,
            CODE_SHOPING_ORDER_STATUS.PRODUCT_COMPLETION,
          ].includes(order.status)
      );
      setOrders(filteredOrders);
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };

  const handleOrderClick = (order: OrderData) => {
    setCurrentOrder(order);
    setShowPreorderModal(true);
  };

  const refreshData = () => {
    fetchOrders();
  };

  const MobileView = () => {
    return (
      <MobileWrapper>
        <Title>Processing Orders</Title>
        <Box>
          {orders &&
            orders.map((order, index) => (
              <div
                key={order.orderId}
                onClick={() => handleOrderClick(order)}
                style={{
                  cursor: "pointer",
                  borderBottom: "1px solid lightgray",
                  paddingBottom: "1rem",
                  paddingTop: "1rem",
                }}
              >
                <Row>
                  <Label>Id</Label>
                  <P>{index + 1}</P>
                </Row>

                <Row>
                  <Label>Order Number</Label>
                  <P>{order.orderId}</P>
                </Row>

                <Row>
                  <Label>Order date</Label>
                  <P>{formatDate(order.createdAt)}</P>
                </Row>

                <Row>
                  <Label>Closing of payment</Label>
                  <P>{formatDate(order.paymentDeadline)}</P>
                </Row>

                <Row>
                  <Label>Price(KRW)</Label>
                  <P>{order.totalPriceKRW} KRW</P>
                </Row>

                <Row>
                  <Label>Price(USD)</Label>
                  <P>{order.totalPriceUSD} USD</P>
                </Row>

                <Row>
                  <Label>Status</Label>
                  <P>{CODE_SHOPING_PAYMENT_STATUS[order.status]}</P>
                </Row>
              </div>
            ))}
        </Box>
      </MobileWrapper>
    );
  };

  return (
    <>
      {isMobile ? (
        <MobileView />
      ) : (
        <main id="main" className="main">
          <div className="pagetitle">
            <h1>Processing Orders</h1>
          </div>
          <section className="container-fluid" id="about">
            <div className="container-fluid">
              <table className="table text-center">
                <thead>
                  <tr translate="no">
                    <th scope="col">#</th>
                    <th scope="col">Order Number</th>
                    <th scope="col">Order date</th>
                    <th scope="col">Closing of payment</th>
                    <th scope="col">Price(KRW)</th>
                    <th scope="col">Price(USD)</th>
                    <th scope="col">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {orders.map((order, index) => (
                    <tr
                      key={order.orderId}
                      onClick={() => handleOrderClick(order)}
                      style={{ cursor: "pointer" }}
                    >
                      <td>{index + 1}</td>
                      <td>{order.orderId}</td>
                      <td>{formatDate(order.createdAt)}</td>
                      <td>{formatDate(order.paymentDeadline)}</td>
                      <td>{order.totalPriceKRW} KRW</td>
                      <td>{order.totalPriceUSD} USD</td>
                      <td>{CODE_SHOPING_PAYMENT_STATUS[order.status]}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </section>
        </main>
      )}

      {currentOrder && (
        <CShopOrderPreorderModal
          show={showPreorderModal}
          onHide={() => setShowPreorderModal(false)}
          orderId={currentOrder.orderId}
          refreshData={refreshData}
        />
      )}
    </>
  );
};

export default CShopPaid;

const Title = styled.h3`
  border-bottom: 1px solid lightgray;
  padding-bottom: 1rem;
  margin-top: 1rem;
`;
const FeeBoxRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  & > div {
    flex: 1 0 0;
    text-align: center;
    align-items: center;
    border: 1px solid lightgray;
    padding: 0.4rem 0;
  }
  margin: 0.6rem 0;
`;
const TotalFeeBoxRow = styled(FeeBoxRow)`
  background-color: #474747;
  color: white;
`;

const MobileWrapper = styled.div`
  margin: 0;
  padding: 1rem;
`;

const MBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
  align-items: center;
`;
const Col = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  font-weight: bold;
  margin: 0.4rem 0 0.4rem 0;
`;
const P = styled.p`
  margin: 0.4rem 0 0.4rem 0;
`;
