import { useSelector } from "react-redux";

export default function ModalInfo() {
  const modal = useSelector((state: any) => state.modal);

  return (
    <div style={{ margin: "1rem 0" }}>
      <div dangerouslySetInnerHTML={{ __html: modal.payload?.text }}></div>
    </div>
  );
}
