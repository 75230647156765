import { FormEvent, useState, useEffect } from "react";
import { Button, Form, Table, Alert } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Swal from "sweetalert2";
import { ADMIN_BADGE } from "../../common/constants";
import { modalOpen, modalClose } from "../../redux/modalSlice";
import { reqShipFEE } from "../../requests/ship";
import { RootUserState } from "../../redux/store";
import { reqAddAdminBadge } from "../../requests/badge";
import { reqPaypalPayment } from "../../requests/paypal";
import { reqShipProductBox } from "../../requests/ship";
import {
  reqAddProductSplit,
  reqUploadProductSplitImage,
} from "../../requests/split";
import { reqPayment } from "../../requests/payment";
import { processAfterPayment } from "../../views/Customer/CPaypalResult";
interface ShipProductBoxPayload {
  tracking: string;
  status: number;
}

const ALPHABET = "ABCDEFGHJKLMNOPQRSTUVWXYZ";

export default function StepByStepPage() {
  const dispatch = useDispatch();
  const modal = useSelector((state: any) => state.modal);
  const [step, setStep] = useState(1);
  const [boxCount, setBoxCount] = useState<number>(1);
  const [splitData, setSplitData] = useState<string[]>(Array(10).fill(""));
  const [agreeCheck, setAgreeCheck] = useState<boolean>(false);
  const [files, setFiles] = useState<File[]>(
    Array(10).fill(null as unknown as File)
  );
  const user = useSelector((state: RootUserState) => state.user);
  const checkedItems = modal.payload.list || [];

  const handleFirstStepSubmit = async (event: FormEvent) => {
    event.preventDefault();
    if (!agreeCheck) {
      alert("Please agree to the terms.");
      return;
    }

    const totalUsd = checkedItems.reduce((acc: number, v: any) => {
      return (
        acc + parseFloat(v.overstoreFee) + parseFloat(convertToUSD(v.Extra))
      );
    }, 0);

    if (totalUsd === 0) {
      setStep(2);
      return;
    }

    const shipProductBoxPayload = checkedItems.map((item: any) => ({
      tracking: item.tracking,
      overstoreFee: 0,
      Extra: 0,
    }));

    const paymentPayload = {
      type: "split",
      userId: user.id,
      paymentAmount: totalUsd.toFixed(2),
    };

    try {
      const res = await reqPaypalPayment({
        storeid: "ontactkorea",
        currency: "USD",
        storeorderno: `split-${new Date().getTime().toString()}`,
        amount: parseFloat(totalUsd),
        payerid: `ontactKorea-${user.id}`,
        payeremail: "ontactkorea@gmail.com",
        returnurl: `${process.env.REACT_APP_BASE_URL}paypal/handle/result`,
        notiurl: `${process.env.REACT_APP_BASE_URL}paypal/handle/notify`,
        custom: JSON.stringify({
          reqShipProductBox: shipProductBoxPayload,
          reqPayment: paymentPayload,
        }),
        directreturnflag: "Y",
      });

      if (!res) {
        alert("Paypal API Error");
        return;
      }

      const { online_url, mobile_url } = res.data;
      window.location.replace(isMobile ? mobile_url : online_url);
      dispatch(modalClose());
      return;
    } catch (error) {
      console.error("Error during PayPal payment:", error);
      alert("An error occurred during payment processing.");
    }
  };

  const handleFinalSubmit = async (event: FormEvent) => {
    event.preventDefault();

    dispatch(modalClose());
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "Do you want to proceed with the payment?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, proceed!",
    });

    if (result.isConfirmed) {
      await processAfterPayment(checkedItems, user, boxCount, splitData, files);
      Swal.fire({
        title: "Payment Success",
        text: "Thank you for using our service!",
        icon: "success",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Back to previous page",
      }).then((result: any) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      });
    }
  };

  const handleFileChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files) {
      const newFiles = [...files];
      newFiles[index] = event.target.files[0];
      setFiles(newFiles);
    }
  };

  const handleSplitDataChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newData = [...splitData];
    newData[index] = event.target.value;
    setSplitData(newData);
  };

  const [fixedExchangeRate, setFixedExchangeRate] = useState(0);

  const fetchFixedExchangeRate = async () => {
    try {
      const response = await reqShipFEE();
      if (
        response &&
        response.data &&
        response.data[0]?.fixedExchangeRateShop
      ) {
        setFixedExchangeRate(
          parseFloat(response.data[0].fixedExchangeRateShop)
        );
      }
    } catch (error) {
      console.error("Error fetching fixed exchange rate:", error);
    }
  };

  useEffect(() => {
    fetchFixedExchangeRate();
  }, []);

  const [exchangeRate, setExchangeRate] = useState<number>(0);

  const fetchExchangeRate = async () => {
    try {
      const response = await reqShipFEE();
      const fixedExchangeRate = response.data[0]?.fixedExchangeRate;
      if (fixedExchangeRate) {
        setExchangeRate(parseFloat(fixedExchangeRate));
      } else {
        console.error("Failed to fetch fixed exchange rate: No data");
      }
    } catch (error) {
      console.error("Error fetching fixed exchange rate:", error);
    }
  };

  useEffect(() => {
    fetchExchangeRate();
  }, []);

  const convertToUSD = (krw: number) => (krw / exchangeRate).toFixed(2);

  return (
    <div>
      {step === 1 && (
        <Form onSubmit={handleFirstStepSubmit}>
          <Table bordered hover responsive className="text-center">
            <thead>
              <tr>
                <th>Tracking number</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {checkedItems?.map((v: any, idx: number) => (
                <tr key={idx}>
                  <td>{v.tracking}</td>
                  <td>
                    $
                    {(
                      parseFloat(v.overstoreFee) +
                      parseFloat(convertToUSD(v.Extra))
                    ).toFixed(2)}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <PayCol>
            <p>Split Fee: $5 per box</p>
            <Form.Check
              type="checkbox"
              label="I agree to the split fee and terms"
              checked={agreeCheck}
              onChange={() => setAgreeCheck(!agreeCheck)}
            />
          </PayCol>
          <PayCol>
            <Alert variant="warning">
              Split service is $5 per final split box. Items that were not
              declared in the split data process will be put in the last box.
              <th></th>
              For smooth service, please input the split guidelines as detailed
              as possible. Split service is not an inclusion service meaning you
              can't ask for an album to be opened and photocards to be taken
              out. If there are additional charges, please complete the payment
              first to proceed with the split. Once the split request is sent,
              it can take up to 2 business day to be completed.
            </Alert>
          </PayCol>
          <Row>
            <Button variant="primary" type="submit">
              Next
            </Button>
          </Row>
        </Form>
      )}
      {step === 2 && (
        <Form onSubmit={handleFinalSubmit}>
          <FormGroup controlId="formBoxCount">
            <Form.Label>Number of boxes (max 10)</Form.Label>
            <Form.Control
              as="select"
              value={boxCount}
              onChange={(e) => setBoxCount(parseInt(e.target.value))}
              required
            >
              {Array.from({ length: 10 }, (_, index) => index + 1).map(
                (num) => (
                  <option key={num} value={num}>
                    {num}
                  </option>
                )
              )}
            </Form.Control>
          </FormGroup>
          {[...Array(boxCount)].map((_, idx) => (
            <div
              key={idx}
              style={{
                border: "1px solid gray",
                padding: "10px",
                marginBottom: "10px",
              }}
            >
              <h4>Box {idx + 1}</h4>
              <h5>
                Tracking number:{" "}
                {`${checkedItems[0].tracking}-${ALPHABET[idx]}`}
              </h5>
              <Form.Group controlId={`formSplitData-${idx}`}>
                <Form.Label>Split Data</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={5}
                  placeholder="Enter split data"
                  value={splitData[idx]}
                  onChange={(e) =>
                    handleSplitDataChange(
                      idx,
                      e as React.ChangeEvent<
                        HTMLInputElement | HTMLTextAreaElement
                      >
                    )
                  }
                  required
                />
              </Form.Group>
              <Form.Group controlId={`formFileUpload-${idx}`}>
                <Form.Label>Attach Image</Form.Label>
                <Form.Control
                  type="file"
                  onChange={(e) =>
                    handleFileChange(
                      idx,
                      e as React.ChangeEvent<HTMLInputElement>
                    )
                  }
                />
              </Form.Group>
            </div>
          ))}
          <Row>
            <Button
              variant="primary"
              type="submit"
              style={{
                marginTop: "20px",
                marginBottom: "20px",
                marginLeft: "auto",
              }}
            >
              Submit
            </Button>
          </Row>
        </Form>
      )}
    </div>
  );
}

//! Payment Start
const PayCol = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border-bottom: 1px solid lightgray;
  gap: 0.4rem;
  .title {
    font-weight: bold;
    font-size: 1.2rem;
  }
  .label {
    font-weight: bold;
    margin-bottom: 0;
  }

  & p {
    margin-bottom: 0;
  }
`;
const SpaceRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .order-total {
    color: var(--color-main-blue);
    font-weight: bold;
  }
`;

//! Payment End

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;

  & button {
    flex: 1 1 0;
    margin-top: 1rem;
  }
`;

const FormGroup = styled(Form.Group)`
  margin-bottom: 1rem; // 간격 추가
`;
