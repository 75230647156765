import { Button, Table } from "react-bootstrap";
import Title from "../../components/Title";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { modalOpen } from "../../redux/modalSlice";
import { MODAL_TYPE } from "../../components/modal/GlobalModal";
import { useSelector } from "react-redux";
import { reqGetAdminAuth } from "../../requests/admin-auth";
import FormatData from "../../components/FormatData";
import { DATA_TYPE, DATE_FORMAT } from "../../common/constants";
import AdminHeadTitle from "../../components/AdminHeadTitle";

export default function ARegister() {
  const dispatch = useDispatch();
  const modal = useSelector((state: any) => state.modal);
  const [data, setData] = useState<any[]>([]);

  useEffect(() => {
    const rf = async () => {
      const result = await reqGetAdminAuth();
      setData(result.data);
    };
    rf();
  }, [modal.refresh]);

  return (
    <>
      <AdminHeadTitle
        subtitle1="최고 관리자"
        subtitle2="관리자 계정 관리"
        title="관리자 계정 관리"
      />
      <div
        style={{ height: "500px", overflowY: "scroll", marginBottom: "30px" }}
      >
        <Table>
          <thead>
            <tr>
              <th style={{ width: "70px" }}>번호</th>
              <th>이름</th>
              <th>아이디</th>
              <th>등록일자</th>
              <th>마지막 수정일자</th>
              <th>권한</th>
            </tr>
          </thead>
          <tbody>
            {data.map((v) => {
              return (
                <>
                  <tr
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      dispatch(
                        modalOpen({
                          modalType: MODAL_TYPE.ADMIN_REGISTER_DETAIL,
                          title: "계정 정보",
                          payload: { data: v },
                        })
                      );
                    }}
                  >
                    <td>{v.id}</td>
                    <td>{v.name}</td>
                    <td>{v.email}</td>
                    <td>{FormatData(DATA_TYPE.DATE, v.createdAt)}</td>
                    <td>{FormatData(DATA_TYPE.DATE, v.updatedAt)}</td>
                    <td>{FormatData(DATA_TYPE.AUTH_ROLE, Number(v.role))}</td>
                  </tr>
                </>
              );
            })}
          </tbody>
        </Table>
      </div>
      <div className="d-flex justify-content-end">
        <Button
          variant="dark"
          onClick={(e) => {
            dispatch(
              modalOpen({
                modalType: MODAL_TYPE.ADMIN_REGISTER,
                title: "관리자 계정 추가",
              })
            );
          }}
        >
          관리자 계정 추가
        </Button>
      </div>
    </>
  );
}
