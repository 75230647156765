import axios from "axios";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
import {
  CODE_COUNTRY_ITEMS,
  CODE_QNA_K,
  AUTO_RECEIVE_CONDITION_KR,
} from "../../../common/constants";
import { formatDate } from "../../../common/format";
import { reqAllCouponList, reqAllPoint } from "../../../requests/coupon";
import { reqALlReview } from "../../../requests/review";
import { reqAllShip, reqAShip } from "../../../requests/ship";
import { reqAShop } from "../../../requests/shop";
import {
  reqQuestionAndResponse,
  reqUpdateQuestionAndResponse,
} from "../../../requests/user";
import { reqAllProductSplit } from "../../../requests/split";
import ShopDetailsModal from "../../../components/modal/ShopDetailsModal";
import AShipDetailModal from "../../../components/modal/AShipDetailModal";

const PAYMENT_METHODS: { [key: number]: string } = {
  1: "통장",
  2: "USD",
};

const COMPANY_NAMES: { [key: number]: string } = {
  1: "FEDEX",
  2: "UPS",
  3: "EMS",
  4: "K-PACKET",
  5: "CJ",
};

const MEMBERSHIP: { [key: number]: string } = {
  1: "Red",
  2: "Blue",
  3: "Green",
};

interface Order {
  id: string;
  manager: string | null;
  userId: string;
  createdAt: string;
  paidAt: string | null;
  PaymentMethod: number | null;
  company: number | null;
  snKrwRealFee: number | null;
  snUsdRealFee: number | null;
  status: number;
}

interface ShippingOrder {
  id: string;
  manager: string | null;
  userId: string;
  createdAt: string;
  expiredAt: string;
  PaymentMethod: number | null;
  DepositorFee: string | null;
  status: number;
  company: number | null;
}

interface User {
  id: string;
  name: string;
  country: number;
  createdAt: string;
  membershipId: number;
  membershipEndAt: string;
}

interface Coupon {
  id: number;
  userId: string;
  couponId: number;
  used: boolean;
  expired: boolean;
  createdAt: string;
  expiredAt: string;
  usedAt: string | null;
  krwDiscounted: number;
  usdDiscounted: number;
  orderId: string | null;
  manager: string | null;
  coupon: {
    name: string;
    autoReceiveCondition: string;
  };
}

interface PointLog {
  id: number;
  userId: string;
  used: boolean;
  grant: boolean;
  createdAt: string;
  krwUsedPoint: number;
  usdUsedPoint: string;
  krwGrantPoint: number;
  usdGrantPoint: string;
  status: number;
  manager: string | null;
}

interface Review {
  id: number;
  rating: number;
  content: string;
  createdAt: string;
  photoUrls: string;
  response: string;
  orderId: string;
  view: number;
  status: string;
  manager: string;
  userId: string;
}

interface QuestionResponse {
  id: number;
  userId: string;
  userName: string;
  userType: boolean;
  categoryId: number;
  email: string;
  title: string;
  questionContent: string;
  responseContent: string;
  status: number;
  manager: string;
  createdAt: string;
  updatedAt: string;
  responseAt: string;
}

interface Response {
  id: number;
  title: string;
  userType: boolean;
  userName: string;
  categoryId: number;
  questionContent: string;
  createdAt: string;
  responseAt?: string;
  responseContent: string;
  status: number;
  email: string;
  userId: string;
  manager: string;
}

interface Split {
  id: number;
  tracking: string;
  split: string;
  manager: string;
  userId: string;
  status: number;
  content: string;
  imageUrl: string;
  paypalTransactionId: string | null;
  paypalPayAmount: number;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  shipProduct: string | null;
}

const CustomerDetail: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState<string>("구매대행");
  const [purchaseData, setPurchaseData] = useState<Order[]>([]);
  const [shippingData, setShippingData] = useState<ShippingOrder[]>([]);
  const [filteredData, setFilteredData] = useState<Order[]>([]);
  const [filteredShippingData, setFilteredShippingData] = useState<
    ShippingOrder[]
  >([]);
  const [couponData, setCouponData] = useState<Coupon[]>([]);
  const [pointData, setPointData] = useState<PointLog[]>([]);
  const [reviewData, setReviewData] = useState<Review[]>([]);
  const [questionData, setQuestionData] = useState<QuestionResponse[]>([]);
  const [userData, setUserData] = useState<User | null>(null);
  const [splitData, setSplitData] = useState<Split[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("JK0000TEST");
  const [checkContentData, setCheckContentData] = useState<any[]>([]);
  const [inboundCount, setInboundCount] = useState<number>(0);
  const [outboundCount, setOutboundCount] = useState<number>(0);
  const [discardedCount, setDiscardedCount] = useState<number>(0);
  const [storedCount, setStoredCount] = useState<number>(0);
  const [responses, setResponses] = useState<Response[]>([]);
  const [checkContentPaymentCount, setCheckContentPaymentCount] =
    useState<number>(0);
  const [splitContentPaymentCount, setSplitContentPaymentCount] =
    useState<number>(0);
  const [purchaseCount, setPurchaseCount] = useState<number>(0);
  const [shippingCount, setShippingCount] = useState<number>(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const purchaseResponse = await reqAShop();
        const shippingResponse = await reqAllShip();
        const ashipResponse = await reqAShip();
        const couponResponse = await reqAllCouponList();
        const pointResponse = await reqAllPoint();
        const reviewResponse = await reqALlReview({});
        const questionResponse = await reqQuestionAndResponse();
        const splitResponse = await reqAllProductSplit({ userId: searchTerm });

        const purchaseOrders: Order[] = purchaseResponse.data.map(
          (order: any) => ({
            id: order.orderId,
            manager: order.productId,
            userId: order.userId,
            createdAt: order.createdAt,
            paidAt: order.fancallBirthday,
            PaymentMethod: order.PaymentMethod,
            company: null,
            snKrwRealFee: order.snKrwRealFee,
            snUsdRealFee: order.snUsdRealFee,
            status: order.status,
          })
        );

        const shippingOrders: ShippingOrder[] = shippingResponse.data.map(
          (order: any) => ({
            id: order.id,
            manager: order.manager,
            userId: order.userId,
            createdAt: order.createdAt,
            expiredAt: order.expiredAt,
            PaymentMethod: order.PaymentMethod,
            DepositorFee: order.DepositorFee,
            status: order.status,
            company: order.company,
          })
        );

        setPurchaseData(purchaseOrders);
        setShippingData(shippingOrders);
        setCouponData(
          couponResponse.data.filter(
            (coupon: Coupon) => coupon.userId === searchTerm
          )
        );
        setPointData(
          pointResponse.data.filter(
            (point: PointLog) => point.userId === searchTerm
          )
        );
        setReviewData(
          reviewResponse.data.reviewList.filter(
            (review: Review) => review.userId === searchTerm
          )
        );

        setFilteredData(
          purchaseOrders.filter((order) => order.userId === searchTerm)
        );

        setFilteredShippingData(
          shippingOrders.filter((order) => order.userId === searchTerm)
        );

        const userResponse = await axios.get(
          `${process.env.REACT_APP_BASE_URL}user/${searchTerm}`
        );
        setUserData(userResponse.data);

        const userProducts = ashipResponse.data.filter(
          (product: any) => product.userId === searchTerm
        );

        const inboundCount = userProducts.length;
        const outboundCount = userProducts.filter(
          (product: any) => product.status === 6
        ).length;
        const discardedCount = userProducts.filter(
          (product: any) => product.status === 20
        ).length;
        const storedCount = userProducts.filter((product: any) =>
          [1, 2, 3, 4, 5].includes(product.status)
        ).length;
        const checkContentPaymentCount = userProducts.filter(
          (product: any) => product.check
        ).length;

        setInboundCount(inboundCount);
        setOutboundCount(outboundCount);
        setDiscardedCount(discardedCount);
        setStoredCount(storedCount);
        setCheckContentPaymentCount(checkContentPaymentCount);
        setCheckContentData(
          userProducts.filter((product: any) => product.check)
        );

        setQuestionData(
          questionResponse.data.filter(
            (question: QuestionResponse) => question.userId === searchTerm
          )
        );

        setSplitData(
          splitResponse.data.filter(
            (split: Split) => split.userId === searchTerm
          )
        );

        const completedSplitCount = splitResponse.data.filter(
          (split: any) => split.status === 2 && split.userId === searchTerm
        ).length;
        setSplitContentPaymentCount(completedSplitCount);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };
    fetchData();
  }, [searchTerm]);

  useEffect(() => {
    const countPurchase = purchaseData.filter(
      (order) =>
        (order.status === 4 || order.status === 6) &&
        order.userId === searchTerm
    ).length;
    setPurchaseCount(countPurchase);

    const countShipping = shippingData.filter(
      (order) =>
        (order.status === 6 ||
          order.status === 7 ||
          order.status === 8 ||
          order.status === 9) &&
        order.userId === searchTerm
    ).length;
    setShippingCount(countShipping);
  }, [purchaseData, shippingData, searchTerm]);

  const handleTabClick = (tab: string) => {
    setSelectedTab(tab);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value.toUpperCase());
  };

  const getTotalAmount = (
    orders: Order[],
    currency: string,
    userId: string
  ) => {
    return orders
      .filter(
        (order) =>
          order.userId === userId &&
          order.PaymentMethod === (currency === "KRW" ? 1 : 2)
      )
      .reduce((total, order) => {
        if (currency === "KRW") {
          return total + (order.snKrwRealFee ?? 0);
        } else {
          return total + (order.snUsdRealFee ?? 0);
        }
      }, 0);
  };

  const getTotalShippingAmount = (
    orders: ShippingOrder[],
    currency: string,
    userId: string
  ): number => {
    return orders
      .filter(
        (order) =>
          order.userId === userId &&
          order.PaymentMethod === (currency === "KRW" ? 1 : 2)
      )
      .reduce((total, order) => {
        const fee = parseFloat(order.DepositorFee ?? "0");
        return total + (isNaN(fee) ? 0 : fee);
      }, 0);
  };

  const getSourceText = (status: number) => {
    switch (status) {
      case 1:
        return "Custom Order";
      case 2:
        return "Shipments";
      case 3:
        return "Review";
      case 4:
        return "Manager";
      case 5:
        return "Refund";
      default:
        return "Unknown";
    }
  };

  const getPaymentMethodText = (paymentMethod: number) => {
    switch (paymentMethod) {
      case 1:
        return "쿠폰";
      case 2:
        return "페이레터";
      default:
        return "Unknown";
    }
  };

  const getAutoReceiveConditionText = (condition: string) => {
    return (
      AUTO_RECEIVE_CONDITION_KR[
        condition as keyof typeof AUTO_RECEIVE_CONDITION_KR
      ] || "Unknown condition"
    );
  };

  const handleDeleteClick = async (responseId: number) => {
    Swal.fire({
      title: "정말 삭제하시겠습니까?",
      text: "삭제된 후에는 복구할 수 없습니다.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "삭제",
      cancelButtonText: "취소",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await reqUpdateQuestionAndResponse(responseId.toString(), {
            userType: true,
          });
          setResponses(responses.filter((item) => item.id !== responseId));
          Swal.fire("삭제 완료", "해당 응답이 삭제되었습니다.", "success");
        } catch (error) {
          console.error("Failed to delete response:", error);
          Swal.fire("삭제 실패", "해당 응답을 삭제하지 못했습니다.", "error");
        }
      }
    });
  };

  const getQuestionType = (categoryId: number) => {
    return CODE_QNA_K[categoryId] || "Unknown";
  };

  // 구매대행 모달
  const [showModal, setShowModal] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState<string>("");

  const handleOrderClick = (orderId: string) => {
    setSelectedOrderId(orderId);
    setShowModal(true);
  };

  // 배송대행 모달
  const [showShippingModal, setShowShippingModal] = useState(false); // 배송 모달 상태 관리
  const [selectedShippingOrderId, setSelectedShippingOrderId] =
    useState<string>("");

  const handleShippingOrderClick = (orderId: string) => {
    setSelectedShippingOrderId(orderId);
    setShowShippingModal(true);
  };

  // 구매대행 페이지네이션 관련 상태 추가
  const [entriesPerPage, setEntriesPerPage] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchText, setSearchText] = useState<string>("");

  const handleEntriesChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setEntriesPerPage(Number(e.target.value));
    setCurrentPage(1);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
    setCurrentPage(1);
  };

  const handlePageClick = (data: { selected: number }) => {
    setCurrentPage(data.selected + 1);
  };

  const filteredAndSearchedData = filteredData
    .filter(
      (order) =>
        order.id.includes(searchText) ||
        (order.manager && order.manager.includes(searchText)) ||
        order.userId.includes(searchText)
    )
    .slice((currentPage - 1) * entriesPerPage, currentPage * entriesPerPage);

  // 배송대행 페이지네이션
  const [entriesPerPageShipping, setEntriesPerPageShipping] =
    useState<number>(10);
  const [currentPageShipping, setCurrentPageShipping] = useState<number>(1);
  const [searchTextShipping, setSearchTextShipping] = useState<string>("");

  const handleEntriesChangeShipping = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setEntriesPerPageShipping(Number(e.target.value));
    setCurrentPageShipping(1);
  };

  const handleSearchChangeShipping = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchTextShipping(e.target.value);
    setCurrentPageShipping(1);
  };

  const handlePageClickShipping = (data: { selected: number }) => {
    setCurrentPageShipping(data.selected + 1);
  };

  const filteredAndSearchedShippingData = filteredShippingData
    .filter(
      (order) =>
        order.id.includes(searchTextShipping) ||
        (order.manager && order.manager.includes(searchTextShipping)) ||
        order.userId.includes(searchTextShipping)
    )
    .slice(
      (currentPageShipping - 1) * entriesPerPageShipping,
      currentPageShipping * entriesPerPageShipping
    );

  return (
    <MainContainer>
      <Header>
        <SearchBar
          placeholder="Space Code"
          value={searchTerm}
          onChange={handleSearch}
        />
      </Header>
      <Content>
        {userData && (
          <CustomerInfo>
            <CustomerInfoText>
              <CustomerName>Space Code {userData.id}</CustomerName>
              <CustomerDetails>
                Name: {userData.name}
                <br />
                Country: {CODE_COUNTRY_ITEMS[userData.country]}
                <br />
                Date: {new Date(userData.createdAt).toLocaleDateString()}
                <br />
                Membership: {MEMBERSHIP[userData.membershipId] || "N/A"} ( ~{" "}
                {userData.membershipEndAt
                  ? new Date(userData.membershipEndAt).toLocaleDateString()
                  : "N/A"}
                )
              </CustomerDetails>
            </CustomerInfoText>
          </CustomerInfo>
        )}
        <StatsContainer>
          <StatBox>
            <StatTitle>구매대행</StatTitle>
            <StatValue>
              KRW{" "}
              {getTotalAmount(purchaseData, "KRW", searchTerm).toLocaleString()}{" "}
              ₩
              <br />
              USD {getTotalAmount(purchaseData, "USD", searchTerm).toFixed(2)} $
              <br />
              완료된 건수: {purchaseCount}
            </StatValue>
          </StatBox>
          <StatBox>
            <StatTitle>배송대행</StatTitle>
            <StatValue>
              KRW{" "}
              {getTotalShippingAmount(
                shippingData,
                "KRW",
                searchTerm
              ).toLocaleString()}{" "}
              ₩
              <br />
              USD{" "}
              {getTotalShippingAmount(shippingData, "USD", searchTerm).toFixed(
                2
              )}{" "}
              $
              <br />
              완료된 건수: {shippingCount}
            </StatValue>
          </StatBox>
          <StatBox>
            <StatTitle>입고수량</StatTitle>
            <StatValue>총 입고수량: {inboundCount}</StatValue>
          </StatBox>
          <StatBox>
            <StatTitle>출고수량</StatTitle>
            <StatValue>출고수량: {outboundCount}</StatValue>
          </StatBox>
          <StatBox>
            <StatTitle>폐기수량</StatTitle>
            <StatValue>폐기수량: {discardedCount}</StatValue>
          </StatBox>
          <StatBox>
            <StatTitle>보관중</StatTitle>
            <StatValue>보관중: {storedCount}</StatValue>
          </StatBox>
          <StatBox>
            <StatTitle>체크컨텐츠</StatTitle>
            <StatValue>체크 결제 건수: {checkContentPaymentCount}</StatValue>
          </StatBox>
          <StatBox>
            <StatTitle>스플릿</StatTitle>
            <StatValue>스플릿 결제 건수: {splitContentPaymentCount}</StatValue>
          </StatBox>
        </StatsContainer>
        <TabContainer>
          <Tab
            onClick={() => handleTabClick("구매대행")}
            active={selectedTab === "구매대행"}
          >
            구매대행
          </Tab>
          <Tab
            onClick={() => handleTabClick("배송대행")}
            active={selectedTab === "배송대행"}
          >
            배송대행
          </Tab>
          <Tab
            onClick={() => handleTabClick("포인트")}
            active={selectedTab === "포인트"}
          >
            포인트
          </Tab>
          <Tab
            onClick={() => handleTabClick("쿠폰")}
            active={selectedTab === "쿠폰"}
          >
            쿠폰
          </Tab>
          <Tab
            onClick={() => handleTabClick("리뷰")}
            active={selectedTab === "리뷰"}
          >
            리뷰
          </Tab>
          <Tab
            onClick={() => handleTabClick("체크컨텐츠")}
            active={selectedTab === "체크컨텐츠"}
          >
            체크컨텐츠
          </Tab>
          <Tab
            onClick={() => handleTabClick("질문응답")}
            active={selectedTab === "질문응답"}
          >
            질문응답
          </Tab>
          <Tab
            onClick={() => handleTabClick("스플릿")}
            active={selectedTab === "스플릿"}
          >
            스플릿
          </Tab>
        </TabContainer>
        {selectedTab === "구매대행" ? (
          <DetailContainer>
            <DetailHeader>
              <EntriesSelector>
                <label>Show</label>
                <Dropdown value={entriesPerPage} onChange={handleEntriesChange}>
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={50}>50</option>
                  <option value={-1}>All</option>
                </Dropdown>
                <label>entries per page</label>
              </EntriesSelector>
              <SearchBar
                placeholder="Search..."
                value={searchText}
                onChange={handleSearchChange}
              />
            </DetailHeader>
            <DetailTable>
              <thead>
                <tr>
                  <th>주문번호</th>
                  <th>담당자</th>
                  <th>스페이스코드</th>
                  <th>주문날짜</th>
                  <th>결제날짜</th>
                  <th>결제수단</th>
                  <th>결제금액</th>
                  <th>주문상태</th>
                </tr>
              </thead>
              <tbody>
                {filteredAndSearchedData.map((order) => (
                  <tr key={order.id} onClick={() => handleOrderClick(order.id)}>
                    <td>{order.id}</td>
                    <td>{order.manager || "없음"}</td>
                    <td>{order.userId}</td>
                    <td>{new Date(order.createdAt).toLocaleDateString()}</td>
                    <td>
                      {order.paidAt
                        ? new Date(order.paidAt).toLocaleDateString()
                        : "미결제"}
                    </td>
                    <td>
                      {order.PaymentMethod
                        ? PAYMENT_METHODS[order.PaymentMethod]
                        : "미결제"}
                    </td>
                    <td>
                      {order.PaymentMethod === 1
                        ? `${order.snKrwRealFee ?? "미결제"} KRW`
                        : order.PaymentMethod === 2
                        ? `${order.snUsdRealFee ?? "미결제"} USD`
                        : "미결제"}
                    </td>
                    <td>
                      {order.status === 1 && "요청서"}
                      {order.status === 2 && "결제 대기중"}
                      {order.status === 3 && "결제 확인중"}
                      {order.status === 4 && "결제완료"}
                      {order.status === 6 && "주문 완료"}
                      {order.status === 10 && "취소"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </DetailTable>
            <PaginationContainer>
              <ReactPaginate
                previousLabel={"previous"}
                nextLabel={"next"}
                breakLabel={"..."}
                pageCount={Math.ceil(filteredData.length / entriesPerPage)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                pageClassName={"pagination__link"}
                previousClassName={"pagination__link"}
                nextClassName={"pagination__link"}
                breakClassName={"pagination__link"}
                activeClassName={"pagination__link--active"}
              />
            </PaginationContainer>
          </DetailContainer>
        ) : selectedTab === "배송대행" ? (
          <DetailContainer>
            <DetailHeader>
              <EntriesSelector>
                <label>Show</label>
                <Dropdown
                  value={entriesPerPageShipping}
                  onChange={handleEntriesChangeShipping}
                >
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={50}>50</option>
                  <option value={-1}>All</option>
                </Dropdown>
                <label>entries per page</label>
              </EntriesSelector>
              <SearchBar
                placeholder="Search..."
                value={searchTextShipping}
                onChange={handleSearchChangeShipping}
              />
            </DetailHeader>
            <DetailTable>
              <thead>
                <tr>
                  <th>주문번호</th>
                  <th>담당자</th>
                  <th>스페이스코드</th>
                  <th>주문날짜</th>
                  <th>결제날짜</th>
                  <th>결제수단</th>
                  <th>결제금액</th>
                  <th>주문상태</th>
                  <th>배송사</th>
                </tr>
              </thead>
              <tbody>
                {filteredAndSearchedShippingData.map((order) => (
                  <tr
                    key={order.id}
                    onClick={() => handleShippingOrderClick(order.id)}
                  >
                    <td>{order.id}</td>
                    <td>{order.manager || "없음"}</td>
                    <td>{order.userId}</td>
                    <td>{new Date(order.createdAt).toLocaleDateString()}</td>
                    <td>
                      {order.expiredAt
                        ? new Date(order.expiredAt).toLocaleDateString()
                        : "미결제"}
                    </td>
                    <td>
                      {order.PaymentMethod
                        ? PAYMENT_METHODS[order.PaymentMethod]
                        : "미결제"}
                    </td>
                    <td>
                      {order.DepositorFee
                        ? `${order.DepositorFee ?? "미결제"}`
                        : "미결제"}
                    </td>
                    <td>
                      {order.status === 1 && "요청서"}
                      {order.status === 2 && "포장 중"}
                      {order.status === 3 && "견적서"}
                      {order.status === 4 && "결제 대기중"}
                      {order.status === 5 && "결제 확인중"}
                      {order.status === 6 && "결제 완료"}
                      {order.status === 7 && "특송사 배송"}
                      {order.status === 8 && "특송사 배송완료"}
                      {order.status === 9 && "리뷰완료"}
                      {order.status === 10 && "취소"}
                    </td>
                    <td>
                      {order.company ? COMPANY_NAMES[order.company] : "N/A"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </DetailTable>
            <PaginationContainer>
              <ReactPaginate
                previousLabel={"previous"}
                nextLabel={"next"}
                breakLabel={"..."}
                pageCount={Math.ceil(
                  filteredShippingData.length / entriesPerPageShipping
                )}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClickShipping}
                containerClassName={"pagination"}
                pageClassName={"pagination__link"}
                previousClassName={"pagination__link"}
                nextClassName={"pagination__link"}
                breakClassName={"pagination__link"}
                activeClassName={"pagination__link--active"}
              />
            </PaginationContainer>
          </DetailContainer>
        ) : selectedTab === "리뷰" ? (
          <ReviewContainer>
            <ReviewTable>
              <thead>
                <tr>
                  <th>주문번호</th>
                  <th>등록일</th>
                  <th>별점</th>
                  <th>내용</th>
                  <th>조회수</th>
                  <th>상태</th>
                  <th>담당자</th>
                </tr>
              </thead>
              <tbody>
                {reviewData.map((review) => (
                  <tr key={review.id}>
                    <td>{review.orderId}</td>
                    <td>{new Date(review.createdAt).toLocaleDateString()}</td>
                    <td>{review.rating}</td>
                    <td>
                      {review.content.length > 30
                        ? `${review.content.slice(0, 30)}...`
                        : review.content}
                    </td>
                    <td>{review.view}</td>
                    <td>{review.status}</td>
                    <td>{review.manager || "N/A"}</td>
                  </tr>
                ))}
              </tbody>
            </ReviewTable>
          </ReviewContainer>
        ) : selectedTab === "포인트" ? (
          <PointContainer>
            <PointTable>
              <thead>
                <tr>
                  <th>날짜</th>
                  <th>타입</th>
                  <th>포인트</th>
                  <th>지급 수단</th>
                  <th>담당자</th>
                </tr>
              </thead>
              <tbody>
                {pointData
                  .slice()
                  .sort(
                    (a, b) =>
                      new Date(b.createdAt).getTime() -
                      new Date(a.createdAt).getTime()
                  )
                  .map((log, index) => (
                    <tr key={index}>
                      <td>{new Date(log.createdAt).toLocaleDateString()}</td>
                      <td>
                        {log.used ? "Used" : log.grant ? "Granted" : "Unknown"}
                      </td>
                      <td>
                        {log.used ? (
                          <>
                            {log.krwUsedPoint !== 0 &&
                              `KRW: -${log.krwUsedPoint}`}
                            {log.krwUsedPoint !== 0 &&
                              parseFloat(log.usdUsedPoint) !== 0 && <br />}
                            {parseFloat(log.usdUsedPoint) !== 0 &&
                              `USD: -${parseFloat(log.usdUsedPoint).toFixed(
                                2
                              )}`}
                          </>
                        ) : log.grant ? (
                          <>
                            {log.krwGrantPoint !== 0 &&
                              `KRW: +${log.krwGrantPoint}`}
                            {log.krwGrantPoint !== 0 &&
                              parseFloat(log.usdGrantPoint) !== 0 && <br />}
                            {parseFloat(log.usdGrantPoint) !== 0 &&
                              `USD: +${parseFloat(log.usdGrantPoint).toFixed(
                                2
                              )}`}
                          </>
                        ) : (
                          "0"
                        )}
                      </td>
                      <td>{getSourceText(log.status)}</td>
                      <td>{log.manager ? log.manager : "없음"}</td>
                    </tr>
                  ))}
              </tbody>
            </PointTable>
          </PointContainer>
        ) : selectedTab === "쿠폰" ? (
          <CouponContainer>
            <CouponTable>
              <thead>
                <tr>
                  <th>쿠폰 이름</th>
                  <th>타입</th>
                  <th>사용일자</th>
                  <th>만료날짜</th>
                  <th>지급 수단</th>
                  <th>담당자</th>
                </tr>
              </thead>
              <tbody>
                {couponData.map((coupon, index) => (
                  <tr key={index}>
                    <td>{coupon.coupon.name}</td>
                    <td>{coupon.used ? "사용" : "미사용"}</td>
                    <td>{coupon.usedAt ? coupon.usedAt : "미사용"}</td>
                    <td>{coupon.expiredAt}</td>
                    <td>
                      {getAutoReceiveConditionText(
                        coupon.coupon.autoReceiveCondition
                      )}
                    </td>
                    <td>{coupon.manager || "자동"}</td>
                  </tr>
                ))}
              </tbody>
            </CouponTable>
          </CouponContainer>
        ) : selectedTab === "체크컨텐츠" ? (
          <DetailContainer>
            <DetailTable>
              <thead>
                <tr>
                  <th>요청날짜</th>
                  <th>송장번호</th>
                  <th>결제금액</th>
                  <th>결제수단</th>
                  <th>담당자</th>
                </tr>
              </thead>
              <tbody>
                {checkContentData.map((content, index) => (
                  <tr key={index}>
                    <td>
                      {content.requestAt
                        ? new Date(content.requestAt).toLocaleDateString()
                        : "N/A"}
                    </td>
                    <td>{content.tracking}</td>
                    <td>$5</td>
                    <td>{getPaymentMethodText(content.checkPayment)}</td>
                    <td>
                      {content.isAlarmSubmitted
                        ? content.isAlarmSubmitted
                        : "없음"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </DetailTable>
          </DetailContainer>
        ) : selectedTab === "질문응답" ? (
          <DetailContainer>
            <DetailTable>
              <thead>
                <tr>
                  <th>질문유형</th>
                  <th>등록일</th>
                  <th>답변일</th>
                  <th>담당자</th>
                  <th>제목</th>
                  <th>상태</th>
                  <th>삭제</th>
                </tr>
              </thead>
              <tbody>
                {questionData.map((question) => (
                  <tr key={question.id}>
                    <td>{getQuestionType(question.categoryId)}</td>
                    <td>{formatDate(question.createdAt)}</td>
                    <td>
                      {question.responseAt
                        ? formatDate(question.responseAt)
                        : "답변 대기중"}
                    </td>
                    <td>{question.manager || "담당자 미지정"}</td>
                    <td>{question.title}</td>
                    <td>{question.status ? "완료" : "미완료"}</td>
                    <td>
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => handleDeleteClick(question.id)}
                      >
                        삭제
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </DetailTable>
          </DetailContainer>
        ) : selectedTab === "스플릿" ? (
          <DetailContainer>
            <DetailTable>
              <thead>
                <tr>
                  <th>송장번호</th>
                  <th>스페이스코드</th>
                  <th>생성일</th>
                  <th>내용</th>
                  <th>이미지</th>
                  <th>상태</th>
                </tr>
              </thead>
              <tbody>
                {splitData.map((split) => (
                  <tr key={split.id}>
                    <td>{split.tracking}</td>
                    <td>{split.userId}</td>
                    <td>{new Date(split.createdAt).toLocaleDateString()}</td>
                    <td>
                      {split.content.length > 30
                        ? `${split.content.slice(0, 30)}...`
                        : split.content}
                    </td>
                    <td>
                      {split.imageUrl ? (
                        <img
                          src={split.imageUrl}
                          alt="split"
                          style={{ width: "50px", height: "50px" }}
                        />
                      ) : (
                        "N/A"
                      )}
                    </td>
                    <td>
                      {split.status === 1
                        ? "요청"
                        : split.status === 2
                        ? "완료"
                        : split.status}
                    </td>
                  </tr>
                ))}
              </tbody>
            </DetailTable>
          </DetailContainer>
        ) : (
          <DetailContainer>
            <DetailHeader>
              <EntriesSelector>
                <label>Show</label>
                <Dropdown>
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={50}>50</option>
                  <option value={-1}>All</option>
                </Dropdown>
                <label>entries per page</label>
              </EntriesSelector>
              <SearchBar placeholder="Search..." />
            </DetailHeader>
            <DetailTable>
              <thead>
                <tr>
                  <th>주문번호</th>
                  <th>담당자</th>
                  <th>스페이스코드</th>
                  <th>주문날짜</th>
                  <th>결제날짜</th>
                  <th>결제수단</th>
                  <th>결제금액</th>
                  <th>주문상태</th>
                  {selectedTab === "배송대행" && <th>배송사</th>}
                </tr>
              </thead>
              <tbody>
                {filteredData.map((order) => (
                  <tr key={order.id}>
                    <td>{order.id}</td>
                    <td>{order.manager || "없음"}</td>
                    <td>{order.userId}</td>
                    <td>{new Date(order.createdAt).toLocaleDateString()}</td>
                    <td>
                      {order.paidAt
                        ? new Date(order.paidAt).toLocaleDateString()
                        : "미결제"}
                    </td>
                    <td>
                      {order.PaymentMethod
                        ? PAYMENT_METHODS[order.PaymentMethod]
                        : "미결제"}
                    </td>
                    <td>
                      {order.PaymentMethod === 1
                        ? `${order.snKrwRealFee ?? "미결제"} KRW`
                        : order.PaymentMethod === 2
                        ? `${order.snUsdRealFee ?? "미결제"} USD`
                        : "미결제"}
                    </td>
                    <td>
                      {order.status === 1 && "요청서"}
                      {order.status === 2 && "결제 대기중"}
                      {order.status === 3 && "결제 확인중"}
                      {order.status === 4 && "결제완료"}
                      {order.status === 6 && "주문 완료"}
                      {order.status === 10 && "취소"}
                    </td>
                    {selectedTab === "배송대행" && (
                      <td>
                        {order.company ? COMPANY_NAMES[order.company] : "N/A"}
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </DetailTable>
            <PaginationContainer>
              {/* 페이지네이션 요소 추가 */}
            </PaginationContainer>
          </DetailContainer>
        )}
      </Content>
      <ShopDetailsModal
        show={showModal}
        onHide={() => setShowModal(false)}
        orderId={selectedOrderId}
      />
      <AShipDetailModal
        show={showShippingModal}
        onHide={() => setShowShippingModal(false)}
        orderNumber={selectedShippingOrderId}
        onRepackRequest={() => {}}
        refreshData={() => {}}
      />
    </MainContainer>
  );
};

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SearchBar = styled.input`
  padding: 0.5rem;
  border: 1px solid lightgray;
  border-radius: 0.4rem;
`;

const Content = styled.div`
  margin-top: 1rem;
`;

const CustomerInfo = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem;
  background-color: #f8f9fa;
  border-radius: 0.4rem;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1);
`;

const CustomerInfoText = styled.div`
  margin-left: 1rem;
`;

const CustomerName = styled.h3`
  font-size: 1.5rem;
  font-weight: bold;
`;

const CustomerDetails = styled.p`
  font-size: 1rem;
`;

const StatsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;
`;

const StatBox = styled.div`
  flex: 1;
  margin: 0 0.5rem;
  padding: 1rem;
  background-color: #fff;
  border-radius: 0.4rem;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1);
  text-align: center;
`;

const StatTitle = styled.h4`
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
`;

const StatValue = styled.p`
  font-size: 1rem;
`;

const TabContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 1rem 0;
`;

const Tab = styled.button<{ active: boolean }>`
  flex: 1;
  padding: 0.5rem;
  border: none;
  background-color: ${(props) => (props.active ? "#e9ecef" : "#f8f9fa")};
  border-radius: 0.4rem;
  cursor: pointer;

  &:hover {
    background-color: #e9ecef;
  }
`;

const DetailContainer = styled.div`
  background-color: #fff;
  border-radius: 0.4rem;
  overflow-y: auto;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1);
  padding: 1rem;
`;

const DetailHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

const EntriesSelector = styled.div`
  display: flex;
  align-items: center;

  label {
    margin: 0 0.5rem;
  }
`;

const Dropdown = styled.select`
  margin: 0 0.5rem;
`;

const DetailTable = styled.table`
  width: 100%;
  overflow-y: auto;
  border-collapse: collapse;

  th,
  td {
    padding: 0.5rem;
    border: 1px solid lightgray;
    text-align: center;
  }

  th {
    background-color: #f2f2f2;
  }
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;

  .pagination {
    display: flex;
    list-style: none;
    padding: 0;
  }

  .pagination__link {
    padding: 0.5rem 1rem;
    margin: 0 0.25rem;
    border: 1px solid #ddd;
    cursor: pointer;
    text-decoration: none;
    color: #007bff;

    &:hover {
      background-color: #f1f1f1;
    }
  }

  .pagination__link--active {
    background-color: #007bff;
    color: white;
    border-color: #007bff;
  }
`;

const ReviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const ReviewTable = styled.table`
  width: 100%;
  overflow-y: auto;
  border-collapse: collapse;

  th,
  td {
    padding: 0.5rem;
    border: 1px solid lightgray;
    text-align: center;
  }

  th {
    background-color: #f2f2f2;
  }
`;

const PointContainer = styled.div`
  margin-top: 1rem;
`;

const PointTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  th,
  td {
    padding: 0.5rem;
    border: 1px solid lightgray;
    text-align: center;
  }

  th {
    background-color: #f2f2f2;
  }
`;

const CouponContainer = styled.div`
  margin-top: 1rem;
`;

const CouponTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  th,
  td {
    padding: 0.5rem;
    border: 1px solid lightgray;
    text-align: center;
  }

  th {
    background-color: #f2f2f2;
  }
`;

export default CustomerDetail;
