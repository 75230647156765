import { Button, FormControl, InputGroup, Table } from "react-bootstrap";
import MTitle from "../MTitle";

import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { DATA_TYPE } from "../../../common/constants";
import FormatData from "../../../components/FormatData";
import Icon from "../../../components/Icon";
import { MODAL_TYPE } from "../../../components/modal/GlobalModal";
import { modalOpen } from "../../../redux/modalSlice";
import { RootUserState } from "../../../redux/store";
import { reqUserGetShipList } from "../../../requests/user";

// 작성한 배송요청서
export default function CShipOrder() {
  const dispatch = useDispatch();
  const modal = useSelector((state: any) => state.modal);
  const user = useSelector((state: RootUserState) => state.user);
  const pageCount = 10;
  const [totalCheck, setTotalCheck] = useState<boolean>(false);
  const [searchWord, setSearchWord] = useState<string>("");
  const [data, setData] = useState<any[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [checked, setChecked] = useState<boolean[]>(
    Array<boolean>(0).fill(false)
  );

  const rf = async () => {
    const result = await reqUserGetShipList({
      userId: user.id,
      page: 1,
      pageCount,
      searchWord,
    });

    const filteredData = result.data.list.filter((order: any) =>
      [1, 2].includes(order.status)
    );

    setData(filteredData);
    setTotalCount(filteredData.length);
    setChecked(Array<boolean>(filteredData.length).fill(false));
  };

  useEffect(() => {
    rf();
  }, [user?.id]);

  useEffect(() => {
    if (checked.includes(false)) {
      setTotalCheck(false);
    } else {
      setTotalCheck(true);
    }
  }, [checked]);

  return (
    <>
      <div style={{ padding: isMobile ? "1.4rem" : "30px" }}>
        <span>Delivery agency service</span>
        <Icon style={{ margin: "3px" }} icon="chevron-right" fontSize="14px" />
        <span>completed delivery request</span>

        {isMobile ? (
          <MobileWrapper>
            <MTitle title="Shipping Request" />
            <div
              style={{
                borderBottom: "1px solid lightgray",
                paddingBottom: "1rem",
                marginBottom: "1.5rem",
              }}
            >
              <InputGroup className="mb-3" style={{ width: "100%" }}>
                <FormControl
                  className=""
                  placeholder="order number"
                  value={searchWord}
                  onChange={(e) => {
                    setSearchWord(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "enter") {
                      rf();
                    }
                  }}
                />
                <Button
                  variant="outline"
                  style={{ borderColor: "var(--color-main-blue)" }}
                  onClick={(e) => {
                    rf();
                  }}
                >
                  <Icon icon="search" color="var(--color-main-blue)" />
                </Button>
              </InputGroup>
            </div>

            <div>
              <div>
                {data?.map((v: any, index: number) => {
                  return (
                    <>
                      <div
                        style={{
                          cursor: "pointer",
                          borderBottom: "1px solid lightgray",
                          marginBottom: "1rem",
                          paddingBottom: "1rem",
                        }}
                        onClick={(e) => {
                          dispatch(
                            modalOpen({
                              modalType: MODAL_TYPE.SHIP_ORDER_DATAIL,
                              title: "Order Detail",
                              payload: { data: v },
                            })
                          );
                          const temp = [...checked];
                          temp[index] = !temp[index];
                          setChecked(temp);
                        }}
                      >
                        <Row>
                          {" "}
                          <Label>Order No.</Label>
                          <P>{v.id}</P>
                        </Row>

                        <Row>
                          <Label>Order Date</Label>
                          <P>
                            {v.createdAt
                              ? FormatData(DATA_TYPE.DATE, v.createdAt)
                              : "Unsent"}
                          </P>
                        </Row>

                        <Row>
                          <Label>Status</Label>
                          <P>
                            {FormatData(
                              DATA_TYPE.CODE_SHIPPING_ORDER_STATUS,
                              v.status
                            )}
                          </P>
                        </Row>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </MobileWrapper>
        ) : (
          <>
            <MTitle title="Shipping Request" />
            <div className="d-flex justify-content-end">
              <InputGroup className="mb-3" style={{ width: "300px" }}>
                <FormControl
                  className=""
                  placeholder="order number"
                  value={searchWord}
                  onChange={(e) => {
                    setSearchWord(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "enter") {
                      rf();
                    }
                  }}
                />
                <Button
                  variant="outline"
                  style={{ borderColor: "var(--color-main-blue)" }}
                  onClick={(e) => {
                    rf();
                  }}
                >
                  <Icon icon="search" color="var(--color-main-blue)" />
                </Button>
              </InputGroup>
            </div>
            <div
              style={{
                height: "500px",
                overflowY: "scroll",
                marginBottom: "20px",
              }}
            >
              <Table style={{ position: "relative" }}>
                <thead>
                  <tr translate="no">
                    <th style={{ top: 0, position: "sticky" }}>Order No.</th>
                    <th style={{ top: 0, position: "sticky" }}>Order Date</th>
                    <th style={{ top: 0, position: "sticky" }}>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.map((v: any, index: number) => {
                    return (
                      <>
                        <tr
                          style={{ cursor: "pointer" }}
                          onClick={(e) => {
                            dispatch(
                              modalOpen({
                                modalType: MODAL_TYPE.SHIP_ORDER_DATAIL,
                                title: "Order Detail",
                                payload: { data: v },
                              })
                            );
                            const temp = [...checked];
                            temp[index] = !temp[index];
                            setChecked(temp);
                          }}
                        >
                          <td>{v.id}</td>
                          <td>
                            {v.createdAt
                              ? FormatData(DATA_TYPE.DATE, v.createdAt)
                              : "Unsent"}
                          </td>
                          <td>
                            {FormatData(
                              DATA_TYPE.CODE_SHIPPING_ORDER_STATUS,
                              v.status
                            )}
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </>
        )}
      </div>
    </>
  );
}

const Title = styled.h3`
  border-bottom: 1px solid lightgray;
  padding-bottom: 1rem;
  margin-top: 1rem;
`;
const FeeBoxRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  & > div {
    flex: 1 0 0;
    text-align: center;
    align-items: center;
    border: 1px solid lightgray;
    padding: 0.4rem 0;
  }
  margin: 0.6rem 0;
`;
const TotalFeeBoxRow = styled(FeeBoxRow)`
  background-color: #474747;
  color: white;
`;

const MobileWrapper = styled.div`
  margin: 0;
`;

const MBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
  align-items: center;
`;
const Col = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  font-weight: bold;
  margin: 0.4rem 0 0.4rem 0;
`;
const P = styled.p`
  margin: 0.4rem 0 0.4rem 0;
`;
