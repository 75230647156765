import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  ACODE_SHOPING_ORDER_STATUS,
  MEMBERSHIP,
} from "../../../common/constants";
import Title from "../../../components/Title";
import RepackModal from "../../../components/modal/RepackModal";
import ShopBoxingModal from "../../../components/modal/ShopBoxingModal";
import { getColor } from "../../../components/getColor";
import AdminHeadTitle from "../../../components/AdminHeadTitle";
import { Pagination, Table } from "react-bootstrap";
import AdminHeadFilter from "../../../components/AdminHeadFilter";
import { formatDate } from "../../../common/format";
const ITEMS_PER_PAGE = 10;

export default function AShipBoxing() {
  const nav = useNavigate();
  const [search, setSearch] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState<string | null>(null);
  const [showRepackModal, setShowRepackModal] = useState(false);
  const [data, setData] = useState<OrderData[]>([]);

  type OrderData = {
    id: string;
    userId: string;
    adrReceiver: string;
    createdAt: string;
    manager: string;
    status: number;
    user: {
      name: string;
      membershipId: number;
    };
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}ship`);
      const filteredData = response.data.filter(
        (item: OrderData) => item.status === ACODE_SHOPING_ORDER_STATUS.Packed
      );
      setData(filteredData);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleStatusClick = (orderId: string) => {
    setSelectedOrder(orderId);
    setShowModal(true);
  };

  const handleRepackRequest = () => {
    setShowModal(false);
    setShowRepackModal(true);
    fetchData();
  };

  const refreshData = () => {
    fetchData();
  };

  //! 필터 시작
  const [filteredData, setFilteredData] = useState<any[]>([]);

  const [searchWord, setSearchWord] = useState<string>("");
  const [searchOption, setSearchOption] = useState<string>("");

  const [selectedStatus, setSelectedStatus] = useState("");

  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    const thisFilteredData = data
      .filter((order: any) =>
        order.id.toUpperCase().includes(searchWord.toUpperCase())
      )
      .sort(
        (a: any, b: any) =>
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
      );
    // const thisFilteredData = orders.filter(
    //   (order) =>
    //     order.orderId.toLowerCase().includes(searchWord.toLowerCase()) &&
    //     (selectedStatus ? order.status.toString() === selectedStatus : true)
    // );
    setFilteredData(thisFilteredData);
    setCurrentPage(1);
    setTotalPages(Math.ceil(thisFilteredData.length / itemsPerPage));
  }, [data, searchWord, itemsPerPage, selectedStatus]);

  const handleItemsPerPageChange = (newItemsPerPage: number) => {
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1);
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const displayedOrders = filteredData.slice(
    startIndex,
    startIndex + itemsPerPage
  );
  //! 필터 끝

  const tableRows = displayedOrders.map((item, index) => (
    <tr key={index}>
      <td>{index + 1 + startIndex}</td>
      <td>{item.id}</td>
      <td>{formatDate(item.createdAt)}</td>
      <td>{item.userId}</td>
      <td>{item.user.name}</td>
      <td style={{ color: getColor(item.user.membershipId) }}>
        {MEMBERSHIP[item.user.membershipId]}
      </td>
      <td>{item.manager}</td>
      <td>
        <button
          className="btn btn-primary btn-sm"
          onClick={() => handleStatusClick(item.id)}
        >
          {item.status === 1 ? "요청" : "요청확인"}
        </button>
      </td>
    </tr>
  ));

  // 띄어쓰기
  const handleKeyPress = (event: any) => {
    if (event.key === " ") {
      event.preventDefault();
    }
  };

  return (
    <div>
      <AdminHeadTitle
        subtitle1="배송대행"
        subtitle2="상품 포장중"
        title="상품 포장중"
      />

      {/* 옵션 필터 */}
      <AdminHeadFilter
        selectViewPage={(value: number) => handleItemsPerPageChange(value)}
        enterSearch={(value: string) => setSearchWord(value)}
        selectSearchOption={(value: string) => setSearchOption(value)}
      />

        <div className="table-responsive">
          <Table className="table-custom text-center">
            <thead className="table-light">
              <tr>
                <th>No</th>
                <th>주문번호</th>
                <th>주문날짜</th>
                <th>스페이스코드</th>
                <th>성명</th>
                <th>멤버십 등급</th>
                <th>담당자</th>
                <th>상태</th>
              </tr>
            </thead>
            <tbody>{tableRows}</tbody>
          </Table>
        </div>

      {/* 페이지 바 */}
      <CenteredPagination style={{ marginTop: "1rem" }}>
        {[...Array(totalPages)].map((_, index) => (
          <Pagination.Item
            key={index}
            active={index + 1 === currentPage}
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </Pagination.Item>
        ))}
      </CenteredPagination>

      {showModal && (
        <ShopBoxingModal
          show={showModal}
          onHide={() => setShowModal(false)}
          orderNumber={selectedOrder ?? ""}
          onRepackRequest={handleRepackRequest}
        />
      )}

      <RepackModal
        show={showRepackModal}
        onHide={() => setShowRepackModal(false)}
        orderNumber={selectedOrder ?? ""}
        refreshData={refreshData}
      />
    </div>
  );
}

const CenteredPagination = styled(Pagination)`
  display: flex;
  justify-content: center;
`;

const TableContainer = styled.div`
  width: 100%;
  margin-top: 40px;
  margin-bottom: 40px;

  table {
    width: 100%;
    border-collapse: collapse;

    th,
    td {
      padding: 8px;
      text-align: center;
      border: 1px solid #ddd;
    }

    th {
      background-color: #f2f2f2;
    }
  }
`;

const Toolbar = styled.div`
  .right {
    display: flex;
    gap: 10px;
    width: 300px;
    float: right;

    button {
      width: 100px;
    }
  }

  .left {
    display: flex;
    gap: 10px;

    > div {
      width: 150px;

      button {
        border-radius: 20px;
      }
    }
  }
`;
