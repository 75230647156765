import { Navigate, useLocation } from "react-router-dom";
import { CONST_COOKIE, getCookie } from "../common/cookies";

const RequireAuth = ({ children }) => {
  const location = useLocation();

  const refreshToken = getCookie(CONST_COOKIE.ADMIN_REFRESH);
  if (!refreshToken) {
    return <Navigate to="/admin/login" state={{ from: location }} replace />;
  }

  return <>{children}</>;
};

export default RequireAuth;
