import styled from "styled-components";
import { MEMBERSHIP } from "../common/constants";

export function getColor(membershipId: number) {
    const membership = MEMBERSHIP[membershipId];

    switch (membership) {
        case 'Red':
            return 'red';
        case 'Blue':
            return 'blue';
        case 'Green':
            return 'green';
        case 'None':
        case 'Default':
        default:
            return 'black';
    }
}