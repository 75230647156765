import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import Swal from "sweetalert2";
import { CONST_COOKIE, getCookie, setCookie } from "../../common/cookies";
import Icon from "../../components/Icon";
import { tokenSave } from "../../redux/tokenSlice";
import { userSave } from "../../redux/userSlice";
import { reqLogin } from "../../requests/auth";

export default function SignIn() {
  const dispatch = useDispatch();
  const nav = useNavigate();
  const user = useSelector((state: any) => state.user);
  const [Image, setImage] = useState<any>(
    "https://kr.object.ncloudstorage.com/ontact-bucket/다운로드.png"
  );
  const [ImageGIF, setImageGIF] = useState<any>(
    "https://kr.object.ncloudstorage.com/ontact-bucket/loading-5502_256.gif"
  );
  const [starImage, setsatrImage] = useState<any>(
    "https://kr.object.ncloudstorage.com/ontact-bucket/인스타.png"
  );
  const [whatImage, setwhatImage] = useState<any>(
    "https://kr.object.ncloudstorage.com/ontact-bucket/whatsap.png"
  );
  const [form, setForm] = useState<{ email: string; password: string }>({
    email: "",
    password: "",
  });

  useEffect(() => {
    if (!getCookie(CONST_COOKIE.REFRESH_TOKEN)) {
      return;
    }
    if (user.id !== "") {
      nav("/customer");
    }
  }, [user]);

  const Toast = Swal.mixin({
    toast: true,
    position: "center",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const [hidePassword, setHidePassword] = useState(true);

  const toggleHidePassword = () => {
    setHidePassword(!hidePassword);
  };

  return (
    <>
      <LandingWrapper></LandingWrapper>
      <section
        id="topbar"
        className="topbar d-flex align-items-center"
        style={{ position: "sticky", top: 0, zIndex: "99", height: "140px" }}
      ></section>
      <main id="main">
        <section id="about" className="about">
          <div className="container" data-aos="fade-up">
            <div className="container">
              {/* Outer Row */}
              <div className="row justify-content-center">
                <div className="col-xl-10 col-lg-12 col-md-9">
                  <div className="card o-hidden border-0 shadow-lg my-5">
                    <div className="card-body p-0">
                      {/* Nested Row within Card Body */}
                      <div className="container">
                        <div className="p-4">
                          <div className="text-center">
                            <h1 className="h4 text-gray-900 mb-4">
                              Welcome Back!
                            </h1>
                          </div>
                          <form className="user">
                            <div className="form-group mb-3">
                              <input
                                type="email"
                                className="form-control form-control-user"
                                id="exampleInputEmail"
                                aria-describedby="emailHelp"
                                placeholder="Enter Email Address..."
                                value={form.email}
                                onChange={(e) => {
                                  setForm({
                                    ...form,
                                    email: e.target.value,
                                  });
                                }}
                              />
                            </div>
                            <div
                              className="form-group mb-3"
                              style={{ position: "relative" }}
                            >
                              <input
                                type={hidePassword ? "password" : "text"}
                                className="form-control form-control-user"
                                id="exampleInputPassword"
                                placeholder="Password"
                                value={form.password}
                                onChange={(e) => {
                                  setForm({
                                    ...form,
                                    password: e.target.value,
                                  });
                                }}
                              />
                              <Icon
                                icon={hidePassword ? "eye-slash" : "eye"}
                                style={{
                                  position: "absolute",
                                  top: "21%",
                                  right: isMobile ? "4%" : "2%",
                                }}
                                onClick={toggleHidePassword}
                              />
                            </div>
                            <div className="form-group mb-3">
                              <div className="custom-control custom-checkbox small ">
                                <label
                                  className="custom-control-label d-flex align-items-center"
                                  htmlFor="customCheck"
                                >
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    style={{ marginRight: "5px" }}
                                    id="customCheck"
                                  />
                                  Remember Me
                                </label>
                              </div>
                            </div>
                            <LoginButton
                              type="button"
                              className="btn btn-user btn-block"
                              onClick={async (e) => {
                                try {
                                  if (form.password.length < 6) {
                                    Toast.fire({
                                      icon: "warning",
                                      title:
                                        "The password must be at least 6 digits long.",
                                    });
                                    return;
                                  }
                                  const result = await reqLogin(form);
                                  const errorMessage = result?.data?.message;
                                  if (errorMessage) {
                                    Toast.fire({
                                      icon: "warning",
                                      title: errorMessage,
                                    });
                                    return;
                                  }
                                  if (result.data.Check) {
                                    Swal.fire({
                                      imageUrl: ImageGIF,
                                      imageWidth: 400,
                                      imageHeight: 200,
                                      title:
                                        "<strong>Your account is being reviewed by staff</strong>",
                                      html: `
                                        <p>You chose the 'I was a ontactkorea.kr customer option'</p>
                                        <div style="text-align: center;">
                                          <a href="https://www.instagram.com/ontact_korea/" target="_blank"><img src="${starImage}" style="width: 50px; height: auto; margin: 5px;" /></a>
                                          <a href="https://www.tiktok.com/@ontactkorea?lang=en" target="_blank"><img src="${Image}" style="width: 50px; height: auto; margin: 5px;" /></a>
                                          <a href="https://wa.me/+821027169020" target="_blank"><img src="${whatImage}" style="width: 50px; height: auto; margin: 5px;" /></a>
                                        </div>
                                      `,
                                      confirmButtonText: "Ok",
                                    });
                                    return;
                                  }
                                  dispatch(userSave(result.data));
                                  dispatch(tokenSave(result.data));
                                  setCookie(
                                    CONST_COOKIE.REFRESH_TOKEN,
                                    result.data.refreshToken
                                  );
                                  nav("/customer");
                                } catch (err) {
                                  console.log("login error");
                                }
                              }}
                            >
                              Login
                            </LoginButton>
                          </form>
                          <hr />
                          <div className="text-center">
                            <Link
                              className="small"
                              to="/reset"
                              style={{ color: "var(--color-main-blue-hover)" }}
                            >
                              Forgot Password?
                            </Link>
                          </div>
                          <div className="text-center">
                            <Link
                              className="small"
                              to="/signup"
                              style={{ color: "var(--color-main-blue-hover)" }}
                            >
                              Create an Account!
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End About Us Section */}
      </main>
      {/* End #main */}
    </>
  );
}

const LoginButton = styled.button`
  width: 100%;
  background-color: var(--color-main-blue);
  color: var(--color-white);
  &:hover {
    color: var(--color-white);
    background-color: var(--color-main-blue-hover);
  }
`;

const LandingWrapper = styled.div`
  margin-top: 80px;
  #landing-page > div {
    display: flex;
    justify-content: center;
  }
`;