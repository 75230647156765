import styled from "styled-components";

export default function Title(props: { title: string }) {
  return <H1>{props.title}</H1>;
}

const H1 = styled.h1`
  font-size: x-large;
  margin-bottom: 50px;
`;
