import { Fragment, useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row, Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import styled from "styled-components";
import Swal from "sweetalert2";
import { RootState } from "../../redux/store";
import { reqSendAlarm } from "../../requests/alarm";
import { reqPaypalRefund } from "../../requests/paypal";
import { reqShipFEE } from "../../requests/ship";
import {
  reqShop,
  reqShopDetail,
  reqShopDetailUpdatePut,
  reqShopUpdate,
} from "../../requests/shop";
import { reqResetCouponByOrderId } from "../../requests/coupon";
import {
  reqPostPointlog,
  reqUpdateUserPoints,
  reqUpdateUserPointsUSD,
} from "../../requests/user";
import { CODE_COUNTRY_ITEMS } from "../../common/constants";
import { formatDate } from "../../common/format";
interface ShopPaidModalProps {
  show: boolean;
  onHide: () => void;
  orderId: string;
  refreshData: () => void;
}

interface OrderDetail {
  userId: string;
  orderId: string;
  name: string;
  option1: string;
  option2: string;
  quantity: number;
  price: number;
  ShippingFee: number;
  url: string;
  fee: number;
  paymentDeadline: string;
  cancellationReason: string;
  PackageId: string;
  editablePackageId?: string;
  status: number;
  productId: string;
  preorderStartAt: string;
  preorderEndAt: string;
  fancallName: string;
  fancallBirthday: string;
  fancallNumber: string;
  fancallEmail: string;
  fancallCountry: number;
  fancallKakao: string;
  fancallSnsLine: string;
  isCanceled: boolean;
}

interface UpdatedOrderDetails {
  [key: string]: OrderDetail;
}

interface ShopData {
  userId: string;
  account: string;
  failed: boolean;
  refund: boolean;
  option1: string;
  option2: string;
  url: string;
  fancallCountry: number;
  PaymentMethod: number;
  paymentAmountKRW: number;
  paymentAmountUSD: number;
  paymentConfirmed: boolean;
  usePointKrw: number;
  usePointUse: number;
  snKrwShippingFee: number;
  snKrwFee: number;
  snKrwTotalFee: number;
  snKrwRealFee: number;
  snKrwPointApplied: number;
  snKrwCouponApplied: number;
  snUsdFee: number;
  snUsdTotalFee: number;
  snUsdRealFee: number;
  snUsdPointApplied: number;
  snUsdCouponApplied: number;
  snUsdPaypalSurcharge: number;
  snUsdShippingFee: number;
  paypalTransactionId?: string;
}

const ShopPaidModal = ({
  show,
  onHide,
  orderId,
  refreshData,
}: ShopPaidModalProps) => {
  const [serviceFee, setServiceFee] = useState("");
  const [cancellationReason, setCancellationReason] = useState("");
  const [paymentDeadline, setPaymentDeadline] = useState("");
  const [openItems, setOpenItems] = useState<{ [key: number]: boolean }>({});
  const toggleItem = (index: number) => {
    setOpenItems((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };
  const [shopData, setShopData] = useState<Partial<ShopData>>({});
  const [exchangeRate, setExchangeRate] = useState(0);
  const [fixedExchangeRate, setFixedExchangeRate] = useState(0);
  const [customerMessage, setCustomerMessage] = useState("");
  const [confirmSendModalShow, setConfirmSendModalShow] = useState(false);
  const [confirmCancelModalShow, setConfirmCancelModalShow] = useState(false);
  const [orderDetails, setOrderDetails] = useState<OrderDetail[]>([]);
  const [showRefundPointsConfirmModal, setShowRefundPointsConfirmModal] =
    useState(false);
  const [updatedOrderDetails, setUpdatedOrderDetails] =
    useState<UpdatedOrderDetails>({});
  const [pointsRefunded, setPointsRefunded] = useState(false);
  const [userRefundPoints, setUserRefundPoints] = useState("");
  const admin = useSelector((state: RootState) => state.admin);

  useEffect(() => {
    if (orderId) {
      fetchOrderDetails(orderId);
    }
  }, [orderId]);

  const fetchOrderDetails = async (order_id: string) => {
    try {
      const response = await reqShopDetail({ order_id });
      setOrderDetails(response.data);
      setPaymentDeadline(response.data.paymentDeadline);
      setCancellationReason(response.data.cancellationReason);
    } catch (error) {
      console.error("Error fetching order details:", error);
    }
  };

  useEffect(() => {
    // fetchExchangeRate();
    fetchFixedExchangeRate();
    if (orderId) {
      fetchOrderDetails(orderId);
    }
  }, [orderId]);

  // const fetchExchangeRate = () => {
  //   fetch(
  //     "https://quotation-api-cdn.dunamu.com/v1/forex/recent?codes=FRX.KRWUSD"
  //   )
  //     .then((response) => response.json())
  //     .then((data) => {
  //       const rate = data[0].basePrice;
  //       setExchangeRate(rate);
  //     })
  //     .catch((error) => console.error("Error fetching exchange rate:", error));
  // };

  const fetchFixedExchangeRate = async () => {
    try {
      const response = await reqShipFEE();
      if (
        response &&
        response.data &&
        response.data[0]?.fixedExchangeRateShop
      ) {
        setFixedExchangeRate(
          parseFloat(response.data[0].fixedExchangeRateShop)
        );
      }
    } catch (error) {
      console.error("Error fetching fixed exchange rate:", error);
    }
  };

  useEffect(() => {
    if (orderId) {
      fetchOrderDetails(orderId);
      fetchShopData();
    }
  }, [orderId]);

  const fetchShopData = async () => {
    try {
      const response = await reqShop({ order_id: orderId });
      const feeValue = response.data.fee?.toString() ?? "";
      setServiceFee(feeValue);
      setShopData(response.data);
    } catch (error) {
      console.error("Error fetching shop data:", error);
    }
  };

  const handleOrderDetailChange = (
    index: any,
    field: keyof OrderDetail,
    value: any
  ) => {
    setOrderDetails((prevDetails) =>
      prevDetails.map((detail, idx) =>
        idx === index ? { ...detail, [field]: value } : detail
      )
    );

    setUpdatedOrderDetails((prevDetails) => {
      const updatedDetails = { ...prevDetails };
      updatedDetails[index] = {
        ...(updatedDetails[index] || orderDetails[index]),
        [field]: value,
      };
      return updatedDetails;
    });
  };

  const handleSendEstimateClick = () => {
    setConfirmSendModalShow(true);
  };

  const handleToggleItemCancellation = async (index: number) => {
    const detail = orderDetails[index];
    if ([3, 4, 5, 10].includes(detail.status)) {
      Swal.fire({
        icon: "error",
        title: "조치 불가",
        text: "이 상태의 주문 항목에 대해서는 조치를 취할 수 없습니다.",
        confirmButtonText: "확인",
      });
      return;
    }

    const newIsCanceled = !detail.isCanceled;
    const newStatus = newIsCanceled
      ? 9
      : detail.status === 9
      ? 2
      : detail.status;

    const updatedDetail = {
      ...detail,
      isCanceled: newIsCanceled,
      status: newStatus,
      PackageId: detail.editablePackageId || detail.PackageId,
    };

    try {
      await reqShopDetailUpdatePut(updatedDetail);
      setOrderDetails((prevDetails) =>
        prevDetails.map((item, idx) => (idx === index ? updatedDetail : item))
      );
      Swal.fire({
        icon: "success",
        title: "조치 완료",
        text: newIsCanceled
          ? "상품이 취소되었습니다."
          : "상품 취소가 취소되었습니다.",
        confirmButtonText: "확인",
      });
    } catch (error) {
      console.error("Action failed:", error);
      Swal.fire({
        icon: "error",
        title: "조치 실패",
        text: "서버 오류로 인해 조치를 완료할 수 없습니다.",
        confirmButtonText: "확인",
      });
    }
  };

  const handleConfirmSendEstimate = async () => {
    try {
      setConfirmSendModalShow(false);
      await handleSendEstimate();
    } catch (error) {
      console.error("Error during confirmation:", error);
    }
  };

  const handleSendEstimate = async () => {
    try {
      // 업데이트할 orderDetails 배열을 구성합니다.
      const detailsToUpdate =
        Object.values(updatedOrderDetails).length > 0
          ? Object.values(updatedOrderDetails).map((detail) => ({
              PackageId: detail.editablePackageId || detail.PackageId,
              status: detail.status,
              productId: detail.productId,
            }))
          : orderDetails.map((detail) => ({
              PackageId: detail.PackageId,
              status: detail.status,
              productId: detail.productId,
            }));

      console.log("Details to update:", detailsToUpdate);

      Swal.fire({
        title: "Success!",
        text: "구매 가능한 모든 물품에 대해 주문 번호를 입력하셨습니까?",
        icon: "success",
        showCancelButton: true,
        confirmButtonText: "확인",
        cancelButtonText: "아니요",
      }).then(async (result) => {
        if (result.isConfirmed) {
          // 상태가 2인 항목의 상태를 6으로 변경하지 않음
          const updateRequests = detailsToUpdate.map((detail) => {
            return reqShopDetailUpdatePut(detail);
          });

          await Promise.all(updateRequests);

          const updateOrderPayload = {
            order_id: orderId,
            status: 6,
            fancallNumber: customerMessage,
          };
          await reqShopUpdate(updateOrderPayload);

          const alarmPayload = {
            userId: orderDetails[0].userId,
            read: 0,
            content: `All items ordered are available for purchase.. Order: ${orderId}`,
            sender: admin.name,
          };
          await reqSendAlarm(alarmPayload);

          onHide();
          refreshData();
        }
      });
    } catch (error) {
      console.error("견적서 업데이트 실패:", error);
      Swal.fire({
        title: "Error!",
        text: "견적서 업데이트에 실패했습니다.",
        icon: "error",
        confirmButtonText: "닫기",
      });
    }
  };

  const handleSendEstimates = async (index: number) => {
    try {
      const detail = orderDetails[index];
      const updatedDetail = {
        PackageId: detail.editablePackageId || detail.PackageId,
        status: 2,
        productId: detail.productId,
      };

      await reqShopDetailUpdatePut(updatedDetail);

      alert("상품의 견적서가 업데이트되었습니다.");
      refreshData();
    } catch (error) {
      console.error("견적서 업데이트 실패:", error);
      alert("주문번호를 입력해주세요.");
    }
  };

  const handleCancelOrderClick = () => {
    setConfirmCancelModalShow(true);
  };

  // const handleConfirmCancelOrder = async () => {
  //   try {
  //     setConfirmCancelModalShow(false);
  //     await handleCancelOrder();
  //   } catch (error) {
  //     console.error("Cancellation failed:", error);
  //   }
  // };

  // const handleCancelOrder = async () => {
  //   try {
  //     const updateData = {
  //       order_id: orderId,
  //       status: 10,
  //       fancallNumber: customerMessage,
  //     };

  //     await reqShopUpdate(updateData);
  //     const alarmPayload = {
  //       userId: orderDetails[0].userId,
  //       read: 0,
  //       content: `주문이 취소되었습니다. 주문 번호: ${orderId}`,
  //       sender: admin.name,
  //     };
  //     await reqSendAlarm(alarmPayload);

  //     alert("주문이 취소되었습니다.");
  //     onHide();
  //     refreshData();
  //   } catch (error) {
  //     console.error("주문 취소 실패:", error);
  //     alert("주문 취소에 실패했습니다.");
  //   }
  // };

  const convertToUSD = (amount: number) => {
    return (amount / fixedExchangeRate).toFixed(2);
  };

  const calculateTotal = () => {
    const productTotal = orderDetails.reduce((total, item) => {
      if (item.status === 2 || item.status === 6) {
        return total + item.quantity * item.price;
      }
      return total;
    }, 0);

    const domesticShippingFee = orderDetails.reduce((total, item) => {
      if (item.status === 2 || item.status === 6) {
        return total + item.ShippingFee;
      }
      return total;
    }, 0);

    const parsedServiceFee = parseFloat(serviceFee);
    let serviceFeeValue = Math.round(
      productTotal * (isNaN(parsedServiceFee) ? 0 : parsedServiceFee / 100)
    );
    serviceFeeValue = Math.max(serviceFeeValue, 5000);
    const paypalFee =
      (productTotal + serviceFeeValue + domesticShippingFee) * 0.05;
    const UtotalUSDValue = convertToUSD(
      productTotal + domesticShippingFee + serviceFeeValue + paypalFee
    );

    return {
      productTotal,
      domesticShippingFee,
      serviceFeeValue,
      paypalFee,
      total: productTotal + domesticShippingFee + serviceFeeValue,
      Utotal: productTotal + domesticShippingFee + serviceFeeValue + paypalFee,
      UtotalUSD: parseFloat(UtotalUSDValue),
    };
  };

  const {
    productTotal,
    domesticShippingFee,
    serviceFeeValue,
    paypalFee,
    total,
    Utotal,
  } = calculateTotal();

  const handleCancelItem = async (index: number) => {
    try {
      const detail = orderDetails[index];
      const updatedDetail = {
        ...detail,
        isCanceled: true,
        status: 9,
        PackageId: detail.editablePackageId || detail.PackageId,
      };

      await reqShopDetailUpdatePut(updatedDetail);

      setOrderDetails((prevDetails) =>
        prevDetails.map((item, idx) => (idx === index ? updatedDetail : item))
      );

      alert("상품이 취소되었습니다.");
    } catch (error) {
      console.error("상품 취소에 실패했습니다:", error);
      alert("상품 취소에 실패했습니다.");
    }
  };

  // const handleUserRefundPointsChange = (e: any) => {
  //   setUserRefundPoints(e.target.value);
  // };

  const calculateRefundAmount = () => {
    const canceledItems = orderDetails.filter((item) => item.status === 9);

    let refundTotalKRW = canceledItems.reduce((total, item) => {
      const itemTotal = item.quantity * item.price;
      const itemShippingFee = item.ShippingFee;
      return total + itemTotal + itemShippingFee;
    }, 0);

    const areAllOtherCanceled = orderDetails.every(
      (detail) => [3, 4, 5, 10].includes(detail.status) || detail.status === 9
    );
    if (areAllOtherCanceled) {
      const snKrwFee = shopData.snKrwFee ?? 0;
      const serviceFeeValue = snKrwFee;
      refundTotalKRW += Math.max(serviceFeeValue, 5000);
    }

    const refundTotalUSD = convertToUSD(refundTotalKRW);

    return {
      refundTotalKRW: Math.round(refundTotalKRW),
      refundTotalUSD: refundTotalUSD,
    };
  };

  const allOrderNumbersProvided = orderDetails.every((detail) => {
    return detail.PackageId || detail.editablePackageId;
  });

  const calculatePointConversionK = () => {
    const { refundTotalKRW } = calculateRefundAmount();
    const convertedPoints = Math.floor(refundTotalKRW * 1.05);
    return convertedPoints;
  };

  const calculatePointConversionU = () => {
    const { refundTotalUSD } = calculateRefundAmount();
    const totalPoints = parseFloat(refundTotalUSD);
    const convertedPoints = totalPoints * 1.05;
    return convertedPoints.toFixed(2);
  };

  const handleRefundPointsClick = () => {
    setShowRefundPointsConfirmModal(true);
  };

  const areAllProductsCanceled = () => {
    return orderDetails.every((detail) => detail.isCanceled);
  };

  const performRefundPoints = async () => {
    const pointsToRefund = userRefundPoints
      ? parseInt(userRefundPoints, 10)
      : calculatePointConversionK();

    const userId = orderDetails[0]?.userId;

    try {
      const updateData = {
        order_id: orderId,
        fancallCountry: 1,
      };

      await reqShopUpdate(updateData);
      await reqUpdateUserPoints(userId, { pointsToAdd: pointsToRefund });
      const alarmPayload = {
        userId: orderDetails[0].userId,
        read: 0,
        content: `포인트 환불이 완료되었습니다. 주문 번호: ${orderId}`,
        sender: admin.name,
      };
      await reqSendAlarm(alarmPayload);
      alert("포인트 환불했습니다!");
      setShowRefundPointsConfirmModal(false);
      setPointsRefunded(true);
    } catch (error) {
      console.error("Failed to refund points:", error);
      alert("포인트 환불에 오류가 발생했습니다.");
    }
  };

  const allStatusTwoItemsHaveOrderNumbers = () => {
    return orderDetails
      .filter((detail) => detail.status === 2)
      .every((detail) => detail.PackageId || detail.editablePackageId);
  };

  const copyToClipboardAndNavigate = (url: any) => {
    navigator.clipboard
      .writeText(url)
      .then(() => {
        Swal.fire({
          icon: "success",
          title: "Copied!",
          text: "URL이 클립보드에 복사되었습니다. 새 탭에서 열립니다.",
          timer: 1500,
        });
        window.open(url, "_blank");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
        Swal.fire({
          icon: "error",
          title: "Failed!",
          text: "URL 복사에 실패했습니다.",
        });
      });
  };

  const refundByPaypal = async () => {
    console.log(shopData);

    if (shopData?.snUsdTotalFee && shopData?.paypalTransactionId) {
      await reqPaypalRefund({
        storeid: "ontactkorea",
        paytoken: shopData?.paypalTransactionId,
        currency: "USD",
        amount: 0.01,
      });
    }
  };

  // 환불 페이지 시작
  const [modalKRWTotalRefundShow, setModalKRWTotalRefundShow] = useState(false);
  const [modalKRWTotalPointShow, setModalKRWTotalPointShow] = useState(false);
  const [modalKRWPartialRefundShow, setModalKRWPartialRefundShow] =
    useState(false);
  const [modalKRWPartialPointShow, setModalKRWPartialPointShow] =
    useState(false);
  const [modalPaypalTotalRefundShow, setModalPaypalTotalRefundShow] =
    useState(false);
  const [modalPaypalTotalPointShow, setModalPaypalTotalPointShow] =
    useState(false);
  const [modalPaypalPartialRefundShow, setModalPaypalPartialRefundShow] =
    useState(false);
  const [modalPaypalPartialPointShow, setModalPaypalPartialPointShow] =
    useState(false);

  const handleCompleteButtonClick = () => {
    const hasCanceledItems = orderDetails.some((detail) => detail.status === 9);

    if (hasCanceledItems) {
      const isKRW = shopData.PaymentMethod === 1;
      const isPaypal = shopData.PaymentMethod === 2;
      const isRefund = shopData.refund;
      const isFailed = shopData.failed;

      if (isKRW) {
        if (isFailed && isRefund) {
          setModalKRWTotalRefundShow(true);
        } else if (isFailed && !isRefund) {
          setModalKRWTotalPointShow(true);
        } else if (!isFailed && isRefund) {
          setModalKRWPartialRefundShow(true);
        } else if (!isFailed && !isRefund) {
          setModalKRWPartialPointShow(true);
        }
      } else if (isPaypal) {
        if (isFailed && isRefund) {
          setModalPaypalTotalRefundShow(true);
        } else if (isFailed && !isRefund) {
          setModalPaypalTotalPointShow(true);
        } else if (!isFailed && isRefund) {
          setModalPaypalPartialRefundShow(true);
        } else if (!isFailed && !isRefund) {
          setModalPaypalPartialPointShow(true);
        }
      }
    } else {
      handleConfirmSendEstimate();
    }
  };

  // 전체 취소 - 금액 환불 (KRW) 완료 함수
  const 전체취소_금액환불_KRW = async () => {
    // 나머지 물품 취소 상태 및 주문 취소 상태
    try {
      const updatedOrderDetails = orderDetails.map((detail) => ({
        ...detail,
        status: 9,
        isCanceled: true,
        PackageId: detail.editablePackageId || detail.PackageId,
      }));

      const updateRequests = updatedOrderDetails.map((detail) =>
        reqShopDetailUpdatePut(detail)
      );
      await Promise.all(updateRequests);

      const updateData = {
        order_id: orderId,
        status: 10,
        fancallNumber: customerMessage,
      };
      await reqShopUpdate(updateData);
      // 사용한 쿠폰 있으면 리셋 로직
      if (orderId) {
        await reqResetCouponByOrderId(orderId);
      }
      // 포인트를 사용했으면 포인트 리셋
      const pointsToRefund = shopData?.usePointKrw || 0;
      if (pointsToRefund > 0) {
        const userId = orderDetails[0]?.userId;
        await reqUpdateUserPoints(userId, { pointsToAdd: pointsToRefund });
      }

      // 포인트 로그 기록
      const pointLogPayload = {
        userId: orderDetails[0]?.userId,
        grant: true,
        krwGrantPoint: pointsToRefund,
        status: 5,
        manager: admin.name,
      };

      if (pointsToRefund > 0) {
        try {
          await reqPostPointlog(pointLogPayload);
        } catch (error) {
          console.error("Failed to log points:", error);
        }
      }

      // 알림 전송
      const alarmPayload = {
        userId: orderDetails[0].userId,
        read: 0,
        content: `I have canceled the entire process. Please check the refund amount. Order number:: ${orderId}`,
        sender: admin.name,
      };
      await reqSendAlarm(alarmPayload);

      alert("주문이 취소되었습니다.");
      onHide();
      refreshData();
    } catch (error) {
      console.error("주문 취소 실패:", error);
      alert("주문 취소에 실패했습니다.");
    }
  };

  // 전체 취소 - 포인트 환불 (KRW) 완료 함수
  const handleUserRefundPointsChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setUserRefundPoints(e.target.value);
  };

  const 전체취소_포인트환불_KRW = async () => {
    try {
      // 나머지 물품 취소 상태 및 주문 취소 상태
      const updatedOrderDetails = orderDetails.map((detail) => ({
        ...detail,
        status: 9,
        isCanceled: true,
        PackageId: detail.editablePackageId || detail.PackageId,
      }));

      const updateRequests = updatedOrderDetails.map((detail) =>
        reqShopDetailUpdatePut(detail)
      );
      await Promise.all(updateRequests);

      const updateData = {
        order_id: orderId,
        status: 10,
        fancallNumber: customerMessage,
      };
      await reqShopUpdate(updateData);

      // 포인트를 사용했으면 포인트 리셋
      const pointsToRefund =
        parseInt(userRefundPoints, 10) ||
        parseInt(shopData?.usePointKrw?.toString() || "0", 10) +
          (shopData.paymentAmountKRW
            ? Math.floor(shopData.paymentAmountKRW * 1.05)
            : 0);
      if (pointsToRefund > 0) {
        const userId = orderDetails[0]?.userId;
        await reqUpdateUserPoints(userId, { pointsToAdd: pointsToRefund });
      }
      // 사용한 쿠폰 리셋 로직
      if (orderId) {
        await reqResetCouponByOrderId(orderId);
      }
      // 포인트 로그 기록
      const pointLogPayload = {
        userId: orderDetails[0]?.userId,
        grant: true,
        krwGrantPoint: pointsToRefund,
        status: 5,
        manager: admin.name,
      };

      if (pointsToRefund > 0) {
        try {
          await reqPostPointlog(pointLogPayload);
        } catch (error) {
          console.error("Failed to log points:", error);
        }
      }

      // 알림 전송
      const alarmPayload = {
        userId: orderDetails[0].userId,
        read: 0,
        content: `I have canceled the entire process. Please check the refund KRWpoint. Order number:: ${orderId}`,
        sender: admin.name,
      };
      await reqSendAlarm(alarmPayload);

      alert("주문이 취소되었습니다.");
      onHide();
      refreshData();
    } catch (error) {
      console.error("주문 취소 실패:", error);
      alert("주문 취소에 실패했습니다.");
    }
  };

  // 부분 구매 - 금액 환불 (KRW) 완료 함수
  const 부분구매_금액환불_KRW = async () => {
    try {
      // 상태 업데이트 및 주문번호 업데이트
      const detailsToUpdate = orderDetails.map((detail) => ({
        PackageId:
          detail.editablePackageId !== undefined
            ? detail.editablePackageId
            : detail.PackageId,
        status: detail.status,
        productId: detail.productId,
      }));

      // PackageId가 없는 경우를 확인
      const hasMissingPackageId = detailsToUpdate.some(
        (detail) => detail.status === 2 && !detail.PackageId
      );

      if (hasMissingPackageId) {
        Swal.fire({
          title: "Error!",
          text: "일부 항목에 PackageId가 없습니다. 다시 확인해주세요.",
          icon: "error",
          confirmButtonText: "닫기",
        });
        return; // 이후 작업 중단
      }

      const updateRequests = detailsToUpdate.map((detail) => {
        return reqShopDetailUpdatePut(detail);
      });

      await Promise.all(updateRequests);

      let updateOrderPayload = {
        order_id: orderId,
        status: 6,
        fancallNumber: customerMessage,
      };

      let alarmMessage = `Please check the partially canceled amount. The order for the items that can be purchased has been completed.: ${orderId}`;

      // 모두 취소한 경우 포인트 전송 및 상태 업데이트
      if (orderDetails.every((detail) => detail.status === 9)) {
        updateOrderPayload = {
          order_id: orderId,
          status: 10,
          fancallNumber: customerMessage,
        };

        const pointsToRefund = shopData?.usePointKrw || 0;
        if (pointsToRefund > 0) {
          const userId = orderDetails[0]?.userId;
          await reqUpdateUserPoints(userId, { pointsToAdd: pointsToRefund });
        }
        // 모두 취소된 경우에는 쿠폰 리셋 로직
        if (orderId) {
          await reqResetCouponByOrderId(orderId);
        }

        // 포인트 환불 로그 로직
        const pointLogPayload = {
          userId: orderDetails[0]?.userId,
          grant: true,
          krwGrantPoint: pointsToRefund,
          status: 5,
          manager: admin.name,
        };

        if (pointsToRefund > 0) {
          try {
            await reqPostPointlog(pointLogPayload);
          } catch (error) {
            console.error("Failed to log points:", error);
          }
        }

        alarmMessage = `I canceled because I couldn't purchase all the items. Please check the refund amount and points. Order number: ${orderId}`;
      }
      await reqShopUpdate(updateOrderPayload);

      // 알림전송
      const alarmPayload = {
        userId: orderDetails[0].userId,
        read: 0,
        content: alarmMessage,
        sender: admin.name,
      };
      await reqSendAlarm(alarmPayload);

      Swal.fire({
        title: "Success!",
        text: "모든 상품의 견적서가 업데이트되었습니다.",
        icon: "success",
        confirmButtonText: "확인",
      }).then((result) => {
        if (result.isConfirmed) {
          onHide();
          refreshData();
        }
      });
    } catch (error) {
      console.error("견적서 업데이트 실패:", error);
      Swal.fire({
        title: "Error!",
        text: "견적서 업데이트에 실패했습니다.",
        icon: "error",
        confirmButtonText: "닫기",
      });
    }
  };

  const 부분구매_포인트환불_KRW = async () => {
    try {
      // 상태 업데이트 및 주문번호 업데이트
      const detailsToUpdate = orderDetails.map((detail) => ({
        PackageId:
          detail.editablePackageId !== undefined
            ? detail.editablePackageId
            : detail.PackageId,
        status: detail.status,
        productId: detail.productId,
      }));

      // PackageId가 없는 경우를 확인
      const hasMissingPackageId = detailsToUpdate.some(
        (detail) => detail.status === 2 && !detail.PackageId
      );

      if (hasMissingPackageId) {
        Swal.fire({
          title: "Error!",
          text: "일부 항목에 PackageId가 없습니다. 다시 확인해주세요.",
          icon: "error",
          confirmButtonText: "닫기",
        });
        return; // 이후 작업 중단
      }

      const updateRequests = detailsToUpdate.map((detail) =>
        reqShopDetailUpdatePut(detail)
      );
      await Promise.all(updateRequests);

      let updateOrderPayload = {
        order_id: orderId,
        status: 6,
        fancallNumber: customerMessage,
      };

      let alarmMessage = `Please check the partially canceled amount. The order for the items that can be purchased has been completed.: ${orderId}`;

      const allCanceled = orderDetails
        .filter((detail) => ![3, 4, 5].includes(detail.status))
        .every((detail) => detail.status === 9);
      const pointsToRefund =
        parseInt(userRefundPoints, 10) ||
        (allCanceled
          ? Math.round(
              (shopData?.usePointKrw || 0) +
                (shopData?.snKrwRealFee ?? 0) * 1.05
            )
          : Math.round(calculatePointConversionK()));

      if (allCanceled) {
        updateOrderPayload = {
          order_id: orderId,
          status: 10,
          fancallNumber: customerMessage,
        };

        if (pointsToRefund > 0) {
          const userId = orderDetails[0]?.userId;
          await reqUpdateUserPoints(userId, { pointsToAdd: pointsToRefund });
        }

        if (orderId) {
          await reqResetCouponByOrderId(orderId);
        }
        alarmMessage = `I canceled because I couldn't purchase all the items. Please check the refund amount and points. Order number: ${orderId}`;
      } else {
        if (pointsToRefund > 0) {
          const userId = orderDetails[0]?.userId;
          await reqUpdateUserPoints(userId, { pointsToAdd: pointsToRefund });
        }
      }

      // 포인트 환불 로그 로직
      const pointLogPayload = {
        userId: orderDetails[0]?.userId,
        grant: true,
        krwGrantPoint: pointsToRefund,
        status: 5,
        manager: admin.name,
      };

      if (pointsToRefund > 0) {
        try {
          await reqPostPointlog(pointLogPayload);
        } catch (error) {
          console.error("Failed to log points:", error);
        }
      }

      await reqShopUpdate(updateOrderPayload);

      // 알림전송
      const alarmPayload = {
        userId: orderDetails[0].userId,
        read: 0,
        content: alarmMessage,
        sender: admin.name,
      };
      await reqSendAlarm(alarmPayload);

      Swal.fire({
        title: "Success!",
        text: "모든 상품의 견적서가 업데이트되었습니다.",
        icon: "success",
        confirmButtonText: "확인",
      }).then((result) => {
        if (result.isConfirmed) {
          onHide();
          refreshData();
        }
      });
    } catch (error) {
      console.error("견적서 업데이트 실패:", error);
      Swal.fire({
        title: "Error!",
        text: "견적서 업데이트에 실패했습니다.",
        icon: "error",
        confirmButtonText: "닫기",
      });
    }
  };

  // 전체 취소 - 금액 환불 (USD) 완료 함수
  const 전체취소_금액환불_USD = async () => {
    // 나머지 물품 취소 상태 및 주문 취소 상태
    try {
      const updatedOrderDetails = orderDetails.map((detail) => ({
        ...detail,
        status: 9,
        isCanceled: true,
        PackageId: detail.editablePackageId || detail.PackageId,
      }));

      const updateRequests = updatedOrderDetails.map((detail) =>
        reqShopDetailUpdatePut(detail)
      );
      await Promise.all(updateRequests);

      const updateData = {
        order_id: orderId,
        status: 10,
        fancallNumber: customerMessage,
      };
      await reqShopUpdate(updateData);

      // 페이팔 환불 로직
      if (shopData?.snUsdRealFee && shopData?.paypalTransactionId) {
        await reqPaypalRefund({
          storeid: "ontactkorea",
          paytoken: shopData?.paypalTransactionId,
          currency: "USD",
          amount: shopData.snUsdRealFee,
        });
      }
      // 사용한 쿠폰 리셋 로직
      if (orderId) {
        await reqResetCouponByOrderId(orderId);
      }
      // 포인트를 사용했으면 포인트 리셋
      const pointsToRefund =
        parseFloat(userRefundPoints) || shopData?.snUsdPointApplied || 0;
      if (pointsToRefund > 0) {
        const userId = orderDetails[0]?.userId;
        await reqUpdateUserPointsUSD(userId, { pointsToAdd: pointsToRefund });
      }

      // USD 포인트 로그
      const pointLogPayload = {
        userId: orderDetails[0]?.userId,
        grant: true,
        usdGrantPoint: parseFloat(pointsToRefund.toFixed(2)),
        status: 5,
        manager: admin.name,
      };

      if (parseFloat(pointsToRefund.toFixed(2)) > 0) {
        try {
          await reqPostPointlog(pointLogPayload);
        } catch (error) {
          console.error("Failed to log points:", error);
        }
      }

      // 알림 전송
      const alarmPayload = {
        userId: orderDetails[0].userId,
        read: 0,
        content: `I have canceled the entire process. Please check the refund amount. Order number:: ${orderId}`,
        sender: admin.name,
      };
      await reqSendAlarm(alarmPayload);

      alert("주문이 취소되었습니다.");
      onHide();
      refreshData();
    } catch (error) {
      console.error("주문 취소 실패:", error);
      alert("주문 취소에 실패했습니다.");
    }
  };

  const 전체취소_포인트환불_USD = async () => {
    try {
      // 나머지 물품 취소 상태 및 주문 취소 상태
      const updatedOrderDetails = orderDetails.map((detail) => ({
        ...detail,
        status: 9,
        isCanceled: true,
        PackageId: detail.editablePackageId || detail.PackageId,
      }));

      const updateRequests = updatedOrderDetails.map((detail) =>
        reqShopDetailUpdatePut(detail)
      );
      await Promise.all(updateRequests);

      const updateData = {
        order_id: orderId,
        status: 10,
        fancallNumber: customerMessage,
      };
      await reqShopUpdate(updateData);

      // 포인트를 사용했으면 포인트 리셋
      const pointsToRefund =
        parseFloat(userRefundPoints) ||
        (shopData?.snUsdPointApplied || 0) +
          (shopData.snUsdRealFee
            ? parseFloat((shopData.snUsdRealFee * 1.05).toFixed(2))
            : 0);
      if (pointsToRefund > 0) {
        const userId = orderDetails[0]?.userId;
        await reqUpdateUserPointsUSD(userId, { pointsToAdd: pointsToRefund });
      }

      // USD 포인트 로그
      const pointLogPayload = {
        userId: orderDetails[0]?.userId,
        grant: true,
        usdGrantPoint: parseFloat(pointsToRefund.toFixed(2)),
        status: 5,
        manager: admin.name,
      };

      if (parseFloat(pointsToRefund.toFixed(2)) > 0) {
        try {
          await reqPostPointlog(pointLogPayload);
        } catch (error) {
          console.error("Failed to log points:", error);
        }
      }

      // 사용한 쿠폰 리셋 로직
      if (orderId) {
        await reqResetCouponByOrderId(orderId);
      }
      // 알림 전송
      const alarmPayload = {
        userId: orderDetails[0].userId,
        read: 0,
        content: `I have canceled the entire process. Please check the refund USD points. Order number: ${orderId}`,
        sender: admin.name,
      };
      await reqSendAlarm(alarmPayload);

      alert("주문이 취소되었습니다.");
      onHide();
      refreshData();
    } catch (error) {
      console.error("주문 취소 실패:", error);
      alert("주문 취소에 실패했습니다.");
    }
  };

  // 부분 구매 - 금액 환불 (USD) 완료 함수
  const 부분구매_금액환불_USD = async () => {
    try {
      // 상태 업데이트 및 주문번호 업데이트
      const detailsToUpdate = orderDetails.map((detail) => ({
        PackageId:
          detail.editablePackageId !== undefined
            ? detail.editablePackageId
            : detail.PackageId,
        status: detail.status,
        productId: detail.productId,
      }));

      // PackageId가 없는 경우를 확인
      const hasMissingPackageId = detailsToUpdate.some(
        (detail) => detail.status === 2 && !detail.PackageId
      );

      if (hasMissingPackageId) {
        Swal.fire({
          title: "Error!",
          text: "일부 항목에 PackageId가 없습니다. 다시 확인해주세요.",
          icon: "error",
          confirmButtonText: "닫기",
        });
        return; // 이후 작업 중단
      }

      const updateRequests = detailsToUpdate.map((detail) => {
        return reqShopDetailUpdatePut(detail);
      });

      await Promise.all(updateRequests);

      let updateOrderPayload = {
        order_id: orderId,
        status: 6,
        fancallNumber: customerMessage,
      };
      // 페이팔 환불 로직
      if (
        calculateRefundAmount().refundTotalUSD &&
        shopData?.paypalTransactionId
      ) {
        await reqPaypalRefund({
          storeid: "ontactkorea",
          paytoken: shopData?.paypalTransactionId,
          currency: "USD",
          amount: calculateRefundAmount().refundTotalUSD,
        });
      }

      let alarmMessage = `Please check the partially canceled amount. The order for the items that can be purchased has been completed.: ${orderId}`;

      // 모두 취소한 경우 포인트 전송 및 상태 업데이트
      if (orderDetails.every((detail) => detail.status === 9)) {
        updateOrderPayload = {
          order_id: orderId,
          status: 10,
          fancallNumber: customerMessage,
        };

        // 전체 취소된 경우에 사용한 쿠폰 리셋
        if (orderId) {
          await reqResetCouponByOrderId(orderId);
        }

        const pointsToRefund =
          parseFloat(userRefundPoints) || shopData?.snUsdPointApplied || 0;
        if (pointsToRefund > 0) {
          const userId = orderDetails[0]?.userId;
          await reqUpdateUserPointsUSD(userId, { pointsToAdd: pointsToRefund });
        }

        // 페이팔 환불 로직
        if (shopData?.snUsdRealFee && shopData?.paypalTransactionId) {
          await reqPaypalRefund({
            storeid: "ontactkorea",
            paytoken: shopData?.paypalTransactionId,
            currency: "USD",
            amount: shopData.snUsdRealFee,
          });
        }
        // USD 포인트 로그
        const pointLogPayload = {
          userId: orderDetails[0]?.userId,
          grant: true,
          usdGrantPoint: parseFloat(pointsToRefund.toFixed(2)),
          status: 5,
          manager: admin.name,
        };

        if (parseFloat(pointsToRefund.toFixed(2)) > 0) {
          try {
            await reqPostPointlog(pointLogPayload);
          } catch (error) {
            console.error("Failed to log points:", error);
          }
        }

        alarmMessage = `I canceled because I couldn't purchase all the items. Please check the refund amount and points. Order number: ${orderId}`;
      }

      await reqShopUpdate(updateOrderPayload);

      // 알림 전송
      const alarmPayload = {
        userId: orderDetails[0].userId,
        read: 0,
        content: alarmMessage,
        sender: admin.name,
      };
      await reqSendAlarm(alarmPayload);

      Swal.fire({
        title: "Success!",
        text: "모든 상품의 견적서가 업데이트되었습니다.",
        icon: "success",
        confirmButtonText: "확인",
      }).then((result) => {
        if (result.isConfirmed) {
          onHide();
          refreshData();
        }
      });
    } catch (error) {
      console.error("견적서 업데이트 실패:", error);
      Swal.fire({
        title: "Error!",
        text: "견적서 업데이트에 실패했습니다.",
        icon: "error",
        confirmButtonText: "닫기",
      });
    }
  };

  const calculateRefundPointsModal = (
    userRefundPoints: string | undefined,
    shopData: any,
    orderDetails: any[]
  ): string => {
    const allCanceled = orderDetails
      .filter((detail) => ![3, 4, 5].includes(detail.status))
      .every((detail) => detail.status === 9);

    if (userRefundPoints) {
      return parseFloat(userRefundPoints).toFixed(2);
    }

    if (allCanceled) {
      return (
        (shopData.snUsdRealFee ? shopData.snUsdRealFee * 1.05 : 0) +
        (shopData.snUsdPointApplied || 0)
      ).toFixed(2);
    } else {
      return Number(calculatePointConversionU()).toFixed(2);
    }
  };

  const 부분구매_포인트환불_USD = async () => {
    try {
      // 상태 업데이트 및 주문번호 업데이트
      const detailsToUpdate = orderDetails.map((detail) => ({
        PackageId:
          detail.editablePackageId !== undefined
            ? detail.editablePackageId
            : detail.PackageId,
        status: detail.status,
        productId: detail.productId,
      }));

      // PackageId가 없는 경우를 확인
      const hasMissingPackageId = detailsToUpdate.some(
        (detail) => detail.status === 2 && !detail.PackageId
      );

      if (hasMissingPackageId) {
        Swal.fire({
          title: "Error!",
          text: "일부 항목에 PackageId가 없습니다. 다시 확인해주세요.",
          icon: "error",
          confirmButtonText: "닫기",
        });
        return; // 이후 작업 중단
      }

      const updateRequests = detailsToUpdate.map((detail) =>
        reqShopDetailUpdatePut(detail)
      );
      await Promise.all(updateRequests);

      let updateOrderPayload = {
        order_id: orderId,
        status: 6,
        fancallNumber: customerMessage,
      };

      let alarmMessage = `Please check the partially canceled amount. The order for the items that can be purchased has been completed.: ${orderId}`;

      const pointsToRefund = parseFloat(
        calculateRefundPointsModal(userRefundPoints, shopData, orderDetails)
      );

      if (pointsToRefund > 0) {
        const userId = orderDetails[0]?.userId;
        await reqUpdateUserPointsUSD(userId, { pointsToAdd: pointsToRefund });
      }

      const allCanceled = orderDetails.every((detail) => detail.status === 9);

      if (allCanceled) {
        updateOrderPayload = {
          order_id: orderId,
          status: 10,
          fancallNumber: customerMessage,
        };
        alarmMessage = `I canceled because I couldn't purchase all the items. Please check the refund amount and points. Order number: ${orderId}`;

        if (orderId) {
          await reqResetCouponByOrderId(orderId);
        }
      }

      // USD 포인트 로그
      const pointLogPayload = {
        userId: orderDetails[0]?.userId,
        grant: true,
        usdGrantPoint: parseFloat(pointsToRefund.toFixed(2)),
        status: 5,
        manager: admin.name,
      };

      if (parseFloat(pointsToRefund.toFixed(2)) > 0) {
        try {
          await reqPostPointlog(pointLogPayload);
        } catch (error) {
          console.error("Failed to log points:", error);
        }
      }

      await reqShopUpdate(updateOrderPayload);

      // 알림전송
      const alarmPayload = {
        userId: orderDetails[0].userId,
        read: 0,
        content: alarmMessage,
        sender: admin.name,
      };
      await reqSendAlarm(alarmPayload);

      Swal.fire({
        title: "Success!",
        text: "모든 상품의 견적서가 업데이트되었습니다.",
        icon: "success",
        confirmButtonText: "확인",
      }).then((result) => {
        if (result.isConfirmed) {
          onHide();
          refreshData();
        }
      });
    } catch (error) {
      console.error("견적서 업데이트 실패:", error);
      Swal.fire({
        title: "Error!",
        text: "견적서 업데이트에 실패했습니다.",
        icon: "error",
        confirmButtonText: "닫기",
      });
    }
  };

  const handleClose = () => {
    onHide();
    window.location.reload();
  };

  return (
    <Fragment>
      <StyledModal
        show={show}
        onHide={handleClose}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header className="bg-dark text-white">
          <Modal.Title>{orderId}</Modal.Title>
          <button className="btn-close-custom" onClick={handleClose}>
            &#10006;
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="container-fluid">
            <Table bordered className="text-center table-dark table-hover">
              <div className="container-fluid">
                <Table bordered className="text-center table-dark table-hover">
                  <tbody>
                    <tr>
                      <th>결제 방식</th>
                      <td>{shopData.PaymentMethod === 1 ? "KRW" : "USD"}</td>
                    </tr>
                    <tr>
                      <th>환불 유형</th>
                      <td>{shopData?.refund ? "금액 환불" : "포인트 환불"}</td>
                    </tr>
                    <tr>
                      <th>주문 취소 유형</th>
                      <td>{shopData?.failed ? "전체 취소" : "부분 구매"}</td>
                    </tr>
                    {shopData.refund && shopData.PaymentMethod !== 2 && (
                      <tr>
                        <th>환불 계좌</th>
                        <td>
                          은행: {shopData.option1} <br />
                          예금주: {shopData.option2} <br />
                          계좌번호: {shopData.account}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </Table>
          </div>
          <div className="container-fluid mb-3">
            <StyledTable bordered className="table-hover">
              <thead>
                <tr>
                  <th>NO</th>
                  <th>상품명</th>
                  <th>옵션 1</th>
                  <th>옵션 2</th>
                  <th>수량</th>
                  <th>단가</th>
                  <th>Link</th>
                  <th>팬콜</th>
                  <th>주문 번호</th>
                  <th>조치</th>
                </tr>
              </thead>
              <tbody>
                {orderDetails.map((detail, index) => (
                  <tr key={index}>
                    <td
                      style={
                        [3, 4, 5, 9, 10].includes(detail.status)
                          ? { textDecoration: "line-through" }
                          : undefined
                      }
                    >
                      {detail.productId}
                    </td>
                    <td
                      style={
                        [3, 4, 5, 9, 10].includes(detail.status)
                          ? { textDecoration: "line-through" }
                          : undefined
                      }
                    >
                      {detail.name}
                    </td>
                    <td
                      style={
                        [3, 4, 5, 9, 10].includes(detail.status)
                          ? { textDecoration: "line-through" }
                          : undefined
                      }
                    >
                      {detail.option1}
                    </td>
                    <td
                      style={
                        [3, 4, 5, 9, 10].includes(detail.status)
                          ? { textDecoration: "line-through" }
                          : undefined
                      }
                    >
                      {detail.option2}
                    </td>
                    <td
                      style={
                        [3, 4, 5, 9, 10].includes(detail.status)
                          ? { textDecoration: "line-through" }
                          : undefined
                      }
                    >
                      {detail.quantity}
                    </td>
                    <td
                      style={
                        [3, 4, 5, 9, 10].includes(detail.status)
                          ? { textDecoration: "line-through" }
                          : undefined
                      }
                    >
                      {detail.price}
                    </td>
                    <td
                      style={
                        [3, 4, 5, 9, 10].includes(detail.status)
                          ? { textDecoration: "line-through" }
                          : undefined
                      }
                    >
                      <a
                        href={
                          detail.url.startsWith("http")
                            ? detail.url
                            : `https://${detail.url}`
                        }
                        onClick={(e) => {
                          e.preventDefault();
                          const fullUrl = detail.url.includes("://")
                            ? detail.url
                            : `https://${detail.url}`;
                          copyToClipboardAndNavigate(fullUrl);
                        }}
                        target="_blank"
                        className="d-inline-block text-truncate"
                        style={{ maxWidth: "200px" }}
                        title={detail.url}
                      >
                        {detail.url}
                      </a>
                    </td>
                    <tr>
                      <td colSpan={3}>
                        <Fragment key={index}>
                          {detail.fancallName ||
                          detail.fancallNumber ||
                          detail.fancallEmail ||
                          detail.fancallKakao ||
                          detail.fancallSnsLine ? (
                            <>
                              <Button
                                variant={
                                  detail.fancallName ||
                                  detail.fancallNumber ||
                                  detail.fancallEmail ||
                                  detail.fancallKakao ||
                                  detail.fancallSnsLine
                                    ? "primary"
                                    : "secondary"
                                }
                                onClick={() => toggleItem(index)}
                                aria-expanded={openItems[index]}
                                disabled={
                                  !(
                                    detail.fancallName ||
                                    detail.fancallNumber ||
                                    detail.fancallEmail ||
                                    detail.fancallKakao ||
                                    detail.fancallSnsLine
                                  )
                                }
                              >
                                인적사항
                              </Button>
                              {openItems[index] && (
                                <div className="mt-3">
                                  <p>
                                    <strong>이름:</strong> {detail.fancallName}
                                  </p>
                                  <p>
                                    <strong>생년월일:</strong>{" "}
                                    {detail.fancallBirthday
                                      ? formatDate(detail.fancallBirthday)
                                      : "-"}
                                  </p>
                                  <p>
                                    <strong>연락처:</strong>{" "}
                                    {detail.fancallNumber}
                                  </p>
                                  <p>
                                    <strong>이메일:</strong>{" "}
                                    {detail.fancallEmail}
                                  </p>
                                  <p>
                                    <strong>국가:</strong>{" "}
                                    {CODE_COUNTRY_ITEMS[detail.fancallCountry]}
                                  </p>
                                  <p>
                                    <strong>카카오톡:</strong>{" "}
                                    {detail.fancallKakao}
                                  </p>
                                  <p>
                                    <strong>라인:</strong>{" "}
                                    {detail.fancallSnsLine}
                                  </p>
                                </div>
                              )}
                            </>
                          ) : (
                            <p>팬콜 정보가 없습니다.</p>
                          )}
                        </Fragment>
                      </td>
                    </tr>
                    <td>
                      <Form>
                        <Row className="align-items-center">
                          <Col xs={8}>
                            <Form.Control
                              type="text"
                              value={
                                detail.editablePackageId || detail.PackageId
                              }
                              onChange={(e) =>
                                handleOrderDetailChange(
                                  index,
                                  "editablePackageId",
                                  e.target.value
                                )
                              }
                              disabled={
                                detail.isCanceled ||
                                [3, 4, 5, 10].includes(detail.status)
                              }
                              style={{ width: "100%" }}
                            />
                          </Col>
                          <Col xs={4}>
                            <Button
                              variant="primary"
                              size="sm"
                              onClick={() => handleSendEstimates(index)}
                              disabled={
                                detail.isCanceled ||
                                [3, 4, 5, 10].includes(detail.status)
                              }
                              style={{ width: "100%" }}
                            >
                              수정
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </td>
                    <td>
                      {detail.status === 9 ? (
                        <Button
                          variant="success"
                          onClick={() => handleToggleItemCancellation(index)}
                        >
                          <i className="bi bi-arrow-counterclockwise"></i>{" "}
                          되돌리기
                        </Button>
                      ) : (
                        <Button
                          variant="warning"
                          onClick={() => handleToggleItemCancellation(index)}
                          disabled={[3, 4, 5, 10].includes(detail.status)}
                        >
                          <i className="bi bi-x"></i> 취소
                        </Button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </StyledTable>
          </div>
          <Fragment>
            <div className="container-fluid mb-3">
              <Table bordered className="text-center table-dark table-hover">
                <thead>
                  {shopData.PaymentMethod === 1 ? (
                    <tr>
                      <th>Title</th>
                      <th>KRW</th>
                    </tr>
                  ) : (
                    <tr>
                      <th>Title</th>
                      <th>USD</th>
                    </tr>
                  )}
                </thead>
                <tbody>
                  {shopData.PaymentMethod === 1 && (
                    <>
                      <tr>
                        <th>상품 가격</th>
                        <td>{productTotal}</td>
                      </tr>
                      <tr>
                        <th>배송비</th>
                        <td>{domesticShippingFee}</td>
                      </tr>
                      <tr>
                        <th>서비스 비용</th>
                        <td>{serviceFeeValue}</td>
                      </tr>
                      <tr>
                        <th>총합</th>
                        <td>{total}</td>
                      </tr>
                    </>
                  )}
                  {(shopData.PaymentMethod === 2 ||
                    shopData.PaymentMethod === 3) && (
                    <>
                      <tr>
                        <th>상품 가격</th>
                        <td>{convertToUSD(productTotal)}</td>
                      </tr>
                      <tr>
                        <th>배송비</th>
                        <td>{convertToUSD(domesticShippingFee)}</td>
                      </tr>
                      <tr>
                        <th>서비스 비용</th>
                        <td>{convertToUSD(serviceFeeValue)}</td>
                      </tr>
                      <tr>
                        <th>Paypal Cost(5%)</th>
                        <td>{convertToUSD(paypalFee)}</td>
                      </tr>
                      <tr>
                        <th>총합</th>
                        <td>{convertToUSD(Utotal)}</td>
                      </tr>
                    </>
                  )}
                </tbody>
              </Table>
            </div>
            <div className="container-fluid mb-3">
              <Table bordered className="text-center">
                <thead>
                  {shopData.PaymentMethod === 1 ? (
                    <tr>
                      <th>Title</th>
                      <th>KRW</th>
                    </tr>
                  ) : (
                    <tr>
                      <th>Title</th>
                      <th>USD</th>
                    </tr>
                  )}
                </thead>
                <tbody>
                  {shopData.PaymentMethod === 1 && (
                    <>
                      <tr style={{ backgroundColor: "red" }}>
                        <th>고객 결제 금액</th>
                        <td>{shopData.paymentAmountKRW} KRW</td>
                      </tr>
                      <tr>
                        <th>사용포인트</th>
                        <td>{shopData?.usePointKrw || 0} KRW</td>
                      </tr>
                      <tr style={{ backgroundColor: "red" }}>
                        <th>환불 금액</th>
                        <td>{calculateRefundAmount().refundTotalKRW} KRW</td>
                      </tr>
                      <tr style={{ backgroundColor: "red" }}>
                        <th>포인트 환불 금액</th>
                        <td colSpan={1}>{calculatePointConversionK()} KRW</td>
                      </tr>
                    </>
                  )}
                  {(shopData.PaymentMethod === 2 ||
                    shopData.PaymentMethod === 3) && (
                    <>
                      <tr style={{ backgroundColor: "red" }}>
                        <th>고객 결제 금액</th>
                        <td>{shopData.snUsdRealFee} USD</td>
                      </tr>
                      <tr>
                        <th>사용포인트</th>
                        <td>{shopData?.snUsdPointApplied || 0} USD</td>
                      </tr>
                      <tr style={{ backgroundColor: "red" }}>
                        <th>환불 금액</th>
                        <td>{calculateRefundAmount().refundTotalUSD} USD</td>
                      </tr>
                      <tr style={{ backgroundColor: "red" }}>
                        <th>포인트 환불 금액</th>
                        <td colSpan={1}>{calculatePointConversionU()} USD</td>
                      </tr>
                    </>
                  )}
                </tbody>
              </Table>
            </div>
          </Fragment>
        </Modal.Body>

        <Modal.Footer>
          {/* <Button variant="secondary" onClick={refundByPaypal}>
            (환불 테스트용)
          </Button> */}
          {/* <Button variant="secondary" onClick={handleCancelOrderClick}>
            전체취소
          </Button> */}
          <Button
            variant="success"
            onClick={handleCompleteButtonClick}
            // disabled={!allStatusTwoItemsHaveOrderNumbers()}
          >
            완료
          </Button>
          {/* <Button
            variant="info"
            onClick={handleRefundPointsClick}
            disabled={pointsRefunded || shopData?.fancallCountry === 1}
          >
            포인트 환불
          </Button> */}
        </Modal.Footer>
      </StyledModal>
      <Modal
        show={confirmSendModalShow}
        onHide={() => setConfirmSendModalShow(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>견적서 완료 확인</Modal.Title>
        </Modal.Header>
        <Modal.Body>견적서를 완료하시겠습니까?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setConfirmSendModalShow(false)}
          >
            취소
          </Button>
          <Button variant="primary" onClick={handleConfirmSendEstimate}>
            완료
          </Button>
        </Modal.Footer>
      </Modal>
      {/* <Modal
        show={confirmCancelModalShow}
        onHide={() => setConfirmCancelModalShow(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>주문 취소 확인</Modal.Title>
        </Modal.Header>
        <Modal.Body>정말로 주문을 취소하시겠습니까?</Modal.Body>
        <Form.Group className="mb-3" controlId="formCustomerMessage">
          <Form.Label>고객 메세지</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="고객에게 보내는 취소 이유"
            value={customerMessage}
            onChange={(e) => setCustomerMessage(e.target.value)}
          />
        </Form.Group>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setConfirmCancelModalShow(false)}
          >
            아니요
          </Button>
          <Button variant="danger" onClick={handleConfirmCancelOrder}>
            네, 취소할게요
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showRefundPointsConfirmModal}
        onHide={() => setShowRefundPointsConfirmModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>포인트 환불 확인</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          정말로 포인트를 환불하시겠습니까?
          <Form.Group className="mt-3">
            <Form.Label>
              환불 포인트 금액 (기본값: {calculatePointConversionK()} KRW)
            </Form.Label>
            <Form.Control
              type="number"
              placeholder="수정할 포인트 금액을 입력하세요"
              value={userRefundPoints}
              onChange={handleUserRefundPointsChange}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowRefundPointsConfirmModal(false)}
          >
            아니요
          </Button>
          <Button variant="danger" onClick={performRefundPoints}>
            네, 환불할게요
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={depositModalShow}
        onHide={() => setDepositModalShow(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>입금 확인</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          환불 계좌 정보:
          <br />
          은행: {shopData.option1}
          <br />
          예금주: {shopData.option2}
          <br />
          계좌번호: {shopData.account}
          <br />
          환불금액: {calculateRefundAmount().refundTotalKRW}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setDepositModalShow(false)}
          >
            닫기
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              setDepositModalShow(false);
              setMessageModalShow(true);
            }}
          >
            확인
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={pointsModalShow}
        onHide={() => setPointsModalShow(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>포인트 확인</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mt-3">
            <Form.Label>포인트 금액</Form.Label>
            <Form.Control
              type="number"
              placeholder="포인트 금액을 입력하세요"
              value={userRefundPoints}
              onChange={handleUserRefundPointsChange}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setPointsModalShow(false)}
          >
            닫기
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              setPointsModalShow(false);
              setMessageModalShow(true);
            }}
          >
            확인
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={messageModalShow}
        onHide={() => setMessageModalShow(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>메시지 확인</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mt-3">
            <Form.Label>고객에게 보낼 메시지</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="메시지를 입력하세요"
              value={customerMessage}
              onChange={(e) => setCustomerMessage(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setMessageModalShow(false)}
          >
            닫기
          </Button>
          <Button
            variant="primary"
            onClick={handleConfirmSendEstimate}
          >
            완료
          </Button>
        </Modal.Footer>
      </Modal> */}

      {/* if 조건에 따른 모달 */}
      {/* 전체 취소 - 금액 환불 (KRW) */}
      <Modal
        show={modalKRWTotalRefundShow}
        onHide={() => setModalKRWTotalRefundShow(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>전체 취소 - 금액 환불 (KRW)</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            <strong>환불 계좌 정보:</strong>
            <br />
            <span>은행: {shopData.option1}</span>
            <br />
            <span>예금주: {shopData.option2}</span>
            <br />
            <span>계좌번호: {shopData.account}</span>
            <br />
            <strong>사용 포인트:</strong> {shopData?.usePointKrw || 0} KRW
          </div>
          <hr />
          <div className="mb-3">
            <strong>환불금액:</strong> {shopData.snKrwRealFee} KRW
          </div>
          <hr />
          <Form.Group className="mt-3">
            <Form.Label>고객에게 보낼 메시지</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="메시지를 입력하세요"
              value={customerMessage}
              onChange={(e) => setCustomerMessage(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setModalKRWTotalRefundShow(false)}
          >
            닫기
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              setModalKRWTotalRefundShow(false);
              전체취소_금액환불_KRW();
            }}
          >
            완료
          </Button>
        </Modal.Footer>
      </Modal>

      {/* 전체 취소 - 포인트 환불 (KRW) */}
      <Modal
        show={modalKRWTotalPointShow}
        onHide={() => setModalKRWTotalPointShow(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>전체 취소 - 포인트 환불 (KRW)</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            <br />
            <strong>사용 포인트:</strong> {shopData?.usePointKrw || 0} KRW
          </div>
          <div className="mb-3">
            <strong>환불포인트:</strong>{" "}
            {shopData.paymentAmountKRW
              ? Math.floor(shopData.paymentAmountKRW * 1.05)
              : "N/A"}{" "}
            KRW
          </div>
          <hr />
          <Form.Group className="mt-3">
            <Form.Label>고객에게 보낼 메시지</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="메시지를 입력하세요"
              value={customerMessage}
              onChange={(e) => setCustomerMessage(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mt-3">
            <Form.Label>환불 포인트 금액(KRW)</Form.Label>
            <Form.Control
              type="number"
              placeholder="수정할 포인트 금액을 입력하세요"
              value={
                userRefundPoints ||
                parseInt(shopData?.usePointKrw?.toString() || "0", 10) +
                  (shopData.paymentAmountKRW
                    ? Math.floor(shopData.paymentAmountKRW * 1.05)
                    : 0)
              }
              onChange={handleUserRefundPointsChange}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setModalKRWTotalPointShow(false)}
          >
            닫기
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              setModalKRWTotalPointShow(false);
              전체취소_포인트환불_KRW();
            }}
          >
            완료
          </Button>
        </Modal.Footer>
      </Modal>

      {/* 부분 구매 - 금액 환불 (KRW) */}
      <Modal
        show={modalKRWPartialRefundShow}
        onHide={() => setModalKRWPartialRefundShow(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>부분 구매 - 금액 환불 (KRW)</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            <strong>환불 계좌 정보:</strong>
            <br />
            <span>은행: {shopData.option1}</span>
            <br />
            <span>예금주: {shopData.option2}</span>
            <br />
            <span>계좌번호: {shopData.account}</span>
          </div>
          <hr />
          <div className="mb-3">
            <strong>환불금액:</strong> {calculateRefundAmount().refundTotalKRW}{" "}
            KRW
          </div>
          <hr />
          {orderDetails.every((detail) => detail.status === 9) && (
            <>
              <div className="mb-3">
                <strong>사용 포인트:</strong> {shopData?.usePointKrw || 0} KRW
              </div>
              <hr />
            </>
          )}
          <Form.Group className="mt-3">
            <Form.Label>고객에게 보낼 메시지</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="메시지를 입력하세요"
              value={customerMessage}
              onChange={(e) => setCustomerMessage(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setModalKRWPartialRefundShow(false)}
          >
            닫기
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              setModalKRWPartialRefundShow(false);
              부분구매_금액환불_KRW();
            }}
          >
            완료
          </Button>
        </Modal.Footer>
      </Modal>

      {/* 부분 구매 - 포인트 환불 (KRW) */}
      <Modal
        show={modalKRWPartialPointShow}
        onHide={() => setModalKRWPartialPointShow(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>부분 구매 - 포인트 환불 (KRW)</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {orderDetails
            .filter((detail) => ![3, 4, 5].includes(detail.status))
            .every((detail) => detail.status === 9) && (
            <>
              <div className="mb-3">
                <strong>사용 포인트:</strong> {shopData?.usePointKrw || 0} KRW
              </div>
              <hr />
            </>
          )}
          <div className="mb-3">
            <strong>환불포인트:</strong>{" "}
            {orderDetails
              .filter((detail) => ![3, 4, 5].includes(detail.status))
              .every((detail) => detail.status === 9)
              ? Math.round(
                  (shopData?.usePointKrw || 0) +
                    (shopData?.snKrwRealFee ?? 0) * 1.05
                )
              : Math.round(calculatePointConversionK())}{" "}
            KRW
          </div>
          <Form.Group className="mt-3">
            <Form.Label>환불 포인트 금액(KRW)</Form.Label>
            <Form.Control
              type="number"
              placeholder="수정할 포인트 금액을 입력하세요"
              value={
                userRefundPoints ||
                (orderDetails
                  .filter((detail) => ![3, 4, 5].includes(detail.status))
                  .every((detail) => detail.status === 9)
                  ? Math.round(
                      (shopData?.usePointKrw || 0) +
                        (shopData?.snKrwRealFee ?? 0) * 1.05
                    )
                  : Math.round(calculatePointConversionK())
                ).toString()
              }
              onChange={handleUserRefundPointsChange}
            />
          </Form.Group>
          <hr />
          <Form.Group className="mt-3">
            <Form.Label>고객에게 보낼 메시지</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="메시지를 입력하세요"
              value={customerMessage}
              onChange={(e) => setCustomerMessage(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setModalKRWPartialPointShow(false)}
          >
            닫기
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              setModalKRWPartialPointShow(false);
              부분구매_포인트환불_KRW();
            }}
          >
            완료
          </Button>
        </Modal.Footer>
      </Modal>

      {/* 전체 취소 - 금액 환불(USD) */}
      <Modal
        show={modalPaypalTotalRefundShow}
        onHide={() => setModalPaypalTotalRefundShow(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>전체 취소 - 금액 환불 (USD)</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            <strong>사용 포인트:</strong> {shopData?.snUsdPointApplied || 0} KRW
          </div>
          <hr />
          <div className="mb-3">
            <strong>환불 금액:</strong> {shopData.snUsdRealFee} KRW
          </div>
          <hr />
          <Form.Group className="mt-3">
            <Form.Label>고객에게 보낼 메시지</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="메시지를 입력하세요"
              value={customerMessage}
              onChange={(e) => setCustomerMessage(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setModalPaypalTotalRefundShow(false)}
          >
            닫기
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              setModalPaypalTotalRefundShow(false);
              전체취소_금액환불_USD();
            }}
          >
            완료
          </Button>
        </Modal.Footer>
      </Modal>

      {/* 전체 취소 - 포인트 환불(USD) */}
      <Modal
        show={modalPaypalTotalPointShow}
        onHide={() => setModalPaypalTotalPointShow(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>전체 취소 - 포인트 환불 (USD)</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            <strong>사용 포인트:</strong> {shopData.snUsdPointApplied || 0} USD
          </div>
          <div className="mb-3">
            <strong>환불 금액:</strong>{" "}
            {shopData.snUsdRealFee
              ? (shopData.snUsdRealFee * 1.05).toFixed(2)
              : "N/A"}{" "}
            USD
          </div>
          <Form.Group className="mt-3">
            <Form.Label>환불 포인트 금액 (USD)</Form.Label>
            <Form.Control
              type="number"
              placeholder="수정할 포인트 금액을 입력하세요"
              value={
                userRefundPoints ||
                (
                  (shopData?.snUsdPointApplied || 0) +
                  (shopData.snUsdRealFee
                    ? parseFloat((shopData.snUsdRealFee * 1.05).toFixed(2))
                    : 0)
                ).toString()
              }
              onChange={handleUserRefundPointsChange}
            />
          </Form.Group>
          <hr />
          <Form.Group className="mt-3">
            <Form.Label>고객에게 보낼 메시지</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="메시지를 입력하세요"
              value={customerMessage}
              onChange={(e) => setCustomerMessage(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setModalPaypalTotalPointShow(false)}
          >
            닫기
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              setModalPaypalTotalPointShow(false);
              전체취소_포인트환불_USD();
            }}
          >
            완료
          </Button>
        </Modal.Footer>
      </Modal>

      {/* 부분 구매 - 금액 환불(USD) */}
      <Modal
        show={modalPaypalPartialRefundShow}
        onHide={() => setModalPaypalPartialRefundShow(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>부분 구매 - 금액 환불 (USD)</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            <strong>환불금액:</strong>{" "}
            {orderDetails.every((detail) => detail.status === 9)
              ? shopData.snUsdRealFee
                ? shopData.snUsdRealFee.toFixed(2)
                : "N/A"
              : calculateRefundAmount().refundTotalUSD}{" "}
            USD
          </div>
          <hr />
          {orderDetails.every((detail) => detail.status === 9) && (
            <>
              <div className="mb-3">
                <strong>사용 포인트:</strong> {shopData?.snUsdPointApplied || 0}{" "}
                USD
              </div>
              <hr />
            </>
          )}
          <Form.Group className="mt-3">
            <Form.Label>고객에게 보낼 메시지</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="메시지를 입력하세요"
              value={customerMessage}
              onChange={(e) => setCustomerMessage(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setModalPaypalPartialRefundShow(false)}
          >
            닫기
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              setModalPaypalPartialRefundShow(false);
              부분구매_금액환불_USD();
            }}
          >
            완료
          </Button>
        </Modal.Footer>
      </Modal>

      {/* 부분 구매 - 포인트 환불(USD) */}
      <Modal
        show={modalPaypalPartialPointShow}
        onHide={() => setModalPaypalPartialPointShow(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>부분 구매 - 포인트 환불 (USD)</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {orderDetails
            .filter((detail) => ![3, 4, 5].includes(detail.status))
            .every((detail) => detail.status === 9) && (
            <>
              <div className="mb-3">
                <strong>사용 포인트:</strong> {shopData?.snUsdPointApplied || 0}{" "}
                USD
              </div>
              <div className="mb-3">
                <strong>결제 금액:</strong> {shopData?.snUsdRealFee || 0} USD
              </div>
              <hr />
            </>
          )}
          <div className="mb-3">
            <strong>환불포인트:</strong>{" "}
            {orderDetails
              .filter((detail) => ![3, 4, 5].includes(detail.status))
              .every((detail) => detail.status === 9)
              ? (
                  (shopData.snUsdRealFee ? shopData.snUsdRealFee * 1.05 : 0) +
                  (shopData.snUsdPointApplied || 0)
                ).toFixed(2)
              : calculatePointConversionU()}{" "}
            USD
          </div>
          <Form.Group className="mt-3">
            <Form.Label>환불 포인트 금액(USD)</Form.Label>
            <Form.Control
              type="number"
              placeholder="수정할 포인트 금액을 입력하세요"
              value={
                userRefundPoints ||
                (orderDetails
                  .filter((detail) => ![3, 4, 5].includes(detail.status))
                  .every((detail) => detail.status === 9)
                  ? (
                      (shopData.snUsdRealFee
                        ? shopData.snUsdRealFee * 1.05
                        : 0) + (shopData.snUsdPointApplied || 0)
                    ).toFixed(2)
                  : calculatePointConversionU()
                ).toString()
              }
              onChange={handleUserRefundPointsChange}
            />
          </Form.Group>
          <hr />
          <Form.Group className="mt-3">
            <Form.Label>고객에게 보낼 메시지</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="메시지를 입력하세요"
              value={customerMessage}
              onChange={(e) => setCustomerMessage(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setModalPaypalPartialPointShow(false)}
          >
            닫기
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              setModalPaypalPartialPointShow(false);
              부분구매_포인트환불_USD();
            }}
          >
            완료
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default ShopPaidModal;

const StyledTable = styled(Table)`
  th,
  td {
    width: 12.5%;
    text-align: center;
  }
`;

const StyledModal = styled(Modal)`
  .modal-dialog {
    max-width: 85%;

    @media (max-width: 768px) {
      max-width: 95%;
    }
  }
`;
