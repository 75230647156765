import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { setPopupImage } from "../../../redux/popupImageReducer";
import styled from "styled-components";
import {
  reqCreatePopup,
  reqDeletePopup,
  reqGetPopupList,
  reqModifyPopup,
  reqSetPopupStatus,
} from "../../../requests/popup";
import PopupModifyModal from "../../../components/modal/PopupModifyModal";
import PopupModal from "../../../components/modal/PopupModal";
import Swal from "sweetalert2";
import AdminHeadTitle from "../../../components/AdminHeadTitle";

const AEvent: React.FC = () => {
  const [image, setImage] = useState<any>(null);
  // const [showPopup, setShowPopup] = useState<boolean>(false);
  const [popupList, setPopupList] = useState<any>([]);

  const [showModifyModal, setShowModifyModal] = useState<any>(false);
  const [showPreviewModal, setShowPreviewModal] = useState<any>(false);
  const [clickedPopupId, setClickedPopupId] = useState<number>(-1);
  const [createMode, setCreateMode] = useState<boolean>(false);
  const [checkItems, setCheckItems] = useState(new Set());

  const dispatch = useDispatch();

  useEffect(() => {
    fetchPopupList();
  }, []);

  const fetchPopupList = async () => {
    const response = await reqGetPopupList();
    setPopupList(response.data);
    return;
  };

  // const handleSaveChanges = () => {
  //   if (typeof image === "string") {
  //     console.log("Dispatching setPopupImage with image:", image);
  //     dispatch(setPopupImage(image));
  //   }
  //   setShowPopup(true);
  // };

  // const handleClosePopup = () => {
  //   setShowPopup(false);
  // };

  const openModifyModal = (e: any) => {
    const popupId = idToPopupListIndex(Number(e.target.id.split("-")[1]));
    setClickedPopupId(popupId);
    setShowModifyModal(true);
  };

  const openPreviewModal = (e: any) => {
    const popupId = idToPopupListIndex(Number(e.target.id.split("-")[1]));
    setClickedPopupId(popupId);
    setShowPreviewModal(true);
  };

  const openCreateModal = (e: any) => {
    setCreateMode(true);
    setShowModifyModal(true);
  };

  const [isLoading, setIsLoading] = useState(false);
  const handleModifyModal = async (submitData: any) => {
    setShowModifyModal(false);

    setIsLoading(true);
    const formData = new FormData();
    formData.append("id", submitData?.id || "0");
    formData.append("title", submitData.title);
    formData.append("link", submitData.link);
    formData.append("status", submitData?.status || "0");
    formData.append("startDue", submitData.startDue);
    formData.append("endDue", submitData.endDue);
    formData.append("pcPos", submitData?.pcPos || "0");
    formData.append("pcLeftOffset", submitData?.pcLeftOffset || "0");
    formData.append("pcTopOffset", submitData?.pcTopOffset || "0");
    formData.append("mobilePos", submitData?.mobilePos || "0");
    formData.append("mobileLeftOffset", submitData?.mobileLeftOffset || "0");
    formData.append("mobileTopOffset", submitData?.mobileTopOffset || "0");
    formData.append("onedayBan", submitData?.onedayBan || "0");
    formData.append("file", image);

    const res = submitData.id
      ? await reqModifyPopup(formData)
      : await reqCreatePopup(formData);
    setIsLoading(false);

    if (res) {
      submitData.id
        ? Swal.fire("팝업 수정이 완료되었습니다.", "", "success")
        : Swal.fire("팝업 생성이 완료되었습니다.", "", "success");
    }
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  async function handleUploadImage(event: { target: { files: any[] } }) {
    const file = event.target.files[0];
    if (!file) {
      return;
    }
    const reader = new FileReader();
    reader.onload = () => {};
    reader.readAsDataURL(file);
    setImage(file);
    return;
  }

  async function setPopupStatus(e: any) {
    const popupId = Number(e.target.id.split("-")[1]);
    const popupIndex = idToPopupListIndex(popupId);
    const popupStatus = popupList[popupIndex].status;
    const res = await reqSetPopupStatus({
      id: popupId,
      status: popupStatus ? 0 : 1,
    });
    if (res) {
      if (!popupStatus == true)
        Swal.fire("팝업이 게시되었습니다.", "", "success");
      else Swal.fire("팝업이 내려갔습니다.", "", "success");
    }
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }

  const checkItemHandler = (id: any, isChecked: any) => {
    if (isChecked) {
      checkItems.add(id);
      setCheckItems(checkItems);
    } else {
      checkItems.delete(id);
      setCheckItems(checkItems);
    }
  };

  const checkHandled = ({ target }: any) => {
    const checkedId = target.id.split("-")[2];
    checkItemHandler(checkedId, target.checked);
  };

  async function deletePopupItems() {
    const popupIdList = Array.from(checkItems);
    await reqDeletePopup({ popupIdList: popupIdList });
  }

  const deleteHandler = async () => {
    if (checkItems.size == 0) {
      return;
    }
    Swal.fire({
      title: "선택한 팝업들을 삭제하시겠습니까?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "승인",
      cancelButtonText: "취소",
      reverseButtons: true,
    }).then(async (result) => {
      await deletePopupItems();

      if (result.isConfirmed) {
        Swal.fire("삭제가 완료되었습니다.", "", "success");
      }
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    });
  };

  const idToPopupListIndex = (id: number): number => {
    return popupList.findIndex((popup: any) => Number(popup.id) === id);
  };

  return (
    <main id="main" className="main">
      <main>
        <AdminHeadTitle
          subtitle1="서비스 관리"
          subtitle2="이벤트 관리"
          title="이벤트 관리"
        />

        <div className="container-fluid px-4">
          <div className="container">
            <div className="row p-4">
              <CustomCol3 className="col-3 text-center">
                <h5 className="fw-bold">팝업 리스트</h5>
              </CustomCol3>
              {/* 리스트 */}
              {popupList && (
                <PopupListSection>
                  <PopupHeader>
                    <p className="check-box" onClick={deleteHandler}>
                      <p>삭제</p>
                    </p>
                    <p className="popup-name">이름</p>
                    <p className="popup-due">게시 기간</p>
                    <p>상태</p>
                    <p>설정</p>
                    <p>미리보기</p>
                    <p>게시</p>
                  </PopupHeader>
                  <PopupBody>
                    <PopupList>
                      {/* 
                        id : 1 
                        imageUrl : "https://kr.object.ncloudstorage.com/ontact-dev-registry/user_profile/default_profile.png"
                        title : "화이트데이 팝업" 
                        link : "naver.com" 
                        status : false 
                        pcPos : 1 
                        pcLeftOffset : 0
                        pcTopOffset : 0 
                        mobilePos : 0
                        mobileLeftOffset : 0 
                        mobileTopOffset : 0 
                        onedayBan : true 
                        startDue : "2024-03-12T00:00:00.000Z" 
                        endDue : "2024-03-15T00:00:00
                        .000Z" 
                      */}
                      {isLoading ? (
                        <LoadingSection>
                          <img src="/resources/img/loading.gif" alt="로딩중" />
                        </LoadingSection>
                      ) : null}
                      {popupList.map((popup: any) => (
                        <PopupElement id={popup.id}>
                          <p className="check-box">
                            <input
                              type="checkbox"
                              id={`del-check-${popup.id}`}
                              onChange={(e) => checkHandled(e)}
                            ></input>
                          </p>
                          <p className="popup-name">
                            <PopupTitleCell>
                              <img
                                src={`https://kr.object.ncloudstorage.com/ontact-bucket/${popup.imageUrl}`}
                                alt="popup-thumbnail"
                              ></img>
                              <p>{popup.title}</p>
                            </PopupTitleCell>
                          </p>
                          <p className="popup-due">
                            <p>{popup.startDue?.slice(0, 10)}~</p>
                            <p>{popup.endDue?.slice(0, 10)}</p>
                          </p>
                          <p>
                            {popup.status ? (
                              <PopupPublicText>공개</PopupPublicText>
                            ) : (
                              <PopupPrivateText>비공개</PopupPrivateText>
                            )}
                          </p>
                          <p>
                            <PopupModifyModalButton
                              id={`popup-${popup.id}`}
                              onClick={(e) => openModifyModal(e)}
                            >
                              수정하기
                            </PopupModifyModalButton>
                          </p>
                          <p>
                            <PopupSetViewButton
                              id={`preview-${popup.id}`}
                              onClick={(e) => openPreviewModal(e)}
                            >
                              미리보기
                            </PopupSetViewButton>
                          </p>
                          <p>
                            {popup.status ? (
                              <PopupOffPostButton
                                id={`off-${popup.id}`}
                                onClick={setPopupStatus}
                              >
                                내리기
                              </PopupOffPostButton>
                            ) : (
                              <PopupSetPostButton
                                id={`set-${popup.id}`}
                                onClick={setPopupStatus}
                              >
                                게시하기
                              </PopupSetPostButton>
                            )}
                          </p>
                        </PopupElement>
                      ))}
                    </PopupList>
                  </PopupBody>
                  <PopupFooter>
                    <div>
                      <PopupAddButton onClick={(e) => openCreateModal(e)}>
                        팝업 추가하기
                      </PopupAddButton>
                    </div>
                  </PopupFooter>
                </PopupListSection>
              )}
            </div>
            <hr />
          </div>
        </div>

        {/* 생성 & 수정 모달 */}
        {showModifyModal && (
          <PopupModifyModal
            show={showModifyModal}
            onHide={() => {
              setShowModifyModal(false);
              setCreateMode(false);
            }}
            popupData={popupList[clickedPopupId]}
            onSubmit={(submitData: any) => {
              handleModifyModal(submitData);
              setCreateMode(false);
            }}
            uploadImage={handleUploadImage}
            isCreateMode={createMode}
          />
        )}

        {/* 미리보기 모달 */}
        {showPreviewModal && (
          <PopupModal
            isAdmin={true}
            onHide={() => setShowPreviewModal(false)}
            popupData={popupList[clickedPopupId]}
          />
        )}
      </main>
    </main>
  );
};

const LoadingSection = styled.div`
  position: absolute;
  z-index: 1000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  & > img {
    width: 100%;
  }
`;
const PopupListSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0;
`;
const PopupHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 0 10px 0;
  p {
    margin: 0;
    text-align: center;
    padding: 2px 8px;
    flex: 1 1 0;
  }
  .popup-name {
    flex: 4 1 0;
    text-align: left;
  }
  .popup-due {
    flex: 2 1 0;
  }
  .check-box {
    flex: 0.5 1 0;
    & > p {
      border-radius: 6px;
      background-color: lightgrey;
      color: white;
    }
  }
`;

const PopupBody = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;
const PopupList = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const PopupElement = styled.li`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  align-items: center;
  margin: 6px 0 0 0;
  padding: 8px 0;
  p {
    margin: 0;
    text-align: center;
    padding: 2px 8px;
    flex: 1 1 0;
  }
  .popup-name {
    flex: 4 1 0;
  }
  .popup-due {
    flex: 2 1 0;
  }
  .check-box {
    flex: 0.5 1 0;
  }
`;
const PopupTitleCell = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  vertical-align: center;

  img {
    width: 70px;
    height: 70px;
  }
  p {
    text-align: left;
    margin-left: 20px;
  }
`;
const PopupPublicText = styled.p`
  color: black;
  font-weight: bold;
`;
const PopupPrivateText = styled.p`
  color: grey;
  font-weight: bold;
`;
const PopupModifyModalButton = styled.p`
  background-color: #3e5bde;
  color: white;
  border-radius: 10px;

  &:hover {
    box-shadow: 1px 1px 1px gray;
  }
`;
const PopupSetViewButton = styled.p`
  background-color: #3e5bde;
  color: white;
  border-radius: 10px;

  &:hover {
    box-shadow: 1px 1px 1px gray;
  }
`;
const PopupSetPostButton = styled.p`
  background-color: #3e5bde;
  color: white;
  border-radius: 10px;

  &:hover {
    box-shadow: 1px 1px 1px gray;
  }
`;
const PopupOffPostButton = styled.p`
  background-color: #de4e3e;
  color: white;
  border-radius: 10px;

  &:hover {
    box-shadow: 1px 1px 1px gray;
  }
`;
const PopupFooter = styled.div``;
const PopupAddButton = styled.p`
  background-color: #3e5bde;
  color: white;
  border-radius: 10px;
  text-align: center;
  padding: 6px;
  width: 20%;
  margin-top: 16px;

  &:hover {
    box-shadow: 1px 1px 1px gray;
  }
`;

const CustomCol3 = styled.div`
  &.col-3 {
    width: 10%;
    margin-bottom: 40px;
  }
`;

export default AEvent;
