import {
  Button,
  FormCheck,
  FormControl,
  InputGroup,
  Table,
} from "react-bootstrap";
import styled from "styled-components";
import Icon from "../../../components/Icon";

import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { DATA_TYPE, USER_BADGE } from "../../../common/constants";
import { MButton, MRow, MWrapper } from "../../../common/mobile-styled";
import CustomerPageTitle from "../../../components/CustomerPageTitle";
import FormatData from "../../../components/FormatData";
import { MODAL_TYPE } from "../../../components/modal/GlobalModal";
import { modalOpen } from "../../../redux/modalSlice";
import { RootUserState } from "../../../redux/store";
import { reqReadBadge } from "../../../requests/badge";
import { reqShipFEE } from "../../../requests/ship";
import { reqUserGetShipNeedModify } from "../../../requests/user";
interface IItem {
  id: number;
  check: boolean;
}

interface IData {
  list: IItem[];
}

export default function CWarehouseModify() {
  const dispatch = useDispatch();
  const modal = useSelector((state: any) => state.modal);
  const user = useSelector((state: RootUserState) => state.user);
  const pageCount = 10;
  const [disableCheckContent, setDisableCheckContent] =
    useState<boolean>(false);
  const [searchWord, setSearchWord] = useState<string>("");
  const [data, setData] = useState<any[]>([]);
  const [exchangeRate, setExchangeRate] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [checked, setChecked] = useState<boolean[]>(
    Array<boolean>(0).fill(false)
  );
  const [totalCheck, setTotalCheck] = useState<boolean>(false);

  const rf = async () => {
    const result = await reqUserGetShipNeedModify({
      userId: user.id,
      page: 1,
      pageCount,
      searchWord,
    });

    setData(result.data.list);
    setTotalCount(result.data.totalCount);
    const initialChecked = Array<boolean>(result.data.list.length).fill(false);
    setChecked(initialChecked);

    const anyCheckedItemHasCheckTrue = result.data.list.some(
      (item: IItem, index: number) => initialChecked[index] && item.check
    );
    setDisableCheckContent(anyCheckedItemHasCheckTrue);
  };

  const [fixedExchangeRate, setFixedExchangeRate] = useState(0);

  const fetchFixedExchangeRate = async () => {
    try {
      const response = await reqShipFEE();
      if (response && response.data && response.data[0]?.fixedExchangeRateShop) {
        setFixedExchangeRate(parseFloat(response.data[0].fixedExchangeRateShop));
      }
    } catch (error) {
      console.error("Error fetching fixed exchange rate:", error);
    }
  };

  // const fetchExchangeRate = () => {
  //   fetch(
  //     "https://quotation-api-cdn.dunamu.com/v1/forex/recent?codes=FRX.KRWUSD"
  //   )
  //     .then((response) => response.json())
  //     .then((data) => {
  //       const rate = data[0].basePrice;
  //       setExchangeRate(rate);
  //     })
  //     .catch((error) => console.error("Error fetching exchange rate:", error));
  // };

  useEffect(() => {
    // fetchExchangeRate();
    fetchFixedExchangeRate();
  }, [data]);

  useEffect(() => {
    const anyCheckedItemHasCheckTrue = data.some(
      (item, index) => checked[index] && item.check
    );

    setDisableCheckContent(anyCheckedItemHasCheckTrue);
  }, [checked, data]);

  const handleCheckAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTotalCheck(e.target.checked);
    setChecked(Array<boolean>(data.length).fill(e.target.checked));
  };

  useEffect(() => {
    rf();
    const badgePayload = {
      userId: user.id,
      badgeName: USER_BADGE.MODIFY_PRODUCT,
    };
    reqReadBadge(badgePayload);
  }, [user?.id]);

  const convertToKRW = (usd: number) => {
    return Math.floor(usd * 1000).toLocaleString();
  };

  const convertToUSD = (krw: number) => {
    return (krw / fixedExchangeRate).toFixed(2);
  };

  const [itemOpen, setItemOpen] = useState<any>({});

  const toggleItemOpen = (index: any) => {
    setItemOpen((prev: any) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  // 띄어쓰기
  const handleKeyPress = (event: any) => {
    if (event.key === " ") {
      event.preventDefault();
    }
  };

  const [filteredOrders, setFilteredOrders] = useState<any[]>([]);

  useEffect(() => {
    const filteredData = data.filter((order) => {
      return order.tracking.toLowerCase().includes(searchWord.toLowerCase());
    });
    setFilteredOrders(filteredData);
  }, [data, searchWord]);

  return (
    <>
      {isMobile ? (
        <MWrapper style={{ padding: "1.4rem" }}>
          <CustomerPageTitle
            title="Products Need Modify"
          />

          <MSearchBar>
            <InputGroup>
              <FormControl
                placeholder="Tracking Number"
                value={searchWord.toUpperCase()}
                onKeyPress={handleKeyPress}
                onChange={(e) => setSearchWord(e.target.value)}
              />
              <Button
                variant="outline-success"
                style={{ borderColor: "var(--color-main-blue)" }}
                onClick={rf}
              >
                <Icon
                  icon="search"
                  style={{ color: "var(--color-main-blue)" }}
                />
              </Button>
            </InputGroup>
          </MSearchBar>

          <MTableWrapper>
            {filteredOrders &&
              filteredOrders.map((v, index) => (
                <MTableItem key={v.tracking}>
                  <MTableChecker>
                    <MRow>
                      <Icon
                        icon={itemOpen[index] ? "caret-up" : "caret-down-fill"}
                        fontSize="1rem"
                        style={{ marginRight: "0.5rem" }}
                        onClick={() => toggleItemOpen(index)}
                      />
                      <MTableLabel style={{ fontWeight: "bold" }}>
                        {v.tracking}
                      </MTableLabel>
                    </MRow>

                    <Row>
                      <FormCheck
                        checked={checked[index]}
                        onChange={(e) => {
                          e.stopPropagation();
                          const newChecked = [...checked];
                          newChecked[index] = !newChecked[index];
                          setChecked(newChecked);
                        }}
                      />
                    </Row>
                  </MTableChecker>

                  {!itemOpen[index] && (
                    <div style={{ marginLeft: "1.5rem" }}>
                      <MTableRow>
                        <MTableLabel>Warehouse Date</MTableLabel>
                        <MTableData>
                          {FormatData(DATA_TYPE.DATE, v.createdAt)}
                        </MTableData>
                      </MTableRow>
                      <MTableRow>
                        <MTableLabel>Warehouse Cost</MTableLabel>
                        <MTableData className="price">
                          ${v.overstoreFee} / ₩{convertToKRW(v.overstoreFee)}
                        </MTableData>
                      </MTableRow>
                      <MTableRow>
                        <MTableLabel>Extra Charge</MTableLabel>
                        <MTableData className="price">
                          ${convertToUSD(v.Extra)} / ₩{v.Extra}
                        </MTableData>
                      </MTableRow>
                      <MTableRow>
                        <MTableLabel>Product Info</MTableLabel>
                        <MTableData>
                          {v.detail.length
                            ? `${v.detail[0]?.name.length > 5
                              ? `${v.detail[0]?.name.substring(0, 5)}...`
                              : v.detail[0]?.name
                            } + ${v.detail.length} more`
                            : "Empty"}
                        </MTableData>
                      </MTableRow>
                      <MTableRow
                        onClick={() => {
                          dispatch(
                            modalOpen({
                              modalType: MODAL_TYPE.SHIP_MODIFY,
                              title: "Modify Package",
                              payload: { data: v },
                            })
                          );
                        }}
                      >
                        <MTableLabel>Status</MTableLabel>
                        <MTableData>
                          {FormatData(
                            DATA_TYPE.CODE_SHIPPING_PRODUCT_STATUS,
                            v.status
                          )}
                        </MTableData>
                      </MTableRow>
                    </div>
                  )}
                </MTableItem>
              ))}
          </MTableWrapper>
          <MSubmitButtonRow style={{ border: "1px solid transparent" }}>
            <MButton
              style={{
                backgroundColor: `${checked.reduce((acc, cur) => {
                  acc = cur || acc;
                  return acc;
                }, false)
                  ? "var(--color-main-blue)"
                  : "var(--color-main-blue-hover)"
                  }`,
                color: "var(--color-white)",
                width: "100%",
              }}
              onClick={(e) => {
                const isChecked = checked.reduce((acc, cur) => {
                  acc = cur || acc;
                  return acc;
                });
                console.log(disableCheckContent);
                if (!isChecked) {
                  return;
                }
                dispatch(
                  modalOpen({
                    modalType: MODAL_TYPE.SHIP_CHECK,
                    title: "Checked Items",
                    payload: {
                      list: data.filter((v, index) => checked[index]),
                    },
                  })
                );
              }}
            >
              Checked Items
            </MButton>
          </MSubmitButtonRow>
        </MWrapper>
      ) : (
        <div style={{ padding: "30px" }}>
          <CustomerPageTitle
            title="Products Need Modify"
          />

          <div className="d-flex justify-content-end">
            <InputGroup className="mb-3" style={{ width: "300px" }}>
              <FormControl
                className=""
                placeholder="Tracking Number"
                value={searchWord.toUpperCase()}
                onKeyPress={handleKeyPress}
                onChange={(e) => {
                  setSearchWord(e.target.value);
                }}
              />
              <Button
                variant="outline-success"
                style={{ borderColor: "var(--color-main-blue)" }}
                onClick={(e) => {
                  rf();
                }}
              >
                <Icon icon="search" color="var(--color-main-blue)" />
              </Button>
            </InputGroup>
          </div>
          <div
            style={{
              height: "600px",
              overflowY: "scroll",
              marginBottom: "20px",
            }}
          >
            <div className="table-responsive">
              <Table className="table-custom text-center">
                <thead className="table-light">
                  <tr translate="no">
                    <th style={{ top: 0, position: "sticky", width: "70px" }}>
                      <FormCheck
                        checked={totalCheck}
                        onChange={(e) => {
                          setTotalCheck(e.target.checked);
                          setChecked(
                            Array<boolean>(totalCount).fill(e.target.checked)
                          );
                        }}
                      />
                    </th>
                    <th
                      className="font-large-page-title"
                      style={{ top: 0, position: "sticky" }}
                    >
                      Tracking No.
                    </th>
                    <th
                      className="font-large-page-title"
                      style={{ top: 0, position: "sticky" }}
                    >
                      Warehouse Date
                    </th>
                    <th
                      className="font-large-page-title"
                      style={{ top: 0, position: "sticky" }}
                    >
                      Warehouse Cost
                    </th>
                    <th
                      className="font-large-page-title"
                      style={{ top: 0, position: "sticky" }}
                    >
                      Extra Charge
                    </th>
                    <th
                      className="font-large-page-title"
                      style={{ top: 0, position: "sticky" }}
                    >
                      Product Info
                    </th>
                    <th
                      className="font-large-page-title"
                      style={{ top: 0, position: "sticky" }}
                    >
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredOrders?.map((v: any, index: number) => {
                    let info =
                      v.detail?.length > 0
                        ? `${v.detail[0]?.name.length > 5
                          ? `${v.detail[0]?.name.substring(0, 5)}...`
                          : v.detail[0]?.name
                        } + ${v.detail.length} more`
                        : "Empty";

                    return (
                      <>
                        <tr>
                          <td className="font-large-page">
                            <FormCheck
                              checked={checked[index]}
                              onChange={(e) => {
                                e.stopPropagation();
                                const newChecked = [...checked];
                                newChecked[index] = !newChecked[index];
                                setChecked(newChecked);
                              }}
                            />
                          </td>
                          <td
                            className="ontactweb-height"
                            style={{
                              cursor: "pointer",
                              color: "var(--color-main-blue)",
                              textDecoration: "underline",
                              fontSize: "1.3em",
                            }}
                            onClick={(e) => {
                              const target = e.target as HTMLInputElement;
                              if (target.type !== "checkbox") {
                                dispatch(
                                  modalOpen({
                                    modalType: MODAL_TYPE.SHIP_MODIFY,
                                    title: "Modify Package",
                                    payload: { data: v },
                                  })
                                );
                              }
                            }}
                          >
                            {v.tracking}
                          </td>
                          <td className="font-large-page">
                            {FormatData(DATA_TYPE.DATE, v.createdAt)}
                          </td>
                          <td className="font-large-page">
                            ${v.overstoreFee} / ₩{convertToKRW(v.overstoreFee)}
                          </td>
                          <td className="font-large-page">
                            ${convertToUSD(v.Extra)} / ₩{v.Extra}{" "}
                          </td>
                          <td className="font-large-page">{info}</td>
                          <td className="font-large-page">
                            {FormatData(
                              DATA_TYPE.CODE_SHIPPING_PRODUCT_STATUS,
                              v.status
                            )}
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </div>
          <div className="d-flex justify-content-end">
            <Button
              variant="success"
              style={{
                borderColor: "var(--color-main-blue)",
                backgroundColor: "var(--color-main-blue)",
              }}
              onClick={(e) => {
                dispatch(
                  modalOpen({
                    modalType: MODAL_TYPE.SHIP_CHECK,
                    title: "Checked Items",
                    payload: {
                      list: data.filter((v, index) => checked[index]),
                    },
                  })
                );
              }}
              disabled={disableCheckContent}
            >
              Checked Items
            </Button>
          </div>
        </div>
      )}
    </>
  );
}

const MobileWrapper = styled.div`
  margin: 0;
  padding: 1rem;
`;

const MHeader = styled.div``;
const MMTitle = styled.div`
  margin: 1rem 0;
  color: var(--color-main-blue);
  border-radius: 30px;
  font-weight: bold;
  font-size: 24px;
`;

const MSearchBar = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 1rem;
`;

const MTableWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const MTableSection = styled.div`
  display: flex;
  flex-direction: column;
`;
const MTableChecker = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0.4rem 0 0.8rem 0;
`;
const MTableItem = styled.div`
  display: flex;
  flex-direction: column;
  &:not(:last-of-type) {
    border-bottom: 1px solid lightgray;
    margin-bottom: 1rem;
  }
  .price {
    font-weight: bold;
    text-decoration: underline;
  }
`;
const MTableRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const MTableLabel = styled.label``;
const MTableData = styled.p``;

const MSubmitButtonRow = styled.div`
  position: fixed;
  width: 100vw;
  bottom: 0%;
  left: 0%;
  padding: 1rem;
  background-color: white;
  gap: 1rem;
  display: flex;
  & > button {
    flex-grow: 1;
  }
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.6rem;
  align-items: center;
`;
