import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Icon from "./Icon";
import { CODE_SHOPING_PAYMENT_STATUS_OPTIONS } from "../common/constants";
import { useLocation, useParams } from "react-router-dom";

export const RequestSent_상태로_넘어오는_Ship_페이지_Dropdown = () => {
  return (
    <>
      <option value="">Filter By Status</option>
      <option value="1" selected>
        Request Sent
      </option>
      <option value="2">Packing</option>
      <option value="4">Pending payment</option>
      <option value="5">Confirming deposit</option>
      <option value="6">Preparing for shipment</option>
      <option value="7">Shipped</option>
      <option value="8">Delivery completed</option>
      <option value="11">Discarded</option>
    </>
  );
};

const CustomerHeadShipFilter = ({
  selectStatus,
  selectViewPage,
  enterSearch,
  selectSearchOption,
}: any) => {
  const { pathname } = useLocation();
  const params = useParams();

  const [viewFilter, setViewFilter] = useState<string>("");
  const [searchOptionFilter, setSearchOptionFilter] = useState<string>("");
  const [searchWordFilter, setSearchWordFilter] = useState<string>("");

  const [statusListObject, setStatusListObject] = useState<object>({});
  const [searchOptionList, setSearchOptionList] = useState<string[]>([]);
  const [viewFilterList, setViewFilterList] = useState<object[]>([
    { name: "See 10", value: "10" },
    { name: "See 50", value: "50" },
    { name: "See 100", value: "100" },
    { name: "Full View", value: "1000000" },
  ]);

  useEffect(() => {
    if (pathname.includes("/customer/ship/details")) {
      const updatedStatusOptions = { ...CODE_SHOPING_PAYMENT_STATUS_OPTIONS };
      updatedStatusOptions[2] = "Packing";
      delete updatedStatusOptions[3];
      updatedStatusOptions[7] = "Shipped";
      delete updatedStatusOptions[99];
      setStatusListObject(updatedStatusOptions);
      setSearchOptionList(["Order Number"]);
      setSearchOptionFilter("Order Number");
      selectSearchOption("Order Number");
    }
  }, [pathname, selectSearchOption]);

  useEffect(() => {
    if (params.status === "1") {
      selectStatus("1");
    }
  }, [pathname]);

  const handleKeyPress = (event: any) => {
    if (event.key === " ") {
      event.preventDefault();
    }
  };

  const handleStatusChange = (value: string) => {
    if (value === "2") {
      selectStatus("2,3");
    } else if (value === "7") {
      selectStatus("7,99");
    } else {
      selectStatus(value);
    }
  };

  return (
    <Wrapper>
      <Row>
        {/* 상태 필터 */}
        <Box className="status">
          <select
            name="status"
            className="input-reset dropdown"
            onChange={(e: any) => {
              handleStatusChange(e.target.value);
            }}
          >
            {pathname === "/customer/ship/details/1" ? (
              <RequestSent_상태로_넘어오는_Ship_페이지_Dropdown />
            ) : (
              <>
                <option value="">Filter By Status</option>
                {Object.entries(statusListObject).map(([key, value]) => {
                  return (
                    <option key={key} value={key}>
                      {value}
                    </option>
                  );
                })}
              </>
            )}
          </select>
          <Icon
            icon="caret-down-fill"
            style={{ position: "absolute", top: "20%", right: "4%" }}
          />
        </Box>

        {/* 보기 필터 */}
        <Box className="view">
          <select
            name="view"
            className="input-reset dropdown"
            value={viewFilter}
            onChange={(e: any) => {
              setViewFilter(e.target.value);
              selectViewPage(Number(e.target.value));
            }}
          >
            {viewFilterList.map((status: any, index) => {
              return (
                <option key={index} value={status.value}>
                  {status.name}
                </option>
              );
            })}
          </select>
          <Icon
            icon="caret-down-fill"
            style={{ position: "absolute", top: "20%", right: "6%" }}
          />
        </Box>

        {/* 검색 */}
        <Box className="search">
          {/* <div id="search-opt-dropdown">
              <select
                name="view"
                className="input-reset"
                value={searchOptionFilter}
                onChange={(e: any) => {
                  setSearchOptionFilter(e.target.value);
                  selectSearchOption(e.target.value);
                }}
              >
                {searchOptionList.map((option: any, index) => {
                  return (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  );
                })}
              </select>
              <Icon
                icon="caret-down-fill"
                style={{ position: "absolute", top: "20%", right: "6%" }}
              />
            </div> */}

          <div id="search-input">
            <input
              type="text"
              className="input-reset"
              placeholder={searchOptionFilter}
              value={searchWordFilter}
              onKeyPress={handleKeyPress}
              onChange={(e: any) => {
                const word = e.target.value.toUpperCase();
                setSearchWordFilter(word);
                enterSearch(word);
              }}
            />
            <SearchButton>
              <Icon icon="search" color="var(--color-main-blue)" />
            </SearchButton>
          </div>
        </Box>
      </Row>
    </Wrapper>
  );
};

const SearchButton = styled.div`
  border: 1px solid var(--color-main-blue);
  padding: 0.4rem 1rem;
  align-items: center;
  border-top-right-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem;
  cursor: pointer;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
`;

const Box = styled.div`
  display: flex;
  flex: 1;
  border: 1px solid lightgray;
  border-radius: 0.4rem;
  position: relative;

  .input-reset {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0.4rem;
    width: 100%;
    border: none;
    padding: 0.4rem 0.8rem;
  }

  #search-input {
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    & > input:focus {
      border-radius: 0;
    }
  }
`;

const Wrapper = styled.div`
  padding: 2rem 0;
  margin: 0 0 2rem 0;
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
export default CustomerHeadShipFilter;
