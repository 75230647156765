import { format, parseISO } from "date-fns";
import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { ADMIN_BADGE, USER_BADGE } from "../../../common/constants";
import AdminHeadTitle from "../../../components/AdminHeadTitle";
import { RootState } from "../../../redux/store";
import { reqSendAlarm } from "../../../requests/alarm";
import { reqAddBadge, reqReadAdminBadge } from "../../../requests/badge";
import { reqAShip, reqShipProductBox, reqShipToAlarmSend } from "../../../requests/ship";
import { Pagination } from "react-bootstrap";
import styled from "styled-components";

interface ShipRecord {
  isAlarmSubmitted: any;
  id: string;
  tracking: string;
  userId: string;
  warehouseId: string;
  createdAt: string;
  check: boolean;
  checkEmail: string;
  requestAt: string;
  checkPrice: number;
  checkPayment: number;
  isSubmitted: boolean;
  user: {
    name: string;
    membershipId: number;
  };
}

const AWCheckContent: React.FC = () => {
  const [shipRecords, setShipRecords] = useState<ShipRecord[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState<ShipRecord | null>(null);
  const admin = useSelector((state: RootState) => state.admin);
  const [message, setMessage] = useState<string>("");

  // 필터 상태 추가
  const [trackingFilter, setTrackingFilter] = useState("");
  const [spaceCodeFilter, setSpaceCodeFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState<"전송" | "미전송" | "">("미전송");

  // 페이지네이션 상태 추가
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [totalPages, setTotalPages] = useState(0);

  const fetchShipRecords = async () => {
    try {
      const response = await reqAShip();
      const records = response.data.filter(
        (record: ShipRecord) => record.check === true
      );
      records.sort((a: ShipRecord, b: ShipRecord) => new Date(b.requestAt).getTime() - new Date(a.requestAt).getTime());
      setShipRecords(records);
    } catch (error) {
      console.error("Failed to fetch ship records:", error);
    }
  };

  useEffect(() => {
    fetchShipRecords();

    const badgePayload = {
      badgeName: ADMIN_BADGE.CHECK_CONTENT,
    };
    reqReadAdminBadge(badgePayload);
  }, []);

  const handleShowModal = (record: ShipRecord) => {
    setSelectedRecord(record);
    setMessage(
      `The check content request have been completed. Please check your emails.\n${record.checkEmail}`
    );
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleSubmit = async () => {
    if (selectedRecord) {
      const emailPayload = {
        tracking: selectedRecord.tracking,
        isSubmitted: true,
      };

      const alarmPayload = {
        userId: selectedRecord.userId,
        read: 0,
        content: message,
        sender: admin.name,
      };

      try {
        const emailResponse = await reqShipProductBox(emailPayload);
        if (emailResponse.data) {
          setShipRecords((records) =>
            records.map((record) =>
              record.tracking === selectedRecord.tracking
                ? { ...record, isSubmitted: true }
                : record
            )
          );
        }

        await reqSendAlarm(alarmPayload);

        const badgePayload = {
          userId: selectedRecord.userId,
          badgeName: USER_BADGE.CHECK_CONTENT,
        };
        await reqAddBadge(badgePayload);

        await reqShipToAlarmSend({
          tracking: selectedRecord.tracking,
          id: selectedRecord.id,
          adminName: admin.name,
        });

        Swal.fire({
          title: "제출 완료",
          icon: "success",
        }).then(() => {
          window.location.reload();
        });
      } catch (error) {
        console.error("오류 발생:", error);
        alert("제출 중 오류가 발생했습니다.");
      } finally {
        handleCloseModal();
      }
    } else {
      console.error("선택된 레코드가 없습니다.");
    }
  };

  const filteredRecords = shipRecords.filter((record) => {
    const trackingMatch = record.tracking.includes(trackingFilter);
    const spaceCodeMatch = record.userId.includes(spaceCodeFilter);
    const statusMatch =
      statusFilter === ""
        ? true
        : statusFilter === "전송"
          ? record.isAlarmSubmitted !== "false"
          : record.isAlarmSubmitted === "false";

    return trackingMatch && spaceCodeMatch && statusMatch;
  });

  useEffect(() => {
    setTotalPages(Math.ceil(filteredRecords.length / itemsPerPage));
  }, [filteredRecords]);

  const currentRecords = filteredRecords.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  return (
    <>
      <main id="main" className="main">
        <AdminHeadTitle
          subtitle1="창고 관리"
          subtitle2="체크 컨텐츠 요청 내역"
          title="체크 컨텐츠 요청 내역"
        />

        <div className="container-fluid mb-4">
          <div className="row">
            <div className="col-md-4">
              <label htmlFor="statusFilter" className="form-label">상태</label>
              <select
                id="statusFilter"
                className="form-select"
                value={statusFilter}
                onChange={(e) => setStatusFilter(e.target.value as "전송" | "미전송" | "")}
              >
                <option value="">모두</option>
                <option value="미전송">미전송</option>
                <option value="전송">전송</option>
              </select>
            </div>
            <div className="col-md-4">
              <label htmlFor="trackingFilter" className="form-label">송장번호</label>
              <input
                type="text"
                id="trackingFilter"
                className="form-control"
                value={trackingFilter}
                onChange={(e) => setTrackingFilter(e.target.value)}
              />
            </div>
            <div className="col-md-4">
              <label htmlFor="spaceCodeFilter" className="form-label">스페이스코드</label>
              <input
                type="text"
                id="spaceCodeFilter"
                className="form-control"
                value={spaceCodeFilter}
                onChange={(e) => setSpaceCodeFilter(e.target.value)}
              />
            </div>
          </div>
        </div>

        <table className="table text-center">
          <thead>
            <tr>
              <th>NO</th>
              <th>송장번호</th>
              <th>요청 날짜</th>
              <th>스페이스코드</th>
              <th>이름</th>
              <th>저장위치</th>
              <th>결제 수단</th>
              <th>이메일 및 알림 전송</th>
            </tr>
          </thead>
          <tbody>
            {currentRecords.map((record, index) => (
              <tr key={record.id}>
                <th scope="row">{index + 1}</th>
                <td>{record.tracking}</td>
                <td>
                  {record.requestAt ? format(parseISO(record.requestAt), "yyyy-MM-dd HH:mm:ss") : "N/A"}
                </td>
                <td>{record.userId}</td>
                <td>{record.user.name}</td>
                <td>{record.warehouseId}</td>
                <td>
                  {record.checkPayment === 1
                    ? "Coupon"
                    : record.checkPayment === 2
                      ? "PayLetter"
                      : record.checkPayment === 3
                        ? "Paypal"
                        : ""}
                </td>
                <td>
                  {record.isAlarmSubmitted !== "false" ? (
                    `발신자: ${record?.isAlarmSubmitted}`
                  ) : (
                    <Button
                      variant="primary"
                      onClick={() => handleShowModal(record)}
                    >
                      전송
                    </Button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <CenteredPagination style={{ marginTop: "1rem" }}>
          {[...Array(totalPages)].map((_, index) => (
            <Pagination.Item
              key={index}
              active={index + 1 === currentPage}
              onClick={() => handlePageChange(index + 1)}
            >
              {index + 1}
            </Pagination.Item>
          ))}
        </CenteredPagination>
      </main>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>송장번호: {selectedRecord?.tracking}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            <label htmlFor="emailInput" className="form-label">
              Email
            </label>
            <input
              type="email"
              className="form-control"
              id="emailInput"
              placeholder="Enter email"
              value={selectedRecord?.checkEmail}
              readOnly
            />
          </div>
          <div className="mb-3">
            <p>
              체크 컨텐츠 사진, 영상과 함께 해당 사용자에게 직접 이메일을 보내시고, `제출 완료` 버튼을 클릭해주세요.
            </p>
          </div>
          <div className="mb-3">
            <label className="form-label">사용자 스페이스 코드</label>
            <input
              type="text"
              className="form-control"
              id="userIdInput"
              value={selectedRecord?.userId}
              readOnly
            />
          </div>
          <div className="mb-3">
            <label htmlFor="messageTextarea" className="form-label">
              메시지
            </label>
            <textarea
              className="form-control"
              id="messageTextarea"
              rows={3}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            ></textarea>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleSubmit}>
            제출 완료
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AWCheckContent;

const CenteredPagination = styled(Pagination)`
  display: flex;
  justify-content: center;
`;
