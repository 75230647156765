import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface iUser {
  id: string;
  name: string;
  email: string
  membership: number;
  Check: boolean;
}

const initialState: iUser = {
  id: "",
  name: "",
  membership: 0,
  email: "",
  Check: false,
};

const userSlice = createSlice({
  initialState,
  name: "user",
  reducers: {
    userSave: (state, action: PayloadAction<iUser>) => {
      const { id, name, membership, Check, email } = action.payload;
      state.id = id;
      state.email = email;
      state.name = name;
      state.membership = membership;
      state.Check = Check;
      return state;
    },

    userClear: (state) => {
      state.id = "";
      state.email = "";
      state.name = "";
      state.membership = 0;
      state.Check = false;
      return state;
  },
  },
});

export const { userSave, userClear } = userSlice.actions;
export default userSlice.reducer;
