import { useEffect, useState } from "react";
import { Button, FormCheck, Pagination, Table } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { DATA_TYPE, USER_BADGE } from "../../../common/constants";
import { MButton } from "../../../common/mobile-styled";
import CustomerHeadFilter from "../../../components/CustomerHeadFilter";
import CustomerHeadFilterMobile from "../../../components/CustomerHeadFilterMobile";
import CustomerPageTitle from "../../../components/CustomerPageTitle";
import FormatData from "../../../components/FormatData";
import Icon from "../../../components/Icon";
import { MODAL_TYPE } from "../../../components/modal/GlobalModal";
import { modalOpen } from "../../../redux/modalSlice";
import { RootUserState } from "../../../redux/store";
import { reqReadBadge } from "../../../requests/badge";
import { reqUserGetShipAllProduct } from "../../../requests/user";
import { reqShipFEE } from "../../../requests/ship";
const ITEMS_PER_PAGE = 10;

export default function CWarehouse() {
  const dispatch = useDispatch();
  const modal = useSelector((state: any) => state.modal);
  const user = useSelector((state: RootUserState) => state.user);
  const pageCount = 10;
  const [totalCheck, setTotalCheck] = useState<boolean>(false);
  const [exchangeRate, setExchangeRate] = useState<number>(0);
  const [searchWord, setSearchWord] = useState<string>("");
  const [data, setData] = useState<any[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [disableShippingRequest, setDisableShippingRequest] =
    useState<boolean>(false);
  const [disableCheckContent, setDisableCheckContent] =
    useState<boolean>(false);
  const [disableSplitButton, setDisableSplitButton] = useState<boolean>(true);
  const [checked, setChecked] = useState<{ [key: string]: boolean }>({});
  const [filteredOrders, setFilteredOrders] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(ITEMS_PER_PAGE);

  const customOrder = [1, 2, 4, 5, 6, 3, 20];

  const rf = async () => {
    const result = await reqUserGetShipAllProduct({
      userId: user.id,
      page: 1,
      pageCount,
      searchWord,
    });

    setData(
      result.data.list.sort(
        (a: any, b: any) =>
          customOrder.indexOf(a.status) - customOrder.indexOf(b.status)
      )
    );
    setTotalCount(result.data.totalCount);
  };

  const [fixedExchangeRate, setFixedExchangeRate] = useState(0);

  const fetchFixedExchangeRate = async () => {
    try {
      const response = await reqShipFEE();
      if (
        response &&
        response.data &&
        response.data[0]?.fixedExchangeRateShop
      ) {
        setFixedExchangeRate(
          parseFloat(response.data[0].fixedExchangeRateShop)
        );
      }
    } catch (error) {
      console.error("Error fetching fixed exchange rate:", error);
    }
  };

  useEffect(() => {
    fetchFixedExchangeRate();
  }, []);

  useEffect(() => {
    const checkedItems = data.filter((item) => checked[item.tracking]);
    const isAnyItemSelected = checkedItems.length > 0;

    const allCheckedAreValidForShipping =
      isAnyItemSelected && checkedItems.every((item) => item.status === 2);

    const allCheckedAreValidForChecking =
      isAnyItemSelected &&
      checkedItems.every((item) => item.status === 1 || item.status === 2);

    const isSingleValidForSplit =
      checkedItems.length === 1 &&
      (checkedItems[0].status === 1 || checkedItems[0].status === 2);

    setDisableShippingRequest(!allCheckedAreValidForShipping);
    setDisableCheckContent(!allCheckedAreValidForChecking);
    setDisableSplitButton(!isSingleValidForSplit);
  }, [checked, data]);

  const handleModifyClick = (v: any) => {
    if (
      v.status === 2 ||
      v.status === 3 ||
      v.status === 4 ||
      v.status === 5 ||
      v.status === 6 ||
      v.status === 20
    ) {
      return;
    }
    dispatch(
      modalOpen({
        modalType: MODAL_TYPE.SHIP_MODIFY,
        title: "Modify Package",
        payload: { data: v },
      })
    );
  };

  const handleTrackingClick = (v: any) => {
    if (
      v.status === 1 ||
      v.status === 3 ||
      v.status === 4 ||
      v.status === 5 ||
      v.status === 6 ||
      v.status === 20
    ) {
      return;
    }
    dispatch(
      modalOpen({
        modalType: MODAL_TYPE.SHIP_MODAL,
        title: "Shipping Details",
        payload: { data: v },
      })
    );
  };

  const handleSplitClick = (v: any) => {
    if (
      v.status === 1 ||
      v.status === 2 ||
      v.status === 3 ||
      v.status === 4 ||
      v.status === 6 ||
      v.status === 20
    ) {
      return;
    }
    dispatch(
      modalOpen({
        modalType: MODAL_TYPE.SPLIT_MODIFY,
        title: "Split list",
        payload: { trackingNumber: v.tracking },
      })
    );
  };

  useEffect(() => {
    rf();
    const badgePayload = {
      userId: user.id,
      badgeName: USER_BADGE.ALL_PRODUCT,
    };
    reqReadBadge(badgePayload);
  }, [user?.id]);

  useEffect(() => {
    const newChecked = data.reduce((acc, item) => {
      acc[item.tracking] = checked[item.tracking] || false;
      return acc;
    }, {} as { [key: string]: boolean });

    setChecked(newChecked);
  }, [data]);

  const fetchExchangeRate = async () => {
    try {
      const response = await reqShipFEE();
      const fixedExchangeRate = response.data[0]?.fixedExchangeRate;
      if (fixedExchangeRate) {
        setExchangeRate(parseFloat(fixedExchangeRate));
      } else {
        console.error("Failed to fetch fixed exchange rate: No data");
      }
    } catch (error) {
      console.error("Error fetching fixed exchange rate:", error);
    }
  };

  useEffect(() => {
    fetchExchangeRate();
  }, []);

  const convertToKRW = (usd: number) => {
    return Math.floor(usd * exchangeRate).toLocaleString();
  };

  const convertToUSD = (krw: number) => {
    return (krw / exchangeRate).toFixed(2);
  };

  const [itemOpen, setItemOpen] = useState<{ [key: string]: boolean }>({});

  const toggleItemOpen = (tracking: string) => {
    setItemOpen((prev) => ({
      ...prev,
      [tracking]: !prev[tracking],
    }));
  };

  //! 필터링
  const [selectedStatus, setSelectedStatus] = useState("");

  useEffect(() => {
    const filteredData = data.filter((order) => {
      return (
        order.tracking.toLowerCase().includes(searchWord.toLowerCase()) &&
        (selectedStatus ? order.status.toString() === selectedStatus : true)
      );
    });
    setFilteredOrders(filteredData);
    setCurrentPage(1);
    setTotalPages(Math.ceil(filteredData.length / ITEMS_PER_PAGE));
  }, [data, searchWord, selectedStatus, ITEMS_PER_PAGE]);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const displayedOrders = filteredOrders.slice(
    startIndex,
    startIndex + itemsPerPage
  );

  const handleItemsPerPageChange = (newItemsPerPage: number) => {
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1);
    setTotalPages(Math.ceil(filteredOrders.length / newItemsPerPage));
  };

  const handleKeyPress = (event: any) => {
    if (event.key === " ") {
      event.preventDefault();
    }
  };

  const handleCheckChange = (tracking: string) => {
    setChecked((prev) => ({
      ...prev,
      [tracking]: !prev[tracking],
    }));
  };

  const handleTotalCheckChange = () => {
    const newChecked = { ...checked };
    const allChecked = filteredOrders.every(
      (order) => newChecked[order.tracking]
    );
    filteredOrders.forEach((order) => {
      newChecked[order.tracking] = !allChecked;
    });
    setChecked(newChecked);
  };

  return (
    <>
      {isMobile ? (
        <MobileWrapper>
          <CustomerPageTitle title="All Products" />
          <CustomerHeadFilterMobile
            selectStatus={(value: string) => setSelectedStatus(value)}
            selectViewPage={(value: number) => handleItemsPerPageChange(value)}
            enterSearch={(value: string) => setSearchWord(value)}
          />
          <MTableWrapper>
            <MTableSection>
              {displayedOrders?.map((v: any, index: number) => {
                let info =
                  v.detail?.length > 0
                    ? `${
                        v.detail[0]?.name.length > 5
                          ? `${v.detail[0]?.name.substring(0, 5)}...`
                          : v.detail[0]?.name
                      } 외 ${v.detail.length}건`
                    : "Empty";
                return (
                  <MTableItem key={v.tracking}>
                    <MTableChecker>
                      <Row>
                        <Icon
                          icon={
                            itemOpen[v.tracking]
                              ? "caret-up"
                              : "caret-down-fill"
                          }
                          fontSize="1.2rem"
                          onClick={() => toggleItemOpen(v.tracking)}
                        />

                        <div
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          {v.tracking}
                        </div>
                      </Row>

                      <Row>
                        <FormCheck
                          checked={checked[v.tracking]}
                          onChange={() => handleCheckChange(v.tracking)}
                        />
                      </Row>
                    </MTableChecker>
                    {!itemOpen[v.tracking] && (
                      <div style={{ marginLeft: "1.6rem" }}>
                        <MTableRow>
                          <MTableLabel>Warehouse Date</MTableLabel>
                          <MTableData>
                            {FormatData(DATA_TYPE.DATE, v.createdAt)}
                          </MTableData>
                        </MTableRow>

                        <MTableRow>
                          <MTableLabel>Warehouse Cost</MTableLabel>
                          <MTableData className="price">
                            ${v.overstoreFee} / ₩{convertToKRW(v.overstoreFee)}
                          </MTableData>
                        </MTableRow>

                        <MTableRow>
                          <MTableLabel>Extra Charge</MTableLabel>
                          <MTableData className="price">
                            ${convertToUSD(v.Extra)} / ₩{v.Extra}
                          </MTableData>
                        </MTableRow>

                        <MTableRow>
                          <MTableLabel>Product Info</MTableLabel>
                          <MTableData>{info}</MTableData>
                        </MTableRow>

                        <MTableRow>
                          <MTableLabel>Status</MTableLabel>
                          <MTableData onClick={() => handleModifyClick(v)}>
                            {FormatData(
                              DATA_TYPE.CODE_SHIPPING_PRODUCT_STATUS,
                              v.status
                            )}
                          </MTableData>
                        </MTableRow>
                      </div>
                    )}
                    {v.status === 1 && (
                      <div
                        style={{ marginBottom: "1rem" }}
                        onClick={() => handleModifyClick(v)}
                      >
                        <MButton
                          style={{
                            backgroundColor: "var(--color-main-blue)",
                            color: "var(--color-white)",
                          }}
                        >
                          Enter Data
                        </MButton>
                      </div>
                    )}
                    {v.status === 2 && (
                      <div
                        style={{ marginBottom: "1rem" }}
                        onClick={() => handleTrackingClick(v)}
                      >
                        <MButton
                          style={{
                            backgroundColor: "var(--color-main-blue)",
                            color: "var(--color-white)",
                          }}
                        >
                          Modify Data
                        </MButton>
                      </div>
                    )}
                    {v.status === 5 && (
                      <div
                        style={{ marginBottom: "1rem" }}
                        onClick={() => handleSplitClick(v)}
                      >
                        <MButton
                          style={{
                            backgroundColor: "var(--color-main-blue)",
                            color: "var(--color-white)",
                          }}
                        >
                          Split Data
                        </MButton>
                      </div>
                    )}
                  </MTableItem>
                );
              })}
            </MTableSection>
          </MTableWrapper>

          <CenteredPagination>
            {[...Array(totalPages)].map((_, index) => (
              <Pagination.Item
                key={index}
                active={index + 1 === currentPage}
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </Pagination.Item>
            ))}
          </CenteredPagination>

          <MSubmitButtonRow>
            <Button
              variant="warning"
              style={{
                borderColor: "var(--color-main-blue)",
                backgroundColor: "var(--color-main-blue)",
              }}
              onClick={(e) => {
                dispatch(
                  modalOpen({
                    modalType: MODAL_TYPE.SPLIT,
                    title: "Split",
                    payload: {
                      list: data.filter((v) => checked[v.tracking]),
                    },
                  })
                );
              }}
              disabled={disableSplitButton}
            >
              Split
            </Button>
            <Button
              variant="success"
              style={{
                borderColor: "var(--color-main-blue)",
                backgroundColor: "var(--color-main-blue)",
              }}
              onClick={(e) => {
                dispatch(
                  modalOpen({
                    modalType: MODAL_TYPE.SHIP_CHECK,
                    title: "Checked Items",
                    payload: {
                      list: data.filter((v) => checked[v.tracking]),
                    },
                  })
                );
              }}
              disabled={disableCheckContent}
            >
              Checked Items
            </Button>
            <Button
              variant="success"
              style={{
                borderColor: "var(--color-main-blue)",
                backgroundColor: "var(--color-main-blue)",
              }}
              onClick={(e) => {
                dispatch(
                  modalOpen({
                    modalType: MODAL_TYPE.SHIP_REQUEST,
                    title: "Shipping Request",
                    payload: {
                      list: data.filter((v) => checked[v.tracking]),
                    },
                  })
                );
              }}
              disabled={disableShippingRequest}
            >
              Shipping Request
            </Button>
          </MSubmitButtonRow>
        </MobileWrapper>
      ) : (
        <div style={{ padding: "30px" }}>
          <CustomerPageTitle title="All Products" />

          <CustomerHeadFilter
            selectStatus={(value: string) => setSelectedStatus(value)}
            selectViewPage={(value: number) => handleItemsPerPageChange(value)}
            enterSearch={(value: string) => setSearchWord(value)}
          />

          <div
            style={{
              marginBottom: "2rem",
            }}
          >
            <div className="table-responsive">
              <Table className="table-custom text-center">
                <thead className="table-light">
                  <tr translate="no">
                    <th
                      className="font-large-page-title"
                      style={{ top: 0, position: "sticky", width: "70px" }}
                    >
                      <FormCheck
                        checked={totalCheck}
                        onChange={handleTotalCheckChange}
                      />
                    </th>
                    <th
                      className="font-large-page-title"
                      style={{ top: 0, position: "sticky" }}
                    >
                      Tracking No.
                    </th>
                    <th
                      className="font-large-page-title"
                      style={{ top: 0, position: "sticky" }}
                    >
                      Warehouse Date
                    </th>
                    <th
                      className="font-large-page-title"
                      style={{ top: 0, position: "sticky" }}
                    >
                      Warehouse Cost
                    </th>
                    <th
                      className="font-large-page-title"
                      style={{ top: 0, position: "sticky" }}
                    >
                      Extra Charge
                    </th>
                    <th
                      className="font-large-page-title"
                      style={{ top: 0, position: "sticky" }}
                    >
                      Product Info
                    </th>
                    <th
                      className="font-large-page-title"
                      style={{ top: 0, position: "sticky" }}
                    >
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {displayedOrders?.map((v: any, index: number) => {
                    let info =
                      v.detail?.length > 0
                        ? `${
                            v.detail[0]?.name.length > 5
                              ? `${v.detail[0]?.name.substring(0, 5)}...`
                              : v.detail[0]?.name
                          } + ${v.detail.length} more`
                        : "Empty";

                    return (
                      <tr key={v.tracking}>
                        <td className="font-large-page">
                          <FormCheck
                            checked={checked[v.tracking]}
                            onChange={() => handleCheckChange(v.tracking)}
                          />
                        </td>
                        <td
                          className="ontactweb-height"
                          onClick={
                            v.status === 1
                              ? () => handleModifyClick(v)
                              : v.status === 2
                              ? () => handleTrackingClick(v)
                              : v.status === 5
                              ? () => handleSplitClick(v)
                              : undefined
                          }
                          style={
                            v.status === 1 || v.status === 2 || v.status === 5
                              ? {
                                  cursor: "pointer",
                                  color: "var(--color-main-blue)",
                                  fontWeight: "bold",
                                  textDecoration: "underline",
                                  fontSize: "1.3em",
                                }
                              : {
                                  fontSize: "1.25em",
                                }
                          }
                        >
                          {v.tracking}
                        </td>

                        <td className="font-large-page">
                          {FormatData(DATA_TYPE.DATE, v.createdAt)}
                        </td>
                        <td className="font-large-page">
                          ${v.overstoreFee} / ₩{convertToKRW(v.overstoreFee)}
                        </td>
                        <td className="font-large-page">
                          ${convertToUSD(v.Extra)} / ₩{v.Extra}{" "}
                        </td>
                        <td className="font-large-page">{info}</td>
                        <td className="font-large-page">
                          {FormatData(
                            DATA_TYPE.CODE_SHIPPING_PRODUCT_STATUS,
                            v.status
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </div>

          <CenteredPagination>
            {[...Array(totalPages)].map((_, index) => (
              <Pagination.Item
                key={index}
                active={index + 1 === currentPage}
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </Pagination.Item>
            ))}
          </CenteredPagination>

          <div className="d-flex justify-content-end">
            <Button
              variant="success"
              onClick={(e) => {
                dispatch(
                  modalOpen({
                    modalType: MODAL_TYPE.SPLIT,
                    title: "Split",
                    payload: {
                      list: data.filter((v) => checked[v.tracking]),
                    },
                  })
                );
              }}
              disabled={disableSplitButton}
            >
              Split
            </Button>
            <Button
              variant="success"
              onClick={(e) => {
                dispatch(
                  modalOpen({
                    modalType: MODAL_TYPE.SHIP_CHECK,
                    title: "Checked Items",
                    payload: {
                      list: data.filter((v) => checked[v.tracking]),
                    },
                  })
                );
              }}
              disabled={disableCheckContent}
              style={{ marginLeft: "10px" }}
            >
              Checked Items
            </Button>
            <Button
              variant="success"
              onClick={(e) => {
                dispatch(
                  modalOpen({
                    modalType: MODAL_TYPE.SHIP_REQUEST,
                    title: "Shipping Request",
                    payload: {
                      list: data.filter((v) => checked[v.tracking]),
                    },
                  })
                );
              }}
              disabled={disableShippingRequest}
              style={{ marginLeft: "10px" }}
            >
              Shipping Request
            </Button>
          </div>
        </div>
      )}
    </>
  );
}

const CenteredPagination = styled(Pagination)`
  display: flex;
  justify-content: center;
`;

const MobileWrapper = styled.div`
  margin: 0 0 4rem 0;
  padding: 1.4rem;
`;

const MHeader = styled.div``;
const MMTitle = styled.div`
  margin: 1rem 0;
  color: var(--color-main-blue);
  border-radius: 30px;
  font-weight: bold;
  font-size: 24px;
`;

const MSearchBar = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 1rem;
`;

const MTableWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const MTableSection = styled.div`
  display: flex;
  flex-direction: column;
`;
const MTableChecker = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0.4rem 0 0.8rem 0;
`;
const MTableItem = styled.div`
  display: flex;
  flex-direction: column;
  &:not(:last-of-type) {
    border-bottom: 1px solid lightgray;
    margin-bottom: 0.6rem;
  }
`;
const MTableRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .price {
    font-weight: bold;
    text-decoration: underline;
  }
`;
const MTableLabel = styled.label``;
const MTableData = styled.p``;

const MSubmitButtonRow = styled.div`
  position: fixed;
  width: 100vw;
  bottom: 2%;
  left: 0%;
  padding: 0.6rem;
  background-color: white;
  z-index: 999;
  gap: 1rem;
  display: flex;
  & > button {
    flex-grow: 1;
  }
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.4rem;
  align-items: center;
`;
