import { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  InputGroup,
  Modal,
  Row,
  Tab,
  Table,
  Tabs,
} from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
import styled from "styled-components";
import Swal from "sweetalert2";
import {
  ADMIN_BADGE,
  CODE_COUNTRY_ITEMS,
  CODE_SHIPPING_ORDER_STATUS,
  USE_CONDITION,
} from "../../common/constants";
import {
  MBOptionRow,
  MBody,
  MButton,
  MCard,
  MFooter,
  MHeader,
  MRow,
  MSmallButton,
  MTabBody,
  MTabHeader,
  MTabSection,
  MTableBody,
  MTwoButtonRow,
  MWrapper,
  MobilePaymentSection,
} from "../../common/mobile-styled";
import { RootUserState } from "../../redux/store";
import { reqAddAdminBadge } from "../../requests/badge";
import { reqMyHaveCoupon, reqUseCoupon } from "../../requests/coupon";
import { reqPaypalPayment } from "../../requests/paypal";
import {
  reqShip,
  reqShipDetail,
  reqShipFEE,
  reqShipOrderUpdate,
  reqShipProductDetail,
  reqShipTrackingGet,
  reqShipUpdateOrder,
} from "../../requests/ship";
import { reqPostPointlog, reqUpdateUserPoint } from "../../requests/user";
import { 배송대행_콜백_처리 } from "../../views/Customer/CPaypalResult";
import CouponList from "../CouponList";
import Icon from "../Icon";
import { PaymentMethodSection } from "./CShopOrderModal";

interface CShipPayModal {
  show: boolean;
  onHide: () => void;
  orderNumber: string;
  onRepackRequest: () => void;
  refreshData: () => void;
}
interface Product {
  tracking: string;
  warehouseId: string;
  isUrgent: boolean;
  bag: boolean;
  name: string;
  warehouse: string;
  overstoreFee: number;
  price: number;
  Extra: number;
  boxed: number;
}

interface PackingDetail {
  id: string;
  totalWeightKg: number;
  totalHeightCm: number;
  totalWidthCm: number;
  totalLengthCm: number;
  trackingNumbers: string;
  suspectBattery: boolean;
  food: boolean;
  imageUrl: string;
  isDeleted: boolean;
}

interface OrderDetails {
  user: User;
  products: Product[];
  optionBox: number;
  optionLetter: boolean;
  optionPoster: boolean;
  optionAircap: boolean;
  optionBag: boolean;
  optionSticker: boolean;
  adrReceiver: string;
  adrEmail: string;
  adrPhone: string;
  imageUrl: string;
  adrCountry: number;
  adrPCCC: string;
  adrZip: string;
  adrState: string;
  adrCity: string;
  adrOption1: string;
  adrOption2: string;
  adrOption3: string;
  estimatedAt: string;
  expiredAt: string;
  weightKg: number;
  heightCm: number;
  widthCm: number;
  lengthCm: number;
  suspectBattery: boolean;
  useVacuumPackagingBag: number;
  food: boolean;
  packingStatus: number;
  customerMessage: string;
  managerMessage: string;
  depositor: string;
  DepositorFee: string;
  link: string;
  FEDEXPriceKRW: number | null;
  FEDEXPriceUSD: number | null;
  UPSPriceKRW: number | null;
  UPSPriceUSD: number | null;
  EMSPriceKRW: number | null;
  EMSPriceUSD: number | null;
  PACKETPriceKRW: number | null;
  PACKETPriceUSD: number | null;
  CJPriceKRW: number | null;
  CJPriceUSD: number | null;
  packagPriceKRW: number | null;
  packagPriceUSD: number | null;
}
interface TrackingInfo {
  id: number;
  orderId: string;
  orderIds: string;
  trackingNumbers: string;
}

interface ShippingRate {
  KRW: number | null;
  USD: number | null;
}

interface ShippingOptions {
  [carrier: string]: ShippingRate;
}

interface User {
  id: string;
  email: string;
  membershipId: number;
  point: number;
  pointUsd: number;
}

interface ShippingOptionRates {
  KRW: number | null;
  USD: number | null;
}

interface CheckedShippingOptions {
  [carrier: string]: boolean;
}

export default function CShipPayModal({
  show,
  onHide,
  orderNumber,
  onRepackRequest,
  refreshData,
}: CShipPayModal) {
  const defaultUser: User = {
    id: "",
    email: "",
    membershipId: 0,
    point: 0,
    pointUsd: 0,
  };
  const [exchangeRate, setExchangeRate] = useState(0);
  const [key, setKey] = useState("Request Information");
  const [excludedProducts, setExcludedProducts] = useState<Product[]>([]);
  const [paymentDeadline, setPaymentDeadline] = useState("");
  const [customerMessage, setCustomerMessage] = useState("");
  const [fedexKrw, setFedexKrw] = useState("");
  const [fedexUsd, setFedexUsd] = useState("");
  const [shippingOptions, setShippingOptions] = useState<ShippingOptions>({});
  const [checkedShippingOptions, setCheckedShippingOptions] =
    useState<CheckedShippingOptions>({});
  const [depositorName, setDepositorName] = useState("");
  const [depositAmount, setDepositAmount] = useState("");
  const [currentDepositAmount, setCurrentDepositAmount] = useState("");
  const [selectedShippingOption, setSelectedShippingOption] = useState("");
  const [isDepositorFeeSet, setIsDepositorFeeSet] = useState(false);
  const [link, setLink] = useState("");
  const [remainingPoints, setRemainingPoints] = useState(0);
  const [pointsToApply, setPointsToApply] = useState(0);
  const [usdPointsToApply, setUsdPointsToApply] = useState(0);
  const [remainingUsdPoints, setRemainingUsdPoints] = useState(0);
  const [finalTotalKRW, setFinalTotalKRW] = useState(0);
  const [showKRWPModal, setShowKRWPModal] = useState(false);
  const [showUSDModal, setShowUSDModal] = useState(false);
  const [finalTotalUSD, setFinalTotalUSD] = useState(0);
  const [totalSurchargesUSD, setTotalSurchargesUSD] = useState("");
  const [isMenuOpen, setIsMenuOpen] = useState<{ [key: string]: boolean }>({});
  const [packingDetails, setPackingDetails] = useState<PackingDetail[]>([]);
  const [trackingInfo, setTrackingInfo] = useState<TrackingInfo[]>([]);
  const [isShippingOptionSelected, setIsShippingOptionSelected] =
    useState(false);
  const [orderDetails, setOrderDetails] = useState<OrderDetails>({
    products: [],
    user: defaultUser,
    optionBox: 0,
    optionLetter: false,
    optionPoster: false,
    optionAircap: false,
    optionBag: false,
    optionSticker: false,
    adrReceiver: "",
    adrEmail: "",
    adrPhone: "",
    adrCountry: 0,
    adrPCCC: "",
    adrZip: "",
    adrState: "",
    adrCity: "",
    adrOption1: "",
    adrOption2: "",
    adrOption3: "",
    imageUrl: "",
    estimatedAt: "",
    expiredAt: "",
    weightKg: 0,
    heightCm: 0,
    widthCm: 0,
    lengthCm: 0,
    depositor: "",
    DepositorFee: "",
    link: "",
    suspectBattery: false,
    food: false,
    packingStatus: 0,
    customerMessage: "",
    managerMessage: "",
    FEDEXPriceKRW: 0,
    useVacuumPackagingBag: 0,
    FEDEXPriceUSD: 0,
    UPSPriceKRW: 0,
    UPSPriceUSD: 0,
    EMSPriceKRW: 0,
    EMSPriceUSD: 0,
    PACKETPriceKRW: 0,
    PACKETPriceUSD: 0,
    CJPriceKRW: 0,
    CJPriceUSD: 0,
    packagPriceKRW: 0,
    packagPriceUSD: 0,
  });

  const shippingOptionToCompanyId = {
    FEDEX: 1,
    UPS: 2,
    EMS: 3,
    PACKET: 4,
    CJ: 5,
  };

  const handleSelect = (k: any) => {
    setKey(k);
  };

  useEffect(() => {
    setIsShippingOptionSelected(selectedShippingOption !== "");
  }, [selectedShippingOption]);

  useEffect(() => {
    if (orderNumber) {
      fetchOrderDetails();
    }
  }, [orderNumber]);

  const [warehouseTotalKRW, warehouseTotalUSD] = [
    orderDetails.products
      .filter((product) => product.boxed !== 0)
      .reduce(
        (acc, product) =>
          acc +
          (isNaN(Number(product.overstoreFee))
            ? 0
            : Math.round(Number(product.overstoreFee) * exchangeRate)) +
          product.Extra,
        0
      )
      .toLocaleString("ko-KR"),
    orderDetails.products
      .filter((product) => product.boxed !== 0)
      .reduce(
        (acc, product) =>
          acc +
          (isNaN(Number(product.overstoreFee))
            ? 0
            : Number(product.overstoreFee)) +
          product.Extra / exchangeRate,
        0
      )
      .toFixed(2),
  ];

  const [isZoomed, setIsZoomed] = useState(false);

  // const handleImageClick = () => {
  //   setIsZoomed(!isZoomed);
  // };

  const [imageToShow, setImageToShow] = useState("");
  const [showImageModal, setShowImageModal] = useState(false);

  const handleImageClick = (imageUrl: string) => {
    setImageToShow(imageUrl);
    setShowImageModal(true);
  };

  const closeImageModal = () => {
    setShowImageModal(false);
  };

  const [exchangeRateN, setExchangeRateN] = useState<number>(0);

  // const fetchExchangeRateN = () => {
  //   fetch(
  //     "https://quotation-api-cdn.dunamu.com/v1/forex/recent?codes=FRX.KRWUSD"
  //   )
  //     .then((response) => response.json())
  //     .then((data) => {
  //       const rate = data[0].basePrice;
  //       setExchangeRateN(rate);
  //     })
  //     .catch((error) => console.error("Error fetching exchange rate:", error));
  // };

  const fetchFixedExchangeRate = async () => {
    try {
      const response = await reqShipFEE();
      if (
        response &&
        response.data &&
        response.data[0]?.fixedExchangeRateShop
      ) {
        setFixedExchangeRate(
          parseFloat(response.data[0].fixedExchangeRateShop)
        );
      }
    } catch (error) {
      console.error("Error fetching fixed exchange rate:", error);
    }
  };
  const [fixedExchangeRate, setFixedExchangeRate] = useState(0);

  useEffect(() => {
    // fetchExchangeRateN();
    fetchFixedExchangeRate();
  });

  const convertToKRW = (usd: number) => {
    return Math.floor(usd * exchangeRate).toLocaleString();
  };

  const convertToUSD = (krw: number) => {
    return (krw / exchangeRate).toFixed(2);
  };

  const fetchOrderDetails = async () => {
    try {
      const response = await reqShip({ id: orderNumber });
      const orderData: OrderDetails = response.data;

      const availableShippingOptions: ShippingOptions = {
        FEDEX: { KRW: orderData.FEDEXPriceKRW, USD: orderData.FEDEXPriceUSD },
        UPS: {
          KRW: orderData.UPSPriceKRW ?? null,
          USD: orderData.UPSPriceUSD ?? null,
        },
        EMS: {
          KRW: orderData.EMSPriceKRW ?? null,
          USD: orderData.EMSPriceUSD ?? null,
        },
        PACKET: {
          KRW: orderData.PACKETPriceKRW ?? null,
          USD: orderData.PACKETPriceUSD ?? null,
        },
        CJ: {
          KRW: orderData.CJPriceKRW ?? null,
          USD: orderData.CJPriceUSD ?? null,
        },
      };

      const filteredOptions = Object.entries(availableShippingOptions)
        .filter(([_, rates]) => rates.KRW !== null && rates.USD !== null)
        .reduce((acc, [carrier, rates]) => ({ ...acc, [carrier]: rates }), {});

      setShippingOptions(filteredOptions);
      setOrderDetails(orderData);
      const excluded = orderData.products.filter((p: Product) => !p.boxed);
      setIsDepositorFeeSet(
        !!orderData.DepositorFee && orderData.DepositorFee !== ""
      );
      setExcludedProducts(excluded);
    } catch (error) {
      console.error("Error fetching order details:", error);
    }
  };

  useEffect(() => {
    fetchExchangeRate();
  }, []);

  const fetchExchangeRate = async () => {
    try {
      const response = await reqShipFEE();
      const fixedExchangeRate = response.data[0]?.fixedExchangeRate;
      if (fixedExchangeRate) {
        setExchangeRate(parseFloat(fixedExchangeRate));
      } else {
        console.error("Failed to fetch fixed exchange rate: No data");
      }
    } catch (error) {
      console.error("Error fetching fixed exchange rate:", error);
    }
  };

  useEffect(() => {
    const fetchTrackingInfo = async () => {
      try {
        const response = await reqShipTrackingGet({ id: orderNumber });
        setTrackingInfo(response.data);
      } catch (error) {
        console.error("Error fetching tracking information: ", error);
      }
    };

    if (orderNumber) {
      fetchTrackingInfo();
    }
  }, [orderNumber]);

  const handleUpdate = async () => {
    const now = new Date();
    const estimatedAt = now.toISOString();

    const expiredAt = paymentDeadline;

    await reqShipUpdateOrder({
      id: orderNumber,
      status: CODE_SHIPPING_ORDER_STATUS.PRODUCT_SENT,
    });

    await reqShipProductDetail({
      id: orderNumber,
    });

    await reqShipOrderUpdate({
      id: orderNumber,
      estimatedAt: estimatedAt,
      expiredAt: expiredAt,
      customerMessage: customerMessage,
    });

    onRepackRequest();
    onHide();
    refreshData();
  };

  const handleShippingOptionChange = (carrier: string) => {
    setSelectedShippingOption((prev) => (prev === carrier ? "" : carrier));
    setKrwDiscount(0);
    setUsdDiscount(0);
    setCouponApplied(false);
  };

  const calculateTotalKRWForCheckedOptions = (): number => {
    return Object.entries(shippingOptions)
      .filter(([carrier]) => checkedShippingOptions[carrier])
      .reduce((total, [_, rates]) => total + (rates.KRW ?? 0), 0);
  };

  const handleRepackageClick = () => {
    const packagingCost = orderDetails.packagPriceKRW || 0;
    const optionsCost = totalCostKRW;

    const shippingCost = shippingOptions[selectedShippingOption]?.KRW ?? 0;

    const warehouseCosts = orderDetails.products.reduce((acc, product) => {
      const warehouseCost = isNaN(Number(product.overstoreFee))
        ? 0
        : Math.round(Number(product.overstoreFee) * exchangeRate);
      const extraCost = product.Extra || 0;
      return acc + warehouseCost + extraCost;
    }, 0);

    let totalBeforePoints =
      packagingCost + optionsCost + shippingCost + warehouseCosts;

    const pointsValue = pointsToApply;
    let finalAmountKRW = Math.max(
      totalBeforePoints - pointsValue - krwDiscount,
      0
    );

    setDepositAmount(finalAmountKRW.toString());
    setShowKRWPModal(true);
  };

  const getCompanyId = (option: string): number => {
    if (option in shippingOptionToCompanyId) {
      return shippingOptionToCompanyId[
        option as keyof typeof shippingOptionToCompanyId
      ];
    }
    return 1;
  };

  const selectedCompanyId = getCompanyId(selectedShippingOption);

  const handleKRWPaymentSubmit = async () => {
    if (!isShippingOptionSelected) {
      alert("배송 옵션을 선택해주세요.");
      return;
    }
    if (!agreeCheck) {
      Toast.fire({
        icon: "warning",
        title: "Please agree to the cancellation and refund regulations.",
      });
      return;
    }
    if (depositorName === "" || link === "") {
      Toast.fire({
        icon: "warning",
        title: "Please enter your payment information.",
      });
      return;
    }
    const inputDepositAmount = Number(currentDepositAmount);

    const totalAmount = Number(depositAmount);

    const now = new Date();
    const paidAtISO = now.toISOString();

    if (inputDepositAmount !== totalAmount) {
      Toast.fire({
        icon: "warning",
        title:
          "The deposit amount you entered does not match the total amount of payment. Please enter the correct amount.",
      });
      return;
    }

    const pointsApplied = pointsToApply;
    const originalPoints = orderDetails.user.point;

    if (pointsApplied > originalPoints) {
      alert("Error: You cannot apply more points than you have.");
      return;
    }

    try {
      const depositorNameToSend = depositorName;
      const depositAmountToSend = depositAmount.toString();
      const linkToSend = link;
      const selectedCompanyId =
        shippingOptionToCompanyId[
          selectedShippingOption as keyof typeof shippingOptionToCompanyId
        ] || 1;

      await reqShipOrderUpdate({
        id: orderNumber,
        depositor: depositorNameToSend,
        DepositorFee: depositAmountToSend,
        link: linkToSend,
        paidAt: paidAtISO,
        company: selectedCompanyId,
        PaymentMethod: 1,
        status: CODE_SHIPPING_ORDER_STATUS.PRODUCT_SENT,
        express: "입금 확인 필요",
        usePointKrw: pointsApplied,
        discountCouponKrw: krwDiscount,
      });

      const remainingPoints = originalPoints - pointsApplied;

      if (remainingPoints < 0) {
        console.error("Error: Attempting to set negative points for a user.");
        alert("Error updating points. Please check the applied points.");
        return;
      } else if (remainingPoints === 0) {
        console.log("Notice: All points have been used.");
      }

      const pointLogPayload = {
        userId: orderDetails.user.id,
        used: true,
        krwUsedPoint: pointsApplied,
        status: 2,
      };

      if (pointsApplied > 0) {
        try {
          await reqPostPointlog(pointLogPayload);
        } catch (error) {
          console.error("Failed to log points:", error);
        }
      }

      await reqUpdateUserPoint({
        userId: orderDetails.user.id,
        point: remainingPoints,
      });

      const badgePayload = {
        badgeName: ADMIN_BADGE.SHIP_PAYMENT_WAITING,
      };
      await reqAddAdminBadge(badgePayload);

      if (couponApplied) {
        // 쿠폰 사용
        await reqUseCoupon({
          couponLogId: selectedCouponId,
          krwDiscounted: krwDiscount,
          usdDiscounted: usdDiscount,
        });
      }

      alert("Payment information and points updated successfully.");
      onHide();
      refreshData();
    } catch (error) {
      console.error("Failed to process KRW payment or update points:", error);
      alert("Failed to update payment information or points.");
    }
  };

  const handleUSDPaymentSubmit = async () => {
    if (!agreeCheck) {
      Swal.fire({
        icon: "warning",
        title: "Please agree to the cancellation and refund regulations",
      });
      return;
    }

    const usdAmount = Math.max(
      (orderDetails.packagPriceUSD || 0) +
        parseFloat(totalCostUSD.toString()) +
        parseFloat(warehouseTotalUSD) +
        Number(shippingOptions[selectedShippingOption]?.USD || 0) -
        usdDiscount -
        usdPointsToApply,
      0
    ).toFixed(2);

    const customObject = {
      pk: orderNumber,
      domain: "SHIP",
      reqShipOrderUpdate: {
        id: orderNumber,
        DepositorFee: usdAmount,
        status: CODE_SHIPPING_ORDER_STATUS.PRODUCT_SENT,
        paidAt: new Date().toISOString(),
        company: selectedCompanyId,
        PaymentMethod: 2,
        express: "입금 확인 필요",
        usePointUse: parseFloat(usdPointsToApply.toFixed(2)),
        discountCouponUsd: usdDiscount,
      },
      reqAddAdminBadge: {
        badgeName: ADMIN_BADGE.SHIP_PAYMENT_WAITING,
      },
      reqUpdateUserPointUSD: {
        userId: orderDetails.user.id,
        pointUsd: remainingUsdPoints,
      },
    };

    if (couponApplied) {
      Object.assign(customObject, {
        reqUseCoupon: {
          couponLogId: selectedCouponId,
          krwDiscounted: 0,
          usdDiscounted: usdDiscount,
        },
      });
    }

    // USD 포인트 로그
    const pointLogPayload = {
      userId: orderDetails.user.id,
      used: true,
      usdUsedPoint: parseFloat(usdPointsToApply.toFixed(2)),
      status: 2,
    };

    if (parseFloat(usdPointsToApply.toFixed(2)) > 0) {
      try {
        await reqPostPointlog(pointLogPayload);
      } catch (error) {
        console.error("Failed to log points:", error);
      }
    }

    // 쿠폰으로 인해 0원인 경우 Paypal 결제 없이 처리
    if (parseFloat(usdAmount) === 0) {
      await 배송대행_콜백_처리(JSON.stringify(customObject));
      Swal.fire({
        title: "Payment Success",
        text: "Thank you for using ontact korea!",
        icon: "success",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Back to previous page",
      }).then(async (result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      });
      return;
    }

    const res = await reqPaypalPayment({
      storeid: "ontactkorea",
      currency: "USD",
      storeorderno: `ship-${new Date().getTime().toString()}`,
      amount: parseFloat(usdAmount),
      payerid: `ontactKorea-${user.id}`,
      payeremail: "ontactkorea@gmail.com",
      returnurl: `${process.env.REACT_APP_BASE_URL}paypal/handle/result`,
      notiurl: `${process.env.REACT_APP_BASE_URL}paypal/handle/notify`,
      // 콜백 후처리 객체
      custom: JSON.stringify(customObject),
      directreturnflag: "Y",
    });
    if (!res) {
      alert("Paypal API Error");
      return;
    }
    const { online_url, mobile_url } = res.data;
    window.location.replace(isMobile ? mobile_url : online_url);
    return;
  };

  const [repackFeeKRW, setrepackFeeKRW] = useState<string>("");
  const [repackFeeUSD, setrepackFeeUSD] = useState<string>("");

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const response = await reqShip({ id: orderNumber });
        setOrderDetails(response.data);
      } catch (error) {
        console.error("Error fetching order details: ", error);
      }
    };

    if (orderNumber) {
      fetchOrderDetails();
    }
    const fetchPackingDetails = async () => {
      try {
        const response = await reqShipDetail({ order_id: orderNumber });
        setPackingDetails(response.data);
        const repackFeeRes = calculateRepackFee();
        setrepackFeeKRW(repackFeeRes.repackFeeKRW);
        setrepackFeeUSD(repackFeeRes.repackFeeUSD);
      } catch (error) {
        console.error("Error fetching packing details: ", error);
      }
    };

    if (orderNumber) {
      fetchPackingDetails();
    }
  }, [orderNumber]);

  const getPackingStatusText = (status: number) => {
    switch (status) {
      case 1:
        return "Complete packaging";
      case 2:
        return "lack of space";
      case 3:
        return "Over 30kg";
      default:
        return "No Information";
    }
  };

  const calculateOptionCosts = () => {
    const costs = {
      stickerPerBox: 2000,
      aircapPerBox: 3000,
      insurancePerBox: 5000,
      vacuumPackPerTracking: 5000,
    };

    const actualBoxCount = packingDetails.filter(
      (detail) => !detail.isDeleted
    ).length;

    let totalCostKRW = 0;
    let totalCostUSD = 0;

    if (orderDetails.optionSticker) {
      totalCostKRW += costs.stickerPerBox * actualBoxCount;
      totalCostUSD += (costs.stickerPerBox * actualBoxCount) / exchangeRate;
    }
    if (orderDetails.optionAircap) {
      totalCostKRW += costs.aircapPerBox * actualBoxCount;
      totalCostUSD += (costs.aircapPerBox * actualBoxCount) / exchangeRate;
    }
    if (orderDetails.optionBag) {
      totalCostKRW += costs.insurancePerBox * actualBoxCount;
      totalCostUSD += (costs.insurancePerBox * actualBoxCount) / exchangeRate;
    }

    const productsUsingVacuumPack = orderDetails.products.filter(
      (product) => product.bag === true && product.boxed !== 0
    );
    totalCostKRW +=
      costs.vacuumPackPerTracking * orderDetails.useVacuumPackagingBag;
    totalCostUSD +=
      (costs.vacuumPackPerTracking * orderDetails.useVacuumPackagingBag) /
      exchangeRate;

    return { totalCostKRW, totalCostUSD };
  };

  const { totalCostKRW, totalCostUSD } = calculateOptionCosts();

  const totalWeightSum = packingDetails.reduce((sum, detail) => {
    const weight = Number(detail.totalWeightKg);
    return sum + (isNaN(weight) ? 0 : weight);
  }, 0);

  const membershipDiscounts = {
    1: 1.0,
    2: 0.25,
    3: 0.0,
  };

  const calculateMembershipDiscountRate = () => {
    const membershipDiscountRates = {
      1: 100,
      2: 25,
      3: 0,
    };

    const discountRate =
      membershipDiscountRates[
        orderDetails.user.membershipId as keyof typeof membershipDiscountRates
      ] || 0;
    return discountRate;
  };

  const calculateRepackFee = () => {
    const boxCounts = new Map();
    orderDetails.products.forEach((product) => {
      boxCounts.set(product.boxed, (boxCounts.get(product.boxed) || 0) + 1);
    });

    let totalRepackFeeKRW = 0;
    let totalRepackFeeUSD = 0;

    boxCounts.forEach((count, boxNumber) => {
      if (count > 1) {
        const packingDetail = packingDetails[boxNumber - 1];
        if (packingDetail) {
          const repackFeePerBox = packingDetail.totalWeightKg * 1000;
          totalRepackFeeKRW += repackFeePerBox;
          totalRepackFeeUSD += repackFeePerBox / exchangeRate;
        }
      }
    });

    let discountRate =
      membershipDiscounts[
        orderDetails.user.membershipId as keyof typeof membershipDiscounts
      ] || 0;
    totalRepackFeeKRW *= 1 - discountRate;
    totalRepackFeeUSD *= 1 - discountRate;

    return {
      repackFeeKRW: totalRepackFeeKRW.toLocaleString("ko-KR"),
      repackFeeUSD: totalRepackFeeUSD.toFixed(2),
    };
  };

  const handleDepositAmountChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCurrentDepositAmount(e.target.value);
  };

  const calculateTotalAmount = () => {
    const packagePriceKRW = orderDetails.packagPriceKRW || 0;
    const totalCostKRWValue = Number(totalCostKRW);
    const warehouseTotalKRWValue = Number(warehouseTotalKRW.replace(/,/g, ""));
    const shippingCostKRW = Number(
      shippingOptions[selectedShippingOption]?.KRW || 0
    );
    const totalAmountKRW =
      packagePriceKRW +
      totalCostKRWValue +
      warehouseTotalKRWValue +
      shippingCostKRW -
      krwDiscount;
    return Math.max(totalAmountKRW, 0);
  };

  const calculateTotalAmountUSD = () => {
    const packagePriceUSD = orderDetails.packagPriceUSD || 0;
    const totalCostUSDValue = parseFloat(totalCostUSD.toString());
    const warehouseTotalUSDValue = parseFloat(warehouseTotalUSD);
    const shippingCostUSD = Number(
      shippingOptions[selectedShippingOption]?.USD || 0
    );
    const totalAmountUSD =
      packagePriceUSD +
      totalCostUSDValue +
      warehouseTotalUSDValue +
      shippingCostUSD -
      usdDiscount;
    return Math.max(totalAmountUSD, 0);
  };

  const applyPointsKRW = () => {
    const totalAmountKRW = calculateTotalAmount();
    const pointsToApplyLimited = Math.min(
      pointsToApply,
      orderDetails.user.point,
      totalAmountKRW
    );
    const newRemainingPoints = orderDetails.user.point - pointsToApplyLimited;
    setRemainingPoints(newRemainingPoints);
    const finalAmountKRW = totalAmountKRW - pointsToApplyLimited;
    setFinalTotalKRW(finalAmountKRW);

    Swal.fire({
      title: "Success!",
      text: `Points successfully applied. KRW points: ${pointsToApplyLimited}`,
      icon: "success",
      confirmButtonText: "OK",
    });
  };

  const applyAllPoints = () => {
    const totalAmountKRW = Math.floor(calculateTotalAmount());
    const availablePointsKRW = Math.floor(orderDetails.user.point);

    const pointsToApplyKRW = Math.min(availablePointsKRW, totalAmountKRW);
    const newRemainingPointsKRW = availablePointsKRW - pointsToApplyKRW;
    setRemainingPoints(newRemainingPointsKRW);
    setPointsToApply(pointsToApplyKRW);

    Swal.fire({
      title: "Success!",
      text: `Points successfully applied. KRW points: ${pointsToApplyKRW}`,
      icon: "success",
      confirmButtonText: "OK",
    });
  };

  const applyPointsUSD = () => {
    const totalAmountUSD = calculateTotalAmountUSD();
    const usdPointsToApplyLimited = Math.min(
      pointsToApply,
      orderDetails.user.pointUsd,
      totalAmountUSD
    );
    const newRemainingUsdPoints =
      orderDetails.user.pointUsd - usdPointsToApplyLimited;
    setRemainingUsdPoints(parseFloat(newRemainingUsdPoints.toFixed(2)));
    const finalAmountUSD = totalAmountUSD - usdPointsToApplyLimited;
    setUsdPointsToApply(parseFloat(usdPointsToApplyLimited.toFixed(2)));
    setFinalTotalUSD(parseFloat(finalAmountUSD.toFixed(2)));

    Swal.fire({
      title: "Success!",
      text: `Points successfully applied. USD points: ${usdPointsToApplyLimited.toFixed(
        2
      )}`,
      icon: "success",
      confirmButtonText: "OK",
    });
  };

  const applyAllPointsUSD = () => {
    const totalAmountUSD = calculateTotalAmountUSD();
    const availablePointsUSD = orderDetails.user.pointUsd;

    const pointsToApplyUSD = Math.min(availablePointsUSD, totalAmountUSD);
    const newRemainingUsdPoints = availablePointsUSD - pointsToApplyUSD;
    setRemainingUsdPoints(parseFloat(newRemainingUsdPoints.toFixed(2)));
    setUsdPointsToApply(parseFloat(pointsToApplyUSD.toFixed(2)));

    Swal.fire({
      title: "Success!",
      text: `All points successfully applied. USD points: ${pointsToApplyUSD.toFixed(
        2
      )}`,
      icon: "success",
      confirmButtonText: "OK",
    });
  };

  const [openSections, setOpenSections] = useState<any>({
    "Request Information": false,
    "Product Not Included": false,
    Address: false,
    "Packaging Information": false,
    "Estimate KRW": false,
    "Estimate USD": false,
  });
  const toggleSection = (section: string) => {
    setOpenSections((prev: any) => ({ ...prev, [section]: !prev[section] }));
  };

  // Request Information 섹션 대상
  const [riOpenIndexes, setRiOpenIndexes] = useState<number[]>([]);
  const riToggleOpen = (index: number) => {
    setRiOpenIndexes((prev) =>
      prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
    );
  };

  // Product Not Included 섹션 대상
  const [pniOpenIndexes, setPniOpenIndexes] = useState<number[]>([]);
  const pniToggleOpen = (index: number) => {
    setPniOpenIndexes((prev) =>
      prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
    );
  };

  // 적용 무게 함수
  const calculateVolumeWeight5000 = (
    width: number,
    length: number,
    height: number
  ) => {
    return (width * length * height) / 5000;
  };

  const calculateVolumeWeight6000 = (
    width: number,
    length: number,
    height: number
  ) => {
    return (width * length * height) / 6000;
  };

  const roundToFirstDecimal = (num: any) => {
    const integerPart = Math.floor(num);
    const decimalPart = num - integerPart;

    if (decimalPart <= 0.5) {
      return integerPart + 0.5;
    } else {
      return integerPart + 1;
    }
  };

  //! Coupon Start
  const user = useSelector((state: RootUserState) => state.user);
  const [availableCouponList, setAvailableCouponList] = useState<any>();
  const [krwDiscount, setKrwDiscount] = useState<number>(0);
  const [usdDiscount, setUsdDiscount] = useState<number>(0);
  const [couponApplied, setCouponApplied] = useState<boolean>(false);
  const [couponModal, setCouponModal] = useState<boolean>(false);

  const [selectedCouponId, setSelectedCouponId] = useState<number>();

  const [realKrwTotal, setRealKrwTotal] = useState<number>();
  const [realUsdTotal, setRealUsdTotal] = useState<number>();

  const Toast = Swal.mixin({
    toast: true,
    position: "center",
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const 내가_가진_배송대행_쿠폰_가져오기 = async () => {
    const res = await reqMyHaveCoupon({ userId: user.id });
    const myCouponDataList = res.data;
    const thisAvailableCouponList = myCouponDataList.filter(
      (couponData: any) =>
        couponData.coupon.useCondition === USE_CONDITION["RATING_FEE"]
    );

    setAvailableCouponList(thisAvailableCouponList);
    if (thisAvailableCouponList.length > 0) {
      Toast.fire({
        icon: "success",
        title: "There are coupons you can use!",
      });
    }
  };

  useEffect(() => {
    내가_가진_배송대행_쿠폰_가져오기();
  }, []);

  useEffect(() => {
    setRealKrwTotal(
      Math.max(
        Number(repackFeeKRW.replace(/,/g, "")) +
          Number(totalCostKRW) +
          Number(warehouseTotalKRW.replace(/,/g, "")) +
          Number(shippingOptions[selectedShippingOption]?.KRW || 0),
        0
      )
    );
    setRealUsdTotal(
      Math.max(
        parseFloat(repackFeeUSD) +
          parseFloat(totalCostUSD.toString()) +
          parseFloat(warehouseTotalUSD) +
          Number(shippingOptions[selectedShippingOption]?.USD || 0),
        0
      )
    );
  }, [couponModal]);

  const [paymentMethod, setPaymentMethod] = useState<string>("");
  const [agreeCheck, setAgreeCheck] = useState<boolean>(false);

  useEffect(() => {
    if (couponApplied) {
      setCouponModal(false);
      // 쿠폰 실제 적용
    } else {
      setKrwDiscount(0);
      setUsdDiscount(0);
    }
  }, [couponApplied]);

  //! Coupon End

  const getPaymentCardStyle = (method: string) => {
    return method === paymentMethod ? "payment-card selected" : "payment-card";
  };

  const getMobilePaymentCardStyle = (method: string) => {
    return method === paymentMethod
      ? "mobile-payment-card selected"
      : "mobile-payment-card";
  };

  const sectionContents: any = {
    "Request Information": (
      <MTabBody>
        <div className="sub-header">- Service Options</div>
        <div className="line">
          <MBOptionRow
            label="Pack only one box"
            disabled
            checked={orderDetails.optionBox === 0}
            onChange={(e) =>
              setOrderDetails({
                ...orderDetails,
                optionBox: e.target.checked ? 1 : 0,
              })
            }
          />
          <MBOptionRow
            label="Letter Bag Box Packaging"
            disabled
            checked={orderDetails.optionLetter}
            onChange={(e) =>
              setOrderDetails({
                ...orderDetails,
                optionLetter: e.target.checked,
              })
            }
          />
          <MBOptionRow
            label="Minimize Poster Tubes"
            disabled
            checked={orderDetails.optionPoster}
            onChange={(e) =>
              setOrderDetails({
                ...orderDetails,
                optionPoster: e.target.checked,
              })
            }
          />
          <MBOptionRow
            label="Add Air Cap"
            disabled
            checked={orderDetails.optionAircap}
            onChange={(e) =>
              setOrderDetails({
                ...orderDetails,
                optionAircap: e.target.checked,
              })
            }
          />
          <MBOptionRow
            label="Stickers on 4 Sides"
            disabled
            checked={orderDetails.optionSticker}
            onChange={(e) =>
              setOrderDetails({
                ...orderDetails,
                optionSticker: e.target.checked,
              })
            }
          />
          <MBOptionRow
            label="Insurance coverage"
            disabled
            checked={orderDetails.optionBag}
            onChange={(e) =>
              setOrderDetails({
                ...orderDetails,
                optionBag: e.target.checked,
              })
            }
          />
        </div>

        <div className="sub-header">- Shipping Information</div>
        <div>
          {orderDetails.products.map((product, index) => (
            <div className="dropdown-box" key={index}>
              <div className="dropdown-bar" onClick={() => riToggleOpen(index)}>
                <div className="dropdown-icon">
                  {riOpenIndexes.includes(index) ? (
                    <Icon icon="caret-down-fill" />
                  ) : (
                    <Icon icon="caret-up" />
                  )}
                </div>

                <div
                  style={{
                    textDecoration: product.boxed ? "none" : "line-through",
                  }}
                >
                  {product.tracking}
                </div>
              </div>

              <div className="dropdown-data">
                {riOpenIndexes.includes(index) && (
                  <>
                    <MRow>
                      <div className="label">NO</div>
                      <div className="data">{index + 1}</div>
                    </MRow>

                    <MRow>
                      <div className="label">Tracking Number</div>
                      <div
                        className="data"
                        style={{
                          textDecoration: product.boxed
                            ? "none"
                            : "line-through",
                        }}
                      >
                        {product.tracking}
                      </div>
                    </MRow>

                    <MRow>
                      <div className="label">Warehouse Costs</div>
                      <div className="data price">
                        ${convertToUSD(product.overstoreFee)} / ₩
                        {convertToKRW(product.overstoreFee)}
                      </div>
                    </MRow>

                    <MRow>
                      <div className="label">Extra</div>
                      <div className="data price">
                        ${convertToUSD(product.Extra)} / ₩{product.Extra}
                      </div>
                    </MRow>

                    <MRow>
                      <div className="label">Required Inclusion</div>
                      <div className="data check-box">
                        <input
                          type="checkbox"
                          checked={product.isUrgent}
                          disabled
                        />
                      </div>
                    </MRow>

                    <MRow>
                      <div className="label">Vacuum Packing Bag</div>
                      <div className="data check-box">
                        <input type="checkbox" checked={product.bag} disabled />
                      </div>
                    </MRow>

                    <MRow>
                      <div className="label">BOX</div>
                      <div className="data">{product.boxed}</div>
                    </MRow>
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
      </MTabBody>
    ),
    "Product Not Included": (
      <MTabBody>
        <div>
          <div className="sub-header">- Product Options</div>
          <div className="line">
            <MBOptionRow
              type="checkbox"
              id="batteryCheck"
              label="Suspected Products Including Batteries"
              checked={packingDetails.some((detail) => detail.suspectBattery)}
              disabled
            />
            <MBOptionRow
              type="checkbox"
              id="foodCheck"
              label="Food"
              checked={packingDetails.some((detail) => detail.food)}
              disabled
            />
          </div>
        </div>

        <div>
          <div className="sub-header">- Packaging Status</div>

          <MButton
            style={{
              backgroundColor: "var(--color-main-blue)",
              color: "var(--color-white)",
              marginBottom: ".6rem",
            }}
          >
            {getPackingStatusText(orderDetails.packingStatus)}
          </MButton>

          {excludedProducts.map((product, index) => (
            <div className="dropdown-box" key={index}>
              <div
                className="dropdown-bar"
                onClick={() => pniToggleOpen(index)}
              >
                <div className="dropdown-icon">
                  {pniOpenIndexes.includes(index) ? (
                    <Icon icon="caret-down-fill" />
                  ) : (
                    <Icon icon="caret-up" />
                  )}
                </div>

                <div
                  style={{
                    textDecoration: product.boxed ? "none" : "line-through",
                  }}
                >
                  {product.tracking}
                </div>
              </div>

              <div className="dropdown-data">
                {pniOpenIndexes.includes(index) && (
                  <>
                    <MRow>
                      <div className="label">NO</div>
                      <div className="data">{index + 1}</div>
                    </MRow>

                    <MRow>
                      <div className="label">Tracking Number</div>
                      <div className="data">{product.tracking}</div>
                    </MRow>

                    <MRow>
                      <div className="label">Warehouse Costs</div>
                      <div className="data">
                        ${product.overstoreFee} / ₩
                        {convertToKRW(product.overstoreFee)}
                      </div>
                    </MRow>

                    <MRow>
                      <div className="label">Extra</div>
                      <div className="data">
                        ${convertToUSD(product.Extra)} / ₩{product.Extra}
                      </div>
                    </MRow>

                    <MRow>
                      <div className="label">Required Inclusion</div>
                      <div className="data">
                        <Form.Check
                          type="checkbox"
                          checked={product.isUrgent}
                          disabled
                        />
                      </div>
                    </MRow>

                    <MRow>
                      <div className="label">Vacuum Packing Bag</div>
                      <div className="data">
                        <Form.Check
                          type="checkbox"
                          checked={product.bag}
                          disabled
                        />
                      </div>
                    </MRow>

                    <MRow>
                      <div className="label">BOX</div>
                      <div className="data">{"-"}</div>
                    </MRow>
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
      </MTabBody>
    ),
    Address: (
      <MTabBody>
        <div className="sub-header">- Shipping Address</div>
        <div>
          <MRow>
            <div className="label">Recipient</div>
            <div className="data">{orderDetails.adrReceiver}</div>
          </MRow>

          <MRow>
            <div className="label">E-mail</div>
            <div className="data">{orderDetails.adrEmail}</div>
          </MRow>

          <MRow>
            <div className="label">Phone Number</div>
            <div className="data">{orderDetails.adrPhone}</div>
          </MRow>

          <MRow>
            <div className="label">Country</div>
            <div className="data">
              {CODE_COUNTRY_ITEMS[Number(orderDetails.adrCountry)] ||
                "Country not selected"}
            </div>
          </MRow>

          <MRow>
            <div className="label">PCC</div>
            <div className="data">{orderDetails.adrPCCC}</div>
          </MRow>

          <MRow>
            <div className="label">Zip Code</div>
            <div className="data">{orderDetails.adrZip}</div>
          </MRow>

          <MRow>
            <div className="label">State</div>
            <div className="data">{orderDetails.adrState}</div>
          </MRow>

          <MRow>
            <div className="label">City</div>
            <div className="data">{orderDetails.adrCity}</div>
          </MRow>

          <MRow>
            <div className="label">Address-1(35)</div>
            <div className="data">{orderDetails.adrOption1}</div>
          </MRow>

          <MRow>
            <div className="label">Address-2(35)</div>
            <div className="data">{orderDetails.adrOption2}</div>
          </MRow>

          <MRow>
            <div className="label">Address-3(35)</div>
            <div className="data">{orderDetails.adrOption3}</div>
          </MRow>
        </div>
      </MTabBody>
    ),
    "Packaging Information": (
      <MTabBody>
        <div className="sub-header">- Packaging Information</div>
        {packingDetails
          .filter((detail) => !detail.isDeleted)
          .map((detail, index) => {
            const volumeWeight5000 = parseFloat(
              calculateVolumeWeight5000(
                detail.totalWidthCm,
                detail.totalLengthCm,
                detail.totalHeightCm
              ).toFixed(2)
            );
            const volumeWeight6000 = parseFloat(
              calculateVolumeWeight6000(
                detail.totalWidthCm,
                detail.totalLengthCm,
                detail.totalHeightCm
              ).toFixed(2)
            );

            const roundedWeight5000 = roundToFirstDecimal(volumeWeight5000);
            const roundedWeight6000 = roundToFirstDecimal(volumeWeight6000);

            const displayWeight5000 = Math.max(
              detail.totalWeightKg,
              roundedWeight5000
            );
            const displayWeight6000 = Math.max(
              detail.totalWeightKg,
              roundedWeight6000
            );

            return (
              <div key={index}>
                <div>
                  <div>Package Picture</div>
                  <div>
                    {detail.imageUrl ? (
                      <img
                        src={detail.imageUrl}
                        alt="Attached File"
                        style={{
                          marginTop: "0.4rem",
                          marginBottom: "0.4rem",
                          maxWidth: "-webkit-fill-available",
                          cursor: "pointer",
                        }}
                        // onClick={() => handleImageClick(detail.imageUrl)}
                      />
                    ) : (
                      "No image available"
                    )}
                  </div>
                </div>

                <MRow>
                  <div className="label">Product ID</div>
                  <div className="data">{detail.id}</div>
                </MRow>

                <MRow>
                  <div className="label">Weight(KG)</div>
                  <div className="data">{detail.totalWeightKg}</div>
                </MRow>

                <MRow>
                  <div className="label">Width(CM)</div>
                  <div className="data">{detail.totalWidthCm}</div>
                </MRow>

                <MRow>
                  <div className="label">Length(CM)</div>
                  <div className="data">{detail.totalLengthCm}</div>
                </MRow>

                <MRow>
                  <div className="label">Height(CM)</div>
                  <div className="data">{detail.totalHeightCm}</div>
                </MRow>

                <hr style={{ width: "100%", margin: "1rem 0" }} />

                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "100%",
                  }}
                >
                  <div style={{ fontWeight: "bold" }}>Applied Weight</div>
                </div>

                <MRow>
                  <div className="label">FedEx, UPS</div>
                  <div className="data">{displayWeight5000}</div>
                </MRow>

                <MRow>
                  <div className="label">EMS, K-Packet</div>
                  <div className="data">{displayWeight6000}</div>
                </MRow>
              </div>
            );
          })}
      </MTabBody>
    ),
    "Estimate KRW": (
      <MTableBody>
        <div className="line">
          <div className="sub-header">- Estimate</div>
          <div>
            <MRow>
              <div className="label">Delivery Country</div>
              <div className="data">
                {CODE_COUNTRY_ITEMS[Number(orderDetails.adrCountry)] ||
                  "Country not selected"}
              </div>
            </MRow>
            <MRow>
              <div className="label">Request Quantity</div>
              <div className="data">{orderDetails.products.length}</div>
            </MRow>
            <MRow>
              <div className="label">Quantity not Included</div>
              <div className="data">
                {
                  orderDetails.products.filter((product) => !product.boxed)
                    .length
                }
              </div>
            </MRow>
            <MRow>
              <div className="label">Reasons not Included</div>
              <div className="data">
                {getPackingStatusText(orderDetails.packingStatus)}
              </div>
            </MRow>
            <MRow>
              <div className="label">Box Quantity</div>
              <div className="data">
                {packingDetails.filter((detail) => !detail.isDeleted).length}
              </div>
            </MRow>
          </div>
        </div>

        {/* 포장비용 */}
        <div className="line">
          <div className="sub-header">- Packaging Cost</div>
          <div>
            <MRow>
              <div className="label">Box Quantity</div>
              <div className="data">
                {packingDetails.filter((detail) => !detail.isDeleted).length}
              </div>
            </MRow>

            <MRow>
              <div className="label">Total Weight</div>
              <div className="data">{Number(totalWeightSum).toFixed(2)} KG</div>
            </MRow>

            <MRow>
              <div className="label">Member's Discount Rate (%)</div>
              <div className="data">{calculateMembershipDiscountRate()}%</div>
            </MRow>

            <MRow>
              <div className="label">Cost</div>
              <div className="data price">
                ₩ {orderDetails.packagPriceKRW?.toLocaleString("ko-KR")}
              </div>
            </MRow>
          </div>
        </div>

        {/* 옵션비용 */}
        <div className="line">
          <div className="sub-header">- Option Fee</div>
          <div>
            <MRow>
              <div className="label">Sticker Attachment</div>
              <div className="label">
                {orderDetails.optionSticker
                  ? `${packingDetails.length}개`
                  : "-"}
              </div>
            </MRow>

            <MRow>
              <div className="label">Add Air cap</div>
              <div className="data">
                {orderDetails.optionAircap
                  ? `${packingDetails.length}ps(s)`
                  : "-"}
              </div>
            </MRow>

            <MRow>
              <div className="label">Insurance</div>
              <div className="data">
                {orderDetails.optionBag ? `${packingDetails.length}ps(s)` : "-"}
              </div>
            </MRow>

            <MRow>
              <div className="label">Vaccum Packaging Bag</div>
              <div className="data">
                {orderDetails.useVacuumPackagingBag
                  ? `${orderDetails.useVacuumPackagingBag}ps(s)`
                  : "-"}
              </div>
            </MRow>

            <MRow>
              <div className="label">Cost</div>
              <div className="data price">
                ₩{totalCostKRW.toLocaleString("ko-KR")}
              </div>
            </MRow>
          </div>
        </div>

        {/* 웨어하우스 비용 */}
        <div className="line">
          <div className="sub-header">- Warehouse Cost</div>
          <div>
            <MRow>
              <div className="label">Warehouse Cost</div>
              <div className="data price">
                ₩{" "}
                {orderDetails.products
                  .reduce(
                    (acc, product) =>
                      acc + Math.round(product.overstoreFee * exchangeRate),
                    0
                  )
                  .toLocaleString("ko-KR")}
              </div>
            </MRow>

            <MRow>
              <div className="label">Extra Charge</div>
              <div className="data price">
                ₩{" "}
                {orderDetails.products
                  .reduce((acc, product) => acc + product.Extra, 0)
                  .toLocaleString("ko-KR")}
              </div>
            </MRow>

            <MRow>
              <div className="label">Total Cost</div>
              <div className="data price">₩ {warehouseTotalKRW}</div>
            </MRow>
          </div>
        </div>

        {/* 배송비 */}
        <div className="line">
          <div className="sub-header">- Shipping Fee</div>
          <>
            {Object.entries(shippingOptions).map(([carrier, rates]) => (
              <MCard key={carrier} style={{ marginBottom: "1rem" }}>
                <MRow>
                  <div className="label">Delivery Company</div>
                  <div className="data">{carrier}</div>
                </MRow>

                <MRow>
                  <div className="label">KRW</div>
                  <div className="data price">
                    ₩ {rates.KRW?.toLocaleString() || "-"}
                  </div>
                </MRow>

                <div onClick={() => handleShippingOptionChange(carrier)}>
                  {selectedShippingOption === carrier ? (
                    <MSmallButton
                      style={{
                        backgroundColor: "var(--color-main-blue)",
                        color: "var(--color-white)",
                      }}
                    >
                      Select
                    </MSmallButton>
                  ) : (
                    <MSmallButton
                      style={{
                        boxShadow: "0 0 0 1px var(--color-main-blue) inset",
                        color: "var(--color-main-blue)",
                      }}
                    >
                      Select
                    </MSmallButton>
                  )}
                </div>
              </MCard>
            ))}
          </>

          {/* 쿠폰 */}
          {/* 사용 가능 쿠폰 리스트 모달 버튼 */}
          {availableCouponList && availableCouponList.length > 0 && (
            <MButton
              style={{
                backgroundColor: "var(--color-main-blue)",
                color: "var(--color-white)",
                marginBottom: "1rem",
              }}
              onClick={() => setCouponModal(true)}
            >
              See my coupons
              <Icon
                icon="caret-right-fill"
                color="var(--color-white)"
                fontSize="1.2rem"
              />
            </MButton>
          )}
        </div>

        {/* 합계 금액 */}
        <div className="line">
          <div className="sub-header">- Total Amount</div>
          <MRow>
            <div className="label">Total</div>
            <div
              className={
                couponApplied ? "data price apply-coupon" : "data price"
              }
            >
              {`₩${Math.max(
                (orderDetails.packagPriceKRW || 0) +
                  Number(totalCostKRW) +
                  Number(warehouseTotalKRW.replace(/,/g, "")) +
                  Number(shippingOptions[selectedShippingOption]?.KRW || 0) -
                  krwDiscount,
                0
              ).toLocaleString("ko-KR")}`}
            </div>
          </MRow>
        </div>

        {/* 포인트 입력 */}
        <div className="line">
          <div className="sub-header">- Available Points</div>
          <div style={{ marginBottom: ".8rem" }}>
            <Card>
              <Card.Header>Available Points(KRW)</Card.Header>
              <Card.Body>
                <div style={{ marginBottom: "1rem" }}>
                  Current point: {orderDetails.user.point} P
                </div>
                <InputGroup className="mb-3">
                  <InputGroup.Text>Points Apply</InputGroup.Text>
                  <Form.Control
                    type="number"
                    placeholder="포인트 입력"
                    value={pointsToApply.toString()}
                    onChange={(e) => {
                      const inputValue = parseInt(e.target.value, 10) || 0;
                      const totalAmountKRW = calculateTotalAmount();
                      const maxPoints = Math.min(
                        orderDetails.user.point,
                        totalAmountKRW
                      );
                      setPointsToApply(
                        Math.max(0, Math.min(inputValue, maxPoints))
                      );
                    }}
                  />
                  <Button variant="outline-secondary" onClick={applyPointsKRW}>
                    Apply Points
                  </Button>
                </InputGroup>
                <div className="d-flex justify-content-between align-items-center">
                  <div>Remaining Points: {remainingPoints}P</div>
                  <Button variant="outline-secondary" onClick={applyAllPoints}>
                    Apply All Points
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>

        {/* 고객 메시지 입력 폼 */}
        <div>
          <div className="sub-header">- Charge Message</div>
          <div>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Thank you!"
              defaultValue={orderDetails.customerMessage}
              readOnly
            />
          </div>
        </div>

        <div style={{ marginTop: "1rem" }}>
          {isShippingOptionSelected ? (
            <MButton
              onClick={() => {
                setShowKRWPModal(true);
                handleRepackageClick();
              }}
              style={{
                backgroundColor: "var(--color-main-blue)",
                color: "var(--color-white)",
                marginBottom: "1rem",
              }}
            >
              KRW Payment
            </MButton>
          ) : (
            <MButton
              style={{
                backgroundColor: "var(--color-gray)",
                color: "var(--color-white)",
                marginBottom: "1rem",
              }}
            >
              KRW Payment
            </MButton>
          )}
        </div>
      </MTableBody>
    ),
    "Estimate USD": (
      <MTabBody>
        <div className="line">
          <div className="sub-header">- Estimate</div>
          <div>
            <MRow>
              <div className="label">Delivery Country</div>
              <div className="data">
                {CODE_COUNTRY_ITEMS[Number(orderDetails.adrCountry)] ||
                  "Country not selected"}
              </div>
            </MRow>

            <MRow>
              <div className="label">Request Quantity</div>
              <div className="data">{orderDetails.products.length}</div>
            </MRow>

            <MRow>
              <div className="label">Quantity not Included</div>
              <div className="data">
                {
                  orderDetails.products.filter((product) => !product.boxed)
                    .length
                }
              </div>
            </MRow>

            <MRow>
              <div className="label">Reasons not Included</div>
              <div className="data">
                {getPackingStatusText(orderDetails.packingStatus)}
              </div>
            </MRow>

            <MRow>
              <div className="label">Box Quantity</div>
              <div className="data">
                {packingDetails.filter((detail) => !detail.isDeleted).length}
              </div>
            </MRow>
          </div>
        </div>

        {/* 포장비용 */}
        <div className="line">
          <div className="sub-header">- Packaging Cost</div>
          <div>
            <MRow>
              <div className="label">Box Quantity</div>
              <div className="data">
                {packingDetails.filter((detail) => !detail.isDeleted).length}
              </div>
            </MRow>

            <MRow>
              <div className="label">Total Weight</div>
              <div className="data">{Number(totalWeightSum).toFixed(2)} KG</div>
            </MRow>

            <MRow>
              <div className="label">Member's Discount Rate (%)</div>
              <div className="data">{calculateMembershipDiscountRate()}%</div>
            </MRow>

            <MRow>
              <div className="label">Cost</div>
              <div className="data price">$ {orderDetails.packagPriceUSD}</div>
            </MRow>
          </div>
        </div>

        {/* 옵션비용 */}
        <div className="line">
          <div className="sub-header">- Option Fee</div>
          <div>
            <MRow>
              <div className="label">Sticker Attachment</div>
              <div className="data">
                {orderDetails.optionSticker
                  ? `${packingDetails.length}개`
                  : "-"}
              </div>
            </MRow>

            <MRow>
              <div className="label">Add Air cap</div>
              <div className="data">
                {orderDetails.optionAircap ? `${packingDetails.length}개` : "-"}
              </div>
            </MRow>

            <MRow>
              <div className="label">Insurance</div>
              <div className="data">
                {orderDetails.optionBag ? `${packingDetails.length}개` : "-"}
              </div>
            </MRow>

            <MRow>
              <div className="label">Vaccum Packaging Bag</div>
              <div className="data">
                {orderDetails.products.some((product) => product.bag)
                  ? `${packingDetails.length}개`
                  : "-"}
              </div>
            </MRow>

            <MRow>
              <div className="label">Cost</div>
              <div className="data price">$ {totalCostUSD.toFixed(2)}</div>
            </MRow>
          </div>
        </div>

        {/* 웨어하우스 비용 */}
        <div className="line">
          <div className="sub-header">- Warehouse Cost</div>
          <div>
            <MRow>
              <div className="label">Warehouse Cost</div>
              <div className="data price">
                ${" "}
                {orderDetails.products
                  .filter((product) => product.boxed !== 0)
                  .reduce((acc, product) => acc + product.overstoreFee, 0)
                  .toFixed(2)}{" "}
              </div>
            </MRow>

            <MRow>
              <div className="label">Extra Charge</div>
              <div className="data price">
                ${" "}
                {orderDetails.products
                  .filter((product) => product.boxed !== 0)
                  .reduce(
                    (acc, product) => acc + product.Extra / exchangeRate,
                    0
                  )
                  .toFixed(2)}
              </div>
            </MRow>

            <MRow>
              <div className="label">Total Cost</div>
              <div className="data price">$ {warehouseTotalUSD}</div>
            </MRow>
          </div>
        </div>

        {/* 배송비 */}
        <div className="line">
          <div className="sub-header">- Shipping Fee</div>
          <>
            {Object.entries(shippingOptions).map(([carrier, rates]) => (
              <MCard key={carrier} style={{ marginBottom: "1rem" }}>
                <MRow>
                  <div className="label">Delivery Company</div>
                  <div className="data">{carrier}</div>
                </MRow>

                <MRow>
                  <div className="label">USD</div>
                  <div className="data price">
                    $ {rates.USD ? rates.USD.toFixed(2) : "-"}
                  </div>
                </MRow>

                <div onClick={() => handleShippingOptionChange(carrier)}>
                  {selectedShippingOption === carrier ? (
                    <MSmallButton
                      style={{
                        backgroundColor: "var(--color-main-blue)",
                        color: "var(--color-white)",
                      }}
                    >
                      Select
                    </MSmallButton>
                  ) : (
                    <MSmallButton
                      style={{
                        boxShadow: "0 0 0 1px var(--color-main-blue) inset",
                        color: "var(--color-main-blue)",
                      }}
                    >
                      Select
                    </MSmallButton>
                  )}
                </div>
              </MCard>
            ))}
          </>
        </div>

        {/* 쿠폰 */}
        {/* 사용 가능 쿠폰 리스트 모달 버튼 */}
        {availableCouponList && availableCouponList.length > 0 && (
          <MButton
            style={{
              backgroundColor: "var(--color-main-blue)",
              color: "var(--color-white)",
              marginBottom: "1rem",
            }}
            onClick={() => setCouponModal(true)}
          >
            See my coupons
            <Icon
              icon="caret-right-fill"
              color="var(--color-white)"
              fontSize="1.2rem"
            />
          </MButton>
        )}

        {/* 합계 금액 */}
        <div className="line">
          <div className="sub-header">- Total Amount</div>
          <MRow>
            <div className="label">Total</div>
            <div
              className={
                couponApplied ? "data price apply-coupon" : "data price"
              }
            >
              {`$${Math.max(
                (orderDetails.packagPriceUSD || 0) +
                  parseFloat(totalCostUSD.toString()) +
                  parseFloat(warehouseTotalUSD) +
                  Number(shippingOptions[selectedShippingOption]?.USD || 0) -
                  usdDiscount,
                0
              ).toFixed(2)}`}
            </div>
          </MRow>
        </div>

        {/* 포인트 입력 */}
        <div className="line">
          <div className="sub-header">- Available Points</div>
          <div style={{ marginBottom: ".8rem" }}>
            <Card>
              <Card.Header>Available Points(USD)</Card.Header>
              <Card.Body>
                <div style={{ marginBottom: "1rem" }}>
                  Current point: {orderDetails.user.pointUsd} P
                </div>
                <InputGroup className="mb-3">
                  <InputGroup.Text>Points Apply</InputGroup.Text>
                  <Form.Control
                    type="number"
                    placeholder="포인트 입력"
                    value={pointsToApply.toString()}
                    onChange={(e) => {
                      const inputValue = parseFloat(e.target.value) || 0;
                      const totalAmountUSD = calculateTotalAmountUSD();
                      const maxPoints = Math.min(
                        orderDetails.user.pointUsd,
                        totalAmountUSD
                      );
                      setPointsToApply(
                        Math.max(0, Math.min(inputValue, maxPoints))
                      );
                    }}
                    step="0.01"
                  />
                  <Button variant="outline-secondary" onClick={applyPointsUSD}>
                    Apply points
                  </Button>
                </InputGroup>
                <div className="d-flex justify-content-between align-items-center">
                  <div>Remaining Points: {remainingUsdPoints} P</div>
                  <Button
                    variant="outline-secondary"
                    onClick={applyAllPointsUSD}
                  >
                    Apply All Points
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>

        {/* 담당자 메시지 */}
        <div>
          <div className="sub-header">- Charge Message</div>
          <div>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Thank you!"
              defaultValue={orderDetails.customerMessage}
              readOnly
            />
          </div>
        </div>

        <div style={{ marginTop: "1rem" }}>
          {isShippingOptionSelected ? (
            <MButton
              onClick={() => setShowUSDModal(true)}
              style={{
                backgroundColor: "var(--color-main-blue)",
                color: "var(--color-white)",
                marginBottom: "1rem",
              }}
            >
              USD Payment
            </MButton>
          ) : (
            <MButton
              style={{
                backgroundColor: "var(--color-gray)",
                color: "var(--color-white)",
                marginBottom: "1rem",
              }}
            >
              USD Payment
            </MButton>
          )}
        </div>
      </MTabBody>
    ),
  };

  return (
    <>
      {/* 사용 가능한 쿠폰 리스트 모달 */}
      <Modal
        onEnter={() => setCouponApplied(false)}
        show={couponModal}
        onHide={() => {
          setCouponModal(false);
          setCouponApplied(false);
        }}
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>My Available Coupons</Modal.Title>
        </Modal.Header>

        <CouponListWrapper>
          <Label>Please select a coupon to apply.</Label>
          <P> Duplicate use is not possible.</P>

          <CouponList
            krwInput={realKrwTotal}
            usdInput={realUsdTotal}
            couponList={availableCouponList}
            setKrwDiscount={setKrwDiscount}
            setUsdDiscount={setUsdDiscount}
            couponApplied={setCouponApplied}
            setSelectedCouponId={setSelectedCouponId}
          />
        </CouponListWrapper>
      </Modal>

      <Modal
        show={show}
        onHide={onHide}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <MHeader closeButton>
          <div id="contained-modal-title-vcenter">Estimate - {orderNumber}</div>
        </MHeader>
        {isMobile ? (
          <MWrapper>
            <MBody style={{ backgroundColor: "var(--color-lightgray)" }}>
              {Object.keys(openSections).map((section, index) => (
                <MTabSection key={index}>
                  <MTabHeader onClick={() => toggleSection(section)}>
                    <div className="dropdown">
                      {openSections[section] ? (
                        <Icon icon="caret-down-fill" />
                      ) : (
                        <Icon icon="caret-up" />
                      )}
                    </div>
                    <div> {section}</div>
                  </MTabHeader>
                  {openSections[section] && <>{sectionContents[section]}</>}
                </MTabSection>
              ))}
            </MBody>

            <MFooter style={{ padding: "1rem" }}>
              <div className="pay-dead">
                <div>Payment Deadline</div>
                <div className="date">
                  {orderDetails.expiredAt
                    ? orderDetails.expiredAt.slice(0, 10)
                    : ""}
                </div>
              </div>
            </MFooter>
          </MWrapper>
        ) : (
          <>
            <Modal.Body>
              <Tabs
                activeKey={key}
                onSelect={handleSelect}
                className="mb-3 custom-tab"
              >
                <Tab eventKey="Request Information" title="Request Information">
                  <div className="request-info">
                    <div>
                      <strong className="h4 font-weight-bold">
                        <i
                          className="bi bi-chat-right-text-fill"
                          style={{ fontSize: "1.5rem", marginRight: "0.5rem" }}
                        ></i>
                        Service Options
                      </strong>
                      <Row className="mt-3">
                        <Col md={6}>
                          <Form.Check
                            label={
                              <span
                                className={
                                  orderDetails.optionBox === 0
                                    ? "text-dark"
                                    : ""
                                }
                              >
                                Pack only one box
                              </span>
                            }
                            checked={orderDetails.optionBox === 0}
                            onChange={() => {}}
                          />
                        </Col>
                        <Col md={6}>
                          <Form.Check
                            label={
                              <span
                                className={
                                  orderDetails.optionLetter ? "text-dark" : ""
                                }
                              >
                                Letter bag box packaging
                              </span>
                            }
                            checked={orderDetails.optionLetter}
                            onChange={() => {}}
                          />
                        </Col>
                        <Col md={6}>
                          <Form.Check
                            label={
                              <span
                                className={
                                  orderDetails.optionPoster ? "text-dark" : ""
                                }
                              >
                                Minimize poster tubes
                              </span>
                            }
                            checked={orderDetails.optionPoster}
                            onChange={() => {}}
                          />
                        </Col>
                        <Col md={6}>
                          <Form.Check
                            label={
                              <span
                                className={
                                  orderDetails.optionAircap ? "text-dark" : ""
                                }
                              >
                                Add air cap
                              </span>
                            }
                            checked={orderDetails.optionAircap}
                            onChange={() => {}}
                          />
                        </Col>
                        <Col md={6}>
                          <Form.Check
                            label={
                              <span
                                className={
                                  orderDetails.optionSticker ? "text-dark" : ""
                                }
                              >
                                Stickers on 4 sides
                              </span>
                            }
                            checked={orderDetails.optionSticker}
                            onChange={() => {}}
                          />
                        </Col>
                        <Col md={6}>
                          <Form.Check
                            label={
                              <span
                                className={
                                  orderDetails.optionBag ? "text-dark" : ""
                                }
                              >
                                Insurance coverage
                              </span>
                            }
                            checked={orderDetails.optionBag}
                            onChange={() => {}}
                          />
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <div className="table-responsive mt-3">
                    <Table className="table-custom text-center">
                      <thead className="table-light">
                        <tr>
                          <th scope="col">NO</th>
                          <th scope="col" style={{ width: "25%" }}>
                            Tracking
                          </th>
                          <th scope="col">Costs</th>
                          <th scope="col">Extra</th>
                          <th scope="col">Required</th>
                          <th scope="col" style={{ width: "25%" }}>
                            Vacuum Packing
                          </th>
                          <th scope="col">BOX</th>
                        </tr>
                      </thead>
                      <tbody>
                        {orderDetails.products.map((product, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td
                              style={{
                                textDecoration: product.boxed
                                  ? "none"
                                  : "line-through",
                                width: "25%",
                              }}
                            >
                              {product.tracking}
                            </td>
                            <td>
                              ${product.overstoreFee} / ₩
                              {convertToKRW(product.overstoreFee)}
                            </td>
                            <td>
                              ${convertToUSD(product.Extra)} / ₩{product.Extra}
                            </td>
                            <td>
                              <Form.Check
                                type="checkbox"
                                checked={product.isUrgent}
                                disabled
                              />
                            </td>
                            <td>
                              <Form.Check
                                type="checkbox"
                                checked={product.bag}
                                disabled
                              />
                            </td>
                            <td>{product.boxed}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </Tab>
                <Tab
                  eventKey="Products not included"
                  title="Products Not Included"
                >
                  <div>
                    <strong className="h4 font-weight-bold">
                      <i
                        className="bi bi-chat-right-text-fill"
                        style={{ fontSize: "1.5rem", marginRight: "0.5rem" }}
                      ></i>
                      Service Options
                    </strong>
                    <Row className="align-items-center mt-3">
                      <Col md={6}>
                        <Form.Check
                          type="checkbox"
                          id="batteryCheck"
                          label="Suspected products including batteries"
                          checked={packingDetails.some(
                            (detail) => detail.suspectBattery
                          )}
                        />
                      </Col>
                      <Col md={6}>
                        <Form.Check
                          type="checkbox"
                          id="foodCheck"
                          label="Food"
                          checked={packingDetails.some((detail) => detail.food)}
                        />
                      </Col>
                    </Row>
                    <Row className="align-items-center mt-3">
                      <strong className="h4 font-weight-bold">
                        Packaging status:{" "}
                        {getPackingStatusText(orderDetails.packingStatus)}
                      </strong>
                    </Row>
                    <div className="table-responsive mt-3">
                      {excludedProducts.length > 0 && (
                        <Table className="table-custom text-center">
                          <thead className="table-light">
                            <tr>
                              <th>NO</th>
                              <th scope="col" style={{ width: "25%" }}>
                                Tracking
                              </th>
                              <th scope="col">Costs</th>
                              <th scope="col">Extra</th>
                              <th scope="col">Required</th>
                              <th scope="col" style={{ width: "25%" }}>
                                Vacuum Packing
                              </th>
                              <th scope="col">BOX</th>
                            </tr>
                          </thead>
                          <tbody>
                            {excludedProducts.map((product, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td
                                  style={{
                                    textDecoration: product.boxed
                                      ? "none"
                                      : "line-through",
                                    width: "25%",
                                  }}
                                >
                                  {product.tracking}
                                </td>
                                <td>
                                  ${product.overstoreFee} / ₩
                                  {convertToKRW(product.overstoreFee)}
                                </td>
                                <td>
                                  ${convertToUSD(product.Extra)} / ₩
                                  {product.Extra}
                                </td>
                                <td>
                                  <Form.Check
                                    type="checkbox"
                                    checked={product.isUrgent}
                                    disabled
                                  />
                                </td>
                                <td>
                                  <Form.Check
                                    type="checkbox"
                                    checked={product.bag}
                                    disabled
                                  />
                                </td>
                                <td>{"-"}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      )}
                      {excludedProducts.length === 0 && (
                        <p>No products to display.</p>
                      )}
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="주소" title="Address">
                  <div className="shipping-address">
                    <strong className="h4 font-weight-bold mb-3">
                      <i
                        className="bi-map-fill"
                        style={{ fontSize: "1.5rem", marginRight: "0.5rem" }}
                      ></i>
                      Address
                    </strong>
                    <Row className="mb-3"></Row>
                    <Form>
                      <Row className="mb-3">
                        <Col md={6}>
                          <Form.Group controlId="formAddressReceiver">
                            <Form.Label className="font-bold">
                              Recipient
                            </Form.Label>
                            <Form.Control
                              type="text"
                              readOnly
                              style={{ backgroundColor: "#f8f9fa" }}
                              placeholder="받는 사람 이름"
                              defaultValue={orderDetails.adrReceiver}
                            />
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group controlId="formEmail">
                            <Form.Label className="font-bold">
                              E-mail
                            </Form.Label>
                            <Form.Control
                              type="text"
                              readOnly
                              style={{ backgroundColor: "#f8f9fa" }}
                              defaultValue={orderDetails.adrEmail}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col md={6}>
                          <Form.Group controlId="formPhoneNumber">
                            <Form.Label className="font-bold">
                              Phone number
                            </Form.Label>
                            <Form.Control
                              type="text"
                              readOnly
                              style={{ backgroundColor: "#f8f9fa" }}
                              defaultValue={orderDetails.adrPhone}
                            />
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group controlId="formCountry">
                            <Form.Label className="font-bold">
                              Country
                            </Form.Label>
                            <Form.Control
                              type="text"
                              readOnly
                              style={{ backgroundColor: "#f8f9fa" }}
                              value={
                                CODE_COUNTRY_ITEMS[
                                  Number(orderDetails.adrCountry)
                                ] || "Country not selected"
                              }
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col md={6}>
                          <Form.Group controlId="formPCC">
                            <Form.Label className="font-bold">PCC</Form.Label>
                            <Form.Control
                              type="text"
                              readOnly
                              style={{ backgroundColor: "#f8f9fa" }}
                              defaultValue={orderDetails.adrPCCC}
                            />
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group controlId="formZipcode">
                            <Form.Label className="font-bold">
                              Zipcode
                            </Form.Label>
                            <Form.Control
                              type="text"
                              readOnly
                              style={{ backgroundColor: "#f8f9fa" }}
                              defaultValue={orderDetails.adrZip}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col md={6}>
                          <Form.Group controlId="formState">
                            <Form.Label className="font-bold">State</Form.Label>
                            <Form.Control
                              type="text"
                              readOnly
                              style={{ backgroundColor: "#f8f9fa" }}
                              defaultValue={orderDetails.adrState}
                            />
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group controlId="formCity">
                            <Form.Label className="font-bold">City</Form.Label>
                            <Form.Control
                              type="text"
                              readOnly
                              style={{ backgroundColor: "#f8f9fa" }}
                              defaultValue={orderDetails.adrCity}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col md={6}>
                          <Form.Group controlId="formAddress1">
                            <Form.Label className="font-bold">
                              Address-1(35)
                            </Form.Label>
                            <Form.Control
                              type="text"
                              readOnly
                              style={{ backgroundColor: "#f8f9fa" }}
                              defaultValue={orderDetails.adrOption1}
                            />
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group controlId="formAddress2">
                            <Form.Label className="font-bold">
                              Address-2(35)
                            </Form.Label>
                            <Form.Control
                              type="text"
                              readOnly
                              style={{ backgroundColor: "#f8f9fa" }}
                              defaultValue={orderDetails.adrOption2}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col md={6}>
                          <Form.Group controlId="formAddress3">
                            <Form.Label className="font-bold">
                              Address-3(35)
                            </Form.Label>
                            <Form.Control
                              type="text"
                              readOnly
                              style={{ backgroundColor: "#f8f9fa" }}
                              defaultValue={orderDetails.adrOption3}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </Tab>
                <Tab eventKey="포장정보" title="Packaging Information">
                  <div className="packing-info">
                    <strong className="h4 font-weight-bold mb-3">
                      <i
                        className="bi-box-seam-fill"
                        style={{ fontSize: "1.5rem", marginRight: "0.5rem" }}
                      ></i>
                      Packaging
                    </strong>
                    <Row className="mb-3"></Row>
                    <div className="table-responsive">
                      <Table bordered className="table-custom text-center">
                        <thead className="table-light">
                          <tr>
                            <th rowSpan={2} style={{ width: "15%" }}>
                              Package Picture
                            </th>
                            <th rowSpan={2} style={{ width: "10%" }}>
                              Product ID
                            </th>
                            <th rowSpan={2} style={{ width: "10%" }}>
                              Weight (KG)
                            </th>
                            <th rowSpan={2} style={{ width: "10%" }}>
                              Width (CM)
                            </th>
                            <th rowSpan={2} style={{ width: "10%" }}>
                              Length (CM)
                            </th>
                            <th rowSpan={2} style={{ width: "10%" }}>
                              Height (CM)
                            </th>
                            <th colSpan={2} style={{ width: "20%" }}>
                              Applied Weight (CM)
                            </th>
                          </tr>
                          <tr>
                            <th style={{ width: "10%" }}>FedEx, UPS</th>
                            <th style={{ width: "10%" }}>EMS, K-Packet</th>
                          </tr>
                        </thead>
                        <tbody>
                          {packingDetails
                            .filter((detail) => !detail.isDeleted)
                            .map((detail, index) => {
                              const volumeWeight5000 =
                                calculateVolumeWeight5000(
                                  detail.totalWidthCm,
                                  detail.totalLengthCm,
                                  detail.totalHeightCm
                                );
                              const volumeWeight6000 =
                                calculateVolumeWeight6000(
                                  detail.totalWidthCm,
                                  detail.totalLengthCm,
                                  detail.totalHeightCm
                                );

                              const roundedWeight5000 =
                                roundToFirstDecimal(volumeWeight5000);
                              const roundedWeight6000 =
                                roundToFirstDecimal(volumeWeight6000);

                              const displayWeight5000 = Math.max(
                                detail.totalWeightKg,
                                roundedWeight5000
                              );
                              const displayWeight6000 = Math.max(
                                detail.totalWeightKg,
                                roundedWeight6000
                              );

                              return (
                                <tr key={index}>
                                  <td>
                                    {detail.imageUrl ? (
                                      <img
                                        src={detail.imageUrl}
                                        alt="Attached File"
                                        style={{
                                          maxWidth: isZoomed
                                            ? "22rem"
                                            : "10rem",
                                          height: "auto",
                                          cursor: "pointer",
                                        }}
                                        onClick={() =>
                                          handleImageClick(detail.imageUrl)
                                        }
                                      />
                                    ) : (
                                      "No image available"
                                    )}
                                  </td>
                                  <td>{detail.id}</td>
                                  <td>{detail.totalWeightKg}</td>
                                  <td>{detail.totalWidthCm}</td>
                                  <td>{detail.totalLengthCm}</td>
                                  <td>{detail.totalHeightCm}</td>
                                  <td>{displayWeight5000}</td>
                                  <td>{displayWeight6000}</td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="견적서(USD)" title="Estimate(USD)">
                  <div className="invoice-info">
                    <strong className="h4 font-weight-bold mb-3">
                      <i
                        className="bi bi-calculator"
                        style={{ fontSize: "1.5rem", marginRight: "0.5rem" }}
                      ></i>
                      Estimate(USD)
                    </strong>
                    <Row className="mb-3"></Row>
                    <div className="summary">
                      <div className="table-responsive">
                        <Table className="table-custom text-center">
                          <thead className="table-light">
                            <tr>
                              <th>Delivery country</th>
                              <th>Request quantity</th>
                              <th>Quantity not Included</th>
                              <th>Reasons not Included</th>
                              <th>Box Quantity</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                {CODE_COUNTRY_ITEMS[
                                  Number(orderDetails.adrCountry)
                                ] || "Country not selected"}
                              </td>
                              <td>{orderDetails.products.length}</td>
                              <td>
                                {
                                  orderDetails.products.filter(
                                    (product) => !product.boxed
                                  ).length
                                }
                              </td>
                              <td>
                                {getPackingStatusText(
                                  orderDetails.packingStatus
                                )}
                              </td>
                              <td>
                                {
                                  packingDetails.filter(
                                    (detail) => !detail.isDeleted
                                  ).length
                                }
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </div>
                    {/* 포장비용 */}
                    <div className="repack-fee mt-4">
                      <div className="table-responsive">
                        <Table
                          bordered
                          hover
                          className="table-custom text-center font-unified"
                        >
                          <thead className="table-light">
                            <tr>
                              <th colSpan={2} className="text-center">
                                Packaging cost
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Box Quantity</td>
                              <td colSpan={1} className="text-center">
                                {
                                  packingDetails.filter(
                                    (detail) => !detail.isDeleted
                                  ).length
                                }
                              </td>
                            </tr>
                            <tr>
                              <td>Total weight</td>
                              <td colSpan={1} className="text-center">
                                {Number(totalWeightSum).toFixed(2)} KG
                              </td>
                            </tr>
                            <tr>
                              <td>Member's discount rate (%)</td>
                              <td colSpan={1} className="text-center">
                                {calculateMembershipDiscountRate()}%
                              </td>
                            </tr>
                            <tr className="table-primary">
                              <th>Total</th>
                              {/* <td className="text-center">
                              KRW ₩{orderDetails.packagPriceKRW}
                            </td> */}
                              <td className="text-center">
                                USD ${orderDetails.packagPriceUSD}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </div>
                    {/* 옵션비용 */}
                    <div className="option-fee">
                      <div className="table-responsive">
                        <Table
                          bordered
                          hover
                          className="table-custom text-center font-unified"
                        >
                          <thead className="table-light">
                            <tr>
                              <th colSpan={2} className="text-center">
                                Optional Cost
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Sticker attachment</td>
                              <td colSpan={1} className="text-center">
                                {orderDetails.optionSticker
                                  ? `${packingDetails.length}pc(s)`
                                  : "-"}
                              </td>
                            </tr>
                            <tr>
                              <td>Add Air Cap</td>
                              <td colSpan={1} className="text-center">
                                {orderDetails.optionAircap
                                  ? `${packingDetails.length}pc(s)`
                                  : "-"}
                              </td>
                            </tr>
                            <tr>
                              <td>Insurance</td>
                              <td colSpan={1} className="text-center">
                                {orderDetails.optionBag
                                  ? `${packingDetails.length}pc(s)`
                                  : "-"}
                              </td>
                            </tr>
                            <tr>
                              <td>Vacuum Packaging Bag</td>
                              <td colSpan={1} className="text-center">
                                {orderDetails.useVacuumPackagingBag
                                  ? `${orderDetails.useVacuumPackagingBag}pc(s)`
                                  : "-"}
                              </td>
                            </tr>
                          </tbody>
                          <tfoot>
                            <tr className="table-primary">
                              <th>Total</th>
                              {/* <td className="text-center">
                              KRW {totalCostKRW.toLocaleString("ko-KR")}
                            </td> */}
                              <td className="text-center">
                                USD {totalCostUSD.toFixed(2)}
                              </td>
                            </tr>
                          </tfoot>
                        </Table>
                      </div>
                    </div>
                    {/* 웨어하우스 비용 */}
                    <div className="warehouse-fee">
                      <div className="table-responsive">
                        <Table
                          bordered
                          hover
                          className="table-custom text-center"
                        >
                          <thead className="table-light">
                            <tr>
                              <th colSpan={2} className="text-center">
                                Warehouse Costs
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th className="text-center">Costs</th>
                              {/* <th className="text-center">KRW</th> */}
                              <th className="text-center">USD</th>
                            </tr>
                            <tr>
                              <td className="text-center">Warehouse Cost</td>
                              {/* <td className="text-center">
                              ₩
                              {orderDetails.products
                                .reduce(
                                  (acc, product) =>
                                    acc +
                                    Math.round(
                                      product.overstoreFee * exchangeRate
                                    ),
                                  0
                                )
                                .toLocaleString("ko-KR")}
                            </td> */}
                              <td className="text-center">
                                $
                                {orderDetails.products
                                          .filter((product) => product.boxed !== 0)
                                  .reduce(
                                    (acc, product) =>
                                      acc + product.overstoreFee,
                                    0
                                  )
                                  .toFixed(2)}
                              </td>
                            </tr>
                            <tr>
                              <td className="text-center">Extra charge</td>
                              {/* <td className="text-center">
                              ₩
                              {orderDetails.products
                                .reduce(
                                  (acc, product) => acc + product.Extra,
                                  0
                                )
                                .toLocaleString("ko-KR")}
                            </td> */}
                              <td className="text-center">
                                $
                                {orderDetails.products
                                          .filter((product) => product.boxed !== 0)
                                  .reduce(
                                    (acc, product) =>
                                      acc + product.Extra / exchangeRate,
                                    0
                                  )
                                  .toFixed(2)}
                              </td>
                            </tr>
                          </tbody>
                          <tfoot>
                            <tr className="table-primary">
                              <th className="text-center">Total</th>
                              {/* <td className="text-center">
                              ₩{warehouseTotalKRW}
                            </td> */}
                              <td className="text-center">
                                ${warehouseTotalUSD}
                              </td>
                            </tr>
                          </tfoot>
                        </Table>
                      </div>
                    </div>
                    {/* 배송비 */}
                    <div className="shipping-fee">
                      <strong className="h4 font-weight-bold mb-3">
                        <i
                          className="bi bi-truck"
                          style={{ fontSize: "1.5rem", marginRight: "0.5rem" }}
                        ></i>
                        Delivery Charge(USD)
                      </strong>
                      <Row className="mb-3"></Row>
                      <div className="table-responsive">
                        <Table
                          bordered
                          hover
                          className="table-custom text-center"
                        >
                          <thead className="table-light">
                            <tr>
                              <th></th>
                              <th>Delivery company</th>
                              <th>USD</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Object.entries(shippingOptions).map(
                              ([carrier, rates]) => (
                                <tr key={carrier}>
                                  <td>
                                    <Form.Check
                                      type="radio"
                                      name="shippingOptions"
                                      defaultChecked={
                                        selectedShippingOption === carrier
                                      }
                                      onChange={() =>
                                        handleShippingOptionChange(carrier)
                                      }
                                    />
                                  </td>
                                  <td>{carrier}</td>
                                  <td>
                                    {rates.USD ? rates.USD.toFixed(2) : "-"}
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </Table>
                      </div>
                    </div>

                    {/* 쿠폰 */}
                    {/* 사용 가능 쿠폰 리스트 모달 버튼 */}
                    {availableCouponList && availableCouponList.length > 0 && (
                      <CouponViewButtonRow onClick={() => setCouponModal(true)}>
                        See my coupons
                        <Icon
                          icon="caret-right-fill"
                          color="var(--color-white)"
                          fontSize="1.4rem"
                        />
                      </CouponViewButtonRow>
                    )}

                    {/* 총계 */}
                    <div>
                      <strong className="h4 font-weight-bold mb-3">
                        <i
                          className="bi bi-currency-dollar"
                          style={{ fontSize: "1.5rem", marginRight: "0.5rem" }}
                        ></i>
                        Total Amount(USD)
                      </strong>
                      <TotalFeeRow>
                        <div className={couponApplied ? "apply-coupon" : ""}>
                          {`$${Math.max(
                            (orderDetails.packagPriceUSD || 0) +
                              parseFloat(totalCostUSD.toString()) +
                              parseFloat(warehouseTotalUSD) +
                              Number(
                                shippingOptions[selectedShippingOption]?.USD ||
                                  0
                              ) -
                              usdDiscount,
                            0
                          ).toFixed(2)}`}
                        </div>
                      </TotalFeeRow>
                    </div>
                    <div className="available-points mb-3">
                      <Card className="card-custom">
                        <Card.Header className="card-custom-header">
                          Available Points(USD)
                        </Card.Header>
                        <Card.Body className="card-custom-body">
                          <div className="mb-3">
                            Current point: {orderDetails.user.pointUsd} P
                          </div>
                          <InputGroup className="mb-3 input-group">
                            <InputGroup.Text>Points Apply</InputGroup.Text>
                            <Form.Control
                              type="number"
                              placeholder="포인트 입력"
                              value={pointsToApply.toString()}
                              onChange={(e) => {
                                const inputValue =
                                  parseFloat(e.target.value) || 0;
                                const totalAmountUSD =
                                  calculateTotalAmountUSD();
                                const maxPoints = Math.min(
                                  orderDetails.user.pointUsd,
                                  totalAmountUSD
                                );
                                setPointsToApply(
                                  Math.max(0, Math.min(inputValue, maxPoints))
                                );
                              }}
                              step="0.01"
                            />
                            <Button
                              variant="outline-secondary"
                              onClick={applyPointsUSD}
                            >
                              Apply points
                            </Button>
                          </InputGroup>
                          <div className="d-flex justify-content-between align-items-center">
                            <div>Remaining Points: {remainingUsdPoints} P</div>
                            <Button
                              variant="outline-secondary"
                              onClick={applyAllPointsUSD}
                            >
                              Apply All Points
                            </Button>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                    {/* 고객 메시지 입력 폼 */}
                    <div className="manager-message mb-3">
                      <Card>
                        <Card.Header className="card-custom-header">
                          Message
                        </Card.Header>
                        <Card.Body>
                          <Form.Control
                            as="textarea"
                            rows={3}
                            placeholder="Thank you!"
                            defaultValue={orderDetails.customerMessage}
                            readOnly
                          />
                        </Card.Body>
                      </Card>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end">
                    {!isDepositorFeeSet && (
                      <>
                        <Button
                          variant="primary"
                          onClick={() => setShowUSDModal(true)}
                          disabled={!isShippingOptionSelected}
                        >
                          USD Payment
                        </Button>
                      </>
                    )}
                  </div>
                </Tab>
                <Tab eventKey="견적서" title="Estimate(KRW)">
                  <div className="invoice-info">
                    <strong className="h4 font-weight-bold mb-3">
                      <i
                        className="bi bi-calculator"
                        style={{ fontSize: "1.5rem", marginRight: "0.5rem" }}
                      ></i>
                      Estimate(KRW)
                    </strong>
                    <Row className="mb-3"></Row>
                    <div className="summary">
                      <div className="table-responsive">
                        <Table className="table-custom text-center">
                          <thead className="table-light">
                            <tr>
                              <th>Delivery country</th>
                              <th>Request quantity</th>
                              <th>Quantity not Included</th>
                              <th>Reasons not Included</th>
                              <th>Box Quantity</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                {CODE_COUNTRY_ITEMS[
                                  Number(orderDetails.adrCountry)
                                ] || "Country not selected"}
                              </td>
                              <td>{orderDetails.products.length}</td>
                              <td>
                                {
                                  orderDetails.products.filter(
                                    (product) => !product.boxed
                                  ).length
                                }
                              </td>
                              <td>
                                {getPackingStatusText(
                                  orderDetails.packingStatus
                                )}
                              </td>
                              <td>
                                {
                                  packingDetails.filter(
                                    (detail) => !detail.isDeleted
                                  ).length
                                }
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </div>
                    {/* 포장비용 */}
                    <div className="repack-fee mt-4">
                      <div className="table-responsive">
                        <Table
                          bordered
                          hover
                          className="table-custom text-center font-unified"
                        >
                          <thead className="table-light">
                            <tr>
                              <th colSpan={2} className="text-center">
                                Packaging Cost
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Box Quantity</td>
                              <td colSpan={1} className="text-center">
                                {
                                  packingDetails.filter(
                                    (detail) => !detail.isDeleted
                                  ).length
                                }
                              </td>
                            </tr>
                            <tr>
                              <td>Total weight</td>
                              <td colSpan={1} className="text-center">
                                {Number(totalWeightSum).toFixed(2)} KG
                              </td>
                            </tr>
                            <tr>
                              <td>Member's discount rate (%)</td>
                              <td colSpan={1} className="text-center">
                                {calculateMembershipDiscountRate()}%
                              </td>
                            </tr>
                            <tr className="table-primary">
                              <th>Total</th>
                              <td className="text-center">
                                KRW ₩{orderDetails.packagPriceKRW}
                              </td>
                              {/* <td className="text-center">
                              USD ${orderDetails.packagPriceUSD}
                            </td> */}
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </div>
                    {/* 옵션비용 */}
                    <div className="option-fee">
                      <div className="table-responsive">
                        <Table
                          bordered
                          hover
                          className="table-custom text-center font-unified"
                        >
                          <thead className="table-light">
                            <tr>
                              <th colSpan={2} className="text-center">
                                Optional Cost
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Sticker attachment</td>
                              <td colSpan={1} className="text-center">
                                {orderDetails.optionSticker
                                  ? `${packingDetails.length}pc(s)`
                                  : "-"}
                              </td>
                            </tr>
                            <tr>
                              <td>Add Air Cap</td>
                              <td colSpan={1} className="text-center">
                                {orderDetails.optionAircap
                                  ? `${packingDetails.length}pc(s)`
                                  : "-"}
                              </td>
                            </tr>
                            <tr>
                              <td>Insurance</td>
                              <td colSpan={1} className="text-center">
                                {orderDetails.optionBag
                                  ? `${packingDetails.length}pc(s)`
                                  : "-"}
                              </td>
                            </tr>
                            <tr>
                              <td>Vacuum Packaging Bag</td>
                              <td colSpan={1} className="text-center">
                                {orderDetails.useVacuumPackagingBag
                                  ? `${orderDetails.useVacuumPackagingBag}pc(s)`
                                  : "-"}
                              </td>
                            </tr>
                          </tbody>
                          <tfoot>
                            <tr className="table-primary">
                              <th>Total</th>
                              <td className="text-center">
                                KRW {totalCostKRW.toLocaleString("ko-KR")}
                              </td>
                              {/* <td className="text-center">
                              USD {totalCostUSD.toFixed(2)}
                            </td> */}
                            </tr>
                          </tfoot>
                        </Table>
                      </div>
                    </div>
                    {/* 웨어하우스 비용 */}
                    <div className="warehouse-fee">
                      <div className="table-responsive">
                        <Table
                          bordered
                          hover
                          className="table-custom text-center"
                        >
                          <thead className="table-light">
                            <tr>
                              <th colSpan={2} className="text-center">
                                Warehouse Costs
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th className="text-center">Costs</th>
                              <th className="text-center">KRW</th>
                              {/* <th className="text-center">USD</th> */}
                            </tr>
                            <tr>
                              <td className="text-center">Warehouse Cost</td>
                              <td className="text-center">
                                ₩
                                {orderDetails.products
                                          .filter((product) => product.boxed !== 0)
                                  .reduce(
                                    (acc, product) =>
                                      acc +
                                      Math.round(
                                        product.overstoreFee * exchangeRate
                                      ),
                                    0
                                  )
                                  .toLocaleString("ko-KR")}
                              </td>
                              {/* <td className="text-center">
                              $
                              {orderDetails.products
                                .reduce(
                                  (acc, product) => acc + product.overstoreFee,
                                  0
                                )
                                .toFixed(2)}
                            </td> */}
                            </tr>
                            <tr>
                              <td className="text-center">Extra charge</td>
                              <td className="text-center">
                                ₩
                                {orderDetails.products
                                          .filter((product) => product.boxed !== 0)
                                  .reduce(
                                    (acc, product) => acc + product.Extra,
                                    0
                                  )
                                  .toLocaleString("ko-KR")}
                              </td>
                              {/* <td className="text-center">
                              $
                              {orderDetails.products
                                .reduce(
                                  (acc, product) =>
                                    acc + product.Extra / exchangeRate,
                                  0
                                )
                                .toFixed(2)}
                            </td> */}
                            </tr>
                          </tbody>
                          <tfoot>
                            <tr className="table-primary">
                              <th className="text-center">Total</th>
                              <td className="text-center">
                                ₩{warehouseTotalKRW}
                              </td>
                              {/* <td className="text-center">
                              ${warehouseTotalUSD}
                            </td> */}
                            </tr>
                          </tfoot>
                        </Table>
                      </div>
                    </div>
                    {/* 배송비 */}
                    <div className="shipping-fee">
                      <strong className="h4 font-weight-bold mb-3">
                        <i
                          className="bi bi-truck"
                          style={{ fontSize: "1.5rem", marginRight: "0.5rem" }}
                        ></i>
                        Delivery Charge(KRW)
                      </strong>
                      <Row className="mb-3"></Row>
                      <div className="table-responsive">
                        <Table
                          bordered
                          hover
                          className="table-custom text-center"
                        >
                          <thead className="table-light">
                            <tr>
                              <th></th>
                              <th>Delivery company</th>
                              <th>KRW</th>
                              {/* <th>USD</th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {Object.entries(shippingOptions).map(
                              ([carrier, rates]) => (
                                <tr key={carrier}>
                                  <td>
                                    <Form.Check
                                      type="radio"
                                      name="shippingOptions"
                                      defaultChecked={
                                        selectedShippingOption === carrier
                                      }
                                      onChange={() =>
                                        handleShippingOptionChange(carrier)
                                      }
                                    />
                                  </td>
                                  <td>{carrier}</td>
                                  <td>{rates.KRW?.toLocaleString() || "-"}</td>
                                  {/* <td>
                                  {rates.USD ? rates.USD.toFixed(2) : "-"}
                                </td> */}
                                </tr>
                              )
                            )}
                          </tbody>
                        </Table>
                      </div>
                    </div>

                    {/* 쿠폰 */}
                    {/* 사용 가능 쿠폰 리스트 모달 버튼 */}
                    {availableCouponList && availableCouponList.length > 0 && (
                      <CouponViewButtonRow onClick={() => setCouponModal(true)}>
                        See my coupons
                        <Icon
                          icon="caret-right-fill"
                          color="var(--color-white)"
                          fontSize="1.4rem"
                        />
                      </CouponViewButtonRow>
                    )}

                    {/* 총계 */}
                    <div>
                      <strong className="h4 font-weight-bold mb-3">
                        <i
                          className="bi bi-cash"
                          style={{ fontSize: "1.5rem", marginRight: "0.5rem" }}
                        ></i>
                        Total Amount(KRW)
                      </strong>
                      <Row className="mb-3"></Row>
                      <TotalFeeRow>
                        <div className={couponApplied ? "apply-coupon" : ""}>
                          {`₩${Math.max(
                            (orderDetails.packagPriceKRW || 0) +
                              Number(totalCostKRW) +
                              Number(warehouseTotalKRW.replace(/,/g, "")) +
                              Number(
                                shippingOptions[selectedShippingOption]?.KRW ||
                                  0
                              ) -
                              krwDiscount,
                            0
                          ).toLocaleString("ko-KR")}`}
                        </div>
                        {/* <div className={couponApplied ? "apply-coupon" : ""}>
                          {`$${Math.max(
                            (orderDetails.packagPriceUSD || 0) +
                              parseFloat(totalCostUSD.toString()) +
                              parseFloat(warehouseTotalUSD) +
                              Number(
                                shippingOptions[selectedShippingOption]?.USD ||
                                  0
                              ) -
                              usdDiscount,
                            0
                          ).toFixed(2)}`}
                        </div> */}
                      </TotalFeeRow>
                    </div>
                    <div className="available-points mb-3">
                      <Card className="card-custom">
                        <Card.Header className="card-custom-header">
                          Available Points(KRW)
                        </Card.Header>
                        <Card.Body className="card-custom-body">
                          <div className="mb-3">
                            Current point: {orderDetails.user.point} P
                          </div>
                          <InputGroup className="mb-3 input-group">
                            <InputGroup.Text>Points Apply</InputGroup.Text>
                            <Form.Control
                              type="number"
                              placeholder="포인트 입력"
                              value={pointsToApply.toString()}
                              onChange={(e) => {
                                const inputValue =
                                  parseInt(e.target.value, 10) || 0;
                                const totalAmountKRW = calculateTotalAmount();
                                const maxPoints = Math.min(
                                  orderDetails.user.point,
                                  totalAmountKRW
                                );
                                setPointsToApply(
                                  Math.max(0, Math.min(inputValue, maxPoints))
                                );
                              }}
                            />
                            <Button
                              variant="outline-secondary"
                              className="btn"
                              onClick={applyPointsKRW}
                            >
                              Apply Points
                            </Button>
                          </InputGroup>
                          <div className="d-flex justify-content-between">
                            <div>Remaining Points: {remainingPoints} P</div>
                            <Button
                              variant="outline-secondary"
                              className="btn"
                              onClick={applyAllPoints}
                            >
                              Apply All Points
                            </Button>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                    {/* 고객 메시지 입력 폼 */}
                    <div className="manager-message mb-3">
                      <Card>
                        <Card.Header className="card-custom-header">
                          Message
                        </Card.Header>
                        <Card.Body>
                          <Form.Control
                            as="textarea"
                            rows={3}
                            placeholder="Thank you!"
                            defaultValue={orderDetails.customerMessage}
                            readOnly
                          />
                        </Card.Body>
                      </Card>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end">
                    {!isDepositorFeeSet && (
                      <>
                        <Button
                          variant="secondary"
                          onClick={handleRepackageClick}
                          className="me-3"
                          disabled={!isShippingOptionSelected}
                        >
                          KRW Payment
                        </Button>
                      </>
                    )}
                  </div>
                </Tab>
              </Tabs>
            </Modal.Body>
            <Modal.Footer>
              <div className="w-100 mb-3">
                <InputGroup className="mb-3 input-group">
                  <InputGroup.Text className="input-group-text-custom">
                    Payment Deadline
                  </InputGroup.Text>
                  <Form.Control
                    defaultValue={
                      orderDetails.expiredAt
                        ? orderDetails.expiredAt.slice(0, 10)
                        : ""
                    }
                    readOnly
                  />
                </InputGroup>
              </div>
              {/* <div className="d-flex justify-content-between">
                {!isDepositorFeeSet && (
                  <>
                    <Button
                      variant="secondary"
                      onClick={handleRepackageClick}
                      className="me-3"
                      disabled={!isShippingOptionSelected}
                    >
                      KRW Payment
                    </Button>
                    <Button
                      variant="primary"
                      onClick={() => setShowUSDModal(true)}
                      disabled={!isShippingOptionSelected}
                    >
                      USD Payment
                    </Button>
                  </>
                )}
              </div> */}
            </Modal.Footer>
          </>
        )}
      </Modal>

      {/* KRW Payment Modal */}
      <Modal
        show={showKRWPModal}
        size="lg"
        onHide={() => setShowKRWPModal(false)}
        centered
      >
        <MHeader closeButton>
          <div>KRW Payment</div>
        </MHeader>

        <MWrapper>
          <MBody style={{ padding: "0 1rem" }}>
            <div className="line">
              <div className="sub-header">- Payment from</div>
              <div>
                <div>
                  <div>Name of Depositor</div>
                  <Form.Control
                    type="text"
                    value={depositorName}
                    onChange={(e) => setDepositorName(e.target.value)}
                  />
                </div>

                <div>
                  <div>Deposit Amount</div>
                  <Form.Control
                    type="number"
                    value={currentDepositAmount}
                    onChange={handleDepositAmountChange}
                  />
                </div>

                <div>
                  <div>Link</div>
                  <Form.Control
                    type="text"
                    value={link}
                    onChange={(e) => setLink(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="line">
              <div className="sub-header">- Payment to</div>
              <div>
                <MRow>
                  <div className="label">Bank</div>
                  <div className="data">Woori bank</div>
                </MRow>
                <MRow>
                  <div className="label">Account holder</div>
                  <div className="data">ONTACT KOREA</div>
                </MRow>
                <MRow>
                  <div className="label">Email address</div>
                  <div className="data">ontactkorea@gmail.com</div>
                </MRow>
                <MRow>
                  <div className="label">Bank account number</div>
                  <div className="data">1005-804-179216</div>
                </MRow>
                <MRow>
                  <div className="label">Deposit amount</div>
                  <div className="data price">
                    ￦{Number(depositAmount).toLocaleString("ko-KR")}
                  </div>
                </MRow>
              </div>
            </div>

            <div className="line">
              <div className="sub-header">
                - Cancellation and refund regulations
              </div>
              <div className="f-row">
                <Form.Check
                  type="checkbox"
                  name="agree"
                  checked={agreeCheck === true}
                  onChange={() => setAgreeCheck(!agreeCheck)}
                  style={{ marginRight: ".4rem" }}
                />
                <div style={{ marginRight: ".4rem" }}>I agree to the</div>
                <a
                  target="_blank"
                  rel="noreferrer noopener"
                  href="https://docs.google.com/document/d/1SuZi6RC7hWQG9aXqoRLTO2HbZuH0wJ6uondJEFs5Z6s/edit"
                >
                  <div className="text-link">terms and conditions.</div>
                </a>
              </div>
            </div>

            <div style={{ marginBottom: "1rem" }}>
              <div className="sub-header">- Notice</div>
              <div>
                The deposit of KRW is pending payment until the deposit is
                confirmed at Ontact Korea. It will be processed and will be
                changed to payment completed after confirmation.
              </div>
            </div>
          </MBody>

          <MFooter style={{ padding: "1rem" }}>
            <MTwoButtonRow>
              <MButton
                onClick={() => setShowKRWPModal(false)}
                style={{
                  backgroundColor: "var(--color-gray)",
                  color: "var(--color-white)",
                  margin: "0.6rem 0",
                }}
              >
                Close
              </MButton>

              <MButton
                onClick={handleKRWPaymentSubmit}
                style={{
                  backgroundColor: "var(--color-main-blue)",
                  color: "var(--color-white)",
                  margin: "0.6rem 0",
                }}
              >
                Submit Payment
              </MButton>
            </MTwoButtonRow>
          </MFooter>
        </MWrapper>
      </Modal>

      {/* USD 결제 모달 */}
      <Modal
        show={showUSDModal}
        size="lg"
        onHide={() => setShowUSDModal(false)}
        centered
      >
        <MHeader closeButton>
          <div>USD Payment</div>
        </MHeader>

        <MWrapper>
          <MBody style={{ padding: "0 1rem" }}>
            <div className="line">
              <div className="sub-header">- Order Total</div>
              <MRow>
                <div className="label">Total</div>
                <div className="data price">
                  {`$${Math.max(
                    (orderDetails.packagPriceUSD || 0) +
                      parseFloat(totalCostUSD.toString()) +
                      parseFloat(warehouseTotalUSD) +
                      Number(
                        shippingOptions[selectedShippingOption]?.USD || 0
                      ) -
                      usdDiscount -
                      usdPointsToApply,
                    0
                  ).toFixed(2)}`}
                </div>
              </MRow>
            </div>

            {isMobile ? (
              <div className="line">
                <div className="sub-header">- Payment Method</div>
                <MobilePaymentSection>
                  <div>
                    <img src="/resources/img/PayPal.png" alt="Paypal" />
                  </div>
                  <div>
                    <img src="/resources/img/VLSA.png" alt="Credit Card" />
                  </div>
                  <div>
                    <img
                      src="/resources/img/express.png"
                      className="express-image"
                      alt="EXPRESS"
                    />
                  </div>
                </MobilePaymentSection>
              </div>
            ) : (
              <PayCol>
                <h5>Payment Method</h5>
                <PaymentMethodSection>
                  <div className="payment-card">
                    <img
                      className="paypal"
                      src="/resources/img/PayPal.png"
                      alt="Paypal"
                    />
                  </div>
                  <div className="payment-card">
                    <img
                      className="visa"
                      src="/resources/img/VLSA.png"
                      alt="Credit Card"
                    />
                  </div>
                  <div className="payment-card">
                    <img
                      src="/resources/img/express.png"
                      className="amex"
                      alt="EXPRESS"
                    />
                  </div>
                </PaymentMethodSection>
              </PayCol>
            )}

            <div style={{ marginBottom: "1rem" }}>
              <div className="sub-header">
                - Cancellation and refund regulations
              </div>
              <div className="f-row">
                <Form.Check
                  type="checkbox"
                  name="agree"
                  checked={agreeCheck === true}
                  onChange={() => setAgreeCheck(!agreeCheck)}
                  style={{ marginRight: ".4rem" }}
                />
                <div style={{ marginRight: ".4rem" }}>I agree to the</div>
                <a
                  target="_blank"
                  rel="noreferrer noopener"
                  href="https://docs.google.com/document/d/1SuZi6RC7hWQG9aXqoRLTO2HbZuH0wJ6uondJEFs5Z6s/edit"
                >
                  <div className="text-link">terms and conditions.</div>
                </a>
              </div>
            </div>
          </MBody>

          <MFooter style={{ padding: "1rem" }}>
            <MTwoButtonRow>
              <MButton
                onClick={() => setShowUSDModal(false)}
                style={{
                  backgroundColor: "var(--color-gray)",
                  color: "var(--color-white)",
                  margin: "0.6rem 0",
                }}
              >
                Close
              </MButton>

              <MButton
                onClick={handleUSDPaymentSubmit}
                style={{
                  backgroundColor: "var(--color-main-blue)",
                  color: "var(--color-white)",
                  margin: "0.6rem 0",
                }}
              >
                Submit Payment
              </MButton>
            </MTwoButtonRow>
          </MFooter>
        </MWrapper>
      </Modal>

      <Modal show={showImageModal} onHide={closeImageModal} size="xl" centered>
        <Modal.Header closeButton>
          <Modal.Title>Image Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img
            src={imageToShow}
            alt="Enlarged"
            style={{ width: "100%", maxHeight: "80vh", objectFit: "contain" }}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

//! Coupon Start
const SpaceRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .order-total {
    color: var(--color-main-blue);
    font-weight: bold;
  }
  & label {
  }
`;
const DiscountPriceBlock = styled.div`
  border: 1px solid lightgray;
  border-radius: 1rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
`;
const DiscountPriceRow = styled.div`
  display: flex;
  flex-direction: row;
  & > label {
    flex: 1 1 0;
    text-align: center;
    margin: 0.4rem 0;
    align-items: center;
    display: flex;
    justify-content: center;

    &:not(:last-child) {
      border-right: 1px solid lightgray;
    }
  }
  .after-price {
    color: var(--color-main-blue);
  }
`;

const CouponViewButtonRow = styled.div`
  display: flex;
  background-color: var(--color-main-blue);
  border-radius: 0.4rem;
  padding: 0.4rem;
  margin: 1rem 0;
  color: var(--color-white);
  text-align: center;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  &:hover {
    background-color: var(--color-main-blue-hover);
  }
`;
const CouponListWrapper = styled.div`
  padding: 1rem;
`;

const FeeBoxRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  & > div {
    flex: 1 0 0;
    text-align: center;
    align-items: center;
    border: 1px solid lightgray;
    padding: 0.4rem 0;
  }
  margin: 0.6rem 0;
`;
const TotalFeeBoxRow = styled(FeeBoxRow)`
  background-color: #474747;
  color: white;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.6rem 0;
  margin: 0.4rem 0;
`;

const Label = styled.label`
  font-weight: bold;
  margin: 0.4rem 0 0.4rem 0;
`;
const P = styled.p`
  margin: 0 0 0.4rem 0;
`;
const StyledModal = styled(Modal)`
  .modal-dialog {
    max-width: 93%;
  }
`;
//! Coupon End

const StyledDiv = styled.div<{ checked: boolean }>`
  background-color: ${(props) => (props.checked ? "#f0f0f0" : "transparent")};
  transition: background-color 0.3s ease;
`;

const FooterButtonRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  & > button {
    flex-grow: 1;
  }
`;

const EstimateTableSection = styled.div`
  .shade {
    background-color: var(--color-lightgray);
  }
`;

const TotalFeeRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  border: 1px solid lightgray;
  padding: 0.4rem;
  margin: 1rem 0 2rem 0;
  & > div {
    flex-grow: 1;
    text-align: center;

    &:not(:last-child) {
      border-right: 1px solid lightgray;
    }
  }

  & .apply-coupon {
    color: var(--color-main-blue);
    font-weight: bold;
  }
`;
const PayCol = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border-bottom: 1px solid lightgray;
  gap: 0.1rem;
  .title {
    font-weight: bold;
    font-size: 1.1rem;
  }
  & label {
    margin-bottom: 0;
  }
  & p {
    margin-bottom: 0;
  }
`;
