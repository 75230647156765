import { useEffect, useState } from "react";
import { Button, Collapse, Form, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import {
  CODE_COUNTRY_ITEMS,
  CODE_SHIPPING_ORDER_STATUS,
} from "../../common/constants";
import { RootState } from "../../redux/store";
import { reqSendAlarm } from "../../requests/alarm";
import {
  reqShip,
  reqShipOrderUpdate,
  reqShipProductBox,
  reqShipUpdateOrder,
} from "../../requests/ship";
import { reqShipFEE } from "../../requests/ship";
interface RequestModalProps {
  show: boolean;
  onHide: () => void;
  orderNumber: string;
  refreshData: () => void;
}

interface ProductDetail {
  id: number;
  productId: number;
  category: number;
  name: string;
  quantity: number;
  price: number;
  trackingNumber: string;
}

interface Product {
  tracking: string;
  warehouseId: string;
  isUrgent: boolean;
  bag: boolean;
  warehouse: string;
  overstoreFee: number;
  Extra: number;
  name: string;
  price: number;
  details: ProductDetail[];
}

interface OrderDetails {
  products: Product[];
  optionBox: number;
  userId: string;
  membershipId: number;
  optionLetter: boolean;
  optionPoster: boolean;
  optionAircap: boolean;
  optionBag: boolean;
  optionSticker: boolean;
  adrReceiver: string;
  adrEmail: string;
  adrPhone: string;
  adrCountry: number;
  adrPCCC: string;
  adrZip: string;
  adrState: string;
  adrCity: string;
  adrOption1: string;
  adrOption2: string;
  adrOption3: string;
}

interface OrderDetailsForPrint {
  orderNumber: string;
  spaceCode: string;
  manager: string;
  adrReceiver: string;
  adrCountry: number;
  requestDate: string;
  latestWarehouseId: string;
  products: Product[];
  optionBox: number;
}

export default function RequestModal({
  show,
  onHide,
  orderNumber,
  refreshData,
}: RequestModalProps) {
  const [isOpen, setIsOpen] = useState(true);
  const admin = useSelector((state: RootState) => state.admin);
  const [exchangeRate, setExchangeRate] = useState<number>(0);
  const [orderDetails, setOrderDetails] = useState<OrderDetails>({
    products: [],
    optionBox: 0,
    userId: "",
    membershipId: 0,
    optionLetter: false,
    optionPoster: false,
    optionAircap: false,
    optionBag: false,
    optionSticker: false,
    adrReceiver: "",
    adrEmail: "",
    adrPhone: "",
    adrCountry: 0,
    adrPCCC: "",
    adrZip: "",
    adrState: "",
    adrCity: "",
    adrOption1: "",
    adrOption2: "",
    adrOption3: "",
  });
  const handleInputChange = (field: keyof OrderDetails, value: any) => {
    setOrderDetails((prev) => ({ ...prev, [field]: value }));
  };

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const response = await reqShip({ id: orderNumber });
        setOrderDetails(response.data);
      } catch (error) {
        console.error("Error fetching order details: ", error);
      }
    };

    if (orderNumber) {
      fetchOrderDetails();
    }
  }, [orderNumber]);

  // const fetchExchangeRate = () => {
  //   fetch(
  //     "https://quotation-api-cdn.dunamu.com/v1/forex/recent?codes=FRX.KRWUSD"
  //   )
  //     .then((response) => response.json())
  //     .then((data) => {
  //       const rate = data[0].basePrice;
  //       setExchangeRate(rate);
  //     })
  //     .catch((error) => console.error("Error fetching exchange rate:", error));
  // };

  useEffect(() => {
    // fetchExchangeRate();
    fetchFixedExchangeRate();
  });

  const [fixedExchangeRate, setFixedExchangeRate] = useState(0);
  const fetchFixedExchangeRate = async () => {
    try {
      const response = await reqShipFEE();
      if (response && response.data && response.data[0]?.fixedExchangeRateShop) {
        setFixedExchangeRate(parseFloat(response.data[0].fixedExchangeRateShop));
      }
    } catch (error) {
      console.error("Error fetching fixed exchange rate:", error);
    }
  };

  const convertToKRW = (usd: number) => {
    return Math.floor(usd * fixedExchangeRate).toLocaleString();
  };

  const convertToKRWOVER = (usd: number) => {
    return Math.floor(usd * 1000).toLocaleString();
  };

  // 상품 정렬과 색상 표시를 포함한 주문 상세 인쇄 기능
  const printOrderDetails = (orderDetails: OrderDetailsForPrint) => {
    const printWindow = window.open("", "_blank");
    if (printWindow) {
      printWindow.document.write(`
        <html>
        <head>
          <title>Print Order</title>
          <style>
            body { font-family: Arial, sans-serif; margin: 20px; }
            table { width: 100%; border-collapse: collapse; margin-top: 20px; }
            th, td { border: 1px solid black; padding: 8px; text-align: left; }
            th { background-color: #f2f2f2; }
            .header { font-size: 24px; font-weight: bold; margin-bottom: 10px; }
            .info-section { display: flex; flex-wrap: wrap; margin-bottom: 20px; }
            .info-item { width: 50%; padding: 5px 0; } /* Each item takes up half the width of the info-section */
            .urgent { background-color: #FFCCCC; } /* Urgent priority */
            .vacuum { background-color: #CCCCFF; } /* Vacuum bag priority */
          </style>
        </head>
        <body>
        <div class="header">배송 요청서</div>
        <div class="info-section">
          <div class="info-item"><strong>주문번호:</strong> ${orderDetails.orderNumber}</div>
          <div class="info-item"><strong>담당자:</strong> ${orderDetails.manager}</div>
          <div class="info-item"><strong>스페이스코드:</strong> ${orderDetails.spaceCode}</div>
          <div class="info-item"><strong>저장위치:</strong> ${orderDetails.latestWarehouseId}</div>
          <div class="info-item"><strong>고객 성명:</strong> ${orderDetails.adrReceiver}</div>
          <div class="info-item"><strong>배송 국가:</strong> ${CODE_COUNTRY_ITEMS[orderDetails.adrCountry]}</div>
          <div class="info-item"><strong>요청일:</strong> ${orderDetails.requestDate}</div>
          <div class="info-item"><strong>박스옵션:</strong> ${orderDetails.optionBox === 0 ? '1박스' : '여러박스'}</div>
        </div>
        <table>
          <thead>
            <tr>
              <th>No.</th>
              <th>Tracking No.</th>
              <th>Right 8</th>
              <th>Urgent</th>
              <th>Vacuum Bag</th>
            </tr>
          </thead>
          <tbody>
      `);

      orderDetails.products.forEach((product, index) => {
        let rowClass = product.isUrgent ? "urgent" : product.bag ? "vacuum" : "";
        const lastEightChars = product.tracking.slice(-8);
        const formattedTracking = `${lastEightChars.slice(0, 4)}-${lastEightChars.slice(4, 8)}`;
        printWindow.document.write(`
          <tr class="${rowClass}">
            <td>${index + 1}</td>
            <td>${product.tracking}</td>
            <td>${formattedTracking}</td>
            <td>${product.isUrgent ? "Yes" : "No"}</td>
            <td>${product.bag ? "Yes" : "No"}</td>
          </tr>
        `);
      });

      printWindow.document.write(`
          </tbody>
        </table>
      </body>
      </html>
    `);

      printWindow.document.close();
      printWindow.focus();
      printWindow.onload = function () {
        setTimeout(function () {
          printWindow.print();
          printWindow.close();
        }, 500);
      };
    } else {
      Swal.fire("Failed to open the print window.");
    }
  };

  const prepareAndPrintOrderDetails = () => {
    const sortedProducts = orderDetails.products.sort((a, b) => {
      if (a.isUrgent && !b.isUrgent) return -1;
      if (!a.isUrgent && b.isUrgent) return 1;
      if (a.bag && !b.bag) return -1;
      if (!a.bag && b.bag) return 1;
      if (a.warehouse && !b.warehouse) return -1;
      if (!a.warehouse && b.warehouse) return 1;
      return (
        parseInt(a.tracking.slice(-1), 10) - parseInt(b.tracking.slice(-1), 10)
      );
    });

    const latestWarehouseId =
      orderDetails.products[orderDetails.products.length - 1]?.warehouseId ??
      "N/A";
    const detailsForPrint = {
      orderNumber: orderNumber,
      spaceCode: orderDetails.userId,
      manager: admin.name,
      requestDate: new Date().toLocaleDateString(),
      latestWarehouseId: latestWarehouseId,
      products: sortedProducts,
      adrReceiver: orderDetails.adrReceiver,
      adrCountry: orderDetails.adrCountry,
      optionBox: orderDetails.optionBox,
    };

    printOrderDetails(detailsForPrint);
  };

  const handleReqestFormPrint = async () => {
    Swal.fire({
      title: "요청서를 출력하시겠습니까?",
      text: "사용자에게 알림을 발송합니다.",
      icon: "question",

      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "승인",
      cancelButtonText: "취소",

      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await reqShipUpdateOrder({
          id: orderNumber,
          status: CODE_SHIPPING_ORDER_STATUS.WAIT_PAY,
        });
        await reqShipOrderUpdate({
          id: orderNumber,
          manager: admin.name,
        });

        const alarmPayload = {
          userId: orderDetails.userId,
          read: 0,
          content: `${orderNumber} packing has begun. This request can no longer be canceled`,
          sender: admin.name,
        };
        await reqSendAlarm(alarmPayload);

        onHide();
        refreshData();
        prepareAndPrintOrderDetails();
      }
    });
  };

  const handleCancelOrder = async () => {
    Swal.fire({
      title: '배송 취소',
      text: '배송주문을 정말 취소하시나요?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'OK',
      cancelButtonText: 'No'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          for (const product of orderDetails.products) {
            await reqShipProductBox({
              tracking: product.tracking,
              status: 2,
            });
          }

          await reqShipUpdateOrder({
            id: orderNumber,
            status: CODE_SHIPPING_ORDER_STATUS.CANCELED,
          });

          Swal.fire({
            icon: 'success',
            title: '주문 취소',
            text: '주문이 취소되었습니다.',
            confirmButtonColor: '#3085d6',
          });

          onHide();
          refreshData();
        } catch (error) {
          console.error('Failed to cancel the order:', error);
          Swal.fire({
            icon: 'error',
            title: '주문 취소 실패',
            text: '배송주문 취소를 실패했습니다..',
            confirmButtonColor: '#d33',
          });
        }
      } else {
        Swal.fire({
          title: '주문 취소 실패',
          text: '주문 취소가 중단되었습니다.',
          icon: 'info',
          confirmButtonColor: '#3085d6'
        });
      }
    });
  };

  return (
    <Modal show={show} onHide={onHide} size="xl" centered>
      <Modal.Header closeButton style={{ backgroundColor: "#343a40" }}>
        <Modal.Title style={{ color: "#ffffff" }}>{orderNumber}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-4">
          <Button
            onClick={() => setIsOpen(!isOpen)}
            aria-controls="request-info-collapse"
            aria-expanded={isOpen}
            variant="secondary"
          >
            요청정보
          </Button>
        </div>
        <Collapse in={isOpen}>
          <div id="request-info-collapse">
            <table className="table text-center table-hover">
              <thead>
                <tr>
                  <th scope="col">Tracking No.</th>
                  <th scope="col">Name</th>
                  <th scope="col">Warehouse</th>
                  <th scope="col">Unit Price</th>
                  <th scope="col">Urgent</th>
                  <th scope="col">Vacuum Bag</th>
                </tr>
              </thead>
              <tbody>
                {orderDetails.products.map((product, pIndex) =>
                  product.details.length > 0 ? (
                    product.details.map((detail, dIndex) => (
                      <tr key={`detail-${pIndex}-${dIndex}`}>
                        {dIndex === 0 && (
                          <td rowSpan={product.details.length}>
                            {product.tracking}
                          </td>
                        )}
                        <td>{detail.name}</td>
                        <td>
                          ${product.overstoreFee} / ₩
                          {convertToKRWOVER(product.overstoreFee)}
                        </td>
                        <td>
                          ${detail.price} / ₩{convertToKRW(detail.price)}
                        </td>
                        {dIndex === 0 && (
                          <>
                            <td rowSpan={product.details.length}>
                              <Form.Check
                                type="checkbox"
                                checked={product.isUrgent}
                                disabled
                              />
                            </td>
                            <td rowSpan={product.details.length}>
                              <Form.Check
                                type="checkbox"
                                checked={product.bag}
                                disabled
                              />
                            </td>
                          </>
                        )}
                      </tr>
                    ))
                  ) : (
                    <tr key={`product-${pIndex}`}>
                      <td>{product.tracking}</td>
                      <td>{product.name || "N/A"}</td>
                      <td>
                        ${product.overstoreFee} / ₩
                        {convertToKRWOVER(product.overstoreFee)}
                      </td>
                      <td>
                        ${product.price} / ₩{convertToKRW(product.price)}
                      </td>
                      <td>
                        <Form.Check
                          type="checkbox"
                          checked={product.isUrgent}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          type="checkbox"
                          checked={product.bag}
                          disabled
                        />
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
        </Collapse>
        {/* 체크박스 옵션들 */}
        <div className="row">
          <div className="col-md-6 mb-3">
            <div
              style={{
                background: "#f8f9fa",
                padding: "10px",
                borderRadius: "5px",
              }}
            >
              <h5 className="mb-3" style={{ fontWeight: "bold" }}>
                옵션
              </h5>
            </div>
            <Form.Check
              label="편지봉투 박스 포장"
              checked={orderDetails.optionLetter}
              disabled
            />
            <Form.Check
              label="포스터튜브 최소화"
              checked={orderDetails.optionPoster}
              disabled
            />
            <Form.Check
              label="에어캡 추가"
              checked={orderDetails.optionAircap}
              disabled
            />
            <Form.Check
              label="깨지기쉬움 스티커 4면 부착"
              checked={orderDetails.optionSticker}
              disabled
            />
            <Form.Check
              label="보험가입"
              checked={orderDetails.optionBag}
              disabled
            />
            <Form.Check
              label="only 1박스"
              checked={orderDetails.optionBox === 0}
              disabled
            />
          </div>
          {/* 배송지 정보 입력 폼 */}
          <div className="col-md-6">
            <div
              style={{
                background: "#f8f9fa",
                padding: "15px",
                borderRadius: "5px",
              }}
            >
              <h5 className="mb-3" style={{ fontWeight: "bold" }}>
                배송정보
              </h5>
            </div>
            <div className="container">
              <Form>
                <div className="row">
                  <Form.Group className="col-md-4 mb-1">
                    <Form.Label>받는사람</Form.Label>
                    <Form.Control
                      type="text"
                      value={orderDetails.adrReceiver}
                      onChange={(e) =>
                        handleInputChange("adrReceiver", e.target.value)
                      }
                    />
                  </Form.Group>
                  <Form.Group className="col-md-4 mb-1">
                    <Form.Label>이메일</Form.Label>
                    <Form.Control
                      type="text"
                      value={orderDetails.adrEmail}
                      onChange={(e) =>
                        handleInputChange("adrEmail", e.target.value)
                      }
                    />
                  </Form.Group>
                  <Form.Group className="col-md-4 mb-1">
                    <Form.Label>폰번호</Form.Label>
                    <Form.Control
                      type="text"
                      value={orderDetails.adrPhone}
                      onChange={(e) =>
                        handleInputChange("adrPhone", e.target.value)
                      }
                    />
                  </Form.Group>
                </div>
                <div className="row">
                  <Form.Group className="col-md-4 mb-1">
                    <Form.Label>국가</Form.Label>
                    <Form.Control
                      type="text"
                      value={
                        CODE_COUNTRY_ITEMS[Number(orderDetails.adrCountry)] ||
                        "Country not selected"
                      }
                      onChange={(e) =>
                        handleInputChange("adrCountry", e.target.value)
                      }
                    />
                  </Form.Group>
                  <Form.Group className="col-md-4 mb-1">
                    <Form.Label>개인통관부호</Form.Label>
                    <Form.Control
                      type="text"
                      value={orderDetails.adrPCCC}
                      onChange={(e) =>
                        handleInputChange("adrPCCC", e.target.value)
                      }
                    />
                  </Form.Group>
                  <Form.Group className="col-md-4 mb-1">
                    <Form.Label>Zipcode</Form.Label>
                    <Form.Control
                      type="text"
                      value={orderDetails.adrZip}
                      onChange={(e) =>
                        handleInputChange("adrZip", e.target.value)
                      }
                    />
                  </Form.Group>
                </div>
                <div className="row">
                  <Form.Group className="col-md-4 mb-1">
                    <Form.Label>State</Form.Label>
                    <Form.Control
                      type="text"
                      value={orderDetails.adrState}
                      onChange={(e) =>
                        handleInputChange("adrState", e.target.value)
                      }
                    />
                  </Form.Group>
                  <Form.Group className="col-md-4 mb-1">
                    <Form.Label>City</Form.Label>
                    <Form.Control
                      type="text"
                      value={orderDetails.adrCity}
                      onChange={(e) =>
                        handleInputChange("adrCity", e.target.value)
                      }
                    />
                  </Form.Group>
                </div>
                <Form.Group className="mb-2">
                  <Form.Label>Address-1(35)</Form.Label>
                  <Form.Control
                    type="text"
                    value={orderDetails.adrOption1}
                    onChange={(e) =>
                      handleInputChange("adrOption1", e.target.value)
                    }
                  />
                </Form.Group>
                <Form.Group className="mb-2">
                  <Form.Label>Address-2(35)</Form.Label>
                  <Form.Control
                    type="text"
                    value={orderDetails.adrOption2}
                    onChange={(e) =>
                      handleInputChange("adrOption2", e.target.value)
                    }
                  />
                </Form.Group>
                <Form.Group className="mb-2">
                  <Form.Label>Address-3(35)</Form.Label>
                  <Form.Control
                    type="text"
                    value={orderDetails.adrOption3}
                    onChange={(e) =>
                      handleInputChange("adrOption3", e.target.value)
                    }
                  />
                </Form.Group>
              </Form>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={handleCancelOrder}>
          요청서 취소
        </Button>
        <Button onClick={async () => handleReqestFormPrint()}>
          요청서 출력
        </Button>
        <Button variant="secondary" onClick={onHide}>
          닫기
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
