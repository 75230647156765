import React from 'react';

interface IconProps {
  icon: string;
  color?: string;
  fontSize?: string;
  title?: string;
  style?: React.CSSProperties;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

const Icon: React.FC<IconProps> = ({ icon, color, fontSize, title, style, onClick }) => {
  return (
    <i
      style={{
        fontSize: fontSize || "inherit",
        color: color || "inherit",
        ...style
      }}
      title={title}
      onClick={onClick}
      className={`bi-${icon}`}
    />
  );
}

export default Icon;
