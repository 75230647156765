import { FormEvent, useEffect, useState } from "react";
import { Alert, Button, Form, Modal, Table } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Swal from "sweetalert2";
import { ADMIN_BADGE, USE_CONDITION } from "../../common/constants";
import {
  MBody,
  MButton,
  MFooter,
  MobilePaymentSection,
  MRow,
  MWrapper,
} from "../../common/mobile-styled";
import { modalClose } from "../../redux/modalSlice";
import { RootUserState } from "../../redux/store";
import { reqMyHaveCoupon } from "../../requests/coupon";
import { reqPaypalPayment } from "../../requests/paypal";
import { 체크컨텐츠_콜백_처리 } from "../../views/Customer/CPaypalResult";
import CouponList from "../CouponList";
import Icon from "../Icon";
import { PaymentMethodSection } from "./CShopOrderModal";

export default function ModalCheck() {
  const dispatch = useDispatch();
  const modal = useSelector((state: any) => state.modal);
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [discount, setDiscount] = useState<number>(5);

  const checkedItems = modal.payload.list || [];

  const [selectedItem, setSelectedItem] = useState<string>("");

  const handleSubmit = async (event: FormEvent) => {
    if (email === "") {
      alert("Please enter an email to receive the check content.");
      return;
    }
    if (!agreeCheck) {
      alert("Please agree to the cancellation and refund regulations.");
      return;
    }

    const checkedItemsIdList = checkedItems?.map((item: any) => item.id);
    const customObject = {
      pk: { checkedItemsIdList },
      domain: "CHECK_CONTENTS",
      reqShipProductBox: {},
      reqAddAdminBadge: {
        badgeName: ADMIN_BADGE.CHECK_CONTENT,
      },
    };
    const payloadList = checkedItems.reduce((acc: any[], cur: any) => {
      acc.push({
        tracking: cur.tracking,
        status: 4,
        check: true,
        checkEmail: email,
        checkPrice:
          selectedItem === cur.tracking ? Math.max(5 - discount, 0) : 5,
        checkPayment: parseFloat(orderTotal) === 0 ? 1 : 2,
        requestAt: new Date().toISOString(),
      });
      return acc;
    }, []);

    Object.assign(customObject, {
      reqShipProductBox: payloadList,
    });

    if (couponApplied) {
      Object.assign(customObject, {
        reqUseCoupon: {
          couponLogId: selectedCouponId,
          krwDiscounted: 0,
          usdDiscounted: discount,
        },
      });
    }

    // 쿠폰으로 인해 0원인 경우 Paypal 결제 없이 처리
    if (parseFloat(orderTotal) === 0) {
      await 체크컨텐츠_콜백_처리(JSON.stringify(customObject));
      Swal.fire({
        title: "Payment Success",
        text: "Thank you for using ontact korea!",
        icon: "success",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Back to previous page",
      }).then(async (result) => {
        if (result.isConfirmed) {
          dispatch(modalClose());
          window.location.reload();
        }
      });
      return;
    }

    const res = await reqPaypalPayment({
      storeid: "ontactkorea",
      currency: "USD",
      storeorderno: `checkcontent-${new Date().getTime().toString()}`,
      amount: parseFloat(orderTotal),
      payerid: `ontactKorea-${user.id}`,
      payeremail: "ontactkorea@gmail.com",
      returnurl: `${process.env.REACT_APP_BASE_URL}paypal/handle/result`,
      notiurl: `${process.env.REACT_APP_BASE_URL}paypal/handle/notify`,
      // 콜백 후처리 객체
      custom: JSON.stringify(customObject),
      directreturnflag: "Y",
    });
    dispatch(modalClose());

    if (!res) {
      alert("Paypal API Error");
      return;
    }
    const { online_url, mobile_url } = res.data;
    window.location.replace(isMobile ? mobile_url : online_url);
    return;
  };

  //! 쿠폰
  const user = useSelector((state: RootUserState) => state.user);
  const [availableCouponList, setAvailableCouponList] = useState<any>();
  const [krwDiscount, setKrwDiscount] = useState<number>(0);
  const [usdDiscount, setUsdDiscount] = useState<number>(0);
  const [couponApplied, setCouponApplied] = useState<boolean>(false);
  const [couponModal, setCouponModal] = useState<boolean>(false);

  const [selectedCouponId, setSelectedCouponId] = useState<number>();

  const Toast = Swal.mixin({
    toast: true,
    position: "center",
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const 내가_가진_체크컨텐츠_쿠폰_가져오기 = async () => {
    const res = await reqMyHaveCoupon({ userId: user.id });
    const myCouponDataList = res.data;
    const thisAvailableCouponList = myCouponDataList.filter(
      (couponData: any) =>
        couponData.coupon.useCondition === USE_CONDITION["CHECK_CONTENTS"]
    );

    setAvailableCouponList(thisAvailableCouponList);

    if (thisAvailableCouponList.length > 0) {
      Toast.fire({
        icon: "success",
        title: "There are coupons you can use!",
      });
    }
  };

  useEffect(() => {
    if (couponApplied) {
      setCouponModal(false);
      setDiscount(usdDiscount);
      setTotalSaving(convertToUsd(usdDiscount));
      const newOrderTotal = convertToUsd(Number(total) - usdDiscount);
      setOrderTotal(newOrderTotal);
      setSelectedItem(modal?.payload?.list[0].tracking);
      // 쿠폰 실제 적용
    } else {
      setKrwDiscount(0);
      setUsdDiscount(0);
    }
  }, [couponApplied]);

  useEffect(() => {
    내가_가진_체크컨텐츠_쿠폰_가져오기();
  }, []);

  //! Payment
  const [total, setTotal] = useState<string>();
  const [totalSaving, setTotalSaving] = useState<string>("0.00");
  const [orderTotal, setOrderTotal] = useState<string>("");
  const [paymentMethod, setPaymentMethod] = useState<string>("");
  const [agreeCheck, setAgreeCheck] = useState<boolean>(false);

  const getPaymentCardStyle = (method: string) => {
    return method === paymentMethod ? "payment-card selected" : "payment-card";
  };

  useEffect(() => {
    const totalUsd = convertToUsd(modal?.payload?.list.length * 5);
    setTotal(totalUsd);
    setOrderTotal(totalUsd);
  }, []);

  const convertToUsd = (num: number) => {
    return parseFloat(num.toString()).toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const [checkMobileModal, setCheckMobileModal] = useState<boolean>(false);

  return (
    <>
      {/* 사용 가능한 쿠폰 리스트 모달 */}
      <Modal
        onEnter={() => setCouponApplied(false)}
        show={couponModal}
        onHide={() => {
          setCouponModal(false);
          setCouponApplied(false);
        }}
        centered
        size="lg"
        style={{ zIndex: "99999" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>My Available Coupons</Modal.Title>
        </Modal.Header>

        <CouponListWrapper>
          <Label>Please select a coupon to apply.</Label>
          <P> Duplicate use is not possible.</P>

          <CouponList
            krwInput={0}
            usdInput={5}
            couponList={availableCouponList}
            setKrwDiscount={setKrwDiscount}
            setUsdDiscount={setUsdDiscount}
            couponApplied={setCouponApplied}
            setSelectedCouponId={setSelectedCouponId}
          />
        </CouponListWrapper>
      </Modal>

      {isMobile ? (
        <MWrapper
          style={{
            borderTop: "1px solid lightgray",
          }}
        >
          <MBody>
            {/* 정보 제공란 */}
            <div>
              <div className="sub-header">- Information</div>
              <div>
                {modal?.payload?.list?.map((v: any, idx: number) => (
                  <div className="line" style={{ padding: ".6rem 0" }}>
                    <MRow>
                      <div className="label">NO</div>
                      <div className="data">{idx + 1}</div>
                    </MRow>

                    <MRow>
                      <div className="label">Tracking Number</div>
                      <div className="data">{v.tracking}</div>
                    </MRow>

                    <MRow>
                      <div className="label">Price</div>
                      {v.tracking === selectedItem ? (
                        <div
                          className={
                            couponApplied
                              ? "data price apply-coupon"
                              : " price data"
                          }
                        >{`$${Math.max(5 - usdDiscount, 0).toFixed(2)}`}</div>
                      ) : (
                        <div className="data price">5$</div>
                      )}
                    </MRow>
                  </div>
                ))}
              </div>

              <div
                style={{
                  marginTop: ".6rem",
                  paddingBottom: "1rem",
                  borderBottom: "1px solid lightgray",
                }}
              >
                <div className="label">Email To Receive *</div>
                <div className="data" style={{ marginTop: ".5rem" }}>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
              </div>
            </div>

            {/* 가격 안내란 */}
            <div className="line">
              <div className="sub-header">- Price</div>
              <MRow>
                <div className="label">Total</div>
                <div className="data price">{total}$</div>
              </MRow>

              <MRow>
                <div className="label">Total discount</div>
                <div
                  className={
                    couponApplied ? "data price apply-coupon" : "price data"
                  }
                >
                  {totalSaving}$
                </div>
              </MRow>

              <MRow>
                <div className="label">Order Total</div>
                <div
                  className={
                    couponApplied ? "data price apply-coupon" : "price data"
                  }
                >
                  {orderTotal}$
                </div>
              </MRow>

              {/* 사용 가능 쿠폰 리스트 모달 버튼 */}
              {availableCouponList && availableCouponList.length > 0 && (
                <MButton
                  style={{
                    backgroundColor: "var(--color-main-blue)",
                    color: "var(--color-white)",
                    margin: "1rem 0",
                  }}
                  onClick={() => setCouponModal(true)}
                >
                  See my coupons
                  <Icon
                    icon="caret-right-fill"
                    color="var(--color-white)"
                    fontSize="1.2rem"
                  />
                </MButton>
              )}
            </div>

            {/* 결제 안내란 */}
            <div className="line">
              <div className="sub-header">- Payment Method</div>
              <MobilePaymentSection>
                <div>
                  <img src="/resources/img/PayPal.png" alt="Paypal" />
                </div>
                <div>
                  <img src="/resources/img/VLSA.png" alt="Credit Card" />
                </div>
                <div>
                  <img
                    src="/resources/img/express.png"
                    className="express-image"
                    alt="EXPRESS"
                  />
                </div>
              </MobilePaymentSection>
            </div>

            {/* 약관 고지 */}
            <div className="line">
              <div className="sub-header">
                - Cancellation and Refund Regulations
              </div>
              <div>
                <div className="data">
                  <div className="f-row">
                    <Form.Check
                      type="checkbox"
                      name="agree"
                      checked={agreeCheck === true}
                      onChange={() => setAgreeCheck(!agreeCheck)}
                      style={{ marginRight: ".4rem" }}
                    />
                    <div style={{ marginRight: ".4rem" }}>I agree to the</div>
                    <a
                      target="_blank"
                      rel="noreferrer noopener"
                      href="https://docs.google.com/document/d/1SuZi6RC7hWQG9aXqoRLTO2HbZuH0wJ6uondJEFs5Z6s/edit"
                    >
                      <div className="text-link">terms and conditions.</div>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            {error && (
              <Alert variant="danger" style={{ marginTop: "1rem" }}>
                {error}
              </Alert>
            )}
          </MBody>

          {/* 제출 버튼 */}
          <MFooter>
            <MButton
              style={{
                backgroundColor: "var(--color-main-blue)",
                color: "var(--color-white)",
                margin: "0.6rem 0",
              }}
              onClick={handleSubmit}
            >
              {`Check Out ${orderTotal}$`}
            </MButton>
          </MFooter>
        </MWrapper>
      ) : (
        <Form>
          <Table bordered hover responsive className="text-center">
            <thead>
              <tr>
                <th>NO</th>
                <th>Tracking number</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              {modal?.payload?.list?.map((v: any, idx: number) => (
                <PriceRow key={idx}>
                  <td>{idx + 1}</td>
                  <td>{v.tracking}</td>
                  <td>5.00 USD</td>
                </PriceRow>
              ))}
            </tbody>
          </Table>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </Form.Group>

          {error && <Alert variant="danger">{error}</Alert>}

          {/* 사용 가능 쿠폰 리스트 모달 버튼 */}
          {availableCouponList && availableCouponList.length > 0 && (
            <CouponViewButtonRow onClick={() => setCouponModal(true)}>
              See my coupons
              <Icon
                icon="caret-right-fill"
                color="var(--color-white)"
                fontSize="1.4rem"
              />
            </CouponViewButtonRow>
          )}

          <PayCol
            style={{ borderTop: "1px solid lightgray", marginTop: "1rem" }}
          >
            <SpaceRow>
              <label className="label">Total</label>
              <p>{total} USD</p>
            </SpaceRow>

            <SpaceRow>
              <label className="label">Total discount</label>
              <p>{totalSaving} USD</p>
            </SpaceRow>
          </PayCol>

          <PayCol>
            <SpaceRow>
              <label className="label">Order Total</label>
              <p className="order-total">{orderTotal} USD</p>
            </SpaceRow>
          </PayCol>

          <PayCol>
            <h5>Payment Method</h5>
            <PaymentMethodSection>
              <div className="payment-card">
                <img
                  className="paypal"
                  src="/resources/img/PayPal.png"
                  alt="Paypal"
                />
              </div>
              <div className="payment-card">
                <img
                  className="visa"
                  src="/resources/img/VLSA.png"
                  alt="Credit Card"
                />
              </div>
              <div className="payment-card">
                <img
                  src="/resources/img/express.png"
                  className="amex"
                  alt="EXPRESS"
                />
              </div>
            </PaymentMethodSection>
          </PayCol>

          <PayCol>
            <h5>Cancellation and refund regulations</h5>
            <div className="f-row">
              <Form.Check
                type="checkbox"
                name="agree"
                checked={agreeCheck === true}
                onChange={() => setAgreeCheck(!agreeCheck)}
                style={{ marginRight: ".4rem" }}
              />
              <div style={{ marginRight: ".4rem" }}>I agree to the</div>
              <a
                target="_blank"
                rel="noreferrer noopener"
                href="https://docs.google.com/document/d/1SuZi6RC7hWQG9aXqoRLTO2HbZuH0wJ6uondJEFs5Z6s/edit"
              >
                <div className="text-link">terms and conditions.</div>
              </a>
            </div>
          </PayCol>

          <Row>
            <Button variant="primary" onClick={handleSubmit}>
              CHECK OUT {orderTotal} USD
            </Button>
          </Row>
        </Form>
      )}
    </>
  );
}

//! Payment Start
const PayCol = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border-bottom: 1px solid lightgray;
  gap: 0.4rem;
  .title {
    font-weight: bold;
    font-size: 1.2rem;
  }
  .label {
    font-weight: bold;
    margin-bottom: 0;
  }

  & p {
    margin-bottom: 0;
  }
`;
const SpaceRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .order-total {
    color: var(--color-main-blue);
    font-weight: bold;
  }
`;

//! Payment End

//! Coupon Start

const PriceRow = styled.tr`
  & .discounted {
    font-weight: bold;
    color: var(--color-main-blue);
  }
  & .apply-coupon {
    background-color: var(--color-main-blue-hover);
  }
`;

const DiscountPriceBlock = styled.div`
  border: 1px solid lightgray;
  border-radius: 1rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
`;
const DiscountPriceRow = styled.div`
  display: flex;
  flex-direction: row;
  & > label {
    flex: 1 1 0;
    text-align: center;
    margin: 0.4rem 0;
    align-items: center;
    display: flex;
    justify-content: center;

    &:not(:last-child) {
      border-right: 1px solid lightgray;
    }
  }
  .after-price {
    color: var(--color-main-blue);
  }
`;

const CouponViewButtonRow = styled.div`
  display: flex;
  background-color: var(--color-main-blue);
  border-radius: 0.4rem;
  padding: 0.4rem;
  margin: 1rem 0;
  color: var(--color-white);
  text-align: center;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  flex: 1 1 0;

  &:hover {
    background-color: var(--color-main-blue-hover);
  }
`;
const CouponListWrapper = styled.div`
  padding: 1rem;
`;

const Label = styled.label`
  font-weight: bold;
  margin: 0.4rem 0 0.4rem 0;
`;
const P = styled.p`
  margin: 0 0 0.4rem 0;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;

  & button {
    flex: 1 1 0;
    margin-top: 1rem;
  }
`;

//! Coupon End
