import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { reqAShip } from "../../../requests/ship";
import {
  reqGetAllUserById,
  reqUpdateUserById,
  reqUploadExcelFile,
} from "../../../requests/user";
import styled from "styled-components";
import AdminHeadTitle from "../../../components/AdminHeadTitle";
import Swal from "sweetalert2";

Modal.setAppElement("#root");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

interface User {
  id: string;
  name: string;
  latestWarehouseId: string;
}

interface Product {
  id: number;
  userId: string;
  status: number;
  warehouseId: string;
}

export default function AWPosition() {
  const [activeFloor, setActiveFloor] = useState("1F");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newUserId, setNewUserId] = useState("");
  const [selectedLocationId, setSelectedLocationId] = useState("");
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredUsers, setFilteredUsers] = useState<User[]>([]);
  const [excelFile, setExcelFile] = useState<File | null>(null);
  const floors = ["1F", "2F", "3F"];
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [fileName, setFileName] = useState("");
  const columns = Array.from({ length: 26 }, (_, i) => {
    const char = String.fromCharCode(i + 65);
    return char !== "L" && char !== "O" ? char : null;
  }).filter(Boolean);

  const rows = Array.from({ length: 15 }, (_, i) => i + 1);

  const fetchAllUsers = async () => {
    try {
      const response = await reqGetAllUserById();
      setUsers(response.data);
      setFilteredUsers(response.data);
    } catch (error) {
      console.error("전체 사용자 정보 조회 실패:", error);
    }
  };

  useEffect(() => {
    fetchAllUsers();
  }, []);

  useEffect(() => {
    if (searchTerm.trim() === "") {
      setFilteredUsers(users);
    } else {
      const lowercasedFilter = searchTerm.toLowerCase();
      const filteredData = users.filter((user) =>
        user.id.toLowerCase().includes(lowercasedFilter)
      );
      setFilteredUsers(filteredData);
    }
  }, [searchTerm, users]);

  const handleFloorChange = (floor: string) => {
    setActiveFloor(floor);
  };

  const getLocationButtonStyle = (locationId: string) => {
    const userAtLocation = filteredUsers.some(
      (user) => user.latestWarehouseId === locationId
    );
    return userAtLocation ? "btn-success" : "btn-secondary";
  };

  const handleLocationClick = (locationId: string) => {
    setSelectedLocationId(locationId);
    const assignedUsers = filteredUsers
      .filter((user) => user.latestWarehouseId === locationId)
      .map((user) => user.id);
    setSelectedUsers(assignedUsers);
    setIsModalOpen(true);
  };

  const handleSubmit = async () => {
    const updatedSelectedUsers = newUserId
      ? [...selectedUsers, newUserId]
      : selectedUsers;

    if (!selectedLocationId || updatedSelectedUsers.length === 0) {
      alert("창고 위치와 사용자를 선택해주세요.");
      return;
    }

    try {
      await Promise.all(
        updatedSelectedUsers.map((userId) =>
          reqUpdateUserById({
            id: userId,
            latestWarehouseId: selectedLocationId,
          })
        )
      );
      alert(`위치 ${selectedLocationId}에 사용자를 업데이트했습니다.`);
      setIsModalOpen(false);
      setNewUserId("");
      fetchAllUsers();
    } catch (error) {
      console.error("사용자 위치 업데이트 실패:", error);
      alert("사용자 위치 업데이트에 실패했습니다.");
    }
  };

  const handleReset = async () => {
    if (selectedUsers.length === 0) {
      alert("초기화할 사용자를 선택해주세요.");
      return;
    }

    try {
      await Promise.all(
        selectedUsers.map((userId) =>
          reqUpdateUserById({
            id: userId,
            latestWarehouseId: "창고위치없음",
          })
        )
      );
      Swal.fire(
        "초기화 완료",
        "선택된 사용자의 창고 위치가 초기화되었습니다.",
        "success"
      );
      setIsModalOpen(false);
      setSelectedUsers([]);
      fetchAllUsers();
    } catch (error) {
      console.error("창고 위치 초기화 실패:", error);
      Swal.fire("오류", "창고 위치 초기화에 실패했습니다.", "error");
    }
  };

  const handleKeyPress = (event: any) => {
    if (event.key === " ") {
      event.preventDefault();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedFile(file);
      setFileName(file.name);
    } else {
      setSelectedFile(null);
      setFileName("");
    }
  };

  const handleFileUpload = async () => {
    if (!selectedFile) {
      alert("파일이 없습니다.");
      return;
    }
    try {
      await reqUploadExcelFile(selectedFile);
      alert("파일이 업로드되었습니다.");
      setSelectedFile(null);
      setFileName("");
      window.location.reload();
    } catch (error) {
      console.error("파일 업로드 중 오류가 발생했습니다.", error);
      alert("존재하지 않는 스페이스 코드가 있습니다.");
    }
  };

  const handleUserClick = (userId: string) => {
    Swal.fire({
      title: "정말 초기화하시겠습니까?",
      text: "이 사용자의 창고 위치를 초기화하시겠습니까?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "네, 초기화합니다",
      cancelButtonText: "취소",
    }).then((result) => {
      if (result.isConfirmed) {
        reqUpdateUserById({
          id: userId,
          latestWarehouseId: "창고위치없음",
        })
          .then(() => {
            Swal.fire(
              "초기화 완료",
              "사용자의 창고 위치가 초기화되었습니다.",
              "success"
            );
            fetchAllUsers();
          })
          .catch((error) => {
            console.error("창고 위치 초기화 실패:", error);
            Swal.fire("오류", "창고 위치 초기화에 실패했습니다.", "error");
          });
      }
    });
  };

  return (
    <>
      <main id="main" className="main">
        <AdminHeadTitle
          subtitle1="창고 관리"
          subtitle2="저장 위치 관리"
          title="저장 위치 관리"
        />

        <div className="container-fluid px-2">
          <FloorButtonContainer>
            {floors.map((floor) => (
              <button
                key={floor}
                className={`btn ${
                  floor === activeFloor ? "btn-primary" : "btn-secondary"
                }`}
                onClick={() => handleFloorChange(floor)}
              >
                {floor}
              </button>
            ))}
          </FloorButtonContainer>
          <SearchBarContainer>
            <input
              type="text"
              onKeyPress={handleKeyPress}
              placeholder="스페이스 코드 검색"
              value={searchTerm.toUpperCase()}
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{ width: "100%", padding: "8px", marginBottom: "10px" }}
            />
          </SearchBarContainer>
          <div className="container-fluid px-2">
            <UploadContainer>
              <input
                type="file"
                accept=".xlsx"
                id="file-upload"
                onChange={handleFileChange}
              />
              <label htmlFor="file-upload" className="upload-label">
                엑셀 파일 선택
              </label>
              {fileName && <span>{fileName}</span>}
              <button className="btn btn-primary" onClick={handleFileUpload}>
                엑셀 파일 업로드
              </button>
            </UploadContainer>
          </div>
          {filteredUsers.length > 0 && (
            <UserList>
              {filteredUsers.map((user) => (
                <UserItem
                  key={user.id}
                  onClick={() => handleUserClick(user.id)}
                >
                  {user.id} - {user.latestWarehouseId}
                </UserItem>
              ))}
            </UserList>
          )}
          <div className="scrollable-table">
            <TableContainer>
              <StyledTable className="table text-center table-sm table-hover">
                <thead>
                  <tr className="bg-light text-dark">
                    <th>#</th>
                    {columns.map((column) => (
                      <th key={column}>{column}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {rows.map((row) => (
                    <tr key={row}>
                      <th scope="row">{row}</th>
                      {columns.map((column) => {
                        const locationId = `${activeFloor}-${column}${row}`;
                        return (
                          <td key={column}>
                            <button
                              type="button"
                              className={`btn position-relative btn-sm ${getLocationButtonStyle(
                                locationId
                              )}`}
                              onClick={() => handleLocationClick(locationId)}
                            >
                              {column}
                              {row}
                            </button>
                          </td>
                        );
                      })}
                    </tr>
                  ))}
                </tbody>
              </StyledTable>
            </TableContainer>
          </div>
        </div>
      </main>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        style={customStyles}
      >
        <h2>창고 위치 업데이트</h2>
        <select
          multiple
          value={selectedUsers}
          onChange={(e) => {
            const options = e.target.options;
            let value = [];
            for (let i = 0, l = options.length; i < l; i++) {
              if (options[i].selected) {
                value.push(options[i].value);
              }
            }
            setSelectedUsers(value);
          }}
          style={{ width: "100%", minHeight: "100px", marginBottom: "10px" }}
        >
          {users
            .filter((user) => selectedUsers.includes(user.id))
            .map((user) => (
              <option key={user.id} value={user.id}>
                {user.id} - {user.name}
              </option>
            ))}
        </select>
        <input
          type="text"
          placeholder="새 사용자 ID 추가"
          onKeyPress={handleKeyPress}
          value={newUserId.toUpperCase()}
          onChange={(e) => setNewUserId(e.target.value)}
          style={{ width: "100%", marginBottom: "10px" }}
        />
        <div>
          <StyledButtonContainer>
            <StyledButton onClick={handleSubmit}>업데이트</StyledButton>
            <StyledButton onClick={handleReset}>초기화</StyledButton>
            <StyledButton onClick={() => setIsModalOpen(false)}>
              취소
            </StyledButton>
          </StyledButtonContainer>
        </div>
      </Modal>
    </>
  );
}

const FloorButtonContainer = styled.div`
  margin-bottom: 2rem;
  button {
    margin-right: 0.5rem;
    &:last-child {
      margin-right: 0;
    }
  }
`;

const StyledButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-top: 10px;
`;

const StyledButton = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }

  &:focus {
    outline: none;
  }
`;

const SearchBarContainer = styled.div`
  margin-bottom: 1rem;
`;

const UserList = styled.div`
  margin-bottom: 1rem;
  max-height: 150px;
  overflow-y: auto;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 4px;
`;

const UserItem = styled.div`
  padding: 5px 0;
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
`;

const StyledTable = styled.table`
  width: 100%;
  th,
  td {
    text-align: center;
    padding: 4px;
  }
  th:first-child,
  td:first-child {
    padding: 4px 2px;
    width: 75px;
  }
  button {
    width: 50%;
    height: 50%;
    padding: 2px 8px;
    box-sizing: border-box;
  }
`;

const TableContainer = styled.div`
  overflow-x: auto;
`;

const UploadContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;

  input[type="file"] {
    display: none;
  }

  .upload-label {
    background-color: #007bff;
    color: white;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: #0056b3;
    }
  }
`;
