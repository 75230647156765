import { useState } from "react";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Table,
} from "react-bootstrap";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { DATA_TYPE } from "../../common/constants";
import { reqAdminUpdate } from "../../requests/admin-auth";
import FormatData from "../FormatData";

interface AdminData {
  id: number;
  email: string;
  name: string;
  phone: string;
  role: string;
  createdAt: string;
  updatedAt: string;
}

const defaultData: AdminData = {
  id: 0,
  email: "",
  name: "",
  phone: "",
  role: "0",
  createdAt: "",
  updatedAt: "",
};

export default function ModalAdminRegisterDetail() {
  const modal = useSelector((state: any) => state.modal);

  const [data, setData] = useState<any>(modal.payload.data);
  const [isEditing, setEditing] = useState<boolean>(false);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setData({ ...data, [name]: value });
  };

  const handleUpdate = async () => {
    try {
      await reqAdminUpdate(data);
      setEditing(false);
    } catch (error) {
      console.error("Update failed:", error);
    }
  };

  const handleRoleChange = (roleValue: string) => {
    setData({ ...data, role: roleValue });
  };

  const roleOptions: { [key: string]: string } = {
    "1": "최고관리자",
    "0": "일반관리자",
    "2": "비활성화",
    "3": "중간관리자",
  };

  return (
    <>
      <AdminRegisterDetail>
        <Table>
          <tbody>
            <tr>
              <th>No.</th>
              <td>{data.id}</td>
            </tr>
            <tr>
              <th>Email</th>
              <td>
                {isEditing ? (
                  <input
                    type="email"
                    value={data.email}
                    name="email"
                    onChange={handleInputChange}
                  />
                ) : (
                  data.email
                )}
              </td>
            </tr>
            <tr>
              <th>이름</th>
              <td>
                {isEditing ? (
                  <input
                    type="text"
                    value={data.name}
                    name="name"
                    onChange={handleInputChange}
                  />
                ) : (
                  data.name
                )}
              </td>
            </tr>
            <tr>
              <th>휴대전화</th>
              <td>
                {isEditing ? (
                  <input
                    type="text"
                    value={data.phone}
                    name="phone"
                    onChange={handleInputChange}
                  />
                ) : (
                  data.phone
                )}
              </td>
            </tr>
            <tr>
              <th>권한</th>
              <td>
                {isEditing ? (
                  <Dropdown>
                    <DropdownToggle variant="secondary" id="dropdown-basic">
                      {roleOptions[data.role]}
                    </DropdownToggle>
                    <DropdownMenu>
                      {Object.entries(roleOptions).map(([key, label]) => (
                        <DropdownItem
                          key={key}
                          onClick={() => handleRoleChange(key)}
                        >
                          {label}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </Dropdown>
                ) : (
                  roleOptions[data.role]
                )}
              </td>
            </tr>
          </tbody>
        </Table>
        <div className="data-history">
          <div>
            계정 생성일자
            <span>{FormatData(DATA_TYPE.DATE, data.createdAt)}</span>
          </div>
          <div>
            마지막 수정일자
            <span>{FormatData(DATA_TYPE.DATE, data.updatedAt)}</span>
          </div>
        </div>
        <div className="d-flex justify-content-end">
          <Button
            variant="dark"
            style={{ width: "180px" }}
            onClick={isEditing ? handleUpdate : () => setEditing(true)}
          >
            {isEditing ? "저장" : "수정"}
          </Button>
        </div>
      </AdminRegisterDetail>
    </>
  );
}

const AdminRegisterDetail = styled.div`
  tr {
    border-bottom: 1px solid #eee;
  }
  th,
  td {
    padding: 10px;
  }

  .data-history {
    color: #555;
    font-size: 14px;
    div {
      display: inline-block;
      margin-right: 30px;

      span {
        margin-left: 6px;
      }
    }
  }
`;
