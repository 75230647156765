import { createSlice } from "@reduxjs/toolkit";

const initialState: {
  codeCategory: any[];
  codeColor: any[];
  codeCountry: any[];
  refresh: boolean;
} = {
  codeCategory: [],
  codeColor: [],
  codeCountry: [],
  refresh: false,
};

export const codeSlice = createSlice({
  name: "code",
  initialState,
  reducers: {
    codeSave: (state, actions) => {
      const { codeCategory, codeCountry, codeColor } = actions.payload;
      state.codeCategory = codeCategory;
      state.codeCountry = codeCountry;
      state.codeColor = codeColor;

      return state;
    },
    codeRefresh: (state) => {
      state.refresh = !state.refresh;

      return state;
    },
  },
});

export const { codeSave, codeRefresh } = codeSlice.actions;
export default codeSlice.reducer;
