import { createSlice } from "@reduxjs/toolkit";

interface iToken {
  accessToken: string;
}

const initialState: iToken = {
  accessToken: "",
};

const tokenSlice = createSlice({
  initialState,
  name: "token",
  reducers: {
    tokenSave: (state, action) => {
      const { accessToken } = action.payload;
      state.accessToken = accessToken;
      return state;
    },
    tokenClear: (state) => {
      state.accessToken = "";
      return state;
    },
  },
});

export const { tokenSave, tokenClear } = tokenSlice.actions;
export default tokenSlice.reducer;
