import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface PopupImageState {
  imageUrl: string | null;
}

const initialState: PopupImageState = {
  imageUrl: null,
};

const popupImageSlice = createSlice({
  name: "popupImage",
  initialState,
  reducers: {
    setPopupImage: (state, action: PayloadAction<string>) => {
      state.imageUrl = action.payload;
    },
    clearPopupImage: (state) => {
      state.imageUrl = null;
    },
  },
});

export const { setPopupImage, clearPopupImage } = popupImageSlice.actions;
export default popupImageSlice.reducer;
