import React from "react";
import { isMobile } from "react-device-detect";
import CustomerPageTitle from "../../components/CustomerPageTitle";
import styled from "styled-components";
import { Tab, Tabs } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";

const CStorageGuide = () => {
  const qaList = [
    {
      question: `How do I find the tracking number ?`,
      answer: `Ontact Korea provides a tracking number for each shipped box. You can find it on 'My shipments', click on the order number and on the shipping tab. In case of several boxes, a tracking number will be available for each box. To ensure smooth custom clearance we usually ship 2 boxes per day, per customer.`,
    },
    {
      question: `I paid for shipping but my box still doesn't have a tracking #?`,
      answer: `According to the shipping carrier schedule it can take up to 2 business days to ship your boxes.`,
    },
    {
      question: `What happens when my package is returned to the carrier ?`,
      answer: `If delivery is impossible for any reason (wrong address, non-delivery, customs failure, etc.), it will be returned to the shipper's address or disposed of at the carrier's local facility according to the carrier's policy. We will contact you upon notification from the carrier. Please note, however, that we are not responsible for packages that are discarded or returned unless reasonable action is taken by the time specified by the local carrier or by the time specified by the local carrier. In the case of a return, we do not bear the shipping fee, the customer is charged below the cost, and Ontact Korea is not responsible for the cost related to the return shipping. Return shipping cost Customs duties and taxes (if any) imposed by local customs and import duties; & Re-delivery fee. If you need help with shipping, please contact us at ontactkorea@gmail.com and we will do our best to help.`,
    },
    {
      question: `What shipping options and carriers can I choose ?`,
      answer: `Ontact Korea recommends a suitable delivery method for each country based on its extensive delivery experience. The shipping carrier selection will be available at check out.`,
    },
    {
      question: `Do I have to pay customs duties and taxes ?`,
      answer: `Shipping does not include duties and taxes. The receiver is responsible for any customs duties and taxes imposed by the country you ship to.`,
    },
    {
      question: `Are there any items that have international shipping restrictions ?`,
      answer: `Customs restricted items vary by country, so you can contact your country's EMS, UPS and Fedex Items generally restricted by all countries. The general list includes Perishable products Fragile products, Frozen food, plants, Dangerous goods (chemicals, paints, <b>batteries</b>, perfumes, etc.) Under certain circumstances and shipping carrier's policy, some dangerous goods may be shipped in limited quantities.`,
    },
    {
      question: `What does "volume weight" mean ?`,
      answer: `Carriers consider the size and weight of the package when determining the shipping cost. The weight of a package is important, but sometimes the space it takes up on a truck or plane is even more important. Volumetric or dimensional weight is a method of measurement calculated using the dimensions of the package (length x width x height). Shipping costs are based on actual weight or dimensional weight, whichever is greater. The formula for calculating the dimensional weight of a package shipped internationally is: (Length x Width x Height) / Dimension factor (provided by carrier) = Dimension weight. <b>If the volume weight is greater than the actual weight, the shipping company will charge the shipping cost by volume weight.</b>`,
    },
    {
      question: `How long will it take for my package to arrive to me ?`,
      answer: `Availability of shipping options and delivery times vary depending on the size, weight, and destination country of the package.   

UPS/Fedex Prority: 3-5 business days
K-Packet: 7-10 business days
EMS: 5-7 business  days

We are not responsible for shipping delays caused by customs or carriers. However, if you encounter any problems, please contact our support team and we will do our best to get back to you.`,
    },
    {
      question: `Is delivery available to other Korean addresses ?`,
      answer: `Yes, however the Korean address should be fill up during the shipping request, you cannot switch address after you requested international shipping`,
    },
    {
      question: `How much is the shipping fee ?`,
      answer: `We cannot provide any quote before a box is repacked. However, you can check out the shipping calculator to get an estimated price for your country.`,
    },
    {
      question: `What is the repack fee ?`,
      answer: `The repack fee includes packing time, consolidation of the box and extra protection inside the box if needed. Repack fee is $1 per kg of your box. To learn more about shipping service pricing please visit the pricing page.`,
    },
    {
      question: `Where can I track my tracking number ?`,
      answer: `Track Fedex shipments : https://www.fedex.com/fedextrack/
Track UPS shipments : https://www.ups.com/track?loc=en_US&requester=ST/
Track EMS / KPACKET shipments : https://ems.epost.go.kr/front.Tracking01Eng.postal`,
    },
  ];

  return (
    <Wrapper style={{ padding: `${isMobile && "1.4rem"}` }}>
      <CustomerPageTitle title="Guide: Tutorial video shipping" />

      <GuideWrapper>
        <div className="f-col">
          <div
            className={`${isMobile ? "f-col line" : "f-row line"}`}
            style={{ paddingBottom: "2rem" }}
          >
            <div className={`${isMobile ? "line" : ""}`}>
              <Tabs
                defaultActiveKey="step1"
                id="uncontrolled-tab-example"
                className="mb-3"
              >
                {/* Step 1 */}
                <Tab eventKey="step1" title="Step 1">
                  <img
                    className={`${isMobile ? "mobile-guide-img" : "guide-img"}`}
                    src="/resources/img/guide-3/1.png"
                    alt="1"
                  />
                </Tab>

                {/* Step 2 */}
                <Tab eventKey="step2" title="Step 2">
                  <Carousel slide={false}>
                    <Carousel.Item>
                      <img
                        className={`${
                          isMobile ? "mobile-guide-img" : "guide-img"
                        }`}
                        src="/resources/img/guide-3/2.png"
                        alt="2-1"
                      />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className={`${
                          isMobile ? "mobile-guide-img" : "guide-img"
                        }`}
                        src="/resources/img/guide-3/3.png"
                        alt="2-2"
                      />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className={`${
                          isMobile ? "mobile-guide-img" : "guide-img"
                        }`}
                        src="/resources/img/guide-3/4.png"
                        alt="2-3-1"
                      />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className={`${
                          isMobile ? "mobile-guide-img" : "guide-img"
                        }`}
                        src="/resources/img/guide-3/5.png"
                        alt="2-3-2"
                      />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className={`${
                          isMobile ? "mobile-guide-img" : "guide-img"
                        }`}
                        src="/resources/img/guide-3/6.png"
                        alt="2-4"
                      />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className={`${
                          isMobile ? "mobile-guide-img" : "guide-img"
                        }`}
                        src="/resources/img/guide-3/7.png"
                        alt="2-5"
                      />
                    </Carousel.Item>
                  </Carousel>
                </Tab>

                {/* Step 3 */}
                <Tab eventKey="step3" title="Step 3">
                  <img
                    className={`${isMobile ? "mobile-guide-img" : "guide-img"}`}
                    src="/resources/img/guide-3/8.png"
                    alt="3"
                  />
                </Tab>

                {/* Step 4 */}
                <Tab eventKey="step4" title="Step 4">
                  <img
                    className={`${isMobile ? "mobile-guide-img" : "guide-img"}`}
                    src="/resources/img/guide-3/9.png"
                    alt="4"
                  />
                </Tab>

                {/* Step 5 */}
                <Tab eventKey="step5" title="Step 5">
                  <Carousel slide={false}>
                    <Carousel.Item>
                      <img
                        className={`${
                          isMobile ? "mobile-guide-img" : "guide-img"
                        }`}
                        src="/resources/img/guide-3/10.png"
                        alt="5-1"
                      />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className={`${
                          isMobile ? "mobile-guide-img" : "guide-img"
                        }`}
                        src="/resources/img/guide-3/11.png"
                        alt="5-2-1"
                      />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className={`${
                          isMobile ? "mobile-guide-img" : "guide-img"
                        }`}
                        src="/resources/img/guide-3/12.png"
                        alt="5-2-2"
                      />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className={`${
                          isMobile ? "mobile-guide-img" : "guide-img"
                        }`}
                        src="/resources/img/guide-3/13.png"
                        alt="5-3"
                      />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className={`${
                          isMobile ? "mobile-guide-img" : "guide-img"
                        }`}
                        src="/resources/img/guide-3/14.png"
                        alt="5-3"
                      />
                    </Carousel.Item>
                  </Carousel>
                </Tab>

                {/* Step 6 */}
                <Tab eventKey="step6" title="Step 6">
                  <img
                    className={`${isMobile ? "mobile-guide-img" : "guide-img"}`}
                    src="/resources/img/guide-3/15.png"
                    alt="6"
                  />
                </Tab>

                {/* Step 7 */}
                <Tab eventKey="step7" title="Step 7">
                  <img
                    className={`${isMobile ? "mobile-guide-img" : "guide-img"}`}
                    src="/resources/img/guide-3/16.png"
                    alt="7"
                  />
                </Tab>
              </Tabs>
            </div>

            {isMobile ? (
              <div>
                <div
                  style={{
                    fontSize: "1.4rem",
                    fontWeight: "bold",
                    margin: "1.6rem 0 1.4rem 0",
                    color: "var(--color-main-blue)",
                  }}
                >
                  Custom order status workflow
                </div>
                <img
                  src="/resources/img/guide-step3.png"
                  alt="ex"
                  style={{ marginLeft: ".6rem", width: "100%" }}
                />
              </div>
            ) : (
              <div style={{ marginLeft: "4rem" }}>
                <Tabs
                  defaultActiveKey="step1"
                  id="uncontrolled-tab-example"
                  className="mb-3"
                >
                  <Tab eventKey="step1" title="Custom order status workflow">
                    <img
                      src="/resources/img/guide-step3.png"
                      alt="ex"
                      style={{ marginLeft: ".6rem" }}
                    />
                  </Tab>
                </Tabs>
              </div>
            )}
          </div>

          <FaqWrapper>
            <div className="qa-title">Custom order service FAQ</div>

            {qaList.map((qa: any) => {
              return (
                <div className="qa-section">
                  <div className="question">{qa.question}</div>
                  <div
                    className="answer"
                    dangerouslySetInnerHTML={{ __html: `${qa.answer}` }}
                  ></div>
                </div>
              );
            })}
          </FaqWrapper>
        </div>
      </GuideWrapper>
    </Wrapper>
  );
};

const FaqWrapper = styled.div`
  .red-bold {
    color: var(--color-main-red);
    font-weight: bold;
  }
  .qa-title {
    color: var(--color-main-blue);
    font-weight: bold;
    font-size: 1.4rem;
    margin: 1.4rem 0 1rem 0;
  }
  .qa-section {
    margin-bottom: 1rem;
  }
  .question {
    font-weight: bold;
    font-size: 1.2rem;
  }
  .answer {
    margin: 0.4rem 0 0 0.6rem;
  }
`;

const GuideWrapper = styled.div`
  .guide-img {
    height: 893px;
  }
  .mobile-guide-img {
    width: 100%;
  }
`;

const Wrapper = styled.div`
  padding: 2rem;
  margin-bottom: 4rem;
`;

export default CStorageGuide;
