import { format, parseISO } from "date-fns";
import { useEffect, useState } from "react";
import { Button, Form, Modal, Pagination, Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import styled from "styled-components";
import Swal from "sweetalert2";
import { ADMIN_BADGE } from "../../../common/constants";
import AdminHeadTitle from "../../../components/AdminHeadTitle";
import { RootState } from "../../../redux/store";
import { reqSendAlarm } from "../../../requests/alarm";
import { reqReadAdminBadge } from "../../../requests/badge";
import {
  reqShipProductBox
} from "../../../requests/ship";
import {
  reqAllProductSplit,
  reqUpdateProductSplitByTracking
} from "../../../requests/split";
interface ShipProduct {
  warehouseId: string;
}

interface ProductSplit {
  id: string;
  tracking: string;
  Split: string;
  manager: string | null;
  userId: string;
  status: number;
  content: string;
  imageUrl: string | null;
  createdAt: string;
  shipProduct: ShipProduct | null;
}

const ASplit = () => {
  const [splits, setSplits] = useState<ProductSplit[]>([]);
  const [selectedSplit, setSelectedSplit] = useState<ProductSplit | null>(null);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [trackingFilter, setTrackingFilter] = useState("");
  const [spaceCodeFilter, setSpaceCodeFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState<"요청" | "완료" | "">(
    "요청"
  );

  const admin = useSelector((state: RootState) => state.admin);

  useEffect(() => {
    const fetchSplits = async () => {
      try {
        const response = await reqAllProductSplit({});
        setSplits(response.data);
      } catch (error) {
        console.error("Error fetching splits:", error);
      }
    };

    const badgePayload = {
      badgeName: ADMIN_BADGE.SPLIT,
    };
    reqReadAdminBadge(badgePayload);
    fetchSplits();
  }, []);

  const handleRowClick = (split: ProductSplit) => {
    setSelectedSplit(split);
    const modalElement = document.getElementById("splitModal");
    if (modalElement) {
      const bootstrapModal = new (window as any).bootstrap.Modal(modalElement);
      bootstrapModal.show();
    }
  };

  const handleImageClick = (imageUrl: string) => {
    setSelectedImage(imageUrl);
    const modalElement = document.getElementById("imageModal");
    if (modalElement) {
      const bootstrapModal = new (window as any).bootstrap.Modal(modalElement);
      bootstrapModal.show();
    }
  };

  const handlePrint = () => {
    if (!selectedSplit) return; // Ensure selectedSplit is not null

    const printContent = document.getElementById("splitModalContent");
    if (printContent) {
      const printWindow = window.open("", "", "width=800,height=600");
      if (printWindow) {
        printWindow.document.write(`
          <html>
            <head>
              <style>
                @page {
                  size: A4;
                  margin: 20mm;
                }
                body {
                  font-family: Arial, sans-serif;
                  margin: 0;
                  padding: 20mm;
                }
                table {
                  width: 100%;
                  border-collapse: collapse;
                }
                th, td {
                  border: 1px solid #000;
                  padding: 8px;
                  text-align: center;
                }
                th {
                  background-color: #f2f2f2;
                }
              </style>
            </head>
            <body>
              <div style="display: table; width: 100%; font-size: 0.9em;">
                <div style="display: table-row;">
                  <div style="display: table-cell; padding: 5px; font-weight: bold;">Tracking Number</div>
                  <div style="display: table-cell; padding: 5px;">${selectedSplit?.tracking}</div>
                </div>
                <div style="display: table-row;">
                  <div style="display: table-cell; padding: 5px; font-weight: bold;">User ID</div>
                  <div style="display: table-cell; padding: 5px;">${selectedSplit?.userId}</div>
                </div>
                <div style="display: table-row;">
                  <div style="display: table-cell; padding: 5px; font-weight: bold;">Warehouse Location</div>
                  <div style="display: table-cell; padding: 5px;">${selectedSplit?.shipProduct?.warehouseId}</div>
                </div>
              </div>
              ${printContent.innerHTML}
            </body>
          </html>
        `);
        printWindow.document.close();
        printWindow.print();
      }
    }
  };

  const handleDelete = async () => {
    if (!selectedSplit) return;

    Swal.fire({
      title: "제품을 완료로 설정하시겠습니까?",
      text: "이 작업은 되돌릴 수 없습니다!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "완료",
      cancelButtonText: "취소",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          // 선택된 제품 삭제
          const deletePayload = {
            tracking: selectedSplit.tracking,
            deletedAt: new Date().toISOString(),
          };
          const deleteResponse = await reqShipProductBox(deletePayload);
          console.log("Delete successful for selected product", deleteResponse);

          // 상태값 2와 어드민 아이디로 업데이트
          const adminUpdatePayload = {
            status: 2,
            manager: admin.name,
          };
          const adminUpdateResponse = await reqUpdateProductSplitByTracking(
            selectedSplit.tracking,
            adminUpdatePayload
          );
          const alarmPayload = {
            userId: selectedSplit.userId,
            read: 0,
            content:
              "The split has been completed, please check the new tracking number in my warehouse package and proceed with enter data, thank you.",
            sender: admin.name,
          };

          await reqSendAlarm(alarmPayload);
          console.log("Admin update successful", adminUpdateResponse);

          Swal.fire(
            "완료 설정됨!",
            "제품이 완료로 성공적으로 설정되었습니다.",
            "success"
          );
          setSelectedSplit(null);
          window.location.reload();
        } catch (error) {
          console.error("Error updating product:", error);
          Swal.fire(
            "업데이트 실패!",
            "업데이트가 가능한 상태가 아닙니다.",
            "error"
          );
        }
      }
    });
  };

  const filteredSplits = splits
    .filter((split) => {
      const trackingMatch = split.tracking.includes(trackingFilter);
      const spaceCodeMatch = split.userId.includes(spaceCodeFilter);
      const statusMatch =
        statusFilter === ""
          ? [1, 2].includes(split.status)
          : statusFilter === "요청"
          ? split.status === 1
          : split.status === 2;

      return trackingMatch && spaceCodeMatch && statusMatch;
    })
    .reduce((acc: ProductSplit[], current) => {
      const x = acc.find((item) => item.tracking === current.tracking);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);

  const totalPages = Math.ceil(filteredSplits.length / itemsPerPage);
  const currentRecords = filteredSplits.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  return (
    <>
      <main id="main" className="main">
        <AdminHeadTitle
          subtitle1="창고 관리"
          subtitle2="스플릿"
          title="스플릿"
        />
        <div className="container-fluid mb-4">
          <div className="row">
            <div className="col-md-4">
              <Form.Label htmlFor="statusFilter">상태</Form.Label>
              <Form.Control
                as="select"
                id="statusFilter"
                value={statusFilter}
                onChange={(e) =>
                  setStatusFilter(e.target.value as "요청" | "완료" | "")
                }
              >
                <option value="">모두</option>
                <option value="요청">요청</option>
                <option value="완료">완료</option>
              </Form.Control>
            </div>
            <div className="col-md-4">
              <Form.Label htmlFor="trackingFilter">송장번호</Form.Label>
              <Form.Control
                type="text"
                id="trackingFilter"
                value={trackingFilter}
                onChange={(e) => setTrackingFilter(e.target.value)}
              />
            </div>
            <div className="col-md-4">
              <Form.Label htmlFor="spaceCodeFilter">스페이스코드</Form.Label>
              <Form.Control
                type="text"
                id="spaceCodeFilter"
                value={spaceCodeFilter}
                onChange={(e) => setSpaceCodeFilter(e.target.value)}
              />
            </div>
          </div>
        </div>

        <Table bordered hover responsive className="text-center">
          <thead>
            <tr>
              <th>NO</th>
              <th>송장번호</th>
              <th>요청날짜</th>
              <th>스페이스코드</th>
              <th>저장위치</th>
              <th>담당자</th>
            </tr>
          </thead>
          <tbody>
            {currentRecords.map((split, index) => (
              <tr key={split.id} onClick={() => handleRowClick(split)}>
                <td>{index + 1 + (currentPage - 1) * itemsPerPage}</td>
                <td>{split.tracking}</td>
                <td>{format(parseISO(split.createdAt), "yyyy-MM-dd")}</td>
                <td>{split.userId}</td>
                <td>{split.shipProduct?.warehouseId || "N/A"}</td>
                <td>{split.manager || "미정"}</td>
              </tr>
            ))}
          </tbody>
        </Table>

        <CenteredPagination>
          {[...Array(totalPages)].map((_, index) => (
            <Pagination.Item
              key={index}
              active={index + 1 === currentPage}
              onClick={() => handlePageChange(index + 1)}
            >
              {index + 1}
            </Pagination.Item>
          ))}
        </CenteredPagination>

        <Modal
          show={selectedSplit !== null}
          onHide={() => setSelectedSplit(null)}
          size="xl"
          id="splitModal"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <div
                style={{ display: "table", width: "100%", fontSize: "0.9em" }}
              >
                <div style={{ display: "table-row" }}>
                  <div
                    style={{
                      display: "table-cell",
                      padding: "5px",
                      fontWeight: "bold",
                    }}
                  >
                    Tracking Number
                  </div>
                  <div style={{ display: "table-cell", padding: "5px" }}>
                    {selectedSplit?.tracking}
                  </div>
                </div>
                <div style={{ display: "table-row" }}>
                  <div
                    style={{
                      display: "table-cell",
                      padding: "5px",
                      fontWeight: "bold",
                    }}
                  >
                    User ID
                  </div>
                  <div style={{ display: "table-cell", padding: "5px" }}>
                    {selectedSplit?.userId}
                  </div>
                </div>
                <div style={{ display: "table-row" }}>
                  <div
                    style={{
                      display: "table-cell",
                      padding: "5px",
                      fontWeight: "bold",
                    }}
                  >
                    Warehouse Location
                  </div>
                  <div style={{ display: "table-cell", padding: "5px" }}>
                    {selectedSplit?.shipProduct?.warehouseId}
                  </div>
                </div>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body id="splitModalContent">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th style={{ width: "15%", textAlign: "center" }}>Box No</th>
                  <th style={{ width: "70%", textAlign: "center" }}>Comment</th>
                  <th style={{ width: "15%", textAlign: "center" }}>Photo</th>
                </tr>
              </thead>
              <tbody>
                {splits
                  .filter((split) => split.tracking === selectedSplit?.tracking)
                  .map((split) => (
                    <tr key={split.id}>
                      <td style={{ textAlign: "center" }}>{split.Split}</td>
                      <td
                        style={{
                          wordWrap: "break-word",
                          whiteSpace: "pre-wrap",
                          maxWidth: "100%",
                          textAlign: "center",
                        }}
                      >
                        {split.content}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {split.imageUrl && (
                          <a
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              handleImageClick(split.imageUrl!);
                            }}
                          >
                            {split.imageUrl.split("/").pop()?.slice(0, 10)}
                          </a>
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setSelectedSplit(null)}>
              Close
            </Button>
            <Button variant="danger" onClick={handleDelete}>
              완료
            </Button>
            <Button variant="primary" onClick={handlePrint}>
              출력
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={selectedImage !== null}
          onHide={() => setSelectedImage(null)}
          id="imageModal"
          size="xl"
        >
          <Modal.Header closeButton>
            <Modal.Title>Image Preview</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {selectedImage && (
              <img
                src={selectedImage}
                alt="Selected"
                className="img-fluid"
                style={{ width: "100%" }}
              />
            )}
          </Modal.Body>
        </Modal>
      </main>
    </>
  );
};

export default ASplit;

const CenteredPagination = styled(Pagination)`
  display: flex;
  justify-content: center;
`;
