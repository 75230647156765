import React from "react";
import Icon from "./Icon";
import styled from "styled-components";

const AdminHeadTitle = ({ subtitle1, subtitle2, title }: any) => {
  return (
    <Wrapper>
      <div>
        <span>{subtitle1}</span>
        {subtitle2 !== undefined && (
          <Icon
            style={{ margin: ".4rem" }}
            icon="chevron-right"
            fontSize="1rem"
          />
        )}

        <span>{subtitle2}</span>
      </div>

      <Title>{title}</Title>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  border-bottom: 1px solid lightgray;
  padding: 1rem 0;
  margin-bottom: 2rem;
`;

const Title = styled.p`
  margin: 1rem 0;

  color: var(--color-main-blue);
  font-weight: bold;
  font-size: 1.6rem;
`;

export default AdminHeadTitle;
