import { format } from "date-fns";

export function FormatTime(date: Date) {
  return format(date, "yyyy-MM-dd") + "T" + format(date, "HH:mm");
}

export function formatCustomDate(date: Date): string {
  return format(date, "yyyy-MM-dd");
}

export function formatDate(dateString: string): string {
  if (!dateString || new Date(dateString).toString() === "Invalid Date") {
    return "Not specified";
  }
  return formatCustomDate(new Date(dateString));
}

export function parseDateString(dateString: string): Date {
  const date = new Date(dateString);
  if (isNaN(date.getTime())) {
    throw new Error("Invalid Date");
  }
  return date;
}
