import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Icon from "./Icon";
import {
  CODE_SHIPPING_PRODUCT_STATUS_OPTIONS,
  CODE_SHOPING_PAYMENT_STATUS_OPTIONS,
  CODE_SHOP_STATUS_OPTIONS,
} from "../common/constants";
import { useLocation, useParams } from "react-router-dom";

export const RequestSent_상태로_넘어오는_Shop_페이지_Dropdown = () => {
  return (
    <>
      <option value="">Filter By Status</option>
      <option value="1" selected>
        Request Sent
      </option>
      <option value="2">Pending payment</option>
      <option value="3">Confirming deposit</option>
      <option value="4">Payment completed</option>
      <option value="6">Order completed</option>
      <option value="10">Cancelled</option>
      <option value="100">Processing</option>
    </>
  );
};

const CustomerHeadFilter = ({
  selectStatus,
  selectViewPage,
  enterSearch,
  selectSearchOption,
}: any) => {
  const { pathname } = useLocation();
  const params = useParams();

  const [viewFilter, setViewFilter] = useState<string>("");
  const [searchOptionFilter, setSearchOptionFilter] = useState<string>("");
  const [searchWordFilter, setSearchWordFilter] = useState<string>("");

  const [statusListObject, setStatusListObject] = useState<object>({});
  const [searchOptionList, setSearchOptionList] = useState<string[]>([]);
  const [viewFilterList, setViewFilterList] = useState<object[]>([
    { name: "See 10", value: "10" },
    { name: "See 50", value: "50" },
    { name: "See 100", value: "100" },
    { name: "Full View", value: "1000000" },
  ]);

  useEffect(() => {
    if (pathname.includes("/customer/shop/order")) {
      setStatusListObject(CODE_SHOP_STATUS_OPTIONS);
      setSearchOptionList(["Order Number"]);
      setSearchOptionFilter("Order Number");
      if (params.status === "1") {
        selectStatus("1");
      }
    }
    if (pathname === "/customer/warehouse") {
      setStatusListObject(CODE_SHIPPING_PRODUCT_STATUS_OPTIONS);
      setSearchOptionList(["Tracking Number"]);
      setSearchOptionFilter("Tracking Number");
    }
  }, [pathname]);

  const handleKeyPress = (event: any) => {
    if (event.key === " ") {
      event.preventDefault();
    }
  };

  return (
    <Wrapper>
      <Row>
        <Box>
          <select
            name="status"
            className="input-reset dropdown"
            onChange={(e: any) => {
              selectStatus(e.target.value);
            }}
          >
            {pathname === "/customer/shop/order/1" ? (
              <RequestSent_상태로_넘어오는_Shop_페이지_Dropdown />
            ) : (
              <>
                <option value="">Filter By Status</option>
                {Object.entries(statusListObject).map(([key, value]) => {
                  return (
                    <option key={key} value={key}>
                      {value}
                    </option>
                  );
                })}
              </>
            )}
          </select>
          <Icon
            icon="caret-down-fill"
            style={{ position: "absolute", top: "20%", right: "4%" }}
          />
        </Box>
        <Box>
          <select
            name="view"
            className="input-reset dropdown"
            value={viewFilter}
            onChange={(e: any) => {
              setViewFilter(e.target.value);
              selectViewPage(Number(e.target.value));
            }}
          >
            {viewFilterList.map((status: any, index) => {
              return (
                <option key={index} value={status.value}>
                  {status.name}
                </option>
              );
            })}
          </select>
          <Icon
            icon="caret-down-fill"
            style={{ position: "absolute", top: "20%", right: "4%" }}
          />
        </Box>
        <Box className="search">
          <div id="search-input">
            <input
              type="text"
              className="input-reset"
              placeholder={searchOptionFilter}
              value={searchWordFilter}
              onKeyPress={handleKeyPress}
              onChange={(e: any) => {
                const word = e.target.value.toUpperCase();
                setSearchWordFilter(word);
                enterSearch(word);
              }}
            />
            <SearchButton>
              <Icon icon="search" color="var(--color-main-blue)" />
            </SearchButton>
          </div>
        </Box>
      </Row>
    </Wrapper>
  );
};

const SearchButton = styled.div`
  border: 1px solid var(--color-main-blue);
  padding: 0.4rem 1rem;
  align-items: center;
  border-top-right-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem;
  cursor: pointer;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
`;

const Box = styled.div`
  display: flex;
  flex: 1;
  border: 1px solid lightgray;
  border-radius: 0.4rem;
  position: relative;

  .input-reset {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0.4rem;
    width: 100%;
    border: none;
    padding: 0.4rem 0.8rem;
  }

  #search-input {
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    & > input:focus {
      border-radius: 0;
    }
  }
`;

const Wrapper = styled.div`
  padding: 2rem 0;
  margin: 0 0 2rem 0;
`;

export default CustomerHeadFilter;
