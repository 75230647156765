import { createSlice } from "@reduxjs/toolkit";

interface iLoading {
  isLoading: boolean;
}

const initialState: iLoading = {
  isLoading: false,
};

const loadingSlice = createSlice({
  initialState,
  name: "loading",
  reducers: {
    loadingOpen: (state) => {
      state.isLoading = true;
      return state;
    },
    loadingClose: (state) => {
      state.isLoading = false;
      return state;
    },
  },
});

export const { loadingOpen, loadingClose } = loadingSlice.actions;
export default loadingSlice.reducer;
