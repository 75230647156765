import axios from "axios";
import { isPast } from "date-fns";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Swal from "sweetalert2";
import {
  ACODE_SHOPING_ORDER_STATUS,
  CODE_SHIPPING_ORDER_STATUS,
  MEMBERSHIP,
} from "../../../common/constants";
import Title from "../../../components/Title";
import { getColor } from "../../../components/getColor";
import AShipExpiredModal from "../../../components/modal/AShipExpiredModal";
import ExpiredModal from "../../../components/modal/ExpiredModal";
import { RootState } from "../../../redux/store";
import { reqSendAlarm } from "../../../requests/alarm";
import { reqShipUpdateOrder, reqShipProductBox } from "../../../requests/ship";
import AdminHeadTitle from "../../../components/AdminHeadTitle";
import AdminHeadFilter from "../../../components/AdminHeadFilter";
import { Pagination } from "react-bootstrap";
const ITEMS_PER_PAGE = 10;

export default function AShipExpired() {
  const nav = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState<string | null>(null);
  const [data, setData] = useState<OrderData[]>([]);
  const [showAShipExpiredModal, setShowAShipExpiredModal] = useState(false);
  const [showExpiredModal, setShowExpiredModal] = useState(false);
  const admin = useSelector((state: RootState) => state.admin);

  type ProductData = {
    id: number;
    tracking: string;
    status: number;
  };

  type OrderData = {
    id: string;
    createdAt: string;
    estimatedAt: string;
    expiredAt: string;
    userId: string;
    adrReceiver: string;
    status: number;
    membershipGrade: string;
    user: {
      name: string;
      membershipId: number;
    };
    products: ProductData[];
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}ship`
        );
        const filteredData = response.data.filter(
          (item: OrderData) =>
            item.expiredAt &&
            isPast(new Date(item.expiredAt)) &&
            item.status === 4
        );
        setData(filteredData);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };
    fetchData();
  }, []);

  const handleStatusClick = (orderId: string) => {
    setSelectedOrder(orderId);
    setShowAShipExpiredModal(true);
  };

  const handleDeleteClick = (orderId: string) => {
    setSelectedOrder(orderId);
    setShowExpiredModal(true);
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}ship`);
      const filteredData = response.data.filter(
        (item: OrderData) => item.status === CODE_SHIPPING_ORDER_STATUS.WAIT_PAY
      );
      setData(filteredData);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  const refreshData = () => {
    fetchData();
  };

  const handleRepackRequest = () => {
    setShowModal(false);
    setShowAShipExpiredModal(true);
    fetchData();
  };

  const handleConfirmDelete = () => {
    setShowModal(false);
  };

  const handleDiscardOrder = async (orderId: string, userId: string) => {
    Swal.fire({
      title: "폐기 처리",
      text: "정말 폐기 처리 하시겠습니까?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes!",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          // 주문 상태 업데이트
          await reqShipUpdateOrder({
            id: orderId,
            status: ACODE_SHOPING_ORDER_STATUS.EXPIRED,
          });

          // 제품 상태 업데이트
          const order = data.find((item) => item.id === orderId);
          if (order && order.products) {
            const updatePromises = order.products.map((product) =>
              reqShipProductBox({
                tracking: product.tracking,
                status: 20,
              })
            );
            await Promise.all(updatePromises);
          }

          // 알람 전송
          const alarmPayload = {
            userId: userId,
            read: 0,
            content: `${orderId} the disposal has been completed after the payment deadline.`,
            sender: admin.name,
          };
          await reqSendAlarm(alarmPayload);

          window.location.reload();
          Swal.fire("Discarded!", "The order has been discarded.", "success");
        } catch (error) {
          console.error("Error discarding order: ", error);
          Swal.fire(
            "Failed!",
            "There was an issue discarding the order. Please try again.",
            "error"
          );
        }
      }
    });
  };

  //! 필터 시작
  const [filteredData, setFilteredData] = useState<any[]>([]);

  const [searchWord, setSearchWord] = useState<string>("");
  const [searchOption, setSearchOption] = useState<string>("");

  const [selectedStatus, setSelectedStatus] = useState("");

  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    const thisFilteredData = data
      .filter((order: any) => {
        if (searchOption === "스페이스 코드") {
          return order.userId.toUpperCase().includes(searchWord.toUpperCase());
        }
        return order.id.toUpperCase().includes(searchWord.toUpperCase());
      })
      .sort(
        (a: any, b: any) =>
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
      );
    // const thisFilteredData = orders.filter(
    //   (order) =>
    //     order.orderId.toLowerCase().includes(searchWord.toLowerCase()) &&
    //     (selectedStatus ? order.status.toString() === selectedStatus : true)
    // );
    setFilteredData(thisFilteredData);
    setCurrentPage(1);
    setTotalPages(Math.ceil(thisFilteredData.length / itemsPerPage));
  }, [data, searchWord, itemsPerPage, selectedStatus, searchOption]);

  const handleItemsPerPageChange = (newItemsPerPage: number) => {
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1);
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const displayedOrders = filteredData.slice(
    startIndex,
    startIndex + itemsPerPage
  );
  //! 필터 끝

  const tableRows = displayedOrders.map((item, index) => {
    const isExpired = new Date(item.expiredAt) < new Date();

    return (
      <tr key={index}>
        <td>{index + 1 + startIndex}</td>
        <td
          onClick={() => handleStatusClick(item.id)}
          style={{ cursor: "pointer" }}
        >
          {item.id}
        </td>
        <td>
          {item.estimatedAt
            ? new Date(item.estimatedAt).toLocaleDateString()
            : "날짜 없음"}
        </td>
        <td style={{ backgroundColor: "red", color: "white" }}>
          {item.expiredAt
            ? new Date(item.expiredAt).toLocaleDateString()
            : "날짜 없음"}
        </td>

        <td>{item.userId}</td>
        <td>{item.adrReceiver}</td>
        <td style={{ color: getColor(item.user.membershipId) }}>
          {MEMBERSHIP[item.user.membershipId]}
        </td>
        <td>
          <button
            className="btn btn-danger btn-sm"
            onClick={() => handleDiscardOrder(item.id, item.userId)}
          >
            폐기
          </button>
        </td>
      </tr>
    );
  });

  // 띄어쓰기
  const handleKeyPress = (event: any) => {
    if (event.key === " ") {
      event.preventDefault();
    }
  };

  return (
    <div>
      <AdminHeadTitle
        subtitle1="배송대행"
        subtitle2="배송대행 결제기한 만료"
        title="배송대행 결제기한 만료"
      />

      {/* 옵션 필터 */}
      <AdminHeadFilter
        selectViewPage={(value: number) => handleItemsPerPageChange(value)}
        enterSearch={(value: string) => setSearchWord(value)}
        selectSearchOption={(value: string) => setSearchOption(value)}
      />

      <TableContainer>
        <table>
          <thead>
            <tr>
              <th>No</th>
              <th>주문 번호</th>
              <th>견적서 전송 날짜</th>
              <th>결제 마감일</th>
              <th>스페이스 코드</th>
              <th>성명</th>
              <th>멤버십 등급</th>
              <th>상태</th>
            </tr>
          </thead>
          <tbody>{tableRows}</tbody>
        </table>
      </TableContainer>

      {/* 페이지 바 */}
      <CenteredPagination style={{ marginTop: "1rem" }}>
        {[...Array(totalPages)].map((_, index) => (
          <Pagination.Item
            key={index}
            active={index + 1 === currentPage}
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </Pagination.Item>
        ))}
      </CenteredPagination>

      {showAShipExpiredModal && (
        <AShipExpiredModal
          show={showAShipExpiredModal}
          onHide={() => setShowAShipExpiredModal(false)}
          orderNumber={selectedOrder ?? ""}
          onRepackRequest={handleRepackRequest}
          refreshData={refreshData}
        />
      )}
      {showExpiredModal && (
        <ExpiredModal
          show={showExpiredModal}
          onHide={() => setShowExpiredModal(false)}
          onConfirm={handleConfirmDelete}
        />
      )}
    </div>
  );
}

const CenteredPagination = styled(Pagination)`
  display: flex;
  justify-content: center;
`;

const TableContainer = styled.div`
  width: 100%;
  margin-top: 40px;
  margin-bottom: 40px;

  table {
    width: 100%;
    border-collapse: collapse;

    th,
    td {
      padding: 8px;
      text-align: center;
      border: 1px solid #ddd;
    }

    th {
      background-color: #f2f2f2;
    }
  }
`;

const Toolbar = styled.div`
  .right {
    display: flex;
    gap: 10px;
    width: 300px;
    float: right;

    button {
      width: 100px;
    }
  }

  .left {
    display: flex;
    gap: 10px;

    > div {
      width: 150px;

      button {
        border-radius: 20px;
      }
    }
  }
`;
