import { AxiosManager } from "../axiosManager";

export function reqInboundShop(payload: any) {
  return AxiosManager.post("warehouse/inbound/shop", payload);
}

export function reqInboundShip(payload: any) {
  return AxiosManager.post("warehouse/inbound/ship", payload);
}

export function reqInboundsShip(payload: any) {
  return AxiosManager.post("warehouse/inbound/ships", payload);
}

export function reqInboundlsotShip(payload: any) {
  return AxiosManager.post("warehouse/inbound/ship/lost", payload);
}

export function reqGetMembership() {
  return AxiosManager.get("memberships");
}

export function reqPutMembership(payload: any) {
  return AxiosManager.put(`memberships/${payload.id}`, payload);
}

export function reqShipProductBuy(payload: any) {
  return AxiosManager.post("product/buy", payload);
}

export function reqShipProductUpdates(payload: any) {
  return AxiosManager.put("product/details/update", payload);
}

export function reqlostUpdates(payload: any) {
  return AxiosManager.patch(`lost-shipment-requests/${payload.id}`, payload);
}

export function reqlostGetUser(payload: any) {
  return AxiosManager.get(`lost-shipment-requests/by-user/${payload.userId}`, payload);
}