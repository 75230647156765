import { useEffect, useState } from "react";
import { Button, Collapse, Form, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import styled from "styled-components";
import Swal from "sweetalert2";
import XLSX from "xlsx-js-style";
import {
  ACODE_SHOPING_ORDER_STATUS,
  CODE_COUNTRY_ITEMS,
  CODE_SHIPPING_COMPANY,
  CODE_SHIPPING_PAY,
  CODE_SHOPPING_ORDER_ITEMS,
} from "../../common/constants";
import { RootState } from "../../redux/store";
import { reqSendAlarm } from "../../requests/alarm";
import {
  reqProductDetail,
  reqPutExpress,
  reqShip,
  reqShipCompleteForCron,
  reqShipDetail,
  reqShipOrderUpdate,
  reqShipProductBox,
  reqShipUpdateOrder,
} from "../../requests/ship";

interface ProductDetail {
  id: number;
  productId: number;
  category: number;
  name: string;
  price: number;
  quantity: number;
  trackingNumber: string;
}
interface Product {
  tracking: string;
  warehouseId: string;
  isUrgent: boolean;
  bag: boolean;
  name: string;
  warehouse: string;
  price: number;
  quantity: number;
  details: ProductDetail[];
}

interface OrderDetails {
  userId: string;
  products: Product[];
  adrReceiver: string;
  adrEmail: string;
  adrPhone: string;
  adrCountry: string;
  adrPCCC: string;
  adrZip: string;
  adrState: string;
  adrCity: string;
  adrOption1: string;
  adrOption2: string;
  DepositorFee: string;
  adrOption3: string;
  weightKg: number;
  heightCm: number;
  widthCm: number;
  lengthCm: number;
  suspectBattery: boolean;
  food: boolean;
  packingStatus: string;
  customerMessage: string;
  PaymentMethod: number;
  company: number;
}
interface RepackModalCP {
  show: boolean;
  onHide: () => void;
  orderNumber: string;
  onRepackRequest: () => void;
  refreshData: () => void;
}

interface PackingDetail {
  id: string;
  totalWeightKg: number;
  totalHeightCm: number;
  totalWidthCm: number;
  totalLengthCm: number;
  trackingNumbers: string;
  suspectBattery: boolean;
  food: boolean;
  managerMessage: string;
  isDeleted: boolean;
  express: string;
}

interface IsMenuOpenState {
  [key: string]: boolean;
}

interface TrackingInfo {
  id: number;
  orderId: string;
  orderIds: string;
  trackingNumbers: string;
}

type ProductDetailsByPackingType = {
  [key: string]: ProductDetail[];
};

export default function RepackModalCP({
  show,
  onHide,
  orderNumber,
  onRepackRequest,
  refreshData,
}: RepackModalCP) {
  const [packingDetails, setPackingDetails] = useState<PackingDetail[]>([]);
  const [isMenuOpen, setIsMenuOpen] = useState<{ [key: string]: boolean }>({});
  const [expressTrackingNumbers, setExpressTrackingNumbers] = useState<{
    [key: string]: string;
  }>({});
  const [trackingInfo, setTrackingInfo] = useState<TrackingInfo[]>([]);
  const [productDetailsByPacking, setProductDetailsByPacking] =
    useState<ProductDetailsByPackingType>({});
  const [orderDetails, setOrderDetails] = useState<OrderDetails>({
    userId: "",
    products: [],
    adrReceiver: "",
    adrEmail: "",
    adrPhone: "",
    adrCountry: "",
    adrPCCC: "",
    adrZip: "",
    adrState: "",
    adrCity: "",
    adrOption1: "",
    adrOption2: "",
    DepositorFee: "",
    adrOption3: "",
    company: 0,
    PaymentMethod: 0,
    weightKg: 0,
    heightCm: 0,
    widthCm: 0,
    lengthCm: 0,
    suspectBattery: false,
    food: false,
    packingStatus: "",
    customerMessage: "",
  });
  const admin = useSelector((state: RootState) => state.admin);

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const response = await reqShip({ id: orderNumber });
        setOrderDetails(response.data);
      } catch (error) {
        console.error("Error fetching order details: ", error);
      }
    };

    if (orderNumber) {
      fetchOrderDetails();
    }
    const fetchPackingDetails = async () => {
      try {
        const response = await reqShipDetail({ order_id: orderNumber });
        setPackingDetails(response.data);
      } catch (error) {
        console.error("Error fetching packing details: ", error);
      }
    };

    if (orderNumber) {
      fetchPackingDetails();
    }
  }, [orderNumber]);

  useEffect(() => {
    const fetchPackingDetails = async () => {
      try {
        const response = await reqShipDetail({ order_id: orderNumber });
        const activeDetails = (response.data as PackingDetail[]).filter(
          (detail) => !detail.isDeleted
        );
        setPackingDetails(activeDetails);
        const expressNumbers: { [key: string]: string } = {};
        activeDetails.forEach((detail) => {
          expressNumbers[detail.id] = detail.express || "";
        });
        setExpressTrackingNumbers(expressNumbers);
      } catch (error) {
        console.error("Error fetching packing details: ", error);
      }
    };

    if (orderNumber) {
      fetchPackingDetails();
    }
  }, [orderNumber]);

  useEffect(() => {
    const fetchProductDetailsForPacking = async () => {
      const newProductDetailsByPacking: ProductDetailsByPackingType = {};
      for (const detail of packingDetails) {
        try {
          const response = await reqProductDetail({ id: detail.id });
          newProductDetailsByPacking[detail.id] = response.data;
        } catch (error) {
          console.error(
            `Error fetching product details for packing ${detail.id}: `,
            error
          );
        }
      }
      setProductDetailsByPacking(newProductDetailsByPacking);
    };

    if (packingDetails.length > 0) {
      fetchProductDetailsForPacking();
    }
  }, [packingDetails]);

  const updateExpressTracking = async (detailId: string) => {
    Swal.fire({
      title: "해당 박스에 대해 특송사 등록을 완료하시겠습니까?",
      text: "사용자에게 알림을 전송합니다.",
      icon: "question",

      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "승인",
      cancelButtonText: "취소",

      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const trackingNumber = expressTrackingNumbers[detailId];
        const alarmPayload = {
          userId: orderDetails.userId,
          read: 0,
          content: `${detailId} is ready for pick up.\nThe tracking number is ${trackingNumber}.`,
          sender: admin.name,
        };
        await reqSendAlarm(alarmPayload);

        const payload = {
          id: detailId,
          express: trackingNumber,
        };
        await reqPutExpress(payload);
        await reqShipUpdateOrder({
          id: orderNumber,
          status: ACODE_SHOPING_ORDER_STATUS.PRODUCT_SHOPING,
        });
        refreshData();

        Swal.fire(
          "해당 물품에 대해 특송사 등록이 완료되었습니다.",
          "",
          "success"
        );
      }
    });
  };

  const handleExpressRegister = async () => {
    Swal.fire({
      title: "특송사 등록을 완료하시겠습니까?",
      text: "사용자에게 알림을 전송합니다.",
      icon: "question",

      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "승인",
      cancelButtonText: "취소",

      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await reqShipUpdateOrder({
          id: orderNumber,
          status: ACODE_SHOPING_ORDER_STATUS.PRODUCT_WAREHOUSING,
        });

        for (const detail of packingDetails) {
          const expressTrackingNumber = expressTrackingNumbers[detail.id];
          if (expressTrackingNumber) {
            const productDetails = productDetailsByPacking[detail.id] || [];
            for (const productDetail of productDetails) {
              if (productDetail.trackingNumber) {
                await reqShipProductBox({
                  tracking: productDetail.trackingNumber,
                  status: 6,
                });
              }
            }
            const payload = {
              id: detail.id,
              express: expressTrackingNumber,
            };
            await reqPutExpress(payload);
            await reqShipOrderUpdate({
              id: orderNumber,
              deliveryAt: new Date(),
            });
          }
        }

        // 입고 등록 상태 업데이트
        await reqShipCompleteForCron({
          id: orderNumber,
        });

        const alarmPayload = {
          userId: orderDetails.userId,
          read: 0,
          content: `${orderNumber} is ready for pick up. The tracking number is ${packingDetails[0].id}`,
          sender: admin.name,
        };
        await reqSendAlarm(alarmPayload);

        onHide();
        refreshData();
        alert("특송사 전체 등록 완료.");
        onRepackRequest();

        Swal.fire("특송사 등록이 완료되었습니다.", "", "success");
      }
    });
  };

  // 카테고리별 제품 수와 총 수량 계산
  const getCategorySummary = (productDetails: ProductDetail[]) => {
    const summary: {
      [key: number]: { name: string; count: number; quantity: number };
    } = {};

    productDetails.forEach((productDetail) => {
      const category = productDetail.category;
      if (!summary[category]) {
        summary[category] = {
          name: CODE_SHOPPING_ORDER_ITEMS[category],
          count: 0,
          quantity: 0,
        };
      }
      summary[category].count += 1;
      summary[category].quantity += productDetail.quantity;
    });

    return summary;
  };

  // 개별 박스별 엑셀 다운로드 기능 추가
  const fetchAndModifyExcelForBox = async (packingDetailId: string) => {
    try {
      // 엑셀 템플릿 파일을 불러옴
      const response = await fetch("/resources/TAX.xlsx");
      const arrayBuffer = await response.arrayBuffer();
      const workbook = XLSX.read(arrayBuffer, { type: "array" });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];

      // 스타일 정의
      const style = {
        font: { bold: true },
        alignment: { horizontal: "center" },
        border: {
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
          right: { style: "thin" },
        },
      };

      // 셀 업데이트 함수
      const updateCell = (cellAddress: string, value: string | number) => {
        const cell = worksheet[cellAddress] || { t: "s", v: "" };
        cell.v = value;
        cell.s = style; // 스타일을 셀에 적용
        worksheet[cellAddress] = cell;
      };

      // 데이터 입력
      updateCell("A11", orderDetails.adrReceiver);
      updateCell("A12", orderDetails.adrOption1);
      updateCell("A13", orderDetails.adrOption2);
      updateCell("A14", orderDetails.adrOption3);
      updateCell(
        "A15",
        `${orderDetails.adrCity}, ${orderDetails.adrState}, ${orderDetails.adrZip}`
      );
      updateCell("A16", `TAX Id: ${orderDetails.adrPCCC}`);
      updateCell(
        "D24",
        `${orderDetails.adrCity}, ${
          CODE_COUNTRY_ITEMS[Number(orderDetails.adrCountry)]
        }`
      );

      const productDetails = productDetailsByPacking[packingDetailId] || [];
      const productSummary = Object.entries(getCategorySummary(productDetails));

      productSummary.forEach(([category, data], index) => {
        updateCell(`A${30 + index}`, data.name);
        updateCell(`G${30 + index}`, data.quantity);
      });

      const totalAmount = productDetails.reduce(
        (total, productDetail) =>
          total + productDetail.quantity * productDetail.price,
        0
      );

      updateCell("K34", totalAmount.toFixed(2));

      // 스타일이 적용된 엑셀 파일을 작성
      const workbookOut = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      // Blob으로 변환 후 다운로드 링크 생성
      const blob = new Blob([workbookOut], {
        type: "application/octet-stream",
      });
      const url = URL.createObjectURL(blob);

      // 엑셀 파일 다운로드
      const a = document.createElement("a");
      a.href = url;
      a.download = `Modified_TAX_${packingDetailId}.xlsx`;
      a.click();
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error fetching and modifying Excel file", error);
    }
  };

  return (
    <FullScreenModal show={show} onHide={onHide} centered>
      <Modal.Header closeButton className="bg-danger">
        <Modal.Title className="text-white">{orderNumber}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* 요청 정보 */}
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <div className="container text-center bg-dark">
              <h3 className="text-white">결재정보</h3>
            </div>
            <table className="table text-center table-hover">
              <tbody>
                <tr>
                  <td>배송사</td>
                  <td>
                    {CODE_SHIPPING_COMPANY[orderDetails.company] || "정보없음"}
                  </td>
                </tr>
                <tr>
                  <td>결재금액</td>
                  <td>{orderDetails.DepositorFee}</td>
                </tr>
                <tr>
                  <td>결제수단</td>
                  <td>
                    {CODE_SHIPPING_PAY[orderDetails.PaymentMethod] ||
                      "정보없음"}
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="container text-center bg-dark">
              <h3 className="text-white">특송사 송장번호 등록</h3>
            </div>
            <table className="table text-center table-hover">
              <tbody>
                <tr>
                  <td>RP-NUMBER</td>
                  <td>Tracking Number</td>
                </tr>
                {packingDetails.map((detail, index) => (
                  <tr key={index}>
                    <td>{detail.id}</td>
                    <td>
                      <div className="d-flex align-items-center">
                        <Form.Control
                          type="text"
                          className="me-2"
                          value={expressTrackingNumbers[detail.id] || ""}
                          onChange={(e) =>
                            setExpressTrackingNumbers({
                              ...expressTrackingNumbers,
                              [detail.id]: e.target.value,
                            })
                          }
                        />
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => updateExpressTracking(detail.id)}
                        >
                          V
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {/* <div className="container text-center bg-dark mt-3">
              <h3 className="text-white">통화 선택</h3>
              <Form.Select
                aria-label="통화 선택"
                value={currency}
                onChange={handleCurrencyChange}
              >
                <option value="USD">USD</option>
                <option value="EUR">EUR</option>
              </Form.Select>
            </div> */}
            <Button
              onClick={async () => handleExpressRegister()}
              disabled={Object.values(expressTrackingNumbers).some(
                (value) => !value
              )}
            >
              특송사 등록 완료
            </Button>
            <div className="d-flex justify-content-end">
              <Button
                className="mt-2"
                variant="warning"
                onClick={async () => {
                  // Show confirmation dialog using Swal
                  const result = await Swal.fire({
                    title: "정말로 상태를 되돌리시겠습니까?",
                    text: "이 작업은 취소할 수 없습니다.",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "되돌리기",
                    cancelButtonText: "취소",
                    reverseButtons: true,
                  });

                  // If the user confirms, proceed with the status update
                  if (result.isConfirmed) {
                    try {
                      await reqShipUpdateOrder({
                        id: orderNumber,
                        status: ACODE_SHOPING_ORDER_STATUS.PRODUCT_PART,
                      });
                      Swal.fire("상태가 되돌려졌습니다.", "", "success");

                      // Hide modal and refresh data after the update
                      onHide();
                      refreshData();

                      // Optionally, reload the page for a full refresh
                      window.location.reload();
                    } catch (error) {
                      console.error("Error updating order status: ", error);
                      Swal.fire("상태 되돌리기에 실패했습니다.", "", "error");
                    }
                  }
                }}
              >
                되돌리기
              </Button>
            </div>
          </div>
          {/* 배송지 정보 입력 폼 */}
          <div className="col-3">
            <div className="container text-center bg-dark">
              <h3 className="text-white">배송정보</h3>
            </div>
            <div className="container">
              <Form>
                <Form.Group className="mb-1">
                  <Form.Label>받는사람</Form.Label>
                  <Form.Control type="text" value={orderDetails.adrReceiver} />
                </Form.Group>
                <Form.Group className="mb-1">
                  <Form.Label>이메일</Form.Label>
                  <Form.Control type="text" value={orderDetails.adrEmail} />
                </Form.Group>
                <Form.Group className="mb-1">
                  <Form.Label>폰번호</Form.Label>
                  <Form.Control type="text" value={orderDetails.adrPhone} />
                </Form.Group>
                <Form.Group className="mb-1">
                  <Form.Label>국가</Form.Label>
                  <Form.Control
                    type="text"
                    value={
                      CODE_COUNTRY_ITEMS[Number(orderDetails.adrCountry)] ||
                      "Country not selected"
                    }
                  />
                </Form.Group>
                <Form.Group className="mb-1">
                  <Form.Label>개인통관부호</Form.Label>
                  <Form.Control type="text" value={orderDetails.adrPCCC} />
                </Form.Group>
                <Form.Group className="mb-1">
                  <Form.Label>Zipcode</Form.Label>
                  <Form.Control type="text" value={orderDetails.adrZip} />
                </Form.Group>
                <Form.Group className="mb-1">
                  <Form.Label>State</Form.Label>
                  <Form.Control type="text" value={orderDetails.adrState} />
                </Form.Group>
                <Form.Group className="mb-1">
                  <Form.Label>City</Form.Label>
                  <Form.Control type="text" value={orderDetails.adrCity} />
                </Form.Group>
                <Form.Group className="mb-1">
                  <Form.Label>Address-1(35)</Form.Label>
                  <Form.Control type="text" value={orderDetails.adrOption1} />
                </Form.Group>
                <Form.Group className="mb-1">
                  <Form.Label>Address-2(35)</Form.Label>
                  <Form.Control type="text" value={orderDetails.adrOption2} />
                </Form.Group>
                <Form.Group className="mb-1">
                  <Form.Label>Address-3(35)</Form.Label>
                  <Form.Control type="text" value={orderDetails.adrOption3} />
                </Form.Group>
              </Form>
            </div>
          </div>
          {/* 포장정보 섹션 */}
          <div className="col-lg-5 col-md-6">
            <div className="container text-center bg-dark">
              <h3 className="text-white">포장정보</h3>
            </div>
            <div
              className="accordion accordion-flush"
              id="accordionFlushExample"
            >
              {packingDetails.map((detail, index) => (
                <div className="accordion-item" key={index}>
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      onClick={() => {
                        setIsMenuOpen({
                          ...isMenuOpen,
                          [`packingDetail${index}`]:
                            !isMenuOpen[`packingDetail${index}`],
                        });
                      }}
                    >
                      <ResponsiveTable className="table text-center table-hover">
                        <thead>
                          <tr>
                            <th scope="col" style={{ width: "10%" }}>
                              BOX NO
                            </th>
                            <th scope="col" style={{ width: "20%" }}>
                              RP-NUMBER
                            </th>
                            <th scope="col" style={{ width: "10%" }}>
                              KG
                            </th>
                            <th scope="col" style={{ width: "10%" }}>
                              가로
                            </th>
                            <th scope="col" style={{ width: "10%" }}>
                              세로
                            </th>
                            <th scope="col" style={{ width: "10%" }}>
                              높이
                            </th>
                            <th scope="col" style={{ width: "15%" }}>
                              박스 총 금액
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th scope="row">{index + 1}</th>
                            <td>{detail.id}</td>
                            <td>{detail.totalWeightKg}</td>
                            <td>{detail.totalWidthCm}</td>
                            <td>{detail.totalLengthCm}</td>
                            <td>{detail.totalHeightCm}</td>
                            <td>
                              $
                              {productDetailsByPacking[detail.id]
                                ? productDetailsByPacking[detail.id]
                                    .reduce(
                                      (total, productDetail) =>
                                        total +
                                        productDetail.quantity *
                                          productDetail.price,
                                      0
                                    )
                                    .toFixed(2)
                                : "0.00"}
                            </td>
                          </tr>
                        </tbody>
                      </ResponsiveTable>
                    </button>
                  </h2>
                  <Collapse in={isMenuOpen[`packingDetail${index}`]}>
                    <div
                      id={`flush-collapse${index}`}
                      className="accordion-collapse"
                      aria-labelledby={`flush-heading${index}`}
                    >
                      <div className="accordion-body">
                        {/* 카테고리별 제품 수와 총 수량 */}
                        <Button
                          onClick={() => fetchAndModifyExcelForBox(detail.id)}
                          className="mt-3"
                        >
                          엑셀 다운로드 (박스 {index + 1})
                        </Button>
                        <div className="container text-center bg-light mb-3">
                          <h5>카테고리별 제품 수와 총 수량</h5>
                          <ResponsiveTable className="table table-striped">
                            <thead>
                              <tr>
                                <th>카테고리</th>
                                <th>카테고리 수량</th>
                                <th>총 수량</th>
                              </tr>
                            </thead>
                            <tbody>
                              {Object.entries(
                                getCategorySummary(
                                  productDetailsByPacking[detail.id] || []
                                )
                              ).map(([category, data], catIndex) => (
                                <tr key={catIndex}>
                                  <td>{data.name}</td>
                                  <td>{data.count}</td>
                                  <td>{data.quantity}</td>
                                </tr>
                              ))}
                            </tbody>
                          </ResponsiveTable>
                        </div>
                        <ResponsiveTable className="table table-striped">
                          <thead>
                            <tr>
                              <th>NO</th>
                              <th>송장번호</th>
                              <th>카테고리</th>
                              <th>수량</th>
                              <th>단가</th>
                              <th>합계</th>
                            </tr>
                          </thead>
                          <tbody>
                            {productDetailsByPacking[detail.id]?.map(
                              (productDetail, dIndex) => (
                                <tr key={dIndex}>
                                  <th scope="row">{dIndex + 1}</th>
                                  <td>{productDetail.trackingNumber}</td>
                                  <td>
                                    {
                                      CODE_SHOPPING_ORDER_ITEMS[
                                        productDetail.category
                                      ]
                                    }
                                  </td>
                                  <td>{productDetail.quantity}개</td>
                                  <td>${productDetail.price}</td>
                                  <td>
                                    $
                                    {Number(
                                      (
                                        productDetail.quantity *
                                        productDetail.price
                                      ).toFixed(2)
                                    )}
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </ResponsiveTable>
                        {/* 엑셀 다운로드 버튼을 각 박스별로 추가 */}
                      </div>
                    </div>
                  </Collapse>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </FullScreenModal>
  );
}

const FullScreenModal = styled(Modal)`
  .modal-dialog {
    max-width: 100%;
    margin: 0;
    font-size: 0.9em; // 모달 전체 폰트 사이즈 조정
    .small-font {
      font-size: 0.5em;
    }
  }
`;

const ResponsiveTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  table-layout: auto; // Allows the table to adjust width dynamically

  th,
  td {
    padding: 8px;
    text-align: center;
    border: 1px solid #ddd;
  }

  @media (max-width: 768px) {
    font-size: 0.8em; // Smaller text on smaller screens
    th,
    td {
      padding: 6px;
    }
  }

  @media (max-width: 480px) {
    font-size: 0.7em; // Even smaller text for mobile devices
    th,
    td {
      padding: 4px;
    }
  }
`;
