import React from 'react';

const CSplit = () => {

    return (
        <main id="main" className="main">
            <div className="pagetitle">
                <h1>Hello Ontact Family, This is the form to request your items to be split.</h1>
                <h5>Please note that:</h5>
                <ul>
                    <li>You can only request 1 tracking number to be split by request.</li>
                    <li>Once a box is split you cannot ask for split again.</li>
                    <li>Split service is not inclusion service meaning you cannot ask for an album to be opened and photocards to be taken out.</li>
                    <li>Check out the split service prices right here <a href="https://ontactkorea.kr/untitled-58">https://ontactkorea.kr/untitled-58</a></li>
                </ul>
                <div className="mb-3">
                    <label htmlFor="exampleFormControlInput1" className="form-label">Which tracking number do you want to split?</label>
                    <div className="input-group mb-3" id="exampleFormControlInput1">
                        <span className="input-group-text"><button className="btn" data-bs-toggle="modal" data-bs-target="#tnsel">Select</button></span>
                        <input type="text" className="form-control" placeholder="567100000001" />
                    </div>
                </div>
                <div className="mb-3">
                    <label htmlFor="exampleFormControlInput2" className="form-label">몇 박스로 나눌꺼야?</label>
                    <div id="exampleFormControlInput2">
                        <ul>
                            <li><a href="ware_5-2.html">2 box</a></li>
                            <li><a href="ware_5-3.html">3 box</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default CSplit;
