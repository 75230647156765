import { addDays, differenceInCalendarDays, format } from "date-fns";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CODE_SHOPPING_ORDER_ITEMS,
  USER_BADGE,
} from "../../../common/constants";
import { FormatTime } from "../../../common/format";
import { MODAL_TYPE } from "../../../components/modal/GlobalModal";
import { modalOpen } from "../../../redux/modalSlice";
import { RootState, RootUserState } from "../../../redux/store";
import {
  reqShopDetailUpdatePut,
  reqShopUserDetail,
  reqShopUpdate,
  reqShopProductIdDetail,
  reqAShopDetail,
} from "../../../requests/shop";
import { reqGetUserById } from "../../../requests/user";
import {
  reqGetMembership,
  reqInboundsShip,
  reqShipProductBuy,
} from "../../../requests/warehouse";
import Swal from "sweetalert2";
import { reqSendAlarm } from "../../../requests/alarm";
import styled from "styled-components";
import { reqAddBadge } from "../../../requests/badge";
import { reqShipFEE } from "../../../requests/ship";
import AdminHeadTitle from "../../../components/AdminHeadTitle";
import JsBarcode from "jsbarcode";
import * as XLSX from 'xlsx';

interface FetchedOrder {
  orderId: string;
  userId: string;
  name: string;
  option1: string;
  option2: string;
  quantity: number;
  category: number;
  createdAt: string;
  PackageId: string;
  productId: string;
  price: string;
  isCanceled: boolean;
  status: number;
  Addstatus: number;
  warehouse: string;
  user: {
    name: string;
    membershipId: number;
    latestWarehouseId: string;
  };
}

interface MembershipInfo {
  freeStorageDaysRed: number;
  freeStorageDaysGreen: number;
  freeStorageDaysBlue: number;
}

export default function AWInbound() {
  const [orderDetails, setOrderDetails] = useState<FetchedOrder[]>([]);
  const [latestWarehouseId, setLatestWarehouseId] = useState("");
  const user = useSelector((state: RootUserState) => state.user);
  const [userId, setUserId] = useState<string>("");
  const [file, setFile] = useState<File | null>(null);
  const [productId, setProductId] = useState("");
  const [exchangeRate, setExchangeRate] = useState<number>(0);
  const [fetchedOrders, setFetchedOrders] = useState<FetchedOrder[]>([]);
  const [userMembershipId, setUserMembershipId] = useState<number>(3);
  const [membershipInfo, setMembershipInfo] = useState<MembershipInfo | null>(
    null
  );
  const dispatch = useDispatch();
  const [data, setData] = useState({
    userId: "",
    tracking: "",
    date: new Date(),
    Extra: 0,
    warehouseId: "",
    warehouse: "",
    file: "",
    Addstatus: 0,
    name: "",
    price: 0,
    quantity: 0,
    category: 0,
  });
  const [isValid, setIsValid] = useState({
    userId: true,
    tracking: true,
    warehouseId: true,
    warehouse: true,
    Extra: true,
    productId: true,
    Addstatus: true,
    name: true,
    price: true,
    quantity: true,
    category: true,
  });
  const [imageUrl, setImageUrl] = useState<any>("");
  const admin = useSelector((state: RootState) => state.admin);

  const [orderList, setOrderList] = useState<FetchedOrder[]>([]);
  const [allOrders, setAllOrders] = useState<FetchedOrder[]>([]);

  const [fixedExchangeRate, setFixedExchangeRate] = useState(0);

  const fetchFixedExchangeRate = async () => {
    try {
      const response = await reqShipFEE();
      if (response && response.data && response.data[0]?.fixedExchangeRateShop) {
        setFixedExchangeRate(parseFloat(response.data[0].fixedExchangeRateShop));
      }
    } catch (error) {
      console.error("Error fetching fixed exchange rate:", error);
    }
  };

  useEffect(() => {
    fetchFixedExchangeRate();
  }, [data]);

  const fetchOrderDetails = async () => {
    if (!userId) return;
    try {
      const response = await reqShopUserDetail({ userId });
      if (response.data) {
        const filteredOrders = response.data.filter(
          (order: FetchedOrder) => order.status === 2 && order.PackageId
        );
        setFetchedOrders(filteredOrders);
        setOrderList(filteredOrders);
      }
    } catch (error) {
      console.error("Error fetching order details: ", error);
    }
  };

  const fetchProductDetails = async () => {
    if (!productId) return;
    try {
      const response = await reqShopProductIdDetail({ productId });
      if (response.data) {
        const filteredOrders = response.data.filter(
          (order: FetchedOrder) => order.status === 2 && order.PackageId
        );
        setOrderList(filteredOrders);
      }
    } catch (error) {
      console.error("Error fetching product details:", error);
    }
  };

  const fetchAllOrders = async () => {
    try {
      const response = await reqAShopDetail();
      if (response.data) {
        const filteredOrders = response.data.filter(
          (order: FetchedOrder) => order.status === 2 && order.PackageId
        );
        const sortedOrders = filteredOrders.sort((a: any, b: any) =>
          new Date(a.createdAt) > new Date(b.createdAt) ? 1 : -1
        );
        setAllOrders(sortedOrders);
      }
    } catch (error) {
      console.error("Error fetching all orders: ", error);
    }
  };

  const checkValid = () => {
    let [formIsValid, message] = [true, ""];
    const newIsValidState = { ...isValid };

    if (data.userId === "") {
      newIsValidState.userId = false;
      formIsValid = false;
      message = "User ID를 입력해주세요.";
    }
    if (data.tracking === "") {
      newIsValidState.tracking = false;
      formIsValid = false;
      message = "송장번호를 입력해주세요.";
    }
    if (data.Extra < 0) {
      newIsValidState.Extra = false;
      formIsValid = false;
      message = "입고금액을 올바르게 입력해주세요.";
    }
    if (data.name === "") {
      setIsValid({ ...isValid, name: false });
      formIsValid = false;
      return { formIsValid, message };
    }
    setIsValid(newIsValidState);
    return { formIsValid, message };
  };

  useEffect(() => {
    const fetchLatestWarehouseIdAndMembership = async () => {
      if (!userId) return;
      try {
        const response = await reqGetUserById({ id: userId });
        if (response && response.data) {
          const { latestWarehouseId, membershipId } = response.data;
          setLatestWarehouseId(latestWarehouseId || "");
          setUserMembershipId(membershipId || 3);
        } else {
          setLatestWarehouseId("");
          setUserMembershipId(3);
        }
      } catch (error) {
        console.error("창고 위치 및 멤버십 ID 조회 실패:", error);
        setLatestWarehouseId("");
        setUserMembershipId(3);
      }
    };

    fetchLatestWarehouseIdAndMembership();
  }, [userId]);

  useEffect(() => {
    const fetchMembershipInfo = async () => {
      try {
        const response = await reqGetMembership();
        if (response && response.data && response.data.length > 0) {
          setMembershipInfo(response.data[0]);
        }
      } catch (error) {
        console.error("멤버십 정보 조회 실패:", error);
      }
    };

    fetchMembershipInfo();
  }, []);

  useEffect(() => {
    fetchAllOrders();
  }, []);

  const calculateFreeAt = (createdAt: any, membershipId: any) => {
    let daysToAdd = 0;

    if (membershipInfo) {
      switch (membershipId) {
        case 1:
          daysToAdd = membershipInfo.freeStorageDaysRed || 30;
          break;
        case 2:
          daysToAdd = membershipInfo.freeStorageDaysGreen || 30;
          break;
        default:
          daysToAdd = 30;
      }
    }

    const resultDate = addDays(new Date(createdAt), daysToAdd);
    return resultDate;
  };

  const [selectedOrderId, setSelectedOrderId] = useState<string>("");
  const handleCheckboxChange = (order: FetchedOrder) => {
    if (selectedOrderId === order.productId) {
      setSelectedOrderId("");
      return;
    }
    setSelectedOrderId(order.productId);
    setData({
      ...data,
      userId: order.userId,
      tracking: order.productId,
      warehouseId: order.user.latestWarehouseId,
      name: order.name,
      price: Number(order.price),
      quantity: order.quantity,
      category: order.category,
    });
    setIsValid({
      ...isValid,
      userId: true,
      productId: true,
      warehouseId: true,
      name: true,
      price: true,
      quantity: true,
      category: true,
    });
  };

  const updateOrderStatus = async (productId: string, status: number) => {
    try {
      await reqShopDetailUpdatePut({ productId, status });
    } catch (error) {
      console.error("Error updating order status:", error);
    }
  };

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      if (reader.readyState === 2) {
        setImageUrl(reader.result);
      }
    };
    reader.readAsDataURL(file);
    setFile(file);
  };

  const [isLoading, setIsLoading] = useState(false);

  const handleFormSubmit = async () => {
    const { formIsValid, message } = checkValid();
    if (!formIsValid) {
      Swal.fire(message, "", "warning");
      return;
    }
    if (!file) {
      Swal.fire("사진을 등록해주세요.", "", "warning");
      return;
    }
    if (file.size > 5 * 1024 * 1024) {
      Swal.fire("파일 용량을 줄여주세요. (최대 5MB)", "", "warning");
      return;
    }

    Swal.fire({
      title: "입고 등록을 진행하시겠습니까?",
      text: "",
      icon: "question",

      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "승인",
      cancelButtonText: "취소",

      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const freeAt = calculateFreeAt(data.date, userMembershipId);
        const currentDate = new Date();
        const daysPastFreeAt = differenceInCalendarDays(currentDate, freeAt);

        let warehouseValue = "free";
        if (daysPastFreeAt > 0) {
          warehouseValue = `free +${daysPastFreeAt} days`;
        }

        const formattedDate = format(data.date, "yyyy-MM-dd'T'HH:mm:ss");
        const formattedFreeAt = format(freeAt, "yyyy-MM-dd'T'HH:mm:ss");

        const formData = new FormData();
        formData.append("userId", data.userId);
        formData.append("tracking", data.tracking);
        formData.append("date", formattedDate);
        formData.append("Extra", data.Extra.toString());
        formData.append("warehouseId", data.warehouseId);
        formData.append("Addstatus", data.Addstatus.toString());
        formData.append("warehouse", warehouseValue);
        formData.append("freeAt", formattedFreeAt);
        formData.append("status", "2");

        try {
          setIsLoading(true);
          if (file) {
            formData.append("file", file);
          }
          const result = await reqInboundsShip(formData);
          setIsLoading(false);

          if (result && result.data) {
            await updateOrderStatus(data.tracking, 7);
            const currentOrder = orderDetails.find(
              (o) => o.productId === data.tracking
            );

            if (currentOrder) {
              const updatePayload = {
                order_id: currentOrder.orderId,
              };
              await reqShopUpdate(updatePayload);
            }

            try {
              const priceInUSD = parseFloat(
                (data.price / fixedExchangeRate).toFixed(2)
              );
              const buyResult = await reqShipProductBuy({
                name: data.name,
                category: data.category,
                quantity: data.quantity,
                price: priceInUSD,
                trackingNumber: data.tracking,
              });

              if (buyResult && buyResult.data) {
                setData({
                  userId: "",
                  tracking: "",
                  date: new Date(),
                  Extra: 0,
                  warehouseId: "",
                  file: "",
                  Addstatus: 0,
                  warehouse: "",
                  name: "",
                  price: 0,
                  quantity: 0,
                  category: 0,
                });

                const alarmPayload = {
                  userId: data.userId,
                  read: 0,
                  content:
                    "New products have arrived. Please input the product details.",
                  sender: admin.name,
                };
                await reqSendAlarm(alarmPayload);

                const badgePayload = {
                  userId: user.id,
                  badgeName: USER_BADGE.ALL_PRODUCT,
                };
                await reqAddBadge(badgePayload);

                await updateOrderStatus(data.tracking, 7);
                fetchOrderDetails();

                handlePrintSelected([
                  {
                    id: data.tracking,
                    tracking: data.tracking,
                    userId: data.userId,
                    user: {
                      name:
                        orderDetails.find(
                          (order) => order.productId === data.tracking
                        )?.user.name || "",
                    },
                    warehouseId: data.warehouseId,
                  },
                ]);

                dispatch(
                  modalOpen({
                    modalType: MODAL_TYPE.DATA_SAVE,
                    title: "입고 등록 완료",
                  })
                );
                window.location.reload();
              }
            } catch (error) {
              console.error("Error during the buy process", error);
            }
          }
        } catch (error) {
          console.error("Error submitting inbound ship data", error);
        }
      }
    });
  };

  const handleKeyPress = (event: any) => {
    if (event.key === " ") {
      event.preventDefault();
    }
  };

  const handlePrintSelected = (selectedRecordsToPrint: any) => {
    const printWindow = window.open("", "", "width=800,height=600");
    if (!printWindow) {
      console.error(
        "Failed to open the print window. Check your browser popup settings."
      );
      return;
    }

    printWindow.document.write("<html><head><title>Print</title><style>");
    printWindow.document.write("@page { size: 8cm 6cm; margin: 0; }");
    printWindow.document.write(
      "body { margin: 0; display: flex; flex-direction: column; align-items: center; }"
    );
    printWindow.document.write(
      ".page { display: flex; justify-content: center; align-items: center; width: 8cm; height: 6cm; page-break-after: always; }"
    );
    printWindow.document.write(
      ".print-container { width: 8cm; height: 6cm; display: flex; flex-direction: column; justify-content: center; align-items: center; border: 1px solid #000; padding: 10px; box-sizing: border-box; }"
    );
    printWindow.document.write("svg { width: 6cm; height: 2cm; }");
    printWindow.document.write("p { margin: 0; padding: 2px 0; text-align: center; }");
    printWindow.document.write("</style></head><body>");

    selectedRecordsToPrint.forEach((record: any) => {
      const svgBarcode = `<svg id="barcode-${record.id}"></svg>`;

      printWindow.document.write('<div class="page">');
      printWindow.document.write('<div class="print-container">');
      printWindow.document.write(svgBarcode);
      printWindow.document.write(`<p>${record.tracking}</p>`);
      printWindow.document.write(`<p>${record.userId}</p>`);
      printWindow.document.write(`<p>${record.user.name}</p>`);
      printWindow.document.write(
        `<p>${record.warehouseId}/${record.tracking.slice(-8)}</p>`
      );
      printWindow.document.write("</div>");
      printWindow.document.write("</div>");
    });

    printWindow.document.write("</body></html>");
    printWindow.document.close();

    selectedRecordsToPrint.forEach((record: any) => {
      const barcodeElement = printWindow.document.getElementById(
        `barcode-${record.id}`
      );
      JsBarcode(barcodeElement, record.tracking, {
        format: "CODE128",
        displayValue: false,
        width: 1,
        height: 30,
        margin: 0,
      });
    });

    printWindow.focus();
    printWindow.print();
    printWindow.close();
  };

  const handleExportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(
      allOrders.map((order, index) => ({
        NO: index + 1,
        주문번호: order.productId,
        주문날짜: format(new Date(order.createdAt), "yyyy-MM-dd"),
        카테고리: CODE_SHOPPING_ORDER_ITEMS[order.category] || "Unknown Category",
        상품명: order.name,
        수량: order.quantity,
        금액: order.price,
        창고위치: order.user.latestWarehouseId || "위치 정보 없음",
      }))
    );
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "All Orders");
    XLSX.writeFile(wb, "All_Orders.xlsx");
  };

  return (
    <>
      {isLoading ? (
        <LoadingSection>
          <img src="/resources/img/loading.gif" alt="로딩중" />
        </LoadingSection>
      ) : null}

      <AdminHeadTitle
        subtitle1="창고 관리"
        subtitle2="입고 등록 (구매대행)"
        title="입고 등록 (구매대행)"
      />

      <main className="mt-3">
        <div className="row">
          <div className="col-4">
            <h5>송장 정보</h5>
            <div className="input-group mb-3">
              <span className="input-group-text" id="basic-addon1">
                스페이스 코드
              </span>
              <input
                type="text"
                className="form-control"
                placeholder="스페이스 코드 입력"
                value={data.userId}
                onKeyPress={handleKeyPress}
                onChange={async (e) => {
                  const newUserId = e.target.value.toUpperCase().trim();
                  setData({ ...data, userId: newUserId });
                  setIsValid({ ...isValid, userId: true });
                  setUserId(newUserId);

                  if (newUserId) {
                    try {
                      const response = await reqGetUserById({ id: newUserId });
                      if (
                        response &&
                        response.data &&
                        response.data.latestWarehouseId
                      ) {
                        setLatestWarehouseId(response.data.latestWarehouseId);
                        setData((prevData) => ({
                          ...prevData,
                          warehouseId: response.data.latestWarehouseId,
                        }));
                      } else {
                        setLatestWarehouseId("");
                        setData((prevData) => ({
                          ...prevData,
                          warehouseId: "",
                        }));
                      }
                    } catch (error) {
                      console.error("창고 위치 조회 실패:", error);
                      setLatestWarehouseId("");
                      setData((prevData) => ({ ...prevData, warehouseId: "" }));
                    }
                  }
                }}
              />
            </div>
            <div className="input-group mb-3">
              <span className="input-group-text" id="basic-addon1">
                송장번호
              </span>
              <input
                type="text"
                className="form-control"
                placeholder="송장번호"
                value={data.tracking}
                onKeyPress={handleKeyPress}
                onChange={(e) => {
                  setData({ ...data, tracking: e.target.value.toUpperCase() });
                  setIsValid({ ...isValid, tracking: true });
                }}
              />
            </div>
            <div className="input-group mb-3">
              <span className="input-group-text" id="basic-addon1">
                입고날짜
              </span>
              <input
                type="datetime-local"
                className="form-control"
                placeholder="입고 날짜 선택"
                value={FormatTime(data.date)}
                onChange={(e) => {
                  setData({ ...data, date: new Date(e.target.value) });
                }}
              />
            </div>
            <div className="input-group mb-3">
              <span className="input-group-text" id="basic-addon1">
                창고 위치
              </span>
              <input
                type="text"
                className="form-control"
                aria-describedby="basic-addon1"
                value={data.warehouseId}
                onChange={(e) => {
                  setData({ ...data, warehouseId: e.target.value });
                  setIsValid({ ...isValid, warehouseId: true });
                }}
                readOnly
              />
            </div>
            <div className="input-group">
              <input type="file" onChange={handleFileChange} />
              <button
                className="btn btn-outline-secondary"
                type="button"
                id="inputGroupFileAddon04"
              >
                사진 촬영
              </button>
            </div>
            <div className="mt-3 text-end">
              <button
                className="btn btn-success w-50"
                onClick={handleFormSubmit}
              >
                입고등록
              </button>
              {imageUrl && (
                <ImageTmpSection>
                  <img src={imageUrl} alt="Preview" />
                </ImageTmpSection>
              )}
            </div>
          </div>
          <div className="col-8">
            <h5>
              모든 주문 목록
              <button
                className="btn btn-outline-secondary ms-3"
                onClick={handleExportToExcel}
              >
                엑셀 다운로드
              </button>
            </h5>
            <SearchCol>
              <SearchRow>
                <input
                  type="text"
                  className="form-control input"
                  placeholder="스페이스 코드 입력"
                  value={userId}
                  onKeyPress={handleKeyPress}
                  onChange={(e) => setUserId(e.target.value.toUpperCase())}
                />
                <button
                  className="btn btn-outline-secondary"
                  type="button"
                  onClick={() => fetchOrderDetails()}
                >
                  조회
                </button>
              </SearchRow>

              <SearchRow>
                <input
                  type="text"
                  className="form-control input"
                  placeholder="송장번호 입력"
                  value={productId}
                  onKeyPress={handleKeyPress}
                  onChange={(e) => setProductId(e.target.value.toUpperCase())}
                />
                <button
                  className="btn btn-outline-secondary"
                  type="button"
                  onClick={fetchProductDetails}
                >
                  조회
                </button>
              </SearchRow>
            </SearchCol>

            <Header>
              <div className="check-box">
                <input type="checkbox" />
              </div>
              <div>주문번호</div>
              <div>주문날짜</div>
              <div>구분</div>
              <div>상품명</div>
              <div>수량</div>
              <div>금액</div>
              <div>창고 위치</div>
            </Header>
            {orderList.map((order, index) => (
              <Body key={order.orderId}>
                <div className="check-box">
                  <input
                    type="checkbox"
                    checked={selectedOrderId === order.productId}
                    onChange={() => handleCheckboxChange(order)}
                  />
                </div>
                <div>{order.productId}</div>
                <div>{format(new Date(order.createdAt), "yyyy-MM-dd")}</div>
                <div>
                  {CODE_SHOPPING_ORDER_ITEMS[order.category] ||
                    "Unknown Category"}
                </div>
                <div>{order.name}</div>
                <div>{order.quantity}</div>
                <div>{order.price}</div>
                <div>{order.user.latestWarehouseId || "위치 정보 없음"}</div>
              </Body>
            ))}

            <Gap />

            <h5>모든 주문 목록</h5>
            {allOrders.map((order, index) => (
              <Body key={order.orderId}>
                <div className="check-box">
                  <input
                    type="checkbox"
                    checked={selectedOrderId === order.productId}
                    onChange={() => handleCheckboxChange(order)}
                  />
                </div>
                <div>{index + 1}</div>
                <div>{order.productId}</div>
                <div>{format(new Date(order.createdAt), "yyyy-MM-dd")}</div>
                <div>
                  {CODE_SHOPPING_ORDER_ITEMS[order.category] ||
                    "Unknown Category"}
                </div>
                <div>{order.name}</div>
                <div>{order.quantity}</div>
                <div>{order.price}</div>
                <div>{order.user.latestWarehouseId || "위치 정보 없음"}</div>
              </Body>
            ))}

          </div>
        </div>
      </main>
    </>
  );
}

const Gap = styled.div`
  margin: 5rem 0;
`;

const LoadingSection = styled.div`
  position: absolute;
  z-index: 1000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  & > img {
    width: 100%;
  }
`;
const Body = styled.div`
  display: flex;
  flex-direction: row;
  text-align: center;
  & > div {
    flex: 1 1 0;
  }
  .check-box {
    flex: 0.2 1 0;
  }
`;
const Header = styled.div`
  display: flex;
  flex-direction: row;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1rem;
  & > div {
    flex: 1 1 0;
  }
  .check-box {
    flex: 0.2 1 0;
  }
`;

const SearchCol = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 1.6rem 0;
`;
const SearchRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;

  .input {
    flex: 4 1 0;
  }
  .btn {
    flex: 1 1 0;
  }
`;

const ImageTmpSection = styled.div`
  display: flex;
  flex-direction: row;
  text-align: center;
  align-items: center;
  margin-top: 3rem;
  & > img {
    max-width: 30rem;
    max-height: 30rem;
    object-fit: scale-down;
  }
`;
