import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Swal from "sweetalert2";
import { MEMBERSHIP } from "../../common/constants";
import Icon from "../../components/Icon";
import TitleCustomer from "../../components/TitleCustomer";
import { reqAllBadge } from "../../requests/badge";
import { reqGetUserById } from "../../requests/user";
import CShopSendorder from "./Shop/CShopSendorder";

export default function CDashBoard() {
  const nav = useNavigate();
  const user = useSelector((state: any) => state.user);

  const Toast = Swal.mixin({
    toast: true,
    position: "center",
    showConfirmButton: false,
    timer: 1000,
    timerProgressBar: true,
    didOpen: (toast: any) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  // Badge
  const [badgeList, setBadgeList] = useState<any>([]);

  const fetchBadgeList = async () => {
    const res = await reqAllBadge({ userId: user.id });
    setBadgeList(res.data);
    return res.data;
  };

  useEffect(() => {
    fetchBadgeList();
  }, [user]);

  // user
  const [userData, setUserData] = useState<any>({});

  const fetchUserData = async () => {
    const res = await reqGetUserById({ id: user.id });
    setUserData(res.data);
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  const [createOrderModal, setCreateOrderModal] = useState<boolean>(false);

  const MobileView = () => {
    return (
      <MobileWrapper>
        <MService>
          <TitleCustomer
            title={`WELCOME BACK,\n${user.name}`}
            style={{ fontSize: "30px" }}
          />
          <MSpaceCodeTextRow>
            <span>Your Space Code: </span>
            <MSpaceCodeText>{user.id}</MSpaceCodeText>
          </MSpaceCodeTextRow>

          <MMembershipWrapper
            onClick={(e) => {
              nav("/customer/membership");
            }}
            color={MEMBERSHIP[userData.membershipId]}
          >
            <MIconWrapper color={`${MEMBERSHIP[userData.membershipId]}`}>
              <Icon icon="award" color="#fbf7f0" />
            </MIconWrapper>
            <P>{MEMBERSHIP[userData.membershipId]} Membership</P>
          </MMembershipWrapper>

          <div className="service-item" style={{ marginBottom: "2rem" }}>
            <MLabel className="title">Your Korean Address</MLabel>

            <div className="service-address">
              <Col>
                <MLabel>Address</MLabel>
                <P>
                  부산광역시 수영구 좌수영로 210번길 36 1층&nbsp;
                  <label className="text-decoration-underline spacecode">
                    {user.id}
                  </label>
                  <Icon
                    icon="copy"
                    style={{
                      marginLeft: "0.4rem",
                      color: "var(--color-main-blue)",
                    }}
                    onClick={() => {
                      navigator.clipboard.writeText(
                        `부산광역시 수영구 좌수영로 210번길 36 1층 ${user.id}`
                      );
                      Toast.fire({
                        icon: "success",
                        title: "Copied to clipboard",
                      });
                    }}
                  />
                </P>
              </Col>
              <Row>
                <MLabel>Zip Code</MLabel>
                <P>48213</P>
              </Row>
              <Row>
                <MLabel>Phone</MLabel>
                <P>01027169020</P>
              </Row>
              <Row>
                <MLabel>To</MLabel>
                <P>Your Name</P>
              </Row>
              <hr />
              <div>
                <Col>
                  <MLabel>Address</MLabel>
                  <P>
                    Suyeong-gu, Busan, Jwasuyeong-ro 210beon-gil 36 1st
                    floor&nbsp;
                    <label className="text-decoration-underline spacecode">
                      {user.id}
                    </label>
                    <Icon
                      icon="copy"
                      style={{
                        marginLeft: "0.4rem",
                        color: "var(--color-main-blue)",
                      }}
                      onClick={() => {
                        navigator.clipboard.writeText(
                          `Suyeong-gu, Busan, Jwasuyeong-ro 210beon-gil 36 1st floor ${user.id}`
                        );
                        Toast.fire({
                          icon: "success",
                          title: "Copied to clipboard",
                        });
                      }}
                    />
                  </P>
                </Col>
                <Row>
                  <MLabel>Zip Code</MLabel>
                  <P>48213</P>
                </Row>
                <Row>
                  <MLabel>Phone</MLabel>
                  <P>+82 1027169020</P>
                </Row>
                <Row>
                  <MLabel>To</MLabel>
                  <P>Your Name</P>
                </Row>
              </div>
            </div>
          </div>

          <div className="service-item mt-4" translate="no">
            <Label className="title">My Storage</Label>
            <ul>
              <span
                onClick={(e) => {
                  nav("/customer/warehouse");
                }}
              >
                <span>
                  <MBadgeBox className="f-row">
                    <Icon icon="box-seam" fontSize="40px" />
                    All My Packages
                    {badgeList.includes("ALL_PRODUCT") && (
                      <MBadge>
                        <Icon
                          icon="exclamation-circle-fill"
                          fontSize="1.8rem"
                          color="var(--color-main-red)"
                        />
                      </MBadge>
                    )}
                  </MBadgeBox>
                </span>
              </span>

              <span
                onClick={(e) => {
                  nav("/customer/warehouse/modify");
                }}
              >
                <span>
                  <MBadgeBox className="f-row">
                    <Icon icon="clipboard" fontSize="40px" />
                    My Package's Information
                    {badgeList.includes("MODIFY_PRODUCT") && (
                      <MBadge>
                        <Icon
                          icon="exclamation-circle-fill"
                          fontSize="1.8rem"
                          color="var(--color-main-red)"
                        />
                      </MBadge>
                    )}
                  </MBadgeBox>
                </span>
              </span>

              <span
                onClick={(e) => {
                  nav("/customer/warehouse/lost");
                }}
              >
                <span>
                  <MBadgeBox>
                    <Icon icon="exclamation-diamond" fontSize="40px" />
                    Find My Tracking Number
                    {badgeList.includes("LOST_PRODUCT") && (
                      <MBadge>
                        <Icon
                          icon="exclamation-circle-fill"
                          fontSize="1.8rem"
                          color="var(--color-main-red)"
                        />
                      </MBadge>
                    )}
                  </MBadgeBox>
                </span>
              </span>

              <span
                onClick={(e) => {
                  nav("/customer/warehouse/checkcontents");
                }}
              >
                <span>
                  <MBadgeBox>
                    <Icon icon="check" fontSize="40px" />
                    My Checked Items
                    {badgeList.includes("CHECK_CONTENT") && (
                      <MBadge>
                        <Icon
                          icon="exclamation-circle-fill"
                          fontSize="1.8rem"
                          color="var(--color-main-red)"
                        />
                      </MBadge>
                    )}
                  </MBadgeBox>
                </span>
              </span>
            </ul>
          </div>

          <div className="service-item">
            <Label className="title">My Custom Orders</Label>
            <ul>
              <span onClick={() => setCreateOrderModal(true)}>
                <span>
                  <MBadgeBox>
                    <Icon icon="pen" fontSize="40px" />
                    Create Order Request
                  </MBadgeBox>
                </span>
              </span>

              <span
                onClick={(e) => {
                  nav("/customer/shop/order/1");
                }}
              >
                <span>
                  <MBadgeBox>
                    <Icon icon="card-text" fontSize="40px" />
                    Request Sent
                  </MBadgeBox>
                </span>
              </span>

              <span
                onClick={(e) => {
                  nav("/customer/shop/pay");
                }}
              >
                <span>
                  <MBadgeBox>
                    <Icon icon="credit-card" fontSize="40px" />
                    Pending Payments
                    {badgeList.includes("SHOP_PAYMENT_WAITING") && (
                      <MBadge
                        style={{
                          position: "absolute",
                          top: "-22%",
                          right: "-2%",
                        }}
                      >
                        <Icon
                          icon="exclamation-circle-fill"
                          fontSize="1.8rem"
                          color="var(--color-main-red)"
                        />
                      </MBadge>
                    )}
                  </MBadgeBox>
                </span>
              </span>

              <span
                onClick={(e) => {
                  nav("/customer/shop/order");
                }}
              >
                <span>
                  <MBadgeBox>
                    <Icon icon="bag-heart" fontSize="40px" />
                    My Orders
                  </MBadgeBox>
                </span>
              </span>
            </ul>
          </div>

          <div className="service-item">
            <Label className="title">My Shipments</Label>
            <ul>
              <span
                onClick={(e) => {
                  nav("/customer/ship/sendorder");
                }}
              >
                <span>
                  <MBadgeBox>
                    <Icon icon="pen" fontSize="40px" />
                    Create Shipping Request
                  </MBadgeBox>
                </span>
              </span>

              <span
                onClick={(e) => {
                  nav("/customer/ship/details/1");
                }}
              >
                <span>
                  <MBadgeBox>
                    <Icon icon="card-text" fontSize="40px" />
                    Request Sent
                  </MBadgeBox>
                </span>
              </span>

              <span
                onClick={(e) => {
                  nav("/customer/ship/pay");
                }}
              >
                <span>
                  <MBadgeBox>
                    <Icon icon="credit-card" fontSize="40px" />
                    Pending Payments
                    {badgeList.includes("SHIP_PAYMENT_WAITING") && (
                      <MBadge>
                        <Icon
                          icon="exclamation-circle-fill"
                          fontSize="1.8rem"
                          color="var(--color-main-red)"
                        />
                      </MBadge>
                    )}
                  </MBadgeBox>
                </span>
              </span>

              <span
                onClick={(e) => {
                  nav("/customer/ship/details");
                }}
              >
                <span>
                  <MBadgeBox>
                    <Icon icon="airplane" fontSize="40px" />
                    My Shipments
                  </MBadgeBox>
                </span>
              </span>
            </ul>
          </div>

          <div>
            <Label className="title">Banner</Label>
            <img
              id="banner-section"
              src="/resources/img/dashboard-banner.png"
              alt="Banner"
              onClick={() =>
                window.open(
                  "https://ontactworld.com/",
                  "_blank",
                  "noopener, noreferrer"
                )
              }
            />
          </div>
        </MService>
      </MobileWrapper>
    );
  };

  return (
    <>
      {createOrderModal && (
        <CShopSendorder
          closeCreateOrderModal={() => setCreateOrderModal(false)}
        />
      )}

      {isMobile ? (
        <MobileView />
      ) : (
        <div>
          <Main className="d-flex justify-contents-space-between">
            <Service>
              <TitleCustomer
                title={`WELCOME BACK, ${user.name}`}
                style={{ fontSize: "50px", fontWeight: "normal" }}
              />
              <SpaceCodeTextRow>
                <div>Your Space Code: </div>
                <SpaceCodeText>{user.id}</SpaceCodeText>
              </SpaceCodeTextRow>

              <MembershipWrapper
                onClick={(e) => {
                  nav("/customer/membership");
                }}
                style={{ cursor: "pointer" }}
                color={MEMBERSHIP[userData.membershipId]}
              >
                <IconWrapper color={`${MEMBERSHIP[userData.membershipId]}`}>
                  <Icon icon="award" color="#fbf7f0" />
                </IconWrapper>
                <div>
                  {MEMBERSHIP[userData.membershipId]} Membership
                  {userData.membershipEndAt === null
                    ? ""
                    : ` - until ${new Date(
                        userData.membershipEndAt
                      ).toLocaleString("ko-KR", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                        hour12: false,
                      })}`}
                </div>
              </MembershipWrapper>

              <div className="service-item">
                <div className="title">Your Korean Address</div>
                <div className="service-address">
                  <table>
                    <tbody>
                      <tr>
                        <th>Address</th>
                        <td>
                          부산광역시 수영구 좌수영로 210번길 36 1층&nbsp;
                          <label className="text-decoration-underline spacecode">
                            {user.id}
                          </label>
                          <Icon
                            icon="copy"
                            style={{ color: "var(--color-main-blue)" }}
                            onClick={() => {
                              navigator.clipboard.writeText(
                                `부산광역시 수영구 좌수영로 210번길 36 1층 ${user.id}`
                              );
                              Toast.fire({
                                icon: "success",
                                title: "Copied to clipboard",
                              });
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th>Zip Code</th>
                        <td>48213</td>
                      </tr>
                      <tr>
                        <th>Phone</th>
                        <td>01027169020</td>
                      </tr>
                      <tr>
                        <th>To</th>
                        <td>Your Name</td>
                      </tr>
                    </tbody>
                  </table>
                  <hr />
                  <div>
                    <table>
                      <tbody>
                        <tr>
                          <th>Address</th>
                          <td>
                            Suyeong-gu, Busan, Jwasuyeong-ro 210beon-gil 36 1st
                            floor&nbsp;
                            <label className="text-decoration-underline spacecode">
                              {user.id}
                            </label>
                            <Icon
                              icon="copy"
                              style={{ color: "var(--color-main-blue)" }}
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  `Suyeong-gu, Busan, Jwasuyeong-ro 210beon-gil 36 1st floor ${user.id}`
                                );
                                Toast.fire({
                                  icon: "success",
                                  title: "Copied to clipboard",
                                });
                              }}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th>Zip Code</th>
                          <td>48213</td>
                        </tr>
                        <tr>
                          <th>Phone</th>
                          <td>+82 1027169020</td>
                        </tr>
                        <tr>
                          <th>To</th>
                          <td>Your Name</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div>
                <img
                  id="banner-section"
                  src="/resources/img/dashboard-banner.png"
                  alt="Banner"
                  onClick={() =>
                    window.open(
                      "https://ontactworld.com/",
                      "_blank",
                      "noopener, noreferrer"
                    )
                  }
                />
              </div>
            </Service>

            <RightSide>
              <div className="service-item mt-4">
                <div className="title">My Storage</div>
                <ul>
                  <li
                    onClick={(e) => {
                      nav("/customer/warehouse");
                    }}
                  >
                    <BadgeBox>
                      {badgeList.includes("ALL_PRODUCT") && (
                        <Badge>
                          <Icon
                            icon="exclamation-circle-fill"
                            fontSize="1.8rem"
                            color="var(--color-main-red)"
                          />
                        </Badge>
                      )}
                      <Icon icon="box-seam" fontSize="40px" />
                      All My Packages
                    </BadgeBox>
                  </li>
                  <li
                    onClick={(e) => {
                      nav("/customer/warehouse/modify");
                    }}
                  >
                    <BadgeBox>
                      {badgeList.includes("MODIFY_PRODUCT") && (
                        <Badge>
                          <Icon
                            icon="exclamation-circle-fill"
                            fontSize="1.8rem"
                            color="var(--color-main-red)"
                          />
                        </Badge>
                      )}
                      <Icon icon="clipboard" fontSize="40px" />
                      My Package's Information
                    </BadgeBox>
                  </li>
                  <li
                    onClick={(e) => {
                      nav("/customer/warehouse/lost");
                    }}
                  >
                    <BadgeBox>
                      {badgeList.includes("LOST_PRODUCT") && (
                        <Badge>
                          <Icon
                            icon="exclamation-circle-fill"
                            fontSize="1.8rem"
                            color="var(--color-main-red)"
                          />
                        </Badge>
                      )}
                      <Icon icon="exclamation-diamond" fontSize="40px" />
                      Find My Tracking Number
                    </BadgeBox>
                  </li>
                  <li
                    onClick={(e) => {
                      nav("/customer/warehouse/checkcontents");
                    }}
                  >
                    <BadgeBox>
                      {badgeList.includes("CHECK_CONTENT") && (
                        <Badge>
                          <Icon
                            icon="exclamation-circle-fill"
                            fontSize="1.8rem"
                            color="var(--color-main-red)"
                          />
                        </Badge>
                      )}
                      <Icon icon="check" fontSize="40px" />
                      My Checked Items
                    </BadgeBox>
                  </li>
                </ul>
              </div>

              <div className="service-item">
                <div className="title">My Custom Orders</div>
                <ul>
                  <li onClick={() => setCreateOrderModal(true)}>
                    <div>
                      <Icon icon="pen" fontSize="40px" />
                      Create Order Request
                    </div>
                  </li>
                  <li
                    onClick={(e) => {
                      nav("/customer/shop/order/1");
                    }}
                  >
                    <div>
                      <Icon icon="card-text" fontSize="40px" />
                      Request Sent
                    </div>
                  </li>
                  <li
                    onClick={(e) => {
                      nav("/customer/shop/pay");
                    }}
                  >
                    <BadgeBox>
                      {badgeList.includes("SHOP_PAYMENT_WAITING") && (
                        <Badge>
                          <Icon
                            icon="exclamation-circle-fill"
                            fontSize="1.8rem"
                            color="var(--color-main-red)"
                          />
                        </Badge>
                      )}
                      <Icon icon="credit-card" fontSize="40px" />
                      Pending Payments
                    </BadgeBox>
                  </li>
                  <li
                    onClick={(e) => {
                      nav("/customer/shop/order");
                    }}
                  >
                    <div>
                      <Icon icon="bag-heart" fontSize="40px" />
                      My Orders
                    </div>
                  </li>
                </ul>
              </div>

              <div className="service-item">
                <div className="title">My Shipments</div>
                <ul>
                  <li
                    onClick={(e) => {
                      nav("/customer/ship/sendorder");
                    }}
                  >
                    <div>
                      <Icon icon="pen" fontSize="40px" />
                      Create Shipping Request
                    </div>
                  </li>
                  <li
                    onClick={(e) => {
                      nav("/customer/ship/details/1");
                    }}
                  >
                    <div>
                      <Icon icon="card-text" fontSize="40px" />
                      Request Sent
                    </div>
                  </li>
                  <li
                    onClick={(e) => {
                      nav("/customer/ship/pay");
                    }}
                  >
                    <BadgeBox>
                      {badgeList.includes("SHIP_PAYMENT_WAITING") && (
                        <Badge>
                          <Icon
                            icon="exclamation-circle-fill"
                            fontSize="1.8rem"
                            color="var(--color-main-red)"
                          />
                        </Badge>
                      )}
                      <Icon icon="credit-card" fontSize="40px" />
                      Pending Payments
                    </BadgeBox>
                  </li>
                  <li
                    onClick={(e) => {
                      nav("/customer/ship/details");
                    }}
                  >
                    <div>
                      <Icon icon="airplane" fontSize="40px" />
                      My Shipments
                    </div>
                  </li>
                </ul>
              </div>
            </RightSide>
          </Main>
        </div>
      )}
    </>
  );
}

const MBadgeBox = styled.li`
  position: relative;
  gap: 1rem;
  display: flex;
  justify-content: left;

  & > i {
    margin-left: 1rem;
  }
`;
const BadgeBox = styled.div`
  position: relative;
`;

const Badge = styled.div`
  position: absolute;
  top: -22%;
  right: -2%;
`;
const MBadge = styled.div`
  position: absolute;
  top: 0%;
  right: 2%;
`;

const MService = styled.div`
  padding: 2rem 1.4rem 0 1.4rem;

  .title {
    color: var(--color-main-blue-hover);
    font-size: 1.2rem;
  }

  #banner-section {
    width: 100%;
    margin: 0 0 6rem 0;

    border-radius: 1rem;
    cursor: pointer;

    box-shadow: 0 8px 10px rgba(0, 0, 0, 0.15), 0 5px 5px rgba(0, 0, 0, 0.15);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    &:hover {
      box-shadow: 0 12px 15px rgba(0, 0, 0, 0.2), 0 11px 11px rgba(0, 0, 0, 0.2);
    }
  }

  .service-item li:hover {
    box-shadow: 0px 1px 10px #00000048;
    color: var(--color-main-blue);
  }

  .service-item li > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  ul {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 0.7rem;
    margin-bottom: 1rem;
    padding: 0px;
    display: flex;
    justify-content: space-between;
    gap: 0.4rem;

    @media (max-width: 1279px) {
    }

    @media (max-width: 767px) {
    }

    @media (max-width: 575px) {
    }

    li {
      cursor: pointer;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      border: 1px solid #eee;
      border-radius: 1rem;
      text-align: center;
      font-size: 1.1rem;
      overflow-y: visible;
      padding: 0.4rem !important;

      position: relative;
      box-shadow: 0px 1px 10px #00000012;

      transition-duration: 0.3s;

      &:hover {
        box-shadow: 0px 1px 10px #00000048;
        color: var(--color-main-blue-hover);
      }

      i {
        display: block;
        margin-right: 4px;
      }

      .alarm {
        display: block;
        background-color: var(--color-main-blue);
        color: white;
        border-radius: 5px;
        width: 30px;
        height: 30px;
        text-align: center;
        line-height: 30px;
        font-weight: bold;
      }
    }
  }

  .service-address {
    border: 1px solid lightgray;
    border-radius: 0.5rem;
    padding: 1rem;
    margin-top: 0.7rem;
  }
`;

interface MembershipColor {
  color: string;
}

const MMembershipWrapper = styled.div<MembershipColor>`
  padding: 1rem;
  border-radius: 1rem;
  font-size: 1.3rem;

  border: 2px solid
    ${(props) =>
      props.color === "Green"
        ? "var(--color-green)"
        : props.color === "Blue"
        ? "var(--color-main-blue)"
        : "var(--color-main-red)"};
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 1rem 0 1.5rem 0;

  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

  &:hover {
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15), 0 5px 5px rgba(0, 0, 0, 0.15);
  }
`;

const MobileWrapper = styled.div`
  margin: 0;
  display: flex;
  flex-direction: column;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
  align-items: center;
`;
const Col = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  font-weight: bold;
  margin: 1.4rem 0 0.8rem 0;
`;
const MLabel = styled.label`
  font-weight: bold;
  margin: 0.4rem 0 0.4rem 0;
`;
const P = styled.p`
  margin: 0.4rem 0 0.4rem 0;
`;

const SpaceCodeTextRow = styled.div`
  margin: 1.9rem 0 2rem 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 28px;
  background: linear-gradient(to right, #4b84da, #fdc5be);
  border-radius: 0.6rem;
  padding: 1rem;
  color: var(--color-white);

  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.15), 0 5px 5px rgba(0, 0, 0, 0.15);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
`;
const MSpaceCodeTextRow = styled.div`
  margin: 1rem 0 1rem 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1.3rem;
  background: linear-gradient(to right, #4b84da, #fdc5be);
  border-radius: 0.6rem;
  padding: 1rem;
  color: var(--color-white);

  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.15), 0 5px 5px rgba(0, 0, 0, 0.15);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
`;
const SpaceCodeText = styled.div`
  font-weight: bold;
  margin-left: 2rem;
`;
const MSpaceCodeText = styled.div`
  font-weight: bold;
  margin-left: 0.4rem;
`;
const Main = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;

  .title {
    font-size: 20px;
    padding-left: 5px;
    color: var(--color-main-blue-hover);
    font-weight: bold;
    margin: 1.4rem 0 1rem 0;
  }
`;

const MembershipWrapper = styled.div<MembershipColor>`
  padding: 1rem;
  border-radius: 1rem;
  font-size: 1.3rem;

  border: 2px solid
    ${(props) =>
      props.color === "Green"
        ? "var(--color-green)"
        : props.color === "Blue"
        ? "var(--color-main-blue)"
        : "var(--color-main-red)"};
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1rem;

  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

  &:hover {
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15), 0 5px 5px rgba(0, 0, 0, 0.15);
  }
`;

const Service = styled.div`
  padding: 30px;
  width: 767px;

  .rightside-membership {
    border: 2px solid var(--color-main-blue);
    width: 100%;
    padding: 1rem;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 1rem;

    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

    &:hover {
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15), 0 5px 5px rgba(0, 0, 0, 0.15);
    }

    @media (max-width: 1279px) {
      text-align: center;
    }
    @media (max-width: 767px) {
      text-align: center;
    }

    @media (max-width: 575px) {
      text-align: center;
    }

    @media (max-width: 767px) {
      &:active {
        background-color: #ffccbc;
      }
    }

    div {
      font-size: 1.4rem;
      margin-right: 1rem;
    }
  }

  .service-address {
    width: 100%;
    border: 1px solid #eee;
    border-radius: 3px;

    padding: 20px;
    position: relative;
    transition-duration: 0.3s;

    th {
      display: inline-block;
      width: 120px;
      color: var(--color-black);
      font-weight: normal;
    }

    i {
      margin-left: 10px;
      cursor: pointer;
    }
  }

  #banner-section {
    width: 100%;
    margin-top: 1rem;

    border-radius: 1rem;
    cursor: pointer;

    box-shadow: 0 8px 10px rgba(0, 0, 0, 0.15), 0 5px 5px rgba(0, 0, 0, 0.15);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    &:hover {
      box-shadow: 0 12px 15px rgba(0, 0, 0, 0.2), 0 11px 11px rgba(0, 0, 0, 0.2);
    }
  }
`;

interface IconProps {
  color: string;
}

const IconWrapper = styled.div<IconProps>`
  background-color: ${(props) => {
    switch (props.color) {
      case "Red":
        return "var(--color-main-red)";
      case "Green":
        return "var(--color-green)";
      case "Blue":
        return "var(--color-main-blue)";
      default:
        return "#ffffff";
    }
  }};
  padding: 0.6rem;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0;
  vertical-align: middle;
  margin-right: 2rem;
`;

const MIconWrapper = styled(IconWrapper)`
  margin-right: 1rem;
`;

const RightSide = styled.div`
  padding: 24px;
  width: calc(100% - 767px);

  .service-item li {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    max-width: 100%;
    width: 10vw;
    height: 200px;
    border: 1px solid #eee;
    border-radius: 3px;
    text-align: center;
    font-size: 1.1rem;
    padding: 20px;
    box-shadow: 0px 1px 10px #00000012;
    transition-duration: 0.3s;
  }

  .service-item li:hover {
    box-shadow: 0px 1px 10px #00000048;
    color: var(--color-main-blue);
  }

  .service-item li > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  ul {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 40px;
    padding: 0px;
    display: flex;
    justify-content: space-between;

    li {
      cursor: pointer;
      max-width: 100%;
      width: 165px;
      height: 185px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 10px;
      align-items: center;
      border: 1px solid #eee;
      border-radius: 3px;
      text-align: center;
      font-size: 1.1rem;
      overflow-y: visible;
      padding: 20px;
      position: relative;
      box-shadow: 0px 1px 10px #00000012;
      transition-duration: 0.3s;

      &:hover {
        box-shadow: 0px 1px 10px #00000048;
        color: var(--color-main-blue-hover);
      }

      i {
        display: block;
        margin-right: 4px;
      }

      .alarm {
        display: block;
        background-color: var(--color-main-blue);
        color: white;
        border-radius: 5px;
        width: 30px;
        height: 30px;
        text-align: center;
        line-height: 30px;
        font-weight: bold;
      }
    }
  }

  @media (max-width: 1279px) {
    padding: 10px;
    width: 100%;
    border-left: none;
  }
  @media (max-width: 767px) {
    padding: 10px;
    width: 100%;
    border-left: none;
  }

  @media (max-width: 575px) {
    padding: 10px;
    width: 100%;
    border-left: none;
  }
`;
