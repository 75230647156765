import React, { useEffect, useState } from "react";
import {
  reqCreateNotice,
  reqModifyNotice,
  reqDeleteNotice,
  reqNoticeListAdmin,
} from "../../../requests/user";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Dropdown } from "react-bootstrap";
import styled from "styled-components";
import Swal from "sweetalert2";
import NoticePreviewModal from "../../../components/modal/NoticePreviewModal";
import Icon from "../../../components/Icon";
import { reqSendBroadcastAlarm } from "../../../requests/alarm";
import { RootState } from "../../../redux/store";
import { useSelector } from "react-redux";
import AdminHeadTitle from "../../../components/AdminHeadTitle";
import { formatDate } from "../../../common/format";
const ANotice: React.FC = () => {
  const CATEGORY = ["Notice", "Event"];
  const [noticeList, setNoticeList] = useState<any[]>([]);
  const [createmode, setCreatemode] = useState<any>(false);
  const [modifymode, setModifymode] = useState<any>(false);
  const [previewModal, setPreviewModal] = useState<any>(false);
  const [currPage, setCurrPage] = useState<number>(1);
  const [pageMetaData, setPageMetaData] = useState<any>({});
  const [noticeTmpImage, setNoticeTmpImage] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);

  const fetchNoticeList = async () => {
    try {
      const response = await reqNoticeListAdmin(currPage);
      setNoticeList(response.data?.noticeList);
      const noticeCount = Number(response.data?.noticeCount);
      let pageCount = noticeCount / 7;
      if (noticeCount % 7 !== 0) {
        pageCount = pageCount + 1;
      }
      setPageMetaData({
        noticeCount,
        pageCount,
      });
    } catch (error) {
      console.error("공지사항 목록을 불러오는데 실패했습니다.", error);
    }
  };

  useEffect(() => {
    fetchNoticeList();
  }, []);

  const [formData, setFormData] = useState<any>({
    id: "",
    category: "",
    title: "",
    subTitle: "",
    image: "",
    imageUrl: "",
    content: "",
    isPublic: false,
  });

  const handleSetModify = ({ target }: any) => {
    const noticeId = Number(target.id.split("-")[1]);
    const selectNotice = noticeList.find(
      (notice: any) => notice.id === noticeId
    );
    setFormData({
      id: selectNotice.id,
      category: selectNotice.category,
      title: selectNotice.title,
      subTitle: selectNotice.subTitle,
      image: "",
      imageUrl: selectNotice.imageUrl || "",
      content: selectNotice.content,
      isPublic: selectNotice.isPublic,
    });
    setModifymode(true);
  };

  const handleChange = (event: any) => {
    if (event.target && event.target.name === "image") {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          if (reader.readyState === 2) {
            setFormData((prevState: any) => ({
              ...prevState,
              imageUrl: "",
            }));
            setNoticeTmpImage(reader.result);
            // setFormData((prevState: any) => ({
            //   ...prevState,
            //   image: reader.result,
            // }));
          }
        };
        reader.readAsDataURL(file);
        setFormData((prevState: any) => ({
          ...prevState,
          image: file,
        }));
      }
    } else {
      if (event.target.name === "isPublic") {
        const isPublicValue = event.target.value === "true";
        setFormData((prevState: any) => ({
          ...prevState,
          [event.target.name]: isPublicValue,
        }));
      } else {
        const { name, value } = event.target
          ? event.target
          : { name: "content", value: event };
        setFormData((prevState: any) => ({
          ...prevState,
          [name]: value,
        }));
      }
    }
  };

  const handleQuillChange = (content: string) => {
    setFormData((prevState: any) => ({
      ...prevState,
      content: content,
    }));
  };
  const admin = useSelector((state: RootState) => state.admin);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      Swal.fire({
        title: "공지사항을 등록하시겠습니까?",
        text: "공지를 공개로 설정한 경우, 모든 사용자에게 알림을 전송합니다.",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "승인",
        cancelButtonText: "취소",
      }).then(async (result) => {
        if (result.isConfirmed) {
          setIsLoading(true);
          const noticeFormData = new FormData();
          noticeFormData.append("id", formData.id);
          noticeFormData.append("title", formData.title);
          noticeFormData.append("subTitle", formData.subTitle);
          noticeFormData.append("category", formData.category);
          noticeFormData.append("content", formData.content);
          noticeFormData.append("isPublic", formData.isPublic);
          noticeFormData.append("file", formData.image);
          await reqCreateNotice(noticeFormData);
          setIsLoading(false);

          if (formData.isPublic === true) {
            const alarmPayload = {
              userId: "-",
              read: 0,
              content: `Ontact Korea has uploaded a new notice !`,
              sender: admin.name,
            };
            await reqSendBroadcastAlarm(alarmPayload);
          }

          setFormData({
            id: "",
            category: "",
            title: "",
            subTitle: "",
            image: "",
            content: "",
            isPublic: false,
          });
          Swal.fire("등록이 완료되었습니다.", "", "success");
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      });
    } catch (error) {
      console.error("Failed to add notice:", error);
    }
  };

  const handlePreviewModal = (flag: boolean) => {
    setPreviewModal(flag);
  };

  const handleDelete = () => {
    try {
      Swal.fire({
        title: "공지사항을 삭제하시겠습니까?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "승인",
        cancelButtonText: "취소",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await reqDeleteNotice(formData.id);
          Swal.fire("삭제가 완료되었습니다.", "", "success");
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      });
    } catch (error) {
      console.error("Failed to modify notice:", error);
    }
  };

  const handleSave = async () => {
    try {
      Swal.fire({
        title: "공지사항을 수정하시겠습니까?",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "승인",
        cancelButtonText: "취소",
      }).then(async (result) => {
        if (result.isConfirmed) {
          setIsLoading(true);
          const noticeFormData = new FormData();
          noticeFormData.append("id", formData.id);
          noticeFormData.append("title", formData.title);
          noticeFormData.append("subTitle", formData.subTitle);
          noticeFormData.append("category", formData.category);
          noticeFormData.append("content", formData.content);
          noticeFormData.append("isPublic", formData.isPublic);
          noticeFormData.append("file", formData.image);

          await reqModifyNotice(noticeFormData);
          setIsLoading(false);
          Swal.fire("수정이 완료되었습니다.", "", "success");
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      });
    } catch (error) {
      console.error("Failed to modify notice:", error);
    }
  };

  const handlePageChange = async (page: number) => {
    if (page === 0) {
      return;
    }
    const response = await reqNoticeListAdmin(page);
    const responseNotices = response.data?.noticeList;

    const noticeCount = Number(response.data?.noticeCount);
    let pageCount = Math.ceil(noticeCount / 7);
    if (page > pageCount) {
      return;
    }
    setCurrPage(page);
    setNoticeList(responseNotices);
    setPageMetaData({
      noticeCount,
      pageCount,
    });
  };

  return (
    <div>
      {isLoading ? (
        <LoadingSection>
          <img src="/resources/img/loading.gif" alt="로딩중" />
        </LoadingSection>
      ) : null}

      <AdminHeadTitle
        subtitle1="서비스 관리"
        subtitle2="공지사항 관리"
        title="공지사항 관리"
      />

      {!modifymode && !createmode ? (
        <div
          id="submit-button-section"
          style={{ textAlign: "right", paddingRight: "40px" }}
        >
          <button
            type="submit"
            className="btn btn-primary"
            onClick={() => setCreatemode(true)}
          >
            공지사항 추가
          </button>
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <FormRow className="form-group">
            <label htmlFor="category">카테고리</label>
            <Dropdown>
              <Dropdown.Toggle variant="" style={{ width: "30%" }}>
                {formData.category || "선택"}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {CATEGORY.map((element) => (
                  <Dropdown.Item
                    key={element}
                    onClick={() =>
                      handleChange({
                        target: {
                          name: "category",
                          value: element,
                        },
                      })
                    }
                  >
                    {element}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </FormRow>
          <FormRow className="form-group">
            <label htmlFor="title">제목</label>
            <input
              type="text"
              className="form-control"
              id="title"
              name="title"
              value={formData.title}
              onChange={handleChange}
            />
          </FormRow>

          <FormRow className="form-group">
            <label htmlFor="subTitle">부제목</label>
            <input
              type="text"
              className="form-control"
              id="subTitle"
              name="subTitle"
              value={formData.subTitle}
              onChange={handleChange}
            />
          </FormRow>

          <FormRow className="form-group">
            <label htmlFor="image">사진 업로드</label>
            <input
              type="file"
              className="form-control-file"
              id="image"
              name="image"
              accept="image/*"
              onChange={handleChange}
            />
            {formData.imageUrl ? (
              <div className="image-cover">
                <img
                  src={`https://kr.object.ncloudstorage.com/ontact-bucket/${formData.imageUrl}`}
                  alt="Preview"
                />
              </div>
            ) : (
              <div className="image-cover">
                {noticeTmpImage && <img src={noticeTmpImage} alt="Preview" />}
              </div>
            )}
          </FormRow>
          <FormRow className="form-group">
            <label htmlFor="content">내용</label>
            <ReactQuill
              className="custom-quill"
              value={formData.content}
              onChange={handleQuillChange}
            />
          </FormRow>
          <FormFooter>
            <div id="public-section">
              <label>공개 여부</label>
              <div>
                <span>공개</span>
                <input
                  type="radio"
                  name="isPublic"
                  value="true"
                  onChange={handleChange}
                  checked={formData.isPublic == true}
                ></input>
                <span>비공개</span>
                <input
                  type="radio"
                  name="isPublic"
                  value="false"
                  onChange={handleChange}
                  checked={formData.isPublic == false}
                ></input>
              </div>
            </div>

            {!modifymode ? (
              <div id="submit-button-section">
                <button type="submit" className="btn btn-primary">
                  공지사항 등록
                </button>
              </div>
            ) : (
              <ModifyButtonRow>
                <div>
                  <div
                    className="btn btn-primary"
                    onClick={() => handlePreviewModal(true)}
                  >
                    미리보기
                  </div>
                </div>
                <div>
                  <div className="btn delete-btn" onClick={handleDelete}>
                    삭제
                  </div>
                </div>
                <div>
                  <div className="btn btn-primary" onClick={handleSave}>
                    저장
                  </div>
                </div>
              </ModifyButtonRow>
            )}
          </FormFooter>
        </form>
      )}
      <section>
        <div className="container-fluid">
          <table className="table text-center">
            <thead>
              <tr>
                <th>카테고리</th>
                <th>제목</th>
                <th>부제목</th>
                <th>작성시간</th>
                <th>조회수</th>
                <th>게시 상태</th>
                <th>수정</th>
              </tr>
            </thead>
            <tbody>
              {noticeList &&
                noticeList?.map((notice) => (
                  <tr key={notice.id}>
                    <td>{notice.category}</td>
                    <NoticeTitle>{notice.title}</NoticeTitle>
                    <td>{notice.subTitle}</td>
                    <td>
                      {new Date(notice.createdAt).toLocaleDateString("ko-KR", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                      })}
                    </td>
                    <td>{notice.view}</td>
                    <td>
                      <PublicStatus isPublic={notice.isPublic}>
                        {notice.isPublic ? "공개" : "비공개"}
                      </PublicStatus>
                    </td>
                    <NoticeListButtonRow>
                      <button
                        id={`modify-${notice.id}`}
                        className="btn"
                        onClick={(e) => handleSetModify(e)}
                      >
                        수정하기
                      </button>
                    </NoticeListButtonRow>
                  </tr>
                ))}
            </tbody>
          </table>

          <NoticeFooter>
            <Icon
              icon="arrow-left-short"
              fontSize="2.4rem"
              onClick={() => handlePageChange(currPage - 1)}
            />
            {Array.from({ length: pageMetaData.pageCount }, (_, index) => (
              <PageNumber
                key={index + 1}
                isSelected={index + 1 === currPage}
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </PageNumber>
            ))}
            <Icon
              icon="arrow-right-short"
              fontSize="2.4rem"
              onClick={() => handlePageChange(currPage + 1)}
            />
          </NoticeFooter>
        </div>
      </section>
      {previewModal && (
        <NoticePreviewModal
          notice={formData}
          tmpImage={noticeTmpImage}
          setViewNotice={(flag: boolean) => handlePreviewModal(flag)}
        />
      )}
    </div>
  );
};
const LoadingSection = styled.div`
  position: absolute;
  z-index: 1000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  & > img {
    width: 100%;
  }
`;
const NoticeFooter = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
`;
const PageNumber = styled.span<{ isSelected: boolean }>`
  font-size: 1.4rem;
  cursor: pointer;
  margin: 0 1rem;
  color: ${(props) => (props.isSelected ? "black" : "lightgray")};
`;

const NoticeTitle = styled.td`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
interface PublicStatusProps {
  isPublic: boolean;
}
const PublicStatus = styled.span<PublicStatusProps>`
  color: ${(props) => (props?.isPublic ? "blue" : "gray")};
  font-weight: bold;
`;

const NoticeListButtonRow = styled.td`
  & > button {
    background-color: lightgray;
  }
`;

const ModifyButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  & > div {
    margin-left: 20px;

    .delete-btn {
      background-color: #fc2d2d;
      color: white;
    }
  }
`;

const FormRow = styled.div`
  display: flex;
  flex-direction: column;

  & > label {
    margin: 20px 0 10px 0;
  }

  .dropdown {
    width: 50%;

    & > button {
      border: 1px solid;
    }
  }

  .image-cover {
    text-align: left;
    & > img {
      margin-top: 20px;
      max-height: 300px;
      max-width: 1000px;
      object-fit: scale-down;
    }
  }
`;

const FormFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  #public-section {
    display: flex;
    flex-direction: column;
    margin: 20px 0 10px 0;

    & > label {
      margin: 0 0 10px 0;
    }

    & > div {
      input {
        margin: 0 10px;
      }
    }
  }

  #submit-button-section {
  }
`;

export default ANotice;
