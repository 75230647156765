import React from "react";
import { isMobile } from "react-device-detect";
import { MButton, MWrapper } from "../../common/mobile-styled";
import CustomerPageTitle from "../../components/CustomerPageTitle";
import styled from "styled-components";
import { Tab, Tabs } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";

const CShipGuide = () => {
  const qaList = [
    {
      question: `What is a space code ?`,
      answer: `Your space code is unique to you and helps us link packages to your space.`,
    },
    {
      question: `Where can I find the address and space code ?`,
      answer: `After signing up, the warehouse address and space code can be found on your main dashboard in the section 'Your Korean address'`,
    },
    {
      question: `What phone number should I use when ordering online ?`,
      answer: `The phone number for shipping should be 010 2716 9020 (for Korean websites) `,
    },
    {
      question: `How much is the storage fee after the free storage period ends ?`,
      answer: `After the 30 days free storage you will be charged $1 per day and per package`,
    },
    {
      question: `How can I switch to a membership plan ?`,
      answer: `You can switch to a membership plan by completing payment for desired membership on the 'Membership' page. Please be aware that you will have to complete your previous warehouse charge to unlock the membership payment.`,
    },
    {
      question: `My package has been delivered to the warehouse. Can I get a photo immediately ?`,
      answer: `Ontact Korea checks the incoming quantity every day and provides photos as quickly as possible, but it may take 1-2 business days at times of high volume.`,
    },
    {
      question: `Do I need to inform ONTACT KOREA of the order information that arrived at the warehouse ?`,
      answer: `If a space code is added at the end of the address, you don't have to inform us. We will upload it directly to the 'All my packages' page.`,
    },
    {
      question: `Can I send foreign packages to Ontact Korea warehouse ?`,
      answer: `Yes, however for custom clearance purposes you may need a tax number or a custom clearance number. Please contact our support team for further details.`,
    },
  ];

  return (
    <Wrapper style={{ padding: `${isMobile && "1.4rem"}` }}>
      <CustomerPageTitle title="Guide: Tutorial video my storage" />

      <GuideWrapper>
        <div className="f-col">
          <div
            className={`${isMobile ? "f-col line" : "f-row line"}`}
            style={{ paddingBottom: "2rem" }}
          >
            <div className={`${isMobile ? "line" : ""}`}>
              <Tabs
                defaultActiveKey="step1"
                id="uncontrolled-tab-example"
                className="mb-3"
              >
                {/* Step 1 */}
                <Tab eventKey="step1" title="Step 1">
                  <Carousel slide={false}>
                    <Carousel.Item>
                      <img
                        className={`${
                          isMobile ? "mobile-guide-img" : "guide-img"
                        }`}
                        src="/resources/img/guide-2/1.png"
                        alt="1-1"
                      />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className={`${
                          isMobile ? "mobile-guide-img" : "guide-img"
                        }`}
                        src="/resources/img/guide-2/2.png"
                        alt="1-2"
                      />
                    </Carousel.Item>
                  </Carousel>
                </Tab>

                {/* Step 2 */}
                <Tab eventKey="step2" title="Step 2">
                  <img
                    className={`${isMobile ? "mobile-guide-img" : "guide-img"}`}
                    src="/resources/img/guide-2/3.png"
                    alt="2"
                  />
                </Tab>

                {/* Step 3 */}
                <Tab eventKey="step3" title="Step 3">
                  <Carousel slide={false}>
                    <Carousel.Item>
                      <img
                        className={`${
                          isMobile ? "mobile-guide-img" : "guide-img"
                        }`}
                        src="/resources/img/guide-2/4.png"
                        alt="3-1"
                      />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className={`${
                          isMobile ? "mobile-guide-img" : "guide-img"
                        }`}
                        src="/resources/img/guide-2/5.png"
                        alt="3-2"
                      />
                    </Carousel.Item>
                  </Carousel>
                </Tab>

                {/* Step 4 */}
                <Tab eventKey="step4" title="Step 4">
                  <Carousel slide={false}>
                    <Carousel.Item>
                      <img
                        className={`${
                          isMobile ? "mobile-guide-img" : "guide-img"
                        }`}
                        src="/resources/img/guide-2/6.png"
                        alt="4-1"
                      />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className={`${
                          isMobile ? "mobile-guide-img" : "guide-img"
                        }`}
                        src="/resources/img/guide-2/7.png"
                        alt="4-2"
                      />
                    </Carousel.Item>
                  </Carousel>
                </Tab>

                {/* Step 5 */}
                <Tab eventKey="step5" title="Step 5">
                  <img
                    className={`${isMobile ? "mobile-guide-img" : "guide-img"}`}
                    src="/resources/img/guide-2/8.png"
                    alt="6"
                  />
                </Tab>
              </Tabs>
            </div>

            {isMobile ? (
              <div>
                <div
                  style={{
                    fontSize: "1.4rem",
                    fontWeight: "bold",
                    margin: "1.6rem 0 1.4rem 0",
                    color: "var(--color-main-blue)",
                  }}
                >
                  Custom order status workflow
                </div>
                <img
                  src="/resources/img/guide-step1.png"
                  alt="ex"
                  style={{ marginLeft: ".6rem", width: "100%" }}
                />
              </div>
            ) : (
              <div style={{ marginLeft: "4rem" }}>
                <Tabs
                  defaultActiveKey="step1"
                  id="uncontrolled-tab-example"
                  className="mb-3"
                >
                  <Tab eventKey="step1" title="Custom order status workflow">
                    <img
                      src="/resources/img/guide-step2.png"
                      alt="ex"
                      style={{ marginLeft: ".6rem" }}
                    />
                  </Tab>
                </Tabs>
              </div>
            )}
          </div>

          <FaqWrapper>
            <div className="qa-title">My storage FAQ</div>

            {qaList.map((qa: any) => {
              return (
                <div className="qa-section">
                  <div className="question">{qa.question}</div>
                  <div className="answer">{qa.answer}</div>
                </div>
              );
            })}
          </FaqWrapper>
        </div>
      </GuideWrapper>
    </Wrapper>
  );
};

const FaqWrapper = styled.div`
  .qa-title {
    color: var(--color-main-blue);
    font-weight: bold;
    font-size: 1.4rem;
    margin: 1.4rem 0 1rem 0;
  }
  .qa-section {
    margin-bottom: 1rem;
  }
  .question {
    font-weight: bold;
    font-size: 1.2rem;
  }
  .answer {
    margin: 0.4rem 0 0 0.6rem;
  }
`;

const GuideWrapper = styled.div`
  .guide-img {
    height: 597px;
  }
  .mobile-guide-img {
    width: 100%;
  }
`;

const Wrapper = styled.div`
  padding: 2rem;
  margin-bottom: 4rem;
`;

export default CShipGuide;
