import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import styled from "styled-components";
import {
  CODE_SHIPPING_PRODUCT_STATUS_OPTIONS,
  CODE_SHOPING_PAYMENT_STATUS_OPTIONS,
  CODE_SHOP_STATUS_OPTIONS,
} from "../common/constants";
import Icon from "./Icon";
import { RequestSent_상태로_넘어오는_Shop_페이지_Dropdown } from "./CustomerHeadFilter";

const CustomerHeadFilterMobile = ({
  selectStatus,
  selectViewPage,
  enterSearch,
  selectSearchOption,
}: any) => {
  const { pathname } = useLocation();
  const params = useParams();

  const [viewFilter, setViewFilter] = useState<string>("");
  const [searchOptionFilter, setSearchOptionFilter] = useState<string>("");
  const [searchWordFilter, setSearchWordFilter] = useState<string>("");

  const [statusListObject, setStatusListObject] = useState<object>({});
  const [searchOptionList, setSearchOptionList] = useState<string[]>([]);
  const [viewFilterList, setViewFilterList] = useState<object[]>([
    { name: "See 10", value: "10" },
    { name: "See 50", value: "50" },
    { name: "See 100", value: "100" },
    { name: "Full View", value: "1000000" },
  ]);

  useEffect(() => {
    if (pathname.includes("/customer/shop/order")) {
      setStatusListObject(CODE_SHOP_STATUS_OPTIONS);
      setSearchOptionList(["Order Number"]);
      setSearchOptionFilter("Order Number");
      if (params.status === "1") {
        selectStatus("1");
      }
    }
    if (pathname === "/customer/warehouse") {
      setStatusListObject(CODE_SHIPPING_PRODUCT_STATUS_OPTIONS);
      setSearchOptionList(["Tracking Number"]);
      setSearchOptionFilter("Tracking Number");
    }
  }, []);

  const handleKeyPress = (event: any) => {
    if (event.key === " ") {
      event.preventDefault();
    }
  };

  return (
    <Wrapper>
      <Col>
        <Row>
          {/* 상태 필터 */}
          <Box className="status">
            <select
              name="status"
              className="input-reset dropdown"
              onChange={(e: any) => {
                selectStatus(e.target.value);
              }}
            >
              {pathname === "/customer/shop/order/1" ? (
                <RequestSent_상태로_넘어오는_Shop_페이지_Dropdown />
              ) : (
                <>
                  <option value="">Filter By Status</option>
                  {Object.entries(statusListObject).map(([key, value]) => {
                    return (
                      <option key={key} value={key}>
                        {value}
                      </option>
                    );
                  })}
                </>
              )}
            </select>
            <Icon
              icon="caret-down-fill"
              style={{ position: "absolute", top: "20%", right: "4%" }}
            />
          </Box>
        </Row>

        <Row>
          {/* 보기 필터 */}
          <Box className="view">
            <select
              name="view"
              className="input-reset dropdown"
              value={viewFilter}
              onChange={(e: any) => {
                setViewFilter(e.target.value);
                selectViewPage(Number(e.target.value));
              }}
            >
              {viewFilterList.map((status: any) => (
                <option key={status.value} value={status.value}>
                  {status.name}
                </option>
              ))}
            </select>
            <Icon
              icon="caret-down-fill"
              style={{ position: "absolute", top: "20%", right: "6%" }}
            />
          </Box>
        </Row>

        <Row>
          {/* 검색 */}
          <Box className="search">
            <div id="search-input">
              <Input
                type="text"
                className="input-reset"
                placeholder={searchOptionFilter}
                value={searchWordFilter}
                onChange={(e: any) => {
                  const word = e.target.value.toUpperCase();
                  setSearchWordFilter(word);
                  enterSearch(word);
                }}
              />
              <SearchButton>
                <Icon icon="search" color="var(--color-main-blue)" />
              </SearchButton>
            </div>
          </Box>
        </Row>
      </Col>
    </Wrapper>
  );
};

const SearchButton = styled.div`
  border: 1px solid var(--color-main-blue);
  padding: 0.4rem 1rem;
  align-items: center;
  border-top-right-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem;
  cursor: pointer;
`;
const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;
const Col = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
const Box = styled.div`
  display: flex;
  border: 1px solid lightgray;
  border-radius: 0.4rem;
  position: relative;
  width: 100%;
  .input-reset {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0.4rem;
    width: 100%;
    border: none;
    padding: 0.4rem 0.8rem;
  }
  #search-opt-dropdown {
    position: relative;
    flex: 1 1 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 1px solid lightgray;
  }
  #search-opt-input {
    flex: 1 1 0;
    margin-left: 1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  #search-input {
    position: relative;
    display: flex;
    align-items: center;
    flex: 1.4;
    margin-left: 1px;
    border-radius: 4px;
  }
`;
const Wrapper = styled.div`
  margin-bottom: 1.4rem;
  /* border-bottom: 1px solid lightgray; */
`;
const Input = styled.input`
  flex-grow: 2;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;
const Select = styled.select`
  flex: 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 10px;
`;
export default CustomerHeadFilterMobile;
