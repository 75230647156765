/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState } from "react";
import { reqNoticeList } from "../../../requests/user";
import styled from "styled-components";
import Icon from "../../../components/Icon";
import { isMobile } from "react-device-detect";
import CustomerPageTitle from "../../../components/CustomerPageTitle";
import { useNavigate } from "react-router-dom";
import CMobileNotice from "../../../components/CMobileNotice";

export default function Cevent() {
  const nav = useNavigate();

  const [noticeList, setNoticeList] = useState<any>([]);
  const [viewNotice, setViewNotice] = useState<boolean>(false);
  const [selectedNotice, setSelectedNotice] = useState<any>({
    id: "",
    category: "",
    title: "",
    subTitle: "",
    imageUrl: "",
    content: "",
    view: "",
    createdAt: "",
    updatedAt: "",
  });
  const [currPage, setCurrPage] = useState<number>(1);
  const [pageMetaData, setPageMetaData] = useState<any>({});

  const fetchNoticeList = async () => {
    try {
      const response = await reqNoticeList(currPage);
      const responseNotices = response.data?.noticeList;
      setNoticeList(responseNotices);

      const noticeCount = Number(response.data?.noticeCount);
      let pageCount = Math.floor(noticeCount / 7);
      if (noticeCount % 7 !== 0) {
        pageCount = pageCount + 1;
      }
      setPageMetaData({
        noticeCount,
        pageCount,
      });
    } catch (error) {
      console.error("Failed to load notices.", error);
    }
  };

  useEffect(() => {
    fetchNoticeList();
  }, []);

  const handleClickNotice = async ({ target }: any) => {
    const selectedNoticeId = Number(
      target.closest(".notice-row").id.split("-")[2]
    );
    const selectedNotice = noticeList.find(
      (notice: any) => notice.id === selectedNoticeId
    );
    setSelectedNotice({
      id: selectedNotice.id,
      category: selectedNotice.category,
      title: selectedNotice.title,
      subTitle: selectedNotice.subTitle,
      imageUrl: selectedNotice.imageUrl || "",
      content: selectedNotice.content,
      view: selectedNotice.view,
      createdAt: selectedNotice.createdAt,
      updatedAt: selectedNotice.updatedAt,
    });
    setViewNotice(true);
    nav(`/customer/service/notice/${selectedNoticeId}`);
  };

  const getDiffOfNow = (createdAt: any) => {
    const now = new Date();
    const created = new Date(createdAt);
    let diff = Math.abs(now.getTime() - created.getTime());
    diff = Math.ceil(diff / (1000 * 60 * 60 * 24));
    return diff;
  };

  const handlePageChange = async (page: number) => {
    if (page === 0) {
      return;
    }
    const response = await reqNoticeList(page);
    const responseNotices = response.data?.noticeList;

    const noticeCount = Number(response.data?.noticeCount);
    let pageCount = Math.floor(noticeCount / 7);
    if (noticeCount % 7 !== 0) {
      pageCount = pageCount + 1;
    }
    if (page > pageCount) {
      return;
    }
    setCurrPage(page);
    setNoticeList(responseNotices);
    setPageMetaData({
      noticeCount,
      pageCount,
    });
  };

  return (
    <div
      className="container-fluid"
      style={{
        alignItems: "center",
        paddingTop: "1.4rem",
        paddingLeft: isMobile ? "1.4rem" : "2rem",
        paddingRight: isMobile ? "1.4rem" : "3rem",
      }}
    >
      {isMobile ? (
        <CustomerPageTitle title="Notice" />
      ) : (
        <CustomerPageTitle title="Notice" />
      )}

      <>
        {isMobile ? (
          <MobileWrapper>
            <NoticeTableHead>
              <HeadCell>Id</HeadCell>
              <HeadCellTitle>Title</HeadCellTitle>
              <HeadCell>View</HeadCell>
            </NoticeTableHead>

            <NoticeTableBody>
              {noticeList &&
                noticeList.map((notice: any) => (
                  <NoticeTableBodyRow
                    id={`notice-row-${notice.id}`}
                    className="notice-row"
                    key={notice.id}
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      handleClickNotice(e);
                    }}
                  >
                    <BodyCell>{notice.id}</BodyCell>
                    <M_BodyTitle>
                      {notice.title}
                      {getDiffOfNow(notice.createdAt) <= 3 && (
                        <NewNoticeLabel>New</NewNoticeLabel>
                      )}
                    </M_BodyTitle>
                    <BodyCell>{notice.view}</BodyCell>
                  </NoticeTableBodyRow>
                ))}

              <NoticeFooter>
                {Array.from({ length: pageMetaData.pageCount }, (_, index) => (
                  <PageNumber
                    key={index + 1}
                    $isSelected={index + 1 === currPage}
                    onClick={() => handlePageChange(index + 1)}
                  >
                    {index + 1}
                  </PageNumber>
                ))}
              </NoticeFooter>
            </NoticeTableBody>
          </MobileWrapper>
        ) : (
          <NoticeTable>
            <NoticeTableHead>
              <HeadCell>Category</HeadCell>
              <HeadCellTitle>Title</HeadCellTitle>
              <HeadCell>SubTitle</HeadCell>
              <HeadCell>Created At</HeadCell>
              <HeadCell>View</HeadCell>
            </NoticeTableHead>
            <NoticeTableBody>
              {noticeList &&
                noticeList.map((notice: any) => (
                  <NoticeTableBodyRow
                    id={`notice-row-${notice.id}`}
                    className="notice-row"
                    key={notice.id}
                    style={{ cursor: "pointer" }}
                    onClick={(e) => handleClickNotice(e)}
                  >
                    <BodyCell>{notice.category}</BodyCell>
                    <BodyCellTitle>
                      {notice.title}
                      {getDiffOfNow(notice.createdAt) <= 3 && (
                        <NewNoticeLabel>New</NewNoticeLabel>
                      )}
                    </BodyCellTitle>
                    <BodyCell>{notice.subTitle}</BodyCell>
                    <BodyCell>
                      {new Date(notice.createdAt).toLocaleDateString("ko-KR", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                        hour: "2-digit",
                        minute: "2-digit",
                        hourCycle: "h23",
                      })}
                    </BodyCell>
                    <BodyCell>{notice.view}</BodyCell>
                  </NoticeTableBodyRow>
                ))}
            </NoticeTableBody>
            <NoticeFooter>
              {Array.from({ length: pageMetaData.pageCount }, (_, index) => (
                <PageNumber
                  key={index + 1}
                  $isSelected={index + 1 === currPage}
                  onClick={() => handlePageChange(index + 1)}
                >
                  {index + 1}
                </PageNumber>
              ))}
            </NoticeFooter>
          </NoticeTable>
        )}
      </>
    </div>
  );
}

const Title = styled.h3`
  border-bottom: 1px solid lightgray;
  padding-bottom: 1rem;
  margin-top: 1rem;
`;
const FeeBoxRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  & > div {
    flex: 1 0 0;
    text-align: center;
    align-items: center;
    border: 1px solid lightgray;
    padding: 0.4rem 0;
  }
  margin: 0.6rem 0;
`;
const TotalFeeBoxRow = styled(FeeBoxRow)`
  background-color: #474747;
  color: white;
`;

const MobileWrapper = styled.div`
  margin: 0;
`;

const MBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
  align-items: center;
`;
const Col = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  font-weight: bold;
  margin: 0.4rem 0 0.4rem 0;
`;
const P = styled.p`
  margin: 0.4rem 0 0.4rem 0;
`;

const NoticeFooter = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
`;
const PageNumber = styled.span<{ $isSelected: boolean }>`
  font-size: 1rem;
  cursor: pointer;
  margin: 0 0.2rem;
  padding: 6px 12px;
  border-radius: 0.375rem;
  background-color: ${(props) =>
    props.$isSelected ? "var(--color-main-blue)" : "transparent"};

  color: ${(props) => props.$isSelected && "var(--color-white)"};
`;

const NoticeTable = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 2rem 0rem;
`;
const NoticeTableHead = styled.div`
  display: flex;
  background-color: #f8f9fa;
  padding: 5px;
  border-bottom: 2px solid #dee2e6;
`;
const NoticeTableBody = styled.div`
  display: flex;
  flex-direction: column;
`;
const NoticeTableBodyRow = styled.div`
  display: flex;
  flex-direction: row;
  padding: 5px;
  border-bottom: 1px solid #dee2e6;
  &:last-child {
    border-bottom: none;
  }
`;
const CellBase = styled.p`
  text-align: center;
  margin: 0;
  padding: 1rem;
  align-items: center;
  flex: 1;
`;

const HeadCell = styled(CellBase)`
  font-weight: bold;
`;
const HeadCellTitle = styled(HeadCell)`
  flex: 4;
`;
const NewNoticeLabel = styled.div`
  border-radius: 0.4rem;
  background-color: var(--color-main-blue);
  color: white;
  margin-left: 1rem;
  height: 90%;
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
`;
const BodyCell = styled(CellBase)`
  flex: 1;
`;
const BodyCellTitle = styled(BodyCell)`
  display: flex;
  flex: 4;
  text-align: left;
  padding: 0.5rem 0 0.5rem 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const M_BodyTitle = styled(BodyCellTitle)`
  flex: 11;
`;
