import React, { useEffect, useState } from "react";
import { Pagination } from "react-bootstrap";
import { useSelector } from "react-redux";
import styled from "styled-components";
import Swal from "sweetalert2";
import * as XLSX from "xlsx";
import { CODE_COUNTRY_ITEMS } from "../../../common/constants";
import AdminHeadFilter from "../../../components/AdminHeadFilter";
import AdminHeadTitle from "../../../components/AdminHeadTitle";
import Icon from "../../../components/Icon";
import { RootState } from "../../../redux/store";
import { reqSendAlarm } from "../../../requests/alarm";
import { reqEachCouponList, reqSendCoupon } from "../../../requests/coupon";
import {
  reqGetAllUserById,
  reqGetEveryUserServiceLog,
} from "../../../requests/user";

interface User {
  id: string;
  name: string;
  country: number;
  email: string;
  point: number;
  agreeMarketing: boolean;
  favorite: string;
  latestWarehouseId: string;
  membershipId: number;
}

const Toast = Swal.mixin({
  toast: true,
  position: "center",
  showConfirmButton: false,
  timer: 2000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

export default function Alist() {
  const [users, setUsers] = useState<User[]>([]);
  const [shipLog, setShipLog] = useState<any>({});
  const [shopLog, setShopLog] = useState<any>({});
  const [showModal, setShowModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [message, setMessage] = useState<string>("");
  const admin = useSelector((state: RootState) => state.admin);

  useEffect(() => {
    fetchAllUsers();
  }, []);

  const fetchAllUsers = async () => {
    try {
      const response = await reqGetAllUserById();
      setUsers(response.data);

      const serviceLogs = await reqGetEveryUserServiceLog();
      setShipLog(serviceLogs.data.shipLog);
      setShopLog(serviceLogs.data.shopLog);
    } catch (error) {
      console.error("전체 사용자 정보 조회 실패:", error);
    }
  };

  const handleDownload = () => {
    const wb = XLSX.utils.book_new();
    const selectedUsers = checkedUserIdList.length
      ? users.filter((user) => checkedUserIdList.includes(user.id))
      : users;

    const wsData = selectedUsers.map((user) => ({
      이름: user.name,
      SpaceCode: user.id,
      나라: CODE_COUNTRY_ITEMS[user.country] || "Unknown",
      이메일: user.email,
      Points: user.point + "P",
      서비스횟수: shipLog[user.id]?.count + shopLog[user.id]?.count || 0,
      이용금액KRW:
        shipLog[user.id]?.krwAmount + shopLog[user.id]?.krwAmount || 0,
      이용금액USD:
        shipLog[user.id]?.usdAmount + shopLog[user.id]?.usdAmount || 0,
      수신동의: user.agreeMarketing ? "알림 동의" : "알림 거절",
    }));

    const ws = XLSX.utils.json_to_sheet(wsData);
    XLSX.utils.book_append_sheet(wb, ws, "Users");
    XLSX.writeFile(wb, "UserList.xlsx");
  };

  const [couponDataList, setCouponDataList] = useState<any>();
  const [selectedCouponId, setSelectedCouponId] = useState<string>();

  const fetchCouponDataList = async () => {
    try {
      const res = await reqEachCouponList();
      const idNameList = res.data.map((data: any) => {
        return { id: data.id, name: data.name };
      });
      setCouponDataList(idNameList);
    } catch (error) {}
  };

  useEffect(() => {
    fetchCouponDataList();
  }, []);

  const [checkedUserIdList, setCheckedUserIdList] = useState<string[]>([]);

  const handleCheckboxChange = (userId: string, isChecked: boolean) => {
    if (isChecked) {
      setCheckedUserIdList([...checkedUserIdList, userId]);
    } else {
      setCheckedUserIdList(checkedUserIdList.filter((u) => u !== userId));
    }
  };

  const [couponModal, setCouponModal] = useState<boolean>(false);

  const handleSendCouponModal = () => {
    setCouponModal(true);
    if (couponDataList.length !== 0) setSelectedCouponId(couponDataList[0].id);
  };

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    setSelectedCouponId(value);
  };

  const handleSendCoupon = () => {
    if (!selectedCouponId) {
      Toast.fire({
        icon: "warning",
        title: "쿠폰을 선택해주세요.",
      });
      return;
    }
    Swal.fire({
      title: "쿠폰을 지급하시겠습니까?",
      text: "사용자에게 알림을 전송합니다.",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "승인",
      cancelButtonText: "취소",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await reqSendCoupon({
          userIdList: checkedUserIdList,
          couponId: selectedCouponId,
          manager: admin.name,
        });

        checkedUserIdList.map(async (userId: string) => {
          const alarmPayload = {
            userId: userId,
            read: 0,
            content: `You have received a new coupon.`,
            sender: "최고 관리자",
          };
          await reqSendAlarm(alarmPayload);
        });

        Swal.fire("쿠폰 지급에 성공했습니다.", "", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    });
  };

  const [filteredUsers, setFilteredUsers] = useState<any[]>([]);

  const [searchWord, setSearchWord] = useState<string>("");
  const [searchOption, setSearchOption] = useState<string>("");

  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedStatusOption, setSelectedStatusOption] = useState("");

  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    const thisFilteredUsers = users
      .filter((user: any) => {
        if (selectedStatusOption === "국가") {
          return (
            user.id.toUpperCase().includes(searchWord.toUpperCase()) &&
            (selectedStatus !== "0"
              ? user.country.toString() === selectedStatus
              : true)
          );
        }
        if (selectedStatusOption === "마케팅 동의") {
          return (
            user.id.toUpperCase().includes(searchWord.toUpperCase()) &&
            (selectedStatus
              ? user.agreeMarketing.toString() === selectedStatus
              : true)
          );
        }
        if (selectedStatusOption === "멤버십") {
          return (
            user.id.toUpperCase().includes(searchWord.toUpperCase()) &&
            (selectedStatus
              ? user.membershipId.toString() === selectedStatus
              : true)
          );
        }
        return user.id.toUpperCase().includes(searchWord.toUpperCase());
      })
      .sort(
        (a: any, b: any) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      );
    setFilteredUsers(thisFilteredUsers);
    setCurrentPage(1);
    setTotalPages(Math.ceil(thisFilteredUsers.length / itemsPerPage));
  }, [users, searchWord, itemsPerPage, selectedStatus, selectedStatusOption]);

  const handleItemsPerPageChange = (newItemsPerPage: number) => {
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1);
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const displayedUsers = filteredUsers.slice(
    startIndex,
    startIndex + itemsPerPage
  );

  const handleShowModal = (user: User) => {
    setSelectedUser(user);
    setMessage(`Hello ${user.name}, you have a new message from admin.`);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedUser(null);
    setMessage("");
  };

  const handleSendAlarm = async () => {
    if (selectedUser) {
      const alarmPayload = {
        userId: selectedUser.id,
        read: 0,
        content: message,
        sender: "최고 관리자",
      };

      try {
        await reqSendAlarm(alarmPayload);
        Swal.fire("알림 전송 완료", "", "success");
        handleCloseModal();
      } catch (error) {
        console.error("알림 전송 실패:", error);
        Swal.fire("알림 전송 실패", "", "error");
      }
    }
  };

  const renderPaginationItems = () => {
    const pageItems = [];
    const maxVisiblePages = 10;
    const halfVisiblePages = Math.floor(maxVisiblePages / 2);

    let startPage = Math.max(currentPage - halfVisiblePages, 1);
    let endPage = Math.min(currentPage + halfVisiblePages, totalPages);

    if (endPage - startPage + 1 < maxVisiblePages) {
      if (startPage === 1) {
        endPage = Math.min(startPage + maxVisiblePages - 1, totalPages);
      } else {
        startPage = Math.max(endPage - maxVisiblePages + 1, 1);
      }
    }

    if (currentPage > 1) {
      pageItems.push(
        <Pagination.First key="first" onClick={() => handlePageChange(1)} />
      );
      pageItems.push(
        <Pagination.Prev
          key="prev"
          onClick={() => handlePageChange(currentPage - 1)}
        />
      );
    }

    for (let pageNumber = startPage; pageNumber <= endPage; pageNumber++) {
      pageItems.push(
        <Pagination.Item
          key={pageNumber}
          active={pageNumber === currentPage}
          onClick={() => handlePageChange(pageNumber)}
        >
          {pageNumber}
        </Pagination.Item>
      );
    }

    if (currentPage < totalPages) {
      pageItems.push(
        <Pagination.Next
          key="next"
          onClick={() => handlePageChange(currentPage + 1)}
        />
      );
      pageItems.push(
        <Pagination.Last
          key="last"
          onClick={() => handlePageChange(totalPages)}
        />
      );
    }

    return pageItems;
  };

  return (
    <main id="main" className="main">
      {couponModal && (
        <ModalBackgound>
          <ModalWrapper>
            <ModalHeader>
              <div>쿠폰 지급하기</div>
              <Icon
                icon="x-lg"
                fontSize="2rem"
                onClick={() => setCouponModal(false)}
              />
            </ModalHeader>
            <ModalBody>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                }}
              >
                <label>사용 조건</label>
                <Dropdown
                  name="useCondition"
                  value={selectedCouponId}
                  onChange={handleSelectChange}
                >
                  {couponDataList.map((couponData: any, index: number) => {
                    return (
                      <option key={index} value={couponData.id}>
                        {couponData.name}
                      </option>
                    );
                  })}
                </Dropdown>
                <Icon
                  icon="caret-down-fill"
                  style={{ position: "absolute", top: "66%", right: "6%" }}
                />
              </div>
              <div>
                <label>사용 대상</label>
                <div>
                  {checkedUserIdList.map((id: string) => {
                    return <p key={id}>{id}</p>;
                  })}
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                style={{
                  marginTop: "2rem",
                  width: "100%",
                  textAlign: "center",
                }}
                onClick={handleSendCoupon}
              >
                지급하기
              </Button>
            </ModalFooter>
          </ModalWrapper>
        </ModalBackgound>
      )}

      {showModal && (
        <ModalBackgound>
          <ModalWrapper>
            <ModalHeader>
              <div>알림 메시지 전송</div>
              <Icon icon="x-lg" fontSize="2rem" onClick={handleCloseModal} />
            </ModalHeader>
            <ModalBody>
              <div>
                <label>수신자: {selectedUser?.name}</label>
              </div>
              <div>
                <label>메시지</label>
                <textarea
                  className="form-control"
                  rows={5}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                ></textarea>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                style={{
                  marginTop: "2rem",
                  width: "100%",
                  textAlign: "center",
                }}
                onClick={handleSendAlarm}
              >
                전송하기
              </Button>
            </ModalFooter>
          </ModalWrapper>
        </ModalBackgound>
      )}

      <AdminHeadTitle
        subtitle1="고객 관리"
        subtitle2="전체 고객 리스트"
        title="전체 고객 리스트"
      />

      <AdminHeadFilter
        selectStatus={(value: string) => setSelectedStatus(value)}
        selectStatusOption={(value: string) => setSelectedStatusOption(value)}
        selectViewPage={(value: number) => handleItemsPerPageChange(value)}
        enterSearch={(value: string) => setSearchWord(value)}
        selectSearchOption={(value: string) => setSearchOption(value)}
      />

      <Row style={{ margin: "2rem 0", width: "30%" }}>
        <Button onClick={handleDownload}>Download Excel</Button>
        <Button onClick={handleSendCouponModal}>쿠폰 지급하기</Button>
      </Row>

      <div
        style={{
          padding: "0.5rem 1rem",
          borderRadius: "0.4rem",
          backgroundColor: "#f8f9fa",
          textAlign: "center",
        }}
      >
        <span style={{ fontWeight: "bold", fontSize: "1.2rem" }}>
          회원: {filteredUsers.length}명
        </span>
      </div>

      <div className="container-fluid">
        <table className="table text-center">
          <thead>
            <Row
              style={{
                borderBottom: "1px solid lightgray",
                fontWeight: "bold",
              }}
            >
              <div className="check">선택</div>
              <div>이름</div>
              <div>스페이스코드</div>
              <div>국가</div>
              <div>이메일</div>
              <div>포인트</div>
              <div>서비스이용 횟수</div>
              <div>서비스이용 금액 KRW</div>
              <div>서비스이용 금액 USD</div>
              <div>알림 동의</div>
            </Row>
          </thead>

          <tbody>
            {shipLog &&
              shopLog &&
              displayedUsers.map((user, index) => (
                <Row
                  key={index}
                  onClick={(e) => {
                    const target = e.target as HTMLElement;
                    if (target.tagName !== "INPUT") {
                      handleShowModal(user);
                    }
                  }}
                >
                  <div className="check">
                    <input
                      type="checkbox"
                      onChange={(e) =>
                        handleCheckboxChange(user.id, e.target.checked)
                      }
                    />
                  </div>
                  <div>{user.name}</div>
                  <div>{user.id}</div>
                  <div>{CODE_COUNTRY_ITEMS[user.country] || "Unknown"}</div>
                  <div>{user.email}</div>
                  <div>{user.point}P</div>
                  <div>
                    {shipLog[user.id]?.count + shopLog[user.id]?.count || 0}
                  </div>
                  <div>
                    {(
                      shipLog[user.id]?.krwAmount +
                        shopLog[user.id]?.krwAmount || 0
                    ).toLocaleString("ko-KR")}
                  </div>
                  <div>
                    {shipLog[user.id]?.usdAmount +
                      shopLog[user.id]?.usdAmount || 0}
                  </div>
                  <div>{user.agreeMarketing ? "알림 동의" : "알림 거절"}</div>
                </Row>
              ))}
          </tbody>
        </table>
      </div>

      <CenteredPagination style={{ marginTop: "1rem" }}>
        {renderPaginationItems()}
      </CenteredPagination>
    </main>
  );
}

const CenteredPagination = styled(Pagination)`
  display: flex;
  justify-content: center;
`;

const Dropdown = styled.select`
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;

  border-radius: 0.4rem;
  border: 1px solid lightgray;
  padding: 0.8rem;
`;

const ModalFooter = styled.div``;
const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  & label {
    font-weight: bold;
    margin: 1rem 0;
  }
`;
const ModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid lightgray;

  font-size: 1.6rem;
  font-weight: bold;
`;
const ModalWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
  border-radius: 1rem;
  width: 26vw;
  overflow-y: scroll;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
  background-color: #fff;

  padding: 1.8rem;
  height: 80vh;
`;
const ModalBackgound = styled.div`
  position: fixed;
  z-index: 98;
  top: 0%;
  left: 0%;
  background-color: #00000078;
  width: 100vw;
  height: 100vh;
`;

const Button = styled.div`
  padding: 0.4rem;
  border-radius: 0.6rem;
  background-color: var(--color-main-blue);
  color: var(--color-white);
  &:hover {
    background-color: var(--color-main-blue-hover);
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  text-align: center;
  align-items: center;
  gap: 1rem;
  & > div {
    flex: 1 1 0;
    word-break: break-all;
    padding: 0.5rem;
    white-space: normal;
    overflow: hidden;
  }
  & .check {
    flex: 0.2 1 0;
  }
`;
