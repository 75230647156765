import { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  InputGroup,
  Modal,
  Row,
  Tab,
  Table,
  Tabs,
} from "react-bootstrap";
import {
  ACODE_SHOPING_ORDER_STATUS,
  CODE_COUNTRY_ITEMS,
} from "../../common/constants";
import {
  reqShip,
  reqShipDetail,
  reqShipFEE,
  reqShipOrderUpdate,
  reqShipProductDetail,
  reqShipTrackingGet,
  reqShipUpdateOrder,
} from "../../requests/ship";

interface CShipPayModal {
  show: boolean;
  onHide: () => void;
  orderNumber: string;
  onRepackRequest: () => void;
  refreshData: () => void;
}
interface Product {
  tracking: string;
  warehouseId: string;
  isUrgent: boolean;
  bag: boolean;
  name: string;
  warehouse: string;
  overstoreFee: number;
  price: number;
  boxed: number;
  Extra: number;
}

interface PackingDetail {
  id: string;
  totalWeightKg: number;
  totalHeightCm: number;
  totalWidthCm: number;
  totalLengthCm: number;
  trackingNumbers: string;
  suspectBattery: boolean;
  food: boolean;
  imageUrl: string;
  isDeleted: boolean;
}

interface OrderDetails {
  user: User;
  products: Product[];
  optionBox: number;
  optionLetter: boolean;
  optionPoster: boolean;
  optionAircap: boolean;
  optionBag: boolean;
  optionSticker: boolean;
  adrReceiver: string;
  adrEmail: string;
  adrPhone: string;
  adrCountry: number;
  adrPCCC: string;
  adrZip: string;
  adrState: string;
  adrCity: string;
  adrOption1: string;
  adrOption2: string;
  adrOption3: string;
  estimatedAt: string;
  expiredAt: string;
  weightKg: number;
  status: number;
  heightCm: number;
  widthCm: number;
  lengthCm: number;
  suspectBattery: boolean;
  food: boolean;
  packingStatus: number;
  customerMessage: string;
  managerMessage: string;
  depositor: string;
  useVacuumPackagingBag: number;
  DepositorFee: string;
  link: string;
  FEDEXPriceKRW: number | null;
  FEDEXPriceUSD: number | null;
  UPSPriceKRW: number | null;
  UPSPriceUSD: number | null;
  EMSPriceKRW: number | null;
  EMSPriceUSD: number | null;
  PACKETPriceKRW: number | null;
  PACKETPriceUSD: number | null;
  CJPriceKRW: number | null;
  CJPriceUSD: number | null;
  company: number;
  PaymentMethod: number;
  usePointKrw: number;
  usePointUse: number;
  discountCouponKrw: number;
  discountCouponUsd: number;
}
interface TrackingInfo {
  id: number;
  orderId: string;
  orderIds: string;
  trackingNumbers: string;
}

interface ShippingRate {
  KRW: number | null;
  USD: number | null;
}

interface ShippingOptions {
  [carrier: string]: ShippingRate;
}

interface User {
  id: string;
  email: string;
  membershipId: number;
}

interface ShippingOptionRates {
  KRW: number | null;
  USD: number | null;
}

interface CheckedShippingOptions {
  [carrier: string]: boolean;
}

export default function RequestModal({
  show,
  onHide,
  orderNumber,
  onRepackRequest,
  refreshData,
}: CShipPayModal) {
  const defaultUser: User = {
    id: "",
    email: "",
    membershipId: 0,
  };
  const [exchangeRate, setExchangeRate] = useState(0);
  const [key, setKey] = useState("요청정보");
  const [excludedProducts, setExcludedProducts] = useState<Product[]>([]);
  const [paymentDeadline, setPaymentDeadline] = useState("");
  const [customerMessage, setCustomerMessage] = useState("");
  const [fedexKrw, setFedexKrw] = useState("");
  const [fedexUsd, setFedexUsd] = useState("");
  const [shippingOptions, setShippingOptions] = useState<ShippingOptions>({});
  const [checkedShippingOptions, setCheckedShippingOptions] =
    useState<CheckedShippingOptions>({});
  const [depositorName, setDepositorName] = useState("");
  const [depositAmount, setDepositAmount] = useState("");
  const [link, setLink] = useState("");
  const [showKRWPModal, setShowKRWPModal] = useState(false);
  const [totalSurchargesUSD, setTotalSurchargesUSD] = useState("");
  const [isMenuOpen, setIsMenuOpen] = useState<{ [key: string]: boolean }>({});
  const [packingDetails, setPackingDetails] = useState<PackingDetail[]>([]);
  const [trackingInfo, setTrackingInfo] = useState<TrackingInfo[]>([]);
  const [depositor, setDepositor] = useState("");
  const [depositorFee, setDepositorFee] = useState("");
  const [paymentLink, setPaymentLink] = useState("");

  const [imageToShow, setImageToShow] = useState("");
  const [showImageModal, setShowImageModal] = useState(false);

  const handleImageClick = (imageUrl: string) => {
    setImageToShow(imageUrl);
    setShowImageModal(true);
  };

  const closeImageModal = () => {
    setShowImageModal(false);
  };

  const [orderDetails, setOrderDetails] = useState<OrderDetails>({
    products: [],
    user: defaultUser,
    optionBox: 0,
    status: 0,
    optionLetter: false,
    optionPoster: false,
    optionAircap: false,
    optionBag: false,
    optionSticker: false,
    adrReceiver: "",
    adrEmail: "",
    adrPhone: "",
    adrCountry: 0,
    useVacuumPackagingBag: 0,
    adrPCCC: "",
    adrZip: "",
    adrState: "",
    adrCity: "",
    adrOption1: "",
    adrOption2: "",
    adrOption3: "",
    estimatedAt: "",
    expiredAt: "",
    weightKg: 0,
    heightCm: 0,
    widthCm: 0,
    lengthCm: 0,
    depositor: "",
    DepositorFee: "",
    link: "",
    suspectBattery: false,
    food: false,
    packingStatus: 0,
    customerMessage: "",
    managerMessage: "",
    FEDEXPriceKRW: 0,
    FEDEXPriceUSD: 0,
    UPSPriceKRW: 0,
    UPSPriceUSD: 0,
    EMSPriceKRW: 0,
    EMSPriceUSD: 0,
    PACKETPriceKRW: 0,
    PACKETPriceUSD: 0,
    CJPriceKRW: 0,
    CJPriceUSD: 0,
    company: 0,
    PaymentMethod: 0,
    usePointKrw: 0,
    usePointUse: 0,
    discountCouponKrw: 0,
    discountCouponUsd: 0,
  });

  const handleSelect = (k: any) => {
    setKey(k);
  };

  useEffect(() => {
    if (orderNumber) {
      fetchOrderDetails();
    }
  }, [orderNumber]);

  const fetchOrderDetails = async () => {
    try {
      const response = await reqShip({ id: orderNumber });
      const orderData: OrderDetails = response.data;

      const availableShippingOptions: ShippingOptions = {
        FEDEX: { KRW: orderData.FEDEXPriceKRW, USD: orderData.FEDEXPriceUSD },
        UPS: {
          KRW: orderData.UPSPriceKRW ?? null,
          USD: orderData.UPSPriceUSD ?? null,
        },
        EMS: {
          KRW: orderData.EMSPriceKRW ?? null,
          USD: orderData.EMSPriceUSD ?? null,
        },
        PACKET: {
          KRW: orderData.PACKETPriceKRW ?? null,
          USD: orderData.PACKETPriceUSD ?? null,
        },
        CJ: {
          KRW: orderData.CJPriceKRW ?? null,
          USD: orderData.CJPriceUSD ?? null,
        },
      };

      const filteredOptions = Object.entries(availableShippingOptions)
        .filter(([_, rates]) => rates.KRW !== null && rates.USD !== null)
        .reduce((acc, [carrier, rates]) => ({ ...acc, [carrier]: rates }), {});

      setShippingOptions(filteredOptions);
      setOrderDetails(orderData);
      const excluded = orderData.products.filter((p: Product) => !p.boxed);
      setExcludedProducts(excluded);
    } catch (error) {
      console.error("Error fetching order details:", error);
    }
  };

  const [warehouseTotalKRW, warehouseTotalUSD] = [
    orderDetails.products
      .filter((product) => product.boxed !== 0)
      .reduce(
        (acc, product) =>
          acc +
          (isNaN(Number(product.overstoreFee))
            ? 0
            : Math.round(Number(product.overstoreFee) * exchangeRate)) +
          product.Extra,
        0
      )
      .toLocaleString("ko-KR"),
    orderDetails.products
      .filter((product) => product.boxed !== 0)
      .reduce(
        (acc, product) =>
          acc +
          (isNaN(Number(product.overstoreFee))
            ? 0
            : Number(product.overstoreFee)) +
          product.Extra / exchangeRate,
        0
      )
      .toFixed(2),
  ];

  const [exchangeRateN, setExchangeRateN] = useState<number>(0);

  // const fetchExchangeRateN = () => {
  //   fetch(
  //     "https://quotation-api-cdn.dunamu.com/v1/forex/recent?codes=FRX.KRWUSD"
  //   )
  //     .then((response) => response.json())
  //     .then((data) => {
  //       const rate = data[0].basePrice;
  //       setExchangeRateN(rate);
  //     })
  //     .catch((error) => console.error("Error fetching exchange rate:", error));
  // };

  const [fixedExchangeRate, setFixedExchangeRate] = useState(0);
  const fetchFixedExchangeRate = async () => {
    try {
      const response = await reqShipFEE();
      if (
        response &&
        response.data &&
        response.data[0]?.fixedExchangeRateShop
      ) {
        setFixedExchangeRate(
          parseFloat(response.data[0].fixedExchangeRateShop)
        );
      }
    } catch (error) {
      console.error("Error fetching fixed exchange rate:", error);
    }
  };

  useEffect(() => {
    // fetchExchangeRateN();
    fetchFixedExchangeRate();
  });

  const convertToKRW = (usd: number) => {
    return Math.floor(usd * exchangeRate).toLocaleString();
  };

  const convertToUSD = (krw: number) => {
    return (krw / exchangeRate).toFixed(2);
  };

  useEffect(() => {
    fetchExchangeRate();
  }, []);

  const fetchExchangeRate = async () => {
    try {
      const response = await reqShipFEE();
      const fixedExchangeRate = response.data[0]?.fixedExchangeRate;
      if (fixedExchangeRate) {
        setExchangeRate(parseFloat(fixedExchangeRate));
      } else {
        console.error("Failed to fetch fixed exchange rate: No data");
      }
    } catch (error) {
      console.error("Error fetching fixed exchange rate:", error);
    }
  };

  useEffect(() => {
    const fetchTrackingInfo = async () => {
      try {
        const response = await reqShipTrackingGet({ id: orderNumber });
        setTrackingInfo(response.data);
      } catch (error) {
        console.error("Error fetching tracking information: ", error);
      }
    };

    if (orderNumber) {
      fetchTrackingInfo();
    }
  }, [orderNumber]);

  const handleUpdate = async () => {
    const now = new Date();
    const estimatedAt = now.toISOString();

    const expiredAt = paymentDeadline;

    await reqShipProductDetail({
      id: orderNumber,
    });

    await reqShipOrderUpdate({
      id: orderNumber,
      expiredAt: expiredAt,
    });

    onRepackRequest();
    onHide();
    refreshData();
  };

  const handleShippingOptionChange = (carrier: string) => {
    setCheckedShippingOptions((prev) => ({
      ...prev,
      [carrier]: !prev[carrier],
    }));
  };

  const getShippingCompanyName = (company: number) => {
    const shippingCompanies: { [key: number]: string } = {
      1: "FEDEX",
      2: "UPS",
      3: "EMS",
      4: "PACKET",
      5: "CJ",
    };
    return shippingCompanies[company] || "정보없음";
  };

  const calculateTotalKRWForCheckedOptions = (): number => {
    return Object.entries(shippingOptions)
      .filter(([carrier]) => checkedShippingOptions[carrier])
      .reduce((total, [_, rates]) => total + (rates.KRW ?? 0), 0);
  };

  const handleback = async () => {
    await reqShipUpdateOrder({
      id: orderNumber,
      status: ACODE_SHOPING_ORDER_STATUS.WAIT_PAY,
    });
    onHide();
    refreshData();
  };

  const handleRepackageClick = async () => {
    await reqShipUpdateOrder({
      id: orderNumber,
      status: ACODE_SHOPING_ORDER_STATUS.PRODUCT_COMPLETION,
    });

    const totalKRW = calculateTotalKRWForCheckedOptions();
    setDepositAmount(totalKRW.toString());
    setShowKRWPModal(true);
    onRepackRequest();
    onHide();
    refreshData();
  };

  const handleKRWPaymentSubmit = async () => {
    try {
      const depositorNameToSend = depositorName;
      const depositAmountToSend = depositAmount.toString();
      const linkToSend = link;

      await reqShipOrderUpdate({
        id: orderNumber,
        depositor: depositorNameToSend,
        DepositorFee: depositAmountToSend,
        link: linkToSend,
      });

      console.log("KRW Payment submitted successfully");

      onRepackRequest();
      onHide();
      refreshData();
    } catch (error) {
      console.error("Failed to process KRW payment: ", error);
    }
  };

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const response = await reqShip({ id: orderNumber });
        setOrderDetails(response.data);
      } catch (error) {
        console.error("Error fetching order details: ", error);
      }
    };

    if (orderNumber) {
      fetchOrderDetails();
    }
    const fetchPackingDetails = async () => {
      try {
        const response = await reqShipDetail({ order_id: orderNumber });
        setPackingDetails(response.data);
      } catch (error) {
        console.error("Error fetching packing details: ", error);
      }
    };

    if (orderNumber) {
      fetchPackingDetails();
    }
  }, [orderNumber]);

  const getPackingStatusText = (status: number) => {
    switch (status) {
      case 1:
        return "전체포장완료";
      case 2:
        return "공간 부족";
      case 3:
        return "30kg 초과";
      default:
        return "정보 없음";
    }
  };

  const calculateVolumeWeight5000 = (
    width: number,
    length: number,
    height: number
  ) => {
    return (width * length * height) / 5000;
  };

  const calculateVolumeWeight6000 = (
    width: number,
    length: number,
    height: number
  ) => {
    return (width * length * height) / 6000;
  };

  const roundToFirstDecimal = (num: any) => {
    const integerPart = Math.floor(num);
    const decimalPart = num - integerPart;

    if (decimalPart <= 0.5) {
      return integerPart + 0.5;
    } else {
      return integerPart + 1;
    }
  };

  const calculateOptionCosts = () => {
    const costs = {
      stickerPerBox: 2000,
      aircapPerBox: 3000,
      insurancePerBox: 5000,
      vacuumPackPerTracking: 5000,
    };

    const actualBoxCount = packingDetails.filter(
      (detail) => !detail.isDeleted
    ).length;

    let totalCostKRW = 0;
    let totalCostUSD = 0;

    if (orderDetails.optionSticker) {
      totalCostKRW += costs.stickerPerBox * actualBoxCount;
      totalCostUSD += (costs.stickerPerBox * actualBoxCount) / exchangeRate;
    }
    if (orderDetails.optionAircap) {
      totalCostKRW += costs.aircapPerBox * actualBoxCount;
      totalCostUSD += (costs.aircapPerBox * actualBoxCount) / exchangeRate;
    }
    if (orderDetails.optionBag) {
      totalCostKRW += costs.insurancePerBox * actualBoxCount;
      totalCostUSD += (costs.insurancePerBox * actualBoxCount) / exchangeRate;
    }

    const productsUsingVacuumPack = orderDetails.products.filter(
      (product) => product.bag === true && product.boxed !== 0
    );
    totalCostKRW +=
      costs.vacuumPackPerTracking * orderDetails.useVacuumPackagingBag;
    totalCostUSD +=
      (costs.vacuumPackPerTracking * orderDetails.useVacuumPackagingBag) /
      exchangeRate;

    return { totalCostKRW, totalCostUSD };
  };

  const { totalCostKRW, totalCostUSD } = calculateOptionCosts();

  const totalWeightSum = packingDetails.reduce((sum, detail) => {
    const weight = Number(detail.totalWeightKg);
    return sum + (isNaN(weight) ? 0 : weight);
  }, 0);

  const membershipDiscounts = {
    1: 1.0,
    2: 0.25,
    3: 0.0,
  };

  const calculateMembershipDiscountRate = () => {
    const membershipDiscountRates = {
      1: 100,
      2: 25,
      3: 0,
    };

    const discountRate =
      membershipDiscountRates[
        orderDetails.user.membershipId as keyof typeof membershipDiscountRates
      ] || 0;
    return discountRate;
  };

  const [isZoomed, setIsZoomed] = useState(false);

  // const handleImageClick = () => {
  //   setIsZoomed(!isZoomed);
  // };

  const calculateRepackFee = () => {
    const boxCounts = new Map();
    orderDetails.products.forEach((product) => {
      boxCounts.set(product.boxed, (boxCounts.get(product.boxed) || 0) + 1);
    });

    let totalRepackFeeKRW = 0;
    let totalRepackFeeUSD = 0;

    boxCounts.forEach((count, boxNumber) => {
      if (count > 1) {
        const packingDetail = packingDetails[boxNumber - 1];
        if (packingDetail) {
          const repackFeePerBox = packingDetail.totalWeightKg * 1000;
          totalRepackFeeKRW += repackFeePerBox;
          totalRepackFeeUSD += repackFeePerBox / 1000;
        }
      }
    });

    let discountRate =
      membershipDiscounts[
        orderDetails.user.membershipId as keyof typeof membershipDiscounts
      ] || 0;
    totalRepackFeeKRW *= 1 - discountRate;
    totalRepackFeeUSD *= 1 - discountRate;

    return {
      repackFeeKRW: totalRepackFeeKRW.toLocaleString("ko-KR"),
      repackFeeUSD: totalRepackFeeUSD.toFixed(2),
    };
  };

  const { repackFeeKRW, repackFeeUSD } = calculateRepackFee();
  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            견적서 - {orderNumber}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Tabs activeKey={key} onSelect={handleSelect} className="mb-3">
            <Tab eventKey="요청정보" title="요청정보">
              <div className="request-info">
                <div>
                  <strong>서비스 옵션:</strong>
                  <ul>
                    <Form.Check
                      label="한박스만 포장"
                      disabled
                      checked={orderDetails.optionBox === 0}
                      onChange={(e) =>
                        setOrderDetails({
                          ...orderDetails,
                          optionBox: e.target.checked ? 1 : 0,
                        })
                      }
                    />
                    <Form.Check
                      label="편지봉투 박스 포장"
                      disabled
                      checked={orderDetails.optionLetter}
                      onChange={(e) =>
                        setOrderDetails({
                          ...orderDetails,
                          optionLetter: e.target.checked,
                        })
                      }
                    />
                    <Form.Check
                      label="포스터 튜브 최소화"
                      disabled
                      checked={orderDetails.optionPoster}
                      onChange={(e) =>
                        setOrderDetails({
                          ...orderDetails,
                          optionPoster: e.target.checked,
                        })
                      }
                    />
                    <Form.Check
                      disabled
                      label="에어캡 추가"
                      checked={orderDetails.optionAircap}
                      onChange={(e) =>
                        setOrderDetails({
                          ...orderDetails,
                          optionAircap: e.target.checked,
                        })
                      }
                    />
                    <Form.Check
                      disabled
                      label="스티커 4면 부착"
                      checked={orderDetails.optionSticker}
                      onChange={(e) =>
                        setOrderDetails({
                          ...orderDetails,
                          optionSticker: e.target.checked,
                        })
                      }
                    />
                    <Form.Check
                      disabled
                      label="보험가입 유무"
                      checked={orderDetails.optionBag}
                      onChange={(e) =>
                        setOrderDetails({
                          ...orderDetails,
                          optionBag: e.target.checked,
                        })
                      }
                    />
                  </ul>
                </div>
              </div>
              <table className="table text-center table-hover">
                <thead>
                  <tr>
                    <th scope="col">NO</th>
                    <th scope="col">운송장번호</th>
                    <th scope="col">창고비용</th>
                    <th scope="col">추가비용</th>
                    <th scope="col">필수포함</th>
                    <th scope="col">진공포장백</th>
                    <th scope="col">BOX</th>
                  </tr>
                </thead>
                <tbody>
                  {orderDetails.products.map((product, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td
                        style={{
                          textDecoration:
                            product.boxed === 0 ? "line-through" : "none",
                        }}
                      >
                        {product.tracking}
                      </td>
                      <td>
                        ${product.overstoreFee} / ₩
                        {convertToKRW(product.overstoreFee)}
                      </td>
                      <td>
                        ${convertToUSD(product.Extra)} / ₩{product.Extra}
                      </td>
                      <td>
                        <Form.Check
                          type="checkbox"
                          checked={product.isUrgent}
                          disabled
                        />
                      </td>
                      <td>
                        <Form.Check
                          type="checkbox"
                          checked={product.bag}
                          disabled
                        />
                      </td>
                      <td>{product.boxed}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Tab>
            <Tab eventKey="미포함상품" title="미포함상품">
              <div>
                <Form.Check
                  type="checkbox"
                  id="batteryCheck"
                  label="배터리 포함 의심상품"
                  checked={packingDetails.some(
                    (detail) => detail.suspectBattery
                  )}
                  disabled
                />
                <Form.Check
                  type="checkbox"
                  id="foodCheck"
                  label="음식"
                  checked={packingDetails.some((detail) => detail.food)}
                  disabled
                />
                <div>
                  포장 상태: {getPackingStatusText(orderDetails.packingStatus)}
                </div>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>NO</th>
                      <th>운송장번호</th>
                      <th>창고비용</th>
                      <th>추가비용</th>
                      <th>필수포함</th>
                      <th>진공포장백</th>
                      <th>BOX</th>
                    </tr>
                  </thead>
                  <tbody>
                    {excludedProducts.map((product, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{product.tracking}</td>
                        <td>
                          ${product.overstoreFee} / ₩
                          {convertToKRW(product.overstoreFee)}
                        </td>
                        <td>
                          ${convertToUSD(product.Extra)} / ₩{product.Extra}
                        </td>
                        <td>
                          <Form.Check
                            type="checkbox"
                            checked={product.isUrgent}
                            disabled
                          />
                        </td>
                        <td>
                          <Form.Check
                            type="checkbox"
                            checked={product.bag}
                            disabled
                          />
                        </td>
                        <td>{"-"}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Tab>
            <Tab eventKey="주소" title="주소">
              <div className="shipping-address">
                <h4>배송 주소</h4>
                <Form>
                  <Form.Group className="mb-3" controlId="formAddressReceiver">
                    <Form.Label>받는 사람</Form.Label>
                    <Form.Control
                      type="text"
                      disabled
                      placeholder="받는 사람 이름"
                      defaultValue={orderDetails.adrReceiver}
                    />
                  </Form.Group>
                  <Form.Group className="mb-1">
                    <Form.Label>이메일</Form.Label>
                    <Form.Control
                      type="text"
                      disabled
                      defaultValue={orderDetails.adrEmail}
                    />
                  </Form.Group>
                  <Form.Group className="mb-1">
                    <Form.Label>폰번호</Form.Label>
                    <Form.Control
                      type="text"
                      disabled
                      defaultValue={orderDetails.adrPhone}
                    />
                  </Form.Group>
                  <Form.Group className="mb-1">
                    <Form.Label>국가</Form.Label>
                    <Form.Control
                      type="text"
                      disabled
                      value={
                        CODE_COUNTRY_ITEMS[Number(orderDetails.adrCountry)] ||
                        "Country not selected"
                      }
                    />
                  </Form.Group>
                  <Form.Group className="mb-1">
                    <Form.Label>개인통관부호</Form.Label>
                    <Form.Control
                      type="text"
                      disabled
                      defaultValue={orderDetails.adrPCCC}
                    />
                  </Form.Group>
                  <Form.Group className="mb-1">
                    <Form.Label>Zipcode</Form.Label>
                    <Form.Control
                      type="text"
                      disabled
                      defaultValue={orderDetails.adrZip}
                    />
                  </Form.Group>
                  <Form.Group className="mb-1">
                    <Form.Label>State</Form.Label>
                    <Form.Control
                      type="text"
                      disabled
                      defaultValue={orderDetails.adrState}
                    />
                  </Form.Group>
                  <Form.Group className="mb-1">
                    <Form.Label>City</Form.Label>
                    <Form.Control
                      type="text"
                      disabled
                      defaultValue={orderDetails.adrCity}
                    />
                  </Form.Group>
                  <Form.Group className="mb-1">
                    <Form.Label>Address-1(35)</Form.Label>
                    <Form.Control
                      type="text"
                      disabled
                      defaultValue={orderDetails.adrOption1}
                    />
                  </Form.Group>
                  <Form.Group className="mb-1">
                    <Form.Label>Address-2(35)</Form.Label>
                    <Form.Control
                      type="text"
                      disabled
                      defaultValue={orderDetails.adrOption2}
                    />
                  </Form.Group>
                  <Form.Group className="mb-1">
                    <Form.Label>Address-3(35)</Form.Label>
                    <Form.Control
                      type="text"
                      disabled
                      defaultValue={orderDetails.adrOption3}
                    />
                  </Form.Group>
                </Form>
              </div>
            </Tab>
            <Tab eventKey="포장정보" title="포장정보">
              <div className="packing-info">
                <h4>포장 정보</h4>
                {/* 포장 상세 정보 표시 */}
                <div className="table-responsive">
                  <Table bordered className="table-custom text-center">
                    <thead className="table-light">
                      <tr>
                        <th rowSpan={2} style={{ width: "15%" }}>
                          택배 사진
                        </th>
                        <th rowSpan={2} style={{ width: "10%" }}>
                          상품 ID
                        </th>
                        <th rowSpan={2} style={{ width: "10%" }}>
                          무게 (KG)
                        </th>
                        <th rowSpan={2} style={{ width: "10%" }}>
                          가로 (CM)
                        </th>
                        <th rowSpan={2} style={{ width: "10%" }}>
                          세로 (CM)
                        </th>
                        <th rowSpan={2} style={{ width: "10%" }}>
                          높이 (CM)
                        </th>
                        <th colSpan={2} style={{ width: "20%" }}>
                          적용 중량
                        </th>
                      </tr>
                      <tr>
                        <th style={{ width: "10%" }}>FedEx, UPS</th>
                        <th style={{ width: "10%" }}>EMS, K-Packet</th>
                      </tr>
                    </thead>
                    <tbody>
                      {packingDetails
                        .filter((detail) => !detail.isDeleted)
                        .map((detail, index) => {
                          const volumeWeight5000 = calculateVolumeWeight5000(
                            detail.totalWidthCm,
                            detail.totalLengthCm,
                            detail.totalHeightCm
                          );
                          const volumeWeight6000 = calculateVolumeWeight6000(
                            detail.totalWidthCm,
                            detail.totalLengthCm,
                            detail.totalHeightCm
                          );

                          const roundedWeight5000 =
                            roundToFirstDecimal(volumeWeight5000);
                          const roundedWeight6000 =
                            roundToFirstDecimal(volumeWeight6000);

                          const displayWeight5000 = Math.max(
                            detail.totalWeightKg,
                            roundedWeight5000
                          );
                          const displayWeight6000 = Math.max(
                            detail.totalWeightKg,
                            roundedWeight6000
                          );

                          return (
                            <tr key={index}>
                              <td>
                                {detail.imageUrl ? (
                                  <img
                                    src={detail.imageUrl}
                                    alt="Attached File"
                                    style={{
                                      maxWidth: isZoomed ? "22rem" : "10rem",
                                      height: "auto",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      handleImageClick(detail.imageUrl)
                                    }
                                  />
                                ) : (
                                  "No image available"
                                )}
                              </td>
                              <td>{detail.id}</td>
                              <td>{detail.totalWeightKg}</td>
                              <td>{detail.totalWidthCm}</td>
                              <td>{detail.totalLengthCm}</td>
                              <td>{detail.totalHeightCm}</td>
                              <td>{displayWeight5000}</td>
                              <td>{displayWeight6000}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Tab>
            <Tab eventKey="견적서" title="견적서">
              <div className="invoice-info">
                <h2>견적서</h2>
                <div className="summary">
                  <table className="table table-responsive">
                    <thead>
                      <tr>
                        <th>배송국가</th>
                        <th>요청수량</th>
                        <th>미포함수량</th>
                        <th>미포함사유</th>
                        <th>박스수량</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {CODE_COUNTRY_ITEMS[
                            Number(orderDetails.adrCountry)
                          ] || "Country not selected"}
                        </td>
                        <td>{orderDetails.products.length}</td>
                        <td>
                          {
                            orderDetails.products.filter(
                              (product) => !product.boxed
                            ).length
                          }
                        </td>
                        <td>
                          {getPackingStatusText(orderDetails.packingStatus)}
                        </td>
                        <td>
                          {
                            packingDetails.filter((detail) => !detail.isDeleted)
                              .length
                          }
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                {/* 포장비용 */}
                <div className="repack-fee mt-4">
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th colSpan={3} className="text-center">
                          포장 비용
                        </th>
                      </tr>
                      <tr>
                        <td>박스수량</td>
                        <td colSpan={2} className="text-center">
                          {
                            packingDetails.filter((detail) => !detail.isDeleted)
                              .length
                          }
                        </td>
                      </tr>
                      <tr>
                        <td>무게합계</td>
                        <td colSpan={2} className="text-center">
                          {Number(totalWeightSum).toFixed(2)} KG
                        </td>
                      </tr>
                      <tr>
                        <td>멤버쉅 할인율 (%)</td>
                        <td colSpan={2} className="text-center">
                          {calculateMembershipDiscountRate()}%
                        </td>
                      </tr>
                      <tr className="table-primary">
                        <th>Total</th>
                        <td className="text-center">KRW {repackFeeKRW}</td>
                        <td className="text-center">USD {repackFeeUSD}</td>
                      </tr>
                    </thead>
                    <tbody></tbody>
                  </Table>
                </div>
                {/* 옵션비용 */}
                <div className="option-fee">
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th colSpan={3} className="text-center">
                          옵션 비용
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>스티커 부착</td>
                        <td colSpan={2} className="text-center">
                          {orderDetails.optionSticker
                            ? `${packingDetails.length}개`
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td>에어캡 추가</td>
                        <td colSpan={2} className="text-center">
                          {orderDetails.optionAircap
                            ? `${packingDetails.length}개`
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td>보험</td>
                        <td colSpan={2} className="text-center">
                          {orderDetails.optionBag
                            ? `${packingDetails.length}개`
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td>진공포장백 사용</td>
                        <td colSpan={2} className="text-center">
                          {orderDetails.useVacuumPackagingBag
                            ? `${orderDetails.useVacuumPackagingBag}개`
                            : "-"}
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr className="table-primary">
                        <th>Total</th>
                        <td className="text-center">
                          KRW {totalCostKRW.toLocaleString("ko-KR")}
                        </td>
                        <td className="text-center">
                          USD {totalCostUSD.toFixed(2)}
                        </td>
                      </tr>
                    </tfoot>
                  </Table>
                </div>
                {/* 웨어하우스 비용 */}
                <div className="warehouse-fee">
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th colSpan={3} className="text-center">
                          웨어하우스 비용
                        </th>
                      </tr>
                      <tr>
                        <th className="text-center">비용</th>
                        <th className="text-center">KRW</th>
                        <th className="text-center">USD</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="text-center">웨어하우스 수수료</td>
                        <td className="text-center">
                          ₩
                          {orderDetails.products
                            .filter((product) => product.boxed !== 0)
                            .reduce(
                              (acc, product) =>
                                acc +
                                Math.round(product.overstoreFee * exchangeRate),
                              0
                            )
                            .toLocaleString("ko-KR")}
                        </td>
                        <td className="text-center">
                          $
                          {orderDetails.products
                            .filter((product) => product.boxed !== 0)
                            .reduce(
                              (acc, product) => acc + product.overstoreFee,
                              0
                            )
                            .toFixed(2)}
                        </td>
                      </tr>
                      <tr>
                        <td className="text-center">엑스트라 차지</td>
                        <td className="text-center">
                          ₩
                          {orderDetails.products
                            .filter((product) => product.boxed !== 0)
                            .reduce((acc, product) => acc + product.Extra, 0)
                            .toLocaleString("ko-KR")}
                        </td>
                        <td className="text-center">
                          $
                          {orderDetails.products
                            .filter((product) => product.boxed !== 0)
                            .reduce(
                              (acc, product) =>
                                acc + product.Extra / exchangeRate,
                              0
                            )
                            .toFixed(2)}
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr className="table-primary">
                        <th className="text-center">Total</th>
                        <td className="text-center">₩{warehouseTotalKRW}</td>
                        <td className="text-center">${warehouseTotalUSD}</td>
                      </tr>
                    </tfoot>
                  </Table>
                </div>
                {/* 배송비 */}
                <div className="shipping-fee">
                  <h5 className="mb-3">배송비</h5>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>배송사</th>
                        <th>KRW</th>
                        <th>USD</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.entries(shippingOptions).map(
                        ([carrier, rates], index) => (
                          <tr
                            key={index}
                            style={
                              getShippingCompanyName(orderDetails.company) ===
                              carrier
                                ? { backgroundColor: "#f8d7da" }
                                : {}
                            }
                          >
                            <td>
                              <input
                                type="checkbox"
                                onChange={() =>
                                  handleShippingOptionChange(carrier)
                                }
                                checked={!!checkedShippingOptions[carrier]}
                              />
                            </td>
                            <td
                              style={
                                getShippingCompanyName(orderDetails.company) ===
                                carrier
                                  ? { color: "red" }
                                  : {}
                              }
                            >
                              {carrier}
                            </td>
                            <td>{rates.KRW?.toLocaleString() || "-"}</td>
                            <td>{rates.USD ? rates.USD.toFixed(2) : "-"}</td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </Table>
                </div>
                {/* 고객 메시지 입력 폼 */}
                <div className="manager-message mb-3">
                  <Card>
                    <Card.Header>담당자가 보내는 메시지</Card.Header>
                    <Card.Body>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="담당자 메시지 입력"
                        defaultValue={orderDetails.customerMessage}
                        readOnly
                      />
                    </Card.Body>
                  </Card>
                </div>
              </div>
            </Tab>
            {orderDetails.PaymentMethod === 1 && (
              <Tab eventKey="KRW결제" title="KRW 결제">
                <div className="krw-payment">
                  <Form>
                    <Row>
                      <Col sm={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>입금자명</Form.Label>
                          <Form.Control
                            type="text"
                            value={orderDetails.depositor}
                            readOnly
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>입금 금액(₩)</Form.Label>
                          <Form.Control
                            type="text"
                            value={orderDetails.DepositorFee}
                            readOnly
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>할인 쿠폰 금액(₩)</Form.Label>
                          <Form.Control
                            type="text"
                            value={orderDetails.discountCouponKrw}
                            readOnly
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>할인 포인트 금액(₩)</Form.Label>
                          <Form.Control
                            type="text"
                            value={orderDetails.usePointKrw}
                            readOnly
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={12}>
                        <Form.Group className="mb-3">
                          <Form.Label>Link</Form.Label>
                          {orderDetails.link ? (
                            <a
                              href={`http${
                                orderDetails.link.includes("://") ? "" : "s://"
                              }${orderDetails.link}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                display: "block",
                                padding: "0.375rem 0.75rem",
                                fontSize: "1rem",
                                lineHeight: "1.5",
                                color: "blue",
                                backgroundColor: "#fff",
                                border: "1px solid #ced4da",
                                borderRadius: "0.25rem",
                                textDecoration: "none",
                                width: "100%",
                                maxWidth: "100%",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {orderDetails.link}
                            </a>
                          ) : (
                            <div
                              style={{
                                display: "block",
                                padding: "0.375rem 0.75rem",
                                fontSize: "1rem",
                                lineHeight: "1.5",
                                color: "gray",
                                backgroundColor: "#fff",
                                border: "1px solid #ced4da",
                                borderRadius: "0.25rem",
                                width: "100%",
                                maxWidth: "100%",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              No link provided
                            </div>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Table bordered>
                      <tbody>
                        <tr>
                          <th>Bank</th>
                          <td>Woori bank</td>
                        </tr>
                        <tr>
                          <th>account holder</th>
                          <td>ONTACT KOREA</td>
                        </tr>
                        <tr>
                          <th>bank account number</th>
                          <td>1005-804-179216</td>
                        </tr>
                        <tr>
                          <th>입금금액</th>
                          <td>{orderDetails.DepositorFee}</td>
                        </tr>
                      </tbody>
                    </Table>
                    <div className="text-danger fw-bold">NOTICE</div>
                    <p>
                      원화입금은 온택트코리아에서 입금확인전까지는 결제
                      대기중으로 처리되며 확인 후 결제완료로 변경됩니다.
                    </p>
                  </Form>
                </div>
              </Tab>
            )}
            {(orderDetails.PaymentMethod === 2 ||
              orderDetails.PaymentMethod === 3) && (
              <Tab eventKey="USD결제" title="USD 결제">
                <div className="krw-payment">
                  <Form>
                    <Row>
                      <Col sm={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>입금자명</Form.Label>
                          <Form.Control
                            type="text"
                            value={orderDetails.depositor}
                            readOnly
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>입금금액($)</Form.Label>
                          <Form.Control
                            type="text"
                            value={orderDetails.DepositorFee}
                            readOnly
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>할인 쿠폰 금액($)</Form.Label>
                          <Form.Control
                            type="text"
                            value={orderDetails.discountCouponUsd}
                            readOnly
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>할인 포인트 금액($)</Form.Label>
                          <Form.Control
                            type="text"
                            value={orderDetails.usePointUse}
                            readOnly
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>결제수단</Form.Label>
                          <Form.Control
                            type="text"
                            value={
                              orderDetails.PaymentMethod === 2
                                ? "PayPal"
                                : orderDetails.PaymentMethod === 3
                                ? "Other"
                                : ""
                            }
                            readOnly
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </Tab>
            )}
          </Tabs>
        </Modal.Body>
        <Modal.Footer>
          <div className="w-100 mb-3">
            <InputGroup>
              <InputGroup.Text>결제마감일</InputGroup.Text>
              <Form.Control
                defaultValue={
                  orderDetails.expiredAt
                    ? orderDetails.expiredAt.slice(0, 10)
                    : ""
                }
                readOnly
              />
            </InputGroup>
            <InputGroup>
              <InputGroup.Text>결제마감일</InputGroup.Text>
              <Form.Control
                type="date"
                value={paymentDeadline}
                onChange={(e) => setPaymentDeadline(e.target.value)}
              />
            </InputGroup>
          </div>
          <div className="d-flex justify-content-between">
            {orderDetails.status === 4 && (
              <Button variant="primary" onClick={handleback} className="me-3">
                관리자 견적서
              </Button>
            )}

            <Button
              variant="secondary"
              onClick={handleRepackageClick}
              className="me-3"
            >
              입금 확인
            </Button>
            <Button variant="primary" onClick={handleUpdate} className="me-3">
              수정
            </Button>
            <Button variant="danger" onClick={onHide}>
              닫기
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal show={showImageModal} onHide={closeImageModal} size="xl" centered>
        <Modal.Header closeButton>
          <Modal.Title>Image Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img
            src={imageToShow}
            alt="Enlarged"
            style={{ width: "100%", maxHeight: "80vh", objectFit: "contain" }}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}
