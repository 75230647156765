import { AxiosManager } from "../axiosManager";

// 모든 알림 송신
export function reqEveryAlarm(payload: any) {
  return AxiosManager.get("alarm/every", {
    params: payload,
  });
}

// 단일 알림 읽음 처리
export function reqReadSign(payload: any) {
  return AxiosManager.post("alarm/read", payload);
}

// 단일 알림 삭제 처리
export function reqDeleteAlarm(payload: any) {
  return AxiosManager.post("alarm/delete", payload);
}

// 모든 읽은 알림 삭제 처리
export function reqDeleteAllAlarm(payload: any) {
  return AxiosManager.get("alarm/all-delete", {
    params: payload,
  });
}

export function reqSendAlarm(payload: any) {
  return AxiosManager.post("alarm/send", payload);
}

export function reqSendBroadcastAlarm(payload: any) {
  return AxiosManager.post("alarm/send/broadcast", payload);
}
