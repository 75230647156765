import styled from "styled-components";
import Icon from "../../../components/Icon";
import {
  Button,
  FormCheck,
  FormControl,
  InputGroup,
  Table,
} from "react-bootstrap";
import MTitle from "../MTitle";

import { useEffect, useState } from "react";
import { reqUserGetShipWaitShipping } from "../../../requests/user";
import { DATA_TYPE, DATE_FORMAT } from "../../../common/constants";
import { format } from "date-fns";
import FormatData from "../../../components/FormatData";
import { useDispatch } from "react-redux";
import { modalOpen } from "../../../redux/modalSlice";
import { MODAL_TYPE } from "../../../components/modal/GlobalModal";
import { useSelector } from "react-redux";
import { RootUserState } from "../../../redux/store";
import { isMobile } from "react-device-detect";
import CustomerPageTitle from "../../../components/CustomerPageTitle";
import { reqShipFEE } from "../../../requests/ship";
export default function CShipSendOrder() {
  const dispatch = useDispatch();
  const modal = useSelector((state: any) => state.modal);
  const user = useSelector((state: RootUserState) => state.user);
  const pageCount = 10;
  const [totalCheck, setTotalCheck] = useState<boolean>(false);
  const [searchWord, setSearchWord] = useState<string>("");
  const [exchangeRate, setExchangeRate] = useState<number>(0);
  const [data, setData] = useState<any[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [checked, setChecked] = useState<boolean[]>([]);

  const rf = async () => {
    const result = await reqUserGetShipWaitShipping({
      userId: user.id,
      page: 1,
      pageCount,
      searchWord,
    });

    setData(result.data.list);
    setTotalCount(result.data.totalCount);
    setChecked(Array<boolean>(result.data.totalCount).fill(false));
  };

  const isShippable = data.some((v, index) => checked[index] && v.status !== 1);

  // const fetchExchangeRate = () => {
  //   fetch(
  //     "https://quotation-api-cdn.dunamu.com/v1/forex/recent?codes=FRX.KRWUSD"
  //   )
  //     .then((response) => response.json())
  //     .then((data) => {
  //       const rate = data[0].basePrice;
  //       setExchangeRate(rate);
  //     })
  //     .catch((error) => console.error("Error fetching exchange rate:", error));
  // };

  const fetchFixedExchangeRate = async () => {
    try {
      const response = await reqShipFEE();
      if (response && response.data && response.data[0]?.fixedExchangeRateShop) {
        setFixedExchangeRate(parseFloat(response.data[0].fixedExchangeRateShop));
      }
    } catch (error) {
      console.error("Error fetching fixed exchange rate:", error);
    }
  };
  const [fixedExchangeRate, setFixedExchangeRate] = useState(0);

  useEffect(() => {
    // fetchExchangeRate();
    fetchFixedExchangeRate();
  }, [data]);

  useEffect(() => {
    rf();
  }, [user?.id]);

  useEffect(() => {
    if (checked.includes(false)) {
      setTotalCheck(false);
    } else {
      setTotalCheck(true);
    }
  }, [checked]);

  const convertToKRW = (usd: number) => {
    return Math.floor(usd * 1000).toLocaleString();
  };

  const convertToUSD = (krw: number) => {
    return (krw / fixedExchangeRate).toFixed(2);
  };

  const handleKeyPress = (event: any) => {
    if (event.key === " ") {
      event.preventDefault();
    }
  };

  const handleTotalCheckChange = (e: any) => {
    const isChecked = e.target.checked;
    setTotalCheck(isChecked);
    setChecked(Array<boolean>(data.length).fill(isChecked));
  };

  const handleCheckChange = (index: number) => {
    const newChecked = [...checked];
    newChecked[index] = !newChecked[index];
    setChecked(newChecked);
  };

  return (
    <>
      <div style={{ padding: isMobile ? "1.4rem" : "30px" }}>
        {isMobile ? (
          <MobileWrapper>
            <CustomerPageTitle title="Shipping Request" />

            <div style={{ marginBottom: "1.5rem" }}>
              <InputGroup style={{ width: "100%" }}>
                <FormControl
                  className=""
                  placeholder="Tracking Number"
                  onKeyPress={handleKeyPress}
                  value={searchWord.toUpperCase()}
                  onChange={(e) => {
                    setSearchWord(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "enter") {
                      rf();
                    }
                  }}
                />
                <Button
                  variant="outline"
                  style={{ borderColor: "var(--color-main-blue)" }}
                  onClick={(e) => {
                    rf();
                  }}
                >
                  <Icon icon="search" color="var(--color-main-blue)" />
                </Button>
              </InputGroup>
            </div>

            <SelectAllLabel>
              <FormCheck
                checked={totalCheck}
                onChange={handleTotalCheckChange}
              />
              Select All
            </SelectAllLabel>

            <div>
              {data?.map((v: any, index: number) => {
                let info = "Empty";
                if (v.status === 2) {
                  if (v.detail.length) {
                    const detailName = v.detail[0]?.name;
                    const moreCount = v.detail.length - 1;
                    info = detailName.length > 5
                      ? `${detailName.slice(0, 5)}... + ${moreCount} more`
                      : `${detailName} + ${moreCount} more`;
                  }
                }

                return (
                  v.status === 2 && (
                    <div
                      key={index}
                      style={{
                        cursor: "pointer",
                        borderBottom: "1px solid lightgray",
                        marginBottom: "1rem",
                        paddingBottom: "1rem",
                      }}
                      onClick={() => handleCheckChange(index)}
                    >
                      <div style={{ display: "flex" }}>
                        <P style={{ marginRight: "0.5rem" }}>
                          <FormCheck checked={checked[index]} />
                        </P>
                        <Label>{v.tracking}</Label>
                      </div>

                      <div style={{ marginLeft: "1.5rem" }}>
                        <Row>
                          <P>Warehouse Date</P>
                          <P>{FormatData(DATA_TYPE.DATE, v.inboundAt)}</P>
                        </Row>

                        <Row>
                          <P>Warehouse Cost</P>
                          <P>
                            ${v.overstoreFee} / ₩{convertToKRW(v.overstoreFee)}
                          </P>
                        </Row>

                        <Row>
                          <P>Extra Cost</P>
                          <P>
                            ${convertToUSD(v.Extra)} / ₩{v.Extra}{" "}
                          </P>
                        </Row>

                        <Row>
                          <P>Product Info</P>
                          <P>{info}</P>
                        </Row>

                        <Row>
                          <P>Status</P>
                          <P>
                            {FormatData(
                              DATA_TYPE.CODE_SHIPPING_PRODUCT_STATUS,
                              v.status
                            )}
                          </P>
                        </Row>
                      </div>
                    </div>
                  )
                );
              })}
            </div>
          </MobileWrapper>
        ) : (
          <>
            <CustomerPageTitle title="Shipping Request" />

            <div className="d-flex justify-content-end">
              <InputGroup className="mb-3" style={{ width: "300px" }}>
                <FormControl
                  className=""
                  placeholder="Tracking Number"
                  value={searchWord.toUpperCase()}
                  onKeyPress={handleKeyPress}
                  onChange={(e) => {
                    setSearchWord(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "enter") {
                      rf();
                    }
                  }}
                />
                <Button
                  variant="outline"
                  style={{ borderColor: "var(--color-main-blue)" }}
                  onClick={(e) => {
                    rf();
                  }}
                >
                  <Icon icon="search" color="var(--color-main-blue)" />
                </Button>
              </InputGroup>
            </div>
            <section className="container-fluid" id="about">
              <div style={{ height: "500px", overflowY: "scroll" }}>
                <div className="table-responsive">
                  <Table className="table-custom text-center">
                    <thead className="table-light">
                      <tr translate="no">
                        <th
                          style={{ top: 0, position: "sticky", width: "70px" }}
                        >
                          <FormCheck
                            checked={totalCheck}
                            onChange={handleTotalCheckChange}
                          />
                        </th>
                        <th
                          className="font-large-page-title"
                          style={{ top: 0, position: "sticky", width: "150px" }}
                        >
                          Tracking No.
                        </th>
                        <th
                          className="font-large-page-title"
                          style={{ top: 0, position: "sticky", width: "150px" }}
                        >
                          Warehouse Date
                        </th>
                        <th
                          className="font-large-page-title"
                          style={{ top: 0, position: "sticky", width: "150px" }}
                        >
                          Warehouse Cost
                        </th>
                        <th
                          className="font-large-page-title"
                          style={{ top: 0, position: "sticky", width: "150px" }}
                        >
                          Extra Cost
                        </th>
                        <th
                          className="font-large-page-title"
                          style={{ top: 0, position: "sticky", width: "150px" }}
                        >
                          Product Info
                        </th>
                        <th
                          className="font-large-page-title"
                          style={{ top: 0, position: "sticky", width: "150px" }}
                        >
                          Status
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.map((v: any, index: number) => {
                        let info = "Empty";
                        if (v.status === 2) {
                          if (v.detail.length) {
                            const detailName = v.detail[0]?.name;
                            const moreCount = v.detail.length - 1;
                            info = detailName.length > 5
                              ? `${detailName.slice(0, 5)}... + ${moreCount} more`
                              : `${detailName} + ${moreCount} more`;
                          }
                        }

                        return (
                          v.status === 2 && (
                            <tr key={index}>
                              <td className="font-large-page">
                                <FormCheck
                                  checked={checked[index]}
                                  onChange={() => handleCheckChange(index)}
                                />
                              </td>
                              <td
                                className="ontactweb-height"
                                style={{
                                  cursor: "pointer",
                                  color: "var(--color-main-blue)",
                                  textDecoration: "underline",
                                  fontSize: "1.3em",
                                }}
                                onClick={() => handleCheckChange(index)}
                              >
                                {v.tracking}
                              </td>
                              <td className="font-large-page">
                                {FormatData(DATA_TYPE.DATE, v.inboundAt)}
                              </td>
                              <td className="font-large-page">
                                ${v.overstoreFee} / ₩{convertToKRW(v.overstoreFee)}
                              </td>
                              <td className="font-large-page">
                                ${convertToUSD(v.Extra)} / ₩{v.Extra}{" "}
                              </td>
                              <td className="font-large-page">{info}</td>
                              <td className="font-large-page">
                                {FormatData(
                                  DATA_TYPE.CODE_SHIPPING_PRODUCT_STATUS,
                                  v.status
                                )}
                              </td>
                            </tr>
                          )
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              </div>
            </section>
          </>
        )}

        {isMobile ? (
          <MSubmitButtonRow>
            <Button
              variant="success"
              style={{
                backgroundColor: "var(--color-main-blue)",
                borderColor: "var(--color-main-blue)",
              }}
              onClick={() => {
                dispatch(
                  modalOpen({
                    modalType: MODAL_TYPE.SHIP_REQUEST,
                    title: "Shipping Request",
                    payload: {
                      list: data.filter(
                        (v, index) => checked[index] && v.status !== 1
                      ),
                    },
                  })
                );
              }}
              disabled={!isShippable}
            >
              Shipping Request
            </Button>
          </MSubmitButtonRow>
        ) : (
          <div
            className="d-flex justify-content-end"
            style={{ paddingBottom: "4rem" }}
          >
            <Button
              variant="success"
              style={{
                backgroundColor: "var(--color-main-blue)",
                borderColor: "var(--color-main-blue)",
              }}
              onClick={() => {
                dispatch(
                  modalOpen({
                    modalType: MODAL_TYPE.SHIP_REQUEST,
                    title: "Shipping Request",
                    payload: {
                      list: data.filter(
                        (v, index) => checked[index] && v.status !== 1
                      ),
                    },
                  })
                );
              }}
              disabled={!isShippable}
            >
              Shipping Request
            </Button>
          </div>
        )}
      </div>
    </>
  );
}

const MSubmitButtonRow = styled.div`
  position: fixed;
  width: 100vw;
  bottom: 0%;
  left: 0%;
  padding: 1rem;
  background-color: white;
  gap: 1rem;
  display: flex;
  & > button {
    flex-grow: 1;
  }
`;

const Title = styled.h3`
  border-bottom: 1px solid lightgray;
  padding-bottom: 1rem;
  margin-top: 1rem;
`;

const FeeBoxRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  & > div {
    flex: 1 0 0;
    text-align: center;
    align-items: center;
    border: 1px solid lightgray;
    padding: 0.4rem 0;
  }
  margin: 0.6rem 0;
`;

const TotalFeeBoxRow = styled(FeeBoxRow)`
  background-color: #474747;
  color: white;
`;

const MobileWrapper = styled.div`
  margin: 0;
`;

const MBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
  align-items: center;
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  font-weight: bold;
  margin: 0.4rem 0 0.4rem 0;
`;

const P = styled.p`
  margin: 0.4rem 0 0.4rem 0;
`;

const SelectAllLabel = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  font-weight: bold;
  cursor: pointer;

  input {
    margin-right: 0.5rem;
  }
`;
