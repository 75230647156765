import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ADMIN_BADGE, CODE_QNA_K } from "../../../common/constants";
import { RootState } from "../../../redux/store";
import {
  reqQuestionAndResponse,
  reqUpdateQuestionAndResponse,
} from "../../../requests/user";
import Swal from "sweetalert2";
import { reqSendAlarm } from "../../../requests/alarm";
import { reqReadAdminBadge } from "../../../requests/badge";
import AdminHeadTitle from "../../../components/AdminHeadTitle";
import { formatDate } from "../../../common/format";
import { Pagination, Table } from "react-bootstrap";
import styled from "styled-components";

interface Response {
  id: number;
  title: string;
  userType: boolean;
  userName: string;
  categoryId: number;
  questionContent: string;
  createdAt: string;
  responseAt?: string;
  responseContent: string;
  status: number;
  email: string;
  userId: string;
  manager: string;
  imageUrl?: string; // 이미지 URL 속성 추가
}

const Aqna: React.FC = () => {
  const [responses, setResponses] = useState<Response[]>([]);
  const [responseContent, setResponseContent] = useState("");
  const admin = useSelector((state: RootState) => state.admin);
  const [selectedResponse, setSelectedResponse] = useState<Response | null>(
    null
  );
  const [isModalOpen, setIsModalOpen] = useState(false);

  // 이미지 모달 상태 추가
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [selectedImageUrl, setSelectedImageUrl] = useState<string | null>(null);

  // 필터 상태 추가
  const [categoryFilter, setCategoryFilter] = useState<number | "">(0);
  const [spaceCodeFilter, setSpaceCodeFilter] = useState("");

  // 페이지네이션 상태 추가
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    fetchResponses();

    const badgePayload = {
      badgeName: ADMIN_BADGE.QNA,
    };
    reqReadAdminBadge(badgePayload);

    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        closeModal();
      }
    };

    document.addEventListener("keydown", handleEsc);

    return () => {
      document.removeEventListener("keydown", handleEsc);
    };
  }, []);

  const fetchResponses = async () => {
    try {
      const response = await reqQuestionAndResponse();
      console.log(response.data);
      setResponses(response.data);
    } catch (error) {
      console.error("Failed to fetch questions and responses:", error);
    }
  };

  const handleStatusClick = (response: Response) => {
    setSelectedResponse(response);
    setResponseContent(response.responseContent || "");
    setIsModalOpen(true);
  };

  const handleDeleteClick = async (responseId: number) => {
    Swal.fire({
      title: "정말 삭제하시겠습니까?",
      text: "삭제된 후에는 복구할 수 없습니다.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "삭제",
      cancelButtonText: "취소",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await reqUpdateQuestionAndResponse(responseId.toString(), {
            userType: true,
          });
          setResponses(responses.filter((item) => item.id !== responseId));
          Swal.fire("삭제 완료", "해당 응답이 삭제되었습니다.", "success");
        } catch (error) {
          console.error("Failed to delete response:", error);
          Swal.fire("삭제 실패", "해당 응답을 삭제하지 못했습니다.", "error");
        }
      }
    });
  };

  const handleResetResponse = () => {
    if (selectedResponse) {
      setResponseContent(selectedResponse.responseContent || "");
    }
  };

  const closeModal = () => {
    setSelectedResponse(null);
    setIsModalOpen(false);
  };

  const getQuestionType = (categoryId: number) => {
    return CODE_QNA_K[categoryId] || "Unknown";
  };

  const handleResponseSubmit = async () => {
    if (!selectedResponse) {
      return;
    }

    Swal.fire({
      title: "답변을 전송하시겠습니까?",
      text: "사용자에게 알림을 전송합니다.",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "승인",
      cancelButtonText: "취소",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const formattedResponseAt = format(
            new Date(),
            "yyyy-MM-dd'T'HH:mm:ss'Z'"
          );
          const updatedResponse = {
            ...selectedResponse,
            status: 1,
            responseAt: formattedResponseAt,
            manager: admin.name,
            responseContent: responseContent,
          };
          await reqUpdateQuestionAndResponse(
            selectedResponse.id.toString(),
            updatedResponse
          );
          setResponses(
            responses.map((item) =>
              item.id === selectedResponse.id ? updatedResponse : item
            )
          );
          closeModal();
        } catch (error) {
          console.error("Failed to submit response:", error);
        }

        const alarmPayload = {
          userId: selectedResponse.userId,
          read: 0,
          content: `${admin.name} has answered your question.`,
          sender: admin.name,
        };
        await reqSendAlarm(alarmPayload);

        Swal.fire("답변을 완료했습니다.", "", "success");
      }
    });
  };

  const handleResponseContentChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setResponseContent(event.target.value);
  };

  const filteredResponses = responses
    .filter((response) => {
      const categoryMatch =
        categoryFilter === 0 || response.categoryId === categoryFilter;
      const spaceCodeMatch = response.userId
        .toLowerCase()
        .includes(spaceCodeFilter.toLowerCase());

      return categoryMatch && spaceCodeMatch;
    })
    .sort((a, b) => {
      if (a.status === b.status) {
        return (
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
      }
      return a.status - b.status;
    });

  useEffect(() => {
    setTotalPages(Math.ceil(filteredResponses.length / itemsPerPage));
  }, [filteredResponses]);

  const currentResponses = filteredResponses.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  return (
    <main id="main" className="main">
      <AdminHeadTitle
        subtitle1="서비스 관리"
        subtitle2="질문 응답"
        title="질문 응답"
      />

      <div>
        <div className="container-fluid mb-4">
          <div className="row">
            <div className="col-md-4">
              <label htmlFor="categoryFilter" className="form-label">
                질문 유형
              </label>
              <select
                id="categoryFilter"
                className="form-select"
                value={categoryFilter}
                onChange={(e) => setCategoryFilter(Number(e.target.value))}
              >
                <option value="">모두</option>
                {Object.entries(CODE_QNA_K).map(([key, value]) => (
                  <option key={key} value={key}>
                    {value}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-4">
              <label htmlFor="spaceCodeFilter" className="form-label">
                스페이스 코드
              </label>
              <input
                type="text"
                id="spaceCodeFilter"
                className="form-control"
                value={spaceCodeFilter}
                onChange={(e) => setSpaceCodeFilter(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <table className="table text-center">
            <thead>
              <tr>
                <th>질문유형</th>
                <th>스페이스 코드</th>
                <th>이름</th>
                <th>등록일</th>
                <th>답변일</th>
                <th>담당자</th>
                <th>제목</th>
                <th>상태</th>
                <th>삭제</th>
              </tr>
            </thead>
            <tbody>
              {currentResponses
                .filter((item) => !item.userType)
                .map((item) => (
                  <tr key={item.id} onClick={() => handleStatusClick(item)}>
                    <td>{getQuestionType(item.categoryId)}</td>
                    <td>{item.userId}</td>
                    <td>{item?.userName}</td>
                    <td>{formatDate(item.createdAt)}</td>
                    <td>
                      {item.responseAt
                        ? formatDate(item.responseAt)
                        : "답변 대기중"}
                    </td>
                    <td>{item.manager || "담당자 미지정"}</td>
                    <td>{item.title}</td>
                    <td>{item.status ? "완료" : "미완료"}</td>
                    <td>
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDeleteClick(item.id);
                        }}
                      >
                        삭제
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      {selectedResponse && (
        <div
          className={`modal ${isModalOpen ? "show" : ""}`}
          tabIndex={-1}
          role="dialog"
          style={{ display: isModalOpen ? "block" : "none" }}
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5">{selectedResponse.title}</h1>
                <button
                  type="button"
                  className="btn-close"
                  onClick={closeModal}
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="container-fluid">
                  <div className="card">
                    <div className="card-header">
                      <table className="table">
                        <tbody>
                          <tr>
                            <th>질문유형</th>
                            <td>
                              {getQuestionType(selectedResponse.categoryId)}
                            </td>
                          </tr>
                          <tr>
                            <th>고객유형</th>
                            <td>
                              {selectedResponse.userType ? "비회원" : "회원"}
                            </td>
                          </tr>
                          <tr>
                            <th>스페이스코드</th>
                            <td>{selectedResponse.userId}</td>
                          </tr>
                          <tr>
                            <th>이름</th>
                            <td>{selectedResponse?.userName}</td>
                          </tr>
                          <tr>
                            <th>이메일</th>
                            <td>{selectedResponse.email}</td>
                          </tr>
                          {/* 이미지 미리보기 추가 */}
                          {selectedResponse.imageUrl && (
                            <tr>
                              <th>이미지</th>
                              <td>
                                <img
                                  src={selectedResponse.imageUrl}
                                  alt="Question image"
                                  style={{
                                    maxWidth: "100px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    if (selectedResponse.imageUrl) {
                                      setSelectedImageUrl(
                                        selectedResponse.imageUrl
                                      );
                                      setImageModalOpen(true);
                                    }
                                  }}
                                />
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div className="card-body">
                      <h5 className="mb-2">내용</h5>
                      <p>{selectedResponse.questionContent}</p>
                    </div>
                    <div className="card-footer">
                      <div className="form-floating">
                        <textarea
                          className="form-control"
                          placeholder="Leave a comment here"
                          id="floatingTextarea2"
                          style={{ height: "300px" }}
                          value={responseContent}
                          onChange={handleResponseContentChange}
                        ></textarea>
                        <label htmlFor="floatingTextarea2">답변</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleResponseSubmit}
                >
                  전송
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  data-bs-dismiss="modal"
                  onClick={closeModal}
                >
                  닫기
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* 이미지 미리보기 모달 */}
      {imageModalOpen && (
        <div
          className="modal show"
          tabIndex={-1}
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">이미지 보기</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setImageModalOpen(false)}
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                {selectedImageUrl && (
                  <img
                    src={selectedImageUrl}
                    alt="Question image"
                    style={{ width: "100%" }}
                  />
                )}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setImageModalOpen(false)}
                >
                  닫기
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <CenteredPagination style={{ marginTop: "1rem" }}>
        {[...Array(totalPages)].map((_, index) => (
          <Pagination.Item
            key={index}
            active={index + 1 === currentPage}
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </Pagination.Item>
        ))}
      </CenteredPagination>
    </main>
  );
};

export default Aqna;

const CenteredPagination = styled(Pagination)`
  display: flex;
  justify-content: center;
`;
