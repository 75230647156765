import { AxiosManager } from "../axiosManager";

// 뱃지 생성
export function reqAddBadge(payload: any) {
  return AxiosManager.post("badge/add", payload);
}

// 모든 뱃지 송신
export function reqAllBadge(payload: any) {
  return AxiosManager.get("badge/all", {
    params: payload,
  });
}

// 단일 뱃지 읽음 처리
export function reqReadBadge(payload: any) {
  return AxiosManager.post("badge/read", payload);
}

// 관리자에게 뱃지 생성
export function reqAddAdminBadge(payload: any) {
  return AxiosManager.post("badge/admin/add", payload);
}

// 모든 뱃지 송신
export function reqAllAdminBadge() {
  return AxiosManager.get("badge/admin/all");
}

// 단일 뱃지 읽음 처리
export function reqReadAdminBadge(payload: any) {
  return AxiosManager.post("badge/admin/read", payload);
}
