import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { modalClose } from "../../redux/modalSlice";
import styled from "styled-components";
import { isMobile } from "react-device-detect";
import {
  MButton,
  MFooter,
  MRow,
  MTwoButtonRow,
} from "../../common/mobile-styled";

export default function CONFIRM() {
  const dispatch = useDispatch();
  const modal = useSelector((state: any) => state.modal);

  const handleConfirm = () => {
    if (modal.payload?.onConfirm) {
      modal.payload.onConfirm();
    }
    dispatch(modalClose());
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  const handleCancel = () => {
    dispatch(modalClose());
  };

  return (
    <ModalContent>
      <ConfirmText
        dangerouslySetInnerHTML={{ __html: modal.payload?.text }}
      ></ConfirmText>
      {isMobile ? (
        <MFooter>
          <MTwoButtonRow>
            <MButton
              onClick={handleConfirm}
              style={{
                backgroundColor: "var(--color-main-blue)",
                color: "var(--color-white)",
              }}
            >
              Yes
            </MButton>
            <MButton
              onClick={handleCancel}
              style={{
                backgroundColor: "var(--color-main-red)",
                color: "var(--color-white)",
              }}
            >
              No
            </MButton>
          </MTwoButtonRow>
        </MFooter>
      ) : (
        <MFooter>
          <MTwoButtonRow>
            <MButton
              onClick={handleConfirm}
              style={{
                backgroundColor: "var(--color-main-blue)",
                color: "var(--color-white)",
                margin: "0",
              }}
            >
              Yes
            </MButton>
            <MButton
              onClick={handleCancel}
              style={{
                backgroundColor: "var(--color-main-red)",
                color: "var(--color-white)",
                margin: "0",
              }}
            >
              No
            </MButton>
          </MTwoButtonRow>
        </MFooter>
      )}
    </ModalContent>
  );
}

const ModalContent = styled.div`
  background: white;
`;

const Button = styled.button`
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  margin: 10px;
  cursor: pointer;
  font-weight: bold;
  background-color: #3085d6;
  color: white;

  &:first-child {
    background-color: #d33;
  }

  &:hover {
    opacity: 0.9;
  }
`;

const ConfirmText = styled.div`
  margin-top: 1rem;
`;
