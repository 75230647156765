import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { reqSingleQuestionAndResponse } from "../requests/user";
import { Col } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import CustomerPageTitle from "./CustomerPageTitle";
import { Label, P, MobileWrapper } from "./CMobileNotice";
import { MPageTitle } from "../common/mobile-styled";
import styled from "styled-components";

const CQuestionPage = () => {
  const { questionId } = useParams();
  const navigate = useNavigate();
  const [question, setQuestion] = useState<any>();

  useEffect(() => {
    async function getSelectedQuestion() {
      const res = await reqSingleQuestionAndResponse(Number(questionId));
      setQuestion(res.data);
    }
    getSelectedQuestion();
  }, [questionId]);

  return (
    <>
      {isMobile ? (
        <MobileWrapper style={{ padding: "1.4rem" }}>
          <CustomerPageTitle subtitle1="Event" title="Question" />
          <BackButton onClick={() => navigate('/customer/support')}>Back</BackButton>
          {question && (
            <div>
              <Col>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <Label>Title:</Label>
                  <P>{question.title}</P>
                </div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <Label>Requested At:</Label>
                  <P>
                    {new Date(question.createdAt).toLocaleDateString("ko-KR", {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                      hour: "2-digit",
                      minute: "2-digit",
                      hourCycle: "h23",
                    })}
                  </P>
                </div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <Label>Responsed At:</Label>
                  <P>
                    {question.responseAt
                      ? new Date(question.responseAt).toLocaleDateString(
                          "ko-KR",
                          {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                            hour: "2-digit",
                            minute: "2-digit",
                            hourCycle: "h23",
                          }
                        )
                      : "N/A"}
                  </P>
                </div>
                <MPageTitle>Question</MPageTitle>
                <P>{question.questionContent}</P>
                <MPageTitle>Answer by {question.manager}</MPageTitle>
                <P>{question.responseContent}</P>
              </Col>
            </div>
          )}
        </MobileWrapper>
      ) : (
        <PCWrapper>
          <CustomerPageTitle title="Question" />
          <BackButton onClick={() => navigate('/customer/support')}>Back</BackButton>
          {question && (
            <ContentWrapper>
              <Col>
                <InfoRow>
                  <Label>Title:</Label>
                  <P>{question.title}</P>
                </InfoRow>
                <InfoRow>
                  <Label>Requested At:</Label>
                  <P>
                    {new Date(question.createdAt).toLocaleDateString("ko-KR", {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                      hour: "2-digit",
                      minute: "2-digit",
                      hourCycle: "h23",
                    })}
                  </P>
                </InfoRow>
                <InfoRow>
                  <Label>Responsed At:</Label>
                  <P>
                    {question.responseAt
                      ? new Date(question.responseAt).toLocaleDateString(
                          "ko-KR",
                          {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                            hour: "2-digit",
                            minute: "2-digit",
                            hourCycle: "h23",
                          }
                        )
                      : "N/A"}
                  </P>
                </InfoRow>
                <StyledPageTitle>Question</StyledPageTitle>
                <P>{question.questionContent}</P>
                <StyledPageTitle>Answer by {question.manager}</StyledPageTitle>
                <P>{question.responseContent}</P>
              </Col>
            </ContentWrapper>
          )}
        </PCWrapper>
      )}
    </>
  );
};

export default CQuestionPage;

const PCWrapper = styled.div`
  padding: 1.4rem;
  max-width: 800px;
  margin: 0 auto;
`;

const ContentWrapper = styled.div`
  background-color: #f9f9f9;
  padding: 1.4rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const InfoRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;

  ${Label} {
    flex: 1;
    font-weight: bold;
  }

  ${P} {
    flex: 2;
  }
`;

const StyledPageTitle = styled(MPageTitle)`
  margin-top: 2rem;
`;

const BackButton = styled.button`
  margin-bottom: 1rem;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s, box-shadow 0.3s, transform 0.2s;

  &:hover {
    background-color: #0056b3;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transform: translateY(-2px);
  }

  &:active {
    background-color: #004080;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transform: translateY(1px);
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(38, 143, 255, 0.5);
  }
`;
