import { createSlice } from "@reduxjs/toolkit";

interface iTable {
  name: string;
  checkedIds: any[];
}

const initialState: iTable = {
  name: "",
  checkedIds: [],
};

const tableSlice = createSlice({
  initialState,
  name: "table",
  reducers: {
    tableSave: (state, action) => {
      const { name, checkedIds } = action.payload;
      state.name = name;
      state.checkedIds = checkedIds;
      return state;
    },
    tableClear: (state) => {
      state.name = "";
      state.checkedIds = [];
      return state;
    },
  },
});

export const { tableSave, tableClear } = tableSlice.actions;
export default tableSlice.reducer;
