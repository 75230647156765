import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import { reqAddAdminBadge } from "../../requests/badge";
import {
  reqIssueCheckContentsCoupon,
  reqUseCoupon,
} from "../../requests/coupon";
import { reqShipOrderUpdate, reqShipProductBox } from "../../requests/ship";
import { reqShopUpdate } from "../../requests/shop";
import {
  reqUpdateUserMembership,
  reqUpdateUserPointUSD,
} from "../../requests/user";
import { reqPayment } from "../../requests/payment";
import { ADMIN_BADGE } from "../../common/constants";
import {
  reqAddProductSplit,
  reqUploadProductSplitImage,
} from "../../requests/split";

export const 구매대행_콜백_처리 = async (custom: string) => {
  const parsedCustom = JSON.parse(custom as string);
  for (const key in parsedCustom) {
    const args = parsedCustom[key];
    switch (key) {
      case "reqShopUpdate":
        await reqShopUpdate(args);
        break;
      case "reqUpdateUserPointUSD":
        await reqUpdateUserPointUSD(args);
        break;
      case "reqAddAdminBadge":
        await reqAddAdminBadge(args);
        break;
      case "reqUseCoupon":
        await reqUseCoupon(args);
        break;
      default:
        console.log(`Unknown function: ${key}`);
    }
  }
};

export const 배송대행_콜백_처리 = async (custom: string) => {
  const parsedCustom = JSON.parse(custom as string);
  for (const key in parsedCustom) {
    const args = parsedCustom[key];
    switch (key) {
      case "reqShipOrderUpdate":
        await reqShipOrderUpdate(args);
        break;
      case "reqUpdateUserPointUSD":
        await reqUpdateUserPointUSD(args);
        break;
      case "reqAddAdminBadge":
        await reqAddAdminBadge(args);
        break;
      case "reqUseCoupon":
        await reqUseCoupon(args);
        break;
      default:
        console.log(`Unknown function: ${key}`);
    }
  }
};

export const 체크컨텐츠_콜백_처리 = async (custom: string) => {
  const parsedCustom = JSON.parse(custom as string);
  for (const key in parsedCustom) {
    const args = parsedCustom[key];
    switch (key) {
      case "reqShipProductBox":
        const promises = args.map(async (payload: any) => {
          return reqShipProductBox(payload);
        });
        await Promise.all(promises);
        break;
      case "reqAddAdminBadge":
        await reqAddAdminBadge(args);
        break;
      case "reqUseCoupon":
        await reqUseCoupon(args);
        break;
      default:
        console.log(`Unknown function: ${key}`);
    }
  }
};

export const 멤버십_콜백_처리 = async (custom: string) => {
  const parsedCustom = JSON.parse(custom as string);
  for (const key in parsedCustom) {
    const args = parsedCustom[key];
    switch (key) {
      case "reqUpdateUserMembership":
        await reqUpdateUserMembership(args);
        break;
      case "issueCheckContentsCoupon":
        const { userId, membershipId } = args;
        const couponCount =
          membershipId === 1 ? 12 : membershipId === 2 ? 3 : 0;
        if (couponCount > 0) {
          const result = await reqIssueCheckContentsCoupon({
            userId,
            membershipId,
            count: couponCount,
          });
          if (result.data.success) {
            console.log(
              `${couponCount} check contents coupons have been issued.`
            );
          } else {
            console.log("Failed to issue check contents coupons.");
          }
        }
        break;
      case "reqPayment":
        await reqPayment(args);
        break;
      case "reqShipProductBox":
        const updatedArgs = args.map((item: any) => ({
          ...item,
          overstoreFee: 0,
        }));
        await Promise.all(
          updatedArgs.map((payload: any) => reqShipProductBox(payload))
        );
        break;
      default:
        console.log(`Unknown function: ${key}`);
    }
  }
};

interface ShipProductBoxPayload {
  tracking: string;
  status: number;
}

const ALPHABET = "ABCDEFGHJKLMNOPQRSTUVWXYZ";

export const processAfterPayment = async (
  checkedItems: any,
  user: any,
  boxCount: number,
  splitData: string[],
  files: File[]
) => {
  const shipProductBoxPayload = checkedItems.map((item: any) => ({
    tracking: item.tracking,
    status: 5,
  }));

  const addAdminBadgePayload = {
    badgeName: ADMIN_BADGE.SPLIT,
    userId: user.id,
  };

  try {
    await Promise.all(
      shipProductBoxPayload.map((payload: ShipProductBoxPayload) =>
        reqShipProductBox(payload)
      )
    );

    await reqAddAdminBadge(addAdminBadgePayload);

    for (let i = 0; i < boxCount; i++) {
      const trackingNumber = `${checkedItems[0].tracking}-${ALPHABET[i]}`;
      const payload = {
        tracking: checkedItems[0].tracking,
        Split: trackingNumber,
        userId: user.id,
        status: 1,
        content: splitData[i],
        imageUrl: "",
      };

      const response = await reqAddProductSplit(payload);
      const splitId = response.data.id;

      if (files[i]) {
        await reqUploadProductSplitImage(splitId, files[i]);
      }
    }
  } catch (error) {
    console.error("Error processing after payment:", error);
  }
};

export const 스플릿_콜백_처리 = async (custom: string) => {
  const parsedCustom = JSON.parse(custom as string);
  for (const key in parsedCustom) {
    const args = parsedCustom[key];
    switch (key) {
      case "reqShipProductBox":
        const updatedArgs = args.map((item: any) => ({
          ...item,
          overstoreFee: 0,
          Extra: 0,
        }));
        await Promise.all(
          updatedArgs.map((payload: any) => reqShipProductBox(payload))
        );
        break;
      case "reqPayment":
        await reqPayment(args);
        break;
      default:
        console.log(`Unknown function: ${key}`);
    }
  }
};

const CPaypalCallback = () => {
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const retcode = queryParams.get("retcode");
  const retmsg = queryParams.get("retmsg");
  const payamt = queryParams.get("payamt");
  const pginfo = queryParams.get("pginfo");
  const storeorderno = queryParams.get("storeorderno");
  const storeid = queryParams.get("storeid");
  const custom = queryParams.get("custom");
  const returnlink = queryParams.get("returnlink");

  useEffect(() => {
    const handlePaymentResult = async () => {
      if (retcode !== "0") {
        Swal.fire({
          title: "Payment Failed",
          text: "Sorry. Please contact to admin.",
          icon: "error",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Back to previous page",
        }).then(async (result) => {
          if (result.isConfirmed) {
            window.location.replace(returnlink as string);
          }
        });
        return;
      }

      const customObject = JSON.parse(custom || "{}");
      const { checkedItems, user, boxCount, splitData, files } = customObject;

      if (returnlink === "shop/pay") {
        await 구매대행_콜백_처리(custom as string);
      }
      if (returnlink === "ship/pay") {
        await 배송대행_콜백_처리(custom as string);
      }
      if (returnlink === "warehouse") {
        await 체크컨텐츠_콜백_처리(custom as string);
      }
      if (returnlink === "membership") {
        await 멤버십_콜백_처리(custom as string);
      }
      if (returnlink === "warehouse") {
        await 스플릿_콜백_처리(custom as string);
      }

      Swal.fire({
        title: "Payment Success",
        text: "Thank you for using ontact korea!",
        icon: "success",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Back to previous page",
      }).then(async (result) => {
        if (result.isConfirmed) {
          window.location.replace(returnlink as string);
        }
      });
    };

    handlePaymentResult();
  }, []);

  return <div style={{ padding: "30px" }}></div>;
};

export default CPaypalCallback;
