import { Cookies } from "react-cookie";

const cookies = new Cookies();

/** Key, Value를 받아 Cookie 생성 후 저장
 * @param {string} name
 * @param {string} value
 */
export const setCookie = (name: string, value: string) => {
  return cookies.set(name, value, { maxAge: 60 * 60 * 3, path: "/" });
};

/** Key를 받아 Value를 반환
 * @param {string} name
 * @returns {string}
 */
export const getCookie = (name: string) => {
  return cookies.get(name);
};

/** Key를 받아 쿠키를 삭제
 * @param {string} name
 */
export const removeCookie = (name: string) => {
  return cookies.remove(name);
};

export const CONST_COOKIE = {
  ACCESS_TOKEN: "access_token",
  REFRESH_TOKEN: "refresh_token",
  ADMIN_ACCESS: "admin_access_token",
  ADMIN_REFRESH: "admin_refresh_token",

  ADMIN_LOGIN_SAVE_ID_OPTION: "Sj6H6",
  ADMIN_LOGIN_SAVED_ID: "gMLmD",

  USER_LOGIN_SAVE_ID_OPTION: "lsKbM",
  USER_LOGIN_SAVED_ID: "c0Pue",
};
