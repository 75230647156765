import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { modalClose } from '../../redux/modalSlice';
import styled from 'styled-components';

const ImagePreviewModal = () => {
    const modalData = useSelector((state: any) => state.modal);
    const dispatch = useDispatch();

    const imageUrl = modalData.payload?.data?.imageUrl;

    if (!imageUrl) {
        console.error('No image URL provided');
        dispatch(modalClose());
        return null;
    }

    const handleClose = () => {
        dispatch(modalClose());
    };

    return (
        <StyledModal>
            <div className="modal-content">
                <img src={imageUrl} alt="Preview" onClick={() => dispatch(modalClose())} />
            </div>
            <button className="close-button" onClick={handleClose}>Close</button>
        </StyledModal>
    );
};

const StyledModal = styled.div`
  position: relative;
  width: auto;
  max-width: 95%;
  max-height: 95%;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .modal-content {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      max-width: 100%;
      max-height: 80vh;
      cursor: pointer;
    }
  }

  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px 10px;
    font-size: 16px;
    background-color: #f44336;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
`;

export default ImagePreviewModal;
