import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Icon from "../Icon";

export default function PopupModal(props: {
  isAdmin?: any;
  onHide?: any;
  popupData?: any;
}) {
  const nav = useNavigate();
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [data, setData] = useState<any>({
    id: props.popupData.id,
    title: props.popupData.title,
    imageUrl: props.popupData.imageUrl,
    link: props.popupData.link,
    pcPos: props.popupData.pcPos,
    pcLeftOffset: props.popupData.pcLeftOffset,
    pcTopOffset: props.popupData.pcTopOffset,
    mobileTopOffset: props.popupData.mobileTopOffset,
    onedayBan: props.popupData.onedayBan,
  });

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    const popupCookie = Cookies.get("hidePopup");
    if (popupCookie) {
      props.onHide();
    }

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleDontShowToday = () => {
    if (props.isAdmin) {
      return;
    }
    Cookies.set("hidePopup", "true", { expires: 1 });
    props.onHide();
  };

  return (
    <PopupWrapper
      pos={data.pcPos}
      topOffset={isMobile ? "0" : `${data.pcTopOffset}px`}
      leftOffset={isMobile ? "0" : `${data.pcLeftOffset}px`}
      isMobile={isMobile}
    >
      <div id="popup-header">
        <Icon icon="x" fontSize="30px" onClick={props.onHide}></Icon>
      </div>
      <img
        src={`https://kr.object.ncloudstorage.com/ontact-bucket/${data.imageUrl}`}
        alt="Popup"
        onClick={() => window.open(data.link, "_blank", "noopener, noreferrer")}
        style={{
          width: isMobile ? "100%" : "auto",
          height: isMobile ? "auto" : "auto",
        }}
      />
      <div id="popup-close-bar">
        {!data.onedayBan ? (
          <div>
            <input type="radio" onChange={handleDontShowToday} />
            <label>Do not show today</label>
          </div>
        ) : (
          <div></div>
        )}
        <span onClick={props.onHide}>Close</span>
      </div>
    </PopupWrapper>
  );
}

interface PopupWrapperProps {
  pos: number;
  topOffset: string;
  leftOffset: string;
  isMobile: boolean;
}

const PopupWrapper = styled.div<PopupWrapperProps>`
  background-color: aliceblue;
  position: fixed;
  top: ${({ pos, topOffset, isMobile }) =>
    isMobile ? "0" : pos === 1 ? "50%" : topOffset};
  left: ${({ pos, leftOffset, isMobile }) => {
    if (isMobile) return "0";
    if (pos === 0) return leftOffset;
    if (pos === 1) return "50%";
    if (pos === 2) return `calc(100% - ${leftOffset})`;
  }};
  transform: ${({ pos, isMobile }) =>
    pos === 1 && !isMobile ? "translate(-50%, -50%)" : "none"};
  z-index: 999999;
  background: white;
  border: 1px solid black;
  text-align: center;
  max-width: ${({ isMobile }) => (isMobile ? "100vw" : "80vw")};
  max-height: ${({ isMobile }) => (isMobile ? "100vh" : "80vh")};
  overflow: auto;

  #popup-header {
    display: flex;
    justify-content: flex-end;
    padding: 4px 8px 0 0;
  }

  #popup-close-bar {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    padding: 0 10px 10px 10px;
    & > div {
      input {
        margin-right: 10px;
      }
    }

    & > span {
      font-weight: bold;
      text-decoration: underline;
    }
  }
`;
