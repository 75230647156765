import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { reqProductSplit } from "../../requests/split";
import { modalClose } from "../../redux/modalSlice";
import { Table, Alert, Button, Form, Modal } from "react-bootstrap";
import styled from "styled-components";
import { format } from "date-fns";
import { isMobile } from "react-device-detect";

export default function ModalGetSplit() {
  const dispatch = useDispatch();
  const modal = useSelector((state: any) => state.modal);
  const [productSplits, setProductSplits] = useState([]);
  const [trackingInfo, setTrackingInfo] = useState<any>(null);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  useEffect(() => {
    async function fetchData() {
      try {
        const trackingNumber = modal.payload.trackingNumber;
        if (!trackingNumber) {
          console.error("Tracking number is not defined");
          return;
        }

        const response = await reqProductSplit(trackingNumber);
        setProductSplits(response.data);
        if (response.data.length > 0) {
          setTrackingInfo({
            tracking: response.data[0].tracking,
            manager: response.data[0].manager,
            userId: response.data[0].userId,
            createdAt: response.data[0].createdAt,
          });
        }
      } catch (error) {
        console.error("Error fetching product splits:", error);
      }
    }
    fetchData();
  }, [modal.payload.trackingNumber]);

  const handleImageClick = (imageUrl: string) => {
    setSelectedImage(imageUrl);
  };

  const handleCloseImageView = () => {
    setSelectedImage(null);
  };

  return (
    <ModalContent>
      {trackingInfo ? (
        <>
          <TrackingInfo>
            <h4>Tracking Number: {trackingInfo.tracking}</h4>
            <p>Manager: {trackingInfo.manager || "N/A"}</p>
            <p>User ID: {trackingInfo.userId}</p>
            <p>
              Created At:{" "}
              {format(new Date(trackingInfo.createdAt), "yyyy.MM.dd")}
            </p>
          </TrackingInfo>
          {productSplits.length > 0 ? (
            productSplits.map((split: any, idx: number) => (
              <div
                key={split.id}
                style={{
                  border: "1px solid gray",
                  padding: "10px",
                  marginBottom: "10px",
                }}
              >
                <h4>Split {idx + 1}</h4>
                <Form.Group controlId={`formSplitData-${idx}`}>
                  <Form.Label>Split Data</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={5}
                    value={split.content}
                    readOnly
                  />
                </Form.Group>
                <Form.Group controlId={`formFileUpload-${idx}`}>
                  <Form.Label>Image</Form.Label>
                  {split.imageUrl && (
                    <div style={{ textAlign: "center", marginTop: "10px" }}>
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          handleImageClick(split.imageUrl!);
                        }}
                      >
                        {split.imageUrl.split("/").pop()?.slice(0, 10)}
                      </a>
                    </div>
                  )}
                </Form.Group>
              </div>
            ))
          ) : (
            <Alert variant="warning">
              No product splits found for this tracking number.
            </Alert>
          )}
        </>
      ) : (
        <Alert variant="warning">
          No information found for this tracking number.
        </Alert>
      )}
      <ButtonContainer>
        <Button onClick={() => dispatch(modalClose())}>Close</Button>
      </ButtonContainer>

      {isMobile ? (
        selectedImage && (
          <ImagePreviewContainer onClick={handleCloseImageView}>
            <ImagePreview src={selectedImage} alt="Selected" />
          </ImagePreviewContainer>
        )
      ) : (
        <Modal show={!!selectedImage} onHide={handleCloseImageView} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Image Preview</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {selectedImage && (
              <img
                src={selectedImage}
                alt="Selected"
                className="img-fluid"
                style={{ width: "100%" }}
              />
            )}
          </Modal.Body>
        </Modal>
      )}
    </ModalContent>
  );
}

const ModalContent = styled.div`
  padding: 20px;
  max-height: 70vh;
  overflow-y: auto;

  table {
    margin-top: 20px;
  }
`;

const TrackingInfo = styled.div`
  padding: 20px;
  border: 1px solid gray;
  margin-bottom: 20px;

  h4 {
    margin-bottom: 10px;
  }

  p {
    margin: 0;
    margin-bottom: 5px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
`;

const ImagePreviewContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ImagePreview = styled.img`
  max-width: 100%;
  max-height: 100%;
  cursor: pointer;
`;
