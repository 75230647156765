import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import styled from "styled-components";
import * as XLSX from "xlsx"; // Import the xlsx library
import { reqShiprevenue } from "../../../requests/ship";
import { reqAShopDaily } from "../../../requests/shop";

const DailyRevenueStatistics: React.FC = () => {
  const [startDate, setStartDate] = useState<string>("");
  const [shipRevenue, setShipRevenue] = useState<{
    krw: number;
    usd: number;
    packageKRW: number;
    packageUSD: number;
    optionKRW: number;
    optionUSD: number;
    warehouseKRW: number;
    warehouseUSD: number;
    usePointKRW: number;
    usePointUSD: number;
    discountCouponKRW: number;
    discountCouponUSD: number;
  } | null>(null);

  const [shopRevenue, setShopRevenue] = useState<{
    totalKRWShippingFee: number;
    totalKRWServiceFee: number;
    totalKRWPaymentAmount: number;
    totalKRWPointsUsed: number;
    totalKRWCouponsUsed: number;
    totalKRWProductPrice: number;
    totalUSDShippingFee: number;
    totalUSDServiceFee: number;
    totalUSDPaymentAmount: number;
    totalUSDPointsUsed: number;
    totalUSDCouponsUsed: number;
    totalUSDPaypalFee: number;
    totalUSDProductPrice: number;
  } | null>(null);

  const [weeklyShipRevenue, setWeeklyShipRevenue] = useState<
    { date: string; krw: number; usd: number }[]
  >([]);

  const [weeklyShopRevenue, setWeeklyShopRevenue] = useState<
    { date: string; krw: number; usd: number }[]
  >([]);

  const [error, setError] = useState<string | null>(null);

  const handleFetchRevenues = async () => {
    if (!startDate) {
      setError("날짜를 선택하세요.");
      return;
    }

    try {
      const shipResponse = await reqShiprevenue(startDate);
      setShipRevenue(shipResponse.data);

      const shopResponse = await reqAShopDaily(startDate);
      setShopRevenue(shopResponse.data);

      setError(null);
    } catch (error) {
      console.error("Error fetching revenue: ", error);
      setError("매출 데이터를 가져오는 중 오류가 발생했습니다.");
      setShipRevenue(null);
      setShopRevenue(null);
    }
  };

  const handleFetchWeeklyRevenues = async () => {
    if (!startDate) {
      setError("날짜를 선택하세요.");
      return;
    }

    const weekShipData: { date: string; krw: number; usd: number }[] = [];
    const weekShopData: { date: string; krw: number; usd: number }[] = [];

    for (let i = 0; i < 7; i++) {
      const currentDay = new Date(startDate);
      currentDay.setDate(currentDay.getDate() - i);
      const formattedDate = currentDay.toISOString().split("T")[0];

      try {
        const shipResponse = await reqShiprevenue(formattedDate);
        weekShipData.push({
          date: formattedDate,
          krw: shipResponse.data.krw,
          usd: shipResponse.data.usd,
        });

        const shopResponse = await reqAShopDaily(formattedDate);
        weekShopData.push({
          date: formattedDate,
          krw: shopResponse.data.totalKRWPaymentAmount,
          usd: shopResponse.data.totalUSDPaymentAmount,
        });
      } catch (error) {
        console.error(`Error fetching revenue for ${formattedDate}: `, error);
      }
    }

    setWeeklyShipRevenue(weekShipData.reverse());
    setWeeklyShopRevenue(weekShopData.reverse());
  };

  useEffect(() => {
    handleFetchRevenues();
    handleFetchWeeklyRevenues();
  }, [startDate]);

  const generateExcelSheet = (data: any[], sheetName: string) => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, sheetName);
    XLSX.writeFile(wb, `${sheetName}.xlsx`);
  };

  const downloadShipRevenueExcel = () => {
    if (!shipRevenue) return;

    const data = [
      { 항목: "총액", KRW: shipRevenue.krw, USD: shipRevenue.usd },
      {
        항목: "포장 비용",
        KRW: shipRevenue.packageKRW,
        USD: shipRevenue.packageUSD,
      },
      {
        항목: "옵션 비용",
        KRW: shipRevenue.optionKRW,
        USD: shipRevenue.optionUSD,
      },
      {
        항목: "창고 비용",
        KRW: shipRevenue.warehouseKRW,
        USD: shipRevenue.warehouseUSD,
      },
      {
        항목: "사용 포인트",
        KRW: shipRevenue.usePointKRW,
        USD: shipRevenue.usePointUSD,
      },
      {
        항목: "쿠폰 할인 금액",
        KRW: shipRevenue.discountCouponKRW,
        USD: shipRevenue.discountCouponUSD,
      },
    ];

    generateExcelSheet(data, "Ship_Revenue");
  };

  const downloadShopRevenueExcel = () => {
    if (!shopRevenue) return;

    const data = [
      {
        항목: "매출",
        KRW: shopRevenue.totalKRWPaymentAmount,
        USD: shopRevenue.totalUSDPaymentAmount,
      },
      {
        항목: "서비스 비용",
        KRW: shopRevenue.totalKRWServiceFee,
        USD: shopRevenue.totalUSDServiceFee,
      },
      {
        항목: "물품 가격",
        KRW: shopRevenue.totalKRWProductPrice,
        USD: shopRevenue.totalUSDProductPrice,
      },
      {
        항목: "배송비",
        KRW: shopRevenue.totalKRWShippingFee,
        USD: shopRevenue.totalUSDShippingFee,
      },
      {
        항목: "사용 포인트",
        KRW: shopRevenue.totalKRWPointsUsed,
        USD: shopRevenue.totalUSDPointsUsed,
      },
      {
        항목: "쿠폰 사용 금액",
        KRW: shopRevenue.totalKRWCouponsUsed,
        USD: shopRevenue.totalUSDCouponsUsed,
      },
      { 항목: "페이팔 수수료", KRW: "-", USD: shopRevenue.totalUSDPaypalFee },
    ];

    generateExcelSheet(data, "Shop_Revenue");
  };

  const downloadWeeklyShipRevenueExcel = () => {
    const data = weeklyShipRevenue.map((day) => ({
      날짜: day.date,
      KRW: day.krw,
      USD: day.usd,
    }));

    generateExcelSheet(data, "Weekly_Ship_Revenue");
  };

  const downloadWeeklyShopRevenueExcel = () => {
    const data = weeklyShopRevenue.map((day) => ({
      날짜: day.date,
      KRW: day.krw,
      USD: day.usd,
    }));

    generateExcelSheet(data, "Weekly_Shop_Revenue");
  };

  const downloadAllRevenueExcel = () => {
    const workbook = XLSX.utils.book_new();

    if (shipRevenue) {
      const shipData = [
        { 항목: "총액", KRW: shipRevenue.krw, USD: shipRevenue.usd },
        {
          항목: "포장 비용",
          KRW: shipRevenue.packageKRW,
          USD: shipRevenue.packageUSD,
        },
        {
          항목: "옵션 비용",
          KRW: shipRevenue.optionKRW,
          USD: shipRevenue.optionUSD,
        },
        {
          항목: "창고 비용",
          KRW: shipRevenue.warehouseKRW,
          USD: shipRevenue.warehouseUSD,
        },
        {
          항목: "사용 포인트",
          KRW: shipRevenue.usePointKRW,
          USD: shipRevenue.usePointUSD,
        },
        {
          항목: "쿠폰 할인 금액",
          KRW: shipRevenue.discountCouponKRW,
          USD: shipRevenue.discountCouponUSD,
        },
      ];
      const wsShip = XLSX.utils.json_to_sheet(shipData);
      XLSX.utils.book_append_sheet(workbook, wsShip, "Ship_Revenue");
    }

    if (shopRevenue) {
      const shopData = [
        {
          항목: "매출",
          KRW: shopRevenue.totalKRWPaymentAmount,
          USD: shopRevenue.totalUSDPaymentAmount,
        },
        {
          항목: "서비스 비용",
          KRW: shopRevenue.totalKRWServiceFee,
          USD: shopRevenue.totalUSDServiceFee,
        },
        {
          항목: "물품 가격",
          KRW: shopRevenue.totalKRWProductPrice,
          USD: shopRevenue.totalUSDProductPrice,
        },
        {
          항목: "배송비",
          KRW: shopRevenue.totalKRWShippingFee,
          USD: shopRevenue.totalUSDShippingFee,
        },
        {
          항목: "사용 포인트",
          KRW: shopRevenue.totalKRWPointsUsed,
          USD: shopRevenue.totalUSDPointsUsed,
        },
        {
          항목: "쿠폰 사용 금액",
          KRW: shopRevenue.totalKRWCouponsUsed,
          USD: shopRevenue.totalUSDCouponsUsed,
        },
        { 항목: "페이팔 수수료", KRW: "-", USD: shopRevenue.totalUSDPaypalFee },
      ];
      const wsShop = XLSX.utils.json_to_sheet(shopData);
      XLSX.utils.book_append_sheet(workbook, wsShop, "Shop_Revenue");
    }

    if (weeklyShipRevenue.length > 0) {
      const weeklyShipData = weeklyShipRevenue.map((day) => ({
        날짜: day.date,
        KRW: day.krw,
        USD: day.usd,
      }));
      const wsWeeklyShip = XLSX.utils.json_to_sheet(weeklyShipData);
      XLSX.utils.book_append_sheet(
        workbook,
        wsWeeklyShip,
        "Weekly_Ship_Revenue"
      );
    }

    if (weeklyShopRevenue.length > 0) {
      const weeklyShopData = weeklyShopRevenue.map((day) => ({
        날짜: day.date,
        KRW: day.krw,
        USD: day.usd,
      }));
      const wsWeeklyShop = XLSX.utils.json_to_sheet(weeklyShopData);
      XLSX.utils.book_append_sheet(
        workbook,
        wsWeeklyShop,
        "Weekly_Shop_Revenue"
      );
    }

    XLSX.writeFile(workbook, "All_Revenues.xlsx");
  };

  const shipKrwChartData = shipRevenue
    ? {
        labels: ["배송대행 KRW"],
        datasets: [
          {
            label: "총액",
            data: [shipRevenue.krw],
            backgroundColor: "rgba(75, 192, 192, 0.6)",
            borderColor: "rgba(75, 192, 192, 1)",
            borderWidth: 1,
          },
          {
            label: "포장 비용",
            data: [shipRevenue.packageKRW],
            backgroundColor: "rgba(153, 102, 255, 0.6)",
            borderColor: "rgba(153, 102, 255, 1)",
            borderWidth: 1,
          },
          {
            label: "옵션 비용",
            data: [shipRevenue.optionKRW],
            backgroundColor: "rgba(255, 159, 64, 0.6)",
            borderColor: "rgba(255, 159, 64, 1)",
            borderWidth: 1,
          },
          {
            label: "창고 비용",
            data: [shipRevenue.warehouseKRW],
            backgroundColor: "rgba(54, 162, 235, 0.6)",
            borderColor: "rgba(54, 162, 235, 1)",
            borderWidth: 1,
          },
          {
            label: "사용 포인트",
            data: [shipRevenue.usePointKRW],
            backgroundColor: "rgba(255, 206, 86, 0.6)",
            borderColor: "rgba(255, 206, 86, 1)",
            borderWidth: 1,
          },
          {
            label: "쿠폰 할인 금액",
            data: [shipRevenue.discountCouponKRW],
            backgroundColor: "rgba(231, 76, 60, 0.6)",
            borderColor: "rgba(231, 76, 60, 1)",
            borderWidth: 1,
          },
        ],
      }
    : null;

  const shipUsdChartData = shipRevenue
    ? {
        labels: ["배송대행 USD"],
        datasets: [
          {
            label: "총액",
            data: [shipRevenue.usd],
            backgroundColor: "rgba(75, 192, 192, 0.6)",
            borderColor: "rgba(75, 192, 192, 1)",
            borderWidth: 1,
          },
          {
            label: "포장 비용",
            data: [shipRevenue.packageUSD],
            backgroundColor: "rgba(153, 102, 255, 0.6)",
            borderColor: "rgba(153, 102, 255, 1)",
            borderWidth: 1,
          },
          {
            label: "옵션 비용",
            data: [shipRevenue.optionUSD],
            backgroundColor: "rgba(255, 159, 64, 0.6)",
            borderColor: "rgba(255, 159, 64, 1)",
            borderWidth: 1,
          },
          {
            label: "창고 비용",
            data: [shipRevenue.warehouseUSD],
            backgroundColor: "rgba(54, 162, 235, 0.6)",
            borderColor: "rgba(54, 162, 235, 1)",
            borderWidth: 1,
          },
          {
            label: "사용 포인트",
            data: [shipRevenue.usePointUSD],
            backgroundColor: "rgba(255, 206, 86, 0.6)",
            borderColor: "rgba(255, 206, 86, 1)",
            borderWidth: 1,
          },
          {
            label: "쿠폰 할인 금액",
            data: [shipRevenue.discountCouponUSD],
            backgroundColor: "rgba(231, 76, 60, 0.6)",
            borderColor: "rgba(231, 76, 60, 1)",
            borderWidth: 1,
          },
        ],
      }
    : null;

  const shopKrwChartData = shopRevenue
    ? {
        labels: ["구매대행 KRW"],
        datasets: [
          {
            label: "매출",
            data: [shopRevenue.totalKRWPaymentAmount],
            backgroundColor: "rgba(75, 192, 192, 0.6)",
            borderColor: "rgba(75, 192, 192, 1)",
            borderWidth: 1,
          },
          {
            label: "배송비",
            data: [shopRevenue.totalKRWShippingFee],
            backgroundColor: "rgba(153, 102, 255, 0.6)",
            borderColor: "rgba(153, 102, 255, 1)",
            borderWidth: 1,
          },
          {
            label: "서비스 비용",
            data: [shopRevenue.totalKRWServiceFee],
            backgroundColor: "rgba(255, 159, 64, 0.6)",
            borderColor: "rgba(255, 159, 64, 1)",
            borderWidth: 1,
          },
          {
            label: "사용 포인트",
            data: [shopRevenue.totalKRWPointsUsed],
            backgroundColor: "rgba(54, 162, 235, 0.6)",
            borderColor: "rgba(54, 162, 235, 1)",
            borderWidth: 1,
          },
          {
            label: "쿠폰 사용 금액",
            data: [shopRevenue.totalKRWCouponsUsed],
            backgroundColor: "rgba(255, 206, 86, 0.6)",
            borderColor: "rgba(255, 206, 86, 1)",
            borderWidth: 1,
          },
          {
            label: "물품 가격",
            data: [shopRevenue.totalKRWProductPrice],
            backgroundColor: "rgba(231, 76, 60, 0.6)",
            borderColor: "rgba(231, 76, 60, 1)",
            borderWidth: 1,
          },
        ],
      }
    : null;

  const shopUsdChartData = shopRevenue
    ? {
        labels: ["구매대행 USD"],
        datasets: [
          {
            label: "매출",
            data: [shopRevenue.totalUSDPaymentAmount],
            backgroundColor: "rgba(75, 192, 192, 0.6)",
            borderColor: "rgba(75, 192, 192, 1)",
            borderWidth: 1,
          },
          {
            label: "배송비",
            data: [shopRevenue.totalUSDShippingFee],
            backgroundColor: "rgba(153, 102, 255, 0.6)",
            borderColor: "rgba(153, 102, 255, 1)",
            borderWidth: 1,
          },
          {
            label: "서비스 비용",
            data: [shopRevenue.totalUSDServiceFee],
            backgroundColor: "rgba(255, 159, 64, 0.6)",
            borderColor: "rgba(255, 159, 64, 1)",
            borderWidth: 1,
          },
          {
            label: "사용 포인트",
            data: [shopRevenue.totalUSDPointsUsed],
            backgroundColor: "rgba(54, 162, 235, 0.6)",
            borderColor: "rgba(54, 162, 235, 1)",
            borderWidth: 1,
          },
          {
            label: "쿠폰 사용 금액",
            data: [shopRevenue.totalUSDCouponsUsed],
            backgroundColor: "rgba(255, 206, 86, 0.6)",
            borderColor: "rgba(255, 206, 86, 1)",
            borderWidth: 1,
          },
          {
            label: "페이팔 수수료",
            data: [shopRevenue.totalUSDPaypalFee],
            backgroundColor: "rgba(231, 76, 60, 0.6)",
            borderColor: "rgba(231, 76, 60, 1)",
            borderWidth: 1,
          },
          {
            label: "물품 가격",
            data: [shopRevenue.totalUSDProductPrice],
            backgroundColor: "rgba(241, 196, 15, 0.6)",
            borderColor: "rgba(241, 196, 15, 1)",
            borderWidth: 1,
          },
        ],
      }
    : null;

  const weeklyShipKrwChartData = {
    labels: weeklyShipRevenue.map((day) => day.date),
    datasets: [
      {
        label: "KRW (배송대행)",
        data: weeklyShipRevenue.map((day) => day.krw),
        backgroundColor: "rgba(75, 192, 192, 0.6)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
  };

  const weeklyShipUsdChartData = {
    labels: weeklyShipRevenue.map((day) => day.date),
    datasets: [
      {
        label: "USD (배송대행)",
        data: weeklyShipRevenue.map((day) => day.usd),
        backgroundColor: "rgba(153, 102, 255, 0.6)",
        borderColor: "rgba(153, 102, 255, 1)",
        borderWidth: 1,
      },
    ],
  };

  const weeklyShopKrwChartData = {
    labels: weeklyShopRevenue.map((day) => day.date),
    datasets: [
      {
        label: "KRW (구매대행)",
        data: weeklyShopRevenue.map((day) => day.krw),
        backgroundColor: "rgba(255, 159, 64, 0.6)",
        borderColor: "rgba(255, 159, 64, 1)",
        borderWidth: 1,
      },
    ],
  };

  const weeklyShopUsdChartData = {
    labels: weeklyShopRevenue.map((day) => day.date),
    datasets: [
      {
        label: "USD (구매대행)",
        data: weeklyShopRevenue.map((day) => day.usd),
        backgroundColor: "rgba(54, 162, 235, 0.6)",
        borderColor: "rgba(54, 162, 235, 1)",
        borderWidth: 1,
      },
    ],
  };

  return (
    <Container>
      <h2>일매출 통계</h2>
      <DateInputContainer>
        <label>날짜 선택: </label>
        <input
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
        />
        <button onClick={handleFetchRevenues}>조회</button>
        <Button
          onClick={downloadAllRevenueExcel}
          style={{
            marginLeft: "auto",
            display: "block",
          }}
        >
          전체 엑셀 다운로드
        </Button>
      </DateInputContainer>
      {error && <ErrorMessage>{error}</ErrorMessage>}
      {shipRevenue && (
        <Section>
          <RevenueTableContainer>
            <h3>배송대행 매출</h3>
            <RevenueTable>
              <thead>
                <tr>
                  <th>항목</th>
                  <th>KRW</th>
                  <th>USD</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>총액</td>
                  <td>{Math.floor(shipRevenue.krw).toLocaleString()}</td>
                  <td>{shipRevenue.usd.toFixed(2)}</td>
                </tr>
                <tr>
                  <td>포장 비용</td>
                  <td>{Math.floor(shipRevenue.packageKRW).toLocaleString()}</td>
                  <td>{shipRevenue.packageUSD.toFixed(2)}</td>
                </tr>
                <tr>
                  <td>옵션 비용</td>
                  <td>{Math.floor(shipRevenue.optionKRW).toLocaleString()}</td>
                  <td>{shipRevenue.optionUSD.toFixed(2)}</td>
                </tr>
                <tr>
                  <td>창고 비용</td>
                  <td>
                    {Math.floor(shipRevenue.warehouseKRW).toLocaleString()}
                  </td>
                  <td>{shipRevenue.warehouseUSD.toFixed(2)}</td>
                </tr>
                <tr>
                  <td>사용 포인트</td>
                  <td>
                    {Math.floor(shipRevenue.usePointKRW).toLocaleString()}
                  </td>
                  <td>{shipRevenue.usePointUSD.toFixed(2)}</td>
                </tr>
                <tr>
                  <td>쿠폰 할인 금액</td>
                  <td>
                    {Math.floor(shipRevenue.discountCouponKRW).toLocaleString()}
                  </td>
                  <td>{shipRevenue.discountCouponUSD.toFixed(2)}</td>
                </tr>
              </tbody>
            </RevenueTable>
            <Button onClick={downloadShipRevenueExcel}>엑셀 다운로드</Button>
          </RevenueTableContainer>
          <ChartContainer>
            {shipKrwChartData && <Bar data={shipKrwChartData} />}
            {shipUsdChartData && <Bar data={shipUsdChartData} />}
          </ChartContainer>
        </Section>
      )}
      {shopRevenue && (
        <Section>
          <RevenueTableContainer>
            <h3>구매대행 매출</h3>
            <RevenueTable>
              <thead>
                <tr>
                  <th>항목</th>
                  <th>KRW</th>
                  <th>USD</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>매출</td>
                  <td>
                    {Math.floor(
                      shopRevenue.totalKRWPaymentAmount
                    ).toLocaleString()}
                  </td>
                  <td>{shopRevenue.totalUSDPaymentAmount.toFixed(2)}</td>
                </tr>
                <tr>
                  <td>서비스 비용</td>
                  <td>
                    {Math.floor(
                      shopRevenue.totalKRWServiceFee
                    ).toLocaleString()}
                  </td>
                  <td>{shopRevenue.totalUSDServiceFee.toFixed(2)}</td>
                </tr>
                <tr>
                  <td>물품 가격</td>
                  <td>
                    {Math.floor(
                      shopRevenue.totalKRWProductPrice
                    ).toLocaleString()}
                  </td>
                  <td>{shopRevenue.totalUSDProductPrice.toFixed(2)}</td>
                </tr>
                <tr>
                  <td>배송비</td>
                  <td>
                    {Math.floor(
                      shopRevenue.totalKRWShippingFee
                    ).toLocaleString()}
                  </td>
                  <td>{shopRevenue.totalUSDShippingFee.toFixed(2)}</td>
                </tr>
                <tr>
                  <td>사용 포인트</td>
                  <td>
                    {Math.floor(
                      shopRevenue.totalKRWPointsUsed
                    ).toLocaleString()}
                  </td>
                  <td>{shopRevenue.totalUSDPointsUsed.toFixed(2)}</td>
                </tr>
                <tr>
                  <td>쿠폰 사용 금액</td>
                  <td>
                    {Math.floor(
                      shopRevenue.totalKRWCouponsUsed
                    ).toLocaleString()}
                  </td>
                  <td>{shopRevenue.totalUSDCouponsUsed.toFixed(2)}</td>
                </tr>
                <tr>
                  <td>페이팔 수수료</td>
                  <td>-</td>
                  <td>{shopRevenue.totalUSDPaypalFee.toFixed(2)}</td>
                </tr>
              </tbody>
            </RevenueTable>
            <Button onClick={downloadShopRevenueExcel}>엑셀 다운로드</Button>
          </RevenueTableContainer>
          <ChartContainer>
            {shopKrwChartData && <Bar data={shopKrwChartData} />}
            {shopUsdChartData && <Bar data={shopUsdChartData} />}
          </ChartContainer>
        </Section>
      )}
      <Section>
        <LargeChartContainer>
          <Button onClick={downloadWeeklyShipRevenueExcel}>
            엑셀 다운로드
          </Button>
          <div>
            <h3>최근 7일 매출 (배송대행 KRW)</h3>
            <Bar
              data={weeklyShipKrwChartData}
              options={{ maintainAspectRatio: false }}
            />
          </div>
          <div>
            <h3>최근 7일 매출 (배송대행 USD)</h3>
            <Bar
              data={weeklyShipUsdChartData}
              options={{ maintainAspectRatio: false }}
            />
          </div>
        </LargeChartContainer>
        <LargeChartContainer>
          <Button onClick={downloadWeeklyShopRevenueExcel}>
            엑셀 다운로드
          </Button>
          <div>
            <h3>최근 7일 매출 (구매대행 KRW)</h3>
            <Bar
              data={weeklyShopKrwChartData}
              options={{ maintainAspectRatio: false }}
            />
          </div>
          <div>
            <h3>최근 7일 매출 (구매대행 USD)</h3>
            <Bar
              data={weeklyShopUsdChartData}
              options={{ maintainAspectRatio: false }}
            />
          </div>
        </LargeChartContainer>
      </Section>
    </Container>
  );
};

const Container = styled.div`
  max-width: 1500px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
`;

const DateInputContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  label {
    margin-right: 10px;
    font-weight: bold;
  }

  input {
    padding: 8px;
    margin-right: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }

  button {
    padding: 8px 12px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    &:hover {
      background-color: #0056b3;
    }
  }
`;

const Section = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
`;

const RevenueTableContainer = styled.div`
  flex: 1;
`;

const RevenueTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  th,
  td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: center;
  }

  th {
    background-color: #f2f2f2;
  }

  td {
    background-color: #ffffff;
  }
`;

const LargeChartContainer = styled.div`
  flex: 1;
  padding: 20px;
  canvas {
    height: 300px !important; /* 그래프의 높이를 크게 설정 */
  }
`;

const ChartContainer = styled.div`
  flex: 1;
  margin-left: 20px;
`;

const Button = styled.button`
  margin-top: 10px;
  padding: 10px 15px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #218838;
  }
`;

const ErrorMessage = styled.div`
  color: red;
  margin-bottom: 20px;
`;

export default DailyRevenueStatistics;
