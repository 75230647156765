import { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import styled from "styled-components";
import Swal from "sweetalert2";
import { CODE_COUNTRY_ITEMS, MEMBERSHIP } from "../../../common/constants";
import AdminHeadTitle from "../../../components/AdminHeadTitle";
import { RootState } from "../../../redux/store";
import { reqIssueCheckContentsCoupon } from "../../../requests/coupon";
import {
  reqGetAllUserById,
  reqGetEveryUserServiceLog,
  reqPostPointlog,
  reqUpdateUserMembership,
  reqUpdateUserPoints,
  reqUpdateUserPointsUSD,
  reqUpdateUserStatus,
} from "../../../requests/user";

interface User {
  id: string;
  name: string;
  country: number;
  oldid: string;
  saveid: string;
  email: string;
  point: number;
  pointUsd: number;
  latestWarehouseId: string;
  membershipId: number;
  status: number;
}

export default function ACustomerCare() {
  const [users, setUsers] = useState<User[]>([]);
  const [shipLog, setShipLog] = useState<any>({});
  const [shopLog, setShopLog] = useState<any>({});
  const [showModal, setShowModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [updatedPoints, setUpdatedPoints] = useState<number>(0);
  const [updatedPointsUSD, setUpdatedPointsUSD] = useState<number>(0);
  const [membershipStatus, setMembershipStatus] = useState<number>(3);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [newUserId, setNewUserId] = useState("");
  const [editMode, setEditMode] = useState(false);
  const admin = useSelector((state: RootState) => state.admin);
  const [reason, setReason] = useState<string>("");

  useEffect(() => {
    fetchAllUsers();
  }, []);

  const fetchAllUsers = async () => {
    try {
      const response = await reqGetAllUserById();
      setUsers(response.data);

      const serviceLogs = await reqGetEveryUserServiceLog();
      setShipLog(serviceLogs.data.shipLog);
      setShopLog(serviceLogs.data.shopLog);
    } catch (error) {
      console.error("전체 사용자 정보 조회 실패:", error);
    }
  };

  const handleRowClick = (user: User) => {
    setSelectedUser(user);
    setUpdatedPoints(0);
    setUpdatedPointsUSD(0);
    setMembershipStatus(user.membershipId);
    setNewUserId(user.id);
    setShowModal(true);
    setEditMode(false);
  };

  const handleSaveChanges = async () => {
    if (!selectedUser) return;

    Swal.fire({
      title: "진행하시겠습니까?",
      text: "변경 사항을 저장하시겠습니까?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, save it!",
      cancelButtonText: "No, cancel!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const updatePointsResult = await updateUserPoints();
        const updatePointsUSDResult = await updateUserPointUSD();
        const updateMembershipResult = await updateUserMembership();

        if (
          updatePointsResult &&
          updatePointsUSDResult &&
          updateMembershipResult
        ) {
          Swal.fire("Saved!", "The changes have been saved.", "success");
          setShowModal(false);
          window.location.reload();
        } else {
          Swal.fire(
            "Failed!",
            "There was an error saving the changes.",
            "error"
          );
        }
      }
    });
  };

  const updateUserPoints = async () => {
    if (!selectedUser) return false;

    try {
      const pointsToAdd =
        updatedPoints !== null ? updatedPoints : selectedUser.point;
      if (pointsToAdd === 0) return true;

      await reqUpdateUserPoints(selectedUser.id, { pointsToAdd });

      setUsers(
        users.map((user) => {
          if (user.id === selectedUser.id) {
            return {
              ...user,
              point: pointsToAdd,
            };
          }
          return user;
        })
      );

      const pointLogPayload = {
        userId: selectedUser.id,
        grant: true,
        krwGrantPoint: pointsToAdd,
        status: 4,
        manager: admin.name,
        reason: reason,
      };

      if (pointsToAdd > 0) {
        try {
          await reqPostPointlog(pointLogPayload);
        } catch (error) {
          console.error("Failed to log points:", error);
        }
      }

      console.log(`User ${selectedUser.id}'s points updated successfully.`);
      return true;
    } catch (error) {
      console.error("Failed to update user points:", error);
      return false;
    }
  };

  const updateUserPointUSD = async () => {
    if (!selectedUser) return false;

    try {
      const pointsToAdd =
        updatedPointsUSD !== null ? updatedPointsUSD : selectedUser.pointUsd;
      if (pointsToAdd === 0) return true;

      await reqUpdateUserPointsUSD(selectedUser.id, { pointsToAdd });

      setUsers(
        users.map((user) => {
          if (user.id === selectedUser.id) {
            return {
              ...user,
              pointUsd: pointsToAdd,
            };
          }
          return user;
        })
      );

      // USD 포인트 로그
      const pointLogPayload = {
        userId: selectedUser.id,
        grant: true,
        usdGrantPoint: parseFloat(pointsToAdd.toFixed(2)),
        status: 4,
        manager: admin.name,
        reason: reason,
      };

      if (parseFloat(pointsToAdd.toFixed(2)) > 0) {
        try {
          await reqPostPointlog(pointLogPayload);
        } catch (error) {
          console.error("Failed to log points:", error);
        }
      }

      console.log(`User ${selectedUser.id}'s USD points updated successfully.`);
      return true;
    } catch (error) {
      console.error("Failed to update user USD points:", error);
      return false;
    }
  };

  const updateUserMembership = async () => {
    if (!selectedUser) return false;

    try {
      // 기존의 멤버십 상태를 저장
      const originalMembershipStatus = selectedUser.membershipId;

      // 멤버십 상태가 변경된 경우에만 업데이트 진행
      if (originalMembershipStatus !== membershipStatus) {
        const membershipPayload = {
          id: selectedUser.id,
          membershipId: membershipStatus,
        };
        await reqUpdateUserMembership(membershipPayload);

        setUsers(
          users.map((user) => {
            if (user.id === selectedUser.id) {
              return {
                ...user,
                membershipId: membershipStatus,
              };
            }
            return user;
          })
        );

        // 멤버십이 실제로 변경되었을 때만 쿠폰 발급 함수 호출
        await issueCheckContentsCoupon(selectedUser.id, membershipStatus);

        console.log(
          `User ${selectedUser.id}'s membership updated successfully.`
        );
        return true;
      } else {
        console.log(`No changes to User ${selectedUser.id}'s membership.`);
        return true;
      }
    } catch (error) {
      console.error("Failed to update user membership:", error);
      return false;
    }
  };

  // 쿠폰 지급 요청 함수
  const issueCheckContentsCoupon = async (
    userId: string,
    membershipId: number
  ) => {
    const couponCount = membershipId === 1 ? 12 : membershipId === 2 ? 3 : 0;
    if (couponCount > 0) {
      const result = await reqIssueCheckContentsCoupon({
        userId,
        membershipId,
        count: couponCount,
      });
      if (result.data.success) {
        Swal.fire({
          icon: "success",
          title: `${couponCount} check contents coupons have been issued.`,
        });
        return true;
      } else {
        Swal.fire({
          icon: "error",
          title: "Failed to issue check contents coupons.",
        });
        return false;
      }
    }
    return false;
  };

  // New function to handle status toggle
  const handleToggleStatus = async (event: React.MouseEvent, user: User) => {
    event.stopPropagation(); // Prevent the row click event
    try {
      const updatedStatus = user.status === 0 ? 1 : 0;
      await reqUpdateUserStatus(user.id, { status: updatedStatus });

      setUsers(
        users.map((u) =>
          u.id === user.id ? { ...u, status: updatedStatus } : u
        )
      );

      Swal.fire(
        "Updated!",
        `User status has been updated to ${
          updatedStatus === 0 ? "Active" : "Inactive"
        }.`,
        "success"
      );
    } catch (error) {
      console.error("Failed to update user status:", error);
      Swal.fire("Failed!", "There was an error updating the status.", "error");
    }
  };

  const filteredUsers = searchKeyword.trim()
    ? users.filter((user) => user.id === searchKeyword)
    : users;

  // 띄어쓰기
  const handleKeyPress = (event: any) => {
    if (event.key === " ") {
      event.preventDefault();
    }
  };

  return (
    <main id="main" className="main">
      <div>
        <div className="pagetitle">
          <AdminHeadTitle
            subtitle1="최고 관리자"
            subtitle2="전체 고객 리스트"
            title="전체 고객 리스트"
          />
          <FilterBar>
            <Form>
              <Form.Group controlId="searchKeyword">
                <Form.Control
                  type="text"
                  placeholder="스페이스 코드로 검색"
                  value={searchKeyword.toUpperCase()}
                  onKeyPress={handleKeyPress}
                  onChange={(e) => setSearchKeyword(e.target.value)}
                />
              </Form.Group>
            </Form>
          </FilterBar>
          <TableContainer>
            <ResponsiveTable>
              <thead>
                <tr>
                  <th>이름</th>
                  <th>스페이스코드</th>
                  <th>국가</th>
                  <th>이메일</th>
                  <th>포인트(KRW)</th>
                  <th>포인트(USD)</th>
                  <th>멤버십 등급</th>
                  <th>서비스이용 횟수</th>
                  <th>서비스이용 금액 KRW</th>
                  <th>서비스이용 금액 USD</th>
                  <th>Status</th>
                  <th>Toggle Status</th>
                </tr>
              </thead>
              <tbody>
                {filteredUsers.map((user, index) => (
                  <tr key={index} onClick={() => handleRowClick(user)}>
                    <td>{user.name}</td>
                    <td>{user.id}</td>
                    <td>{CODE_COUNTRY_ITEMS[user.country] || "Unknown"}</td>
                    <td>{user.email}</td>
                    <td>₩{user.point}</td>
                    <td>${user.pointUsd}</td>
                    <td>{MEMBERSHIP[user.membershipId]}</td>
                    <td>
                      {shipLog[user.id]?.count + shopLog[user.id]?.count || 0}
                    </td>
                    <td>
                      {(
                        shipLog[user.id]?.krwAmount +
                          shopLog[user.id]?.krwAmount || 0
                      ).toLocaleString("ko-KR")}
                    </td>
                    <td>
                      {shipLog[user.id]?.usdAmount +
                        shopLog[user.id]?.usdAmount || 0}
                    </td>
                    <td>{user.status === 0 ? "Active" : "Inactive"}</td>
                    <td>
                      <Button
                        onClick={(event) => handleToggleStatus(event, user)}
                      >
                        {user.status === 0 ? "Deactivate" : "Activate"}
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </ResponsiveTable>
          </TableContainer>
        </div>
      </div>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>멤버십 변경 및 포인트 부여</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {/* Editable form fields */}
            <Form.Group controlId="formPoints">
              <Form.Label>포인트 부여(KRW)</Form.Label>
              <Form.Control
                type="number"
                value={updatedPoints.toString()}
                onChange={(e) =>
                  setUpdatedPoints(
                    e.target.value ? parseInt(e.target.value) : 0
                  )
                }
                readOnly={!editMode}
              />
            </Form.Group>
            <Form.Group controlId="formPointsUSD">
              <Form.Label>포인트 부여(USD)</Form.Label>
              <Form.Control
                type="number"
                value={updatedPointsUSD.toString()}
                onChange={(e) =>
                  setUpdatedPointsUSD(
                    e.target.value ? parseFloat(e.target.value) : 0
                  )
                }
                readOnly={!editMode}
              />
            </Form.Group>
            <Form.Group controlId="reason">
              {" "}
              <Form.Label>부여 사유 (Reason)</Form.Label>
              <Form.Control
                type="text"
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                readOnly={!editMode}
              />
            </Form.Group>
            <Form.Group controlId="membershipStatus">
              <Form.Label>멤버십 변경</Form.Label>
              <Form.Select
                value={membershipStatus}
                onChange={(e) => setMembershipStatus(parseInt(e.target.value))}
                disabled={!editMode}
              >
                <option value="1">Red</option>
                <option value="2">Blue</option>
                <option value="3">Green</option>
              </Form.Select>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant={editMode ? "success" : "primary"}
            onClick={editMode ? handleSaveChanges : () => setEditMode(true)}
          >
            {editMode ? "저장" : "수정"}
          </Button>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            닫기
          </Button>
        </Modal.Footer>
      </Modal>
    </main>
  );
}

const ResponsiveTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  table-layout: auto;
  margin-top: 20px;

  th,
  td {
    padding: 8px;
    text-align: center;
    border: 1px solid #ddd;
  }

  tbody tr:hover {
    background-color: #f1f1f1;
  }

  @media (max-width: 768px) {
    font-size: 0.8em;
    th,
    td {
      padding: 6px;
    }
  }

  @media (max-width: 480px) {
    font-size: 0.7em;
    th,
    td {
      padding: 4px;
    }
  }
`;

const FilterBar = styled.div`
  margin-bottom: 20px;
`;

const TableContainer = styled.div`
  overflow-x: auto;
  margin-top: 20px;
`;
