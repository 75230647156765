import { AxiosManager } from "../axiosManager";

// 새로운 결제 로그를 생성
export const reqPayment = async (payload: any) => {
  return await AxiosManager.post("payment-logs", payload);
};

// 모든 결제 로그를 가져옴
export const getAllPayments = async () => {
  return await AxiosManager.get("payment-logs");
};

// 특정 ID의 결제 로그를 가져옴
export const getPaymentById = async (id: number) => {
  return await AxiosManager.get(`payment-logs/${id}`);
};

// 새로운 픽업 요청을 생성
export const reqPickUpRequest = async (payload: any) => {
  return await AxiosManager.post("rate/pick-up", payload);
};