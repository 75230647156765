import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Collapse,
  Dropdown,
  FormControl,
  Modal,
  Table,
} from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
import Select, { SingleValue } from "react-select";
import styled from "styled-components";
import Swal from "sweetalert2";
import {
  CODE_COUNTRY_ITEMS,
  CODE_SHIPPING_PRODUCT_STATUS,
  CODE_SHOPING_PRODUCT_DETAIL_STATUS,
  CODE_SHOPPING_ORDER_ITEMS,
} from "../../common/constants";
import { RootUserState } from "../../redux/store";
import {
  reqShop,
  reqShopDetail,
  reqShopDetailUpdate,
  reqShopDetailpPost,
  reqShopPost,
} from "../../requests/shop";
import { reqShipFEE } from "../../requests/ship";
import { formatDate } from "../../common/format";
import { MBody, MButton, MFooter, MRow } from "../../common/mobile-styled";
interface ShopOrderModalProps {
  show: boolean;
  onHide: () => void;
  orderId: string;
  refreshData: () => void;
}
interface OrderDetail {
  order_id: string;
  productId: string;
  category: number;
  status: number;
  name: string;
  option1: string;
  option2: string;
  quantity: number;
  price: number;
  url: string;
  preorder: boolean;
  preorderStartAt: string;
  preorderEndAt: string;
  fancall: boolean;
  fancallName: string;
  ShippingFee: number;
  fancallBirthday: string;
  fancallNumber: string;
  fancallEmail: string;
  fancallCountry: number;
  fancallKakao: string;
  fancallSnsLine: string;
  isCanceled: boolean;
}

interface ProductDetail {
  name: string;
  category: number;
  option1: string;
  option2: string;
  quantity: number;
  price: number;
  url: string;
  preorder: boolean;
  preorderStartAt?: string;
  preorderEndAt?: string;
  fancall: boolean;
  fancallName?: string;
  fancallBirthday?: string;
  fancallNumber?: string;
  fancallEmail?: string;
  fancallCountry?: number;
  fancallKakao?: string;
  fancallSnsLine?: string;
}

interface AdditionalInfo {
  failed: boolean;
}

interface UpdatedOrderDetails {
  [key: string]: OrderDetail;
}

interface ShopData {
  account: string;
  failed: boolean;
  refund: boolean;
  usePointKrw: number;
  usePointUse: number;
  snKrwShippingFee: number;
  snKrwFee: number;
  snKrwTotalFee: number;
  snKrwRealFee: number;
  snKrwPointApplied: number;
  snKrwCouponApplied: number;
  snUsdFee: number;
  snUsdTotalFee: number;
  snUsdRealFee: number;
  snUsdPointApplied: number;
  snUsdCouponApplied: number;
  snUsdPaypalSurcharge: number;
  snUsdShippingFee: number;
  PaymentMethod: number;
}

const CShopOrderPreorderModal = ({
  show,
  onHide,
  orderId,
  refreshData,
}: ShopOrderModalProps) => {
  const [orderDetails, setOrderDetails] = useState<OrderDetail[]>([]);
  const [serviceFee, setServiceFee] = useState("");
  const [productDetails, setProductDetails] = useState<ProductDetail[]>([]);
  const [shopData, setShopData] = useState<ShopData | null>(null);
  const [paymentDeadline, setPaymentDeadline] = useState("");
  const [fixedExchangeRate, setFixedExchangeRate] = useState(0);
  const [exchangeRate, setExchangeRate] = useState(0);
  const [UtotalUSD, setUtotalUSD] = useState(0);
  const excludeStatuses = [3, 4, 5, 9, 10];
  const [step, setStep] = useState(1);
  const [PaymentMethod, setPaymentMethod] = useState(1);
  const [selectedDetail, setSelectedDetail] = useState<OrderDetail | null>(
    null
  );
  const [confirmCancelModalShow, setConfirmCancelModalShow] = useState(false);
  const [updatedOrderDetails, setUpdatedOrderDetails] =
    useState<UpdatedOrderDetails>({});
  const [pointsApplied, setPointsApplied] = useState(0);
  const handleCancelItem = async (index: number) => {
    const updatedDetails = {
      ...orderDetails[index],
      isCanceled: true,
      status: CODE_SHIPPING_PRODUCT_STATUS.CANCELED,
      order_id: orderId,
    };

    setOrderDetails((prevDetails) =>
      prevDetails.map((detail, idx) =>
        idx === index ? updatedDetails : detail
      )
    );

    try {
      await reqShopDetailUpdate([updatedDetails]);
      alert("상품이 취소되었습니다.");
    } catch (error) {
      console.error("상품 취소 실패:", error);
      alert("상품 취소에 실패했습니다.");
    }
  };

  const fetchFixedExchangeRate = async () => {
    try {
      const response = await reqShipFEE();
      if (response && response.data && response.data[0]?.fixedExchangeRateShop) {
        setFixedExchangeRate(parseFloat(response.data[0].fixedExchangeRateShop));
      }
    } catch (error) {
      console.error("Error fetching fixed exchange rate:", error);
    }
  };

  const convertToUSD = (amount: number) => {
    return (amount / fixedExchangeRate).toFixed(2);
  };

  useEffect(() => {
    // fetchExchangeRate();
    fetchFixedExchangeRate();
    if (orderId) {
      fetchOrderDetails(orderId);
    }
  }, [orderId]);

  // const fetchExchangeRate = () => {
  //   fetch(
  //     "https://quotation-api-cdn.dunamu.com/v1/forex/recent?codes=FRX.KRWUSD"
  //   )
  //     .then((response) => response.json())
  //     .then((data) => {
  //       const rate = data[0].basePrice;
  //       setExchangeRate(rate);
  //     })
  //     .catch((error) => console.error("Error fetching exchange rate:", error));
  // };

  useEffect(() => {
    if (orderId) {
      fetchOrderDetails(orderId);
    }
  }, [orderId]);

  const formatPaymentDeadlineUTC = (isoString: any) => {
    const date = new Date(isoString);
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const day = String(date.getUTCDate()).padStart(2, "0");
    const hours = String(date.getUTCHours()).padStart(2, "0");
    const minutes = String(date.getUTCMinutes()).padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  const fetchOrderDetails = async (order_id: string) => {
    try {
      const detailResponse = await reqShopDetail({ order_id });
      const detailsWithStatus: OrderDetail[] = detailResponse.data.map(
        (item: OrderDetail) => ({
          ...item,
          isCanceled: item.status === 10,
        })
      );
      setOrderDetails(detailsWithStatus);

      const shopResponse = await reqShop({ order_id });
      const feeValue = shopResponse.data.fee?.toString() ?? "";
      setServiceFee(feeValue);
      setShopData(shopResponse.data);
      if (shopResponse.data.paymentDeadline) {
        const formattedDeadline = new Date(shopResponse.data.paymentDeadline)
          .toISOString()
          .slice(0, 16);
        setPaymentDeadline(formattedDeadline);
      }
    } catch (error) {
      console.error("Error fetching order details:", error);
    }
  };

  const calculateTotal = () => {
    if (orderDetails.length === 0) {
      return {
        productTotal: 0,
        domesticShippingFee: 0,
        serviceFeeValue: 0,
        paypalFee: 0,
        total: 0,
        Utotal: 0,
        UtotalUSD: 0,
      };
    }

    const statusesToCheck = [
      CODE_SHOPING_PRODUCT_DETAIL_STATUS.EXPIRATION,
      CODE_SHOPING_PRODUCT_DETAIL_STATUS.RESTRICTION,
      CODE_SHOPING_PRODUCT_DETAIL_STATUS.SOLD_OUT,
      CODE_SHOPING_PRODUCT_DETAIL_STATUS.CANCELED,
      CODE_SHOPING_PRODUCT_DETAIL_STATUS.POINT,
    ];

    const allInSpecifiedStatuses = orderDetails.every((detail) =>
      statusesToCheck.includes(detail.status)
    );

    const productTotal = orderDetails.reduce((total, item) => {
      if (!excludeStatuses.includes(item.status)) {
        return total + item.quantity * item.price;
      }
      return total;
    }, 0);

    const domesticShippingFee = orderDetails.reduce((total, item) => {
      if (!excludeStatuses.includes(item.status)) {
        return total + item.ShippingFee;
      }
      return total;
    }, 0);

    let serviceFeeValue = 0;
    if (!allInSpecifiedStatuses) {
      const parsedServiceFee = parseFloat(serviceFee);
      serviceFeeValue = Math.round(
        productTotal * (isNaN(parsedServiceFee) ? 0 : parsedServiceFee / 100)
      );
      serviceFeeValue = Math.max(serviceFeeValue, 5000);
    }

    const paypalFee =
      (productTotal + serviceFeeValue + domesticShippingFee) * 0.05;
    const UtotalUSDValue = convertToUSD(
      productTotal + domesticShippingFee + serviceFeeValue + paypalFee
    );

    return {
      productTotal,
      domesticShippingFee,
      serviceFeeValue,
      paypalFee,
      total: productTotal + domesticShippingFee + serviceFeeValue,
      Utotal: productTotal + domesticShippingFee + serviceFeeValue + paypalFee,
      UtotalUSD: parseFloat(UtotalUSDValue),
    };
  };

  const {
    productTotal,
    domesticShippingFee,
    serviceFeeValue,
    paypalFee,
    total,
    Utotal,
  } = calculateTotal();

  const handleOrderDetailChange = (
    index: any,
    field: keyof OrderDetail,
    value: any
  ) => {
    setOrderDetails((prevDetails) =>
      prevDetails.map((detail, idx) =>
        idx === index ? { ...detail, [field]: value } : detail
      )
    );

    setUpdatedOrderDetails((prevDetails) => {
      const updatedDetails = { ...prevDetails };
      updatedDetails[index] = {
        ...(updatedDetails[index] || orderDetails[index]),
        [field]: value,
      };
      return updatedDetails;
    });
  };

  const [additionalInfo, setAdditionalInfo] = useState<AdditionalInfo>({
    failed: false,
  });
  const user = useSelector((state: RootUserState) => state.user);

  const submitAllInfo = async () => {
    const updatedProductDetails = products.map((product) => ({
      name: product.name,
      category: product.category,
      option1: product.option1,
      option2: product.option2,
      quantity: product.quantity,
      price: product.price,
      url: product.url,
      preorder: product.preorder,
      fancall: product.fancall,
      preorderStartAt: product.preorderStartAt,
      preorderEndAt: product.preorderEndAt,
      fancallName: product.fancallName,
      fancallBirthday: product.fancallBirthday,
      fancallNumber: product.fancallNumber,
      fancallEmail: product.fancallEmail,
      fancallCountry: product.fancallCountry,
      fancallKakao: product.fancallKakao,
      fancallSnsLine: product.fancallSnsLine,
      userId: user.id,
    }));

    setProductDetails(updatedProductDetails);

    try {
      const productResponse = await reqShopDetailpPost(updatedProductDetails);
      console.log("상품 상세 정보 제출됨", productResponse);

      const additionalResponse = await reqShopPost({
        ...additionalInfo,
        userId: user.id,
        preorder: products.some((product) => product.preorder),
        fancall: products.some((product) => product.fancall),
      });
      alert("Order has been reapplied successfully.");
      window.location.reload();
      console.log("추가 정보 제출됨", additionalResponse);

      console.log("모든 정보가 제출되었습니다.");
    } catch (error) {
      console.error("정보 제출 중 오류 발생", error);
    }
  };

  const categoryOptions = Object.entries(CODE_SHOPPING_ORDER_ITEMS).map(
    ([key, value]) => ({
      value: Number(key),
      label: value,
    })
  );

  const handleCategoryChange = (
    selectedOption: SingleValue<{ value: number; label: string }>,
    index: number
  ) => {
    const newProducts = [...products];
    newProducts[index].category = selectedOption ? selectedOption.value : 0;
    setProducts(newProducts);
  };

  const handleInputChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value, type, checked } = event.target;
    let newValue: any = type === "checkbox" ? checked : value;

    if (name === "price") {
      newValue = newValue.replace(/[^0-9]/g, "");
    }

    if (name === "quantity") {
      newValue = newValue.replace(/[^0-9]/g, "");
    }

    const updatedProducts = products.map((product, i) => {
      if (i === index) {
        if (name === "preorder" && newValue) {
          return { ...product, [name]: newValue, fancall: false };
        } else if (name === "fancall" && newValue) {
          return { ...product, [name]: newValue, preorder: false };
        } else {
          return { ...product, [name]: newValue };
        }
      }
      return product;
    });

    setProducts(updatedProducts);
  };

  const toggleSection = (index: number, section: "preorder" | "fancall") => {
    const newProducts = products.map((product, idx) => {
      if (idx === index) {
        const updatedSection = { [section]: !product[section] };

        if (updatedSection[section]) {
          if (section === "preorder") {
            return { ...product, ...updatedSection, fancall: false };
          } else {
            return { ...product, ...updatedSection, preorder: false };
          }
        } else {
          return { ...product, ...updatedSection };
        }
      }
      return product;
    });

    setProducts(newProducts);
  };

  const handleAdditionalInfoChange = (event: any) => {
    const { name, checked } = event.target;

    if (name === "totalCancellation") {
      setAdditionalInfo((prevInfo) => ({
        ...prevInfo,
        failed: checked,
      }));
    } else if (name === "partialCancellation") {
      setAdditionalInfo((prevInfo) => ({
        ...prevInfo,
        failed: !checked,
      }));
    } else {
      const { value, type } = event.target;
      let finalValue = value;

      if (type === "checkbox") {
        finalValue = checked;
      } else if (type === "radio") {
        finalValue = parseInt(value, 10);
      }

      setAdditionalInfo((prevInfo) => ({
        ...prevInfo,
        [name]: finalValue,
      }));
    }
  };

  const handleFancallCountrySelect = (countryId: number, index: number) => {
    const newProducts = [...products];
    newProducts[index].fancallCountry = countryId;
    setProducts(newProducts);
  };

  const [countrySearchTerm, setCountrySearchTerm] = useState("");

  const filteredCountries = Object.entries(CODE_COUNTRY_ITEMS).filter(
    ([id, name]) => {
      if (Array.isArray(name)) {
        return name.some((n) =>
          n.toLowerCase().includes(countrySearchTerm.toLowerCase())
        );
      } else {
        return name.toLowerCase().includes(countrySearchTerm.toLowerCase());
      }
    }
  );

  const handleNextStep = (nextStep: number) => {
    if (nextStep === 3) {
      const isAnyFieldMissing = products.some((product) => {
        const missing =
          product.name === "" ||
          product.category === 0 ||
          product.quantity === 0 ||
          product.price === 0 ||
          product.url === "";
        if (missing) {
          console.log("Missing field in product:", product);
        }
        return missing;
      });

      const isNotNaturalNumber = products.some((product) => {
        const notNatural =
          !Number.isInteger(Number(product.quantity)) ||
          product.quantity <= 0 ||
          !Number.isInteger(Number(product.price)) ||
          product.price <= 0;
        if (notNatural) {
          console.log("Non-natural number in product:", product);
        }
        return notNatural;
      });

      if (isAnyFieldMissing) {
        alert(
          "Please fill in all required fields for every product (Product name, Category, Quantity, and Price)."
        );
        return;
      }

      if (isNotNaturalNumber) {
        alert(
          "Quantity and Unit Price must be positive integers. Please correct the values."
        );
        return;
      }
    }

    console.log("Proceeding to next step:", nextStep);
    setStep(nextStep);
  };

  const deleteProduct = (index: number) => {
    setProducts((currentProducts) =>
      currentProducts.filter((_, i) => i !== index)
    );
  };

  const [openItems, setOpenItems] = useState<{ [key: number]: boolean }>({});

  const toggleItem = (index: number) => {
    setOpenItems((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const [products, setProducts] = useState([
    {
      id: Date.now().toString(),
      name: "",
      category: 0,
      option1: "",
      option2: "",
      quantity: 0,
      price: 0,
      url: "",
      preorder: false,
      fancall: false,
      preorderStartAt: "",
      preorderEndAt: "",
      fancallName: "",
      fancallBirthday: "",
      fancallNumber: "",
      fancallEmail: "",
      fancallCountry: 0,
      fancallKakao: "",
      fancallSnsLine: "",
    },
  ]);

  const addProduct = () => {
    setProducts([
      ...products,
      {
        id: `${Date.now()}-${Math.random().toString(36).substr(2, 9)}`,
        name: "",
        category: 0,
        option1: "",
        option2: "",
        quantity: 0,
        price: 0,
        url: "",
        preorder: false,
        fancall: false,
        preorderStartAt: "",
        preorderEndAt: "",
        fancallName: "",
        fancallBirthday: "",
        fancallNumber: "",
        fancallEmail: "",
        fancallCountry: 0,
        fancallKakao: "",
        fancallSnsLine: "",
      },
    ]);
  };

  useEffect(() => {
    if (orderDetails.length > 0) {
      setProducts(
        orderDetails.map((detail) => ({
          id: detail.order_id,
          name: detail.name,
          category: detail.category,
          option1: detail.option1,
          option2: detail.option2,
          quantity: detail.quantity,
          price: detail.price,
          url: detail.url,
          preorder: detail.preorder,
          preorderStartAt: detail.preorderStartAt || "",
          preorderEndAt: detail.preorderEndAt || "",
          fancall: detail.fancall,
          fancallName: detail.fancallName || "",
          fancallBirthday: detail.fancallBirthday || "",
          fancallNumber: detail.fancallNumber || "",
          fancallEmail: detail.fancallEmail || "",
          fancallCountry: detail.fancallCountry,
          fancallKakao: detail.fancallKakao || "",
          fancallSnsLine: detail.fancallSnsLine || "",
        }))
      );
    }
  }, [orderDetails]);

  const handleReapplyAllOrders = async () => {
    Swal.fire({
      title: "Are you sure to repurchase?",
      icon: "question",

      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",

      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          // 예를 들어 모든 주문에 대한 처리 로직
          for (const detail of orderDetails) {
            // await reqShopUpdate({
            //   order_id: detail.order_id,
            //   status: CODE_SHOPING_ORDER_STATUS.NEW,
            // });
          }
          setStep(2);
          refreshData();
          onHide();
        } catch (error) {
          console.error("Failed to reapply all orders:", error);
          alert("Failed to reapply all orders.");
        }
      }
    });
  };

  const handleCancelOrderClick = () => {
    onHide();
  };

  const MobileView = () => {
    return (
      <MobileWrapper>
        <MBody style={{ border: "none" }}>
          <div
            style={{
              fontSize: "1.3rem",
              fontWeight: "bold",
              borderBottom: "1px solid lightgray",
              padding: "0 0 1rem 0",
            }}
          >
            Product Info
          </div>

          <div>
            {orderDetails.map((detail, index) => (
              <div
                key={index}
                style={{
                  borderBottom: "1px solid lightgray",
                  padding: "1rem 0",
                }}
              >
                <div>
                  <MRow>
                    <div className="label">NO</div>
                    <div
                      className="data"
                      style={
                        [3, 4, 5, 9, 10].includes(detail.status)
                          ? { textDecoration: "line-through" }
                          : undefined
                      }
                    >
                      {detail.productId}
                    </div>
                  </MRow>

                  <MRow>
                    <div className="label">Product name</div>
                    <div
                      className="data"
                      style={
                        [3, 4, 5, 9, 10].includes(detail.status)
                          ? { textDecoration: "line-through" }
                          : undefined
                      }
                    >
                      {detail.name}
                    </div>
                  </MRow>

                  <MRow>
                    {" "}
                    <div className="label">Option 1</div>
                    <div
                      className="data"
                      style={
                        [3, 4, 5, 9, 10].includes(detail.status)
                          ? { textDecoration: "line-through" }
                          : undefined
                      }
                    >
                      {detail.option1}
                    </div>
                  </MRow>

                  <MRow>
                    <div className="label">Option 2</div>
                    <div
                      className="data"
                      style={
                        [3, 4, 5, 9, 10].includes(detail.status)
                          ? { textDecoration: "line-through" }
                          : undefined
                      }
                    >
                      {detail.option2}
                    </div>
                  </MRow>

                  <MRow>
                    <div className="label">Quantity</div>
                    <div
                      className="data"
                      style={
                        [3, 4, 5, 9, 10].includes(detail.status)
                          ? { textDecoration: "line-through" }
                          : undefined
                      }
                    >
                      {detail.quantity}
                    </div>
                  </MRow>

                  <MRow>
                    <div className="label">Unit Price</div>
                    <div
                      className="data price"
                      style={
                        [3, 4, 5, 9, 10].includes(detail.status)
                          ? { textDecoration: "line-through" }
                          : undefined
                      }
                    >
                      {detail.price}
                    </div>
                  </MRow>

                  <MRow>
                    <div className="label">Shipping Fee</div>
                    <div
                      className="data price"
                      style={
                        [3, 4, 5, 9, 10].includes(detail.status)
                          ? { textDecoration: "line-through" }
                          : undefined
                      }
                    >
                      {detail.ShippingFee}
                    </div>
                  </MRow>
                </div>

                {/* Pre Order */}
                <>
                  {detail.preorder &&
                    detail.preorderStartAt &&
                    detail.preorderEndAt && (
                      <MRow>
                        <div className="label">- Pre order</div>
                        <div
                          className="data"
                          style={
                            [3, 4, 5, 9, 10].includes(detail.status)
                              ? { textDecoration: "line-through" }
                              : undefined
                          }
                        >{`${formatDate(detail.preorderStartAt)} ~ ${formatDate(
                          detail.preorderEndAt
                        )}`}</div>
                      </MRow>
                    )}
                </>

                {/* Fan Call */}
                {detail.fancall && (
                  <div>
                    <Label>- Fancall</Label>
                    {detail.fancallName && (
                      <MRow>
                        <div className="label">Fancall Name</div>
                        <div
                          className="data"
                          style={
                            [3, 4, 5, 9, 10].includes(detail.status)
                              ? { textDecoration: "line-through" }
                              : undefined
                          }
                        >
                          {detail.fancallName}
                        </div>
                      </MRow>
                    )}
                    {detail.fancallBirthday && (
                      <MRow>
                        <div className="label">Fancall Birthday</div>
                        <div
                          className="data"
                          style={
                            [3, 4, 5, 9, 10].includes(detail.status)
                              ? { textDecoration: "line-through" }
                              : undefined
                          }
                        >
                          {formatDate(detail.fancallBirthday)}
                        </div>
                      </MRow>
                    )}
                    {detail.fancallNumber && (
                      <MRow>
                        <div className="label">Fancall Number</div>
                        <div
                          className="data"
                          style={
                            [3, 4, 5, 9, 10].includes(detail.status)
                              ? { textDecoration: "line-through" }
                              : undefined
                          }
                        >
                          {detail.fancallNumber}
                        </div>
                      </MRow>
                    )}
                    {detail.fancallEmail && (
                      <MRow>
                        <div className="label">Fancall Email</div>
                        <div
                          className="data"
                          style={
                            [3, 4, 5, 9, 10].includes(detail.status)
                              ? { textDecoration: "line-through" }
                              : undefined
                          }
                        >
                          {detail.fancallEmail}
                        </div>
                      </MRow>
                    )}
                    {detail.fancallCountry && (
                      <MRow>
                        <div className="label">Fancall Country</div>
                        <div
                          className="data"
                          style={
                            [3, 4, 5, 9, 10].includes(detail.status)
                              ? { textDecoration: "line-through" }
                              : undefined
                          }
                        >
                          {CODE_COUNTRY_ITEMS[detail.fancallCountry]}
                        </div>
                      </MRow>
                    )}
                    {detail.fancallKakao && (
                      <MRow>
                        <div className="label">Fancall Kakao</div>
                        <div
                          className="data"
                          style={
                            [3, 4, 5, 9, 10].includes(detail.status)
                              ? { textDecoration: "line-through" }
                              : undefined
                          }
                        >
                          {detail.fancallKakao}
                        </div>
                      </MRow>
                    )}
                    {detail.fancallSnsLine && (
                      <MRow>
                        <div className="label">Fancall SNS Line</div>
                        <div
                          className="data"
                          style={
                            [3, 4, 5, 9, 10].includes(detail.status)
                              ? { textDecoration: "line-through" }
                              : undefined
                          }
                        >
                          {detail.fancallSnsLine}
                        </div>
                      </MRow>
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>

          <div
            style={{
              fontSize: "1.3rem",
              fontWeight: "bold",
              borderBottom: "1px solid lightgray",
              padding: "1rem 0 1rem 0",
            }}
          >
            Payment Info
          </div>

          <div>
            {shopData?.PaymentMethod === 1 && (
              <div style={{ paddingTop: "1rem" }}>
                <MRow>
                  <div className="label">Product Total</div>
                  <div className="data price">
                    {`₩ ${productTotal.toLocaleString("ko-KR")}`}
                  </div>
                </MRow>
                <MRow>
                  <div className="label">Domestic Shipping Cost</div>
                  <div className="data price">
                    {`₩ ${domesticShippingFee.toLocaleString("ko-KR")}`}
                  </div>
                </MRow>
                <MRow>
                  <div className="label">Service Cost</div>
                  <div className="data price">
                    {`₩ ${serviceFeeValue.toLocaleString("ko-KR")}`}
                  </div>
                </MRow>
                <MRow>
                  <div className="label">Paypal Cost</div>
                  <div className="data price">{`₩ -`}</div>
                </MRow>
                <MRow>
                  <div className="label">Use Point</div>
                  <div className="data price">
                    {`₩ ${(shopData?.usePointKrw || 0).toLocaleString(
                      "ko-KR"
                    )}`}
                  </div>
                </MRow>
                <MRow>
                  <div className="label">Total</div>
                  <div className="data price">
                    {`₩ ${total.toLocaleString("ko-KR")}`}
                  </div>
                </MRow>
              </div>
            )}
            {shopData?.PaymentMethod === 2 || shopData?.PaymentMethod === 3 ? (
              <>
                <MRow>
                  <div className="label">Product total</div>
                  <div className="data price">
                    {`$ ${convertToUSD(productTotal)}`}
                  </div>
                </MRow>
                <MRow>
                  <div className="label">Domestic Shipping Cost</div>
                  <div className="data price">
                    {`$ ${convertToUSD(domesticShippingFee)}`}
                  </div>
                </MRow>
                <MRow>
                  <div className="label">Service Cost</div>
                  <div className="data price">
                    {`$ ${convertToUSD(serviceFeeValue)}`}
                  </div>
                </MRow>
                <MRow>
                  <div className="label">Paypal Cost</div>
                  <div className="data price">
                    {`$ ${convertToUSD(paypalFee)}`}
                  </div>
                </MRow>
                <MRow>
                  <div className="label">Use Point</div>
                  <div className="data price">
                    {`$ ${convertToUSD(shopData?.usePointUse || 0)}`}
                  </div>
                </MRow>
                <MRow>
                  <div className="label">Total</div>
                  <div className="data price">
                    {`$ ${convertToUSD(Utotal)}`}
                  </div>
                </MRow>
              </>
            ) : null}
          </div>
        </MBody>
      </MobileWrapper>
    );
  };

  return (
    <Fragment>
      <Modal show={show} onHide={onHide} size="xl" centered>
        <Modal.Header
          closeButton
          style={{ backgroundColor: "var(--color-lightgray)" }}
        >
          <div style={{ fontSize: "1.3rem" }}>{orderId}</div>
        </Modal.Header>

        {isMobile ? (
          <MobileView />
        ) : (
          <Modal.Body>
            <div className="table-responsive mt-3">
              <Table className="table-custom text-center">
                <thead className="table-light">
                  <tr>
                    <th>NO</th>
                    <th>Product name</th>
                    <th>Option 1</th>
                    <th>Option 2</th>
                    <th>Quantity</th>
                    <th>Unit Price</th>
                    <th>Shipping Fee</th>
                  </tr>
                </thead>
                <tbody>
                  {orderDetails.map((detail, index) => (
                    <React.Fragment key={index}>
                      <tr>
                        <th
                          style={
                            [3, 4, 5, 9, 10].includes(detail.status)
                              ? { textDecoration: "line-through" }
                              : undefined
                          }
                        >
                          {detail.productId}
                        </th>
                        <td
                          style={
                            [3, 4, 5, 9, 10].includes(detail.status)
                              ? { textDecoration: "line-through" }
                              : undefined
                          }
                        >
                          {detail.name}
                        </td>
                        <td
                          style={
                            [3, 4, 5, 9, 10].includes(detail.status)
                              ? { textDecoration: "line-through" }
                              : undefined
                          }
                        >
                          {detail.option1}
                        </td>
                        <td
                          style={
                            [3, 4, 5, 9, 10].includes(detail.status)
                              ? { textDecoration: "line-through" }
                              : undefined
                          }
                        >
                          {detail.option2}
                        </td>
                        <td
                          style={
                            [3, 4, 5, 9, 10].includes(detail.status)
                              ? { textDecoration: "line-through" }
                              : undefined
                          }
                        >
                          {detail.quantity}
                        </td>
                        <td
                          style={
                            [3, 4, 5, 9, 10].includes(detail.status)
                              ? { textDecoration: "line-through" }
                              : undefined
                          }
                        >
                          {detail.price}
                        </td>
                        <td
                          style={
                            [3, 4, 5, 9, 10].includes(detail.status)
                              ? { textDecoration: "line-through" }
                              : undefined
                          }
                        >
                          {detail.ShippingFee}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={7} style={{ textAlign: "center" }}>
                          <Table>
                            {detail.preorder &&
                              detail.preorderStartAt &&
                              detail.preorderEndAt && (
                                <tr>
                                  <th>Pre order</th>
                                  <td
                                    style={{
                                      textAlign: "center",
                                      textDecoration: [3, 4, 5, 9, 10].includes(
                                        detail.status
                                      )
                                        ? "line-through"
                                        : undefined,
                                    }}
                                  >
                                    {`${formatDate(
                                      detail.preorderStartAt
                                    )} ~ ${formatDate(detail.preorderEndAt)}`}
                                  </td>
                                </tr>
                              )}
                          </Table>
                        </td>
                      </tr>

                      {detail.fancall && (
                        <tr>
                          <td colSpan={7}>
                            <Table>
                              <tbody>
                                {detail.fancallName && (
                                  <tr>
                                    <th>Fancall Name</th>
                                    <td
                                      style={
                                        [3, 4, 5, 9, 10].includes(detail.status)
                                          ? { textDecoration: "line-through" }
                                          : undefined
                                      }
                                    >
                                      {detail.fancallName}
                                    </td>
                                  </tr>
                                )}
                                {detail.fancallBirthday && (
                                  <tr>
                                    <th>Fancall Birthday</th>
                                    <td
                                      style={
                                        [3, 4, 5, 9, 10].includes(detail.status)
                                          ? { textDecoration: "line-through" }
                                          : undefined
                                      }
                                    >
                                      {formatDate(detail.fancallBirthday)}
                                    </td>
                                  </tr>
                                )}
                                {detail.fancallNumber && (
                                  <tr>
                                    <th>Fancall Number</th>
                                    <td
                                      style={
                                        [3, 4, 5, 9, 10].includes(detail.status)
                                          ? { textDecoration: "line-through" }
                                          : undefined
                                      }
                                    >
                                      {detail.fancallNumber}
                                    </td>
                                  </tr>
                                )}
                                {detail.fancallEmail && (
                                  <tr>
                                    <th>Fancall Email</th>
                                    <td
                                      style={
                                        [3, 4, 5, 9, 10].includes(detail.status)
                                          ? { textDecoration: "line-through" }
                                          : undefined
                                      }
                                    >
                                      {detail.fancallEmail}
                                    </td>
                                  </tr>
                                )}
                                {detail.fancallCountry && (
                                  <tr>
                                    <th>Fancall Country</th>
                                    <td
                                      style={
                                        [3, 4, 5, 9, 10].includes(detail.status)
                                          ? { textDecoration: "line-through" }
                                          : undefined
                                      }
                                    >
                                      {
                                        CODE_COUNTRY_ITEMS[
                                        detail.fancallCountry
                                        ]
                                      }
                                    </td>
                                  </tr>
                                )}
                                {detail.fancallKakao && (
                                  <tr>
                                    <th>Fancall Kakao</th>
                                    <td
                                      style={
                                        [3, 4, 5, 9, 10].includes(detail.status)
                                          ? { textDecoration: "line-through" }
                                          : undefined
                                      }
                                    >
                                      {detail.fancallKakao}
                                    </td>
                                  </tr>
                                )}
                                {detail.fancallSnsLine && (
                                  <tr>
                                    <th>Fancall SNS Line</th>
                                    <td
                                      style={
                                        [3, 4, 5, 9, 10].includes(detail.status)
                                          ? { textDecoration: "line-through" }
                                          : undefined
                                      }
                                    >
                                      {detail.fancallSnsLine}
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </Table>
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  ))}
                </tbody>
              </Table>
            </div>
            <div className="container-fluid mb-3">
              {shopData?.PaymentMethod === 1 && (
                <div className="table-responsive mt-3">
                  <Table className="table-custom text-center">
                    <thead className="table-light">
                      <tr>
                        <th>Title</th>
                        <th>KRW</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th>Product total</th>
                        <td>{productTotal}</td>
                      </tr>
                      <tr>
                        <th>Domestic Shipping Cost</th>
                        <td>{domesticShippingFee}</td>
                      </tr>
                      <tr>
                        <th>Service Cost</th>
                        <td>{serviceFeeValue}</td>
                      </tr>
                      <tr>
                        <th>Paypal Cost</th>
                        <td>-</td>
                      </tr>
                      <tr>
                        <th>Use Point</th>
                        <td>{shopData?.usePointKrw || 0}</td>
                      </tr>
                      <tr>
                        <th>Total</th>
                        <td>{total}</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              )}
              {(shopData?.PaymentMethod === 2 || shopData?.PaymentMethod === 3) && (
                <div className="table-responsive mt-3">
                  <Table className="table-custom text-center">
                    <thead className="table-light">
                      <tr>
                        <th>Title</th>
                        <th>USD</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th>Product total</th>
                        <td>{`$${convertToUSD(productTotal)}`}</td>
                      </tr>
                      <tr>
                        <th>Domestic Shipping Cost</th>
                        <td>{`$${convertToUSD(domesticShippingFee)}`}</td>
                      </tr>
                      <tr>
                        <th>Service Cost</th>
                        <td>{`$${convertToUSD(serviceFeeValue)}`}</td>
                      </tr>
                      <tr>
                        <th>Paypal Cost</th>
                        <td>{`$${convertToUSD(paypalFee)}`}</td>
                      </tr>
                      <tr>
                        <th>Use Point</th>
                        <td>{`${convertToUSD(shopData?.usePointUse || 0)}`}</td>
                      </tr>
                      <tr>
                        <th>Total</th>
                        <td>{`$${convertToUSD(Utotal)}`}</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              )}
            </div>
          </Modal.Body>
        )}

        {isMobile ? (
          <MFooter
            style={{ padding: "1rem", borderTop: "1px solid lightgray" }}
          >
            <MButton
              onClick={handleCancelOrderClick}
              style={{
                backgroundColor: "var(--color-gray)",
                color: "var(--color-white)",
              }}
            >
              Close
            </MButton>
            {/* <Button variant="success" onClick={handleReapplyAllOrders}>
            Repurchase
          </Button> */}
          </MFooter>
        ) : (
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCancelOrderClick}>
              Close
            </Button>
            {/* <Button variant="success" onClick={handleReapplyAllOrders}>
            Repurchase
          </Button> */}
          </Modal.Footer>
        )}
      </Modal>

      <Modal show={step === 2} onHide={() => setStep(0)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Step 2 Enter product information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {products.map((product, index) => (
            <form className="row" key={product.id}>
              <div className="col-12 mb-1">
                <label className="form-label">category *</label>
                <Select
                  value={categoryOptions.find(
                    (option) => option.value === product.category
                  )}
                  onChange={(selectedOption) =>
                    handleCategoryChange(
                      selectedOption as SingleValue<{
                        value: number;
                        label: string;
                      }>,
                      index
                    )
                  }
                  options={categoryOptions}
                  placeholder="카테고리 선택"
                />
              </div>
              <div className="col-12 mb-1">
                <label className="form-label">Product name *</label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  value={product.name}
                  onChange={(e) => handleInputChange(index, e)}
                />
              </div>
              <div className="col-6">
                <label className="form-label">Option1</label>
                <input
                  type="text"
                  className="form-control"
                  name="option1"
                  value={product.option1}
                  onChange={(e) => handleInputChange(index, e)}
                />
              </div>
              <div className="col-6">
                <label className="form-label">Option2</label>
                <input
                  type="text"
                  className="form-control"
                  name="option2"
                  value={product.option2}
                  onChange={(e) => handleInputChange(index, e)}
                />
              </div>
              <div className="col-6">
                <label className="form-label">Quantity *</label>
                <input
                  className="form-control"
                  name="quantity"
                  value={product.quantity}
                  onChange={(e) => handleInputChange(index, e)}
                  onFocus={(e) => (e.target.value = "")}
                />
              </div>
              <div className="col-6">
                <label className="form-label">Unit Price(KRW) *</label>
                <input
                  className="form-control"
                  name="price"
                  value={product.price}
                  onChange={(e) => handleInputChange(index, e)}
                  onFocus={(e) => {
                    if (e.target.value === "0") e.target.value = "";
                  }}
                />
              </div>
              <div className="col-12">
                <label className="form-label">Site url *</label>
                <input
                  type="text"
                  className="form-control"
                  name="url"
                  value={product.url}
                  onChange={(e) => handleInputChange(index, e)}
                />
              </div>
              <div className="col-12">
                <div
                  className="accordion accordion-flush"
                  id="accordionFlushExample"
                >
                  <div className="accordion-item">
                    <h4 className="accordion-header">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id={`preorder-${product.id}`}
                          name="preorder"
                          checked={product.preorder}
                          onChange={(e) => handleInputChange(index, e)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`preorder-${product.id}`}
                        >
                          Pre Order
                        </label>
                      </div>
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        onClick={() => toggleSection(index, "preorder")}
                        aria-expanded={product.preorder}
                        aria-controls={`flush-collapseOne-${index}`}
                      ></button>
                    </h4>
                    <Collapse in={product.preorder}>
                      <div
                        id={`flush-collapseOne-${index}`}
                        className="accordion-body"
                      >
                        <div className="accordion-body">
                          <div>
                            <h5>Start date ~ End date</h5>
                          </div>
                          <div className="input-group mb-3">
                            <input
                              type="date"
                              className="form-control"
                              name="preorderStartAt"
                              value={product.preorderStartAt || ""}
                              onChange={(e) => handleInputChange(index, e)}
                              placeholder="Start date"
                              aria-label="Start date"
                            />
                            <span className="input-group-text">~</span>
                            <input
                              type="date"
                              className="form-control"
                              name="preorderEndAt"
                              value={product.preorderEndAt || ""}
                              onChange={(e) => handleInputChange(index, e)}
                              placeholder="End date"
                              aria-label="End date"
                            />
                          </div>
                        </div>
                      </div>
                    </Collapse>
                  </div>
                  <div className="accordion-item">
                    <h4 className="accordion-header">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id={`fancall-${product.id}`}
                          name="fancall"
                          checked={product.fancall}
                          onChange={(e) => handleInputChange(index, e)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`fancall-${product.id}`}
                        >
                          Fan Call
                        </label>
                      </div>
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        onClick={() => toggleSection(index, "fancall")}
                        aria-expanded={product.fancall}
                        aria-controls={`flush-collapseTwo-${index}`}
                      ></button>
                    </h4>
                    <Collapse in={product.fancall}>
                      <div
                        id={`flush-collapseTwo-${index}`}
                        className="accordion-body"
                      >
                        <div className="accordion-body">
                          {/* Name Input */}
                          <div className="input-group mb-3">
                            <span className="input-group-text">Name</span>
                            <input
                              type="text"
                              aria-label="First name"
                              className="form-control"
                              name="fancallName"
                              value={product.fancallName || ""}
                              onChange={(e) => handleInputChange(index, e)}
                            />
                          </div>
                          {/* Birthday Input */}
                          <div className="input-group mb-3">
                            <span className="input-group-text">Birthday</span>
                            <input
                              type="date"
                              className="form-control"
                              name="fancallBirthday"
                              value={product.fancallBirthday || ""}
                              onChange={(e) => handleInputChange(index, e)}
                            />
                          </div>

                          {/* Phone Number Input */}
                          <div className="input-group mb-3">
                            <span className="input-group-text">Phone</span>
                            <input
                              type="text"
                              className="form-control"
                              name="fancallNumber"
                              value={product.fancallNumber || ""}
                              onChange={(e) => handleInputChange(index, e)}
                              placeholder="Phone Number"
                              aria-label="Phone Number"
                            />
                          </div>

                          {/* Email Input */}
                          <div className="input-group mb-3">
                            <span className="input-group-text">E-mail</span>
                            <input
                              type="email"
                              className="form-control"
                              name="fancallEmail"
                              value={product.fancallEmail || ""}
                              onChange={(e) => handleInputChange(index, e)}
                              placeholder="E-mail"
                              aria-label="E-mail"
                            />
                          </div>

                          {/* Country Input */}
                          <div className="input-group mb-3">
                            <label
                              className="input-group-text"
                              htmlFor="dropdown-basic-fancallCountry"
                            >
                              Country
                            </label>
                            <Dropdown>
                              <Dropdown.Toggle
                                variant="success"
                                id="dropdown-basic-fancallCountry"
                              >
                                {products[index].fancallCountry
                                  ? CODE_COUNTRY_ITEMS[
                                  products[index].fancallCountry
                                  ]
                                  : "Select Country"}
                              </Dropdown.Toggle>

                              <Dropdown.Menu
                                style={{
                                  maxHeight: "300px",
                                  overflowY: "auto",
                                }}
                              >
                                <FormControl
                                  autoFocus
                                  className="mx-2 my-1 w-auto"
                                  placeholder="Search country..."
                                  onChange={(e) =>
                                    setCountrySearchTerm(e.target.value)
                                  }
                                  value={countrySearchTerm}
                                />
                                {filteredCountries.map(([id, name]) => (
                                  <Dropdown.Item
                                    key={id}
                                    onClick={() =>
                                      handleFancallCountrySelect(
                                        Number(id),
                                        index
                                      )
                                    }
                                  >
                                    {name}
                                  </Dropdown.Item>
                                ))}
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                          {/* KakaoTalk ID Input */}
                          <div className="input-group mb-3">
                            <span className="input-group-text">
                              KakaoTalk ID
                            </span>
                            <input
                              type="text"
                              className="form-control"
                              name="fancallKakao"
                              value={product.fancallKakao || ""}
                              onChange={(e) => handleInputChange(index, e)}
                              placeholder="Kakao"
                              aria-label="Kakao"
                            />
                          </div>

                          {/* Line ID Input */}
                          <div className="input-group mb-3">
                            <span className="input-group-text">Line ID</span>
                            <input
                              type="text"
                              className="form-control"
                              name="fancallSnsLine"
                              value={product.fancallSnsLine || ""}
                              onChange={(e) => handleInputChange(index, e)}
                              placeholder="SnsLine"
                              aria-label="SnsLine"
                            />
                          </div>
                        </div>
                      </div>
                    </Collapse>
                  </div>
                </div>
                <div className="col-12">
                  <button
                    type="button"
                    style={{ width: "100%", marginBottom: "1rem" }}
                    className="btn btn-danger"
                    onClick={() => deleteProduct(index)}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </form>
          ))}
          <Button
            style={{ width: "100%", marginBottom: "1rem" }}
            onClick={addProduct}
          >
            Add Product
          </Button>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={() => handleNextStep(1)}>
            BACK
          </Button>
          <Button variant="outline-success" onClick={() => handleNextStep(3)}>
            NEXT
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={step === 3} onHide={() => setStep(0)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Step 3: Preview Input Information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="accordion" id="accordionPreview">
            {products.map((product, index) => (
              <div className="accordion-item" key={product.id}>
                <h2 className="accordion-header" id={`heading${index}`}>
                  <Button
                    variant="secondary"
                    onClick={() => toggleItem(index)}
                    aria-expanded={openItems[index] ?? true}
                    aria-controls={`collapse${index}`}
                  >
                    No. {index + 1}
                  </Button>
                </h2>
                <Collapse in={openItems[index] ?? true}>
                  <div
                    id={`collapse${index}`}
                    className="accordion-collapse collapse"
                  >
                    <div className="accordion-body">
                      <ResponsiveTable className="table">
                        <tbody>
                          <tr>
                            <th>Category</th>
                            <td>
                              {CODE_SHOPPING_ORDER_ITEMS[product.category]}
                            </td>
                          </tr>
                          <tr>
                            <th>Product name</th>
                            <td>{product.name}</td>
                          </tr>
                          <tr>
                            <th>Option 1</th>
                            <td>{product.option1 || "-"}</td>
                          </tr>
                          <tr>
                            <th>Option 2</th>
                            <td>{product.option2 || "-"}</td>
                          </tr>
                          <tr>
                            <th>Quantity</th>
                            <td>{product.quantity}</td>
                          </tr>
                          <tr>
                            <th>Unit price</th>
                            <td>{product.price}</td>
                          </tr>
                          <tr>
                            <th>Hyperlink</th>
                            <td>
                              <a
                                href={product.url}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {product.url}
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <th>Pre Order</th>
                            <td>
                              {product.preorder
                                ? `${product.preorderStartAt} ~ ${product.preorderEndAt}`
                                : "No"}
                            </td>
                          </tr>
                          {product.fancall && (
                            <tr>
                              <th>Fan Call</th>
                              <td>
                                <ul>
                                  <li>Name: {product.fancallName}</li>
                                  <li>Birth: {product.fancallBirthday}</li>
                                  <li>Contact: {product.fancallNumber}</li>
                                  <li>Email: {product.fancallEmail}</li>
                                  <li>
                                    Country:{" "}
                                    {CODE_COUNTRY_ITEMS[product.fancallCountry]}
                                  </li>
                                  <li>Kakao: {product.fancallKakao}</li>
                                  <li>Line: {product.fancallSnsLine}</li>
                                </ul>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </ResponsiveTable>
                    </div>
                  </div>
                </Collapse>
              </div>
            ))}
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                name="totalCancellation"
                onChange={handleAdditionalInfoChange}
                checked={products.length === 1 || additionalInfo.failed}
              />{" "}
              Total cancellation
            </div>
            {products.length > 1 && (
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  name="partialCancellation"
                  onChange={handleAdditionalInfoChange}
                  checked={!additionalInfo.failed}
                />{" "}
                Partial cancellation (purchase available items)
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={() => setStep(2)}>
            Back
          </Button>
          <Button variant="outline-success" onClick={submitAllInfo}>
            Submit
          </Button>
          {/* <Button variant="outline-success" onClick={() => {
                            setAdditionalInfo(prev => ({ ...prev, PaymentMethod: 1 }));
                            setStep(4);
                        }}>KRW 결제</Button>
                        <Button variant="outline-success" disabled onClick={() => {
                            setAdditionalInfo(prev => ({ ...prev, PaymentMethod: 2 }));
                            setStep(5);
                        }}>USD 결제</Button> */}
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};
export default CShopOrderPreorderModal;

const Title = styled.h3`
  border-bottom: 1px solid lightgray;
  padding-bottom: 1rem;
  margin-top: 1rem;
`;
const FeeBoxRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  & > div {
    flex: 1 0 0;
    text-align: center;
    align-items: center;
    border: 1px solid lightgray;
    padding: 0.4rem 0;
  }
  margin: 0.6rem 0;
`;
const TotalFeeBoxRow = styled(FeeBoxRow)`
  background-color: #474747;
  color: white;
`;

const MobileWrapper = styled.div`
  margin: 0;
  padding: 1rem;
`;

const MBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.6rem 0;
  margin: 0.4rem 0;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;
const Col = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  font-weight: bold;
  margin: 0.4rem 0 0.4rem 0;
`;
const P = styled.p`
  margin: 0 0 0.4rem 0;
`;

const ResponsiveTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  table-layout: auto; // Allows the table to adjust width dynamically

  th,
  td {
    padding: 8px;
    text-align: center;
    border: 1px solid #ddd;
  }

  // Using media queries to adjust font size and padding for smaller screens
  @media (max-width: 768px) {
    font-size: 0.8em; // Smaller text on smaller screens
    th,
    td {
      padding: 6px;
    }
  }

  @media (max-width: 480px) {
    font-size: 0.7em; // Even smaller text for mobile devices
    th,
    td {
      padding: 4px;
    }
  }
`;
