import { useState, useEffect } from "react";
import styled from "styled-components";
import { CODE_COUNTRY } from "../../common/constants";
import { reqEveryRate } from "../../requests/rate";
import { isMobile } from "react-device-detect";

export default function Calculator() {
  const [countryCode, setCountryCode] = useState("");
  const [destinationPostalCode, setDestinationPostalCode] = useState("");
  const [weight, setWeight] = useState(0);
  const [dimensions, setDimensions] = useState({
    width: 0,
    height: 0,
    length: 0,
  });
  const [everyRate, setEveryRate] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);

  const getEveryRate = async () => {
    setIsLoading(true);
    const res = await reqEveryRate({
      countryCode: countryCode,
      postalCode: destinationPostalCode || "",
      totalWeight: Number(weight) * 1000,
      boxWidth: dimensions.width,
      boxHeight: dimensions.height,
      boxLength: dimensions.length,
    });
    setIsLoading(false);
    setEveryRate(res.data);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    getEveryRate();
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (name === "width" || name === "height" || name === "length") {
      setDimensions((prevDimensions) => ({
        ...prevDimensions,
        [name]: parseFloat(value) || 0,
      }));
    } else if (name === "weight") {
      setWeight(parseFloat(value) || 0);
    }
  };

  return (
    <>
      <RateCalculator>
        {isLoading ? (
          <LoadingSection>
            <img src="/resources/img/loading.gif" alt="로딩중" />
          </LoadingSection>
        ) : null}
        <RateForm onSubmit={handleSubmit}>
          <div className="form-group">
            <Label htmlFor="countryCode">Recipient Country Code:</Label>
            <select
              value={countryCode}
              onChange={(e) => setCountryCode(e.target.value)}
              className="form-control"
            >
              <option value="">Select a country</option>
              {CODE_COUNTRY.map((country) => (
                <option key={country.code} value={country.code}>
                  {country.name}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <Label htmlFor="destinationPostalCode">
              Destination Postal Code:
            </Label>
            <FormControl
              type="text"
              id="destinationPostalCode"
              value={destinationPostalCode}
              onChange={(e) => setDestinationPostalCode(e.target.value)}
              placeholder="Destination Postal Code"
              required
            />
          </div>
          <div className="form-group">
            <Label htmlFor="weight">Package Weight (KG):</Label>
            <FormControl
              type="number"
              id="weight"
              name="weight"
              value={weight.toString()}
              onChange={(e) => setWeight(parseFloat(e.target.value) || 0)}
              placeholder="Package Weight (KG)"
            />
          </div>
          {isMobile ? (
            <>
              <div className="form-group dimensions-group">
                <Label>Dimensions (CM):</Label>
                <div className="dimensions-inline">
                  <Row>
                    <MLabel className="dimension-label">W:</MLabel>
                    <FormControl
                      type="number"
                      name="width"
                      value={dimensions.width.toString()}
                      onChange={handleChange}
                      placeholder="Width"
                      className="dimension-input"
                    />
                  </Row>

                  <Row>
                    <MLabel className="dimension-label">H:</MLabel>
                    <FormControl
                      type="number"
                      name="height"
                      value={dimensions.height.toString()}
                      onChange={handleChange}
                      placeholder="Height"
                      className="dimension-input"
                    />
                  </Row>

                  <Row>
                    <MLabel className="dimension-label">L:</MLabel>
                    <FormControl
                      type="number"
                      name="length"
                      value={dimensions.length.toString()}
                      onChange={handleChange}
                      placeholder="Length"
                      className="dimension-input"
                    />
                  </Row>
                </div>
              </div>
            </>
          ) : (
            <div className="form-group dimensions-group">
              <Label>Dimensions (CM):</Label>
              <div className="dimensions-inline">
                <MLabel className="dimension-label">W:</MLabel>
                <FormControl
                  type="number"
                  name="width"
                  value={dimensions.width.toString()}
                  onChange={handleChange}
                  placeholder="Width"
                  className="dimension-input"
                  style={{ marginRight: "0.6rem" }}
                />
                <MLabel className="dimension-label">H:</MLabel>
                <FormControl
                  type="number"
                  name="height"
                  value={dimensions.height.toString()}
                  onChange={handleChange}
                  placeholder="Height"
                  className="dimension-input"
                  style={{ marginRight: "0.6rem" }}
                />
                <MLabel className="dimension-label">L:</MLabel>
                <FormControl
                  type="number"
                  name="length"
                  value={dimensions.length.toString()}
                  onChange={handleChange}
                  placeholder="Length"
                  className="dimension-input"
                  style={{ marginRight: "0.6rem" }}
                />
              </div>
            </div>
          )}

          <SubmitButton
            type="submit"
            style={{ backgroundColor: "var(--color-main-blue)" }}
          >
            Calculate
          </SubmitButton>
        </RateForm>
        {everyRate && (
          <>
            {isMobile ? (
              <>
                <MResponsePre>
                  FEDEX Total Surcharges (KRW): {everyRate.fedex?.krw || "-"}
                </MResponsePre>
                <MResponsePre>
                  FEDEX Total Surcharges (USD): {everyRate.fedex?.usd || "-"}
                </MResponsePre>
                <MResponsePre>
                  UPS Total Surcharges (KRW): {everyRate.ups?.krw || "-"}
                </MResponsePre>
                <MResponsePre>
                  UPS Total Surcharges (USD): {everyRate.ups?.usd || "-"}
                </MResponsePre>
                <MResponsePre>
                  EMS Total Surcharges (KRW): {everyRate.ems?.krw || "-"}
                </MResponsePre>
                <MResponsePre>
                  EMS Total Surcharges (USD): {everyRate.ems?.usd || "-"}
                </MResponsePre>
                <MResponsePre>
                  K-PACKET Total Surcharges (KRW):{" "}
                  {everyRate.kpacket?.krw || "-"}
                </MResponsePre>
                <MResponsePre>
                  K-PACKET Total Surcharges (USD):{" "}
                  {everyRate.kpacket?.usd || "-"}
                </MResponsePre>
              </>
            ) : (
              <>
                <RateReponseRow>
                  <ResponsePre>
                    FEDEX Total Surcharges (KRW): {everyRate.fedex?.krw || "-"}
                  </ResponsePre>
                  <ResponsePre>
                    FEDEX Total Surcharges (USD): {everyRate.fedex?.usd || "-"}
                  </ResponsePre>
                </RateReponseRow>
                <RateReponseRow>
                  <ResponsePre>
                    UPS Total Surcharges (KRW): {everyRate.ups?.krw || "-"}
                  </ResponsePre>
                  <ResponsePre>
                    UPS Total Surcharges (USD): {everyRate.ups?.usd || "-"}
                  </ResponsePre>
                </RateReponseRow>
                <RateReponseRow>
                  <ResponsePre>
                    EMS Total Surcharges (KRW): {everyRate.ems?.krw || "-"}
                  </ResponsePre>
                  <ResponsePre>
                    EMS Total Surcharges (USD): {everyRate.ems?.usd || "-"}
                  </ResponsePre>
                </RateReponseRow>
                <RateReponseRow>
                  <ResponsePre>
                    K-PACKET Total Surcharges (KRW):{" "}
                    {everyRate.kpacket?.krw || "-"}
                  </ResponsePre>
                  <ResponsePre>
                    K-PACKET Total Surcharges (USD):{" "}
                    {everyRate.kpacket?.usd || "-"}
                  </ResponsePre>
                </RateReponseRow>
              </>
            )}
          </>
        )}
        <RatingInfoSection>
          {/* <p>⏺ K-PACKET only handles products under 2kg.</p> */}
          <h4>About applied weight</h4>
          <p>
            When making an estimate after packaging the product, use the
            `applied weight`.
          </p>
          <p>
            The `applied weight` is applied to a higher number of the `actual
            weight` and `volume weight`.
          </p>
          <p>
            The `volume weight` is calculated by calculating the (long side *
            short side * height) / 5000 of the box.
          </p>
          <p> - FEDEX, UPS measure / 5000 </p>
          <p> - EMS, K-PACKET measure / 6000</p>
          <p>For Example, The `actual weight` of the box is 12kg.</p>
          <p>
            If the box size is 60*50*30, the `volume weight` is calculated as
            18kg (fedex, ups, dhl), 15kg (ems, k-packets).
          </p>
          <p> Therefore, the `applied weight` is calculated as 18kg or 15kg.</p>
        </RatingInfoSection>
      </RateCalculator>
    </>
  );
}

const FeeBoxRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  & > div {
    flex: 1 0 0;
    text-align: center;
    align-items: center;
    border: 1px solid lightgray;
    padding: 0.4rem 0;
  }
  margin: 0.6rem 0;
`;
const TotalFeeBoxRow = styled(FeeBoxRow)`
  background-color: #474747;
  color: white;
`;

const MobileWrapper = styled.div`
  margin: 0;
`;

const MBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  text-align: center;
  align-items: center;
`;
const Col = styled.div`
  display: flex;
  flex-direction: column;
`;

const MLabel = styled.label`
  font-weight: bold;
  margin: 0.4rem 0.4rem 0.4rem 0;
`;
const P = styled.p`
  margin: 0.4rem 0 0.4rem 0;
`;

const RatingInfoSection = styled.div`
  & > h4 {
    margin: 1.5rem 0 1rem 0;
  }
  & > p {
    margin: 0 0 0.4rem 0;
  }
`;

const LoadingSection = styled.div`
  position: absolute;
  z-index: 1000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  & > img {
    width: 100%;
  }
`;

const RateCalculator = styled.div`
  max-width: 900px;
  margin: 3rem auto;
  padding: 20px;
  background-color: #f4f4f4;
  border-radius: 8px;
`;

const RateForm = styled.form`
  .form-group {
    margin-bottom: 15px;
  }
`;

const Label = styled.label`
  margin-right: 15px;
`;

const FormControl = styled.input`
  margin: 5px;
  padding: 10px;
  border: 2px solid #ddd;
  border-radius: 4px;
`;

const SubmitButton = styled.button`
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: block;
  width: 100%;
  margin-top: 10px;

  &:hover {
    background-color: #45a049;
  }
`;

const RateReponseRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ResponsePre = styled.pre`
  background-color: #333;
  color: #fff;
  padding: 10px;
  border-radius: 4px;
  margin-top: 20px;
  width: 48%;
`;

const MResponsePre = styled(ResponsePre)`
  width: 100%;
`;

const RatingInfo = styled.div`
  margin: 20px 0;

  & > ul {
    margin-top: 10px;
    & > li {
      margin-top: 10px;
    }
  }
`;
