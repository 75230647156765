import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Pagination } from "react-bootstrap";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { CODE_SHIPPING_PRODUCT_STATUS } from "../../../common/constants";
import AdminHeadTitle from "../../../components/AdminHeadTitle";
import { RootState } from "../../../redux/store";
import { reqSendAlarm } from "../../../requests/alarm";
import {
  reqAShipstatus,
  reqfilterByStatusAndUserId,
  reqShipProductBox,
} from "../../../requests/ship";

interface ShipRecord {
  id: string;
  tracking: string;
  userId: string;
  warehouseId: string;
  createdAt: string;
  inboundAt: string;
  status: number;
  warehouse: string;
  overstoreFee: number;
  Storage: boolean;
  freeAt: string;
  user: {
    name: string;
    membershipId: number;
  };
}

export default function AWExpired() {
  const [shipRecords, setShipRecords] = useState<ShipRecord[]>([]);
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [isSearching, setIsSearching] = useState(false); // 검색 상태 확인
  const admin = useSelector((state: RootState) => state.admin);

  useEffect(() => {
    fetchShipRecords();
  }, [page, itemsPerPage]);

  const fetchShipRecords = async () => {
    try {
      const response = await reqAShipstatus();
      console.log("Full API response:", response);
      const records = response?.data ?? [];
      setTotalRecords(records.length); // 전체 레코드 수 설정
      setShipRecords(records);
      setIsSearching(false); // 검색 아님 상태로 설정
    } catch (error) {
      console.error("입고기록 조회 실패:", error);
    }
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleItemsPerPageChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setItemsPerPage(Number(event.target.value));
    setPage(1); // 페이지를 처음으로 리셋
  };

  const handleSearch = async () => {
    if (searchTerm) {
      setIsSearching(true); // 검색 중임을 표시
      try {
        const response = await reqfilterByStatusAndUserId(searchTerm, [
          CODE_SHIPPING_PRODUCT_STATUS["DISCARDED"],
        ]);
        const records = response?.data?.data ?? [];
        setShipRecords(records);
        setTotalRecords(records.length); // 전체 검색 결과 수 설정
      } catch (error) {
        console.error("검색 실패:", error);
        setShipRecords([]); // 오류 시 빈 배열로 초기화
      }
    } else {
      fetchShipRecords();
    }
  };

  const handleStorageChange = async (recordId: string) => {
    const recordToUpdate = shipRecords.find((record) => record.id === recordId);
    if (recordToUpdate) {
      Swal.fire({
        title: "확인",
        text: "이 항목을 폐기 처리하시겠습니까?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "예",
        cancelButtonText: "아니요",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const payload = {
            ...recordToUpdate,
            Storage: true,
            status: CODE_SHIPPING_PRODUCT_STATUS["DISCARDED"],
            deletedAt: new Date(),
          };

          try {
            await reqShipProductBox(payload);
            setShipRecords((records) =>
              records.map((record) =>
                record.id === recordId ? { ...record, Storage: true } : record
              )
            );

            const alarmPayload = {
              userId: recordToUpdate.userId,
              read: 0,
              content: `${recordToUpdate.tracking} The disposal of the item has been completed.`,
              sender: admin.name,
            };
            await reqSendAlarm(alarmPayload);
            Swal.fire(
              "폐기 처리 완료",
              "폐기 처리가 완료되었습니다.",
              "success"
            );
            fetchShipRecords();
            window.location.reload();
          } catch (error) {
            console.error("폐기 처리 실패:", error);
            Swal.fire("오류", "폐기 처리 중 오류가 발생했습니다.", "error");
          }
        }
      });
    }
  };

  // 페이지 그룹 설정
  const maxPagesToShow = 10;
  const totalPages = Math.ceil(totalRecords / itemsPerPage);
  const startPage = Math.floor((page - 1) / maxPagesToShow) * maxPagesToShow + 1;
  const endPage = Math.min(startPage + maxPagesToShow - 1, totalPages);

  // 현재 페이지에 해당하는 데이터만 보여주기 위해 slice 사용
  const currentRecords = isSearching
    ? shipRecords // 검색 중이면 전체 데이터 표시
    : shipRecords.slice((page - 1) * itemsPerPage, page * itemsPerPage);

  const renderPaginationControls = () => {
    if (isSearching || totalPages <= 1) return null; // 검색 중이거나 페이지가 1개 이하면 숨김

    return (
      <Pagination className="justify-content-center mt-3">
        <Pagination.Prev
          onClick={() => handlePageChange(page - 1)}
          disabled={page <= 1}
        />
        {Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i).map(
          (pageNumber) => (
            <Pagination.Item
              key={pageNumber}
              active={pageNumber === page}
              onClick={() => handlePageChange(pageNumber)}
            >
              {pageNumber}
            </Pagination.Item>
          )
        )}
        <Pagination.Next
          onClick={() => handlePageChange(page + 1)}
          disabled={page >= totalPages}
        />
      </Pagination>
    );
  };

  return (
    <>
      <main id="main" className="main">
        <AdminHeadTitle
          subtitle1="창고 관리"
          subtitle2="무료 기한 만료 상품"
          title="무료 기한 만료 상품"
        />
        <div className="container-fluid px-4">
          <div className="d-flex justify-content-between mb-3">
            <select
              className="form-select"
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
              style={{ width: "150px" }}
              disabled={isSearching} // 검색 중에는 페이지네이션 비활성화
            >
              <option value="10">10개씩 보기</option>
              <option value="50">50개씩 보기</option>
              <option value="100">100개씩 보기</option>
              <option value="999999">전체보기</option>
            </select>
            <div className="input-group w-25">
              <input
                type="text"
                className="form-control"
                placeholder="입고코드 검색"
                value={searchTerm.toUpperCase()}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <button
                className="btn btn-outline-secondary"
                onClick={handleSearch}
              >
                검색
              </button>
            </div>
          </div>

          <table className="table text-center table-sm">
            <thead>
              <tr>
                <th>#</th>
                <th>송장번호</th>
                <th>입고코드</th>
                <th>입고날짜</th>
                <th>무료만료날짜</th>
                <th>추가금액</th>
                <th>상태</th>
                <th>폐기처리</th>
              </tr>
            </thead>
            <tbody>
              {currentRecords.length > 0 ? (
                currentRecords.map((record, index) => (
                  <tr key={record.id}>
                    <td>{(page - 1) * itemsPerPage + index + 1}</td>
                    <td>{record.tracking}</td>
                    <td>{record.userId}</td>
                    <td>{new Date(record.inboundAt).toLocaleString()}</td>
                    <td>{new Date(record.freeAt).toLocaleString()}</td>
                    <td>{`${record.overstoreFee}$`}</td>
                    <td>{getStatusMessage(record.status)}</td>
                    <td>
                      <button
                        className="btn btn-outline-danger"
                        onClick={() => handleStorageChange(record.id)}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={8}>No records found.</td>
                </tr>
              )}
            </tbody>
          </table>

          {renderPaginationControls()}
        </div>
      </main>
    </>
  );
}

const getStatusMessage = (status: number) => {
  switch (status) {
    case CODE_SHIPPING_PRODUCT_STATUS["ENTER_DATA"]:
      return "Enter data";
    case CODE_SHIPPING_PRODUCT_STATUS["SHIPPING_AVAILABLE"]:
      return "Ready to ship";
    case CODE_SHIPPING_PRODUCT_STATUS["DISCARDED"]:
      return "Discarded";
    default:
      return "Unknown status";
  }
};
