import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { formatDate } from "../../common/format";
import { Review } from "../../interface/Review";

const LandingReviewModal = () => {
  const dispatch = useDispatch();
  const modal = useSelector((state: any) => state.modal);
  const review: Review = modal.payload.data;

  const maskLastName = (lastName: any) => {
    if (!lastName || lastName.length < 2) return lastName;
    return (
      "*".repeat(lastName.length - 1) + lastName.charAt(lastName.length - 1)
    );
  };

  const userInfo = review?.user;
  const userName = ((userInfo?.firstName as string) +
    " " +
    maskLastName(userInfo?.lastName)) as string;

  return (
    <ReviewModalWrapper>
      <div className="review-img">
        {review.photoUrls ? (
          <img
            src={`https://kr.object.ncloudstorage.com/ontact-bucket/${review.photoUrls}`}
            alt=""
          />
        ) : null}
      </div>

      <ul className="star">
        {[1, 2, 3, 4, 5].map((_, index) => {
          return index <= Number(review.rating) ? (
            <li className="on"></li>
          ) : (
            <li className=""></li>
          );
        })}
      </ul>

      <div className="review-writer">
        <div>{userName}</div>
        <div>{formatDate(review.updatedAt)}</div>
      </div>

      <div className="review-text">{review.content}</div>
    </ReviewModalWrapper>
  );
};

const ReviewModalWrapper = styled.div`
  padding-top: 1rem;
  padding-bottom: 1rem;

  .review-img img {
    width: 100%;
  }

  .star {
    margin: 15px 0 0 0;
    padding-left: 0;
  }
  .star li {
    display: inline-block;
    width: 16px;
    height: 16px;
    background: url("/landing/images/star_off.png") no-repeat center;
    background-size: cover;
  }
  .star li.on {
    background: url("/landing/images/star_on.png") no-repeat center;
    background-size: cover;
  }

  .review-writer {
    display: flex;
    flex-direction: row;
    gap: 2.4rem;
  }

  .review-text {
    margin-top: 1rem;
  }
`;

export default LandingReviewModal;
